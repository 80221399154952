import React, { Component } from 'react'
import OrbitIcon from './orbit-icon.svg'
import MapIcon from './map-icon.svg'
import Map3DIcon from './map3d-icon.svg'
import PanoramaIcon from './pano-icon.svg'
import VideoIcon from './video-icon.svg'
import ThreeDIcon from './3d-icon.svg'
import GalleryIcon from './gallery-icon.svg'
import SpexigonIcon from './spexigon-icon.svg'

export default class ProductIcon extends Component {

  render() {
    switch (this.props.product.type) {
      case 'panorama': return <img src={PanoramaIcon} alt="icon" className="icon" />
      case 'orbit': return <img src={OrbitIcon} alt="icon" className="icon" />
      case 'gallery': return <img src={GalleryIcon} alt="icon" className="icon" />
      case '3d': return <img src={ThreeDIcon} alt="icon" className="icon" />
      case 'map': return <img src={MapIcon} alt="icon" className="icon" />
      case 'map+3d': return <img src={Map3DIcon} alt="icon" className="icon" />
      case 'video': return <img src={VideoIcon} alt="icon" className="icon" />
      case 'spexigon-multipano': return <img src={SpexigonIcon} alt="icon" className="icon" />
      default: return null
    }
  }
}