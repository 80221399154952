export function openAddProjectMenu(state = false, action) {
  switch (action.type) {
    case 'OPEN_ADD_PROJECT_MENU':

      return action.openAddProjectMenu
    default:
      return state
  }
}


export function isAddingProject(state = false, action) {
  switch (action.type) {
    case 'SET_IS_ADDING_PROJECT':
      return action.isAddingProject
    default:
      return state
  }
}

export function isEdittingProject(state = false, action) {
  switch (action.type) {
    case 'SET_EDIT_PROJECT':
      return action.isEdittingProject
    default:
      return state
  }
}

export function addProjectFiles(state = false, action) {
  switch (action.type) {
    case 'ADD_PROJECT_FILES':
      return action.uploadingFiles
    default:
      return state
  }
}


export function projectsHasErrored(state = false, action) {
  switch (action.type) {
    case 'PROJECTS_HAS_ERRORED':
      return action.hasErrored
    default:
      return state
  }
}


export function projects(state = [], action) {
  switch (action.type) {
    case 'SET_PROJECTS':
      return action.projects
    case 'REMOVE_PROJECT': {
      let newProjects = [...state]
      let projectIndex = newProjects.findIndex(project => {
        return project.id === action.projectId
      })
      if (projectIndex !== -1) {
        newProjects.splice(projectIndex, 1)
      }
      return newProjects
    }
    case 'SET_PROJECT': {
      let newProjects = [...state]
      let projectIndex = newProjects.findIndex(project => {
        return project.id === action.project.id
      })
      if (projectIndex !== -1) {
        newProjects[projectIndex] = action.project
      } else {
        newProjects.push(action.project)
      }
      return newProjects
    }
    default:
      return state
  }
}


export function project(state = {}, action) {
  switch (action.type) {
    case 'SET_PROJECT':
      return action.project
    default:
      return state
  }
}


const initialFormState = {
  name: '',
  description: '',
  category: '',
  status: 'open',
  flightPlans: [],
  projectPdfs: [],
  price: 0,
  slug: '',
  teams: [],
  public: true,
  passcode: ''
}
export function projectForm(state = initialFormState, action) {
  // TODO: Make this handle multiple teams on a project in future
  // state.team_id = state.teams && state.teams.length > 0 ? state.teams[0].team_id : ''

  switch (action.type) {
    case 'SET_PROJECT_FORM':
      if (action.data === null) {
        return { ...initialFormState }
      } else {
        return { ...state, ...action.data }
      }
    default:
      return state
  }
}

const initialProjectSearchOptions = {
  name: '',
  order: '-updated_at'
}

export function projectSearchOptions(state = initialProjectSearchOptions, action) {
  switch (action.type) {
    case 'SET_PROJECT_SEARCH_OPTIONS':
      if (action.options === null) {
        return { ...initialProjectSearchOptions }
      } else {
        return { ...state, ...action.options }
      }
    default:
      return state
  }
}

const initialProjectSearchState = {
  isLoading: true,
  hasMore: true,
  page: 0,
}

export function projectSearchState(state = initialProjectSearchState, action) {
  switch (action.type) {
    case 'SET_PROJECT_SEARCH_STATE':
      if (action.state === null) {
        return { ...initialProjectSearchState }
      } else {
        return { ...state, ...action.state }
      }
    default:
      return state
  }
}


export function openSingleProject(state = false, action) {
  switch (action.type) {
    case 'PROJECTS_SINGLE_OPEN':
      return action.openSingleProject
    default:
      return state
  }
}

export function metadatas(state = [], action) {
  switch (action.type) {
    case 'SET_METADATAS':
      if (action.metadatas === null) {
        return []
      } else if (Array.isArray(action.metadatas)) {
        return action.metadatas
      } else {
        return [...state, action.metadatas]
      }
    default:
      return state
  }
}


export function isUploadingGCPs(state = false, action) {
  switch (action.type) {
    case 'SET_IS_UPLOADING_GCPS':
      return action.boolean
    default:
      return state
  }
}


export function isGCPOverlayOpen(state = false, action) {
  switch (action.type) {
    case 'SET_IS_GCP_OVERLAY_OPEN':
      return action.isGCPOverlayOpen
    default:
      return state
  }
}


export function isSendingToCustomer(state = false, action) {
  switch (action.type) {
    case 'SET_IS_SENDING_TO_CUSTOMER':
      return action.isSendingToCustomer
    default:
      return state
  }
}

export function projectPdfs(state = [], action) {
  switch (action.type) {
    case 'SET_PROJECT_PDF':
      if (action.data === null) {
        return [...state]
      } else {
        return [...state, ...action.data]
      }
    case 'SET_PDF_DATA_SUCCESS': 
      return action.projectPdfs
    case 'ADD_PDF_DATA':
      return [...state, action.newPdf]
    case 'REMOVE_PROJECT_PDF': {
      let newProjects = [...state]
      let projectIndex = newProjects.findIndex(project => {
        return project.id === action.projectPdfId
      })
      if (projectIndex !== -1) {
        newProjects.splice(projectIndex, 1)
      }
      console.log('removed from pdfs', action.projectPdfId, newProjects)
      return newProjects
    }
    default:
      return state
  }
}