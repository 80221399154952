import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Chip from '@material-ui/core/Chip'
import Input from '@material-ui/core/Input'

// TODO: move styles into css sheet??
const useStyles = makeStyles({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '15px',
    marginTop: '15px'
  },
  formControl: {
    flexGrow: '1',
    borderRadius: '14px',
    padding: '0 5px 0 5px'
  },
  filterLabel: {
    color: '#426288',
    fontFamily: 'Montserrat, sans-serif',
    fontSize: '1rem !important'
  },
  filterOption: {
    color: '#444',
    margin: '0',
  }
})

export default function ProjectsSortFilter(props) {
  const { order, authored_only, bounds } = props.projectSearchOptions
  const classes = useStyles()
  const [currentFilters, setCurrentFilters] = useState([])

  useEffect(() => {
    // since there is only one filter currently
    if (authored_only) {
      setCurrentFilters(['Only My projects'])
    } else {
      setCurrentFilters(['None'])
    }
      
  }, [authored_only])

  return (
    <div>
      <div className={classes.mainContainer}>
        <FormControl className={`${classes.formControl} project-filter-input-container`}>
          <div className="project-filter-sort-label-wrapper">
            <InputLabel id="project-filter-select-label" className={classes.filterLabel}>Sort</InputLabel>
          </div>
          <Select
            id="project-filter-select"
            className={classes.filterOption}
            value={order}
            onChange={props.onSortChange}
          >
            <MenuItem className={classes.filterOption} value={'-updated_at'}>Recently Updated</MenuItem>
            <MenuItem className={classes.filterOption} value={'-created_at'}>Newest</MenuItem>
            <MenuItem className={classes.filterOption} value={'-is_favorite,-updated_at'}>Favorites</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={`${classes.formControl} project-filter-input-container`}>
          <div className="project-filter-sort-label-wrapper">
            <InputLabel id="project-filter-mutiple-checkbox-label" className={classes.filterLabel}>Filters</InputLabel>
          </div>
          <Select
            id="project-filter-mutiple-checkbox"
            multiple
            value={currentFilters}
            input={<Input />}
            renderValue={selected => selected.join(', ')}
          >
            <MenuItem key={'Only My projects'} value={'Only My projects'}>
              <Checkbox className="project-filter-checkbox" checked={authored_only} onChange={props.onAuthoredOnlyChange} />
              <ListItemText primary={'Only My projects'} />
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      {bounds && <Chip key={'Clear Bounds Search'} label={'Clear Bounds Search'} onDelete={props.clearProjectBoundsSearch} />}
    </div>
  )
}

ProjectsSortFilter.propTypes = {
  projectSearchOptions: PropTypes.object.isRequired,
  onSortChange: PropTypes.func.isRequired,
  onAuthoredOnlyChange: PropTypes.func.isRequired,
  clearProjectBoundsSearch: PropTypes.func.isRequired
}