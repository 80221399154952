import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OrganizationHelpModal from './OrganizationHelpModal'
import { OrganizationApi } from '../../api'
// import LoadingAnimation from '../LoadingAnimation'
import './styles.css'

import OrganizationData from './OrganizationData'

class Organization extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    userTimezone: PropTypes.string.isRequired,
    setAlertModal: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      // isLoading: false,
      organizations: [],
      organization_roles: [],
      isHelpClicked: false,
      organization_permissions: null,
      role_has_permissions: [],
    }
  }

  componentDidMount() {
    this.fetchOrganizationRoles()
    this.fetchOrganizations()
    this.fetchOrganizationPermissions()
    this.fetchOrganizationPermmsionsRelation()
  }

  fetchOrganizations = () => {
    if (this.props.user.isAdmin) {
      OrganizationApi.getAll().then((resp) => {
        if (resp.success) {
          let all_organizations = Object.values(resp.data)
          let organizations = []
          all_organizations.forEach((organization) => {
            organizations.push({
              current_member: null,
              organization: organization,
            })
          })
          this.setState({ organizations })
        }
      })
    } else {
      OrganizationApi.myOrgs().then((resp) => {
        if (resp.success) {
          let organizations = []
          resp.data.forEach((organization) => {
            const current_member = Object.fromEntries(
              Object.entries(organization).filter(
                ([key]) => !key.includes('organization')
              )
            )
            organizations.push({
              current_member: current_member,
              organization: organization.organization,
            })
          })
          this.setState({ organizations })
        }
      })
    }
  }

  fetchOrganizationRoles = () => {
    OrganizationApi.getRoles().then((resp) => {
      if (resp.success) {
        this.setState({ organization_roles: resp.data })
      }
    })
  }

  fetchOrganizationPermissions = () => {
    OrganizationApi.getPermissions().then((resp) => {
      if (resp.success) {
        this.setState({ organization_permissions: resp.data })
      }
    })
  }

  fetchOrganizationPermmsionsRelation = () => {
    OrganizationApi.getPermissionRelation().then((resp) => {
      if (resp.success) {
        this.setState({ role_has_permissions: resp.data })
      }
    })
  }

  renderOrganizations = () => {
    return this.state.organizations.map((ele, i) => {
      return (
        <OrganizationData
          key={i}
          isAdmin={this.props.user.isAdmin ? true : false}
          current_member={ele.current_member}
          organization={ele.organization}
          organization_roles={this.state.organization_roles}
          timezone={this.props.userTimezone}
          setAlertModal={this.props.setAlertModal}
          autoExpandMemberList={
            this.state.organizations.length > 1 ? false : true
          }
        />
      )
    })
  }

  updateParentState = (key, value) => {
    this.setState({ [key]: value })
  }

  render() {
    return (
      <>
        {/* <LoadingAnimation visible={this.state.isLoading} /> */}
        <section id="organizations" className="organization">
          <header className="organizations__header">
            <h1 className="title">Organizations</h1>
            <i
              className="material-icons help__icon"
              onClick={() => {
                this.setState({ isHelpClicked: !this.state.isHelpClicked })
              }}
            >
              help_outline
            </i>{' '}
          </header>
          {this.state.isHelpClicked && this.state.organization_permissions && (
            <OrganizationHelpModal
              organization_roles={this.state.organization_roles}
              organization_permissions={this.state.organization_permissions}
              role_has_permissions={this.state.role_has_permissions}
              updateParentState={this.updateParentState}
            />
          )}
          <div>{this.renderOrganizations()}</div>
        </section>
      </>
    )
  }
}

export default Organization
