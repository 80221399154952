import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import './style.css'

const TooltipComponent = (props) => {
  const {
    type,
    tip
  } = props

  return (
    <div className="tooltip-wrapper">
      <Tooltip
        title={tip}
        arrow
        placement="bottom"
        classes={{
          tooltip: "tooltip-desc",
          arrow: "tooltip-arrow"
        }}
        PopperProps={{
          disablePortal: true
        }}
      >
        <div className={`tooltip-icon ${type}`}>
          ?
        </div>
      </Tooltip>
    </div>
  )
}

export default TooltipComponent;