import React, { Component } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import './styles.css'

export default class IconCheckbox extends Component {

  // TODO: extend this so an icon name can be specified for both checked and unchecked states

  static defaultProps = {
  }

  static propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.inputId = uuid()
  }

  render() {
    return <div>
      <input className="icon-checkbox" id={this.inputId} type="checkbox" checked={this.props.checked} onChange={this.props.onChange} />
      <label htmlFor={this.inputId} style={{ content: '\f07e' }} />
    </div>
  }
}
