import * as authActions from './auth'
import * as projectsActions from './projects'
import * as mapActions from './map'
import * as flightPlanActions from './flightPlans'
import * as requestActions from './request'
import * as uiActions from './ui'
import * as productActions from './products'
import * as annotationsActions from './annotations'
import * as productMapLayersActions from './productMapLayers'
import * as walletActions from './wallet'
import * as subscriptionsActions from './subscriptions'

export const ActionCreators = Object.assign(
  {},
  authActions,
  projectsActions,
  mapActions,
  flightPlanActions,
  requestActions,
  uiActions,
  productActions,
  annotationsActions,
  productMapLayersActions,
  walletActions,
  subscriptionsActions
)
