import React, { useEffect } from "react";
import {
  Grid,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogActions,
} from "@material-ui/core";
import { OrganizationSubscriptions } from "../../api";
import { alertTypes } from "../../util/constants";
import _ from "lodash";

const SubscriptionTab = (props) => {
  const {
    organization,
    subscription,
    setSubscription,
    subscriptionUpdated,
    setAlertModal,
    handleDialogClose,
  } = props;

  useEffect(() => {
    if (!subscription.maxRoleLimit) {
      setSubscription({
        ...subscription,
        maxRoleLimit: {
          owner: 1,
          admin: null,
          member: null,
          viewer: null,
          pilot: null,
        },
      });
    }
  }, [subscription, setSubscription]);

  // calculate maxRole limit for selectField as user increase/decrease each role
  const getRoleLimit = (role) => {
    if (subscription.maxRoleLimit) {
      let filteredObj = _.omit(subscription.maxRoleLimit, role);

      let limit = Object.values(filteredObj).filter(
        (x) => !isNaN(x) && x !== null
      );
      if (limit.length > 0) {
        return subscription.maxUserLimit - limit.reduce((a, b) => a + b);
      } else {
        return subscription.maxUserLimit;
      }
    } else {
      return null;
    }
  };

  const inputFields = [
    {
      inputType: "TextField",
      key: "subscription_name",
      type: "text",
      label: "Subscription Name",
      value: subscription.subscription_name,
      size: 8,
      style: { width: "60%" },
      required: true,
      disabled: false,
    },
    {
      inputType: "SelectField",
      key: "active",
      type: "",
      label: "Active",
      value:
        subscription.active || !subscription.active ? subscription.active : "",
      size: 4,
      style: { width: "100%" },
      required: true,
      disabled: false,
      items: [
        {
          key: "active_true",
          value: 1,
          label: "Yes",
        },
        {
          key: "active_false",
          value: 0,
          label: "No",
        },
      ],
    },
    {
      inputType: "TextField",
      key: "maxPhotoLimit",
      type: "number",
      label: "maxPhotoLimit",
      value: subscription.maxPhotoLimit,
      size: 4,
      style: { width: "100%" },
      required: true,
      disabled: false,
      min: 0,
      step: 1,
    },
    {
      inputType: "TextField",
      key: "maxDataStorageBytes",
      type: "number",
      label: "maxDataLimit (Gbs)",
      value: subscription.maxDataStorageBytes,
      size: 4,
      style: { width: "100%" },
      required: false,
      disabled: false,
      min: 0,
      step: 500,
    },
    {
      inputType: "TextField",
      key: "ends_at",
      type: "date",
      label: "    ",
      value: subscription.ends_at,
      size: 4,
      style: { width: "100%" },
      required: false,
      disabled: false,
      helperText: "end date",
    },
    {
      inputType: "TextField",
      key: "maxUserLimit",
      type: "number",
      label: "maxUserLimit",
      value: subscription.maxUserLimit,
      size: 12,
      style: { width: "30%" },
      required: false,
      disabled: false,
      min: 0,
      step: 5,
    },
    {
      inputType: "TextField",
      key: "maxRoleLimit",
      role: "owner",
      type: "number",
      label: "maxOwner",
      value: subscription.maxRoleLimit ? subscription.maxRoleLimit.owner : null,
      size: 2,
      style: { width: "100%" },
      required: false,
      disabled: true,
      min: 0,
      max: getRoleLimit("owner"),
    },
    {
      inputType: "TextField",
      key: "maxRoleLimit",
      role: "admin",
      type: "number",
      label: "maxAdmin",
      value: subscription.maxRoleLimit ? subscription.maxRoleLimit.admin : null,
      size: 2,
      style: { width: "100%" },
      required: false,
      disabled: false,
      min: 0,
      max: getRoleLimit("admin"),
    },
    {
      inputType: "TextField",
      key: "maxRoleLimit",
      role: "member",
      type: "number",
      label: "maxMember",
      value: subscription.maxRoleLimit
        ? subscription.maxRoleLimit.member
        : null,
      size: 2,
      style: { width: "100%" },
      required: false,
      disabled: false,
      min: 0,
      max: getRoleLimit("member"),
    },
    {
      inputType: "TextField",
      key: "maxRoleLimit",
      role: "viewer",
      type: "number",
      label: "maxViewer",
      value: subscription.maxRoleLimit
        ? subscription.maxRoleLimit.viewer
        : null,
      size: 2,
      style: { width: "100%" },
      required: false,
      disabled: false,
      min: 0,
      max: getRoleLimit("viewer"),
    },
    {
      inputType: "TextField",
      key: "maxRoleLimit",
      role: "pilot",
      type: "number",
      label: "maxPilot",
      value: subscription.maxRoleLimit ? subscription.maxRoleLimit.pilot : null,
      size: 2,
      style: { width: "100%" },
      required: false,
      disabled: false,
      min: 0,
      max: getRoleLimit("pilot"),
    },
  ];

  const renderInputFields = () => {
    return inputFields.map((input) => {
      if (input.inputType === "TextField") {
        if (
          input.key !== "maxRoleLimit" ||
          (input.key === "maxRoleLimit" && subscription.maxUserLimit > 0)
        ) {
          return (
            <Grid key={input.key} item xs={input.size}>
              <TextField
                type={input.type}
                label={input.label}
                value={input.value}
                style={input.style}
                required={input.required}
                disabled={input.disabled}
                onInput={(e) => {
                  if (input.key === "maxRoleLimit") {
                    setSubscription({
                      ...subscription,
                      [input.key]: {
                        ...subscription.maxRoleLimit,
                        [input.role]: parseInt(e.target.value),
                      },
                    });
                  } else {
                    setSubscription({
                      ...subscription,
                      [input.key]: e.target.value,
                    });
                  }
                }}
                inputProps={{
                  min: input.min,
                  max: input.max,
                  step: input.step,
                }}
                helperText={input.helperText}
              />
            </Grid>
          );
        }
      }
      if (input.inputType === "SelectField") {
        return (
          <Grid key={input.key} item xs={input.size}>
            <FormControl fullWidth>
              <InputLabel id={input.key}>{input.label}</InputLabel>
              <Select
                id={input.key}
                label={input.label}
                value={input.value}
                style={input.style}
                required={input.required}
                disabled={input.disabled}
                onChange={(e) => {
                  setSubscription({
                    ...subscription,
                    [input.key]: e.target.value,
                  });
                }}
              >
                {input.items.map((item) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        );
      }
      return true;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (subscription.id) {
      let body = {
        ...subscription,
        organization_id: organization.id,
      };
      OrganizationSubscriptions.update(body, subscription.id).then((resp) => {
        if (resp && resp.success) {
          subscriptionUpdated(resp.data.id);
          setAlertModal({
            message: "Subscription Successfully updated",
            type: alertTypes.success,
          });
        } else {
          setAlertModal({
            message: "[Error] Subcription Update",
            type: alertTypes.error,
          });
        }
      });
    } else {
      let body = {
        ...subscription,
        organization_id: organization.id,
      };
      OrganizationSubscriptions.create(body).then((resp) => {
        if (resp && resp.success) {
          subscriptionUpdated(resp.data.id);
          setAlertModal({
            message: "Subscription Successfully saved",
            type: alertTypes.success,
          });
        } else {
          console.log(resp);
          setAlertModal({
            message: "[Error] Subscription Create",
            type: alertTypes.error,
          });
        }
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={3}>
          {renderInputFields()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="btn-primary-white admin-organizations-dialog__btn"
          onClick={handleDialogClose}
        >
          Close
        </button>
        <button
          type="submit"
          className="btn-primary-solid admin-organizations-dialog__btn right"
        >
          Save & Continue
        </button>
      </DialogActions>
    </form>
  );
};

export default SubscriptionTab;
