
export function request(state = {}, action) {
  switch (action.type) {
    case 'REQUEST_IN_PROGRESS':
      return setRequestInProgress(state, action)
    default:
      return state
  }

}

function setRequestInProgress(state, action) {
  const { inProgress, requestType } = action
  const requestObject = {}
  requestObject[requestType] = inProgress
  return Object.assign({}, state, requestObject)
}