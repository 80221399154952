import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';
import CoordinateSystemPicker from '../CoordinateSystemPicker'
import './styles.css'
import { getCrsTypes, DEFAULT_CRS, COMMON_CRS_LIST } from '../../util'

class CoordinateSystemPickerModal extends Component {
  getCommonPicks = () => {
    return this.props.commonPicks.length > 0 ? this.props.commonPicks : COMMON_CRS_LIST
  }

  getRecentlyPicked = (crsType='all') => {
    const recentlyPickedStr = localStorage.getItem(CoordinateSystemPickerModal.LS_RECENTS_KEY)
    if (recentlyPickedStr !== null) {
      // parse the JSON-ified string from local storage
      const recentlyPicked = JSON.parse(recentlyPickedStr)

      // if we have a specific picker type, filter those types
      if (crsType !== 'all') {
        // reverse so we have most recently picked first
        return recentlyPicked.filter(crs => crs.type === crsType).reverse()
      }

      // reverse so we have most recently picked first
      return recentlyPicked.reverse()
    }

    // nothing recent - return empty list
    return []
  }

  updateRecentlyPicked = (crs) => {
    // reverse the list so that LRU is first
    let recentlyPicked = this.getRecentlyPicked().reverse()

    // if a CRS is added again, remove it and add it again so it 
    const foundIdx = recentlyPicked.findIndex(elem => elem.id === crs.id)
    if (foundIdx > -1) {
      recentlyPicked.splice(foundIdx, 1)
    }
    
    recentlyPicked.push(crs)

    // trim down the list if its gone past our max size
    if (recentlyPicked.length > CoordinateSystemPickerModal.MAX_RECENTS) {
      recentlyPicked = recentlyPicked.slice(1)
    }

    // set the local storage item
    localStorage.setItem(CoordinateSystemPickerModal.LS_RECENTS_KEY, JSON.stringify(recentlyPicked))
  }

  onClose = () => {
    // send back null if cancel/close was hit
    this.props.onPick(null)
  }

  onCrsSelected = (crs) => {
    // update the recently picked list
    this.updateRecentlyPicked(crs)

    // send back the selected coordinate system
    this.props.onPick(crs)
  }

  onCommonCrsSelected = (event) => {
    const value = event.target.value
    const crs = this.getCommonPicks().find(elem => elem.id === value) || DEFAULT_CRS
    this.updateRecentlyPicked(crs)

    // send back the selected coordinate system - or default CRS if we didn't find it in the list
    this.props.onPick(crs)
  }

  onRecentCrsSelected = (event) => {
    const value = event.target.value
    const crs = this.getRecentlyPicked(this.props.pickerType).find(elem => elem.id === value) || DEFAULT_CRS
    this.updateRecentlyPicked(crs)

    // send back the selected coordinate system - or default CRS if we didn't find it in the list
    this.props.onPick(crs)
  }

  render() {
    const {
      pickerType,
      title,
      visible,
    } = this.props

    if(!visible) {
      return null
    }

    const commons = pickerType !== 'all' ? this.getCommonPicks().filter(elem => elem.type === pickerType) : this.getCommonPicks()
    const recents = this.getRecentlyPicked(pickerType)
    return (
      <div className="crs-modal">
        <div className="crs-modal__content">
          <div className="crs-modal__header">
            <h1 className="crs-modal__title">{title}</h1>
          </div>
          <div className="crs-modal__picker">
            <CoordinateSystemPicker
              pickerType={pickerType}
              onCrsSelected={this.onCrsSelected} />
          </div>
          <div className="crs-modal__buttons">
            <Grid container spacing={3}>
              <Grid item xs={4}>
                {commons.length > 0 &&
                <form>
                  <label>Common</label>
                  <select defaultValue="none" onChange={this.onCommonCrsSelected.bind(this)}>
                    <option key="none" value="none"></option>
                    {commons.map(crs => (<option key={crs.id} value={crs.id}>{crs.name}</option>))}
                  </select>
                </form>}
              </Grid>
              <Grid item xs={4}>
              {recents.length > 0 && 
                <form>
                  <label>Recently Selected</label>
                  <select defaultValue="none" onChange={this.onRecentCrsSelected.bind(this)}>
                    <option key="none" value="none"></option>
                    {recents.map(crs => (<option key={crs.id} value={crs.id}>{crs.name}</option>))}
                  </select>
                </form>}
              </Grid>
              <Grid item xs={4}>
                <div className="btn-wrapper">
                  <button type="button" className="btn white" onClick={this.onClose}>Cancel</button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

CoordinateSystemPickerModal.propTypes = {
  pickerType: PropTypes.oneOf(getCrsTypes()),
  onPick: PropTypes.func,
  title: PropTypes.string,
  visible: PropTypes.bool,
  commonPicks: PropTypes.arrayOf(PropTypes.object),
}

CoordinateSystemPickerModal.defaultProps = {
  pickerType: 'all',
  onPick: (crs) => { console.warn('No function defined to handle CRS selection...') },
  title: 'Choose Coordinate System',
  visible: false,
  commonPicks: [],
}

CoordinateSystemPickerModal.LS_RECENTS_KEY = 'spexi-crs-recents'
CoordinateSystemPickerModal.MAX_RECENTS = 10

export default CoordinateSystemPickerModal
