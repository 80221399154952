import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { userVerify } from '../actions/auth'


class VerifyContainer extends Component {
  componentDidMount(){
    let token = this.props.match.params.token
    this.props.userVerify(token)  
  }


  render() {
    return <Redirect to="/" />
  }
}


const mapStateToProps = state => {
  return {
    userIsVerified: state.userIsVerified,
    userIsLoggedIn: state.userIsLoggedIn,
    requestsInProgress: state.request,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    userVerify: (token) => dispatch(userVerify(token))
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(VerifyContainer)
