import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import 'moment-timezone'
import ProductUploadList from '../ProductUploadList/'
import { getDefaultProductName } from '../../helpers'
import { Products, OrganizationApi } from '../../api'
import { PLAYER_URL } from '../../config'
import { alertTypes } from '../../util/constants'
import './styles.css'
import SubscriptionsDialog from '../SubscriptionsDialog'

class ProjectUpload extends Component {
  static propTypes = {
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.timer = null

    this.state = {
      hasOrgSubscription: false,
      openSubscriptionDialog: false,
    }
  }

  componentDidMount() {
    this.refreshProductJobs()
    this.checkProjectOrgSubscription()
  }

  componentDidUpdate(prevProps) {
    // when route is visited directly, this is an empty object initially and would never run in componentDidMount
    if (
      Object.keys(prevProps.project).length === 0 &&
      prevProps.project.constructor === Object
    ) {
      this.checkProjectOrgSubscription()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
  }

  checkProjectOrgSubscription = () => {
    // check if the project is associated with an organization that has an org subscription
    if (this.props.project && this.props.project.organization_id) {
      OrganizationApi.hasSubscription(this.props.project.organization_id)
        .then((resp) => {
          const hasOrgSubscription = resp.success && resp.data.has_subscription
          this.setState({ hasOrgSubscription })
        })
        .catch(console.error)
    }
  }

  handleCreateSet = () => {
    const setTypes = ['panorama', 'orbit', 'map+3d']

    this.props.setAlertModal({
      message:
        'Are you sure that you want to create one of each product at once (panorama, orbit, map & 3d)?',
      type: alertTypes.warning,
      confirmLabel: 'Create',
      confirmHandler: () => {
        setTypes.forEach((type, index) => {
          console.log(index)
          Products.create({
            ...this.props.productForm,
            project_id: this.props.project.id,
            type: type,
            name: getDefaultProductName(type),
          })
            .then(() => {
              if (index === setTypes.length - 1) {
                this.props.getProducts(this.props.project.id)
                this.props.setAlertModal({
                  message:
                    'Successfully bulk created a panorama, orbit, and map & 3d product.',
                  type: alertTypes.success,
                  confirmLabel: 'Okay',
                  confirmHandler: () => {},
                  cancellable: false,
                })
              }
            })
            .catch((error) => {
              this.props.setAlertModal({
                message:
                  'Error communicating with upload server. Please contact support.',
                type: alertTypes.error,
              })
            })
        })
      },
      cancellable: true,
    })
  }

  /**
   * Update product jobs on interval
   */
  refreshProductJobs = () => {
    let project_id = this.props.project.id
      ? this.props.project.id
      : this.props.match.params.id
    this.timer = setInterval(() => {
      // alert('okay')
      this.props.getProductJobsDebounced(project_id)
    }, 5000)
  }

  render() {
    let productList = this.props.products
    if (Object.keys(this.props.project).length !== 0) {
      return (
        <article
          className="project-upload project-content"
          key={this.props.project.id}
        >
          <div className="project-card" style={{ position: 'relative' }}>
            <div className="project-card__header">
              <h3 className="project-name">{this.props.project.name}</h3>
              <p className="flight-plan-count">
                {this.props.project.flightPlans.length} flight plan
                {this.props.project.flightPlans.length > 1 && 's'}
              </p>
            </div>

            {this.props.project.created_at !== undefined && (
              <p className="project-time">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 11.164 11.349"
                >
                  <path
                    d="M7.576,2A5.626,5.626,0,0,0,2,7.674a5.626,5.626,0,0,0,5.576,5.674,5.632,5.632,0,0,0,5.587-5.674A5.632,5.632,0,0,0,7.576,2Zm.006,10.214a4.54,4.54,0,1,1,4.465-4.539A4.5,4.5,0,0,1,7.582,12.214Zm.279-7.377H7.024v3.4l2.93,1.787.419-.7L7.861,7.816Z"
                    transform="translate(-2 -2)"
                  />
                </svg>{' '}
                Posted{' '}
                {moment(this.props.project.created_at)
                  .tz(this.props.userTimezone)
                  .fromNow()}
              </p>
            )}

            {this.props.project.public > 0 && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${PLAYER_URL}/${
                  this.props.project.slug
                    ? this.props.project.slug
                    : this.props.project.id
                }`}
                className="share-icon external"
                style={{ position: 'absolute', top: '37px', right: '0' }}
              >
                <svg height={10} viewBox="0 0 384 384" width={10}>
                  <path d="M374 0h-99.7c-5.523 0-10 4.477-10 10s4.477 10 10 10h75.712L155.5 216.379a9.997 9.997 0 0 0-2.746 9.766 9.997 9.997 0 0 0 16.945 4.308L364 34.301v75.398c0 5.524 4.477 10 10 10s10-4.476 10-10V10c0-5.523-4.477-10-10-10zm0 0" />
                  <path d="M324.137 214.492c-5.524 0-10 4.477-10 10V334c-.02 16.563-13.438 29.98-30 30H50c-16.562-.02-29.98-13.438-30-30V99.863c.02-16.562 13.438-29.98 30-30h109.508c5.523 0 10-4.476 10-10 0-5.523-4.477-10-10-10H50c-27.602.032-49.969 22.399-50 50V334c.031 27.602 22.398 49.969 50 50h234.137c27.601-.031 49.968-22.398 50-50V224.492c0-5.523-4.477-10-10-10zm0 0" />
                </svg>
                <span>Share</span>
              </a>
            )}
          </div>

          <div className="full-width">
            {productList && productList.length > 0 && (
              <ProductUploadList {...this.props} />
            )}

            <button
              type="button"
              className="btn"
              onClick={() => this.props.setProductForm({ active: true })}
              disabled={
                this.props.user &&
                (this.props.user.hasActiveSubscription ||
                  this.props.user.hasCredits ||
                  this.state.hasOrgSubscription)
                  ? false
                  : true
              }
            >
              Create Product
            </button>

            {this.props.user &&
              !this.props.user.hasActiveSubscription &&
              !this.props.user.hasCredits &&
              !this.state.hasOrgSubscription && (
              <>
                <p
                  className="small-text"
                  style={{
                    fontSize: '0.8rem',
                    marginTop: 0,
                    marginBottom: '2rem',
                    textAlign: 'center',
                  }}
                >
                    You need a subscription or credits in your wallet to create
                    products.
                </p>

                <button
                  type="button"
                  className="btn white"
                  onClick={() =>
                    this.setState({ openSubscriptionDialog: true })
                  }
                >
                    Buy a Subscription
                </button>

                <button
                  type="button"
                  className="btn white"
                  onClick={() => this.props.setIsPurchasingCredits(true)}
                >
                    Buy Credits
                </button>
              </>
            )}

            {this.props.user && this.props.user.hasActiveSubscription && (
              <button
                type="button"
                className="btn white"
                onClick={this.handleCreateSet}
              >
                Bulk Create Products
              </button>
            )}

            {this.props.requestsInProgress.START_PROCESSING && (
              <div
                className="lds-ring"
                style={{
                  marginTop: '2rem',
                  transform: 'translateX(-50%)',
                  left: '50%',
                }}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
          </div>

          {this.props.user && (
            <SubscriptionsDialog
              user={this.props.user}
              setAlertModal={this.props.setAlertModal}
              open={this.state.openSubscriptionDialog}
              onClose={() => this.setState({ openSubscriptionDialog: false })}
            />
          )}
        </article>
      )
    } else {
      return <div />
    }
  }
}

export default ProjectUpload
