import uuidv1 from 'uuid/v1'
import { DEFAULT_CRS } from '../../util'

const VALID_LABEL_NAMES = ['label']
const VALID_Y_NAMES = ['latitude', 'northing']
const VALID_X_NAMES = ['longitude', 'easting']
const VALID_Z_NAMES = ['elevation']
const VALID_HEADERS = [VALID_LABEL_NAMES, VALID_Y_NAMES, VALID_X_NAMES, VALID_Z_NAMES]
const NUM_COLS = VALID_HEADERS.length

export const cleanGcpHeaders = (csvData) => {
  // lowercase headings and trim whitespace
  let rows = csvData.split(/\r\n|\r|\n/)
  let headings = rows[0].toLowerCase()
  headings = headings.split(',').map(x => x.trim())
  rows[0] = headings.join(',')
  return rows.join('\r\n')
}

export const validateGcpHeaders = (headerRow) => {
  // make sure the header row has the expected number of columns first
  if (headerRow.length !== NUM_COLS) {
    throw new Error(`Invalid number of columns in the header row. Expected ${NUM_COLS} but found ${headerRow.length}`)
  }

  // check to make sure we have valid heading names
  let errors = []
  headerRow.forEach((header, index) => {
    const validHeaderNames = VALID_HEADERS[index]
    if (!validHeaderNames.includes(header)) {
      errors.push(`Column ${index + 1} contains invalid header name "${header}" - please use one of "${validHeaderNames.join('", "')}"`)
    }
  })

  if (errors.length > 0) {
    throw new Error(`Invalid GCP file: ${errors.join(', ')}`)
  }

  return true
}

export const parseGcps = (rows) => {
  // assumes that the rows object doesn't contain the headers..
  let errors = []
  let gcps = []
  rows.forEach((row, rowIndex) => {
    if (row.length !== NUM_COLS) {
      // note: rowIndex + 2 because we assume the header is row 1, and we shouldn't have had the header row
      errors.push(`Invalid number of columns in row ${rowIndex + 2}. Expected ${NUM_COLS} but found ${row.length}`)
      return
    }

    const label = row[0]
    const y = Number(row[1])
    const x = Number(row[2])
    const z = Number(row[3])

    if (isNaN(y) || isNaN(x) || isNaN(z)) {
      // note: rowIndex + 2 because we assume the header is row 1, and we shouldn't have had the header row
      errors.push(`Encountered invalid number for row ${rowIndex + 2} in columns 2, 3 or 4 = (${row[1]}, ${row[2]}, ${row[3]})`)
      return
    }

    gcps.push({
      id: uuidv1(),
      label: label,
      point: {
        coordinates: [x, y]
      },
      elevation: z,
      order: rowIndex + 1, // +1 because we skipped the header column
      is_check_point: false,
      projection: DEFAULT_CRS.id,
    })
  })

  if (errors.length > 0) {
    throw new Error(`Invalid GCP file: ${errors.join(', ')}`)
  }

  return gcps
}

export const getGcps = (csvData) => {
  // make sure we have more than just the header row
  const rows = csvData.data
  if (rows.length <= 1) {
    throw new Error(`Invalid GCP file: Input file only has an invalid number of rows: ${rows.length}`)
  }

  validateGcpHeaders(rows[0])
  return parseGcps(rows.slice(1))
}
