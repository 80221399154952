import React from 'react'
import { Provider, ReactReduxContext } from 'react-redux'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import store, { history } from '../store'
import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { AppContainer as HotAppContainer } from 'react-hot-loader'
import App from '../App'
import SignupContainer from './SignupContainer'
import TermsContainer from './TermsContainer'
import FAQContainer from './FAQContainer'
import ForgotPasswordContainer from './ForgotPasswordContainer'
import ResetPasswordContainer from './ResetPasswordContainer'
import VerifyContainer from './VerifyContainer'
import AuthProviderContainer from './AuthProviderContainer'
import DeleteUserContainer from './DeleteUserContainer'
import NotFound from '../components/NotFound'
import { PLAYER_URL } from '../config'
import { GoogleOAuthProvider } from '@react-oauth/google'
import 'normalize.css'
import '../index.css'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    subtitle1: {
      fontSize: 14,
    },
    subtitle2: {
      fontSize: 13,
    },
  },
  palette: {
    primary: {
      main: '#6AAEC4',
    },
    secondary: {
      main: '#202340',
    },
  },
})

const AppContainer = () => (
  <ThemeProvider theme={theme}>
    <HotAppContainer>
      <Provider store={store} context={ReactReduxContext}>
        <GoogleOAuthProvider clientId="88916558778-a0sh543v3bbb53dmej2o0khgel406n3i.apps.googleusercontent.com">
          <ConnectedRouter history={history} context={ReactReduxContext}>
            <Switch>
              <Route exact path="/" component={App} />
              <Route exact path="/r/:code" component={SignupContainer} />
              <Route path="/signup" component={SignupContainer} />
              <Route path="/terms" component={TermsContainer} />
              <Route path="/faqs" component={FAQContainer} />
              <Route
                path="/forgot-password"
                component={ForgotPasswordContainer}
              />
              <Route
                path="/password-reset/:token"
                component={ResetPasswordContainer}
              />
              <Route path="/verify/:token" component={VerifyContainer} />
              <Route
                path="/auth/:provider/callback"
                component={AuthProviderContainer}
              />
              <Route exact path="/account" component={App} />
              <Route exact path="/admin" component={App} />
              <Route exact path="/teams" component={App} />
              <Route exact path="/add-project" component={App} />
              <Route exact path="/project/:id" component={App} />
              <Route exact path="/project/:id/edit" component={App} />
              <Route exact path="/project/:id/plan" component={App} />
              <Route exact path="/project/:id/process" component={App} />
              <Route exact path="/project/:id/review/:pid" component={App} />
              <Route
                exact
                path="/project/:id/review/:pid/export"
                component={App}
              />
              <Route
                exact
                path="/project/:id/review/:pid/add-annotation"
                component={App}
              />
              <Route
                exact
                path="/project/:id/review/:pid/:aid/edit"
                component={App}
              />
              <Route exact path="/project/:id/review" component={App} />
              <Route exact path="/organizations" component={App} />
              <Route exact path="/organizations/join/:orgId" component={App} />
              <Route
                exact
                path="/delete-user"
                component={DeleteUserContainer}
              />
              <Route component={NotFound} />
            </Switch>
          </ConnectedRouter>
          <iframe
            id="iframeLogin"
            src={PLAYER_URL}
            width="1"
            height="1"
            title="loginIframe"
            style={{
              position: 'fixed',
              top: '-1px',
              left: '-1px',
              border: 0,
              zIndex: -3000,
            }}
          />
        </GoogleOAuthProvider>
      </Provider>
    </HotAppContainer>
  </ThemeProvider>
)

export default AppContainer
