import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import SidebarHeader from '../SidebarHeader'
import moment from 'moment'
import 'moment-timezone'
import { convertStr, isProjectFavorite } from '../../helpers'
import './styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import { debounce } from 'lodash'
import { NoProjectsIcon } from './NoProjectsIcon'
import ProjectsSortFilter from './ProjectsSortFilter'
import { CSSTransition } from 'react-transition-group'
import UserCard from '../UserCard'
import CostCard from '../CostCard'

class ProjectList extends Component {
  static propTypes = {
    projects: PropTypes.array.isRequired,
    leafletMapElement: PropTypes.object,
    hasErrored: PropTypes.bool,
    setMapBounds: PropTypes.func.isRequired,
    projectsBoundsFetchData: PropTypes.func.isRequired,
    projectsMyFetchData: PropTypes.func.isRequired,
    setRequestInProgress: PropTypes.func.isRequired,
    setProjectsMapCenter: PropTypes.func.isRequired,
    fetchProject: PropTypes.func.isRequired,
    setIsAddingProject: PropTypes.func.isRequired,
    mapZoomLevel: PropTypes.number.isRequired,
    projectSearchOptions: PropTypes.object.isRequired,
    setProjectSearchOptions: PropTypes.func.isRequired,
    setProjects: PropTypes.func.isRequired,
    projectSearchState: PropTypes.object.isRequired,
    setProjectSearchState: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      slidesLength: null,
      currentPosition: 0,
      slideTransform: 0,
      interval: null,
      openMenu: false,
      openSortFilterMenu: false,
    }
  }

  // TODO: we need to be able to handle delaying requests
  // if one is already in progress (cancel current or
  // delay) [eg. slamming the desc/asc button creates loads of requests]
  onSearchChangeDebounce = debounce(
    () => {
      this.refreshList()
    },
    500,
    { leading: false, trailing: true }
  )

  onSearchChange = (event) => {
    this.props.setProjectSearchOptions({ search: event.target.value })
    this.onSearchChangeDebounce(event.target.value)
  }

  onAuthoredOnlyChange = (event) => {
    this.props.setProjectSearchOptions({ authored_only: event.target.checked })
    this.refreshList()
  }

  componentDidMount() {
    // This ensures that we can see the base map even if the basemap has been disabled in
    // another part of the application.
    this.props.setMapLayers({ basemap: true })
  }

  focusMapOnProject = (index) => {
    let currentProject = this.props.projects[index]
    let coords = currentProject.centroid.coordinates
    this.props.setProjectsMapCenter([coords[1], coords[0]])
    this.props.mapZoomLevel(16)
  }

  sortProject = (lhs, rhs) => {
    if (isProjectFavorite(lhs.id) && !isProjectFavorite(rhs.id)) {
      return -1
    } else if (isProjectFavorite(rhs.id) && !isProjectFavorite(lhs.id)) {
      return 1
    }
    return 0
  }

  filterProject = (project) => {
    if (project === null) {
      return false
    }
    let filterName = this.props.projectSearchOptions.filter.name.toLowerCase()
    if (this.props.projectSearchOptions.filter.name !== '' && project.name.toLowerCase().includes(filterName) === false) {
      return false
    }
    return true
  }

  clearFilter = () => {
    // TODO: when we add more complex options this will need to change
    this.props.setProjectSearchOptions({ search: '' })
    this.refreshList()
  }

  onOrderChange = (event) => {
    this.props.setProjectSearchOptions({ order: event.target.value })
    this.refreshList()
  }

  refreshList = () => {
    this.props.setProjectSearchState({ page: 0, hasMore: true })
    this.props.setProjects([])
    // the web ecosystem is stable and good
    setTimeout(() => {
      this.loadMore()
    }, 125)
  }

  clearProjectBoundsSearch = () => {
    this.props.setProjectSearchOptions({ bounds: '' })
    this.refreshList()
  }

  toggleSortFilterMenu = () => {
    this.setState({ openSortFilterMenu: !this.state.openSortFilterMenu })
  }

  renderProjects = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div id="project-filter-area" style={{ marginLeft: '2rem', marginRight: '2rem' }}>
          <div id="project-search-sort-wrapper">
            <div id="project-search-bar">
              <input
                name="name"
                autoComplete={'off'}
                onChange={this.onSearchChange}
                value={this.props.projectSearchOptions.search}
              />
              <div id="project-search-bar-inner">
                <i id="project-search-bar-icon" className="fa fa-search" aria-hidden="true" />
                <i
                  id="project-search-bar-cancel"
                  className="fa fa-times"
                  aria-hidden="true"
                  onClick={this.clearFilter}
                  hidden={!this.props.projectSearchOptions.search}
                />
              </div>
            </div>
            <div
              id="project-sort-filter-button"
              onClick={this.toggleSortFilterMenu}
              className={this.state.openSortFilterMenu ? 'sort-filter-active' : ''}
            >
              <i id="project-sort-menu-icon" className="fa fa-sort-amount-desc" aria-hidden="true" />
            </div>
          </div>
          <div style={{ overflow: 'hidden' }}>
            {' '}
            {/* wrapper for CSS margin transition */}
            <CSSTransition
              in={this.state.openSortFilterMenu || this.props.projectSearchOptions.bounds}
              timeout={600}
              classNames="openFilterMenu"
              unmountOnExit
            >
              <ProjectsSortFilter
                projectSearchOptions={this.props.projectSearchOptions}
                onSortChange={this.onOrderChange}
                onAuthoredOnlyChange={this.onAuthoredOnlyChange}
                clearProjectBoundsSearch={this.clearProjectBoundsSearch}
              />
            </CSSTransition>
          </div>
        </div>

        {this.props.projects.length === 0 &&
          !this.props.projectSearchState.hasMore &&
          !this.props.projectSearchState.isLoading && <NoProjectsFound {...this.props} />}

        <InfiniteScroll
          dataLength={this.props.projects.length}
          next={this.loadMore.bind(this)}
          hasMore={this.props.projectSearchState.hasMore} // get this from the response
          loader={
            <div style={{ height: 100, display: 'flex', alignItems: 'center' }}>
              <div className="lds-ring" style={{ transform: 'translateX(-50%)', left: '50%' }}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          }
          scrollableTarget={'sidebar-content'}
        >
          {this.props.projects.map((project, index) => {
            return <ProjectListItem {...this.props} project={project} key={project.id} index={index} />
          })}
        </InfiniteScroll>
      </div>
    )
  }

  resendVerificationLinkHandler = () => {
    this.props.resendVerificationLink(this.props.user.email)
  }

  openMenu = () => {
    this.setState({ openMenu: !this.state.openMenu })
  }

  // TODO: if something changes during the call, we should
  // essentially ignore the response from an existing
  // call

  loadMore = () => {
    this.props.fetchNextProjectPage()
  }

  render() {
    if (this.props.hasErrored) {
      return <p className="status-message">Sorry! There was an error loading the items</p>
    }

    return (
      <div>
        <SidebarHeader title="Projects" />

        <div
          id="project-list"
          className="list"
          ref={(el) => {
            this.projectsListElement = el
          }}
          style={{ transform: `translate(${-(this.state.currentPosition * 75)}vw, 0px)` }}
        >
          {!this.props.userIsVerified && (
            <div className="verify-address">
              Please verify your account to begin.
              <br /> Check your inbox for a verification email.
              <br />
              <br />
              Can't find it?{' '}
              <button type="button" onClick={this.resendVerificationLinkHandler}>
                Resend verification email
              </button>
            </div>
          )}
          {this.renderProjects()}
        </div>
      </div>
    )
  }
}

export default ProjectList

const ProjectListItem = (props) => {
  const [state, setState] = useState({
    isAuthorHovered: false,
    isCostEstimateHovered: false,
  })

  const clickStarHandler = (event) => {
    event.stopPropagation()
    props.setProjectFavourite(project.id, favouriteStatus)
    event.target.classList.toggle('starred')
  }

  const { project } = props
  // favourite status from list prop or toggled local reducer project boolean
  let favouriteStatus = isProjectFavorite(project.id)
  const statusIndictatorLabel = convertStr(project.status)
  const counter = props.index ? props.index : ''
  const renderFavouriteStatusIcon = (starred) => {
    // #6AAEC4
    // #dadbe0

    return (
      <div className="favorite-star" style={{ display: 'flex', marginBottom: '0.25rem' }} onClick={clickStarHandler}>
        <i
          className={starred ? 'fa fa-star' : 'fa fa-star-o'}
          style={{ color: starred ? '#6AAEC4' : '#dadbe0' }}
          aria-hidden={true}
        />
      </div>
    )
  }

  return (
    <div onClick={() => props.fetchProject(project.id)} className="list__item project-card">
      <article className={`project row row-${counter + 1}`}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexShrink: 1, minWidth: 0 }}>
            <h3 className="project-name">{project.name}</h3>
            {renderFavouriteStatusIcon(favouriteStatus)}
          </div>
          {project.status === 'draft' && <div className="project-status">{statusIndictatorLabel}</div>}
        </div>
        <div className="flight-plan-count">
          {project.flightPlans.length} flight plan{project.flightPlans.length > 1 && 's'}
        </div>
        {project.distance !== undefined && (
          <p className="project-distance">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.489 13.581">
              <path
                d="M9.245,2A4.277,4.277,0,0,0,5,6.315c0,3.236,4.245,8.013,4.245,8.013s4.245-4.777,4.245-8.013A4.277,4.277,0,0,0,9.245,2Zm0,5.856A1.541,1.541,0,1,1,10.76,6.315,1.529,1.529,0,0,1,9.245,7.856Z"
                transform="translate(-4.5 -1.5)"
              />
            </svg>{' '}
            {(project.distance / 1000).toFixed(1)} km away
          </p>
        )}

        {project.created_at !== undefined && (
          <div className="project-time">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.164 11.349">
              <path
                d="M7.576,2A5.626,5.626,0,0,0,2,7.674a5.626,5.626,0,0,0,5.576,5.674,5.632,5.632,0,0,0,5.587-5.674A5.632,5.632,0,0,0,7.576,2Zm.006,10.214a4.54,4.54,0,1,1,4.465-4.539A4.5,4.5,0,0,1,7.582,12.214Zm.279-7.377H7.024v3.4l2.93,1.787.419-.7L7.861,7.816Z"
                transform="translate(-2 -2)"
              />
            </svg>{' '}
            Posted {moment(project.created_at).tz(props.userTimezone).fromNow()}
          </div>
        )}

        {props.user && props.user.isAdmin && project.author && project.author.name && (
          <div
            className="project-author"
            style={{ marginRight: '1rem' }}
            onMouseEnter={() => setState({ isAuthorHovered: true })}
            onMouseLeave={() => setState({ isAuthorHovered: false })}
          >
            <svg viewBox="0 0 482.9 482.9">
              <path d="M239.7 260.2h3.2c29.3-.5 53-10.8 70.5-30.5 38.5-43.4 32.1-117.8 31.4-124.9-2.5-53.3-27.7-78.8-48.5-90.7C280.8 5.2 262.7.4 242.5 0H240.8c-11.1 0-32.9 1.8-53.8 13.7-21 11.9-46.6 37.4-49.1 91.1-.7 7.1-7.1 81.5 31.4 124.9 17.4 19.7 41.1 30 70.4 30.5zm-75.1-152.9c0-.3.1-.6.1-.8 3.3-71.7 54.2-79.4 76-79.4H241.9c27 .6 72.9 11.6 76 79.4 0 .3 0 .6.1.8.1.7 7.1 68.7-24.7 104.5-12.6 14.2-29.4 21.2-51.5 21.4h-1c-22-.2-38.9-7.2-51.4-21.4-31.7-35.6-24.9-103.9-24.8-104.5z" />
              <path d="M446.8 383.6v-.3c0-.8-.1-1.6-.1-2.5-.6-19.8-1.9-66.1-45.3-80.9-.3-.1-.7-.2-1-.3-45.1-11.5-82.6-37.5-83-37.8-6.1-4.3-14.5-2.8-18.8 3.3-4.3 6.1-2.8 14.5 3.3 18.8 1.7 1.2 41.5 28.9 91.3 41.7 23.3 8.3 25.9 33.2 26.6 56 0 .9 0 1.7.1 2.5.1 9-.5 22.9-2.1 30.9-16.2 9.2-79.7 41-176.3 41-96.2 0-160.1-31.9-176.4-41.1-1.6-8-2.3-21.9-2.1-30.9 0-.8.1-1.6.1-2.5.7-22.8 3.3-47.7 26.6-56 49.8-12.8 89.6-40.6 91.3-41.7 6.1-4.3 7.6-12.7 3.3-18.8-4.3-6.1-12.7-7.6-18.8-3.3-.4.3-37.7 26.3-83 37.8-.4.1-.7.2-1 .3-43.4 14.9-44.7 61.2-45.3 80.9 0 .9 0 1.7-.1 2.5v.3c-.1 5.2-.2 31.9 5.1 45.3 1 2.6 2.8 4.8 5.2 6.3 3 2 74.9 47.8 195.2 47.8s192.2-45.9 195.2-47.8c2.3-1.5 4.2-3.7 5.2-6.3 5-13.3 4.9-40 4.8-45.2z" />
            </svg>{' '}
            {project.author.name}
            {state.isAuthorHovered && <UserCard userId={project.author.id} isAdmin={true} />}
          </div>
        )}

        {project.teams.length > 0 &&
          project.teams.map((projectTeam) => {
            return (
              <div className="project-author" style={{ marginRight: '1rem' }} key={projectTeam.team_id}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  enableBackground="new 0 0 482.9 482.9"
                  version="1.1"
                  viewBox="0 0 482.9 482.9"
                  xmlSpace="preserve"
                >
                  <path d="M240.3 253.8h2.1c19.3-.3 35-7.1 46.5-20.1 25.4-28.6 21.2-77.7 20.7-82.4-1.7-35.2-18.3-52-32-59.9-10.2-5.9-22.2-9-35.5-9.3H241c-7.3 0-21.7 1.2-35.5 9-13.9 7.9-30.8 24.7-32.4 60.1-.5 4.7-4.7 53.8 20.7 82.4 11.5 13.1 27.2 19.9 46.5 20.2zm-49.6-100.9c0-.2.1-.4.1-.5C193 105.1 226.6 100 241 100h.8c17.8.4 48.1 7.7 50.2 52.4 0 .2 0 .4.1.5.1.5 4.7 45.3-16.3 69-8.3 9.4-19.4 14-34 14.1h-.8c-14.5-.1-25.7-4.8-33.9-14.1-20.9-23.5-16.4-68.6-16.4-69z"></path>
                  <path d="M377 335.3v-.2c0-.5-.1-1.1-.1-1.6-.4-13.1-1.3-43.6-29.9-53.4-.2-.1-.5-.1-.7-.2-29.8-7.6-54.5-24.8-54.8-24.9-4-2.8-9.6-1.8-12.4 2.2-2.8 4-1.8 9.6 2.2 12.4 1.1.8 27.4 19.1 60.3 27.5 15.4 5.5 17.1 21.9 17.6 37 0 .6 0 1.1.1 1.6.1 5.9-.3 15.1-1.4 20.4-10.7 6.1-52.6 27.1-116.4 27.1-63.5 0-105.7-21.1-116.4-27.1-1.1-5.3-1.5-14.5-1.4-20.4 0-.5.1-1.1.1-1.6.5-15 2.2-31.5 17.6-37 32.9-8.4 59.1-26.8 60.3-27.5 4-2.8 5-8.4 2.2-12.4s-8.4-5-12.4-2.2c-.3.2-24.9 17.4-54.8 24.9-.3.1-.5.1-.7.2-28.6 9.8-29.5 40.4-29.9 53.4 0 .6 0 1.1-.1 1.6v.2c-.1 3.4-.1 21.1 3.4 29.9.7 1.7 1.8 3.2 3.4 4.2 2 1.3 49.4 31.5 128.8 31.5s126.9-30.3 128.8-31.5c1.5-1 2.8-2.4 3.4-4.2 3.3-8.9 3.2-26.5 3.2-29.9z"></path>
                  <path
                    d="M88.7 190.1h1c9.5-.2 17.1-3.5 22.8-9.9 12.5-14 10.4-38.1 10.2-40.4-.8-17.2-9-25.5-15.7-29.3-5-2.9-10.9-4.4-17.4-4.6H89c-3.6 0-10.6.6-17.4 4.4-6.8 3.8-15.1 12.1-15.9 29.5-.2 2.3-2.3 26.4 10.2 40.4 5.6 6.4 13.3 9.7 22.8 9.9zm-24.3-49.5v-.3c1.1-23.2 17.5-25.7 24.6-25.7h.4c8.7.2 23.6 3.8 24.6 25.7v.3c0 .2 2.3 22.2-8 33.8-4.1 4.6-9.5 6.9-16.7 6.9H89c-7.1-.1-12.6-2.3-16.6-6.9-10.3-11.5-8.1-33.6-8-33.8z"
                    className="st0"
                  ></path>
                  <path
                    d="M155.7 230v-.9c-.2-6.4-.6-21.4-14.7-26.2-.1 0-.2-.1-.3-.1-14.6-3.7-26.7-12.1-26.8-12.2-2-1.4-4.7-.9-6.1 1.1s-.9 4.7 1.1 6.1c.5.4 13.4 9.3 29.5 13.5 7.5 2.7 8.4 10.7 8.6 18.1v.8c0 2.9-.2 7.4-.7 10-5.2 3-25.8 13.3-57 13.3-31.1 0-51.8-10.3-57.1-13.3-.5-2.6-.7-7.1-.7-10v-.8c.2-7.4 1.1-15.4 8.6-18.1 16.1-4.1 29-13.1 29.5-13.5 2-1.4 2.5-4.1 1.1-6.1s-4.1-2.5-6.1-1.1c-.1.1-12.2 8.5-26.8 12.2-.1 0-.2.1-.3.1-14 4.8-14.5 19.8-14.7 26.2v.9c0 1.7-.1 10.3 1.6 14.7.3.8.9 1.6 1.7 2 1 .6 24.2 15.5 63.1 15.5s62.2-14.8 63.1-15.5c.7-.5 1.4-1.2 1.7-2 1.7-4.4 1.7-13 1.7-14.7zM393 185.8h1c9-.2 16.3-3.3 21.6-9.4 11.8-13.3 9.9-36.2 9.6-38.4-.8-16.4-8.5-24.2-14.9-27.9-4.8-2.7-10.3-4.2-16.5-4.3h-.5c-3.4 0-10.1.6-16.5 4.2-6.4 3.7-14.3 11.5-15.1 28-.2 2.2-2.2 25 9.6 38.4 5.5 6.1 12.7 9.3 21.7 9.4zm-23-46.9v-.2c1-22 16.6-24.4 23.3-24.4h.4c8.3.2 22.4 3.6 23.3 24.4v.2c0 .2 2.2 21.1-7.6 32.1-3.9 4.4-9 6.5-15.8 6.6h-.3c-6.8-.1-11.9-2.2-15.8-6.6-9.6-11-7.5-32-7.5-32.1z"
                    className="st0"
                  ></path>
                  <path
                    d="M456.6 223.7v-.9c-.2-6.1-.6-20.3-13.9-24.8-.1 0-.2-.1-.3-.1-13.8-3.5-25.4-11.5-25.5-11.6-1.9-1.3-4.5-.9-5.8 1-1.3 1.9-.9 4.5 1 5.8.5.4 12.7 8.9 28 12.8 7.2 2.5 8 10.2 8.2 17.2v.8c0 2.8-.2 7-.6 9.5-5 2.8-24.5 12.6-54.1 12.6-29.5 0-49.2-9.8-54.2-12.6-.5-2.5-.7-6.7-.6-9.5v-.8c.2-7 1-14.6 8.2-17.2 15.3-3.9 27.5-12.5 28-12.8 1.9-1.3 2.3-3.9 1-5.8-1.3-1.9-3.9-2.3-5.8-1-.1.1-11.6 8.1-25.5 11.6-.1 0-.2.1-.3.1-13.3 4.6-13.7 18.8-13.9 24.8v.9c0 1.6-.1 9.8 1.6 13.9.3.8.9 1.5 1.6 1.9.9.6 23 14.7 59.9 14.7s59-14.1 59.9-14.7c.7-.5 1.3-1.1 1.6-1.9 1.6-4.1 1.6-12.3 1.5-13.9z"
                    className="st0"
                  ></path>
                </svg>{' '}
                Team: {projectTeam.team.name}
              </div>
            )
          })}
        {props.user && props.user.isAdmin && (
          <div
            className="project-author"
            style={{ marginRight: '1rem' }}
            onMouseEnter={() => setState({ isCostEstimateHovered: true })}
            onMouseLeave={() => setState({ isCostEstimateHovered: false })}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 288 512">
              <path d="M209.2 233.4l-108-31.6C88.7 198.2 80 186.5 80 173.5c0-16.3 13.2-29.5 29.5-29.5h66.3c12.2 0 24.2 3.7 34.2 10.5 6.1 4.1 14.3 3.1 19.5-2l34.8-34c7.1-6.9 6.1-18.4-1.8-24.5C238 74.8 207.4 64.1 176 64V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48h-2.5C45.8 64-5.4 118.7.5 183.6c4.2 46.1 39.4 83.6 83.8 96.6l102.5 30c12.5 3.7 21.2 15.3 21.2 28.3 0 16.3-13.2 29.5-29.5 29.5h-66.3C100 368 88 364.3 78 357.5c-6.1-4.1-14.3-3.1-19.5 2l-34.8 34c-7.1 6.9-6.1 18.4 1.8 24.5 24.5 19.2 55.1 29.9 86.5 30v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-48.2c46.6-.9 90.3-28.6 105.7-72.7 21.5-61.6-14.6-124.8-72.5-141.7z"/>
            </svg>{' '}
            Cost Estimate
            {state.isCostEstimateHovered && <CostCard projectId={project.id} isAdmin={true} />}
          </div>
        )}
      </article>
    </div>
  )
}

const NoProjectsFound = (props) => (
  <div className="no-results">
    <NoProjectsIcon />
    <div className="no-result-message">
      <h3 className="title">Sorry, no projects found.</h3>
      {props.userIsVerified && (
        <div>
          <p className="status-message">
            We couldn't find any projects that match your search. <br />
            Create a new project to begin flying with Spexi.
          </p>
          <p>
            <Link className="btn white" to="/add-project">
              Create new project
            </Link>
          </p>
        </div>
      )}
      {!props.userIsVerified && (
        <p className="status-message">Please verify your account via email to gain access to projects.</p>
      )}
    </div>
  </div>
)
