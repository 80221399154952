import React, { Component } from 'react'
import PageHeading from '../components/PageHeading/PageHeading'
class TermsContainer extends Component {
  render() {
    return (
      <div className="App">
        <div className="page-wrap">
          <PageHeading
            title="Terms of Use"
            bgImage="/assets/img/contact.jpg"
            backgroundPosition="50% 0"
            smallHeader={true}
          />

          <section className="panel basic-page">
            <div className="container">
              <div className="inner-wrap">
                <h1>Spexigeo Platform Terms of Use</h1>
                <p>Last Updated: March 12 2024</p>
                <p>
                  The following terms of use (the “<strong>Terms of Use</strong>
                  ”) govern your access to and use of: (a) our website located
                  at fly.spexigeo.com (the “<strong>Website</strong>”); (b)
                  Spexi mobile device software provided by us (the “
                  <strong>Mobile App</strong>”); (c) any text, pictures, media,
                  data, information, content, records, files or other materials
                  (collectively, the “<strong>Content</strong>”) contained on or
                  provided through the foregoing (a) and (b); and (d) all other
                  content, products or services provided by us to you, as more
                  particularly described on the Website and Mobile App. The
                  Website, Mobile App, and Content are collectively referred to
                  as the “<strong>Spexigeo Platform</strong>”.
                </p>
                <p>
                  These Terms of Use form an agreement between the owners of the
                  Spexigeo Platform, Spexi Geospatial Inc. (“
                  <strong>Spexi Geospatial</strong>”, “<strong>us</strong>”, “
                  <strong>we</strong>”, “<strong>our</strong>”) and you. The
                  term “<strong>you</strong>” or “<strong>User</strong>” refers
                  to the person browsing, installing, downloading, accessing or
                  otherwise using the Spexigeo Platform (“<strong>use</strong>”
                  or “<strong>using</strong>” in these Terms of Use will mean
                  any of the foregoing).
                </p>
                <p>
                  By using the Spexigeo Platform in any way, including any
                  services we provide on or through the platform (the “
                  <strong>Services</strong>”), you:
                </p>
                <p>
                  (A) REPRESENT AND WARRANT THAT (I) YOU HAVE REACHED THE AGE OF
                  MAJORITY IN YOUR JURISDICTION, (II) IF YOU ARE USING THE
                  SPEXIGEO PLATFORM IN THE UNITED STATES, YOU RESIDE IN THE
                  UNITED STATES OR ANY OF ITS TERRITORIES OR POSSESSIONS, (III)
                  YOU HAVE THE CAPACITY TO ENTER INTO BINDING OBLIGATIONS, (IV)
                  YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY OUR PRIVACY
                  POLICY, FOUND AT
                  <a href="HTTPS://WWW.SPEXI.COM/PRIVACY-POLICY">
                    HTTPS://WWW.SPEXI.COM/PRIVACY-POLICY
                  </a>
                  AND (III) ALL INFORMATION SUPPLIED BY YOU TO US THROUGH THE
                  PLATFORM IS TRUE, ACCURATE, CURRENT, AND COMPLETE; AND
                </p>
                <p>
                  (B) AGREE TO BE BOUND BY AND COMPLY WITH THESE TERMS OF USE,
                  AS UPDATED FROM TIME TO TIME IN ACCORDANCE WITH SECTION ‎3.
                </p>
                <p>
                  IF YOU ARE USING THE PLATFORM ON BEHALF OF ANOTHER PERSON OR
                  ORGANIZATION, YOU REPRESENT AND WARRANT THAT YOU HAVE THE
                  AUTHORITY TO BIND SUCH PERSON OR ORGANIZATION TO THESE TERMS
                  OF USE.
                </p>
                <p>
                  YOU MAY NOT ACCESS THE SPEXIGEO PLATFORM FOR PURPOSES OF
                  MONITORING AVAILABILITY, PERFORMANCE OR FUNCTIONALITY, OR FOR
                  ANY OTHER BENCHMARKING OR COMPETITIVE PURPOSES.
                </p>
                <p>
                  These Terms of Use do not alter in any way the terms or
                  conditions of any other agreement you may have with us in
                  respect of any services, products, applications or otherwise,
                  you enter into with us.
                </p>
                <p>
                  <h3 name="_Ref126946287">
                    1. The Spexigeo Platform and Services
                  </h3>
                </p>
                <p>
                  (a) The Spexigeo Platform is a cloud-based platform that
                  allows users to capture, process, analyze, and share aerial
                  imagery captured by drones. Using the web-based “
                  <strong>Flight Dashboard</strong>”, Users can plan drone
                  flights, then use the Mobile App to fly planned flights and
                  capture aerial imagery of the flight and produce geospatial
                  data. Users may also upload aerial imagery to the Spexigeo
                  Platform to produce such geospatial data.
                </p>
                <p>
                  (b) <u>Compatible Drones</u>. The Spexigeo Platform is
                  designed to interface solely with those Drones as are listed
                  on the Website from time to time. Changes, including
                  modifications and upgrades, to Drones made after the date of
                  last update of the compatible drone list may affect the
                  interaction of the Spexigeo Platform with such Drones and the
                  Spexigeo Platform may no longer effectively operate with such
                  Drones following such changes. “<strong>Drone</strong>” means
                  technology (such as unmanned aerial vehicles (otherwise
                  referred to as RPAS, UAVs, multi-rotors, drones and similar)
                  and their transmitters and receivers) provided by third
                  parties, including third-party hardware and third-party
                  software.
                </p>
                <h3>2. Types of Users</h3>
                <p>
                  (a) These Terms of Use apply to Users accessing the Spexigeo
                  Platform to plan and pilot flights using Drones (“
                  <strong>Pilots</strong>”). Subject to and conditioned on your
                  compliance with the terms and conditions in these Terms of Use
                  and Privacy Agreement
                  <a href="https://www.spexi.com/privacy-policy">
                    https://www.spexi.com/privacy-policy
                  </a>
                  , we hereby grant you a non-exclusive, non-transferable right
                  to access and use the Services, solely for use by you in
                  accordance with the terms and conditions herein.
                </p>
                <p>
                  <h3 name="_Ref135131672">
                    3. Changes to these Terms of Use and the Spexigeo Platform
                  </h3>
                </p>
                <p>
                  (a) To the extent permitted by law (“
                  <strong>Applicable Law</strong>”), we reserve the right to:
                </p>
                <p>
                  (i) change any element of these Terms of Use at any time. When
                  we change these Terms of Use, we will: (A) place a notice on
                  the Spexigeo Platform, send you an email and/or notify you by
                  some other means as required by Applicable Law; (B) post a new
                  version to the Spexigeo Platform; and (C) update the “Last
                  Updated” date at the top of these Terms of Use. We may require
                  you to provide consent to the updated Terms of Use in a
                  specified manner before further use of the Spexigeo Platform
                  is permitted. If you do not agree to any change(s) after
                  receiving a notice of such change(s), you agree to stop using
                  the Spexigeo Platform. Otherwise, your continued access to or
                  use of the Spexigeo Platform after any changes to these Terms
                  of Use indicates your acceptance of such changes. All changes
                  are effective immediately when we post them, and apply to all
                  access to and use of the Spexigeo Platform thereafter.
                  However, any changes to the dispute resolution provisions set
                  out in Section ‎22 and ‎23will not apply to any disputes for
                  which the parties have actual notice on or before the date the
                  change is posted on the Spexigeo Platform.
                </p>
                <p>
                  (ii) change any element of the Spexigeo Platform at any time,
                  without notice. We may, at our sole discretion, suspend or
                  terminate your access to or use of the Spexigeo Platform or
                  Services or any component thereof: (A) for scheduled
                  maintenance; (B) due to a force majeure event; (C) if we
                  believe that you have violated any provision of these Terms of
                  Use or any other use guidelines we provide to you or are
                  posted on the Website; (D) to address any emergency security
                  concerns; or (E) if required to do so by a regulatory body or
                  as a result of a change in Applicable Law.
                </p>
                <p>
                  (iii) make any modifications or discontinue all or any portion
                  of the Spexigeo Platform at any time (including by limiting or
                  discontinuing certain features the Spexigeo Platform),
                  temporarily or permanently, without notice to Users. We will
                  have no liability for any change to the Spexigeo Platform,
                  including any paid-for functionalities of the Spexigeo
                  Platform, or any suspension or termination of Users’ access to
                  or use of the Spexigeo Platform.
                </p>
                <p>
                  (iv) require you to update third-party software from time to
                  time to use the Spexigeo Platform.{' '}
                </p>
                <p>
                  (v) engage third parties, including cloud service providers,
                  to provide the Spexigeo Platform or the Services.
                </p>
                <p>
                  <h3>4. User Account</h3>
                </p>
                <p>
                  (a) To access certain features of the Spexigeo Platform, you
                  may be required to sign up for a user account and select a
                  username and password login credentials (the “
                  <strong>User ID</strong>”).
                </p>
                <p>
                  (b) You must keep your User ID secure and agree that you will
                  not grant access to or otherwise share your User ID with any
                  other person.
                </p>
                <p>
                  (c) You must provide us with true, accurate, current and
                  complete information for your User ID. If we believe or
                  suspect that your information is not true, accurate, current
                  or complete, we may deny or terminate your access to the
                  Spexigeo Platform.
                </p>
                <p>
                  (d) We reserve the right to disable any User ID issued to you
                  at any time in our sole discretion. If we disable access to a
                  User ID issued to you, you may be prevented from accessing the
                  Spexigeo Platform (or any portion thereof).
                </p>
                <p>
                  (e) We are entitled to act on instructions received through
                  your account. We are not responsible for any actions taken or
                  transactions made to or from your account by any other party
                  using your User ID. You are solely responsible for all use of
                  your User ID and all activities that occur under or in
                  connection with the User ID. Without limiting any rights which
                  we may otherwise have, we reserve the right to take any and
                  all action, as we deem necessary or reasonable, to ensure the
                  security of the Spexigeo Platform and your account, including
                  terminating your account, changing your password, or
                  requesting additional information to authorize transactions on
                  your account. You agree to be responsible for any act or
                  omission of any users that access the Spexigeo Platform under
                  your User ID, including those that, if undertaken by you,
                  would be deemed a violation of these Terms of Use.
                </p>
                <h3>5. Fees</h3>
                <p>
                  (a) <u>Subscriptions and Credits</u>.
                </p>
                <p>
                  (i) Subscriptions. Some of the Services or features thereof
                  may be offered through a subscription. These Terms of Use will
                  govern the placement of any order you make through the
                  Spexigeo Platform for a subscription to such paid Services
                  (each such order, a “<strong>Subscription Order</strong>”). We
                  will confirm your request by sending an email to the email
                  address you have provided. Your placement of a Subscription
                  Order through the Spexigeo Platform is an offer to purchase
                  the Services ordered, and we may accept your Subscription
                  Order by providing a subscription agreement (the “
                  <strong>Subscription Agreement</strong>”) for your acceptance
                  and processing your payment through the Spexigeo Platform.
                  Your receipt of an electronic or other form of Subscription
                  Order confirmation does not signify our acceptance of your
                  Subscription Order, nor does it constitute confirmation of our
                  offer to sell. For any reason, we may decline to accept your
                  Subscription Order or any part of your Subscription Order. No
                  Subscription Order will be considered accepted by us until the
                  applicable Subscription Agreement has been accepted by us and
                  your payment has been processed. If (a) we decline to accept
                  your Subscription Order, (b) one or more elements of your
                  Subscription Order are unavailable, or (c) your Subscription
                  Order cannot be fulfilled for any reason in our discretion, we
                  will attempt to notify you as soon as practicable at the email
                  address you provided. We may require additional verifications
                  or information before considering any Subscription Order.
                </p>
                <p>
                  (ii) We may also make the Services or features thereof
                  available through the purchase of credits (“
                  <strong>Credit Orders</strong>”). Credits may only be redeemed
                  on the Spexigeo Platform, have no cash value, are
                  non-transferrable and non-refundable. Credits do not expire
                  and can be converted into a subscription at any time.
                  <strong>
                    For the avoidance of doubt, the credits do not operate or
                    serve as stored value facilities in any way.
                  </strong>
                  Purchases and subscriptions will be subject to additional
                  sales terms made available to you at the time of purchase.
                </p>
                <p>
                  (b) <u>Service Fees</u>. Some of the Services or features
                  thereof may be provided with associated fees (“
                  <strong>Service Fees</strong>”). Such Service Fees will be
                  presented to you when you use the Spexigeo Platform to access
                  such paid Services. We may change the Service Fees from time
                  to time by posting the changes on the Spexigeo Platform.
                </p>
                <p>
                  (c) To pay for a Subscription Order, Credit Order or Service
                  Fees (together, the “<strong>Platform Fees</strong>"), you may
                  need to provide us or, if applicable, a third-party payment
                  processor (the “<strong>Payment Processor</strong>”) with the
                  information necessary to process payment from you, including
                  the billing information requested on the Spexigeo Platform or
                  the applicable Payment Processor's platform. The processing of
                  payments will be subject to the terms, conditions and privacy
                  policies of the Payment Processor, if any, in addition to
                  these Terms of Use. We are not responsible for any error by,
                  or other acts or omissions of, any Payment Processor. You may
                  pay the Platform Fees via credit card or any other manner then
                  available on the Spexigei Platform or applicable Payment
                  Processor's platform. By submitting your payment information
                  to us or the Payment Processor, you authorize us or the
                  Payment Processor to charge the applicable payment method at
                  our or their convenience (but within 30 days of credit card
                  authorization). You represent and warrant that you will not
                  use any credit card or other form of payment unless you have
                  all necessary authorization to do so. We assume that because
                  Platform Fees payments require a valid credit card, only
                  persons aged 18 or over are providing us and/or the Payment
                  Processor with the information requested during the payment
                  process. We and/or the Payment Processor are not liable in the
                  event your children or others acting with or without your
                  permission use your credit card or other means of payment to
                  make purchases on the Spexigeo Platform or the Payment
                  Processor's platform (and to the extent your minor children
                  make any such purchases, you hereby represent and warrant that
                  they are authorized to do so); however, you may report any
                  unauthorized use to us or the Payment Processor, and we and/or
                  the Payment Processor will use reasonable measures within our
                  control to help prevent future unauthorized use of your card.
                  We reserve the right to correct any errors or mistakes that
                  any Payment Processor makes even if it has already requested
                  or received payment.
                </p>
                <p>
                  (i) The terms of your payment will be based on your chosen
                  payment provider and may be determined by agreements between
                  you and the financial institution, credit card issuer or other
                  provider of your chosen payment method. If we, either through
                  the Payment Processor or otherwise, do not receive payment
                  from you, you agree to pay all amounts due on your billing
                  account upon demand.
                </p>
                <p>
                  (ii) You must provide current, complete and accurate
                  information for your billing account. You must promptly update
                  all information to keep your billing account current, complete
                  and accurate (such as a change in billing address, credit card
                  number, or credit card expiration date), and you must promptly
                  notify us if your payment method is cancelled (e.g., for loss
                  or theft) or if you become aware of a potential breach of
                  security, such as the unauthorized disclosure or use of your
                  user name or password. Changes to such information can be made
                  in your account settings. If you fail to provide any of the
                  foregoing information, you agree that we may continue charging
                  you for any use of paid services under your billing account
                  unless you have terminated your paid services in accordance
                  with any applicable Subscription Agreement.
                </p>
                <p>
                  (iii) If the amount to be charged to your billing account
                  varies from the amount you preauthorized (other than due to
                  the imposition or change in the amount of applicable sales
                  taxes), you have the right to receive, and we will provide,
                  notice of the amount to be charged and the date of the charge
                  before the scheduled date of the transaction. Any agreement
                  you have with your payment provider will govern your use of
                  your payment method of choice. You agree that we may
                  accumulate charges incurred and submit them as one or more
                  aggregate charges during or at the end of each billing cycle.
                </p>
                <p>
                  (d) <u>Pricing and Services</u>. Subscription and Services
                  options displayed on the Spexigeo Platform may be discontinued
                  or unavailable for any other reason, and prices are subject to
                  change. Except where otherwise indicated, all prices do not
                  include any applicable sales taxes, which will be added to
                  your total purchase price. You are responsible for the payment
                  of any provincial and local sales or use taxes that may apply
                  to the Platform Fees. All Platform fees are non-refundable. We
                  will provide you no less than 7 days' notice prior to
                  implementing a change to our prices.
                </p>
                <h3>6. Promotional Offers</h3>
                <p>
                  (a) We may run promotional offers from time to time on the
                  Spexigeo Platform. The terms of any such promotion will be
                  posted on the Spexigeo Platform. Unless otherwise indicated,
                  we may establish and modify, in our sole discretion, the terms
                  of such offer and end such offer at any point. Promotional
                  offers may not be available in your jurisdiction.
                </p>
                <h3>7. Privacy</h3>
                <p>
                  (a) You acknowledge that you have reviewed our current Privacy
                  Policy, available at
                  <a href="https://www.spexi.com/privacy-policy">
                    https://www.spexi.com/privacy-policy
                  </a>
                  , which contains important information about our practices in
                  collecting, storing, using and disclosing information about
                  identifiable individuals (“
                  <strong>Personal Information</strong>”). You further agree
                  that all information you provide to register with the Spexigeo
                  Platform or otherwise, including, but not limited to, through
                  the use of any interactive features on the Website or Mobile
                  App, is governed by our Privacy Policy, and you consent to all
                  actions we take with respect to your information consistent
                  with our Privacy Policy
                </p>
                <p>8. Electronic Communications</p>
                <p>
                  (a) When you use or view the Spexigeo Platform or send
                  e-mails, texts or other electronic messages to us, you are
                  communicating with us electronically and you consent to
                  receive communications from us electronically. We will
                  communicate with you by e-mail, by text message or by posting
                  notices on the Spexigeo Platform. You agree that all
                  agreements, notices, disclosures and other communications that
                  we provide to you electronically satisfy any legal requirement
                  that such communications be in writing.
                </p>
                <p>
                  (b) If you create an account to access the Spexigeo Platform,
                  you acknowledge that we will send you service-related e-mails
                  relating to your account, including service updates. Your
                  consent to receive communications and do business
                  electronically, and our agreement to do so, applies to all
                  your interactions and transactions with us. Please keep us
                  informed of any changes in your email or mailing address so
                  that you continue to receive all communications without
                  interruption.
                </p>
                <p>
                  (c) If you are a member of our mailing list you will also
                  receive email communications from us regarding our products,
                  services and initiatives (including collaborations and
                  partnerships). If you do not wish to receive these
                  communications, you can unsubscribe from such promotional
                  e-mails at any time by clicking on the unsubscribe link in any
                  of our e-mail communications. Users who unsubscribe from
                  receiving such promotional communications will continue to
                  receive certain transactional and/or account-related
                  communications.
                </p>
                <p>
                  (d) By using the Spexigeo Platform, you acknowledge and agree
                  that we will be transmitting certain of your Personal
                  Information electronically.
                </p>
                <p>
                  (e) You acknowledge and agree that you are solely responsible
                  for providing us with accurate contact information, including
                  your mobile device number and email address, where we may send
                  communications in accordance with these Terms of Use and our
                  Privacy Policy.
                </p>
                <p>
                  <h3>9. User Data and Aerial Imagery</h3>
                </p>
                <p>
                  (a) <u>License to User Data</u>. You grant us an irrevocable,
                  nonexclusive, worldwide, royalty-free, transferable,
                  sublicensable, and fully paid-up right to access, collect,
                  use, process, store, disclose and transmit, other than the
                  Aerial Imagery, any text, pictures, media, data, information,
                  content records or files or other materials that you load,
                  submit, transmit to or enter into the Spexigeo Platform, or
                  that you otherwise transmit to the Spexigeo Platform in
                  connection with these Terms of Use or the Services (“
                  <strong>User Data</strong>”) to: (i) provide you with access
                  to the Spexigeo Platform; (ii) exercise our rights and perform
                  our obligations under these Terms of Use, including ensuring
                  you are complying with these Terms of Use and any other use
                  guidelines we provide to you or are posted on the Spexigeo
                  Platform; and (iii) improve and enhance the Spexigeo Platform
                  and our other offerings and benchmark the foregoing and for
                  other purposes to which you consent or are permitted under
                  these Terms of Use
                </p>
                <p>
                  (b) <u>Aerial Imagery</u>. When you use the Spexigeo Platform,
                  you may upload footage captured by a Drone to the Spexigeo
                  Platform (the “<strong>Aerial Imagery</strong>”). You grant us
                  an irrevocable, nonexclusive, worldwide, royalty-free,
                  transferable, sublicensable, and fully paid-up right to
                  access, collect, use, copy, distribute, process, store,
                  disclose, transmit and prepare derivative works (including
                  Geospatial Products) of and commercially exploit Aerial
                  Imagery you capture through or upload to the Spexigeo
                  Platform. “<strong>Geospatial Products</strong>” means the
                  content generated by Spexi Geospatial through the Spexigeo
                  Platform by processing and transforming Aerial Imagery,
                  including panoramas, orbits, deep zoom image galleries,
                  orthomosaic maps, digital elevation models, 3D models,
                  computer vision algorithms and 3D point clouds. We retain all
                  right, title and interest in Geospatial Products. If you
                  choose to remove your Aerial Imagery from the Spexigeo
                  Platform, such removal will not affect the Geospatial Products
                  already derived from your Aerial Imagery.
                </p>
                <p>
                  <h3>10. Ownership of the Spexigeo Platform</h3>
                </p>
                <p>
                  (a) The Website and its entire contents, features, and
                  functionality (including but not limited to all information,
                  software, text, displays, images, video, and audio, and the
                  design, selection and arrangement thereof) are owned by Spexi
                  Geospatial, its licensors, or other providers of such material
                  and are protected by United States and international
                  copyright, trademark, patent, trade secret, and other
                  intellectual property or proprietary rights laws (“
                  <strong>Intellectual Property Rights</strong>”). These Terms
                  of Use permit you to use the Spexigeo Platform for your
                  personal, non-commercial use only, or for limited and
                  legitimate business purposes in the context of the Services.
                </p>
                <p>
                  (b) All right, title and interest, including Intellectual
                  Property Rights, in the Spexigeo Platform, the source code in
                  the software we use to provide the Spexigeo Platform (the “
                  <strong>Software</strong>”) and all other Content (including
                  Geospatial Products) and materials provided by us hereunder,
                  and any updates, adaptation, translation, customization or
                  derivative works thereof, will remain the sole property of
                  Spexi Geospatial (or our affiliates, licensors or third-party
                  suppliers, if applicable).
                </p>
                <p>
                  (c) The Spexigeo Platform and all Content and materials
                  provided by us hereunder are made available or licensed and
                  not “sold” to you.
                </p>
                <p>
                  (d) Subject to these Terms of Use, we grant you a
                  non-exclusive, non-transferable, non-sublicensable and
                  revocable license to use the Spexigeo Platform, including the
                  installation of the Mobile App, in accordance with these Terms
                  of Use.
                </p>
                <p>
                  (e) The Software and all other Content and materials provided
                  by us hereunder, including content we make available through
                  or in the Spexigeo Platform, are protected by copyright in
                  Canada. You are prohibited from modifying, copying,
                  reproducing, publishing, posting, transmitting, distributing,
                  creating derivative works from, decompiling, transferring or
                  selling any of the Spexigeo Platform, the Software or other
                  materials provided by us hereunder, or sharing or granting
                  access in any of the foregoing to any third party for any
                  purpose.
                </p>
                <p>
                  (f) Any use of third-party software provided in connection
                  with the Spexigeo Platform will be governed by such third
                  parties’ licenses and not by these Terms of Use;
                </p>
                <p>
                  (g) “Spexi” is the trade name and mark of Spexi Geospatial
                  Inc. or our affiliates/licensors. Any trademarks, graphics or
                  logos appearing in or on the Spexigeo Platform are the
                  exclusive property of Spexi Geospatial (or its third-party
                  suppliers) and may not be used in any manner without our
                  express written consent.
                </p>
                <p>
                  (h) All rights not expressly granted to you in these Terms of
                  Use are reserved by us.
                </p>
                <p>
                  <h3>11. Additional Terms</h3>
                </p>
                <p>
                  (a) Your access to and use of certain functionalities provided
                  in or through the Spexigeo Platform may be subject to
                  additional terms and conditions presented to you by us or our
                  service providers. Such additional terms and conditions are
                  incorporated herein by reference. If you do not purchase such
                  additional functionalities or do not agree to such additional
                  terms, you may not have access to, and you should not access
                  or use, those functionalities.
                </p>
                <p>
                  (b) These Terms of Use relate to the Spexigeo Platform only
                  and do not alter in any way the terms or conditions of any
                  other agreement that may apply to your use of the Spexigeo
                  Platform or the Services. For greater certainty, if there is a
                  conflict or inconsistency between the terms and conditions of
                  another written agreement you enter into with us and these
                  Terms of Use, then the provisions of that other agreement will
                  govern to the extent of such conflict or inconsistency.
                </p>
                <h3>12. Your Responsibilities</h3>
                <p>
                  (a) <u>Operation of Drones</u>. The Spexigeo Platform permits
                  the downloading of imagery from the Third-Party Drone
                  Technologies. Pilots are solely responsible for assessing the
                  suitability of the environment in which the Drone is to be
                  operated, taking into account all risk and environmental
                  factors (including risk to individuals and property, risk to
                  User, risk to environment and risk to the Drone). PILOTS
                  SHOULD AT ALL TIMES MONITOR AND CONTROL ANY DRONE THAT THEY
                  ARE USING TO ADDRESS ANY RISK THAT MAY ARISE DUE TO
                  ENVIRONMENTAL CONDITIONS, THE DRONE, OR ANY EXTERNAL FACTOR
                  (including wildlife, wind, rain, geographical features or
                  others). The Spexigeo Platform is intended to be used by
                  experienced users of Drones who have received training in
                  using such Drone safely. Pilots should not use the Spexigeo
                  Platform to operate Drones if they are not properly trained
                  and qualified to fly any Drones.
                </p>
                <p>
                  (b) <u>Liability for use of Drones</u>. Without limitation,
                  and notwithstanding any other provision of this Agreement,
                  Spexi Geospatial assumes no responsibility for any loss,
                  damage, injury or death due to the misuse of the Spexigeo
                  Platform, lack of compliance by the Pilot with these Terms of
                  Use, or caused by any Drone. Pilot assumes all liability for
                  damages to property and persons arising from any Drone which
                  is used in connection with the Spexigeo Platform. Pilot
                  assumes all liability for improper use of Drones. Pilot is
                  responsible for proper configuration and maintenance of the
                  Drone. Drones are inherently dangerous and should not be used
                  by anyone without professional training and experience. It is
                  the responsibility of the Pilot to decide if the Drone is
                  suitable for its intended use.
                </p>
                <p>
                  (c){' '}
                  <u>
                    Spexi Geospatial Not Responsible for Operation of Drones
                  </u>
                  . The Spexigeo Platform may contain checklists to help
                  highlight best practices, but we do not represent or warrant
                  that these checklists or any other information or prompt
                  provided on the Spexigeo Platform is accurate or complete. As
                  set forth in Sections ‎18 (Disclaimer) and ‎19 (Limitation of
                  Liability), Spexi Geospatial is not responsible for any losses
                  or damages relating to your use of the Spexigeo Platform,
                  including issues that may be encountered while piloting a
                  flight path planned using the Flight Dashboard.
                </p>
                <p>
                  (d) <u>Aerial Imagery</u>. When you capture Aerial Imagery
                  through or upload Aerial Imagery to the Spexigeo Platform, you
                  represent and warrant that you own all intellectual property
                  rights in the Aerial Imagery and that uploading such Aerial
                  Imagery and the provision of the license set forth in Section
                  ‎9(b) to such Aerial Imagery does not violate any third
                  party’s intellectual property rights. Furthermore, you
                  represent and warrant that the Aerial Imagery is accurate and
                  authentic.
                </p>
                <p>
                  (e) <u>Using the Spexigeo Platform</u>. You agree to:
                </p>
                <p>
                  (i) use reasonable efforts to prevent unauthorized access to
                  or use of the Spexigeo Platform;
                </p>
                <p>
                  (ii) keep your User IDs and all other login information
                  confidential;
                </p>
                <p>
                  (iii) not register for more than one account except where such
                  accounts are authorized and used on behalf of different
                  organizations, register for an account on behalf of a person
                  (either an individual, group or entity) without such person’s
                  authorization, or register for a new account after your
                  previous account has been terminated;
                </p>
                <p>
                  (iv) monitor and control all activity conducted through your
                  account in connection with the Spexigeo Platform;
                </p>
                <p>
                  (v) upload and disseminate only data to which you own all
                  required rights under law and do so only consistent with
                  Applicable Law;
                </p>
                <p>
                  (vi) promptly notify us if you become aware or reasonably
                  suspect any illegal or unauthorized activity or a security
                  breach involving your account, including any loss, theft, or
                  unauthorized disclosure or use of a User ID or account;
                </p>
                <p>
                  (vii) not use anyone else’s User ID at any time, without the
                  permission of the User ID holder;
                </p>
                <p>
                  (viii) not attempt, in any manner, to obtain the password,
                  account, or other security information from any other user;
                  and
                </p>
                <p>
                  (ix) comply with all Applicable Law, including all
                  intellectual property, data and privacy laws. Except as
                  authorized by applicable law, you agree not to export,
                  re-export or transfer the Spexigeo Platform or any part
                  thereof to any country, person, entity or end user subject to
                  applicable export controls or sanctions. For greater
                  certainty, you are responsible for complying with all
                  applicable trade restrictions, guidelines, regulations and
                  laws both foreign and domestic.
                </p>
                <h3>13. No Unlawful or Prohibited Use</h3>
                <p>
                  (a) You will not use the Spexigeo Platform in violation of
                  these Terms of Use, the Documentation or any other use
                  guidelines we provide to you or are posted on the Spexigeo
                  Platform, or of any Applicable Law, or in the case of
                  third-party materials, websites or content accessed through or
                  provided with the Spexigeo Platform, the applicable
                  third-party license agreement. You will not, without our prior
                  written permission, use the Spexigeo Platform for any purpose
                  other than to access and use the Software and Services we make
                  available through the Spexigeo Platform. Without limiting the
                  generality of the foregoing, you further represent, warrant
                  and covenant that you will not (and will not attempt to)
                  directly or indirectly:
                </p>
                <p>
                  (i) disable, overly burden, impair, or otherwise interfere
                  with servers or networks connected to the Spexigeo Platform
                  (e.g., a denial-of-service attack);
                </p>
                <p>
                  (ii) attempt to gain unauthorized access to the Spexigeo
                  Platform, or bypass any measures we may use to prevent or
                  restrict access to the Spexigeo Platform, attempt to
                  circumvent the intended features, functionality or limitations
                  of the Spexigeo Platform, or otherwise use, copy, distribute,
                  or make available the Spexigeo Platform to permit timesharing,
                  service bureau use or commercially exploit the Spexigeo
                  Platform;
                </p>
                <p>
                  (iii) send, upload, collect, transmit, store, use, post,
                  publish, or otherwise communicate on the Spexigeo Platform any
                  data, information, pictures, videos, audio or other materials
                  or content that (“<strong>User Contributions</strong>”): (A)
                  contains any computer viruses, worms, malicious code, or any
                  software intended to damage or alter a computer system or
                  data; (B) you do not have the lawful right to send, upload,
                  collect, transmit, store, use, post, publish, or otherwise
                  communicate; (C) is false, intentionally misleading, or
                  impersonates any other person; (D) is libelous, slanderous,
                  defamatory, bullying, harassing, abusive, threatening, vulgar,
                  obscene, or offensive, or that contains pornography, nudity,
                  graphic or gratuitous violence or political propaganda, or
                  that promotes violence, racism, discrimination, bigotry,
                  hatred, or physical harm of any kind against any group or
                  individual; (E) is harmful to minors in any way or targeted at
                  minors; (F) infringes, violates or otherwise misappropriates
                  the intellectual property or other rights of any third party
                  (including any moral right, privacy right or right of
                  publicity); or (G) encourages any conduct that may violate any
                  applicable laws or would give rise to civil or criminal
                  liability; (H) discloses or provides information protected
                  under any law, agreement or fiduciary relationship, including
                  proprietary or confidential information of others; or (I)
                  contains information about an identifiable individual, other
                  than as required by us: (Y) to provide support services as
                  further set out on the Website; or (Z) as part of our
                  onboarding process.
                </p>
                <p>
                  (iv) use the Spexigeo Platform in a manner which, in our
                  opinion, would tend to bring the Spexigeo Platform, Spexi
                  Geospatial or any of our trademarks into public disrepute,
                  contempt, scandal or ridicule, would adversely affect Spexi
                  Geospatial’s reputation or goodwill or any of our trademarks,
                  or adversely affect the relationship between Spexi Geospatial
                  and any of our licensors or other users;
                </p>
                <p>
                  (v) use the Spexigeo Platform to store or transmit material
                  that is dangerous, harmful, fraudulent, deceptive,
                  threatening, harassing, defamatory, libelous, obscene, or
                  otherwise objectionable or unlawful;
                </p>
                <p>
                  (vi) use the Spexigeo Platform for any activities where the
                  use or failure of the Spexigeo Platform would reasonably be
                  expected to result in death, serious personal injury, or
                  severe environmental or property damage (such as the creation
                  or operation of weaponry);
                </p>
                <p>
                  (vii) use the Spexigeo Platform to transmit, store, or process
                  health or other sensitive personally identifiable information;
                </p>
                <p>
                  (viii) use any data mining, robots, or similar data gathering
                  or extraction methods, or copy, modify, reverse engineer,
                  reverse assemble, disassemble, or decompile the Spexigeo
                  Platform or any part thereof or otherwise attempt to discover
                  any source code;
                </p>
                <p>
                  (ix) remove or obscure any proprietary notices or labels on
                  the Spexigeo Platform, including brand, copyright, trademark
                  and patent or patent pending notices;
                </p>
                <p>
                  (x) use the Spexigeo Platform or the Content for the purpose
                  of building a similar or competitive product or service;
                </p>
                <p>
                  (xi) perform any vulnerability, penetration or similar testing
                  of the Spexigeo Platform;
                </p>
                <p>
                  (xii) advertise to, or solicit, any user to buy or sell any
                  third-party products or services, or use any information
                  obtained from the Spexigeo Platform to contact, advertise to,
                  solicit, or sell to any user without their prior express
                  consent;
                </p>
                <p>
                  (xiii) use the Spexigeo Platform or any part thereof to
                  distribute, promote or otherwise publish any material
                  containing any solicitation for funds, advertising or
                  solicitation for goods or services, promoting any website, or
                  use any paid advertising platform to promote links that direct
                  to the Spexi Geospatial domain or use the Spexi Geospatial
                  trademark;
                </p>
                <p>
                  (xiv) run Maillist, Listserv, any form of autoresponder or
                  “spam” on the Spexigeo Platform, or any processes that run or
                  are activated while you are not logged into the Spexigeo
                  Platform, or that otherwise interfere with the proper working
                  of the Spexigeo Platform (including by placing an unreasonable
                  load on the Spexigeo Platform’s infrastructure);
                </p>
                <p>
                  (xv) publish, market, advertise or in any way distribute the
                  Content;
                </p>
                <p>
                  (xvi) share, transfer or otherwise provide access to an
                  account designated for you to another person;
                </p>
                <p>
                  (xvii) copy or store any significant portion of the Content;
                </p>
                <p>
                  (xviii) use the Spexigeo Platform or Content to stalk, harass
                  or harm another individual;
                </p>
                <p>
                  (xix) mirror or frame the Spexigeo Platform or any Content,
                  place pop-up windows over its pages, or otherwise affect the
                  display of its pages; or
                </p>
                <p>
                  (xx) authorize, permit, enable, induce or encourage any third
                  party to do any of the above.
                </p>
                <p>
                  <h3>14. User Contributions</h3>
                </p>
                <p>
                  (a) All User Contributions must comply with these Terms of Use
                  and the Privacy Policy.
                </p>
                <p>
                  (b) Any User Contribution you post to the Website or on the
                  Mobile App will be considered non-confidential and
                  non-proprietary. By providing User Contribution on the
                  Spexigeo Platform, you grant us and our affiliates and service
                  providers, and each of their and our respective licensees,
                  successors, and assigns the right to use, reproduce, modify,
                  perform, display, distribute, and otherwise disclose to third
                  parties any such material for any purpose.
                </p>
                <p>
                  (c) You represent and warrant that: (1) you own or control all
                  rights in and to the User Contributions and have the right to
                  grant the license granted above to us and our affiliates and
                  service providers, and each of their and our respective
                  licensees, successors, and assigns, and (2) that all of your
                  User Contributions do and will comply with these Terms of Use.
                </p>
                <p>
                  (d) You understand and acknowledge that you are responsible
                  for any User Contributions you submit or contribute, and you,
                  not Spexi Geospatial, have full responsibility for such
                  content, including its legality, reliability, accuracy, and
                  appropriateness. We are not responsible or liable to any third
                  party for the content or accuracy of any User Contributions
                  posted by you or any other user of the Spexigeo Platform.
                </p>
                <p>
                  (e) We do not guarantee the confidentiality of any
                  communications made by you through the Spexigeo Platform. We
                  do not guarantee the security of data transmitted over the
                  Internet or public networks in connection with your use of the
                  Spexigeo Platform.
                </p>
                <p>
                  (f) <u>Monitoring, Enforcement and Termination</u>. We have
                  the right to:
                </p>
                <p>
                  (i) Remove or refuse to post any User Contributions for any or
                  no reason in our sole discretion.
                </p>
                <p>
                  (ii) Take any action with respect to any User Contribution
                  that we deem necessary or appropriate in our sole discretion,
                  including if we believe that such User Contribution violates
                  the Terms of Use, infringes any intellectual property right or
                  other right of any person or entity, threatens the personal
                  safety of users of the Spexigeo Platform or the public, or
                  could create liability for Spexi Geospatial.
                </p>
                <p>
                  (iii) Disclose your identity or other information about you to
                  any third party who claims that material posted by you
                  violates their rights, including their intellectual property
                  rights or their right to privacy.
                </p>
                <p>
                  (iv) Take appropriate legal action, including without
                  limitation, referral to law enforcement, for any illegal or
                  unauthorized use of the Spexigeo Platform.
                </p>
                <p>
                  (v) Terminate or suspend your access to all or part of the
                  Spexigeo Platform for any or no reason, including without
                  limitation, any violation of these Terms of Use.
                </p>
                <p>
                  Without limiting the foregoing, we have the right to cooperate
                  fully with any law enforcement authorities or court order
                  requesting or directing us to disclose the identity or other
                  information of anyone posting any materials on or through the
                  Spexigeo Platform. YOU WAIVE AND HOLD HARMLESS SPEXI
                  GEOSPATIAL AND ITS AFFILIATES, LICENSEES, AND SERVICE
                  PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY
                  ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A CONSEQUENCE
                  OF, INVESTIGATIONS BY EITHER SPEXI GEOSPATIAL OR LAW
                  ENFORCEMENT AUTHORITIES.
                </p>
                <p>
                  We do not undertake to review all material before it is posted
                  on the Spexigeo Platform and cannot ensure prompt removal of
                  objectionable material after it has been posted. Accordingly,
                  we assume no liability for any action or inaction regarding
                  transmissions, communications, or content provided by any user
                  or third party. We have no liability or responsibility to
                  anyone for performance or non-performance of the activities
                  described in this section.
                </p>
                <p>
                  <h3>15. Feedback</h3>
                </p>
                <p>
                  (a) You agree that any suggestion or idea provided by you
                  (such suggestions or ideas, “<strong>Feedback</strong>”) will
                  not be treated as confidential, and nothing in these Terms of
                  Use will restrict our right to use, profit from, disclose,
                  publish or otherwise exploit any Feedback, without
                  compensation to you. You grant to us a worldwide,
                  royalty-free, fully paid, perpetual, irrevocable license to
                  use, reproduce, modify, translate, distribute, perform,
                  display, import, sell, offer for sale, make, have made and
                  otherwise exploit the Feedback in any form, media, or
                  technology, whether now known or hereafter developed, and to
                  allow others to do the same. This is true whether you provide
                  the Feedback on the Spexigeo Platform or through any other
                  method of communication with us, unless we have entered into a
                  separate agreement with you that provides otherwise. You will
                  not have any claim, including claims based upon invasion of
                  privacy, defamation or right of publicity, arising out of any
                  use, alteration, blurring, distortion or use in composite form
                  of any Feedback. Except as prohibited by law, you hereby
                  waive, and you agree to waive, any moral and author’s rights
                  (including attribution and integrity) that you may have in any
                  Feedback, even if it is altered or changed in a manner not
                  agreeable to you.
                </p>
                <p>
                  <h3>16. Third Party Content, Websites or Services</h3>
                </p>
                <p>
                  (a) The Spexigeo Platform may include links or access to
                  third-party content, websites, or services. Likewise, we may
                  allow you to access the Spexigeo Platform from third-party
                  systems. We do not endorse any third-party content, websites,
                  services, or systems, or guarantee or warrant their quality,
                  durability, accuracy, reliability, completeness, currency,
                  timeliness, non-infringement, merchantability, or fitness for
                  any purpose. Third-party content, websites, services, or
                  systems are not under our control, and if you choose to access
                  any such content, websites, or services, or to access the
                  Spexigeo Platform from such systems, you do so entirely at
                  your own risk. You acknowledge that you may be required to
                  accept terms of use applicable to third party content,
                  websites, services, or systems and agree to accept and comply
                  with any such terms of use.
                </p>
                <p>
                  (b) Your interactions with organizations and/or individuals
                  found on or through the Spexigeo Platform, including payment
                  and delivery of goods and services, and any other terms,
                  conditions, warranties or representations associated with such
                  dealings, are solely between you and such organizations and/or
                  individuals. You agree that to the fullest extent permitted by
                  applicable law that we are not responsible or liable for any
                  loss or damage of any sort incurred as the result of any such
                  dealings.
                </p>
                <p>
                  <h3>17. Malicious Code and Security</h3>
                </p>
                <p>
                  (a) Your access to the Spexigeo Platform and use of Content is
                  strictly at your own risk. We do not guarantee or warrant that
                  the Spexigeo Platform is compatible with your computer system
                  or mobile device or that the Spexigeo Platform, or any links
                  from the Spexigeo Platform, will be free of viruses, worms,
                  trojan horses or disabling devices or other code that
                  manifests contaminating or destructive properties. You are
                  responsible for implementing safeguards to protect the
                  security and integrity of your computer system and/or mobile
                  device, and you are responsible for the entire cost of any
                  service, repairs or connections of and to your computer system
                  and/or mobile device that may be necessary as a result of your
                  use of the Spexigeo Platform.
                </p>
                <p>
                  <h3>18. Disclaimer</h3>
                </p>
                <p>
                  (a) THE LAWS OF CERTAIN JURISDICTIONS, WHICH MAY INCLUDE
                  QUEBEC, DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN
                  LEGAL WARRANTIES, CONDITIONS OR REPRESENTATIONS. IF THESE LAWS
                  APPLY TO YOU, SOME OR ALL OF THE EXCLUSIONS OR LIMITATIONS IN
                  THESE TERMS OF USE (INCLUDING THE FOLLOWING DISCLAIMERS) MAY
                  NOT APPLY, AND YOU MAY HAVE ADDITIONAL RIGHTS. TO THE EXTENT
                  THAT WE MAY NOT, AS A MATTER OF APPLICABLE LAW, DISCLAIM ANY
                  IMPLIED WARRANTY OR CONDITION, THE SCOPE AND DURATION OF SUCH
                  WARRANTY OR CONDITION WILL BE THE MINIMUM PERMITTED UNDER SUCH
                  APPLICABLE LAW.
                </p>
                <p>
                  (b) TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU
                  ACKNOWLEDGE, UNDERSTAND, AND AGREE THAT THE PLATFORM AND THE
                  SERVICES ARE PROVIDED “<strong>AS IS</strong>” AND “
                  <strong>AS AVAILABLE</strong>”, WITH ALL FAULTS AND WITHOUT
                  WARRANTIES OR CONDITIONS OF ANY KIND. EXCEPT AS OTHERWISE
                  REQUIRED BY APPLICABLE LAW, TO THE FULLEST EXTENT PERMITTED BY
                  APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, REPRESENTATIONS
                  AND CONDITIONS OF ANY KIND WITH RESPECT TO THE PLATFORM AND
                  THE SERVICES, WHETHER EXPRESS, IMPLIED, STATUTORY OR
                  COLLATERAL, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES AND
                  CONDITIONS OF MERCHANTABILITY, QUALITY, DURABILITY,
                  COMPATIBILITY, TITLE, SECURITY, RELIABILITY, COMPLETENESS,
                  QUIET ENJOYMENT, ACCURACY, CURRENCY, TIMELINESS, INTEGRATION,
                  FITNESS FOR A PARTICULAR OR GENERAL PURPOSE AND
                  NON-INFRINGEMENT, AND/OR ANY WARRANTIES OR CONDITIONS ARISING
                  OUT OF COURSE OF DEALING OR USAGE OF TRADE, AND/OR THAT THE
                  PLATFORM AND THE SERVICES ARE OR WILL BE ERROR-FREE OR WILL
                  OPERATE WITHOUT INTERRUPTION. TO THE FULLEST EXTENT PERMITTED
                  BY APPLICABLE LAW, WE DISCLAIM ALL WARRANTIES, REPRESENTATIONS
                  AND CONDITIONS OF ANY KIND WITH RESPECT TO THIRD PARTY
                  COMMUNICATIONS AND ANY THIRD-PARTY MATERIALS, WEBSITES OR
                  CONTENT DIRECTLY OR INDIRECTLY ACCESSED THROUGH THE PLATFORM.
                  ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY
                  THIRD-PARTY MATERIALS, WEBSITES OR CONTENT IS STRICTLY BETWEEN
                  CUSTOMER AND THE THIRD PARTY.
                </p>
                <p>
                  <h3>19. Limitation of Liability</h3>
                </p>
                <p>
                  (a) TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                  EVENT WILL WE OR OUR PARENTS, SUBSIDIARIES OR AFFILIATES AND
                  OUR AND THEIR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS,
                  EMPLOYEES, CONTRACTORS, AGENTS, LICENSORS, LICENSEES AND
                  SERVICE PROVIDERS AND ITS AND THEIR SUCCESSORS AND ASSIGNS
                  (COLLECTIVELY, THE “<strong>SPEXI</strong>
                  <strong>PARTIES</strong>”), BE LIABLE, WHETHER BASED ON
                  WARRANTY, CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR ANY
                  OTHER LEGAL THEORY, FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                  CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES, OR LOST
                  PRODUCTION, REVENUE, PROFITS, USE OR DATA, PERSONAL INJURY,
                  FINES, FEES, PENALTIES OR OTHER LIABILITIES (COLLECTIVELY, “
                  <strong>LOSSES</strong>”), IN EACH CASE, WHETHER OR NOT WE
                  WERE ADVISED OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
                  DAMAGES, RESULTING FROM OR RELATED TO THE PLATFORM OR SERVICES
                  OR THE INABILITY TO MAKE USE OF THE PLATFORM, OR THESE TERMS
                  OF USE, INCLUDING, FOR CLARITY, ANY LOSSES RESULTING FROM THE
                  ACCESS, COLLECTION, USE, PROCESSING, STORING, DISCLOSING OR
                  TRANSMITTING OF API DATA OR FINETUNING DATA BY THIRD PARTIES.
                  IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE PLATFORM, YOUR
                  SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE
                  PLATFORM.
                </p>
                <p>
                  (b) TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                  EVENT WILL OUR TOTAL AGGREGATE LIABILITY IN CONNECTION WITH OR
                  UNDER THESE TERMS OF USE, OR YOUR USE OF, OR INABILITY TO MAKE
                  USE OF, THE PLATFORM OR THE SERVICES, EXCEED THE GREATER OF
                  (i) CAD $500; OR (ii) THE TOTAL AMOUNT PAID BY YOU PURSUANT TO
                  THESE TERMS OF USE IN THE 6-MONTH PERIOD PRECEDING THE EVENT
                  GIVING RISE TO THE CLAIM. FOR GREATER CERTAINTY, THE EXISTENCE
                  OF ONE OR MORE CLAIMS UNDER THESE TERMS OF USE WILL NOT
                  INCREASE THIS MAXIMUM LIABILITY AMOUNT.
                </p>
                <p>
                  (c) Without limiting the foregoing, under no circumstances
                  will any of the Spexi Geospatial Parties be held liable for
                  any delay or failure in performance resulting directly or
                  indirectly from acts of nature, forces, or causes beyond our
                  or their reasonable control, including Internet failures,
                  computer equipment failures, telecommunication equipment
                  failures, other equipment failures, electrical power failures,
                  strikes, labour disputes, riots, insurrections, civil
                  disturbances, shortages of labour or materials, fires, floods,
                  storms, explosions, pandemics, acts of God, war, governmental
                  actions, orders of domestic or foreign courts or tribunals, or
                  non-performance of third parties.
                </p>
                <p>
                  <h3>20. Indemnification</h3>
                </p>
                <p>
                  (a) You will defend, indemnify and hold harmless the Spexi
                  Geospatial Parties from and against any claims, causes of
                  action, demands, recoveries, losses, damages, fines, penalties
                  or other costs or expenses of any kind or nature including
                  reasonable legal and accounting fees, arising out of or in
                  connection with:
                </p>
                <p>
                  (i) your use (or the use by any third party using your User
                  ID) of the Spexigeo Platform (except to the extent prohibited
                  by law);
                </p>
                <p>
                  (ii) your breach of any provision of these Terms of Use or any
                  documents referenced herein;
                </p>
                <p>
                  (iii) your violation of any law or the rights of a third party
                  (including Intellectual Property Rights);
                </p>
                <p>(iv) your User Data; or</p>
                <p>
                  (v) any viruses, Trojan horses, worms, time bombs, spyware,
                  malware, cancelbots or other similar harmful or deleterious
                  programming routines input by you into the Spexigeo Platform.
                </p>
                <p>
                  (b) We reserve the right, at our own cost, to assume the
                  exclusive defence and control of any matter otherwise subject
                  to indemnification by you, in which event you will fully
                  cooperate with us in asserting any available defences. You
                  agree that the provisions in this section will survive any
                  termination of your account, the Terms of Use or your access
                  to the Spexigeo Platform.
                </p>
                <p>21. Term and Termination; Survival</p>
                <p>
                  (a) These Terms of Use will commence on the day you first use
                  the Spexigeo Platform and will continue for as long as you use
                  the Spexigeo Platform or until terminated in accordance with
                  the provisions of these Terms of Use. At any time, we may: (i)
                  suspend or terminate your rights to access or use the Spexigeo
                  Platform, or (ii) terminate these Terms of Use, in our sole
                  discretion, for any reason, including if we believe that you
                  have used the Spexigeo Platform in violation of these Terms of
                  Use, the Documentation or any other use guidelines we provide
                  to you or are posted on the Spexigeo Platform, or have engaged
                  in fraudulent activity. You may terminate these Terms of Use
                  by emailing your request that your User ID be deleted to
                  accounting@spexigeo.com, ceasing use of the Spexigeo Platform,
                  and uninstalling the Mobile App. Certain users may also be
                  able to delete their User ID and account through the Mobile
                  App. For greater certainty, if you continue to use any portion
                  of the Spexigeo Platform after these Terms of Use have been
                  purportedly terminated by you as described above, these Terms
                  of Use will continue to apply to the extent of such use. In
                  the event of termination, you are no longer authorized to
                  access or use the Spexigeo Platform and the benefits thereof.
                </p>
                <p>
                  (b) The following Sections, together with any other provision
                  of these Terms of Use which expressly or by its nature
                  survives termination or expiration, or which contemplates
                  performance or observance subsequent to termination or
                  expiration of these Terms of Use, will survive expiration or
                  termination of these Terms of Use for any reason: Sections ‎9
                  (User Data and Aerial Imagery), ‎10 (Ownership of the Spexigeo
                  Platform), ‎11 (Additional Terms), ‎14 (User Contributions),
                  ‎15 (Feedback), ‎16 (Third Party Content, Websites or
                  Services) ‎17 (Malicious Code and Security), ‎18 (Disclaimer),
                  ‎19 (Limitation of Liability), ‎20 (Indemnification), ‎21(b)
                  (Survival), ‎22 (<strong>Disputes</strong>), and ‎23
                  (General).
                </p>
                <p>
                  <h3>22. Disputes</h3>
                </p>
                <p>
                  (a) TO THE EXTENT PERMITTED BY LAW, any controversy, claim or
                  dispute arising out of, relating to, or in respect of these
                  Terms of Service, including their negotiation, validity,
                  existence, breach, termination, construction or application,
                  or the rights, duties or obligations of any party, or the
                  rights, duties or obligations of any party derived from or
                  associated with these Terms of Use (a “
                  <strong>Dispute</strong>”), will be handled according to the
                  provisions set out below.
                </p>
                <p>
                  (b) We are available by email to address any concerns you may
                  have regarding your use of the Spexigeo Platform. Most
                  concerns may be quickly resolved in this manner.
                </p>
                <p>
                  (c) If we and you do not reach an agreed upon solution within
                  a period of 30 days from the time informal dispute resolution
                  is pursued pursuant to the “Initial Dispute Resolution”
                  section above then the Dispute will be referred to and
                  determined by a single arbitrator in a final and binding
                  arbitration administered under the rules of ADR Institute of
                  Canada, Inc.’s Arbitration Rules. If the parties to the
                  Dispute have not agreed upon the arbitrator within 14 days,
                  the parties will ask the ADR Institute of Canada, Inc. to
                  appoint a single arbitrator. The seat of the arbitration will
                  be the same as the provincial or territorial law governing
                  these Terms of Use. The arbitration will be heard in
                  Vancouver, British Columbia. The costs and expenses of the
                  arbitrator will be shared equally between the parties to the
                  Dispute. The parties have no right of appeal from any award of
                  the Arbitrator, whether characterized as final, interim,
                  interlocutory or partial. All Disputes referred to arbitration
                  (including the scope of these Terms of Use to arbitrate, the
                  law relating to the enforcement of these Terms of Use to
                  arbitrate, any relevant limitation periods, the law governing
                  the procedure of the arbitration, the law relating to
                  available remedies, set-off claims and conflict of laws rules)
                  will be governed by the law of the seat, and the parties
                  hereby irrevocably consent to venue in Vancouver, British
                  Columbia, and to the jurisdiction of competent courts in the
                  capital of the seat for all litigation that may be brought,
                  subject to the requirements for arbitration hereunder, with
                  respect to the terms of, and the transactions and
                  relationships contemplated by, these Terms of Use.
                  Notwithstanding this provision, either party may take such
                  steps as are permitted or required to enforce an award made by
                  an arbitrator. The existence of the arbitration and any
                  element of the arbitration, including any award, will be
                  confidential. The deemed undertaking rule will apply. No
                  document or other evidence or information prepared for or
                  produced by or on behalf of the parties to the Dispute will be
                  disclosed to any non-party to the arbitration. You agree that
                  you will not contest venue, and you waive any rights you may
                  have to initiate, transfer, or change the venue of any
                  litigation arising from or related to these Terms of Use.
                </p>
                <p>
                  (d){' '}
                  <strong>
                    <u>Class Action Waiver</u>
                  </strong>{' '}
                  <strong>.</strong> TO THE EXTENT PERMITTED BY LAW, you further
                  agree that any lawsuit or arbitration will be conducted in
                  your individual capacity only and not as a class action or
                  other representative action, and you expressly waive the right
                  to file a class action or seek relief on a class basis. If any
                  court or arbitrator determines that the class action waiver
                  set forth in this paragraph is void or unenforceable for any
                  reason or that an arbitration can proceed on a class basis,
                  then the arbitration provision set forth above will be deemed
                  null and void in its entirety and the parties will be deemed
                  to have not agreed to arbitrate disputes.
                </p>
                <p>
                  <h3>23. General</h3>
                </p>
                <p>
                  (a) <u>Choice of Law.</u> To the extent permitted by law,
                  these Terms of Use will be governed by the laws of the
                  Province of British Columbia and the federal laws of Canada
                  applicable therein (without giving effect to any principles of
                  conflicts of law), and such laws apply to your access to or
                  use of the Spexigeo Platform notwithstanding your physical
                  location. You will only use the Spexigeo Platform in
                  jurisdictions where the Spexigeo Platform may lawfully be
                  used. Except as restricted by applicable law, you hereby
                  consent to the exclusive jurisdiction and venue of courts in
                  Vancouver, British Columbia in all disputes arising out of or
                  relating to the use of the Spexigeo Platform. This choice of
                  jurisdiction does not prevent us from seeking injunctive
                  relief with respect to a violation of Intellectual Property
                  Rights or confidentiality obligations in any appropriate
                  jurisdiction.
                </p>
                <p>
                  (b) <u>Entire Agreement</u>. These Terms of Use constitute the
                  entire agreement between you and us pertaining to the subject
                  matter hereof and supersede all prior or contemporaneous
                  communications and proposals, whether electronic, oral or
                  written, between you and us with respect to the Spexigeo
                  Platform and the Services. A printed version of these Terms of
                  Use and of any notice given in electronic form will be
                  admissible in judicial or administrative proceedings based
                  upon or relating to these Terms of Use to the same extent and
                  subject to the same conditions as other business documents and
                  records originally generated and maintained in printed form.
                </p>
                <p>
                  (c) <u>Order of Precedence</u>. In the event and to the extent
                  of an inconsistency or conflict between any of the provisions
                  of these Terms of Use, including its schedules and appendices
                  and any other documents incorporated herein by reference, the
                  conflict or inconsistency shall be resolved by giving those
                  provisions and documents the following order of descending
                  precedence: (i) these Terms of Use; (ii) the Documentation.
                </p>
                <p>
                  (d) <u>Waiver</u>. Our failure to insist upon or enforce
                  strict performance of any provision of these Terms of Use will
                  not be construed as a waiver of any provision or right. A
                  waiver of any provision of these Terms of Use must be in
                  writing and a waiver in one instance will not preclude
                  enforcement of such provision on other occasions.
                </p>
                <p>
                  (e) <u>Severable</u>. If any of the provisions contained in
                  these Terms of Use are determined to be void, invalid or
                  otherwise unenforceable by a court of competent jurisdiction,
                  such provision will be severed from these Terms of Use and all
                  other provisions of these Terms of Use will remain in full
                  force and effect.
                </p>
                <p>
                  (f) <u>Assignment</u>. You may not assign these Terms of Use
                  to any third party without our prior written consent. We may
                  assign these Terms of Use or any rights under these Terms of
                  Use to any third party without your consent. Any attempted
                  assignment, subcontract, delegation, or transfer in violation
                  of this Section will be null and void. The terms of these
                  Terms of Use will be binding upon permitted assignees. These
                  Terms of Use will inure to the benefit of and be binding upon
                  the parties, their permitted successors and permitted
                  assignees.
                </p>
                <p>
                  (g) <u>References</u>. For purposes of these Terms of Use, (a)
                  the words "include", "includes", and "including" are deemed to
                  be followed by the words "without limitation"; (b) the word
                  "or" is not exclusive; (c) the words "herein", "hereof",
                  "hereby", "hereto", and "hereunder" refer to these Terms of
                  Use as a whole; (d) whenever the singular is used herein, the
                  same includes the plural, and whenever the plural is used
                  herein, the same includes the singular, where appropriate.
                  These Terms of Use shall be construed without regard to any
                  presumption or rule requiring construction or interpretation
                  against the party drafting an instrument or causing any
                  instrument to be drafted.
                </p>
                <p>
                  (h) <u>English Language</u>. It is the express wish of the
                  parties that these Terms of Use and all related documents be
                  drawn up in English. C’est la volonté expresse des parties que
                  la présente convention ainsi que les documents qui s’y
                  rattachent soient rédigés en anglais.
                </p>
                <h3>24. Contact</h3>
                <p>
                  (a) If you have any questions or comments regarding these
                  Terms of Use, please contact us at
                  <a href="mailto:info@spexigeo.com">info@spexigeo.com</a>
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default TermsContainer
