import React, { useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Scatter } from 'react-chartjs-2'
import distance from '@turf/distance'
import { point } from '@turf/helpers'
import convert from 'convert-units'
import pathify, { terrainify, getElevationData, getTakeOffElevationData } from 'spexi-pathify'
import { alertTypes } from '../../util/constants'
import { useDispatch } from 'react-redux'
import { setFlightParameters, resetFlightMapObjects } from '../../actions/flightPlans'
import { setAlertModal } from '../../actions/ui'
import Tooltip from '../Tooltip'
import './style.css'

const TerrainProfile = ({ flightParameters, newFlightPlan, droneCamera }) => {
  const dispatch = useDispatch()
  // local state
  const [groundLeveldata, setGroundLeveldata] = useState([])
  const [flyingLeveldata, setFlyingLeveldata] = useState([])
  const [waypointColors, setWaypointColors] = useState([])
  const [takeOffElev, setTakeOffElev] = useState(0)
  const chartRef = useRef(null)

  const mapAltitudeToDistance = useCallback(
    (waypoints, altitudeOffset = 0) => {
      let segmentDistances = []
      let data = []
      waypoints.forEach((waypoint, i) => {
        const { longitude, latitude } = waypoint.coordinate
        let currentDistFromStart = 0
        let segmentDist = 0
        if (i !== 0) {
          segmentDist = distance(
            point([longitude, latitude]),
            point([
              waypoints[i - 1].coordinate.longitude,
              waypoints[i - 1].coordinate.latitude
            ])
          )
          segmentDistances.push(convert(segmentDist).from('km').to('m'))
          currentDistFromStart = segmentDistances.reduce((a, b) => a + b, 0)
        }

        data.push({
          x: currentDistFromStart,
          y: waypoint.altitude + altitudeOffset,
          backgroundColor:
            waypoint.isFlightLineStart ||
            waypoint.isFlightLineEnd ||
            !newFlightPlan.parameters.isTerrainAwareMission ||
            !newFlightPlan.parameters.takeOffCoordinate
              ? '#c43a31'
              : 'blue'
        })
      })
      return data
    },
    [newFlightPlan]
  )

  useEffect(() => {
    const updateTerrainGraph = async () => {
      const jwt = localStorage.getItem('jwt-token')
      const { parameters } = newFlightPlan
      try {
        if (!parameters?.isTerrainAwareMission ||
          !parameters?.takeOffCoordinate?.latitude ||
          !parameters?.takeOffCoordinate?.longitude
        ) {
          alert('Error Displaying Graph - Flight is not terrain aware!')
          return null
        }
        const flightPath = pathify(newFlightPlan, droneCamera)
        // get and graph terrain profile separately from flightPath
        const takeOffElvData = await getTakeOffElevationData(jwt, newFlightPlan)
        const elevations = await getElevationData(jwt, flightPath)
        // get terrain aware mission
        const newFlightPath = await terrainify(
          flightPath,
          newFlightPlan,
          {
            takeOffElevation: takeOffElvData.results[0].elevation,
            elevations
          }
        )
        // check 3D flightPath is valid
        if (newFlightPath?.errors?.length > 0) {
          newFlightPath.errors.forEach(error => {
            dispatch(setAlertModal({
              message: error.message,
              type: alertTypes.warning,
              confirmLabel: 'Okay',
              confirmHandler: () => {
              },
              cancellable: false
            }))
          })
        }

        const { takeOffElevation } = newFlightPath.meta
        const terrainWaypoints = createTerrainWaypoints(elevations)
        const _takeOffElevation = takeOffElevation
          ? takeOffElevation
          : terrainWaypoints[0].altitude ?? 0
        if (!isNaN(_takeOffElevation)) {
          setTakeOffElev(_takeOffElevation.toFixed(2))
        }
        let _groundLeveldata = mapAltitudeToDistance(terrainWaypoints)
        let _flyingLeveldata = mapAltitudeToDistance(newFlightPath.waypoints, _takeOffElevation)
        let _waypointColors = _flyingLeveldata.map(element => element.backgroundColor)
        setGroundLeveldata(_groundLeveldata)
        setFlyingLeveldata(_flyingLeveldata)
        setWaypointColors(_waypointColors)
      } catch (e) {
        alert(e)
      }
    }
    updateTerrainGraph()
  }, [newFlightPlan, droneCamera, mapAltitudeToDistance, dispatch])

  const createTerrainWaypoints = elevations => {
    let terrainWaypoints = []
    elevations.forEach(flProfile => {
      flProfile.elevationSamples.forEach(elevationSample => {
        terrainWaypoints.push({
          coordinate: {
            longitude: elevationSample.location.lng,
            latitude: elevationSample.location.lat
          },
          altitude: elevationSample.elevation
        })
      })
    })
    return terrainWaypoints
  }

  const options = {
    responsive: true,
    legend: {
      display: false
    },
    tooltips: {
      enabled: true,
      callbacks: {
        title: function () {
          return ''
        },
        label: function (tooltipItem) {
          return tooltipItem.datasetIndex === 0 ? 'Ground Level' : 'Flying height'
        },
        beforeFooter: function (tooltipItem) {
          return `Waypoint ${tooltipItem[0].index + 1}`
        },
        footer: function (tooltipItem) {
          return  `Altitude ${tooltipItem[0].yLabel.toFixed()} m`
        },
        afterFooter: function (tooltipItem) {
          return `Distance ${tooltipItem[0].xLabel.toFixed()} m`
        }
      }
    },
    scales: {
      yAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Relative Altitude (m)',
          fontColor: '#444444',
          fontSize: 12,
          textStrokeWidth: 0
        },
        ticks: {
          callback: function (tickValue) {
            return `${tickValue.toFixed()} m`
          },
          fontSize: 11
        }
      }],
      xAxes: [{
        scaleLabel: {
          display: true,
          labelString: 'Mission Distance (m)',
          fontColor: '#444444',
          fontSize: 12
        },
        ticks: {
          callback: function (tickValue) {
            return `${tickValue.toFixed()} m`
          },
          fontSize: 11,
          autoSkip: true,
          maxTicksLimit: 5
        }
      }]
    },
  }
  const data = {
    datasets: [
      {
        label: 'Terrain Profile',
        data: groundLeveldata,
        borderColor: '#DDD',
        showLine: true
      },
      {
        label: 'Flight Profile',
        data: flyingLeveldata,
        borderColor: '#c43a31',
        backgroundColor: waypointColors,
        showLine: true,
        fill: false
      },
    ],
  }

  const legend = [
    {text: 'Start/End Waypoint', fillStyle: '#c43a31'},
    {text: 'Altitude Waypoint', fillStyle: 'blue'}
  ]

  const onElvThresChange = event => {
    dispatch(setFlightParameters({ elevationThreshold: Number(event.target.value) }))
    setTimeout(() => {
      dispatch(resetFlightMapObjects())
    }, 10)
  }

  return (
    <div className="profile-chart">
      <p>
        When enabled the drone continually alters flying height to maintain a
        constant ground sampling distance above the mission.
      </p>
      <p className="takeOffElevation">
        Take off Elevation: {takeOffElev ?? 0}m(asl)
      </p>
      <div>
        <label>Elevation Threshold:{' '}
          <span className="currentValue">{flightParameters?.elevationThreshold} m </span>
          <Tooltip
            tip="Compares adjacent elevation waypoints and removes waypoints below set threshold. Red points represent the begining and end of a flight line. Blue points represent elevation waypoint changes while traversing a flight line."
            type="dark"
          />
        </label>
      </div>
      <div>
        <input style={{width: '100%'}} type="range" min="0" max="20" step="1" value={flightParameters?.elevationThreshold} name="elevationThreshold" onChange={onElvThresChange} />
      </div>
      <ul className="terrainLegendList">
        {legend.length &&
          legend.map(item => {
            return (
              <li key={item.text} className="terrainLegendItem">
                <div
                  className="terrainLegendCircle"
                  style={{backgroundColor: item.fillStyle}}
                />
                {item.text}
              </li>
            )
          })}
      </ul>
      <Scatter
        ref={chartRef}
        data={data}
        options={options}
      />
    </div>
  )
}

TerrainProfile.propTypes = {
  flightParameters: PropTypes.object.isRequired,
  newFlightPlan: PropTypes.object.isRequired,
  droneCamera: PropTypes.object.isRequired,
}

export default TerrainProfile
