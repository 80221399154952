import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SidebarHeader from '../SidebarHeader'
import './styles.css'
import MapLayerList from '../MapLayerList'
import { TwitterPicker } from 'react-color'
import { Line } from 'react-chartjs-2'
import L from 'leaflet'
import length from '@turf/length'
import along from '@turf/along'
import { lineString } from '@turf/helpers'
import moveIcon from './move.svg'
import { debounce } from 'lodash'
import Tooltip from '../Tooltip'
import { alertTypes } from '../../util/constants'

class AddAnnotation extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    annotationCreationForm: PropTypes.object.isRequired,
    setProfileCoordinate: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      colorPickerVisibility: false,
      chartData: {},
      chartOptions: {
        autoSkip: true,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: function (tickValue) {
                  return `${tickValue.toFixed(1)} m`
                },
                fontSize: 11,
              },
            },
          ],
        },
        tooltips: {
          enabled: false,
        },
      },
    }
  }

  componentDidMount() {
    document.body.classList.add('add-edit-annotation')
    if (
      this.props.product.type === 'map' ||
      this.props.product.type === 'map+3d'
    ) {
      this.props.setMapLayers({ orthomosaic: true })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.GISProfileGraphData !== this.props.GISProfileGraphData) {
      if (Object.keys(this.props.GISProfileGraphData).length !== 0) {
        let labels = []
        for (
          let index = 0;
          index < this.props.GISProfileGraphData.samples.length;
          index++
        ) {
          let adjustValue = (
            this.props.GISProfileGraphData.distance_2d *
            (index / this.props.GISProfileGraphData.samples.length)
          ).toFixed(0)
          labels.push(`${adjustValue} m`)
        }
        let chartData = {
          labels,
          datasets: [
            {
              label: 'Profile',
              backgroundColor: 'rgba(106, 174, 196, 0.25)',
              borderColor: 'rgb(106, 174, 196)',
              fill: 'start',
              pointRadius: 0,
              data: this.props.GISProfileGraphData.samples,
            },
          ],
        }

        this.setState({ chartData })
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('add-edit-annotation')
    this.props.setProfileCoordinate(null)
    this.props.fetchAnnotationsData(this.props.match.params.pid)
    this.props.setGISProfileGraphData({})
  }

  handleChange = (event) => {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.props.setAnnotationCreationForm({ [name]: value })
  }

  handleColorChange = (color) => {
    this.props.setAnnotationCreationForm({ color: color.hex.substring(1) })

    if (this.props.annotationCreationForm.type !== 'map-location') {
      this.props.drawingAnnotationLayer.setStyle({
        color: `#${this.props.annotationCreationForm.color}`,
      })
    }

    if (this.props.annotationCreationForm.type === 'map-location') {
      const markerHtmlStyles = `
      background-color: ${`#${this.props.annotationCreationForm.color}`};
      width: 1rem;
      height: 1rem;
      display: block;
      left: -.5rem;
      top: -.5rem;
      position: relative;
      border-radius: 1rem 1rem 0;
      transform: rotate(45deg);`

      const icon = L.divIcon({
        className: 'annotation-marker-icon',
        iconAnchor: [0, 8],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`,
      })

      this.props.drawingAnnotationLayer.setIcon(icon)
      this.props.drawingAnnotationLayer.setOpacity(1)
    }
  }

  getBasePlaneElevationValue() {
    if (this.props.annotationCreationForm.options.method === 'CUSTOM') {
      return this.props.annotationCreationForm.options
        .custom_base_plane_elevation
    } else if (
      this.props.GISData &&
      'base_plane_elevation' in this.props.GISData
    ) {
      return this.props.GISData.base_plane_elevation.toFixed(3)
    }
    return 0.0
  }

  handleVolumeMethodChange(event) {
    let options = { method: event.target.value }
    if (event.target.value === 'CUSTOM') {
      options = {
        ...options,
        custom_base_plane_elevation: this.getBasePlaneElevationValue(),
      }
    }
    this.props.setAnnotationCreationForm({ options })
    this.props.calculateMeasurement(
      this.props.drawingAnnotationLayer,
      this.props.annotationCreationForm.type,
      options,
      this.props.product.id,
      true
    )
  }

  handleCustomBasePlaneElevationChangeDebounced = debounce(
    (options) => {
      this.props.calculateMeasurement(
        this.props.drawingAnnotationLayer,
        this.props.annotationCreationForm.type,
        options,
        this.props.product.id,
        true
      )
    },
    500,
    { leading: false, trailing: true }
  )

  handleCustomBasePlaneElevationChange(event) {
    if (this.props.annotationCreationForm.options.method !== 'CUSTOM') {
      return
    }
    let options = {
      method: 'CUSTOM',
      custom_base_plane_elevation: event.target.value,
    }
    this.props.setAnnotationCreationForm({ options })
    if (isNaN(parseFloat(event.target.value))) {
      return
    }
    this.handleCustomBasePlaneElevationChangeDebounced(options)
  }

  handleCustomBasePlaneElevationBlur(event) {
    if (
      isNaN(
        parseFloat(
          this.props.annotationCreationForm.options.custom_base_plane_elevation
        )
      )
    ) {
      // value was bunk, replace it with zero and continue on
      let options = { method: 'CUSTOM', custom_base_plane_elevation: 0.0 }
      this.props.setAnnotationCreationForm({ options })
      this.handleCustomBasePlaneElevationChangeDebounced(options)
    }
  }

  togglePicker = (event) => {
    this.setState({ colorPickerVisibility: !this.state.colorPickerVisibility })
  }

  deleteAnnotationHandler = (event) => {
    event.preventDefault()
    this.props.setAlertModal({
      message: 'Are you sure that you want to delete this annotation?',
      type: alertTypes.warning,
      cancellable: true,
      confirmHandler: () => {
        this.props.deleteAnnotation(this.props.annotationCreationForm.id)
      },
    })
  }

  graphClickHandler = (elems) => {
    elems.forEach((elem) => {
      let data = this.state.chartData.datasets[elem._datasetIndex].data
      let t = elem._index / data.length
      let coordinates = []
      if (
        this.props.drawingAnnotationLayer &&
        this.props.drawingAnnotationLayer.editing
      ) {
        if (this.props.annotationCreationForm.type === 'map-distance') {
          coordinates = this.props.drawingAnnotationLayer.editing.latlngs[0]
        } else {
          coordinates = this.props.drawingAnnotationLayer.editing.latlngs[0][0]
          coordinates.push(coordinates[0])
        }
        coordinates = coordinates.map((x) => [x.lng, x.lat])
        let feature = lineString(coordinates)
        let d = length(feature)
        let P = along(feature, d * t)
        this.props.setProfileCoordinate(P.geometry.coordinates)
      }
    })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    console.log('annotation form submit', this.props.annotationCreationForm)
    if (this.props.match.path === '/project/:id/review/:pid/:aid/edit') {
      this.props.updateAnnotationData(this.props.annotationCreationForm)
    } else {
      this.props.saveAnnotationData(this.props.annotationCreationForm)
    }
  }

  getAnnotationTypeHumanReadableName(type) {
    switch (type) {
      case 'map-volume':
        return 'Volume'
      case 'map-area':
        return 'Area'
      case 'map-distance':
        return 'Distance'
      case 'map-location':
        return 'Location'
      default:
        return 'Annotation'
    }
  }

  getMeasurementUomString(uom) {
    if (uom === 'm3') {
      return 'm³'
    } else if (uom === 'm2') {
      return 'm²'
    } else if (uom === 'deg') {
      return '°'
    }
    return uom
  }

  getMeasurementTypes() {
    let type = this.props.annotationCreationForm.type
    if (type === 'map-volume') {
      return [
        { key: 'cut', label: 'Volume Above', uom: 'm3' },
        { key: 'fill', label: 'Volume Below', uom: 'm3' },
        { key: 'total', label: 'Volume Total', uom: 'm3' },
      ]
    } else if (type === 'map-area') {
      return [
        { key: 'area_2d', label: 'Planar Area', uom: 'm2' },
        { key: 'area_3d', label: 'Surface Area', uom: 'm2' },
        { key: 'distance_2d', label: 'Perimeter', uom: 'm' },
      ]
    } else if (type === 'map-distance') {
      return [
        { key: 'distance', label: 'Distance', uom: 'm' },
        { key: 'slope', label: 'Slope', uom: 'deg' },
      ]
    } else if (type === 'map-location') {
      return [{ key: 'elevation', label: 'Elevation', uom: 'm' }]
    }
    return []
  }

  fetchValueFromGIS(type) {
    if (type.key in this.props.GISData) {
      return this.props.GISData[type.key].toFixed(2)
    } else if (type.key in this.props.GISProfileGraphData) {
      return this.props.GISProfileGraphData[type.key].toFixed(2)
    }
    return ''
  }

  render() {
    let formTitle
    if (this.props.match.path === '/project/:id/review/:pid/:aid/edit') {
      formTitle = (
        <SidebarHeader
          title="Edit annotation"
          backLink={`/project/${this.props.match.params.id}/review/${this.props.match.params.pid}`}
          backFunction={this.props.clearAnnotation}
        />
      )
    } else {
      formTitle = (
        <SidebarHeader
          title="New annotation"
          backLink={`/project/${this.props.match.params.id}/review/${this.props.match.params.pid}`}
          backFunction={this.props.clearAnnotation}
        />
      )
    }
    let type = this.getAnnotationTypeHumanReadableName(
      this.props.annotationCreationForm.type
    )
    return (
      <div className="add-annotation-form">
        <svg width="0" height="0" style={{ display: 'block' }}>
          <defs>
            <pattern
              id="move_icon"
              width="100%"
              height="100%"
              x="0"
              y="0"
              viewBox="0 0 20 20"
            >
              <image
                width="20"
                height="20"
                xlinkHref={moveIcon}
                preserveAspectRatio="xMidYMid slice"
              />
            </pattern>
          </defs>
        </svg>

        <div
          className={
            this.state.colorPickerVisibility
              ? 'picker-close-element open'
              : 'picker-close-element'
          }
          onClick={this.togglePicker}
        ></div>
        <form onSubmit={this.handleSubmit}>
          {formTitle}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Annotation Name</label>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input
                required
                type="text"
                value={this.props.annotationCreationForm.name}
                onChange={this.handleChange}
                placeholder="Enter a name"
                name="name"
              />
              <div
                className="annotation-color"
                style={{ color: `#${this.props.annotationCreationForm.color}` }}
                onClick={this.togglePicker}
              >
                <span
                  className="color-circle"
                  style={{
                    backgroundColor: `#${this.props.annotationCreationForm.color}`,
                  }}
                ></span>
              </div>
              <TwitterPicker
                className={
                  this.state.colorPickerVisibility
                    ? 'color-picker open'
                    : 'color-picker'
                }
                color={`#${this.props.annotationCreationForm.color}`}
                onChangeComplete={this.handleColorChange}
                triangle="top-right"
                colors={[
                  '#FF6900',
                  '#FCB900',
                  '#7BDCB5',
                  '#00D084',
                  '#8ED1FC',
                  '#0693E3',
                  '#ABB8C3',
                  '#F30808',
                  '#F78DA7',
                  '#9900EF',
                ]}
              />
            </div>
          </div>

          {this.props.annotationCreationForm.type === 'map-volume' && (
            <div>
              <h4 className="section-title">Base Plane</h4>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <select
                  value={this.props.annotationCreationForm.options.method}
                  onChange={this.handleVolumeMethodChange.bind(this)}
                >
                  <option value="BEST_FIT">Best Fit</option>
                  <option value="MEAN">Mean Level</option>
                  <option value="LOWEST_LEVEL">Lowest Level</option>
                  <option value="HIGHEST_LEVEL">Highest Level</option>
                  <option value="CUSTOM">Custom</option>
                </select>

                <div style={{ textAlign: 'center' }}>
                  {this.props.annotationCreationForm.options.method !==
                    'BEST_FIT' && (
                    <input
                      className={`base-plane
                        ${
                    this.props.requestsInProgress.MEASUREMENTS &&
                          this.props.annotationCreationForm.options.method !==
                            'CUSTOM'
                      ? 'base-plane-loading'
                      : ''
                    }
                        ${
                    this.props.annotationCreationForm.options.method ===
                          'CUSTOM'
                      ? 'base-plane-custom'
                      : ''
                    }
                        `}
                      disabled={
                        this.props.annotationCreationForm.options.method !==
                        'CUSTOM'
                      }
                      value={this.getBasePlaneElevationValue()}
                      onChange={this.handleCustomBasePlaneElevationChange.bind(
                        this
                      )}
                      onBlur={this.handleCustomBasePlaneElevationBlur.bind(
                        this
                      )}
                      type="number"
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          <h4 className="section-title">{type} Measurements</h4>
          <section className="measurements">
            {this.getMeasurementTypes().map((type) => {
              return (
                <div key={type.key} className="measurements__figure">
                  <span className="measurements__label">{type.label}</span>
                  <span className="measurements__number">
                    {this.props.requestsInProgress.MEASUREMENTS && (
                      <i className="fa fa-spinner fa-spin" />
                    )}
                    {!this.props.requestsInProgress.MEASUREMENTS &&
                      this.fetchValueFromGIS(type)}{' '}
                    {this.getMeasurementUomString(type.uom)}
                  </span>
                </div>
              )
            })}
          </section>

          {this.props.annotationCreationForm.type !== 'map-location' && (
            <div>
              <h4 className="section-title">Profile</h4>
              <div className="profile-chart">
                {this.props.requestsInProgress.PROFILE_GRAPH && (
                  <div className="lds-ring" />
                )}
                <Line
                  data={this.state.chartData}
                  options={this.state.chartOptions}
                  width={324}
                  height={200}
                  onElementsClick={this.graphClickHandler}
                />
              </div>
            </div>
          )}

          <label>
            Description <small>(optional)</small>
            <textarea
              value={this.props.annotationCreationForm.description || ''}
              onChange={this.handleChange}
              placeholder="Enter any notes or comments about the annotation."
              name="description"
            />
          </label>

          <label htmlFor="public" style={{ marginBottom: '2rem' }}>
            <input
              type="checkbox"
              id="public"
              name="public"
              checked={this.props.annotationCreationForm.public}
              onChange={this.handleChange}
            />
            Visible in Viewer{' '}
            <Tooltip
              tip="This toggle will display this annotation and measurements in the publicly shareable web viewer/player
."
            />
          </label>

          <input
            type="submit"
            className="button solid"
            value="Save Annotation"
          />

          {this.props.match.path === '/project/:id/review/:pid/:aid/edit' && (
            <button
              type="button"
              className="btn white"
              onClick={this.deleteAnnotationHandler}
            >
              {' '}
              Delete annotation
            </button>
          )}
        </form>

        <MapLayerList {...this.props} />
      </div>
    )
  }
}

export default AddAnnotation
