import { history } from '../store'
import { debounce } from 'lodash'
// import Push from 'push.js'
import L from 'leaflet'
import { Products, GCPs } from '../api'
import { setRequestInProgress } from './request'
import { setPreviewMarkers } from './map'
import { convertGcpsToMarkers } from '../util'
import { alertTypes } from '../util/constants'
import { setAlertModal } from './ui'


import GCPmarkerIcon from '../components/GCPUpload/marker.png'
const GCPmarkerImage = new L.Icon({
  iconUrl: GCPmarkerIcon,
  iconSize: [28, 28],
  iconAnchor: [14, 14]
})

export function setIsUploadingImages(boolean) {
  return {
    type: 'SET_IS_UPLOADING_IMAGES',
    boolean
  }
}


export function setProductForm(data) {
  return {
    type: 'SET_PRODUCT_FORM',
    data
  }
}

export function setProductsDataSuccess(products) {
  return {
    type: 'SET_PRODUCTS_DATA_SUCCESS',
    products
  }
}

export function setProductDataSuccess(product) {
  return {
    type: 'SET_PRODUCT_DATA_SUCCESS',
    product
  }
}


/**
 * Get products from server
 */
export const getProductsDebounceInnerFunc = debounce(
  (dispatch, project_id) => {
    dispatch(setRequestInProgress(true, 'PRODUCTS'))
    Products.getProductsByProjectId(project_id).then(response => {
      console.log('Got products from server.')
      // make sure this doesn't happen after a component has been unmounted
      // but the timeout function is still in progress
      if (history.location.pathname.includes(project_id)) {
        dispatch(setProductsDataSuccess(response.data))
      }
      dispatch(setRequestInProgress(false, 'PRODUCTS'))
    })
      .catch(console.error)
  },
  10000,
  {
    'leading': true,
    'trailing': true,
    'maxWait': 0
  }
)

/**
 * Debounced inner function outside of the wrapping function
 * Needed to do this because of async action thunk
 * See:  https://gist.github.com/krstffr/245fe83885b597aabaf06348220c2fe9
 */
export const getProductsDebounced = (project_id) => dispatch => getProductsDebounceInnerFunc(dispatch, project_id)

// Get products immediately without debounce
export function getProducts(project_id) {
  return (dispatch) => {
    dispatch(setRequestInProgress(true, 'PRODUCTS'))
    return Products.getProductsByProjectId(project_id).then(response => {
      console.log('Got products from server immediately.')
      if (history.location.pathname.includes(project_id)) {
        dispatch(setProductsDataSuccess(response.data))
      }
      dispatch(setRequestInProgress(false, 'PRODUCTS'))
    })
      .catch(console.error)

  }
}


// get current product from existing product data set
export function openSingleProduct(id) {
  return (dispatch, getState) => {

    // get current product from existing product data set
    const { products } = getState()

    if (products.length > 0) {
      let currentProduct = products.filter(product => product.id === id)[0]
      dispatch(setProductDataSuccess(currentProduct))
    }
  }
}


export function exportProduct(projectId, productId, data) {
  return (dispatch, getState) => {
    dispatch(setRequestInProgress(true, 'GLOBAL'))
    Products.export(productId, data)
      .then(result => {
        if (result.success) {
          history.push(`/project/${projectId}/review/${productId}/`)
          dispatch(setAlertModal({ message: 'Data export request received! You will receive a link to download the data at e-mail you provided shortly!', type: alertTypes.success }))
        } else {
          dispatch(setAlertModal({ message: `An error occurred while handling the request (${result.error})`, type: alertTypes.error }))
        }
      })
      .finally(() => {
        dispatch(setRequestInProgress(false, 'GLOBAL'))
      })
  }
}


export function setProductJobs(productJobs) {
  return {
    type: 'SET_PRODUCT_JOBS',
    productJobs
  }
}


export function setProductJob(productJob) {
  return {
    type: 'SET_PRODUCT_JOB',
    productJob
  }
}



/**
 * Get product jobs from server
 */
export const getProductJobsDebounceInnerFunc = debounce(
  (dispatch, getState, project_id) => {
    dispatch(setRequestInProgress(true, 'PRODUCT_JOBS'))
    Products.getProductsJobsByProjectId(project_id).then(response => {
      // if (productJobs.length > 0) {
      // TODO: This should not be the reponsibility of the
      // web-app to poll in this way. The API should afford a way to
      // dispatch notifications that the web-app client
      // listens for (use some third-party notification API).
      // response.data.forEach((job, index) => {
      //   // check existing jobs that match
      //   if (productJobs[index] && productJobs[index].id === job.id) {
      //     if (job.current_task
      //       && job.current_task.executor === 'human'
      //       && job.status === 'paused'
      //       && productJobs[index].status === 'running'
      //       && job.current_task.type.id === 'refine-markers') {
      //       // send browser notification
      //       Push.create('Spexi Flight Dashboard', {
      //         body: 'ACTION REQUIRED: Please refine GCP markers.',
      //         icon: 'https://fly.spexigeo.com/android-chrome-256x256.png',
      //         timeout: 7000,
      //         onClick: function () {
      //           window.focus()
      //           this.close()
      //         }
      //       })
      //     }
      //   }
      // })
      // }
      // make sure this doesn't happen after a component has been unmounted
      // but the timeout function is still in progress
      if (history.location.pathname.includes(project_id)) {
        dispatch(setProductJobs(response.data))
      }
      dispatch(setRequestInProgress(false, 'PRODUCT_JOBS'))
    })
      .catch(console.error)
  },
  10000,
  {
    'leading': true,
    'trailing': true,
    'maxWait': 0
  }
)

/**
 * Debounced inner function outside of the wrapping function
 * Needed to do this because of async action thunk
 * See:  https://gist.github.com/krstffr/245fe83885b597aabaf06348220c2fe9
 */
export const getProductJobsDebounced = (project_id) => (dispatch, getState) => getProductJobsDebounceInnerFunc(dispatch, getState, project_id)


// Get products immediately without debounce
export function getProductJobs(project_id) {
  return (dispatch, getState) => {
    dispatch(setRequestInProgress(true, 'PRODUCT_JOBS'))
    Products.getProductsJobsByProjectId(project_id).then(response => {
      // if (response.data.length > 0 && productJobs.length > 0) {
      // response.data.forEach((job, index) => {
      // check existing jobs that match
      // if (productJobs[index].id === job.id) {
      // if (job.current_task && job.current_task.executor === 'human'
      //   && job.status === 'paused'
      //   && productJobs[index].status === 'running'
      //   && job.current_task.type.id === 'refine-markers') {
      //   // send browser notification
      //   Push.create('Spexi Flight Dashboard', {
      //     body: 'ACTION REQUIRED: Please refine GCP markers.',
      //     icon: 'https://fly.spexigeo.com/android-chrome-256x256.png',
      //     timeout: 7000,
      //     onClick: function () {
      //       window.focus()
      //       this.close()
      //     }
      //   })
      // }
      //     }
      //   })
      // }
      if (history.location.pathname.includes(project_id)) {
        dispatch(setProductJobs(response.data))
      }
    }).catch(console.error)
      .finally(() => {
        dispatch(setRequestInProgress(false, 'PRODUCT_JOBS'))
      })
  }
}


export function deleteProduct(productId) {
  return (dispatch, getState) => {
    dispatch(setRequestInProgress(true, 'GLOBAL'))
    Products.delete(productId)
      .then((result) => {
        if (result.success) {
          const { project } = getState()
          dispatch(setProductForm(null))
          dispatch(getProducts(project.id))
          dispatch(getProductJobs(project.id))
        } else {
          dispatch(setAlertModal({ message: result.error, type: alertTypes.error }))
        }
      })
      .finally(() => {
        dispatch(setRequestInProgress(false, 'GLOBAL'))
      })
      .catch((error) => {
        dispatch(setAlertModal({ message: `An error occurred while handling the request (${error})`, type: alertTypes.error }))
      })
  }
}

export function cancelProductJob(productJobId) {
  return (dispatch, getState) => {
    dispatch(setRequestInProgress(true, 'GLOBAL'))
    Products.cancelJob(productJobId)
      .then(result => {
      }).catch(error => {
        dispatch(setAlertModal({ message: `An error occurred while attempting to cancel the job: ${error}`, type: alertTypes.error }))
      }).finally(() => {
        const { project } = getState()
        dispatch(setRequestInProgress(false, 'GLOBAL'))
        dispatch(setProductForm(null))
        dispatch(getProducts(project.id))
        dispatch(getProductJobs(project.id))
      })
  }
}

export function setIsEditingProductParameters(isEditingProductParameters) {
  return {
    type: 'SET_IS_EDITING_PRODUCT_PARAMETERS',
    isEditingProductParameters
  }
}

// gcps
export function setProductGCPsDataSuccess(gcps) {
  return {
    type: 'SET_PRODUCT_GCPS_DATA_SUCCESS',
    gcps
  }
}


export function getProductGCPs(id) {
  return (dispatch) => {
    dispatch(setRequestInProgress(true, 'GCPs'))
    GCPs.get(id)
      .then(response => {
        console.log(response)
        dispatch(setRequestInProgress(false, 'GCPs'))
        if (response.success) {
          // dispatch(setProductGCPsDataSuccess(response.data))
          dispatch(setProductForm({ gcps: response.data }))

          // if there are GCPs add the markers
          if (response.data && response.data.length > 0) {
            // convert gcps to markers and add to preview markers
            convertGcpsToMarkers(response.data, GCPmarkerImage, 'gcp-marker').then(markers => {
              dispatch(setPreviewMarkers(markers))
            })
          }
        }
      }).catch(error => {
        console.log(error)
      })
  }
}


export function setProductMapPreview(productMapPreview) {
  return {
    type: 'SET_PRODUCT_MAP_PREVIEW',
    productMapPreview
  }
}