import React, { useCallback, useState } from 'react'
import moment from 'moment'
import './styles.css'
import { OrganizationApi } from '../../../../api'
import { alertTypes } from '../../../../util/constants'
import { debounce } from 'lodash'

const MemberRow = (props) => {
  const {
    isAdmin,
    current_member,
    member,
    roles,
    timezone,
    setIsDeleteModalOpen,
    setSelectedMember,
    setMemberUpdate,
    checkPermissions,
    setAlertModal,
  } = props

  const [isRoleUpdating, setIsRoleUpdating] = useState(false)
  const [externalIdUpdateState, setExternalIdUpdateState] = useState({
    isLoading: false,
    isSuccess: false,
    isError: false,
  })
  const [externalId, setExternalId] = useState(member.external_id)

  const handleRoleChange = async (event) => {
    let target = event.target
    let value = target.value

    let updatedMember = {
      ...member,
      role_id: parseInt(value),
      type: roles[parseInt(value) - 1].name,
    }

    setIsRoleUpdating(true)
    const result = await OrganizationApi.updateMember(
      updatedMember.id,
      updatedMember
    )

    if (result.success) {
      setIsRoleUpdating(false)
      setMemberUpdate(true)
    } else {
      setIsRoleUpdating(false)
      setAlertModal({
        message: result.error,
        type: alertTypes.warning,
      })
    }
  }

  const handleExternalIdSubmit = async (id) => {
    const updatedMember = {
      ...member,
      external_id: id
    }
    try {
      setExternalIdUpdateState({...externalIdUpdateState, isLoading: true, isSuccess: false, isError: false })
      const result = await OrganizationApi.updateMemberExternalId(
        updatedMember.id,
        updatedMember
      )
      if (result.success) {
        setExternalIdUpdateState({...externalIdUpdateState, isLoading: false, isSuccess: true })
      } else {
        setExternalIdUpdateState({...externalIdUpdateState, isLoading: false, isError: true })
        setExternalId(member.external_id)
        setAlertModal({
          message: result.error,
          type: alertTypes.warning,
        })
      }
    } catch (e) {
      setExternalIdUpdateState({...externalIdUpdateState, isLoading: false, isError: true })
      setExternalId(member.external_id)
      setAlertModal({
        message: 'Error updating External ID',
        type: alertTypes.warning,
      })
    }
  }

  const handleExternalIdChangeDebounce = useCallback(
    debounce(
      (id) => {
        handleExternalIdSubmit(id)
      },
      500,
      { leading: false, trailing: true }
    ), []
  )

  const handleExternalIdChange = (event) => {
    let target = event.target
    let value = target.value
    setExternalId(value)
    handleExternalIdChangeDebounce(value)
  }

  const renderJoinedDate = () => {
    return moment(member.created_at).tz(timezone).format('MMMM Do YYYY')
  }

  const renderRole = () => {
    if (current_member && current_member.id === member.id) {
      return (  
        <div style={{ flex: 1, justifyContent: 'center'}}>
          <strong>{member.type}</strong>
        </div>
      )

    } else if (member.type === 'owner') {
      if (isAdmin) {
        return roleUpdateButton()
      } else {
        return (
          <div style={{ flex: 1, justifyContent: 'center'}}>
            <strong>{member.type}</strong>
          </div>
        )
      }
    } else if (member.type === 'admin') {
      return roleUpdateButton()
    } else if (member.type === 'member') {
      return roleUpdateButton()
    } else if (member.type === 'viewer') {
      return roleUpdateButton()
    } else if (member.type === 'external-pilot') {
      return roleUpdateButton()
    }
  }

  const roleUpdateButton = () => {
    let _roles = null
    if (!isAdmin) {
      _roles = roles.filter(function (obj) {
        return obj.id !== 1
      })
    } else {
      _roles = roles
    }
    return (
      <select
        name="role_id"
        style={{ width: '60%', marginLeft: '20%' }}
        onChange={(e) => handleRoleChange(e)}
        value={member.role_id}
        disabled={!checkPermissions(`${member.type}_update`)}
        required
      >
        <option value="" hidden disabled>
          {member.type}
        </option>
        {_roles.map((role) => {
          return (
            <option key={role.id} value={role.id}>
              {role.name}
            </option>
          )
        })}
      </select>
    )
  }

  const renderExternalId = () => {
    return (
      <input 
        name="external_id" 
        style={{ width: '60%', marginLeft: '20%' }}
        type={'text'} 
        value={externalId}
        onChange={(e) => handleExternalIdChange(e)}
      />
    )
  }

  const renderExternalUpdateStatus = () => {
    if (externalIdUpdateState.isLoading) {
      return renderComponentLoader()
    }
  }

  const renderDeleteButton = () => {
    if (current_member && current_member.id === member.id) {
      return <></>
    } else if (member.type === 'owner') {
      if (isAdmin) {
        return deleteButton()
      } else {
        return <></>
      }
    } else if (member.type === 'admin') {
      return deleteButton()
    } else if (member.type === 'member') {
      return deleteButton()
    } else if (member.type === 'viewer') {
      return deleteButton()
    } else if (member.type === 'external-pilot') {
      return deleteButton()
    }
  }

  const deleteButton = () => {
    return (
      <span>
        <button
          className="removeButton"
          onClick={handleDelete}
          disabled={!checkPermissions(`${member.type}_remove`)}
        >
          Delete
        </button>
      </span>
    )
  }

  const renderUploadedData = () => {
    return <div>{member.totalUploaded}</div>
  }

  const handleDelete = () => {
    setSelectedMember(member)
    setIsDeleteModalOpen(true)
  }

  const renderComponentLoader = () => {
    return (
      <div style={{ flex: '0 0 auto', width: '20%' }}>
        <div
          className="lds-ring"
          style={{
            height: '1rem',
            width: '1rem',
          }}
        >
          <div style={{ width: '1rem', height: '1rem' }}></div>
          <div style={{ width: '1rem', height: '1rem' }}></div>
          <div style={{ width: '1rem', height: '1rem' }}></div>
          <div style={{ width: '1rem', height: '1rem' }}></div>
        </div>
      </div>
    )
  }

  return (
    <tr className='table__row'>
      <td className='col-10 table-col'>
        {current_member && current_member.id === member.id ? (
          <span><strong>{member.name}</strong>&nbsp;(user)</span>
        ) : (
          <span>{member.name}</span>
        )}    
      </td>
      <td className='col-20'>
        {member.email}
      </td>
      <td className='col-20'>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {renderRole()}
          {isRoleUpdating && renderComponentLoader()}
        </div>
      </td>
      <td className='col-10'>
        {renderJoinedDate()}
      </td>
      <td className='col-10'>
        {renderUploadedData()}
      </td>
      <td className='col-20'>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {renderExternalId()}
          {renderExternalUpdateStatus()}
        </div>
      </td>
      <td className='col-10'>
        {renderDeleteButton()}
      </td>
    </tr>
  )
}

export default MemberRow
