import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './styles.css'

class AddProductAdvancedOptions extends Component {
  static propTypes = {
    flightPlans: PropTypes.array.isRequired,
    setIsUploadingGCPs: PropTypes.func.isRequired

  }

  // TODO: ugly
  onGcpClick = event => {
    this.props.setIsUploadingGCPs(!this.props.isUploadingGCPs)
    this.props.setIsEditingProductParameters(false)
  }

  onParametersClick = event => {
    this.props.setIsEditingProductParameters(!this.props.isEditingProductParameters)
    this.props.setIsUploadingGCPs(false)
  }

  render() {
    return (
      <section className="upload-advanced-options">
        <h4 className="upload-advanced-options__title">Advanced Options</h4>
        <div className="upload-advanced-options__list">
          {((this.props.productForm.type === 'map+3d' || this.props.productForm.type === 'map' || 
            this.props.productForm.type === 'panorama' || this.props.productForm.type === 'orbit' || this.props.productForm.type === 'gallery') &&
            <div className={this.props.isEditingProductParameters ? 'upload-advanced-options__item active' : 'upload-advanced-options__item'} onClick={this.onParametersClick}>
              <i className="fa fa-sliders" /><h4>Parameters</h4>
            </div>
          )}
          {(this.props.productForm.type === 'map' || this.props.productForm.type === 'map+3d') &&
            <div className={this.props.isUploadingGCPs ? 'upload-advanced-options__item active' : 'upload-advanced-options__item'} onClick={this.onGcpClick}>
              <i className="fa fa-flag-o" /><h4>Ground Control Points</h4>
            </div>
          }
        </div>
      </section>
    )

  }
}

export default AddProductAdvancedOptions