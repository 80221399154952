import { cellToBoundary, latLngToCell, gridDisk, cellToLatLng } from 'h3-js'
import * as turf from '@turf/turf'
export * from './epsg'
export * from './url'

export const isFunction = (functionToCheck) => {
  return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

// check a job or task to see if it has terminated
export const isTerminalState = (obj) => {
  return obj.status === 'failed' || obj.status === 'done'
}

// generate geojson polygon from h3 index
export const latLngToH3GeoJson = coordinate => {
  let h3Index = latLngToCell(coordinate[0], coordinate[1], 9)
  let geojson = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [cellToBoundary(h3Index, true)],
    },
    id: `${h3Index}`,
    properties: {
      id: `${h3Index}`,
    },
  }
  return geojson
}

// generate an array of Centroids for Michael's panos from a H3 Index
export const h3IndexToPanos = h3Index => {
  // Get data from the input H3 hexagon
  let center = cellToLatLng(h3Index)
  center.reverse()
  const mainHexCoords = cellToBoundary(h3Index, true)
  const mainHex = turf.polygon([[...mainHexCoords, mainHexCoords[0]]])

  // Get an array with all neighbour cells, it also includes the main hexagon
  const cellsArray = gridDisk(h3Index, 1)

  // Get the centroids of the neighbours and prepare the list for the sorting algorithm
  const centroids = cellsArray.map(h3 => {
    const coord = cellToLatLng(h3)
    return {x: coord[1], y: coord[0]}
  })

  // Sorts the centroids clockwise
  let startAng;
  centroids.forEach(point => {
      var ang = Math.atan2(point.y - center[1],point.x - center[0]);
      if(!startAng){ startAng = ang }
      else {
          if(ang < startAng){  // ensure that all points are clockwise of the start point
              ang += Math.PI * 2;
          }
      }
      point.angle = ang; // add the angle to the point
  });
  centroids.sort((a,b)=> a.angle - b.angle);

  // Return the centroids to the [lng, lat] format
  let centroidArray = centroids.map(c => [c.x, c.y])

  // Removes the main center coordinate from the array
  centroidArray = centroidArray.filter(c => {
    return c[0] !== center[0] && c[1] !== center[1]
  })

  // For each pano, creates a triangle made by the center of the main hex and 2 centroids,
  const panoCentroids = centroidArray.map((c, i) => {
    const triangle = turf.polygon([[center, centroidArray[i], centroidArray[i + 1] || centroidArray[0], center]])
    // Get the intersection of the mainhex with the current triangle and calculates the centroid.
    const intersection = turf.intersect(mainHex, triangle);
    if (intersection) {
      return turf.centroid(intersection)
    }
    return null
  })

  return panoCentroids
}
