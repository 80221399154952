import proj4 from 'proj4'
import { buildQueryParams } from '../util'
import { EpsgApiProxy } from '../api'

const CRS_MAP = {
  all: { basePath: 'CoordRefSystem', name: 'all' },
  compound: { basePath: 'CompoundCoordRefSystem', name: 'compound' },
  engineering: { basePath: 'EngineeringCoordRefSystem', name: 'engineering' },
  geographic: { basePath: 'GeodeticCoordRefSystem', name: 'geographic' },
  projected: { basePath: 'ProjectedCoordRefSystem', name: 'projected' },
  vertical: { basePath: 'VerticalCoordRefSystem', name: 'vertical' },
}

// WKT version 1
const WKT_CS_MAP = {
  COMPD_CS: 'compound',
  GEOGCS: 'geographic',
  GEOCCS: 'geographic',
  PROJCS: 'projected',
  VERT_CS: 'vertical',
}

export const DEFAULT_CRS = { id: 'EPSG::4326', name: 'WGS 84', type: 'geographic 2D' }
export const WGS84_CODE = '4326'

// some of the CRS we used to present to the user before we had the CRS picker
export const COMMON_CRS_LIST = [
  DEFAULT_CRS,
  { id: 'EPSG::4617', name: 'NAD83(CSRS)', type: 'geographic 2D' },
  { id: 'EPSG::6649', name: 'NAD83(CSRS) + CGVD2013 height', type: 'compound' },
  { id: 'EPSG::3157', name: 'NAD83(CSRS) / UTM zone 10N', type: 'projected'},
  { id: 'EPSG::6647', name: 'CGVD2013 height', type: 'vertical' },
  { id: 'EPSG::5713', name: 'CGVD28 height', type: 'vertical' },
  { id: 'EPSG::5773', name: 'EGM96 height', type: 'vertical' },
]

export const getCrsTypes = () => { return Object.keys(CRS_MAP) }

export const getPathForCrsType = (crsType) => { return CRS_MAP[crsType].basePath }

export const crsCompoundEligible = (crsType) => {
  // https://docs.geotools.org/latest/javadocs/org/opengis/referencing/crs/CompoundCRS.html
  // according to above link, compound CRS can be made from geographic 2d, projected and engineering
  // we will simply restrict the 2nd to vertical
  const crsTypeClean = crsType.toLowerCase().trim()
  return crsTypeClean === 'geographic 2d' || crsTypeClean === 'projected' || crsTypeClean === 'engineering'
}

const handleTextResponse = async (response) => {
  // make sure we didn't get unauth...
  if (response.status === 401) {
    localStorage.removeItem('jwt-token')
    window.location.href = '/'
    return ''
  }

  // check to make sure we succeeded...
  if (!response.ok) {
    throw new Error(`Attempted to fetch WKT but the call failed with code ${response.status}: ${response.statusText}`)
  }

  // return the body text containing the WKT string
  const text = await response.text()
  return text
}

export const fetchWkt = async (code, format='wkt', formatVersion=1) => {
  const path = `${CRS_MAP.all.basePath}/${code}/export`
  const query = buildQueryParams({ format, formatVersion })
  const request = EpsgApiProxy.getRequest(path, query)
  const response = await fetch(request)

  // return the body text containing the WKT string
  const text = await handleTextResponse(response)
  return text
}

export const fetchProj4 = async(auth, code) => {
  const path = `${auth}/${code}`
  const request = EpsgApiProxy.getRequest(path, '', 'proj4')
  const response = await fetch(request)

  // return the body text containing the proj4 string
  const text = await handleTextResponse(response)
  return text
}

const crsIdRegex = /([a-z]+)::?(\d+)/i
const matchCrsId = (crsId) => {
  const match = crsIdRegex.exec(crsId.trim())
  if (match === null || match.length !== 3) {
    throw new Error(`Failed to match CRS ID "${crsId}"`)
  }
  return match
}

export const getCodeFromCrsId = (crsId) => {
  return matchCrsId(crsId)[2]
}

export const getAuthFromCrsId = (crsId) => {
  return matchCrsId(crsId)[1]
}

export const getAuthAndCodeFromCrsId = (crsId) => {
  const match = matchCrsId(crsId)
  return { auth: match[1], code: match[2] }
}

export const printCrs = (crs) => {
  return `${crs.name} (${crs.id})`
}

export const proj4FriendlyName = (crs) => {
  return crs.id.replace('::', ':')
}

export const getHorizontalCrs = async (crs) => {
  const path = `${CRS_MAP.compound.basePath}/${getCodeFromCrsId(crs.id)}`
  const compoundCrs = await EpsgApiProxy.get(path)

  if (compoundCrs.hasOwnProperty('HorizontalCrs') && compoundCrs.HorizontalCrs !== null) {
    return { id: `EPSG::${compoundCrs.HorizontalCrs.Code}`, name: compoundCrs.HorizontalCrs.Name }
  }

  throw new Error(`Failed to find a suitable horizontal CRS from compound CRS: ${printCrs(crs)}`)
}

export const createProjConverter = async (inCrs, outCrs) => {
  // NOTE: proj4 isn't able to parse compound CRS, so try to get the horizontal portion
  if (inCrs.type === CRS_MAP.compound.name) {
    inCrs = await getHorizontalCrs(inCrs)
  }

  // we'll fetch WKT files in parallel, if needed
  let promises = []

  // check if proj4 already has them defined, if so we'll just use that
  const inProj4Name = proj4FriendlyName(inCrs)
  if (proj4.defs.hasOwnProperty(inProj4Name)) {
    promises.push(inProj4Name)
  } else {
    // otherwise we need to fetch it from the api
    const authCode = getAuthAndCodeFromCrsId(inCrs.id)
    promises.push(fetchProj4(authCode.auth, authCode.code))
  }

  const outProj4Name = proj4FriendlyName(outCrs)
  if (proj4.defs.hasOwnProperty(outProj4Name)) {
    promises.push(outProj4Name)
  } else {
    // otherwise we need to fetch it from the api
    const authCode = getAuthAndCodeFromCrsId(outCrs.id)
    promises.push(fetchProj4(authCode.auth, authCode.code))
  }

  // fetch in parallel and create a converter
  const values = await Promise.all(promises)
  return proj4(values[0], values[1])
}

export const checkLatLng = (lat, lng) => {
  if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
    return false
  }

  return true
}

const wktNameRegex = /^([a-z_]+)\["([^"]+)".+/i
export const fetchCrs = async (crsId) => {
  const wkt = await fetchWkt(getCodeFromCrsId(crsId.trim()))
  const match = wktNameRegex.exec(wkt)
  if (match === null || match.length !== 3) {
    return null
  }

  const crsType = match[1] in WKT_CS_MAP ? WKT_CS_MAP[match[1]] : ''
  return { id: crsId, name: match[2], type: crsType }
}

export const convertGcpsToMarkers = async (gcps, markerIcon, className) => {
  let markers = []

  // sanity check - no gcps or no projection string, assume its already in WGS84 and return
  if (gcps.length === 0 || !gcps[0].projection) {
    return markers
  }

  // check for a compound projection string - for the proj converter we only deal with horizontal
  const crsId = gcps[0].projection.split('|')[0]

  // pull out the code - if its already in WGS84 just convert direct to markers without any conversion
  const code = getCodeFromCrsId(crsId)
  if (code === WGS84_CODE) {
    // convert to markers and return
    gcps.forEach(gcp => {
      markers.push({
        coordinates: [Number(gcp.point.coordinates[1]), Number(gcp.point.coordinates[0])],
        name: gcp.label,
        icon: markerIcon,
        className: className,
        gcp_id: gcp.id
      })
    })

    return markers
  }

  // create a proj converter
  const crs = await fetchCrs(crsId)
  const proj = await createProjConverter(crs, DEFAULT_CRS)

  // convert the coordinates into WGS84 and create markers
  gcps.forEach(gcp => {
    const coords = proj.forward([Number(gcp.point.coordinates[0]), Number(gcp.point.coordinates[1])])
    markers.push({
      coordinates: coords.reverse(), // needs to be lat/lng order!
      name: gcp.label,
      icon: markerIcon,
      className: className,
      gcp_id: gcp.id
    })
  })

  return markers
}

export const getUtmZone = (lat, lng) => {
  if (!checkLatLng(lat, lng)) {
    throw new Error(`Failed to get the WGS84 UTM code due to invalid lat/lng: (${lat}, ${lng})`)
  }

  // each zone covers 6 degrees of longitude
  let zone = Math.ceil((lng + 180) / 6)
  if (zone < 1) {
    // edge case when lng is exactly -180
    zone = 1
  }

  if (zone > 60) {
    zone = 60
  }

  return zone
}

export const getWgs84UtmCode = (lat, lng) => {
  const zone = getUtmZone(lat, lng)

  // codes are numbered from 32601 to 32660 for UTM Zones 1N to 60N
  // codes are numbered from 32701 to 32760 for UTM Zones 1S to 60S
  const baseCode = (lat >= 0) ? 32600 : 32700

  const epsgCode = baseCode + zone
  return `EPSG::${epsgCode}`
}

export const getUtmZoneName = (lat, lng) => {
  const zone = getUtmZone(lat, lng)

  // above equator is N, below is S
  const dir = (lat >= 0) ? 'N' : 'S'

  return `UTM Zone ${zone}${dir}`
}
