import React, { useState, useEffect } from 'react'
import MemberRow from './MemberRow'
import './styles.css'

const OrganizationMembers = (props) => {
  const {
    isAdmin,
    current_member,
    members,
    roles,
    timezone,
    setIsDeleteModalOpen,
    setSelectedMember,
    setMemberUpdate,
    checkPermissions,
    setAlertModal,
    setCurrentPageNumber,
  } = props

  const [numOfPages, setNumPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(null)

  useEffect(() => {
    let pageArray = []
    for (let i = 1; i < members.last_page + 1; i++) {
      pageArray.push(i)
    }
    setCurrentPage(members)
    setNumPages(pageArray)
  }, [members])

  const renderOrganizationMembers = () => {
    return currentPage.data.map((member, i) => {
      return (
        <MemberRow
          key={member.id}
          isAdmin={isAdmin}
          current_member={current_member}
          member={member}
          roles={roles}
          timezone={timezone}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setSelectedMember={setSelectedMember}
          setMemberUpdate={setMemberUpdate}
          checkPermissions={checkPermissions}
          setAlertModal={setAlertModal}
        />
      )
    })
  }

  const getPagination = () => {
    return numOfPages.map((page, i) => {
      return (
        <button
          key={i}
          onClick={() => toPage(page)}
          style={
            page === currentPage.current_page ? { background: '#ddd' } : {}
          }
        >
          {page}
        </button>
      )
    })
  }

  const previousPage = async () => {
    setCurrentPageNumber(members.current_page - 1)
  }

  const nextPage = async () => {
    setCurrentPageNumber(members.current_page + 1)
  }

  const toPage = async (page) => {
    setCurrentPageNumber(page)
  }

  return (
    <>
      <table className="table__outer">
        <thead>
          <tr className="table__col">
            <th className='col-10' scope="col">Name</th>
            <th className='col-20' scope="col">Email</th>
            <th className='col-20' scope="col">Role</th>
            <th className='col-10' scope="col">Joined</th>
            <th className='col-10' scope="col">Uploaded</th>
            <th className='col-20' scope="col">External ID (Optional)</th>
            <th className='col-10' scope="col"></th>
          </tr>
        </thead>
        <tbody>{currentPage && renderOrganizationMembers()}</tbody>
      </table>

      {currentPage && (
        <>
          <div className="pagination">
            <button
              onClick={previousPage}
              disabled={!currentPage.prev_page_url}
            >
              &laquo;
            </button>
            {numOfPages && getPagination()}
            <button onClick={nextPage} disabled={!currentPage.next_page_url}>
              &raquo;
            </button>
          </div>
        </>
      )}
    </>
  )
}

export default OrganizationMembers
