import React, { Component } from 'react'
import Profile from '../components/Profile/'

class ProfileContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: '',
      'email': '',
      'password': ''
    }
  }

  componentDidMount() {
    // get updated user info when component mounts
    this.props.getLoggedInUser()
  }


  handleInput = () => {

  }


  /**
   * log in form submitted
   */
  onFormSubmit = (e) => {
    e.preventDefault()
  }


  render() {
    if (this.props.user && this.props.user.profile) {
      return <Profile {...this.props}	/>
    }
    return null
  }
}

export default ProfileContainer
