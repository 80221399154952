import React, { Component } from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import InfoIcon from '@material-ui/icons/Info'
import { alertTypes } from '../../util/constants'
import './styles.css'

class AlertModal extends Component {
  confirmHandler = () => {
    if (this.props.confirmHandler) {
      this.props.confirmHandler()
    }
    if (this.props.setAlertModal) {
      this.props.setAlertModal(null)
    }
  }

  closeHandler = () => {
    if (this.props.closeHandler) {
      this.props.closeHandler()
    }
    if (this.props.setAlertModal) {
      this.props.setAlertModal(null)
    }
  }

  render() {
    const { message, confirmLabel, closeLabel, cancellable, closeHandler, type } = this.props

    const visible = message && message.length ? true : false

    const renderIcon = () => {
      switch (type) {
        case alertTypes.success:
          return <CheckCircleOutlineIcon classes={{ root: 'alert-modal__icon success' }} />
        case alertTypes.warning:
          return <ErrorOutlineIcon classes={{ root: 'alert-modal__icon warning' }} />
        case alertTypes.info:
          return <InfoIcon classes={{ root: 'alert-modal__icon info' }} />
        case alertTypes.error:
          return <ErrorOutlineIcon classes={{ root: 'alert-modal__icon error' }} />
        default:
          return <></>
      }
    }

    if (visible) {
      return (
        <section className="alert-modal visible">
          <div className="alert-modal__content">
            <div className="alert-modal__messagebox">
              {renderIcon()}
              <p className="alert-modal__message">{message}</p>
            </div>

            <div className="alert-modal__buttons">
              <button type="button" className="btn solid btn-success" onClick={this.confirmHandler}>
                {confirmLabel ? confirmLabel : 'OK'}
              </button>
              {(closeHandler || cancellable) && (
                <button type="button" className="btn white" onClick={this.closeHandler}>
                  {closeLabel ? closeLabel : 'Cancel'}
                </button>
              )}
            </div>
          </div>
        </section>
      )
    } else {
      return <></>
    }
  }
}

export default AlertModal
