import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ActionCreators } from '../actions'
import LoadingAnimation from '../components/LoadingAnimation'

class AuthProviderContainer extends Component {
  constructor(props) {
    super(props)
    let provider = this.props.match.params.provider
    let urlString = window.location.href.split('callback')[1]
    this.props.loginAuthProvider(provider, urlString)
  }

  render() {
    return <LoadingAnimation visible={true} />

  }
}

const mapStateToProps = state => {
  return {
    userIsVerified: state.userIsVerified,
    userIsLoggedIn: state.userIsLoggedIn,
    requestsInProgress: state.request,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(ActionCreators, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(AuthProviderContainer)