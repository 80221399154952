import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import AppContainer from './containers/AppContainer'

const render = Component => {
  ReactDOM.render((
    <Component />
  ), document.getElementById('root'))
}
render(AppContainer)


// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./containers/AppContainer', () => { render(AppContainer) })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', event => {
        if (event.target.state === 'activated') {
          let confirmed = window.updateFound()
          if(confirmed) {
            window.location.reload()
          }
        }
      })
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
    }
  }
})

window.updateFound = () => {
  let userReload = window.confirm('A new version of the Flight Dashboard has been released. Refresh to see it?')
  if (userReload) {
    return true
  }
}