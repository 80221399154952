import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment-timezone'
import { convertStr } from '../../helpers'
import FlightPlanList from '../FlightPlanList/'
import { PLAYER_URL } from '../../config'
import './styles.css'

import { CSSTransition } from 'react-transition-group'
import AddFlight from '../AddFlight/'

class ProjectPlan extends Component {
  static propTypes = {
    setIsAddingProject: PropTypes.func.isRequired,
    setOpenSingleProject: PropTypes.func.isRequired,
    fetchProject: PropTypes.func.isRequired,
    setPreviewMarkers: PropTypes.func.isRequired,
    editProject: PropTypes.func.isRequired,
    deleteProject: PropTypes.func.isRequired,
    backToProjectList: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    requestsInProgress: PropTypes.object,
    flightPlans: PropTypes.array,
    match: PropTypes.object,
  }

  render() {
    if (this.props.project.length === 0 && this.props.flightPlans > 0) {
      return <div />
    }

    let statusIndictatorLabel
    let getDirectionsButton

    if (this.props.project.status) {
      statusIndictatorLabel = convertStr(this.props.project.status)

      if (this.props.project.flightPlans.length > 0) {
        let lat = this.props.project.centroid.coordinates[1]
        let lng = this.props.project.centroid.coordinates[0]
        getDirectionsButton = (
          <p>
            <a
              className="btn white"
              href={`https://maps.google.com/?daddr=${lat},${lng}&amp;z=12`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Directions
            </a>
          </p>
        )
      }
    }

    return (
      <article
        className="project-plan project-content"
        key={this.props.project.id}
        ref={(el) => {
          this.projectFullElement = el
        }}
      >
        {Object.keys(this.props.project).length !== 0 && (
          <div className="project-details">
            <div className="project-card">
              <div className="project-card__header">
                <h3 className="project-name">{this.props.project.name}</h3>

                <div className="flight-plan-count">
                  {this.props.project.flightPlans.length} flight plan
                  {this.props.project.flightPlans.length > 1 && 's'}
                </div>
              </div>
              {/* {this.props.project.distance !== undefined && (
                <p className="project-distance">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.489 13.581">
                    <path d="M9.245,2A4.277,4.277,0,0,0,5,6.315c0,3.236,4.245,8.013,4.245,8.013s4.245-4.777,4.245-8.013A4.277,4.277,0,0,0,9.245,2Zm0,5.856A1.541,1.541,0,1,1,10.76,6.315,1.529,1.529,0,0,1,9.245,7.856Z" transform="translate(-4.5 -1.5)" />
                  </svg> {(this.props.project.distance / 1000).toFixed(1)} km away
                </p>
              )} */}

              {this.props.project.created_at !== undefined && (
                <p className="project-time">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 11.164 11.349"
                  >
                    <path
                      d="M7.576,2A5.626,5.626,0,0,0,2,7.674a5.626,5.626,0,0,0,5.576,5.674,5.632,5.632,0,0,0,5.587-5.674A5.632,5.632,0,0,0,7.576,2Zm.006,10.214a4.54,4.54,0,1,1,4.465-4.539A4.5,4.5,0,0,1,7.582,12.214Zm.279-7.377H7.024v3.4l2.93,1.787.419-.7L7.861,7.816Z"
                      transform="translate(-2 -2)"
                    />
                  </svg>{' '}
                  Posted{' '}
                  {moment(this.props.project.created_at)
                    .tz(this.props.userTimezone)
                    .fromNow()}
                </p>
              )}

              {this.props.user &&
                this.props.user.isAdmin &&
                this.props.project.operator && (
                <div className="project-users-assigned">
                  <h4>Operator:</h4>
                  <p className="project-user">
                    <svg viewBox="0 0 482.9 482.9">
                      <path d="M239.7 260.2h3.2c29.3-.5 53-10.8 70.5-30.5 38.5-43.4 32.1-117.8 31.4-124.9-2.5-53.3-27.7-78.8-48.5-90.7C280.8 5.2 262.7.4 242.5 0H240.8c-11.1 0-32.9 1.8-53.8 13.7-21 11.9-46.6 37.4-49.1 91.1-.7 7.1-7.1 81.5 31.4 124.9 17.4 19.7 41.1 30 70.4 30.5zm-75.1-152.9c0-.3.1-.6.1-.8 3.3-71.7 54.2-79.4 76-79.4H241.9c27 .6 72.9 11.6 76 79.4 0 .3 0 .6.1.8.1.7 7.1 68.7-24.7 104.5-12.6 14.2-29.4 21.2-51.5 21.4h-1c-22-.2-38.9-7.2-51.4-21.4-31.7-35.6-24.9-103.9-24.8-104.5z" />
                      <path d="M446.8 383.6v-.3c0-.8-.1-1.6-.1-2.5-.6-19.8-1.9-66.1-45.3-80.9-.3-.1-.7-.2-1-.3-45.1-11.5-82.6-37.5-83-37.8-6.1-4.3-14.5-2.8-18.8 3.3-4.3 6.1-2.8 14.5 3.3 18.8 1.7 1.2 41.5 28.9 91.3 41.7 23.3 8.3 25.9 33.2 26.6 56 0 .9 0 1.7.1 2.5.1 9-.5 22.9-2.1 30.9-16.2 9.2-79.7 41-176.3 41-96.2 0-160.1-31.9-176.4-41.1-1.6-8-2.3-21.9-2.1-30.9 0-.8.1-1.6.1-2.5.7-22.8 3.3-47.7 26.6-56 49.8-12.8 89.6-40.6 91.3-41.7 6.1-4.3 7.6-12.7 3.3-18.8-4.3-6.1-12.7-7.6-18.8-3.3-.4.3-37.7 26.3-83 37.8-.4.1-.7.2-1 .3-43.4 14.9-44.7 61.2-45.3 80.9 0 .9 0 1.7-.1 2.5v.3c-.1 5.2-.2 31.9 5.1 45.3 1 2.6 2.8 4.8 5.2 6.3 3 2 74.9 47.8 195.2 47.8s192.2-45.9 195.2-47.8c2.3-1.5 4.2-3.7 5.2-6.3 5-13.3 4.9-40 4.8-45.2z" />
                    </svg>
                    {this.props.project.operator.name} (
                    {this.props.project.operator.email})
                  </p>
                </div>
              )}

              {this.props.project.status === 'draft' && (
                <p className="project-status">{statusIndictatorLabel}</p>
              )}
            </div>
            {this.props.project.public > 0 && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${PLAYER_URL}/${
                  this.props.project.slug
                    ? this.props.project.slug
                    : this.props.project.id
                }`}
                className="share-icon external"
                style={{ position: 'absolute', top: '37px', right: '0' }}
              >
                <svg height={10} viewBox="0 0 384 384" width={10}>
                  <path d="M374 0h-99.7c-5.523 0-10 4.477-10 10s4.477 10 10 10h75.712L155.5 216.379a9.997 9.997 0 0 0-2.746 9.766 9.997 9.997 0 0 0 16.945 4.308L364 34.301v75.398c0 5.524 4.477 10 10 10s10-4.476 10-10V10c0-5.523-4.477-10-10-10zm0 0" />
                  <path d="M324.137 214.492c-5.524 0-10 4.477-10 10V334c-.02 16.563-13.438 29.98-30 30H50c-16.562-.02-29.98-13.438-30-30V99.863c.02-16.562 13.438-29.98 30-30h109.508c5.523 0 10-4.476 10-10 0-5.523-4.477-10-10-10H50c-27.602.032-49.969 22.399-50 50V334c.031 27.602 22.398 49.969 50 50h234.137c27.601-.031 49.968-22.398 50-50V224.492c0-5.523-4.477-10-10-10zm0 0" />
                </svg>
                <span>Share</span>
              </a>
            )}
            <div className="full-width">
              {this.props.project.description && (
                <div className="project-notes">
                  <p>
                    Details
                    <br />
                    {this.props.project.description}
                  </p>
                </div>
              )}

              {this.props.requestsInProgress.GET_FLIGHTS && (
                <div className="lds-ring" style={{ marginBottom: '2rem' }}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}

              {this.props.flightPlans && this.props.flightPlans.length > 0 && (
                <FlightPlanList {...this.props} />
              )}

              {this.props.project.isOrgProject && this.props.project.currentUserOrgPermissions && !this.props.project.currentUserOrgPermissions.includes('project_edit') ? (
                <>
                  {this.props.flightPlans &&
                    this.props.flightPlans.length === 0 &&
                    !this.props.requestsInProgress.GET_FLIGHTS && (
                    <p style={{ marginBottom: '2rem', fontSize: '0.9rem' }}>
                        No flight plans found.{' '}
                      <span
                        style={{ cursor: 'pointer', color: '#6AAEC4' }}
                        onClick={() => this.props.setIsAddingFlightPlan(true)}
                      >
                        {' '}
                          Add some...
                      </span>
                    </p>
                  )}
                  <CSSTransition
                    in={
                      this.props.isAddingFlightPlan &&
                      !this.props.isEdittingFlightPlan
                    }
                    timeout={300}
                    classNames="addFlight"
                    unmountOnExit
                  >
                    <AddFlight {...this.props} />
                  </CSSTransition>
                </>
              ) : (
                <>
                  {this.props.flightPlans &&
                    this.props.flightPlans.length === 0 &&
                    !this.props.requestsInProgress.GET_FLIGHTS && (
                    <p style={{ marginBottom: '2rem', fontSize: '0.9rem' }}>
                        No flight plans found.{' '}
                      <Link to={`/project/${this.props.project.id}/edit`}>
                        <span
                          onClick={() =>
                            this.props.editProject(this.props.project.id)
                          }
                        >
                          {' '}
                            Add some...
                        </span>
                      </Link>
                    </p>
                  )}
                  <p>
                    <Link to={`/project/${this.props.project.id}/edit`}>
                      <button
                        className="btn white"
                        onClick={() =>
                          this.props.editProject(this.props.project.id)
                        }
                      >
                        {' '}
                        Edit Project
                      </button>
                    </Link>
                  </p>
                </>
              )}
              {getDirectionsButton}
            </div>
          </div>
        )}
      </article>
    )
  }
}

export default ProjectPlan
