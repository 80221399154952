import React from 'react'
import PropTypes from 'prop-types'

const MapAreaIcon = (props) => {
  const {width = 57, height = 43 } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 57 43" >
      <g className="annotation__icon" data-name="Group 2316" transform="translate(-27 -309)">
        <circle id="Ellipse_96" data-name="Ellipse 96" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(27 328)" fill="#6aaec4" />
        <g id="Group_2308" data-name="Group 2308">
          <circle id="Ellipse_95" data-name="Ellipse 95" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(42 309)" fill="#6aaec4"/>
          <circle id="Ellipse_97" data-name="Ellipse 97" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(40 344)" fill="#6aaec4"/>
          <circle id="Ellipse_98" data-name="Ellipse 98" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(65 309)" fill="#6aaec4"/>
          <circle id="Ellipse_99" data-name="Ellipse 99" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(81 330)" fill="#6aaec4"/>
          <circle id="Ellipse_100" data-name="Ellipse 100" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(73 349)" fill="#6aaec4"/>
          <g id="Group_2307" data-name="Group 2307">
            <g id="Group_2294" data-name="Group 2294" transform="translate(-238 -283.535)">
              <path id="Path_299" data-name="Path 299" className="cls-2" d="M14467.9,6517.269l-15.561,18.785,13.325,16.269,32.89,5.1,8.408-18.939-16.169-21.215Z" transform="translate(-14186 -5923)" fill="#6aaec4" opacity="0.25"/>
              <path id="Path_300" data-name="Path 300" className="cls-3" d="M14467.9,6517.269l-15.561,18.785,13.325,16.269,32.89,5.1,8.408-18.939-16.169-21.215Z" transform="translate(-14186 -5923)" fill="#6aaec4" opacity="0.25"/>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

MapAreaIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

export default MapAreaIcon