import React, { useState } from 'react'
import {
  Grid,
  DialogContent,
  TextField,
  Box,
  Tab,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
} from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import MaterialTable from 'material-table'
import { Auth, OrganizationApi } from '../../api'
import { alertTypes } from '../../util/constants'

const MembersTab = (props) => {
  const {
    organization,
    members,
    setAlertModal,
    membersUpdated,
    handleDialogClose,
  } = props

  const [tabValue, setTabValue] = useState('1')
  const [addMembersFormLength, setAddMembersFormLength] = useState(4)
  const [membersToDelete, setMembersToDelete] = useState([])
  const [membersToAdd, setMembersToAdd] = useState([])

  const columns = [
    { title: 'Name', field: 'name', render: (rowData) => rowData.name },
    { title: 'Email', field: 'email', render: (rowData) => rowData.email },
    { title: 'Role', filed: 'role', render: (rowData) => rowData.type },
    {
      title: '',
      field: '',
      render: (rowData) => (
        <button
          type="button"
          className="btn-primary-solid admin-organizations__btn-small"
          onClick={() => {
            let exists = membersToDelete.find((ele) => ele.id === rowData.id)
              ? true
              : false
            if (!exists) {
              setMembersToDelete([...membersToDelete, rowData])
            } else {
              setMembersToDelete(
                membersToDelete.filter((ele) => ele.id !== rowData.id)
              )
            }
          }}
        >
          {membersToDelete.find((ele) => ele.id === rowData.id)
            ? 'Undo'
            : 'Delete'}
        </button>
      ),
    },
  ]

  const renderCurrentMembers = () => {
    return (
      <MaterialTable
        title="Members"
        columns={columns}
        data={members}
        style={{ width: '50rem' }}
        options={{
          rowStyle: (rowData) => ({
            color:
              membersToDelete.find((ele) => ele.id === rowData.id) && '#AAA',
            textDecoration:
              membersToDelete.find((ele) => ele.id === rowData.id) &&
              'line-through',
          }),
        }}
      />
    )
  }

  const renderAddMembers = () => {
    let membersFormArray = []
    for (let i = 0; i < addMembersFormLength; i++) {
      membersFormArray.push(
        <>
          <Grid item xs={6}>
            <TextField
              label="Email"
              type="email"
              style={{ width: '60%' }}
              value={membersToAdd[i] ? membersToAdd[i].email : ''}
              onInput={(e) => {
                let updated = membersToAdd.slice()
                updated[i] = { ...updated[i], email: e.target.value }
                setMembersToAdd(updated)
              }}
              error={membersToAdd[i] && membersToAdd[i].error}
              helperText={membersToAdd[i] && membersToAdd[i].error}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel>Roles</InputLabel>
              <Select
                labelId="role-select"
                id="role_id"
                label="Roles"
                value={membersToAdd[i] ? membersToAdd[i].role_id : ''}
                required={membersToAdd[i] ? true : false}
                disabled={!membersToAdd[i]}
                style={{ width: '30%' }}
                onChange={(e) => {
                  let updated = membersToAdd.slice()
                  updated[i] = { ...updated[i], role_id: e.target.value }
                  setMembersToAdd(updated)
                }}
              >
                <MenuItem value="2">Admin</MenuItem>
                <MenuItem value="3">Member</MenuItem>
                <MenuItem value="4">Viewer</MenuItem>
                <MenuItem value="5">Pilot</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </>
      )
    }

    return (
      <DialogContent>
        <button
          type="button"
          className="btn-primary-white admin-organizations-dialog__btn"
          onClick={() => setAddMembersFormLength(addMembersFormLength + 4)}
          style={{ marginBottom: '1rem' }}
        >
          Add More
        </button>
        <Grid container spacing={3}>
          {membersFormArray}
        </Grid>
      </DialogContent>
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    // if there exists members to Delete, batch delete members and update members state
    if (membersToDelete.length) {
      let body = { data: membersToDelete }
      OrganizationApi.batchDeleteMembers(body).then((resp) => {
        if (resp && resp.success) {
          setMembersToDelete([])
          membersUpdated(organization.id)
          setAlertModal({
            message: 'Members Successfully Delete',
            type: alertTypes.success,
          })
        } else {
          setAlertModal({
            message: '[Error] Member Delete Error',
            type: alertTypes.error,
          })
        }
      })
    }

    // if there exists members to add, recursively add members and update members state
    // if adding members returns error, display error message
    let filePromises = membersToAdd.map(
      (member) =>
        new Promise((resolve, reject) => {
          if (member !== null) {
            Auth.doesExist(member.email)
              .then((resp) => {
                if (resp && resp.success) {
                  member = {
                    email: member.email,
                    organization_id: organization.id,
                    user_id: resp.data.id,
                    role_id: member.role_id,
                    valid: true,
                  }
                } else {
                  member = {
                    email: member.email,
                    role_id: member.role_id,
                    valid: false,
                    error: resp.error,
                  }
                }
              })
              .finally(() => {
                if (member.valid) {
                  OrganizationApi.addMember(organization.id, member).then(
                    (resp) => {
                      if (resp && resp.success) {
                        member = { ...member, saved: true }
                        resolve(member)
                      } else {
                        member = { ...member, saved: false, error: resp.error }
                        resolve(member)
                      }
                    }
                  )
                } else {
                  resolve(member)
                }
              })
          } else {
            resolve(member)
          }
        })
    )

    // for members that have been successfully saved, reset the array index consisting added member
    Promise.all(filePromises).then((members) => {
      for (let i = 0; i < members.length; i++) {
        if (members[i] !== null) {
          if (members[i].saved) members[i] = null
        }
      }
      setMembersToAdd(members)
      membersUpdated(organization.id)
    })

    // if there is no members to add/delete and submit was clicked, set alert message that no changes has been made
    if (!membersToDelete.length && !membersToAdd.length) {
      setAlertModal({
        message: 'No changes has been made',
        type: alertTypes.warning,
      })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ width: '100%' }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={(e, value) => {
                setTabValue(value)
              }}
            >
              <Tab label="Current Members" value="1" />
              <Tab label="Add Members" value="2" />
            </TabList>
            <TabPanel value="1">{renderCurrentMembers()}</TabPanel>
            <TabPanel value="2">{renderAddMembers()}</TabPanel>
          </Box>
          <DialogActions>
            <button
              type="button"
              className="btn-primary-white admin-organizations-dialog__btn"
              onClick={handleDialogClose}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn-primary-solid admin-organizations-dialog__btn right"
            >
              Save & Continue
            </button>
          </DialogActions>
        </TabContext>
      </Box>
    </form>
  )
}

export default MembersTab
