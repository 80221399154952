import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { OrganizationApi } from '../../api'
import { alertTypes } from '../../util/constants'
import './styles.css'
import AdminOrganizationsDialog from '../AdminOrganizationsDialog'

const AdminOrganizations = ({ setAlertModal }) => {
  const [openOrganizationsDialog, setOpenOrganizationsDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [organizations, setOrganizations] = useState([])
  const [currentOrg, setCurrentOrg] = useState(null)
  const [isModify, setIsModify] = useState(false)
  const [isUpdated, setIsUpdated] = useState(false)

  useEffect(() => {
    async function getAllOrganizations() {
      setIsLoading(true)
      const response = await OrganizationApi.getAll()

      if (response?.success) {
        let data = Object.keys(response.data).map((key) => {
          return response.data[key]
        })
        const sortedOrgs = data.sort(function (a, b) {
          if (a.id < b.id) return -1
          if (a.id > b.id) return 1
          return 0
        })

        // for each organizaitons, fetch all members of the organization
        let promises = sortedOrgs.map((org) => {
          return new Promise((resolve, reject) => {
            OrganizationApi.allMembers(org.id).then((resp) => {
              if (resp && resp.success) {
                org.members = resp.data
                resolve(org)
              } else {
                reject()
              }
            })
          })
        })
        Promise.all(promises).then(() => {
          setOrganizations(sortedOrgs)
          setIsLoading(false)
        })
      } else {
        setIsLoading(false)
        setAlertModal({
          message: response?.error || 'an error has occured',
          type: alertTypes.error,
        })
      }
    }

    getAllOrganizations()
  }, [isUpdated, setAlertModal])

  const updatePage = () => {
    setIsUpdated(!isUpdated)
  }

  const columns = [
    { title: 'Name', field: 'name', render: (rowData) => rowData.name },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => (rowData.active ? 'Active' : 'Not Active'),
    },
    {
      title: 'Signup Type',
      field: 'signup_type',
      render: (rowData) => rowData.signup_type,
    },
    {
      title: 'Members',
      field: 'members',
      render: (rowData) => (rowData.members ? rowData.members.length : 'NA'),
    },
    {
      title: '',
      field: '',
      render: (rowData) => (
        <>
          <button
            type="button"
            className="btn-primary-solid admin-organizations__btn-small"
            onClick={() => {
              setCurrentOrg(rowData)
              setIsModify(true)
              setOpenOrganizationsDialog(true)
            }}
          >
            Modify
          </button>
        </>
      ),
    },
  ]

  return (
    <section className="admin-organizations">
      <AdminOrganizationsDialog
        organization={currentOrg}
        isModify={isModify}
        open={openOrganizationsDialog}
        setOpenOrganizationsDialog={setOpenOrganizationsDialog}
        updatePage={updatePage}
        setAlertModal={setAlertModal}
      />

      <>
        <MaterialTable
          title="Organizations"
          columns={columns}
          data={organizations}
          isLoading={isLoading}
          components={{
            Toolbar: (props) => (
              <div className="admin-organizations__table-toolbar">
                <h1 className="sidebar-header__title">{props.title}</h1>
                <button
                  type="button"
                  className="btn-primary-white admin-organizations__btn"
                  onClick={() => {
                    setCurrentOrg(null)
                    setIsModify(false)
                    setOpenOrganizationsDialog(true)
                  }}
                >
                  Add new organization
                </button>
              </div>
            ),
          }}
          options={{
            actionsColumnIndex: -1,
            pageSize: 50,
            pageSizeOptions: [50, 100, 200],
            paging: true,
            exportButton: false,
            emptyRowsWhenPaging: false,
          }}
        />
      </>
    </section>
  )
}

export default AdminOrganizations
