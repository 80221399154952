import { MapFlightPlanParameters, OrbitFlightPlanParameters, PanoramaFlightPlanParameters } from 'spexi-pathify'
import { Camera } from 'spexi-pathify'

export function flightPlans(state = [], action) {
  switch (action.type) {
    case 'SET_FLIGHT_PLANS_FETCH_DATA_SUCCESS':
      return action.flightPlans
    case 'TOGGLE_FLIGHT_VISIBILITY':
      return state.map((flightPlan, index) => {
        if ((flightPlan.id !== undefined && flightPlan.id !== action.idOrindex && flightPlan.visible) || (flightPlan.id === undefined && index !== action.idOrindex && flightPlan.visible)) {
          return flightPlan
        }
        return { ...flightPlan, visible: !flightPlan.visible }

      })


    default:
      return state
  }
}


export function flightPlan(state = {}, action) {
  switch (action.type) {
    case 'SET_FLIGHT_PLAN_DATA_SUCCESS':
      return action.flightPlan
    default:
      return state
  }
}


const initialDrawing = {
  line: false,
  shape: false
}
export function drawingFlightMapObjects(state = initialDrawing, action) {
  switch (action.type) {
    case 'SET_DRAW_FLIGHT_MAP_OBJECTS':
      if (action.data.line === null) {
        state.line && state.line.remove()
        action.data.line = false
      }
      if (action.data.shape === null) {
        if (state.shape){
          state.shape.remove()
        }

        action.data.shape = false
      }
      if (action.data.startMarker === null) {
        state.startMarker && state.startMarker.remove()
        action.data.startMarker = false
      }
      if (action.data.endMarker === null) {
        state.endMarker && state.endMarker.remove()
        action.data.endMarker = false
      }
      if (action.data.centerMarker === null) {
        state.centerMarker && state.centerMarker.remove()
        action.data.centerMarker = false
      }
      if (action.data.reference === null) {
        state.reference && state.reference.remove()
        action.data.reference = false
      }

      if (action.data.takeOffLocationMarker === null) {
        state.takeOffLocationMarker && state.takeOffLocationMarker.remove()
        action.data.takeOffLocationMarker = false
      }

      return { ...state, ...action.data }

    default:
      return state
  }
}

export function editFlightLine(state = false, action) {
  switch (action.type) {
    case 'SET_EDIT_FLIGHTLINE':
      return action.data
    default:
      return state
  }
}


const initialParams = new MapFlightPlanParameters()
export function flightParameters(state = initialParams, action) {
  switch (action.type) {
    case 'SET_FLIGHT_PARAMETERS':
      if (action.data == null) {
        return initialParams
      } else {
        return { ...state, ...action.data }
      }
    default:
      return state
  }
}


const initialPanoParams = new PanoramaFlightPlanParameters()
export function panoFlightParameters(state = initialPanoParams, action) {
  switch (action.type) {
    case 'SET_PANO_FLIGHT_PARAMETERS':
      if (action.data == null) {
        return initialPanoParams
      } else {
        return { ...state, ...action.data }
      }
    default:
      return state
  }
}



const initialOrbitParams = new OrbitFlightPlanParameters()
export function orbitFlightParameters(state = initialOrbitParams, action) {
  switch (action.type) {
    case 'SET_ORBIT_FLIGHT_PARAMETERS':
      if (action.data == null) {
        return initialOrbitParams
      } else {
        return { ...state, ...action.data }
      }
    default:
      return state
  }
}

export function flightPlanName(state = '', action) {
  switch (action.type) {
    case 'SET_FLIGHT_PLAN_NAME':
      return action.flightPlanName
    default:
      return state
  }
}

export function flightPlanType(state = '', action) {
  switch (action.type) {
    case 'SET_FLIGHT_PLAN_TYPE':
      return action.flightPlanType
    default:
      return state
  }
}


export function isAddingFlightPlan(state = false, action) {
  switch (action.type) {
    case 'SET_IS_ADDING_FLIGHT_PLAN':
      return action.boolean
    default:
      return state
  }
}


export function isEdittingFlightPlan(state = false, action) {
  switch (action.type) {
    case 'SET_IS_EDITTING_FLIGHT_PLAN':
      return action.boolean
    default:
      return state
  }
}


export function flightEstimatesData(state = {}, action) {
  switch (action.type) {
    case 'SET_FLIGHT_ESTIMATES':
      return action.flightEstimatesData
    default:
      return state
  }
}


export function newFlightPlan(state = {}, action) {
  switch (action.type) {
    case 'SET_NEW_FLIGHT_PLAN':
      return action.newFlightPlan
    default:
      return state
  }
}



export function droneList(state = [], action) {
  switch (action.type) {
    case 'SET_DRONE_LIST':
      return action.droneList
    default:
      return state
  }
}



const defaultCamera = new Camera()
export function droneCamera(state = defaultCamera, action) {
  switch (action.type) {
    case 'SET_DRONE_CAMERA':
      if (action.camera == null) {
        return defaultCamera
      } else {
        return action.camera
      }
    default:
      return state
  }
}

export function isShowingTerrainProfile(state = false, action) {
  switch (action.type) {
    case 'SET_IS_SHOWING_TERRAIN_PROFILE':
      return action.isShowingTerrainProfile
    default:
      return state
  }
}
