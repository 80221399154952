export function userIsLoggedIn(state = false, action) {
  switch (action.type) {
    case 'SET_USER_IS_LOGGED_IN':
      return action.userIsLoggedIn
    default:
      return state
  }
}


export function userIsVerified(state = true, action) {
  switch (action.type) {
    case 'SET_USER_IS_VERIFIED':
      return action.userIsVerified
    default:
      return state
  }
}

export function user(state = null, action) {
  if (action.type === 'SET_USER') {
    return action.user
  } else if (action.type === 'TOGGLE_PROJECT_FAVOURITE') {
    let user = { ...state }
    let projectId = action.id
    let projectIndex = user.favoriteProjects.findIndex(x => x === projectId)
    if (projectIndex === -1) {
      user.favoriteProjects.push(projectId)
    } else {
      user.favoriteProjects.splice(projectIndex, 1)
    }
    return user
  } else {
    return state
  }
}

export function userTimezone(state = 'UTC', action) {
  switch (action.type) {
    case 'SET_USER_TIMEZONE':
      return action.timezone
    default:
      return state
  }
}


export function userAuthSuccess(state = {}, action) {
  switch (action.type) {
    case 'USER_POST_AUTH_SUCCESS':
      return action.data
    default:
      return state
  }
}



export function userLogout(state = false, action) {
  switch (action.type) {
    case 'SET_USER_LOGOUT':
      return action.isLoggedIn;
    default:
      return state;
  }
}


// export default (state = {}, action) => {
//   switch (action.type) {
//     case 'LOGIN':
//     case 'REGISTER':
//       return {
//         ...state,
//         inProgress: false,
//         errors: action.error ? action.payload.errors : null
//       };
//     case 'LOGIN_PAGE_UNLOADED':
//     case 'REGISTER_PAGE_UNLOADED':
//       return {};
//     case 'ASYNC_START':
//       if (action.subtype === 'LOGIN' || action.subtype === 'REGISTER') {
//         return { ...state, inProgress: true };
//       }
//       break;
//     case 'UPDATE_FIELD_AUTH':
//       return { ...state, [action.key]: action.value };
//     default:
//       return state;
//   }

//   return state;
// };


export function teams(state = null, action) {
  switch (action.type) {
    case 'SET_TEAMS':
      return action.teams
    default:
      return state
  }
}


export function orgJoinSuccessMessage(state = false, action) {
  switch (action.type) {
    case 'SET_ORG_JOIN_SUCCESS_MESSAGE':
      return action.orgJoinSuccessMessage
    default:
      return state
  }
}
