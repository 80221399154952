import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import L from 'leaflet'
// import { createPolygonFromBounds } from '../../helpers'
import { alertTypes } from '../../util/constants'
import './styles.css'

class SearchBar extends Component {
  static propTypes = {
    setProjectsMapCenter: PropTypes.func.isRequired,
    setSearchMarker: PropTypes.func.isRequired,
    projectsBoundsFetchData: PropTypes.func.isRequired,
    leafletMapElement: PropTypes.object
  }

  constructor(props){
    super(props)

    this.state = {
      search: ''
    }
  }

  componentDidMount(){
    // set focus on the form so user can start typing after activating the component
    document.querySelector('.search-bar .search__field').focus()
  }

  componentWillUnmount(){
    this.props.setSearchMarker([])
  }

  handleChange = (event) => {
    this.setState({search: event.target.value})
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.findCoordinates(this.state.search)
  }

  findCoordinates = (address) => {
    let geocoder = new window.google.maps.Geocoder()
    geocoder.geocode( { 'address': address}, (results, status) => {
      if (status === 'OK') {
          console.log(results[0])
          let coordinates = [results[0].geometry.location.lat(), results[0].geometry.location.lng()]
          console.log(coordinates)
          if(coordinates){
            this.props.setProjectsMapCenter(coordinates)
            this.props.setSearchMarker({coordinates: coordinates, addressText: results[0].formatted_address})
            // Delay a bit so that the map tiles have time to load a bit
            // otherwise the animation isn't smooth with tiles loading during transition
            setTimeout(() => {
              this.props.leafletMapElement.flyTo(coordinates, 20)
            }, 1000)
          }
      } else {
        this.props.setAlertModal({
          message: 'Error finding address: ' + status,
          type: alertTypes.error
        })
      }
    })


  }


  render() {
    return (
      <div className="search-bar">
        <form className="search" onSubmit={this.handleSubmit}>
          <div className="search__wrapper">
            <input value={this.state.search} type="text" name="search" placeholder="Search for a location" className="search__field" onChange={this.handleChange} />
          </div>
        </form>
      </div>
    )
  }
}

export default SearchBar
