import React, { Component } from 'react'
import Organization from '../components/Organization'
import OrganizationJoin from '../components/OrganizationJoin'

class OrganizationContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  componentDidMount() {
    // get updated user info when component mounts
    this.props.getLoggedInUser()
  }

  render() {
    if (this.props.user) {
      // check the route to see if this is a join org request - if so load the join component instead
      const { match } = this.props
      if (match.isExact && match.path === '/organizations/join/:orgId') {
        let props = {...this.props}
        props.orgId = match.params.orgId
        return <OrganizationJoin {...props} />
      } else {
        return <Organization {...this.props} />
      }
    }
    return null
  }
}

export default OrganizationContainer
