import React, { useState, useEffect, useCallback } from 'react'
import './style.css'
import { Project } from '../../api'

const CostCard = (props) => {
  const {
    projectId,
    usageData,
    isLargeFormat,
    isAdmin
  } = props

  const [state, setState] = useState({
    usageData: usageData ? usageData : null,
    projectId: projectId ? projectId : null,
    isAdmin: isAdmin ? isAdmin : false
  })

  // Fetch user data for the card from the server
  const fetchUsage = useCallback(async () => {
    const result = await Project.getProjectUsageData(state.projectId)
    if (result) {
      setState((prevState) => {
        return { ...prevState, usageData: result.data}
      })
    }
  }, [state.projectId])

  useEffect(() => {
    if (!state.usageData) {
      fetchUsage()
    }
  }, [fetchUsage, state.usageData])

  const renderResultAvatar = () => {
    return (
      <div
        className="user-card__content"
        style={ { maxWidth: '400px' } }
      >
        <div className="user-card__profile" key={1}>
          Approximated total cost: {state.usageData.total_cost ? state.usageData.total_cost.toFixed(3) + ' USD' : 'N/D'} <br />
          Total time: {state.usageData.total_time ? state.usageData.total_time.toFixed(3) + ' Hours' : 'N/D'} <br />
          Total size: {state.usageData.total_size ? state.usageData.total_size.toFixed(3) + ' GB' : 'N/D'} 
        </div>  
      </div>
    )
  }

  return (
    <section className={isLargeFormat ? 'user-card large-format' : 'user-card'}>
      {state.usageData && renderResultAvatar()}
      {!state.usageData && (
        <div className="lds-ring" style={{ marginBottom: '2rem' }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </section>
  )
}

export default CostCard
