import React, { useState, useCallback } from 'react'
import { OrganizationApi, Auth } from '../../../api'
import { debounce } from 'lodash'
import userDefaultAvatar from '../../CollapsibleMenu/user-avatar.svg'
import './styles.css'

const OrganizationAddModal = (props) => {
  const {
    organization,
    roles,
    setIsAddModalOpen,
    setMemberUpdate,
    checkPermissions,
  } = props

  const [user, setUser] = useState(null)
  const [userExists, setUserExists] = useState(null)
  const [errorMsg, setErrorMsg] = useState(null)
  const [newMemberForm, setNewMemberForm] = useState({
    role_id: null,
    user_id: null,
    organization_id: organization.id,
  })

  const userExistsDisplay = () => {
    if (userExists) {
      return {
        message: 'User exists on Spexi.',
      }
    } else {
      return {
        message: 'User does not exist on Spexi.',
      }
    }
  }

  const handleEmailChange = (event) => {
    setErrorMsg(null)
    handleChange(event)
    handleEmailChangeDebounce(event.target.value)
  }

  const handleChange = (event) => {
    let target = event.target
    let value = target.value
    let name = target.name
    setNewMemberForm({ ...newMemberForm, [name]: value })
  }

  const handleEmailChangeDebounce = useCallback(
    debounce(
      (email) => {
        handleEmailLookup(email)
      },
      500,
      { leading: false, trailing: true }
    ),
    []
  )

  const handleEmailLookup = (email) => {
    if (email) {
      Auth.doesExist(email).then((resp) => {
        if (resp && resp.success) {
          let user = resp.data
          setNewMemberForm({ ...newMemberForm, user_id: user.id })
          setUser(user)
          setUserExists(true)
        } else {
          setUser(null)
          setUserExists(false)
        }
      })
    } else {
      setUser(null)
      setUserExists(false)
    }
  }

  const handleSubmit = async () => {
    if (!newMemberForm.user_id) {
      setErrorMsg('User not selected')
    } else if (!newMemberForm.role_id) {
      setErrorMsg('Role not selected')
    } else {
      const result = await OrganizationApi.addMember(
        organization.id,
        newMemberForm
      )

      if (result.success) {
        setIsAddModalOpen(false)
        setMemberUpdate(true)
      } else {
        setErrorMsg(result.error)
      }
    }
  }

  const renderRoles = () => {
    return (
      <div>
        <label>Role: </label>
        <select
          name="role_id"
          value={newMemberForm.role_id ? newMemberForm.role_id : ''}
          onChange={handleChange}
          disabled={!userExists ? true : false}
          required
        >
          <option value="" disabled>
            Select Organization Role
          </option>
          {roles.map((role) => {
            return (
              <option
                key={role.id}
                value={role.id}
                disabled={!checkPermissions(`${role.name}_add`)}
              >
                {role.name}
              </option>
            )
          })}
        </select>
      </div>
    )
  }

  const renderButtons = () => {
    return (
      <div className="dialog__controls">
        <button type="button" className="btn solid" onClick={handleSubmit}>
          Add Member
        </button>
        <button
          type="button"
          className="btn white"
          onClick={() => setIsAddModalOpen(false)}
        >
          Cancel
        </button>
      </div>
    )
  }

  const renderResultAvatar = () => {
    let userAvatar =
      user.profile && user.profile.hashedFileName
        ? user.profile.avatar_url
        : userDefaultAvatar
    return (
      <div className="organization-row__member preview" key={user.id}>
        <div className="organization-row__user-avatar">
          <img src={userAvatar} alt={user.name} />
          <div className="organization-row__user-meta">
            {user.name}
            <br />
            {user.email}
          </div>
          <br />
        </div>
        <span className="preview-label">New Member Preview</span>
      </div>
    )
  }

  const renderErrorMsg = () => {
    return (
      <div style={{ marginTop: '1em', textAlign: 'center' }}>
        <label style={{ color: 'red', alignContent: 'center', marginTop: '' }}>
          {errorMsg}
        </label>
      </div>
    )
  }

  return (
    <section className="dialog">
      <div className="dialog__content">
        <h1 className="dialog__title">Add New Member</h1>
        <p>
          Enter a full email address to add a user to your organization. Users
          must already be registered with Spexi.
        </p>
        <label>
          <span>Email: </span>
          <span style={userExists ? { color: 'green' } : { color: 'red' }}>
            {userExists !== null ? userExistsDisplay().message : ''}
          </span>
          <input
            type="text"
            name="email"
            value={newMemberForm.email ? newMemberForm.email : ''}
            onChange={handleEmailChange}
            placeholder="e.g.: example@example.com"
          />
        </label>

        {user && renderResultAvatar()}

        {renderRoles()}
        {errorMsg && renderErrorMsg()}
        {renderButtons()}
      </div>
    </section>
  )
}

export default OrganizationAddModal
