export function productMapLayers(state = [], action) {
  switch (action.type) {
    case 'SET_PRODUCT_MAP_LAYERS':
      return action.productMapLayers.map(productMapLayer => {
        let layer = state.find(layer => layer.id === productMapLayer.id) || {...productMapLayer, visible: true, colorPicker: false}
        layer = {...layer, color: productMapLayer.color}
        return layer
      })
    case 'TOGGLE_PRODUCT_MAP_LAYER_VISIBILITY':
      return state.map((layer) => {
        if ((action.id === layer.id)) {
          return { ...layer, visible: !layer.visible }
        }
        return layer

      })
    case 'TOGGLE_PRODUCT_MAP_LAYER_COLOR_PICKER':
      return state.map((layer) => {
        if ((action.id === layer.id)) {
          return { ...layer, colorPicker: !layer.colorPicker }
        }
        return layer

      })
    default:
      return state
  }
}