import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import './styles.css'

import SidebarHeader from '../SidebarHeader/'
import MappingFlight from './MappingFlight'
import PanoFlight from './PanoFlight'
import OrbitFlight from './OrbitFlight'

class AddFlight extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    setFlightPlanType: PropTypes.func.isRequired,
    flightPlanType: PropTypes.string.isRequired,
    setIsAddingFlightPlan: PropTypes.func.isRequired
  }

  backHandler = () => {
    this.props.setIsAddingFlightPlan(false)
  }


  render() {
    let formTitle = 'Flight Plan Types'

    let sidebarHeader
    if (this.props.isEdittingProject || this.props.isAddingProject) {
      sidebarHeader = <SidebarHeader title={formTitle} backFunction={this.backHandler} />
    } else {
      sidebarHeader = <SidebarHeader title={formTitle} backLink={`/project/${this.props.match.params.id}/plan`} backFunction={this.backHandler} />
    }

    if (!this.props.flightPlanType) {
      return (
        <section className="flight-types">
          {sidebarHeader}
          <div className="type-list">
            <h4>Select a flight type:</h4>
            <div className="flight-types__item" onClick={() => {this.props.setFlightPlanType('map')}}>
              Mapping Flight
              <span className="description">A vertical capture for orthomosaics and other geospatial data products.</span>
            </div>

            <div className="flight-types__item" onClick={() => {this.props.setFlightPlanType('panorama')}}>
              Panoramic Flight
              <span className="description">A pivoting capture from a single point for interactive panoramas.</span>
            </div>

            <div className="flight-types__item" onClick={() => {this.props.setFlightPlanType('orbit')}}>
              Orbit Flight
              <span className="description">An oblique capture around an object that shows it from its various sides.</span>
            </div>
          </div>
        </section>
      )
    } else {
      return (      
        <section className="flight-types">
          <CSSTransition
            in={(this.props.flightPlanType === 'map')}
            timeout={300}
            classNames="addFlightType"
            unmountOnExit
          >
            <MappingFlight {...this.props} />
          </CSSTransition>


          <CSSTransition
            in={(this.props.flightPlanType === 'panorama')}
            timeout={300}
            classNames="addFlightType"
            unmountOnExit
          >
            <PanoFlight {...this.props} />
          </CSSTransition>

          <CSSTransition
            in={(this.props.flightPlanType === 'orbit')}
            timeout={300}
            classNames="addFlightType"
            unmountOnExit
          >
            <OrbitFlight {...this.props} />
          </CSSTransition>
        </section>
      )
    }

  }
}

export default AddFlight