import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MaterialTable from 'material-table'
import { Paper } from '@material-ui/core'
import { EpsgApiProxy } from '../../api'
import { getCrsTypes, getPathForCrsType, buildQueryParams } from '../../util'

class CoordinateSystemPicker extends Component {
  constructor(props) {
    super(props)

    const {
      pickerType,
    } = props

    this.path = getPathForCrsType(pickerType)

    const nameWidth = 350
    this.columns = [
      { title: 'Name', field: 'Name', headerStyle: {minWidth: nameWidth}, cellStyle: {minWidth: nameWidth} },
      { title: 'Type', field: 'Type' },
      { title: 'ID', field: 'id', render: rowData => this.renderIdCol(rowData) },
      { title: 'Source', field: 'DataSource', hidden: true },
      { title: 'Code', field: 'Code', hidden: true },
    ]

    this.actions = [
      {
        icon: 'add',
        tooltip: 'Select Coordinate System',
        onClick: this.onRowSelect
      }
    ]

    // NOTE: because some of the other components have z-index values greater than 1200, the page size options picker
    //       gets covered - so we just force it to a page size of 10 here
    this.options = {
      debounceInterval: 1000,
      pageSize: 10,
      pageSizeOptions: [10],
      emptyRowsWhenPaging: false,
      searchAutoFocus: true,
      sorting: false,
      showTitle: false,
      draggable: false,
    }
  }
  
  onRowSelect = (event, rowData) => {
    this.props.onCrsSelected({ id: this.renderIdCol(rowData), name: rowData.Name, type: rowData.Type })
  }

  renderIdCol = (rowData) => {
    return `${rowData.DataSource}::${rowData.Code}`
  }

  formatSearchText(text) {
    // clean the text so its in an appropriate format to search
    let cleanedText = text.trim().replace(/[^a-z0-9]/ig, ' ').toLowerCase()

    // replace spaces with + sign
    return cleanedText.trim().replace(/\s+/ig, '+')
  }

  search = (query) => {
    return new Promise((resolve, reject) => {
      const searchText = this.formatSearchText(query.search)

      const opts = { 
        keywords: searchText, 
        includeDeprecated: 1,
        includeWorld: 1,
        sortField: 'Name',
        page: query.page,
        pageSize: query.pageSize,
      }

      EpsgApiProxy.get(this.path, buildQueryParams(opts)).then(response => {
        resolve({
          data: response.Results,
          page: response.Page,
          totalCount: response.TotalResults,
        })
      })
      .catch((error) => {
        console.error(error)
        reject()
      })
    })
  }

  render() {
    return (
      <div>
        <MaterialTable
          columns={this.columns}
          data={query => this.search(query)}
          actions={this.actions}
          options={this.options} 
          components={{
            Container: props => <Paper {...props} elevation={0}/>
          }} />
      </div>
    )
  }
}

CoordinateSystemPicker.propTypes = {
  pickerType: PropTypes.oneOf(getCrsTypes()),
  onCrsSelected: PropTypes.func,
}

CoordinateSystemPicker.defaultProps = {
  pickerType: 'all',
  onCrsSelected: (crs) => { console.warn('No function defined to handle CRS selection...') },
}

export default CoordinateSystemPicker
