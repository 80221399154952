import React, { useEffect, useState } from "react";
import { Products } from "../../api";
import { alertTypes } from "../../util/constants";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

const AdminProcessingReports = ({ setAlertModal, productJobsCosts }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productJobs, setProductJobs] = useState([]);

  const [filters, setFilters] = useState({
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    sortFilter: "total_time",
  });

  useEffect(() => {
    getProductJobsCosts();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProductJobsCosts = async () => {
    setIsLoading(true);
    const response = await Products.getProductJobsCosts(
      // `paginate=${rowsPerPage}`
      filters.startDate,
      filters.endDate,
      filters.sortFilter
    );
    if (response && response.success) {
      // setRowCount(response.paginate.total);
      setProductJobs(response.data);
      console.log(response.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setAlertModal({
        message: response?.error || "an error has occured",
        type: alertTypes.error,
      });
    }
  };

  const onDateChange = (event) => {
    event.persist();

    if (event.target && event.target.id) {
      setFilters((prevState) => {
        return { ...prevState, [event.target.id]: event.target.value };
      });
    }
  };

  const onDateSubmit = () => {
    getProductJobsCosts();
    setIsLoading(true);
  };

  return (
    <div>
      <form className="date-range" noValidate>
        <TextField
          id="startDate"
          label="Start Date"
          type="date"
          defaultValue={filters.startDate}
          className="date-range__start"
          style={{ marginRight: "2rem" }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onDateChange}
        />

        <TextField
          id="endDate"
          label="End Date"
          type="date"
          defaultValue={filters.endDate}
          className="date-range__end"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onDateChange}
        />

        <div
          className="MuiFormControl-root MuiTextField-root"
          style={{ marginLeft: "2rem" }}
        >
          <label>Sort </label>
          <select
            style={{ height: "35px" }}
            value={filters.sortFilter ? filters.sortFilter : "total_time"}
            onChange={onDateChange}
            name="sortFilter"
            id="sortFilter"
          >
            <option value="total_time">Total time (hours)</option>
            <option value="total_size">Total size (GBs)</option>
          </select>
        </div>

        <button
          type="button"
          className="btn-primary btn-primary-white"
          onClick={onDateSubmit}
          style={{ marginLeft: "2rem" }}
        >
          Search
        </button>
      </form>

      {productJobs && Object.keys(productJobs).length === 0 && isLoading && (
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}
        >
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      {productJobs.length > 0 && !isLoading && (
        <>
          <p>
            Total Jobs: {productJobs.length}
            <br />
            Total Time:{" "}
            {Math.round(
              productJobs.reduce(
                (partialSum, item) => partialSum + item.total_time,
                0
              ) * 100
            ) / 100}{" "}
            hours
          </p>
          <h1
            className="sidebar-header__title"
            style={{ marginBottom: "1rem" }}
          >
            Top Sorted Jobs
          </h1>
          {productJobs.length > 0 &&
            productJobs.map((jobStat, i) => {
              const productJob = jobStat.product_job;
              const project = jobStat.project;
              if (productJob) {
                return (
                  <div
                    key={i}
                    style={{
                      marginBottom: "1rem",
                      paddingBottom: "1rem",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <div>
                      <h3
                        style={{ marginBottom: "0.5rem", fontSize: "1.25rem" }}
                      >
                        {i + 1}. Product Job # {productJob.id} -{" "}
                        {productJob.created_at}
                      </h3>
                      <strong> Total Time:</strong>{" "}
                      <span style={{ color: "green" }}>
                        {jobStat.total_time}
                      </span>{" "}
                      hours
                    </div>
                    <div>
                      <strong>Total Size:</strong>{" "}
                      <span style={{ color: "green" }}>
                        {jobStat.total_size}
                      </span>{" "}
                      GBs
                    </div>

                    <div>
                      <strong>Product Type:</strong> {productJob.product_type}
                    </div>
                    <div>
                      <strong>Product Name:</strong> {productJob.product_name}
                    </div>
                    <div>
                      <strong>Project Name:</strong>{" "}
                      <a href={`/project/${project.id}`}>
                        {project.name} (ID: {project.id})
                      </a>
                    </div>
                    <div>
                      <strong>Project Author:</strong> {project.author.name} (
                      {project.author.email})
                    </div>
                    {project.organization && (
                      <div>
                        <strong>Project Organization:</strong>{" "}
                        {project.organization}
                      </div>
                    )}
                  </div>
                );
              } else {
                return <></>
              }
            })}
        </>
      )}
    </div>
  );
};

export default AdminProcessingReports;
