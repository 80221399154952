import React, { Component } from 'react'
import { User } from '../api'
import LoadingAnimation from '../components/LoadingAnimation/'

class DeleteUserContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      deleteSuccess: null,
      errorMsg: null,
    }
  }

  componentDidMount() {
    const { location } = this.props
    const query = new URLSearchParams(location.search)

    const userId = query.get('user')
    const token = query.get('token')

    if (!userId || !token) {
      this.props.history.push('/')
    } else {
      User.deleteUser(userId, token)
        .then((response) => {
          if (response.success) {
            this.setState({
              deleteSuccess: true,
            })
          } else {
            this.setState({
              deleteSuccess: false,
              errorMsg: response.error,
            })
          }
        })
        .finally(() => {
          this.setState({
            isLoading: false,
          })
        })
    }
  }

  render() {
    return (
      <div className="App">
        <LoadingAnimation visible={this.state.isLoading} />
        {!this.state.isLoading && this.state.deleteSuccess !== null && (
          <>
            <main>
              <section id="signup-page" style={{ textAlign: 'center'}}>
                <div className="bg"></div>

                <div style={{ marginTop: '10%'}}>
                  <a href="/" className='site-name'>
                    <img
                      src="/assets/img/logo-white.png"
                      alt="Spexi Geospatial"
                      style={{ width: '20%'}}
                    />
                  </a>

                  {this.state.deleteSuccess ? (
                    <>
                      <h2>Thank you for using Spexi Geospatial</h2>
                      <p>Your account has been successfully deleted.</p>
                    </>
                  ) : (
                    <>
                      {this.state.errorMsg && (
                        <>
                          {this.state.errorMsg === 'already_deleted' && (
                            <>
                              <h2>Account already deleted</h2>
                              <p>Account has already been deleted.</p>
                            </>
                          )}

                          {this.state.errorMsg === 'user_does_not_exists' && (
                            <>
                              <h2>Account does not exist</h2>
                              <p>Account requested to delete does not exist.</p>
                            </>
                          )}

                          {this.state.errorMsg === 'session_expired' && (
                            <>
                              <h2>Session has been expired</h2>
                              <p>Account deletion session has expired.</p>
                              <p>
                                Please use the latest link from the email provided by Spexi Geospatial
                              </p>
                              <p>or</p>
                              <p>Please re-attempt account deletion</p>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>

                <a
                  className='button solid'
                  href="/"
                  style={{
                    paddingInline: '60px',
                    marginTop: '5%'
                  }}
                >
                  Main Page
                </a>

                <div style={{ marginTop: '10%'}}>
                  <p>
                    For any inquiries, please contact
                  </p>
                  <p>support@spexigeo.com</p>
                </div>
                {/* <div
                  className="container"
                  style={{
                    padding: '8rem',
                    opacity: '70%',
                    textAlign: 'center',
                  }}
                >
                  <a href="/" className="site-name">
                    <img
                      src="/assets/img/logo-white.png"
                      alt="Spexi Geospatial"
                      style={iconStyle}
                    />
                  </a>
                  {this.state.deleteSuccess ? (
                    <>
                      <h2>Thank you for using Spexi Geospatial.</h2>
                      <p style={{ fontSize: '1.5rem' }}>
                        You account has been successfully deleted.
                      </p>
                    </>
                  ) : (
                    <>
                      {this.state.errorMsg && (
                        <>
                          {this.state.errorMsg === 'already_deleted' && (
                            <>
                              <h2>Account already deleted.</h2>
                              <p style={{ fontSize: '1.5rem' }}>
                                Account has already been deleted.
                              </p>
                            </>
                          )}
                          {this.state.errorMsg === 'user_does_not_exists' && (
                            <>
                              <h2>Account does not exist.</h2>
                              <p style={{ fontSize: '1.5rem' }}>
                                Account requested to delete does not exist.
                              </p>
                            </>
                          )}
                          {this.state.errorMsg === 'session_expired' && (
                            <>
                              <h2>Session has expired.</h2>
                              <p style={{ fontSize: '1.5rem' }}>
                                Account deletion session has expired.
                              </p>
                              <p>
                                Please use the latest link from the email
                                provided by Spexi Geospatial.
                              </p>
                              <p>or</p>
                              <p>Please re-attempt account deletion.</p>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}

                </div> */}
              </section>
            </main>
          </>
        )}
      </div>
    )
  }
}

export default DeleteUserContainer
