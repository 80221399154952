export function collapsedMenu(state = true, action) {
  switch (action.type) {
    case 'TOGGLE_COLLAPSIBLE_MENU':
      return action.menuState
    default:
      return state
  }
}


export function alertModal(state = {}, action) {
  switch (action.type) {
    case 'SET_ALERT_MODAL':
      if (action.data === null) {
        return {}
      }
      return action.alertModal
    default:
      return state
  }
}


export function coordinateSystemPickerModal(state = {}, action) {
  switch (action.type) {
    case 'SET_CRS_MODAL':
      if (action.data === null) {
        return {}
      }
      return action.coordinateSystemPickerModal
    default:
      return state
  }
}