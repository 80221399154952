import React from 'react'
import { Dialog } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import AddProduct from '../AddProduct'
import { alertTypes } from '../../util/constants'
import './styles.css'

const AddProductDialog = (props) => {
  const {
    requestsInProgress,
    setIsUploadingImages,
    setProductForm,
    setIsUploadingGCPs,
    setIsEditingProductParameters,
    setAlertModal,
    fetchProject,
    getProductJobs,
    match,
    productForm
  } = props

  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const mobileScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    if (requestsInProgress.UPLOADING_IMAGES || requestsInProgress.VERIFYING_UPLOADS) {
      setAlertModal({
        message: 'Images are currently being uploaded and verified. If you navigate away now, your changes will be lost. Are you sure you wish to leave this page?',
        type: alertTypes.warning,
        cancellable: true,
        confirmHandler: () => {
          closeDialog()
        },
      })      
    } else {
      closeDialog()
    }
  };

  const closeDialog = () => {
    setIsUploadingImages(false)
    setProductForm({ active: false })
    setIsUploadingGCPs(false)
    setIsEditingProductParameters(false)
    fetchProject(match.params.id)
    getProductJobs(match.params.id)
  }

  return (
    <Dialog
      classes={{
        root: "add-product__dialog",
        paper: `add-product__dialog-paper ${smScreen ? "sm" : ""}`,
        paperWidthXl: "add-product__dialog-paper"
      }}
      disableEnforceFocus
      fullScreen={smScreen}
      open={productForm.active}
      onClose={handleClose}
      aria-labelledby="responsive-add-product-dialog"
      fullWidth
      maxWidth="xl"
    >
      <AddProduct {...props} closeDialog={handleClose} mobileScreen={mobileScreen} smScreen={smScreen}/>
    </Dialog >

  )
}

export default AddProductDialog
