export const alertTypes = {
  success: 'success',
  warning: 'warning',
  info: 'info',
  error: 'error',
}

export const SUBSCRIPTION_PLAN_TYPES = {
  contract: 'contract',
  paid: 'paid',
}

// Network profile options
export const DRONES = [
  'DJI Spark',
  'DJI Mavic Mini 1',
  'DJI Mavic Mini 2',
  'DJI Mavic Air',
  'DJI Mavic Air 2',
  'DJI Mavic Pro',
  'DJI Mavic Pro Platinum',
  'DJI Mavic 2 Enterprise Dual',
  'DJI Mavic 2 Enterprise Advanced',
  'DJI Mavic 2 Pro',
  'DJI Phantom 3 Standard',
  'DJI Phantom 3 Professional',
  'DJI Phantom 3 Advanced',
  'DJI Phantom 3 4K',
  'DJI Phantom 4 Pro',
  'DJI Phantom 4 Pro RTK',
  'DJI Phantom 4 Pro V2',
  'DJI Phantom 4 Advanced',
  'DJI Inspire 1',
  'DJI Inspire 2',
  'DJI Matrice 210',
  'DJI Matrice 210 V2',
  'DJI Matrice 210 RTK V2',
  'DJI Matrice 300 RTK',
  'Skydio 2',
  'Autel Evo',
  'Parrot Anafi',
  'Wingtra One',
]

export const SENSORS = ['RGB imagery/video', 'NIR', 'Multi-spectral', 'Air sampling', 'Thermal', 'Lidar', 'Other']

export const CERTIFICATE_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
  { value: 'in-progress', label: 'In Progress' },
]

export const CERTIFICATE_TYPES = ['basic', 'advanced']

export const CELLULAR_CONNECTIVITY = ['Yes', 'No', 'Not sure']

export const INSURANCE_TYPES = [
  'No',
  'Less than $1,000,000 liability',
  '$1,000,000 - $5,000,000 liability',
  '$5,000,000 - $9,999,999 liability',
  '$10,000,000 or greater liability',
]

export const COMPANY_TYPES = ['No', 'Corporation', 'Sole proprietorship', 'Other']

export const TRAINING_TYPES = ['ICS 100, 200', 'H2S', 'Oilfield Safety', 'Working at heights', 'Duns', 'Other']

export const SERVICE_TYPES = [
  'Surveying & Mapping',
  'LiDAR',
  'Mining',
  'Infrastructure',
  'Utilities',
  'Construction',
  'Wind & Solar',
  'Real Estate',
  'Agriculture',
  'Inspections',
  'Other',
]

export const NETWORK_FILTERS = {
  drones: {
    type: 'multiple',
    name: 'drones',
    label: 'Drone Type',
    options: DRONES,
  },
  sensors: {
    type: 'multiple',
    name: 'additional_sensors',
    label: 'Sensor',
    options: SENSORS,
  },
  certification: {
    type: 'multiple',
    name: 'certification',
    label: 'Certification',
    options: CERTIFICATE_TYPES,
  },
  insurance: {
    type: 'single',
    name: 'insurance',
    label: 'Insurance',
    options: INSURANCE_TYPES,
  },
  flightController: {
    type: 'single',
    name: 'flight_controller',
    label: 'Controller Type',
    options: ['iOS', 'Android'],
  },
  cellularConnectivity: {
    type: 'single',
    name: 'cellular_connectivity',
    label: 'Cellular Network',
    options: CELLULAR_CONNECTIVITY,
  },
  companyType: {
    type: 'single',
    name: 'company_type',
    label: 'Company',
    options: COMPANY_TYPES,
  },
  training: {
    type: 'multiple',
    name: 'training',
    label: 'Training',
    options: TRAINING_TYPES,
  },
  services: {
    type: 'multiple',
    name: 'services_provided',
    label: 'Service',
    options: SERVICE_TYPES,
  },
}
