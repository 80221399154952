import React, { Component } from 'react'
import Tooltip from '../Tooltip'
import CoordinateSystem from '../CoordinateSystem'
import { isFunction } from '../../util'
import CloseIcon from '@material-ui/icons/Close'
import { AIModel, Webhook } from '../../api'
import './styles.css'

export default class ProductParameters extends Component {
  constructor(props) {
    super(props)
    this.tasks = []
    this.ai_models = []
    this.state = {
      parameters: null,
      webhookAccess: false
    }
  }

  componentDidMount() {
    // TODO: needs to depends on the *type* of the product (only map for now)
    this.tasks = this.getTasks()
    this.getAiModels().then(res => {
      this.ai_models = res
      this.tasks = this.getTasks()
    })
    let parameters = this.props.productForm.parameters
      ? this.props.productForm.parameters
      : {}
    this.setState({
      parameters,
      originalParameters: { ...parameters },
    })

    // Only allow organizational users to view webhook based parameters
    if (this.props.user.organizationMembership) {
      Webhook.getWebhooks().then(res => res.data)
        .then(res => {
          const webhookFound = res.find(webhook => webhook.organization_id === this.props.user.organizationMembership.organization_id)
          this.setState({
            webhookAccess: webhookFound
          })
          this.tasks = this.getTasks()
        })
    }

  }

  getAiModels = () => {
    return AIModel.get().then(res => {
      return res.data.map(r => {
        return {
          name: r.full_name,
          value: r.file_name
        }
      })
    })
  }

  isTowerProject = () => {
    return (
      this.props.projectForm.category === 'tower-inspection' ||
      this.props.project.category === 'tower-inspection'
    )
  }

  getTasks = () => {
    let tasks = []
    if (
      this.props.productForm.type === 'map+3d' ||
      this.props.productForm.type === 'map'
    ) {
      tasks = tasks.concat([
        {
          id: 'shrinkwrap-region',
          name: 'Processing Region',
          options: [
            {
              id: 'should_shrinkwrap',
              type: 'checkbox',
              default: true,
              tooltip:
                'When enabled, the final product area will be constrained to the arbitrarily oriented minimum bounding box of all the mapping flight plans.',
              name: 'Shrinkwrap to Flight Plans',
            },
          ],
        },
        {
          id: 'match-photos',
          name: 'Match Photos',
          tooltip:
            'The match photos step identifies and matches photos that share common points of interest (aka. "tie points" or "control points").',
          options: [
            {
              name: 'Accuracy',
              id: 'downscale',
              type: 'select',
              default: '2',
              tooltip:
                'Accuracy is modulated by downsampling the images while performing the matching. Lowering the accuracy can dramatically increase the speed of this step, though fine details will be lost.',
              values: [
                // { value: '0', name: 'Highest' },
                { value: '1', name: 'High' },
                { value: '2', name: 'Medium' },
                { value: '4', name: 'Low' },
                { value: '8', name: 'Lowest' },
              ],
            },
          ],
        },
        {
          id: 'build-depth-maps',
          name: 'Build Depth Maps',
          options: [
            {
              id: 'downscale',
              name: 'Quality',
              type: 'select',
              default: '4',
              values: [
                // { value: '1', name: 'Ultra High' },
                { value: '2', name: 'High' },
                { value: '4', name: 'Medium' },
                { value: '8', name: 'Low' },
                { value: '16', name: 'Lowest' },
              ],
            },
            {
              id: 'filter_mode',
              name: 'Filter Mode',
              type: 'select',
              default: 'mild',
              values: [
                { value: 'aggressive', name: 'Aggressive' },
                { value: 'moderate', name: 'Moderate' },
                { value: 'mild', name: 'Mild' },
              ],
            },
          ]
        },
        {
          id: 'build-dem',
          name: 'Build DEM',
          options: [
            {
              id: 'interpolation',
              name: 'Interpolation',
              type: 'select',
              default: 'enabled',
              values: [
                { value: 'disabled', name: 'Disabled' },
                { value: 'enabled', name: 'Enabled' },
                { value: 'extrapolated', name: 'Extrapolated' },
              ],
            },
          ],
        },
        {
          id: 'tileset_3d',
          name: '3D Tileset',
          tooltip:
            'Render a 3D model as Tileset to improve model quality on big models.',
          options: [
            {
              id: 'activate',
              name: 'Activate',
              type: 'select',
              default: 'auto',
              values: [
                { value: 'disabled', name: 'Disabled' },
                { value: 'enabled', name: 'Enabled' },
                { value: 'auto', name: 'Automatic' },
              ],
            }
          ],
        },
        {
          id: 'export',
          name: 'Coordinate Systems',
          options: this.renderCoordinateSystemSection,
        },
        {
          id: 'vegetation_index',
          name: 'Vegetation Index Layer',
          options: [
            {
              id: 'index',
              name: 'Algorithm',
              type: 'select',
              default: '',
              values: [
                { value: '', name: 'None' },
                {
                  value: 'VARI',
                  name: 'VARI (Visual Atmospheric Resistance Index)',
                },
                { value: 'GLI', name: 'GLI (Green Leaf Index)' },
              ],
            },
          ],
        },
        {
          id: 'rtk_drone',
          name: 'RTK Drone Imagery',
          options: [
            {
              id: 'is_rtk_drone',
              type: 'checkbox',
              default: false,
              tooltip:
                'When enabled, RTK-based drone imagery altitude values will be respected.',
              name: 'Captured with RTK drone',
            },
          ],
        },
        {
          id: 'dtm',
          name: 'Digital Terrain Model',
          options: this.renderDtmSection,
        }
      ])
    }

    if (
      (this.props.productForm.type === 'map+3d' ||
      this.props.productForm.type === 'map') && this.props.user.isAdmin 
    ) {
      tasks = tasks.concat([
        {
          id: 'inspection_ai',
          name: 'Inspection AI',
          tooltip:
            'Activate inspection AI mode.',
          options: [
            {
              id: 'activate',
              type: 'checkbox',
              default: false,
              name: 'Inspection AI',
            },
            {
              id: 'model',
              type: 'select',
              name: 'Select AI model',
              default: 'Rails V1',
              values: this.ai_models
            }
          ],
        },
        {
          id: 'force_params',
          name: 'Special Parameters',
          tooltip:
            'Special parameters for admins only.',
          options: [
            {
              id: 'dem_resolution',
              type: 'number',
              default: 0.45,
              name: 'DEM resolution',
            },
          ],
        },
      ])
    }

    if (
      this.props.productForm.type === 'map+3d' ||
      this.props.productForm.type === '3d'
    ) {
      tasks = tasks.concat([
        {
          id: 'build-model',
          name: 'Build Model',
          options: [
            {
              id: 'face_count',
              name: 'Face Count',
              type: 'select',
              default: 'medium',
              values: [
                // { value: 'high', name: 'High' },
                { value: 'medium', name: 'Medium' },
                { value: 'low', name: 'Low' },
              ],
            },
          ],
        },
        {
          id: 'export',
          name: 'Point Cloud',
          options: [
            {
              id: 'pc_min_confidence',
              name: 'Minimum Point Cloud Confidence %',
              tooltip:
                'Any points with a confidence percentage level below this value will not be included in the resulting point cloud.',
              type: 'number',
              // set default confidence filter to 10% if its a tower inspection product
              default: this.isTowerProject() ? 10 : 0,
            },
          ],
        },
      ])
    }

    if (this.props.productForm.type === 'panorama') {
      tasks = tasks.concat([
        {
          id: 'match-photos',
          name: 'Match Photos',
          tooltip:
            'The match photos step identifies and matches photos that share common points of interest (aka. "tie points" or "control points").',
          options: [
            {
              name: 'Accuracy',
              id: 'downscale',
              type: 'select',
              default: '1',
              tooltip:
                'Accuracy is modulated by downsampling the images while performing the matching. Lowering the accuracy can dramatically increase the speed of this step, though fine details will be lost.',
              values: [
                { value: '0', name: 'Highest' },
                { value: '1', name: 'High' },
                { value: '2', name: 'Medium' },
                { value: '4', name: 'Low' },
                { value: '8', name: 'Lowest' },
              ],
            },
          ],
        },
        {
          id: 'cpfind',
          name: 'Export',
          tooltip: 'Panorama export settings',
          options: [
            {
              id: 'flippano',
              type: 'checkbox',
              default: true,
              name: 'Automatically flip panorama image',
              tooltip:
                'Detects an upside-down panorama and automatically flips it.',
            },
          ],
        },
        {
          id: 'model_rotation',
          name: 'Rotation',
          tooltip: 'Rotation algorithm for the output sphere.',
          options: [
            {
              name: 'Rotation',
              id: 'rotation_method',
              type: 'select',
              default: '1',
              tooltip:
                'If the resulting pano has slight undulations at the horizon, try an alternate rotation algorithm',
              values: [
                {
                  value: 'camera_pitch',
                  name: 'Use camera pitch for rotation',
                },
                { value: 'vector_sum', name: 'Use vector sum for rotation' },
              ],
            },
          ],
        },
      ])
    }
    if (this.props.productForm.type === 'gallery') {
      tasks = tasks.concat([
        {
          id: 'inspection_ai',
          name: 'Inspection AI',
          tooltip:
            'Activate inspection AI mode.',
          options: [
            {
              id: 'activate',
              type: 'checkbox',
              default: false,
              name: 'Inspection AI',
            },
            {
              id: 'model',
              type: 'select',
              name: 'Select AI model',
              default: 'Rails V1',
              values: this.ai_models
            }
          ],
        },
      ])
    }

    if (this.props.productForm.type === 'orbit') {
      tasks = tasks.concat([
        {
          id: 'order_photos',
          name: 'Order Photos',
          tooltip:
            'By default, photos are ordered by GPS coordinates (angles from the center of the orbit). If your photos have inaccurate GPS metadata, try setting the sort order to "Timestamp".',
          options: [
            {
              id: 'sort_method',
              type: 'select',
              name: 'Sort Method',
              default: 'TIMESTAMP',
              values: [
                { value: 'TIMESTAMP', name: 'Timestamp' },
                { value: 'GPS', name: 'GPS Coordinates' },
              ],
            },
          ],
        },
      ])
    }

    return tasks
  }

  onSelectChanged = (event) => {
    let parts = event.target.id.split('@')
    let task = parts[0]
    let option = parts[1]
    let value = event.target.value
    let parameters = this.state.parameters
    if (!(task in parameters)) {
      parameters[task] = {}
    }
    parameters[task][option] = value
    this.setState({ parameters }, this.onParametersChange)
    this.onParametersChange()
  }

  onCheckboxChange = (event) => {
    let parts = event.target.id.split('@')
    let task = parts[0]
    let option = parts[1]
    let value = event.target.checked
    let parameters = this.state.parameters
    if (!(task in parameters)) {
      parameters[task] = {}
    }
    parameters[task][option] = value
    this.setState({ parameters }, this.onParametersChange)
  }

  onResetClick = () => {
    this.setState({ parameters: {} }, this.onParametersChange)
  }

  onUndoClick = () => {
    console.log('UNDO')
    console.log(this.state.originalParameters)
    this.setState(
      { parameters: this.state.originalParameters },
      this.onParametersChange
    )
  }

  onParametersChange = () => {
    if (this.props.onParametersChange) {
      this.props.onParametersChange(this.state.parameters)
    }
  }

  onCrsChange = (data) => {
    const { crs, vertCrs } = data
    // null crs means user hit cancel
    if (crs === null) {
      return
    }

    // update the export parameters for the CRS change
    const task = 'export'
    let parameters = this.state.parameters
    if (!(task in parameters)) {
      parameters[task] = {}
    }

    // set the horizontal choice
    parameters[task]['dem_crs'] = crs.id

    // set the vertical choice if it was specified
    const vertCrsName = 'vert_cs'
    if (vertCrs !== null) {
      parameters[task][vertCrsName] = vertCrs.id
    } else {
      // if it is null, delete it from the parameters list if it was previously set
      if (vertCrsName in parameters[task]) {
        delete parameters[task]['vert_cs']
      }
    }

    this.setState({ parameters, crs, vertCrs }, this.onParametersChange)
    this.onParametersChange()
  }

  onDtmChange = (event) => {
    // when this option is selected, we want to update parameters for 2 different tasks
    const value = event.target.checked
    const option = 'dtm'
    const tasks = ['build-dense-cloud', 'build-dem']
    let parameters = this.state.parameters
    tasks.forEach((task) => {
      if (!(task in parameters)) {
        parameters[task] = {}
      }
      parameters[task][option] = value
    })
    this.setState({ parameters }, this.onParametersChange)
  }

  getValue = (task, option) => {
    if (task.id in this.state.parameters) {
      if (option.id in this.state.parameters[task.id]) {
        return this.state.parameters[task.id][option.id]
      }
    }
    return option.default
  }

  getCrs = (paramName) => {
    // fetches the crs in from the parameters
    const taskName = 'export'
    if (taskName in this.state.parameters) {
      if (paramName in this.state.parameters[taskName]) {
        return this.state.parameters[taskName][paramName]
      }
    }

    return null
  }

  renderCoordinateSystemSection = () => {
    const crsId = this.getCrs('dem_crs')
    const vertCrsId = this.getCrs('vert_cs')
    return (
      <div>
        <CoordinateSystem
          onChange={this.onCrsChange.bind(this)}
          crsId={crsId}
          vertCrsId={vertCrsId}
        />
      </div>
    )
  }

  renderDtmSection = () => {
    return (
      <div>
        <label>
          <input
            type="checkbox"
            style={{ display: 'inline' }}
            id="build-dense-cloud@dtm"
            checked={this.getValue({ id: 'build-dense-cloud' }, { id: 'dtm' })}
            onChange={this.onDtmChange}
          />
          Export Ground Points Only
          <Tooltip
            tooltipId="dtm-tooltip"
            tip="Select this option to generate a digital terrain model (ground points only)"
            type="light"
          />
        </label>
      </div>
    )
  }

  handleClose = () => {
    this.props.setIsEditingProductParameters(false)
  }

  render() {
    return (
      <section id="gcp-upload-view">
        <CloseIcon
          onClick={this.handleClose}
          className="product-parameters__close-icon"
          fontSize="large"
        />
        <h3 className="section-title">PARAMETERS</h3>
        <p
          style={{
            fontSize: '0.75rem',
            border: 'dashed 1px',
            padding: '1rem',
            borderRadius: '1rem',
          }}
        >
          These options are for{' '}
          <b style={{ color: 'yellow' }}>advanced users only</b>. It is not
          recommended to change these values unless you are familiar with the
          underlying processes involved. The default values should be sufficient
          for most use-cases.
        </p>
        {this.tasks.map((task, i) => (
          <div key={i} style={{ marginBottom: '1rem', borderRadius: '1rem' }}>
            <div className="header">
              <h2>{task.name}</h2>
              {task.tooltip && <Tooltip tip={task.tooltip} type="light" />}
            </div>
            {isFunction(task.options) && task.options()}
            {!isFunction(task.options) &&
              task.options.map((option) => {
                return (
                  <div key={option.id}>
                    {option.type === 'select' && (
                      <div>
                        <label>
                          {option.name}
                          {option.tooltip && (
                            <Tooltip tip={option.tooltip} type="light" />
                          )}
                        </label>
                        <select
                          id={`${task.id}@${option.id}`}
                          onChange={this.onSelectChanged}
                          value={this.getValue(task, option)}
                        >
                          {option.values.map((value) => {
                            return (
                              <option
                                key={`${option.id}-${value.value}`}
                                value={value.value}
                              >
                                {value.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    )}
                    {option.type === 'checkbox' && (
                      <label>
                        <input
                          type="checkbox"
                          style={{ display: 'inline' }}
                          id={`${task.id}@${option.id}`}
                          checked={this.getValue(task, option)}
                          onChange={this.onCheckboxChange}
                        />
                        {option.name}
                        {option.tooltip && (
                          <Tooltip tip={option.tooltip} type="light" />
                        )}
                      </label>
                    )}
                    {option.type === 'number' && (
                      <div>
                        <label>
                          {option.name}
                          {option.tooltip && (
                            <Tooltip tip={option.tooltip} type="light" />
                          )}
                        </label>
                        <input
                          type="number"
                          style={{ display: 'inline' }}
                          id={`${task.id}@${option.id}`}
                          value={this.getValue(task, option)}
                          onChange={this.onSelectChanged}
                        />
                      </div>
                    )}
                  </div>
                )
              })}
          </div>
        ))}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <button className="btn white" onClick={this.onResetClick}>
            Reset to Default
          </button>
        </div>
      </section>
    )
  }
}
