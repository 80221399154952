import React from 'react'
import PropTypes from 'prop-types'

const MapVolumeIcon = (props) => {
  const { width = 20, height = 20 } = props
  return (
    <svg viewBox="0 0 240 316" width={width} height={height} className="volume">
      <path
        d="M120 0C52.7 0 0 27.2 0 61.8v192.3C0 288.8 52.7 316 120 316s120-27.2 120-61.8V61.8C240 27.2 187.3 0 120 0zm0 13.7c56.4 0 104 22 104 48.1s-47.6 48.1-104 48.1-104-22-104-48.1 47.6-48.1 104-48.1zM224 93v130c-20.5-18.4-59-30.6-104-30.6S36.5 204.5 16 223V93c20.5 18.5 59 30.7 104 30.7s83.5-12.2 104-30.7zM120 302.3c-56.4 0-104-22-104-48.1s47.6-48.1 104-48.1 104 22 104 48.1-47.6 48.1-104 48.1z"
        fill="#426288"
      />
    </svg>
  )
}

MapVolumeIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

export default MapVolumeIcon