import React from 'react'
import './styles.css'

const LoadingAnimation = props => {
  if (props.visible) {
    return <div className="loading-overlay visible">
      <div className="spinner">
        <div className="content">
          <div className="loader-circle"></div>
          <div className="loader-line-mask">
            <div className="loader-line"></div>
          </div>
          <img src="/assets/img/icon.png" alt="loading spinner" className="logo-icon" width="60" height="60" />
        </div>
      </div>
    </div>

  } else {
    return <div className="loading-overlay"></div>
  }
}

export default LoadingAnimation