import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './styles.css'

const ConfirmationDialog = ({ open, onClose, type, onConfirm }) => {
  return (
    <Dialog
      classes={{ root: 'confirmation-dialog-container', paper: 'confirmation-dialog' }}
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <CloseIcon onClick={onClose} className="btn-close-menu" />
      <DialogContent>
        <div className="confirmation-dialog__desc">
          {`Do you want to ${type} the subscription now or at the end of current period?`}
        </div>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="btn-primary-white admin-subscriptions-dialog__btn"
          onClick={onClose}
          style={{ marginRight: 'auto' }}
        >
          Close
        </button>
        <div>
          <button type="submit" className="btn-secondary-white admin-subscriptions-dialog__btn" onClick={() => onConfirm(true)}>
            {`${type} now`}
          </button>
          <button
            type="submit"
            className="btn-secondary-white admin-subscriptions-dialog__btn"
            onClick={() => onConfirm(false)}
            style={{ marginLeft: '0.5rem', marginRight: '1rem' }}
          >
            {`${type} at end of current period`}
          </button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(ConfirmationDialog)
