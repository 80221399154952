import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'



const SidebarHeader = props => {
  return (
    <header className="sidebar-header">
      {(props.backLink && !props.backFunction) && <Link to={props.backLink} className="sidebar-header__back-bottom"><i className="fa fa-chevron-left" aria-hidden="true"></i></Link>}
      {(props.backLink && props.backFunction) && <Link to={props.backLink} className="sidebar-header__back-bottom"><span onClick={props.backFunction}><i className="fa fa-chevron-left" aria-hidden="true"></i></span></Link>}
      {(!props.backLink && props.backFunction) && <span className="sidebar-header__back-bottom" onClick={props.backFunction}><i className="fa fa-chevron-left" aria-hidden="true"></i></span>}
      
      <h1 className="sidebar-header__title">{props.title}</h1>
    </header>
  )
}

export default SidebarHeader