export let API_ROOT
export let PLAYER_URL
export let STRIPE_KEY
export let PDF_PATH

if (process.env.REACT_APP_ENV === 'production') {
  API_ROOT = 'https://api.spexigeo.com'
  PLAYER_URL = 'https://projects.spexigeo.com'
  STRIPE_KEY = 'pk_live_CBIHr4IDtmQpq7zuBDaeoQVW'
  PDF_PATH = 'https://spexi-project-pdfs-prod.s3.ca-central-1.amazonaws.com'
} else if (process.env.REACT_APP_ENV === 'staging') {
  API_ROOT = 'https://staging.api.spexigeo.com'
  PLAYER_URL = 'https://staging-player.spexigeo.com'
  STRIPE_KEY = 'pk_test_51JTAZcHsUR8E5c7kQMYfEDSFKXqfHblOCO16CKywVJAuxYvmvOJ6TdVWVBycoM7YhqDIhGcsk7dNBV9qMeq1P1bA00U1tUJIRs'
  PDF_PATH = 'https://spexi-project-pdfs.s3.ca-central-1.amazonaws.com'
} else if (process.env.REACT_APP_ENV === 'lando-offline') {
  // if you want to develop offline with lando
  // https://docs.lando.dev/guides/offline-dev.html
  API_ROOT = 'http://spexiapi.local.host'
  PLAYER_URL = 'http://localhost:1234'
  STRIPE_KEY = ''
} else if (process.env.REACT_APP_ENV === 'local') {
  // uses a Docker code image - preferred dev environment that matches staging/production closely
  API_ROOT = 'http://localhost:4321'
  PLAYER_URL = 'http://localhost:1234'
  PDF_PATH = 'https://spexi-project-pdfs.s3.ca-central-1.amazonaws.com'
  // Add in your local stripe key test account
  STRIPE_KEY = ''
} else {
  // legacy lando endpoints
  API_ROOT = 'http://spexiapi.lndo.site'
  PLAYER_URL = 'http://localhost:1234'
  STRIPE_KEY = ''
  PDF_PATH = 'https://spexi-project-pdfs.s3.ca-central-1.amazonaws.com'
}
