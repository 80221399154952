import React from 'react'
import PropTypes from 'prop-types'
import L from 'leaflet'
import { Marker } from 'react-leaflet'

const LocationAnnotation = props => {
  const { annotation, index } = props
  const icon = L.divIcon({
    className: 'annotation-marker-icon',
    iconAnchor: [10, 25],
    labelAnchor: [-6, 0],
    popupAnchor: [0, -36],
    html: `
      <svg version="1" xmlns="http://www.w3.org/2000/svg" width="20" viewBox="0 0 149 178">
        <path fill="#${annotation.color}" stroke="#${annotation.color}" strokeWidth="6" strokeMiterlimit="10"
        d="M126 23l-6-6A69 69 0 0 0 74 1a69 69 0 0 0-51 22A70 70 0 0 0 1 74c0 21 7 38 22 52l43 47c6 6 11 6 16 0l48-51c12-13 18-29 18-48 0-20-8-37-22-51z"/>
        <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="90px" dy=".3em">${index}</text>
      </svg>
    `
  })

  return (
    <Marker className="annotation-marker"
      position={[annotation.geojson.coordinates[1], annotation.geojson.coordinates[0]]}
      icon={icon}
      data-id={annotation.id}>
    </Marker>
  )

}

LocationAnnotation.propTypes = {
  annotation: PropTypes.object,
  index: PropTypes.number,
}

export default LocationAnnotation