import React, { useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import stripeBadge from './powered_by_stripe.svg'
import { alertTypes } from '../../util/constants'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Montserrat", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const CardField = ({ onChange }) => (
  <div>
    <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChange} />
  </div>
)

const ErrorMessage = ({ children }) => (
  <div className="subscriptions-dialog__error-msg" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
)

const PaymentForm = ({
  paymentIntent,
  billingDetails,
  isLoading,
  updateUserState,
  setAlertModal,
  setIsLoading,
  gotoPrevious,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [cardComplete, setCardComplete] = useState(null)

  const handleSubmitForm = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement('card').focus()
      return
    }

    if (cardComplete) {
      setIsLoading(true)
    }

    const payload = await stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    })

    setIsLoading(false)

    if (payload.error) {
      setError(payload.error)
    } else {
      setAlertModal({
        message:
          'Your card has been successfully processed, please wait a few moments for the change to be visible on your profile. A receipt was sent to your email address',
        type: alertTypes.success,
        confirmHandler: () => {
          updateUserState()
        },
      })
    }
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <h2
        className="subscriptions-dialog__title"
        style={{
          marginBottom: 0,
        }}
      >
        Payment Information
      </h2>
      <fieldset className="subscriptions-dialog__fieldset subscriptions-dialog__payment-fieldset">
        <div className="subscriptions-dialog__payment-summary">
          <h2 className="subscriptions-dialog__section-title">Order Summary</h2>
          {paymentIntent && (
            <section>
              <div className="subscriptions-dialog__payment-summary-text" style={{ marginBottom: '0.5rem' }}>
                Subscription:
                <div className="subscriptions-dialog__payment-summary-text-highlight">
                  {paymentIntent.subscription_info.nickname} / month
                </div>
              </div>

              <div className="subscriptions-dialog__payment-summary-text">
                <div>Subtotal:</div> <div>{formatter.format((paymentIntent.payment_subtotal / 100).toFixed(2))}</div>
              </div>
              {paymentIntent.tax_amounts.map((tax) => (
                <div className="subscriptions-dialog__payment-summary-text" key={tax.amount}>
                  <div>
                    Tax ({tax.tax_name} {tax.tax_percentage}%) :
                  </div>{' '}
                  <div>{formatter.format((tax.amount / 100).toFixed(2))}</div>
                </div>
              ))}
              <div className="subscriptions-dialog__payment-summary-text">
                <div>
                  <strong>Total:</strong>
                </div>
                <div>
                  <strong>{formatter.format((paymentIntent.payment_total / 100).toFixed(2))}</strong>
                </div>
              </div>
            </section>
          )}
        </div>

        <div className="subscriptions-dialog__payment-card">
          <h2
            className="subscriptions-dialog__section-title"
            style={{
              lineHeight: '0',
            }}
          >
            Enter your card details
          </h2>
          <CardField
            onChange={(e) => {
              setError(e.error)
              setCardComplete(e.complete)
            }}
          />
          <div
            style={{
              textAlign: 'left',
              display: 'flex',
              alignItems: 'center',
              lineHeight: '0.5rem',
            }}
          >
            <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
              <img width="100" src={stripeBadge} alt="Powered by Stripe" />
            </a>
            <div style={{ fontSize: '0.7rem', fontWeight: '500', color: '#a0a3b9', marginLeft: '1rem' }}>
              Secure AES-256 Encrypted Checkout
            </div>
          </div>
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
        </div>
      </fieldset>
      <div className="subscriptions-dialog__btn-container">
        <button type="button" className="btn-primary-white subscriptions-dialog__btn" onClick={gotoPrevious}>
          Back
        </button>
        <div>
          <button type="submit" className="btn-primary-solid subscriptions-dialog__btn">
            {isLoading ? 'Processing...' : `Pay ${formatter.format((paymentIntent.payment_total / 100).toFixed(2))} CAD`}
          </button>
        </div>
      </div>
    </form>
  )
}

export default PaymentForm
