import React, { useState, useCallback } from 'react'
import { Auth, Wallet } from '../../api'
import { debounce } from 'lodash'
import userDefaultAvatar from '../CollapsibleMenu/user-avatar.svg'
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons'

export default function NewTransactionModal(props) {
  const { closeModal } = props

  const [state, setState] = useState({
    newTransactionForm: {
      userId: null,
      email: null,
      isFree: true,
      transactionReason: null,
      transactionAmount: null,
      transactionType: null,
    },
    user: null,
  })

  const [errorMsg, setErrorMsg] = useState(null)
  const [transactionSuccess, setTransactionSuccess] = useState(null);
  const [updatedWallet, setUpdatedWallet] = useState(null)
  const [formErrorMsg, setFormErrorMsg] = useState(null)

  const types = ['trial', 'retry', 'deposit', 'withdraw', 'gift']

  const handleSubmit = () => {
    if (
      state.user &&
      state.newTransactionForm.userId &&
      state.newTransactionForm.email &&
      state.newTransactionForm.isFree &&
      state.newTransactionForm.transactionReason &&
      state.newTransactionForm.transactionAmount &&
      state.newTransactionForm.transactionType
    ) {
      if (
        state.newTransactionForm.transactionType === 'withdraw' && 
        state.user.wallet.balance - state.newTransactionForm.transactionAmount < 0
      ) {
        setFormErrorMsg('Can not set user balance under 0.')
      } else {
        Wallet.updateWallet(state.user.wallet.id, state.newTransactionForm).then(
          (response) => {
            if (response && response.success) {
              Auth.doesExist(state.user.email).then((response) => {
                if (response && response.success) {
                  if (
                    state.user.id === response.data.id &&
                    state.user.wallet.id === response.data.wallet.id
                  ) {
                    setUpdatedWallet(response.data.wallet)
                    setTransactionSuccess(true)
                  } else {
                    setTransactionSuccess(false)
                  }
                } else {
                  setTransactionSuccess(false)
                }
              })
            } else {
              setTransactionSuccess(false)
            }
          }
        )
      }
    } else {
      setFormErrorMsg('Please fill out all the above fields.')
    }
  }

  const handleChange = (event) => {
    setFormErrorMsg(null)
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    setState((prevState) => {
      return {
        ...prevState,
        newTransactionForm: {
          ...prevState.newTransactionForm,
          [name]: value,
        },
      }
    })
  }

  const handleEmailChange = (event) => {
    handleChange(event)
    handleEmailChangeDebounce(event.target.value)
  }

  const handleEmailLookup = (email) => {
    if (email) {
      Auth.doesExist(email).then((response) => {
        if (response && response.success) {
          let user = response.data
          if (user.hasActiveSubscription) {
            setErrorMsg('User has an active subscription plan.')
          } else if (!user.wallet) {
            setErrorMsg('User does not have a wallet.')
          } else {
            setState((prevState) => {
              return {
                ...prevState,
                newTransactionForm: {
                  ...prevState.newTransactionForm,
                  userId: user.id,
                },
                user,
              }
            })
            setErrorMsg(null)
          }
        } else {
          setState((prevState) => {
            return { ...prevState, user: null }
          })
          setErrorMsg('User does not exists.')
        }
      })
    } else {
      setState((prevState) => {
        return { ...prevState, user: null }
      })
      setErrorMsg(null)
    }
  }

  const handleEmailChangeDebounce = useCallback(
    debounce(
      (email) => {
        handleEmailLookup(email)
      },
      500,
      { leading: false, trailing: true }
    ),
    []
  )

  const renderResultAvatar = () => {
    let userAvatar =
      state.user.profile && state.user.profile.hashedFileName
        ? state.user.profile.avatar_url
        : userDefaultAvatar
    return (
      <div className="team-row__member preview" key={state.user.id}>
        <div className="team-row__user-avatar">
          <img src={userAvatar} alt={state.user.name} />
          <div className="team-row__user-meta">
            {state.user.name}
            <br />
            {state.user.email}
            <br />
          </div>

          {state.user.wallet && (
            <div
              className="team-row__user-meta"
              style={{ paddingLeft: '10%', textAlign: 'center' }}
            >
              Current Balance
              <br />
              {state.user.wallet.balance}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <section id="wallet_modal" className="dialog">
      <div className="dialog__content">
        {transactionSuccess === null && (
          <>
            <h1 className="dialog__title">Add New Transaction</h1>
            <label className={errorMsg !== null ? 'error' : 'valid'}>
              Email: {errorMsg !== null ? errorMsg : ''}
              <input
                type="text"
                value={
                  state.newTransactionForm.email
                    ? state.newTransactionForm.email
                    : ''
                }
                onChange={handleEmailChange}
                placeholder="e.g.: example@example.com"
                name="email"
                required
              />
            </label>

            {state.user && renderResultAvatar()}

            <div>
              <label>Transaction Type: </label>
              <select
                value={
                  state.newTransactionForm.transactionType
                    ? state.newTransactionForm.transactionType
                    : ''
                }
                name="transactionType"
                style={{ textTransform: 'capitalize' }}
                onChange={handleChange}
                disabled={!state.user}
                required
              >
                <option value="" hidden>
                  Select transaction type
                </option>
                {types.map((type) => {
                  return (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  )
                })}
              </select>
            </div>

            <div>
              <label>Transaction Reason: </label>
              <input
                type="text"
                value={
                  state.newTransactionForm.transactionReason
                    ? state.newTransactionForm.transactionReason
                    : ''
                }
                onChange={handleChange}
                name="transactionReason"
                placeholder="Transaction Reason"
                disabled={!state.user}
                required
              />
            </div>

            <div>
              <label>Transaction Amount: </label>
              <input
                type="number"
                name="transactionAmount"
                onChange={handleChange}
                placeholder="Transaction Amount"
                disabled={!state.user}
                required
              />
            </div>

            {formErrorMsg && (
              <div style={{ textAlign: 'center'}}>
                <label className='error'>{formErrorMsg}</label>
              </div>              
            )}

            <div className="dialog__controls">
              <button
                type="button"
                className="btn solid"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn white"
                onClick={() => {
                  closeModal()
                }}
              >
                Cancel
              </button>
            </div>
          </>
        )}

        {transactionSuccess === true && updatedWallet && (
          <>
            <div className='row'>
              <CheckCircleOutline
                style={{
                  fontSize: '5rem',
                  color: 'green'
                }}
              />
            </div>
            <div className='row'>
              <h1 className="dialog__title" style={{ marginTop: '2rem'}}>Transaction Success</h1>
            </div>

            <div>
              <p style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                Transaction Summary
              </p>
              <p>Customer Name : {state.user.name}</p>
              <p>Customer Email : {state.user.email} </p>
              <p>
                Transaction Type : {state.newTransactionForm.transactionType}
              </p>
              <p style={{ wordWrap: 'break-word' }}>
                Transaction Reason :{' '}
                {state.newTransactionForm.transactionReason}
              </p>
              <p>
                Transaction Amount :{' '}
                {state.newTransactionForm.transactionAmount}
              </p>
              <p style={{ marginTop: '3rem' }}>
                Previous Balance: {state.user.wallet.balance}
              </p>
              <p>New Balance : {updatedWallet.balance}</p>
            </div>
              
            <div className='row'>
              <button
                type="button"
                className="btn white"
                style={{ marginTop: '1rem' }}
                onClick={() => {
                  closeModal(true)
                }}
              >
                Close
              </button>
            </div>
          </>
        )}
        {transactionSuccess === false && (
          <>
            <div className='row'>
              <ErrorOutline style={{ color: 'red', fontSize: '5rem' }} />
            </div>
            <div className='row'>
              <h1 className="dialog__title" style={{ marginTop: '2rem'}}>Transaction Error</h1>
            </div>
            <div className='row'>
              <button
                type="button"
                className="btn white"
                style={{ marginTop: '1rem' }}
                onClick={() => {
                  closeModal()
                }}
              >
                Close
              </button>
            </div>
          </>
        )}
      </div>
    </section>
  )
}
