import L from 'leaflet'
import { Project, FlightPlan, OrganizationApi, ProjectPdf } from '../api'
import { setRequestInProgress } from './request'
import {
  setFlightPlansFetchDataSuccess,
  clearFlightPlanCreation,
  clearNewDrawing,
  setIsAddingFlightPlan,
  setIsEdittingFlightPlan,
} from './flightPlans'
import {
  setStartingMarker,
  setReviseSearchBounds,
  setProjectsMapCenter,
  setPreviewMarkers,
  clearReferenceBoundaryLayer,
  drawReferenceBoundaryLayer,
  fitMaptoReferenceBoundaryLayer,
} from './map'
import {
  getProducts,
  openSingleProduct,
  setProductMapPreview,
} from './products'
import { setAlertModal } from './ui'
import { fetchAnnotationsData } from './annotations'
import { alertTypes } from '../util/constants'
import { history } from '../store'

import { featureGroup } from 'leaflet'
import { debounce } from 'lodash'

export function setIsAddingProject(bool) {
  return {
    type: 'SET_IS_ADDING_PROJECT',
    isAddingProject: bool,
  }
}

export function setEditProject(bool) {
  return {
    type: 'SET_EDIT_PROJECT',
    isEdittingProject: bool,
  }
}

export function addProjectFiles(bool) {
  return {
    type: 'ADD_PROJECT_FILES',
    uploadingFiles: bool,
  }
}

export function projectsHasErrored(bool) {
  return {
    type: 'PROJECTS_HAS_ERRORED',
    hasErrored: bool,
  }
}

export function openAddProjectMenu(bool) {
  return {
    type: 'OPEN_ADD_PROJECT_MENU',
    openAddProjectMenu: bool,
  }
}

// multiple projects
export function setProjects(projects) {
  return {
    type: 'SET_PROJECTS',
    projects,
  }
}

// single project
export function setProject(project) {
  return {
    type: 'SET_PROJECT',
    project,
  }
}

// remove project from list
export function removeProject(projectId) {
  return {
    type: 'REMOVE_PROJECT',
    projectId,
  }
}

/**
 * Fetch project list from API
 */
export function fetchNextProjectPage() {
  return (dispatch, getState) => {
    const { projectSearchState, projectSearchOptions, projects } = getState()
    dispatch(setProjectSearchState({ isLoading: true }))
    Project.getProjects(
      projectSearchOptions.search,
      projectSearchState.page,
      projectSearchOptions.order,
      projectSearchOptions.bounds,
      projectSearchOptions.authored_only
    )
      .then((result) => {
        let newProjects = [...projects]
        newProjects = newProjects.concat(result.data)
        let hasMore = result.next_page_url !== null
        let page = result.current_page + 1
        dispatch(setProjects(newProjects))
        dispatch(setProjectSearchState({ hasMore, page }))
      })
      .catch(() => {
        dispatch(setProjectSearchState({ hasMore: false }))
      })
      .finally(() => {
        dispatch(
          setProjectSearchState({
            isLoading: false,
          })
        )
      })
  }
}

/**
 * Fetch map bound project list from API
 */
export function projectsBoundsFetchData(
  bounds,
  requestType = 'BOUNDED_PROJECTS'
) {
  return (dispatch) => {
    dispatch(setProjects([]))
    dispatch(setProjectSearchOptions({ bounds }))
    dispatch(setProjectSearchState({ page: 0, hasMore: true })) // this is required so that the spinner shows up
    dispatch(fetchNextProjectPage())
    dispatch(setReviseSearchBounds(false))
  }
}

/**
 * Fetch personal project list from API
 */
export function projectsMyFetchData() {
  return (dispatch) => {
    dispatch(setRequestInProgress(true, 'GLOBAL'))
    dispatch(setRequestInProgress(true, 'PERSONAL'))
    dispatch(setRequestInProgress(true, 'PROJECTS'))

    return Project.getMyProjects().then((response) => {
      dispatch(setRequestInProgress(false, 'GLOBAL'))
      dispatch(setRequestInProgress(false, 'PERSONAL'))
      dispatch(setRequestInProgress(false, 'PROJECTS'))
      dispatch(setProjects(response.data))
      dispatch(setReviseSearchBounds(false))
    })
  }
}

/**
 * Set project creation/editing form object used by AddProject and MainMap components
 */
export function setProjectForm(data) {
  // console.log('ACTION: SET PROJECT CREATE FORM', data)
  return {
    type: 'SET_PROJECT_FORM',
    data,
  }
}

export function setProjectPdf(data) {
  // console.log('ACTION: SET PROJECT CREATE FORM', data)
  return {
    type: 'SET_PROJECT_PDF',
    data,
  }
}

export function setProjectSearchOptions(options) {
  return {
    type: 'SET_PROJECT_SEARCH_OPTIONS',
    options,
  }
}

export function setProjectSearchState(state) {
  return {
    type: 'SET_PROJECT_SEARCH_STATE',
    state,
  }
}

/**
 * Store project in backend
 */
export function storeProjectData(data) {
  return (dispatch, getState) => {
    dispatch(setRequestInProgress(true, 'ADD_PROJECT'))
    dispatch(setRequestInProgress(true, 'GLOBAL'))
    dispatch(setRequestInProgress(true, 'PROJECTS'))

    const { viewportCentre } = getState()

    if (!data.flightPlans.length && data.centroid === undefined) {
      data.centroid = viewportCentre
    }

    Project.createProject(data).then((response) => {
      if (
        !data.updated_organization_id ||
        data.updated_organization_id === 'none'
      ) {
        onStoreSuccess(response.data.id)
        dispatch(fetchProject(response.data.id))
      } else {
        OrganizationApi.linkProject(
          data.updated_organization_id,
          response.data.id
        )
          .then((resp) => {
            if (resp.success) {
              onStoreSuccess(response.data.id)
            }
          })
          .catch(() => {
            dispatch(
              setAlertModal({
                message:
                  'Access denied. You don\'t have permission to assign organization to this project.',
                type: alertTypes.warning,
              })
            )
            data.updated_organization_id = null
          })
          .finally(() => {
            dispatch(fetchProject(response.data.id))
          })
      }
    })

    function onStoreSuccess(id) {
      dispatch(setProjectForm(null))
      dispatch(clearFlightPlanCreation())
      dispatch(setEditProject(false))
      dispatch(setIsAddingFlightPlan(false))
      dispatch(setIsEdittingFlightPlan(false))
      dispatch(clearReferenceBoundaryLayer())
      // dispatch(setIsViewingFlightPlan(false))
      history.push(`/project/${id}/plan`)
    }

    dispatch(setRequestInProgress(false, 'ADD_PROJECT'))
    dispatch(setRequestInProgress(false, 'GLOBAL'))
    dispatch(setRequestInProgress(false, 'PROJECTS'))
    dispatch(setIsAddingProject(false))
  }
}

export function setOpenSingleProject(bool) {
  return {
    type: 'PROJECTS_SINGLE_OPEN',
    openSingleProject: bool,
  }
}

/**
 * View project from previously requested projects response data
 */
export function viewSingleProject(id) {
  return (dispatch, getState) => {
    const { projects, leafletMapElement } = getState()

    if (projects.length > 0) {
      let selectProject

      // find the matching project by id from the redux store
      for (let index = 0; index < projects.length; index++) {
        const project = projects[index]
        if (project.id === id) {
          selectProject = project
        }
      }

      if (selectProject) {
        let coords = selectProject.centroid.coordinates
        // if no flight plans exist, pan to marker (<Map center={} state change seems not to update UI)
        if (leafletMapElement) {
          leafletMapElement.panTo([coords[1], coords[0]])
        }
        dispatch(setProjectsMapCenter([coords[1], coords[0]]))
        dispatch(setFlightPlansFetchDataSuccess(selectProject.flightPlans))

        dispatch(setProject(selectProject))
        dispatch(setOpenSingleProject(true))

        if (selectProject.flightPlans.length > 0) {
          let bounds = L.latLngBounds([])

          for (
            let index = 0;
            index < selectProject.flightPlans.length;
            index++
          ) {
            let shape = selectProject.flightPlans[index].geojson.geometry
            let layerBounds
            if (selectProject.flightPlans[index].type === 'map') {
              layerBounds = L.GeoJSON.coordsToLatLngs(shape.coordinates[0])
            } else {
              layerBounds = L.GeoJSON.coordsToLatLng(shape.coordinates)
            }
            bounds.extend(layerBounds)
          }
          if (selectProject.flightPlans.length > 0) {
            leafletMapElement.fitBounds(bounds, { maxZoom: 17 })
          }
        }
      }
    }
  }
}

/**
 * Fetch project from API
 */
export function fetchProject(id) {
  return (dispatch, getState) => {
    dispatch(clearReferenceBoundaryLayer())
    // load the existing data first to
    // get immediate info into componenet
    dispatch(viewSingleProject(id))

    dispatch(setRequestInProgress(true, 'PROJECT'))

    let path = history.location.pathname
    if (path.substr(path.length - 6) === 'plan' || path === '/') {
      history.push(`/project/${id}/plan`)
    }
    // then get a new server copy, in case something has changed
    Project.getProject(id)
      .then((response) => {
        dispatch(setRequestInProgress(false, 'PROJECT'))
        if (response.reference_boundary) {
          dispatch(drawReferenceBoundaryLayer(response.reference_boundary))
          dispatch(fitMaptoReferenceBoundaryLayer())
        }

        // TODO: stop-gap way to push reviewer project team users to only the review tab
        if (
          response.teamMemberRole &&
          response.teamMemberRole.name === 'reviewer'
        ) {
          history.push(`/project/${id}/review`)
        }

        if (!response.centroid) throw Error('No centroid')

        if (response.centroid) {
          // zoom into project (only seems to work when browser is refreshed)
          let coords = response.centroid.coordinates
          dispatch(setProjectsMapCenter([coords[1], coords[0]]))
          dispatch(
            setProductMapPreview({ center: [coords[1], coords[0]], zoom: 18 })
          )

          dispatch(setProject(response))
          dispatch(setOpenSingleProject(true))

          if (response.flightPlans.length > 0) {
            dispatch(setFlightPlansFetchDataSuccess(response.flightPlans))
            if (response.reference_boundary === null) {
              dispatch(fitMaptoFlightPlanBounds())
            }
          } else {
            // center map to centroid when no flight plans exist.
            // needed to do this manually because <Map center={}> prop change
            // doesn't seem to create the required re-render of the Map component
            // when going between states from ProjectList to individual Project view
          }

          const { leafletMapElement } = getState()

          let group = new featureGroup()
          if (leafletMapElement) {
            leafletMapElement.eachLayer(function (layer) {
              if (
                layer.feature !== undefined ||
                layer.options.className === 'annotation' ||
                layer.options.className === 'annotation-marker'
              ) {
                group.addLayer(layer)
              }
            })
            leafletMapElement.fitBounds(group.getBounds(), {
              padding: [20, 20],
            })
          }
        } else {
          dispatch(
            setAlertModal({
              message: 'Project not found',
              type: alertTypes.error,
            })
          )
          history.push('/')
        }
      })
      .catch((response) => {
        if (response.error === 'Project not found.') {
          dispatch(
            setAlertModal({
              message: 'Project not found',
              type: alertTypes.error,
            })
          )
          history.push('/')
        }
      })
  }
}

export function fitMaptoFlightPlanBounds() {
  return (getState) => {
    const { flightPlans, leafletMapElement } = getState()

    if (flightPlans.length > 0) {
      let bounds = L.latLngBounds([])
      for (let index = 0; index < flightPlans.length; index++) {
        let shape = flightPlans[index].geojson.geometry
        let layerBounds
        // console.log(flightPlans)
        if (flightPlans[index].type === 'map') {
          layerBounds = L.GeoJSON.coordsToLatLngs(shape.coordinates[0])
        } else {
          layerBounds = L.GeoJSON.coordsToLatLng(shape.coordinates)
        }
        bounds.extend(layerBounds)
      }
      if (flightPlans.length > 0) {
        leafletMapElement.fitBounds(bounds, { maxZoom: 19 })
      }
    }
  }
}

/**
 * Fetch edit project from API
 */
export function fetchEditProjectData(id) {
  return (dispatch) => {
    // console.log('fetching edit project data')

    dispatch(setRequestInProgress(true, 'GLOBAL'))

    Project.getProject(id)
      .then((response) => {
        dispatch(setProjectForm(response))
        dispatch(setIsAddingProject(true))

        let coords = response.centroid.coordinates
        dispatch(setProjectsMapCenter([coords[1], coords[0]]))

        ProjectPdf.getProjectPdfs(id).then(response => {
          if (response.data) {
            dispatch(setProjectPdfFetchDataSuccess(response.data))
            dispatch(setProjectForm({ projectPdfs: response.data }))
            dispatch(setProjectPdf(response.data))
          }
        })

        FlightPlan.getFlightPlans(`?project_id=${id}`).then((response) => {
          dispatch(setRequestInProgress(false, 'GLOBAL'))
          if (response.data) {
            dispatch(setFlightPlansFetchDataSuccess(response.data))
            dispatch(setProjectForm({ flightPlans: response.data }))
          }
        })
      })
      .catch((error) => {
        dispatch(setRequestInProgress(false, 'GLOBAL'))
        dispatch(
          setAlertModal({
            message: 'Project not found',
            type: alertTypes.error,
          })
        )
        console.log(error)
        history.push('/')
      })
  }
}

/**
 * Update individual project
 */
export function updateProjectData(data) {
  return (dispatch) => {
    let id = data.id

    dispatch(setRequestInProgress(true, 'GLOBAL'))
    dispatch(setRequestInProgress(true, 'PROJECTS'))
    Project.updateProject(id, data).then(() => {
      if (
        !data.updated_organization_id ||
        data.updated_organization_id === data.organization_id
      ) {
        onUpdateSuccess(id)
        dispatch(fetchProject(id))
      } else {
        if (data.updated_organization_id === 'none') {
          OrganizationApi.unlinkProject(id)
            .then((resp) => {
              if (!resp.success) {
                dispatch(
                  setAlertModal({
                    message:
                      'Access denied. You don\'t have permission to remove organization from this project.',
                    type: alertTypes.warning,
                  })
                )
                data.updated_organization_id = null
              } else {
                onUpdateSuccess(id)
              }
            })
            .finally(() => {
              dispatch(fetchProject(id))
            })
        } else {
          OrganizationApi.linkProject(data.updated_organization_id, id)
            .then((resp) => {
              if (resp.success) {
                onUpdateSuccess(id)
              }
            })
            .catch(() => {
              dispatch(
                setAlertModal({
                  message:
                    'Access denied. You don\'t have permission to assign organization to this project.',
                  type: alertTypes.warning,
                })
              )
              data.updated_organization_id = null
            })
            .finally(() => {
              dispatch(fetchProject(id))
            })
        }
      }

      function onUpdateSuccess(id) {
        dispatch(setProjectForm(null))
        dispatch(setIsAddingProject(false))
        dispatch(setEditProject(false))
        dispatch(setIsAddingFlightPlan(false))
        dispatch(setIsEdittingFlightPlan(false))
        dispatch(clearReferenceBoundaryLayer())
        history.push(`/project/${id}/plan`)
      }

      dispatch(setRequestInProgress(false, 'GLOBAL'))
      dispatch(setRequestInProgress(false, 'PROJECTS'))
    })
  }
}

/**
 * Delete project in backend
 */
export function deleteProjectData(id) {
  return (dispatch) => {
    dispatch(setRequestInProgress(true, 'DELETE_PROJECT'))
    dispatch(setRequestInProgress(true, 'GLOBAL'))
    Project.deleteProject(id)
      .then(() => {
        dispatch(removeProject(id))
        history.push('/')
      })
      .finally(() => {
        dispatch(setRequestInProgress(false, 'GLOBAL'))
        dispatch(setRequestInProgress(false, 'DELELTE_PROJECT'))
      })
  }
}

/**
 * Edit project
 */
export function editProject(id) {
  return (dispatch) => {
    dispatch(setEditProject(true))
    dispatch(fetchEditProjectData(id))
  }
}

/**
 * Delete project
 */
export function deleteProject(id) {
  return (dispatch) => {
    dispatch(
      setAlertModal({
        message:
          'Are you sure that you want to PERMANENTLY DELETE this project and all its associated product files?',
        type: alertTypes.warning,
        cancellable: true,
        confirmHandler: () => {
          dispatch(deleteProjectData(id))
          dispatch(backToProjectList())
        },
      })
    )
  }
}

export function backToProjectList() {
  return (dispatch, getState) => {
    // const { activeMapLayer, drawingFlightMapObjects } = getState()
    // console.log('back project list')
    dispatch(setProjectForm(null))
    dispatch(setFlightPlansFetchDataSuccess([]))
    dispatch(setPreviewMarkers(null))
    dispatch(clearFlightPlanCreation())
    dispatch(setEditProject(false))
    // dispatch(setIsViewingFlightPlan(false))
    dispatch(setOpenSingleProject(false))
    dispatch(clearReferenceBoundaryLayer())

    // if (activeMapLayer) {
    //   drawingFlightMapObjects && drawingFlightMapObjects.remove()
    // }
    dispatch(clearNewDrawing())

    const { startingMarker } = getState()
    if (startingMarker && startingMarker.hasOwnProperty('editing')) {
      startingMarker.remove()
      dispatch(setStartingMarker(null))
    }
  }
}

export function setMetadatas(metadatas) {
  return {
    type: 'SET_METADATAS',
    metadatas,
  }
}

export function getProjectAndProduct(projectId, productId) {
  return (dispatch) => {
    dispatch(setRequestInProgress(true, 'GLOBAL'))
    Project.getProject(projectId)
      .then((response) => {
        dispatch(setProject(response))
        dispatch(getProducts(response.id))
      })
      .finally(() => {
        dispatch(openSingleProduct(productId))
        dispatch(fetchAnnotationsData(productId))
        dispatch(setRequestInProgress(false, 'GLOBAL'))
      })
  }
}

export function setProjectFavourite(projectId, starred) {
  return (dispatch) => {
    // TODO: this actually doesn't make any sense,
    // we are explicitly setting the `starred` value
    // but then simply toggling in the underlying
    // client-list. this is not necessarily the
    // same operation.
    dispatch(toggleProjectFavourite(projectId))
    if (!starred) {
      Project.markFavourite(projectId).catch(console.error)
    } else {
      Project.removeFavourite(projectId).catch(console.error)
    }
  }
}

export function toggleProjectFavourite(id) {
  return {
    type: 'TOGGLE_PROJECT_FAVOURITE',
    id,
  }
}

export function setIsUploadingGCPs(boolean) {
  return {
    type: 'SET_IS_UPLOADING_GCPS',
    boolean,
  }
}

export function setIsGCPOverlayOpen(bool) {
  return {
    type: 'SET_IS_GCP_OVERLAY_OPEN',
    isGCPOverlayOpen: bool,
  }
}

export function setIsSendingToCustomer(bool) {
  return {
    type: 'SET_IS_SENDING_TO_CUSTOMER',
    isSendingToCustomer: bool,
  }
}

export function setProjectPdfFetchDataSuccess(projectPdfs) {
  return {
    type: 'SET_PDF_DATA_SUCCESS',
    projectPdfs
  }
}

export function fetchProjectPdfData(project_id) {
  return (dispatch) => {
    ProjectPdf.getProjectPdfs(project_id).then(response => {
      if (response.data) {
        dispatch(setProjectPdfFetchDataSuccess(response.data))
        dispatch(setProjectForm({ projectPdfs: response.data }))
      }
    })
  }
}

export function removeProjectPdf(projectPdfId) {
  return {
    type: 'REMOVE_PROJECT_PDF',
    projectPdfId,
  }
}

export function deleteProjectPdfData(pdf) {
  return (dispatch) => {
    ProjectPdf.deleteProjectPdf(pdf.id).then(response => {
      dispatch(removeProjectPdf(pdf.id))
      dispatch(fetchProjectPdfData(pdf.project_id))
    })
  }
}

export function storeProjectPdf(data) {
  return (dispatch) => {
    ProjectPdf.postProjectPdf(data.projectId, data.name).then(response => {
      if (response.error) {
        dispatch(
          setAlertModal({
            message: response.error,
            type: alertTypes.warning,
          })
        )
      }
      dispatch(fetchProjectPdfData(data.projectId))
    })
  }
}

export const getProjectPdfDebounceInnerFunc = debounce(
  (dispatch, project_id) => {
    ProjectPdf.getProjectPdfs(project_id).then(response => {
      dispatch(setProjectPdfFetchDataSuccess(response.data))
      dispatch(setProjectForm({ projectPdfs: response.data }))
    })
      .catch(console.error)
  },
  20000,
  {
    'leading': true,
    'trailing': true,
    'maxWait': 0
  }
)

export const getProjectPdfDebounced = (project_id) => dispatch => {
  getProjectPdfDebounceInnerFunc(dispatch, project_id)
}