import React, { useState, useEffect } from 'react'
import './style.css'
import { Wallet } from '../../api'
import TextField from '@material-ui/core/TextField'
import moment from 'moment'

export default function WalletStats() {
  const [state, setState] = useState({
    data: {},
    isLoading: false,
    startDate: '2020-04-03',
    endDate: moment().format('YYYY-MM-DD'),
    isFiltered: false,
  })

  const fetchData = async (startDate, endDate) => {
    const result = await Wallet.getStats(startDate, endDate)
    if (result && result.success) {
      return result.data
    }
  }

  useEffect(() => {
    setState((prevState) => {
      return { ...prevState, isLoading: true }
    })
    fetchData().then((data) => {
      setState((prevState) => {
        return { ...prevState, data, isLoading: false }
      })
    })
  }, [])

  const onDateChange = (event) => {
    event.persist()
    if (event.target && event.target.id) {
      setState((prevState) => {
        return { ...prevState, [event.target.id]: event.target.value }
      })
    }
  }

  const onDateSubmit = () => {
    setState((prevState) => {
      return { ...prevState, data: {}, isLoading: true }
    })
    fetchData(state.startDate, state.endDate).then((data) => {
      setState((prevState) => {
        return { ...prevState, data, isLoading: false, isFiltered: true }
      })
    })
  }

  return (
    <div className="wallet-stats">
      <h1 className="sidebar-header__title" style={{ marginBottom: '1rem' }}>
        Wallet Statistic
      </h1>
      <form className="date-range" noValidate>
        <TextField
          id="startDate"
          label="Start Date"
          type="date"
          defaultValue={state.startDate}
          className="date-range__start"
          style={{ marginRight: '2rem' }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onDateChange}
        />

        <TextField
          id="endDate"
          label="End Date"
          type="date"
          defaultValue={state.endDate}
          className="date-range__end"
          InputLabelProps={{
            shrink: true,
          }}
          onChange={onDateChange}
        />

        <button type="button" className="btn-primary btn-primary-white" onClick={onDateSubmit} style={{ marginLeft: '2rem' }}>
          Search
        </button>
      </form>

      {state.data && Object.keys(state.data).length === 0 && state.isLoading && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}

      {state.data && Object.keys(state.data).length !== 0 && !state.isLoading && (
        <div className="wallet-stats__content">
          <div className="wallet-stat" style={{ borderRight: '1px solid rgba(145, 145, 145, .2)', paddingRight: '4rem' }}>
            <h2>Total Credits</h2>
            {state.data.totalCreditsGiven} <span>given</span>
            <br />
            {state.data.totalCreditsUsed} <span>used</span>
            <br />
            {!state.isFiltered && (
              <div className="wallet-stat__subtotal">
                {state.data.totalCreditsGiven - state.data.totalCreditsUsed} <span>remaining</span>
                <br />
              </div>
            )}
          </div>

          <div className="wallet-stat">
            <h2>Free Credits</h2>
            {state.data.freeCreditsGiven} <span>given</span>
            <br />
            {state.data.freeCreditsUsed} <span>used</span>
            <br />
            {!state.isFiltered && (
              <div className="wallet-stat__subtotal">
                {state.data.freeCreditsGiven - state.data.freeCreditsUsed} <span>remaining</span>
                <br />
              </div>
            )}
          </div>
          <div className="wallet-stat">
            <h2>Purchased Credits</h2>
            {state.data.purchasedCreditsGiven} <span>given</span>
            <br />
            {state.data.purchasedCreditsUsed} <span>used</span>
            <br />
            {!state.isFiltered && (
              <div className="wallet-stat__subtotal">
                {state.data.purchasedCreditsGiven - state.data.purchasedCreditsUsed} <span>remaining</span>
                <br />
              </div>
            )}
          </div>
          <div className="wallet-stat">
            <h2>Retry Credits</h2>
            {state.data.retryCreditsGiven} <span>given</span>
            <br />
            {state.data.retryCreditsUsed} <span>used</span>
            <br />
            {!state.isFiltered && (
              <div className="wallet-stat__subtotal">
                {state.data.retryCreditsGiven - state.data.retryCreditsUsed} <span>remaining</span>
                <br />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}
