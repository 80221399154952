import React from 'react'
import './styles.css'
import IconCheckbox from '../IconCheckbox'

function getAnnotationMeasureNumber(annotation) {
  if (!annotation.data) {
    return ''
  } else if (annotation.type === 'map-volume') {
    return `${annotation.data.total.toFixed(3)} m³`
  } else if (annotation.type === 'map-area') {
    return `${annotation.data.area_2d.toFixed(3)} m²`
  } else if (annotation.type === 'map-distance') {
    return `${annotation.data.distance.toFixed(3)} m`
  } else if (annotation.type === 'map-location') {
    return `${annotation.data.elevation.toFixed(3)} m`
  }
  return ''
}

function getAnnotationTypeIcon(annotation) {
  if (annotation.type === 'map-area') {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 43">
      <g className="annotation__icon" data-name="Group 2316" transform="translate(-27 -309)">
        <circle id="Ellipse_96" data-name="Ellipse 96" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(27 328)" />
        <g id="Group_2308" data-name="Group 2308">
          <circle id="Ellipse_95" data-name="Ellipse 95" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(42 309)" />
          <circle id="Ellipse_97" data-name="Ellipse 97" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(40 344)" />
          <circle id="Ellipse_98" data-name="Ellipse 98" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(65 309)" />
          <circle id="Ellipse_99" data-name="Ellipse 99" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(81 330)" />
          <circle id="Ellipse_100" data-name="Ellipse 100" className="cls-1" cx="1.5" cy="1.5" r="1.5" transform="translate(73 349)" />
          <g id="Group_2307" data-name="Group 2307">
            <g id="Group_2294" data-name="Group 2294" transform="translate(-238 -283.535)">
              <path id="Path_299" data-name="Path 299" className="cls-2" d="M14467.9,6517.269l-15.561,18.785,13.325,16.269,32.89,5.1,8.408-18.939-16.169-21.215Z" transform="translate(-14186 -5923)" />
              <path id="Path_300" data-name="Path 300" className="cls-3" d="M14467.9,6517.269l-15.561,18.785,13.325,16.269,32.89,5.1,8.408-18.939-16.169-21.215Z" transform="translate(-14186 -5923)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  } else if (annotation.type === 'map-volume') {
    return <svg viewBox="0 0 240 316" className="volume">
      <path
        d="M120 0C52.7 0 0 27.2 0 61.8v192.3C0 288.8 52.7 316 120 316s120-27.2 120-61.8V61.8C240 27.2 187.3 0 120 0zm0 13.7c56.4 0 104 22 104 48.1s-47.6 48.1-104 48.1-104-22-104-48.1 47.6-48.1 104-48.1zM224 93v130c-20.5-18.4-59-30.6-104-30.6S36.5 204.5 16 223V93c20.5 18.5 59 30.7 104 30.7s83.5-12.2 104-30.7zM120 302.3c-56.4 0-104-22-104-48.1s47.6-48.1 104-48.1 104 22 104 48.1-47.6 48.1-104 48.1z"
        fill="#426288"
      />
    </svg>
  } else if (annotation.type === 'map-distance') {
    return <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 43.7 35.4"
      xmlSpace="preserve"
    >
      <g id="prefix__Symbol_2_1" transform="translate(-26 -353)">
        <g id="prefix__Group_2308" transform="translate(-1 44)">
          <ellipse
            id="prefix__Ellipse_98"
            transform="rotate(-3.371 28.721 310.723) scale(1.00003)"
            style={{ fill: '#6aaec4' }}
            cx={28.7}
            cy={310.7}
            rx={1.7}
            ry={1.7}
          />
          <ellipse
            id="prefix__Ellipse_99"
            transform="rotate(-3.371 69.01 342.655) scale(1.00003)"
            style={{ fill: '#6aaec4' }}
            cx={69}
            cy={342.6}
            rx={1.7}
            ry={1.7}
          />
          <g id="prefix__Group_2307">
            <g id="prefix__Group_2294" transform="translate(-238 -283.535)">
              <path
                id="prefix__Path_300_2_"
                d="M306.1 625.5l-39.2-30.7"
                fill="none"
                stroke="#6aaec4"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  } else if (annotation.type === 'map-location') {
    return <svg viewBox="0 0 10.357 14.796" className="location-icon">
      <g data-name="Group 2339">
        <path
          data-name="Path 313"
          d="M5.27 2.79a2.726 2.726 0 1 1-2.726 2.726A2.726 2.726 0 0 1 5.27 2.79z"
          fill="#fff"
        />
        <path
          d="M5.179 0A5.175 5.175 0 0 0 0 5.179c0 3.884 5.179 9.617 5.179 9.617s5.179-5.733 5.179-9.617A5.175 5.175 0 0 0 5.179 0zm0 7.028a1.85 1.85 0 1 1 1.85-1.85 1.85 1.85 0 0 1-1.85 1.85z"
          fill="#426288"
          data-name="Group 760"
        />
      </g>
    </svg>
  }
}

const AnnotationsList = props => {
  return (
    <section className="annotations review-section">
      <h4 className="section-title">Measurements & Annotations</h4>
      <div className="measure-annotation-list list">
        {props.annotations.length < 1 && (
          <article className="annotation row">
            <svg className="not-found-illustration" viewBox="0 0 661.94 321.658">
              <path
                style={{ fill: '#dadbe0' }}
                d="M184.824 184.682c26.385-31.119 64.476-47.613 102.055-61.038a307.724 307.724 0 0 1 66.315-15.991c22.387-2.719 45.223-2.742 67.722-1.255a309.7 309.7 0 0 1 76.285 14.3c4.9 1.6 7-6.108 2.127-7.708a312.214 312.214 0 0 0-69.465-13.9 373.584 373.584 0 0 0-70.76-.1 312.966 312.966 0 0 0-67.962 14.7c-19.749 6.6-39.442 14.24-57.944 23.827-20.293 10.522-39.178 23.987-54.058 41.489-3.31 3.9 2.319 9.595 5.653 5.653zM293.196 177.639l26.785 13.1 4-6.908-2.159-.983c-4.645-2.119-8.707 4.8-4 6.908l2.159.983c4.613 2.111 8.7-4.621 4-6.908l-26.785-13.1c-4.605-2.247-8.659 4.645-4 6.908z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M306.885 164.63l-7.692 31.67 7.852 1.063v-2.215c.04-5.149-7.955-5.157-8 0v2.215c0 4.453 6.772 5.5 7.852 1.063l7.692-31.67c1.219-4.997-6.489-7.131-7.704-2.126zM351.411 188.052c-1.151-1.919-.512-5.029 1.719-5.965 3.07-1.3 5.4 2.287 5.109 4.909-.24 2.159 2.007 4 4 4 2.359 0 3.766-1.863 4-4 .879-8.06-7.676-15.191-15.415-12.561-7.052 2.4-10.074 11.394-6.308 17.654 2.646 4.406 9.595.392 6.9-4zM395.738 149.718l-17.07 24.45c-2.974 4.262 3.9 8.107 6.908 4l1.407-1.9c3.062-4.142-3.878-8.14-6.9-4l-1.415 1.911 6.908 4 17.07-24.45c2.95-4.23-4-8.227-6.908-4z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M378.668 154.668l22.084 23.986c3.126 3.39 8.475-.951 6.276-4.845l-1.1-1.951c-2.526-4.485-9.435-.456-6.9 4l1.087 1.935 6.276-4.845-22.084-23.987c-3.494-3.8-9.131 1.871-5.653 5.653zM303.454 235.047a134.082 134.082 0 0 0 106.732-21.844c4.126-2.99.144-9.93-4.038-6.908a126.359 126.359 0 0 1-100.568 21.044c-5.013-1.111-7.156 6.6-2.127 7.708z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M182.954 195.237c42.488-7.42 87.151 7.084 117.534 37.579 3.638 3.654 9.291-2 5.653-5.653a143.372 143.372 0 0 0-125.33-39.634c-5.061.888-2.918 8.6 2.119 7.708zM413.246 207.797c-2.1-11.13 7.412-22.124 13.816-30.247a194.147 194.147 0 0 1 74.822-57.744c4.693-2.071.632-8.963-4-6.908a200.536 200.536 0 0 0-80.611 64.4c-7.084 9.547-14.072 20.2-11.721 32.622.959 5.053 8.667 2.91 7.708-2.127z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M186.321 190.863c34.429-15.887 69.617-30.663 106.748-38.954 5.021-1.119 2.894-8.8-2.127-7.708-37.795 8.443-73.615 23.587-108.659 39.754-4.669 2.159-.608 9.051 4.038 6.908zM303.942 149.495a911.715 911.715 0 0 1 184.969-26.769c5.133-.208 5.157-8.2 0-8a921.8 921.8 0 0 0-187.1 27.057c-4.977 1.259-2.863 8.967 2.131 7.712zM252.731 201.145c-26.7 25.042-36.148 66.307-22.659 100.4 1.871 4.733 9.595 2.671 7.708-2.119-12.5-31.614-4.23-69.337 20.612-92.628 3.759-3.526-1.903-9.171-5.661-5.653z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M269.459 202.816c-31.926 25.586-41.3 70.5-23.307 107.068 2.271 4.613 9.171.568 6.908-4-16.519-33.581-6.964-74.094 22.052-97.378 4-3.2-1.671-8.843-5.653-5.653z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M231.822 311.204c3.526 6.444 10.474 13.744 18.39 8.859 6.828-4.222 6.165-13.081.208-17.63-4.086-3.118-8.075 3.822-4.038 6.908a2.4 2.4 0 0 1-1.423 4.23c-3.062.16-5.029-4.245-6.212-6.4-2.471-4.517-9.379-.487-6.9 4zM213.113.234a104.149 104.149 0 0 0-32.654 19.133c-4.318 3.75-9.467 8.132-11.29 13.76a10.834 10.834 0 0 0 4.989 13c4.446 2.447 9.739 2.167 14.576 1.375a73.059 73.059 0 0 0 18.694-5.837c7.14-3.27 26.385-14.048 15.847-23.947-8.2-7.732-23.483 2.814-30.247 7.836-7.34 5.445-19.085 18.03-8 26.233 8.923 6.588 23.723.056 32.366-3.95 5.6-2.591 11.642-5.829 16.175-10.066 3.462-3.246 4.342-9.035-.248-11.785-4.981-2.99-12.2 0-16.99 1.919-5.949 2.4-11.378 6.484-13.489 12.793-3.734 11.1 8.18 22.332 19.077 17.814 4.685-1.935 2.631-9.674-2.127-7.708-5.453 2.255-11.761-2.711-8.995-8.571 1.6-3.334 4.869-5.405 8.147-6.788a51.956 51.956 0 0 1 5.317-1.791c.712-.208 4.07-.456 4.6-.863l-.943-.664-1.088.879a19.243 19.243 0 0 0-2.215 1.6q-2.535 1.727-5.181 3.246a71.627 71.627 0 0 1-10.714 5.061c-4.2 1.559-15.008 6.284-19.133 2.335-5.093-4.869 8.843-13.848 11.921-15.791a55.169 55.169 0 0 1 10.234-5.3c1.807-.664 7.2-2.351 5.813 1.159-1.191 3.086-6.524 5.485-9.219 6.988a63.958 63.958 0 0 1-11.657 5.085c-4.3 1.391-15.136 5.381-19.189 1.407-4.8-4.717 11.346-16.151 14.592-18.5a95.943 95.943 0 0 1 23.123-12.321c4.8-1.759 2.718-9.491-2.127-7.708z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M274.983 208.405a55.849 55.849 0 0 0-3.374 47.653c1.9 4.973 3.2 8.907.152 13.713-2.623 4.189-5.477 8.243-8.219 12.353l-17.126 25.706c-2.862 4.3 4.062 8.307 6.9 4.038l16.695-25.05c4.8-7.2 12.833-15.447 11.674-24.706-.552-4.453-2.663-8.435-4-12.689a45.691 45.691 0 0 1-2.007-12.321 47.517 47.517 0 0 1 6.181-24.658c2.535-4.486-4.373-8.523-6.9-4z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M248.117 299.386l21.591-30.687c2.966-4.222-4-8.219-6.9-4.038l-21.588 30.687c-2.966 4.222 4 8.22 6.908 4.038z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M262.014 213.035a74.983 74.983 0 0 0 .416 51.859c1.815 4.757 9.547 2.694 7.716-2.127a68.018 68.018 0 0 1-.424-47.613c1.75-4.846-5.973-6.935-7.708-2.119zM437.828 168.876c31.894 10.138 55.865 40.513 58.159 73.927.352 5.109 8.347 5.149 8 0-2.543-37.075-28.584-70.361-64.028-81.634-4.924-1.6-7.024 6.155-2.131 7.707z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M423.459 177.587a79.867 79.867 0 0 1 61.565 78.628c-.056 5.141 7.94 5.149 8 0a87.964 87.964 0 0 0-67.43-86.348c-5.005-1.175-7.14 6.532-2.127 7.716z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M498.524 251.229c-.128 2.4-.08 7.068-2.87 8.171a2.407 2.407 0 0 1-2.926-3.478c2.4-4.558-4.525-8.611-6.908-4-3.582 6.884.743 15.711 8.971 15.655 8.891-.072 11.338-9.091 11.729-16.311.28-5.141-7.715-5.125-8 0z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M419.013 184.147a47.437 47.437 0 0 1 15.994 19.445 46.483 46.483 0 0 1 3.486 11.993c.616 4.174.408 8.515 1.6 12.585 2.591 8.579 11.833 12.169 18.909 16.391l28.568 17.047c4.43 2.638 8.459-4.27 4.038-6.908l-27.892-16.639c-4.182-2.494-8.691-4.741-12.633-7.612s-3.926-7.356-4.3-11.761a55.9 55.9 0 0 0-23.776-41.417c-4.23-2.958-8.227 4-4 6.908z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M490.966 241.146l-32.678-18.39c-4.485-2.527-8.523 4.382-4 6.908l32.67 18.39c4.493 2.527 8.523-4.374 4.038-6.9z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M428.12 180.253a58.319 58.319 0 0 1 21.172 46.414c-.128 5.149 7.868 5.149 8 0a65.931 65.931 0 0 0-23.515-52.011c-3.926-3.27-9.595 2.359-5.653 5.653zM142.488 160.049a35.625 35.625 0 0 0-14.456 8.371c-3.142 3.2-4.861 8.363-.8 11.609s9.794 1.255 14.208.064a107.51 107.51 0 0 0 16.631-6.037 98.6 98.6 0 0 0 15.7-8.9c3.438-2.4 7.708-6.6 4.845-11.146s-9.395-2.886-13.688-1.959a61.029 61.029 0 0 0-16.2 6.133c-4.526 2.447-.5 9.355 4.038 6.9a53.139 53.139 0 0 1 10.33-4.318c1.3-.384 2.631-.72 4-1.007.7-.152 5.173-.448 5.373-.8-.112-2.487-.632-3-1.559-1.519-.744.552-1.5 1.087-2.287 1.6-1.143.8-2.319 1.519-3.5 2.247a103.308 103.308 0 0 1-9.147 4.981 96.622 96.622 0 0 1-19.349 6.788c-1.111.264-3.734.168-4.645.64l.928 1.215a14.187 14.187 0 0 1 1.775-1.759 28.041 28.041 0 0 1 9.938-5.413c4.885-1.6 2.8-9.291-2.127-7.708z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M123.403 178.013l11.25 27.617c1.879 4.629 9.323 2.9 7.708-2.119l-.576-1.791c-1.6-4.885-9.291-2.8-7.708 2.119l.576 1.791 7.708-2.119-11.25-27.625c-1.911-4.7-9.651-2.637-7.708 2.127zM172.136 159.969l9.819 25.929 5.869-4.518-1.759-.864c-4.6-2.263-8.659 4.63-4.038 6.9l1.759.871c2.806 1.383 7.2-.975 5.877-4.517l-9.819-25.905c-1.807-4.8-9.539-2.694-7.708 2.127z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M130.159 165.365a167.64 167.64 0 0 0-71.08 24.786c-4.07 2.574-9.419 5.461-10.466 10.618-1.087 5.365 3.4 9.707 8.411 10.394 5.949.8 12.721-2.119 18.286-3.942 5.813-1.911 11.506-4.118 17.11-6.556a187.725 187.725 0 0 0 37.579-21.908c4.07-3.046.088-9.994-4-6.9a188.254 188.254 0 0 1-34.381 20.493 175.823 175.823 0 0 1-18.39 7.2q-4.605 1.511-9.283 2.774c-1.647.448-8.875 2.534-6.6-1.135 1.287-2.071 5.541-3.926 7.556-5.165 2.734-1.7 5.533-3.3 8.363-4.8a155.928 155.928 0 0 1 17.862-8.187 159.913 159.913 0 0 1 39.066-9.547c5.053-.568 5.109-8.571 0-8zM169.793 157.114c11.1-7.412 22.076-15.24 34.117-21.068a116.035 116.035 0 0 1 19.429-7.3 122.768 122.768 0 0 1 9.906-2.327 95.986 95.986 0 0 1 4.294-.744 29.792 29.792 0 0 1 2.91-.36q-.8-2.4-1.943-1.079c-1.183.288-2.886 2.151-3.9 2.87s-2.255 1.6-3.406 2.4q-4 2.711-8.147 5.2a181.9 181.9 0 0 1-16.447 8.8 179.708 179.708 0 0 1-36.2 12.345c-5.013 1.151-2.894 8.859 2.127 7.708a186.7 186.7 0 0 0 64.98-28.672c3.606-2.518 9.155-5.405 9.978-10.178.928-5.341-4.19-7.668-8.667-7.14a122.335 122.335 0 0 0-35.652 10.114c-13.345 5.973-25.314 14.528-37.419 22.611-4.262 2.846-.256 9.771 4.038 6.9z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M228.944 128.738c6.548-4.453 13.081-8.547 20.788-10.61 2.111-.568 4.262-1.015 6.4-1.4l1.311-.224q2.99.192.68-3.374c-.488-.176-2.495 1.255-2.99 1.487l-15.991 7.6c-4.637 2.215-.584 9.107 4.038 6.908l11.114-5.293c3.2-1.519 7.388-2.75 9.842-5.437a5.981 5.981 0 0 0 .96-7.3c-1.6-2.615-4.581-2.982-7.38-2.662-12.361 1.431-22.683 6.492-32.838 13.4-4.23 2.87-.24 9.8 4 6.9z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M257.36 115.585l5.013 12.649 7.708-2.127-.664-1.663c-.8-2.007-2.671-3.406-4.917-2.79-1.9.52-3.6 2.9-2.79 4.917l.664 1.663c.8 2.007 2.671 3.414 4.917 2.8 1.911-.528 3.59-2.9 2.79-4.925l-5.005-12.641c-.8-2.007-2.678-3.414-4.925-2.8-1.904.527-3.591 2.902-2.791 4.917z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M261.539 115.585a99.072 99.072 0 0 1 52.067-29.088c1.863-.368 7.2-1.079 3.326 1.327-2.239 1.375-4.637 2.542-6.964 3.75a244.866 244.866 0 0 1-15.991 7.564 253.783 253.783 0 0 1-32.35 11.337c-4.941 1.383-2.846 9.1 2.127 7.716a266.893 266.893 0 0 0 40.98-15.187c6.045-2.822 13.185-5.6 18.39-9.818 4.39-3.55 5.333-9.827.28-13.393-5.205-3.662-12.793-1.023-18.318.5a108.336 108.336 0 0 0-18.046 6.8 106.973 106.973 0 0 0-31.183 22.811c-3.542 3.734 2.095 9.395 5.653 5.653zM174.167 142.866a45.575 45.575 0 0 1-14.016 3.582 43.607 43.607 0 0 1-6.4 0c-.248 0-3.254-.2-3.238-.456a13.084 13.084 0 0 0 1.815-1.263c6.916-5.309 16.071-8.915 24.234-11.761a123.836 123.836 0 0 1 52.85-6.245c5.117.5 5.077-7.5 0-8a130.326 130.326 0 0 0-67.962 11.841c-6.149 2.894-24.786 10.674-17.358 20.277 3.2 4.118 9.946 3.862 14.616 3.654a51.285 51.285 0 0 0 17.59-3.958c4.677-1.959 2.623-9.7-2.127-7.708zM521.02 83.387a30.211 30.211 0 0 1 10.69.528c.96.232 1.9.52 2.839.847l.76-.951a32.451 32.451 0 0 1-4.869 1.247 96.02 96.02 0 0 1-19.677 1.535c-3.2-.072-6.444-.288-9.643-.672a78.322 78.322 0 0 1-5.6-.864c-.048 0-5.365-1.6-2.479 1.439.184.192 9.475-2.111 10.306-2.191a59.008 59.008 0 0 1 10.154-.056c5.133.384 5.109-7.612 0-8a61.308 61.308 0 0 0-17.67 1.223c-4.294.943-10.466 2.239-10.9 7.636-.392 4.845 4.581 6.9 8.459 7.66a103.278 103.278 0 0 0 18.078 1.823 108.294 108.294 0 0 0 18.126-1.327c4.254-.664 10.035-1.191 12.593-5.237 2.471-3.91.1-8.219-3.59-10.17-5.157-2.726-11.993-3.086-17.59-2.471-5.061.552-5.117 8.547 0 8z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M537.227 84.643l1.015 29.8h8l-.208-1.863c-.552-5.061-8.555-5.117-8 0l.208 1.863c.552 4.989 8.18 5.261 8 0l-1.016-29.8c-.179-5.134-8.175-5.153-7.999 0zM485.377 88.081l1.6 27.672c.184 3.134 4.446 5.693 6.828 2.831l1.279-1.5c3.262-3.934-2.4-9.595-5.653-5.653l-1.247 1.511 6.82 2.822-1.6-27.673c-.3-5.117-8.3-5.149-8 0z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M536.54 82.755a159.512 159.512 0 0 1 38.195-7.324c12.609-.8 26.665-.928 38.97 2.3 4.749 1.247.512 3.2-2.1 3.726-3.326.7-6.748 1.079-10.122 1.455a184.937 184.937 0 0 1-19.853 1.111 182.8 182.8 0 0 1-40.681-4.517c-5.005-1.127-7.148 6.58-2.119 7.716a193.738 193.738 0 0 0 38.97 4.8 185.26 185.26 0 0 0 19.877-.7c6.037-.544 12.9-.8 18.662-2.79 4.453-1.527 8.179-5.285 7.835-10.266s-5.021-7.54-9.411-8.451a149.455 149.455 0 0 0-37.8-2.479 168.408 168.408 0 0 0-42.545 7.736c-4.885 1.551-2.8 9.275 2.119 7.708zM495.794 76.087c-15.991-2.718-32.126-5.965-48.453-5.429a128.628 128.628 0 0 0-23.443 2.958c-4.989 1.1-14.888 2.119-14.848 9.043s9.427 7.644 14.392 8.563a185.936 185.936 0 0 0 71.449-.479c5.045-.991 2.91-8.7-2.119-7.708a180.539 180.539 0 0 1-57.776 1.983c-3.158-.408-6.3-.919-9.427-1.5q-2.231-.416-4.462-.887c-.983-.216-1.975-.432-2.958-.664-1.007-.624-1.311-.4-.919.68.08 1.839 2.4.28 3.71-.064 1.7-.456 3.406-.871 5.125-1.255 2.934-.648 5.9-1.2 8.883-1.6a117.878 117.878 0 0 1 19.189-1.087c13.313.3 26.449 2.95 39.546 5.173 5.037.855 7.2-6.852 2.127-7.708z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M403.271 86.865l.592 13.592c.224 5.125 8.212 5.157 8 0l-.072-1.791c-.208-5.125-8.2-5.157-8 0l.072 1.791h8l-.592-13.592c-.229-5.125-8.224-5.149-8 0z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M409.931 80.381a106.341 106.341 0 0 0-60.67-6.093c-5.125.976-11.434 2.095-13.552 7.588-5.381 13.968 19.989 12.169 26.945 12.3a266.332 266.332 0 0 0 44.263-3.014c5.069-.752 2.91-8.459-2.127-7.708a252.7 252.7 0 0 1-34.381 2.751q-8.515.1-17.022-.368a72.885 72.885 0 0 1-8.147-.592c-.216-.04-2.111-.248-2.151-.568-.072-.672 2.7-1.415 3.038-1.519 19.821-5.957 42.872-2.79 61.693 4.933 4.8 1.951 6.828-5.781 2.127-7.708zM316.959 237.821c12.185 14.7 32.078 11 48.669 8 14.752-2.711 35-8.315 34.908-26.929 0-5.141-8-5.149-8 0 .064 14.712-19.629 17.59-30.519 19.485-13.457 2.4-29.536 5.749-39.41-6.165-3.286-4-8.915 1.719-5.653 5.653z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M314.336 257.602l92.109-15.559-2.111-7.716-2.567.9c-4.8 1.679-2.758 9.4 2.127 7.708l2.567-.888c4.917-1.711 2.918-8.563-2.127-7.716l-92.125 15.567c-5.061.852-2.918 8.559 2.127 7.704z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M314.904 255.219l13.3-12.1c3.814-3.47-1.855-9.115-5.653-5.653l-13.3 12.1c-3.814 3.47 1.855 9.107 5.653 5.653zM394.478 233.655l10.442 6.4 3.075-7.307-1.023-.192a4.11 4.11 0 0 0-4.917 2.8c-.536 1.951.663 4.51 2.8 4.917l1.015.192c4.334.8 7.116-4.8 3.078-7.308l-10.442-6.4c-4.4-2.686-8.419 4.222-4 6.908z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M307.876 256.651l10.746 37.7 6.684-3.886-.8-1c-3.27-4-8.9 1.7-5.653 5.653l.8 1c2.615 3.2 7.716-.256 6.676-3.894l-10.746-37.7c-1.407-4.941-9.124-2.838-7.707 2.127zM352.107 259.561a11.905 11.905 0 0 0 .871 17.206c5.429 4.877 13.864 4.517 19.925 1.047s8.867-10.474 5.517-16.846c-3.2-6.133-10.77-9.035-17.382-7.052-4.917 1.479-2.823 9.195 2.127 7.716 6.036-1.815 12.6 5.3 5.7 9.283a10.377 10.377 0 0 1-8.675 1.015c-2.775-1.1-4.8-4.2-2.4-6.716 3.494-3.766-2.143-9.435-5.661-5.653zM244.376 182.515a39.977 39.977 0 0 1 16.727 4.733c4.533 2.4 8.579-4.47 4.038-6.908a49.571 49.571 0 0 0-20.788-5.821c-5.141-.3-5.125 7.692 0 8zM271.816 192.941c5.141 0 5.149-8 0-8s-5.157 8 0 8zM450.484 93.293l.416 10.13c.2 5.125 8.2 5.149 8 0l-.416-10.13c-.212-5.125-8.208-5.157-8 0zM447.078 78.438a159.312 159.312 0 0 1 49.908-5.493c1.535.072 14.056.176 14.256 1.807.616 5.037 8.619 5.1 8 0-.68-5.509-4.885-8-10.034-8.755a134.838 134.838 0 0 0-21.172-1.231 170.891 170.891 0 0 0-43.08 5.949c-4.965 1.343-2.854 9.059 2.127 7.716zM38.611 163.462a25.4 25.4 0 0 0-16.879 16.935c-1.511 4.933 6.2 7.044 7.708 2.127a17.046 17.046 0 0 1 11.3-11.346c4.891-1.519 2.804-9.243-2.129-7.716z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M37.107 145.996A55.432 55.432 0 0 0 .08 187.809c-.943 5.029 6.756 7.2 7.716 2.127a47.445 47.445 0 0 1 31.438-36.228c4.869-1.6 2.782-9.323-2.127-7.708zM646.734 59.385a25.45 25.45 0 0 0-20.125-12.929c-5.109-.512-5.077 7.492 0 8a17.182 17.182 0 0 1 13.24 8.971c2.5 4.493 9.411.472 6.9-4.038z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M661.662 58.721c-6.98-18.47-23.6-32.446-43.288-35.268-5.029-.72-7.2 6.98-2.127 7.708 16.791 2.4 31.654 13.672 37.707 29.687 1.808 4.798 9.539 2.695 7.708-2.127zM402.223 238.093l-.8 44.775c-.088 5.141 7.908 5.141 8 0l.8-44.775c.084-5.142-7.912-5.149-8 0z"
              />
              <path
                style={{ fill: '#dadbe0' }}
                d="M321.644 301.393l83.258-10.63c5.029-.648 5.093-8.651 0-8l-83.257 10.634c-5.029.648-5.093 8.651 0 8zM138.531 209.989a60.1 60.1 0 0 0 51.105-23.923c3.11-4.1-3.83-8.083-6.908-4-10.322 13.592-27.273 20.948-44.2 19.989-5.141-.3-5.125 7.692 0 8zM489.422 120.158a55.481 55.481 0 0 0 52.771-2.015c4.414-2.638.392-9.555-4.038-6.908a47.174 47.174 0 0 1-44.727 2.023c-4.605-2.255-8.659 4.637-4.038 6.9z"
              />
            </svg>
            <p style={{ fontSize: '0.75rem', textAlign: 'center' }}>No annotations found on this product.</p>
            <p style={{ fontSize: '0.75rem', textAlign: 'center' }}>Add one by clicking (+) in the bottom right corner.</p>
          </article>

        )}

        {props.annotations.length > 0 && (
          props.annotations.map(annotation => {
            let annotationTypeIcon = getAnnotationTypeIcon(annotation)

            return (
              <article key={annotation.id} className="annotation row">
                <div style={{ display: 'flex' }}>
                  <div style={{ display: 'flex', flex: 1 }} onClick={() => { props.editAnnotation(annotation.id) }}>
                    {annotationTypeIcon}

                    <span className="annotation__label label" style={{ flex: 1 }}>{annotation.name}</span>

                    <div className="measurement-row">
                      <span className="measurement-row__number">
                        {getAnnotationMeasureNumber(annotation)}
                      </span>
                    </div>

                    {annotation.color &&
                      <span className="color-circle" style={{ backgroundColor: `#${annotation.color}` }} />
                    }
                  </div>

                  <IconCheckbox checked={annotation.visible} onChange={() => { props.toggleAnnotationVisibility(annotation.id) }} />
                </div>
              </article>
            )
          })
        )}
      </div>
    </section>
  )
}

export default AnnotationsList