import React, { Component } from 'react'
import L from 'leaflet'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import AddFiles from '../AddFiles'
import GCPUpload from '../GCPUpload/'
import { Images } from '../../api'
import './styles.css'
import { API_ROOT } from '../../config'
import ProductParameters from '../ProductParameters'
import GCPmarkerIcon from '../GCPUpload/marker.png'
import { convertGcpsToMarkers } from '../../util'
import AddProductSidebar from '../AddProductSidebar'

const GCPmarkerImage = new L.Icon({
  iconUrl: GCPmarkerIcon,
  iconSize: [28, 28],
  iconAnchor: [14, 14],
})

class AddProduct extends Component {
  static propTypes = {
    project: PropTypes.object.isRequired,
    setFlightPlanDataSuccess: PropTypes.func.isRequired,
    setPreviewUploadedMarkers: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      images: [],
      expandedMap: false,
      activeWallet: this.props.user.wallet,
      showImagesView: false,
      imageData: null,
      totalImageCount: 0,
    }
  }

  componentDidMount() {
    if (this.props.productForm.editing) {
      this.fetchImages()
      this.fetchImageMarkers()
    }

    this.props.getLoggedInUser()
    this.props.getUserTeams()

    this.props.setProductForm({
      project_id: this.props.project.id,
      wallet_id: this.props.user.wallet.id,
    })
    this.setState({ activeWallet: this.props.user.wallet })

    if (this.props.productForm.gcps.length > 0) {
      convertGcpsToMarkers(
        this.props.productForm.gcps,
        GCPmarkerImage,
        'gcp-marker'
      ).then((markers) => {
        this.props.setPreviewMarkers(markers)
      })
    }
  }

  componentWillUnmount() {
    this.props.setPreviewUploadedMarkers(null)
    this.props.setPreviewMarkers(null)
    this.props.setProductForm(null)
  }

  /**
   * Save leaflet map element to do leaflet things outside of
   * what React Leaflet alone can do.
   */
  mapRef = (mapRef) => {
    this._mapEl = mapRef.target
    let markerBounds = []
    this._mapEl.on('layeradd', (event) => {
      if (event.layer instanceof L.Marker) {
        let latLngs = [event.layer.getLatLng()]
        markerBounds.push(L.latLngBounds(latLngs))
        this._mapEl.fitBounds(markerBounds, { maxZoom: 18 })
      }
    })
  }

  /**
   * Get images with pagination and extract corresponding response files thumbnail.
   */
  fetchImages = () => {
    this.props.setRequestInProgress(true, 'FETCHING_IMAGES')
    Images.getImages(this.props.productForm.id).then((response) => {
      let images = this.extractImageThumbnails(response.data)

      this.setState({ 
        images,
        imageData: response.data.files,
        totalImageCount: response.data.files.total
      }, () => {
        this.props.setRequestInProgress(false, 'FETCHING_IMAGES')
      })
    })
  }

  /**
   * Using pagination, fetch next batch of images and merge existing image state with new image data
   */
  fetchMoreImages = () => {
    if (this.state.imageData && this.state.imageData.current_page < this.state.imageData.last_page) {
      const query = `page=${this.state.imageData.current_page+1}`
      Images.getImages(this.props.productForm.id, query).then((response) => {
        let images = this.extractImageThumbnails(response.data)
        
        this.setState({ 
          images: [...this.state.images, ...images],
          imageData: response.data.files 
        })
      })
    }
  }

  /**
   * Fetch image geo markers of given image data.
   */
  fetchImageMarkers = () => {
    Images.getMarkers(this.props.productForm.id).then((response) => {
      let bucket_not_found = false
      let markers = response.data.files.map((img) => {
        let coordinates = img.point ? img.point.coordinates : null
        let name = img.filename
        let imagePath
        if (img.bucket_id) {
          if (response.data.buckets[img.bucket_id]) {
            if (
              img.bucket_id === 'spexi-assets-staging' ||
              img.bucket_id === 'spexi-assets-prod' ||
              img.bucket_id === 'spexi-assets-expiration'
            ) {
              let bucket = response.data.buckets['spexi-assets-thumbnails']
              imagePath = bucket.base_url + '/' + img.uri
            } else {
              let bucket = response.data.buckets[img.bucket_id]
              let imageUri = 
                img.uri.substring(0, img.uri.lastIndexOf('/')) +
                '/thumbnails/' +
                img.hashname
              let basePath = bucket.base_url.replace('/products', '')
              imagePath = `${basePath}/${imageUri}`
            }
          } else {
            bucket_not_found = true
          }
        } else {
          imagePath = API_ROOT + '/uploads/thumbnails' + img.hashname
        }
        return { coordinates, name, preview: imagePath }
      })

      if (bucket_not_found) {
        alert('File storage location not found in avilable bucket list.')
      } else {
        this.props.setPreviewUploadedMarkers(markers)
      }
    })
  }

  /**
   * Fetch image thumbnails of given image data.
   */
  extractImageThumbnails = (data) => {
    let ret = data.files.data.map((img) => {
      if (img.bucket_id) {
        if (data.buckets[img.bucket_id]) {
          if (
            img.bucket_id === 'spexi-assets-staging' ||
            img.bucket_id === 'spexi-assets-prod' ||
            img.bucket_id === 'spexi-assets-expiration'
          ) {
            let bucket = data.buckets['spexi-assets-thumbnails']
            let imageUri = bucket.base_url + '/' + img.uri
            img.thumbnailUrl = imageUri
          } else {
            let bucket = data.buckets[img.bucket_id]
            let imageUri =
              img.uri.substring(0, img.uri.lastIndexOf('/')) +
              '/thumbnails/' +
              img.hashname
            let basePath = bucket.base_url.replace('/products', '')
            img.thumbnailUrl = `${basePath}/${imageUri}`
          }
        }
      }
      return img
    })
    return ret
  }

  deleteImages(image) {
    let deleteConfirm = window.confirm(
      'Are you sure that you want to delete this image?'
    )
    if (deleteConfirm === false) {
      return
    }
    // TODO: we need to be able to *batch* delete (having the client manage this in multiple calls would be a bad idea i think, need an API endpoint)
    Images.deleteImage(image.id)
      .then(() => {
        let images = JSON.parse(JSON.stringify(this.state.images))
        for (let i = 0; i < images.length; ++i) {
          if (images[i].id && images[i].id === image.id) {
            images.splice(i, 1)
            let markers = JSON.parse(
              JSON.stringify(this.props.previewUploadedMarkers)
            )
            markers.splice(i, 1)
            this.props.setPreviewUploadedMarkers(markers)
            break
          }
        }
        this.setState({ images })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleChange = (event) => {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.props.setProductForm({ [name]: value })

    if (name === 'wallet_id') {
      let activeWallet
      if (this.props.user.wallet_id === Number(value)) {
        activeWallet = this.props.user.wallet
      } else if (this.props.teams.length > 0) {
        let teamWalletSearch = this.props.teams.find(
          (team) => team.wallet && team.wallet.id === Number(value)
        )
        if (teamWalletSearch) {
          activeWallet = teamWalletSearch.wallet
        }
      }
      if (activeWallet) {
        this.setState({ activeWallet })
      }
    }
  }

  onParametersChange = (parameters) => {
    this.props.setProductForm({
      parameters,
    })
  }

  handleMapRef = (mapRef) => {
    this._mapRef = mapRef
  }

  toggleMapSize = () => {
    let toggle = this.state.expandedMap ? false : true
    this.setState({ expandedMap: toggle })
    // wait for dom change to finish
    setTimeout(() => {
      this._mapEl.invalidateSize()
    }, 10)
  }

  handleToggleImagesView = () => {
    this.setState({ showImagesView: !this.state.showImagesView })
  }

  render() {
    return (
      <div id="image-upload-view">
        {((!this.state.showImagesView &&
          !this.props.isUploadingGCPs &&
          !this.props.isEditingProductParameters) ||
          !this.props.mobileScreen) && (
          <AddProductSidebar
            {...this.props}
            handleChange={this.handleChange}
            handleEdit={this.handleEdit}
            getDefaultProductName={this.getDefaultProductName}
            onViewportChanged={this.onViewportChanged}
            mapRef={this.mapRef}
            handleMapRef={this.handleMapRef}
            toggleMapSize={this.toggleMapSize}
            expandedMap={this.state.expandedMap}
            toggleImagesView={this.handleToggleImagesView}
          />
        )}

        <AddFiles
          {...this.props}
          images={this.state.images}
          fetchImages={this.fetchImages}
          fetchMoreImages={this.fetchMoreImages}
          totalImageCount={this.state.totalImageCount}
          deleteImage={(image) => this.deleteImage(image)}
          activeWallet={this.state.activeWallet}
          toggleImagesView={this.handleToggleImagesView}
          showImagesView={this.state.showImagesView || !this.props.mobileScreen}
        />

        <CSSTransition
          in={
            Object.keys(this.props.project).length !== 0 &&
            this.props.isUploadingGCPs
          }
          timeout={300}
          classNames="viewGCPUpload"
          unmountOnExit
        >
          <GCPUpload {...this.props} />
        </CSSTransition>

        <CSSTransition
          in={
            Object.keys(this.props.project).length !== 0 &&
            this.props.isEditingProductParameters
          }
          timeout={300}
          classNames="viewGCPUpload"
          unmountOnExit
        >
          <ProductParameters
            {...this.props}
            onParametersChange={this.onParametersChange}
          />
        </CSSTransition>
      </div>
    )
  }
}

export default AddProduct
