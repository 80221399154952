import React, { useState } from 'react'
import './style.css'

export default function FAQ(props) {
  const [visible, setVisible] = useState(false)

  const toggleVisibility = (event) => {
    let toggle = visible ? false : true
    setVisible(toggle)
  }

  return (
    <article className={'question' + (visible ? ' visible' : '')}>
      <header
        onClick={toggleVisibility}
        onKeyPress={toggleVisibility}
        role="button"
        tabIndex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M7 14l5-5 5 5z"></path>
          <path fill="none" d="M0 0h24v24H0z"></path>
        </svg>
        <h2>{props.question}</h2>
      </header>
      <div className="answer-content">{props.children}</div>
    </article>
  )
}
