import { Subscriptions } from '../api'
import { alertTypes } from '../util/constants'
import { setAlertModal } from './ui'

export function setSubscriptionPlans(subscriptionPlans) {
  return {
    type: 'SUBSCRIPTION_PLANS',
    subscriptionPlans,
  }
}

/**
 * Fetch subscription plans
 */
export function getSubscriptionPlans() {
  return (dispatch) => {
    Subscriptions.getAllPlans()
      .then((response) => {
        if (response?.success && response.data) {
          const plansObj = response.data.reduce((obj, item) => {
            obj[item.id] = item
            return obj
          }, {})

          dispatch(setSubscriptionPlans(plansObj))
        } else {
          // error handling
          dispatch(
            setAlertModal({
              message: response?.error || 'error loading subscriptions',
              type: alertTypes.error,
            })
          )
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(
          setAlertModal({
            message: 'Error loading subscriptions',
            type: alertTypes.error,
          })
        )
      })
  }
}
