import React from 'react'
import googleIcon from './btn_google_light_normal_ios.svg'
import { useGoogleLogin } from '@react-oauth/google'

const GoogleAuth = (props) => {

  const handleGoogleAuth = useGoogleLogin({
    onSuccess: async tokenResponse => {
      props.loginWithGoogle(tokenResponse.access_token)
    },
    onError: () => {
      props.loginWithGoogle(null)
    }
  })

  return (
    <button
      type='button'
      className='btn white google'
      onClick={handleGoogleAuth}
    >
      <span>
        <img src={googleIcon} alt='Google logo' />&nbsp;
        {props.title}
      </span>
    </button>
  )
}

export default GoogleAuth