import React, { useState, useEffect, useCallback } from 'react'
import MaterialTable from 'material-table'
import './style.css'
import { Wallet } from '../../api'
import UserCard from '../UserCard/'
import WalletStats from './WalletStats'
import NewTransactionModal from './NewTransactionModal'
import { TablePagination } from '@material-ui/core'
import { formatDateTime } from '../../helpers'
import { debounce } from 'lodash'

export default function WalletTransactionsList() {

  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowCount, setRowCount] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [data, setData] = useState([])
  const [selectedUsername, setSelectedUsername] = useState(null)

  // initialize table columns
  const columns = [
    {
      title: 'User', 
      field: 'user_name',
      render: (rowData) => rowData.user_name
    },
    {
      title: 'Type',
      field: 'type',
      render: (rowData) => rowData.type
    },
    {
      title: 'Amount',
      field: 'amount',
      render: (rowData) => `${rowData.amount} Credits`,
    },
    {
      title: 'Reference #', 
      field: 'hash',
      render: (rowData) => rowData.hash,
    },
    {
      title: 'Created',
      field: 'created_at',
      render: (rowData) => formatDateTime(rowData.created_at),
    },
    {
      title: 'Stripe Payment',
      field: 'stripe_payment_id',
      render: (rowData) => 
        rowData.meta && rowData.meta.stripe_payment_id ? (
          <a
            href={`https://dashboard.stripe.com/${process.env.REACT_APP_ENV === 'production' ? '' : 'test/'}payments/${
              rowData.meta.stripe_payment_id
            }`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Yes
          </a>
        ) : (
          <span>No</span>
        )
    }
  ]

  // Upon component mount, fetch all wallet transaction using pagination
  useEffect(() => {
    const getAllTransactions = async () => {
      setIsLoading(true)
      const query = !selectedUsername ? `paginate=${rowsPerPage}` : `paginate=${rowsPerPage}&userName=${selectedUsername}`
      const response = await Wallet.getAllTransactions(query)
      if (response?.success) {
        setRowCount(response.paginate.total)
        setData(response.data)
      }
      setIsLoading(false)
    }
    getAllTransactions()
  }, [rowsPerPage, selectedUsername])

  // Once user changes table page, fetch next page of data
  const changePage = async (page) => {
    setIsLoading(true)
    const query = !selectedUsername ? `page=${page+1}&paginate=${rowsPerPage}` : `page=${page+1}&paginate=${rowsPerPage}&userName=${selectedUsername}`
    const response = await Wallet.getAllTransactions(query)
    if (response && response.success) {
      setPage(page)
      setData(response.data)
    }
    setIsLoading(false)
  }

  const getAllTransactions = async (userName = null) => {
    setIsLoading(true)
    let query = !userName ? `paginate=${rowsPerPage}` : `paginate=${rowsPerPage}&userName=${userName}`
    const response = await Wallet.getAllTransactions(query)
    if (response?.success) {
      // there exists any data under searched username, setSelectedUsername to be used for pagination
      // if there is no entry under searched username, then selected usename is set to null
      if (!response.data.length) {
        setSelectedUsername(null)
      } else {
        setSelectedUsername(userName)
      }
      setRowCount(response.paginate.total)
      setData(response.data)
    }
    setIsLoading(false)
  }
  
  // Once user closes the newTransactionModal, if there is a successful new entry to be added, re-fetch data
  // if user closed the modal without any new entry to be added, just close the modal
  const closeNewTransactionModal = (newEntry = false) => {
    if (!newEntry) {
      setIsTransactionModalOpen(false)
    } else {
      setPage(0)
      getAllTransactions()
      setIsTransactionModalOpen(false)
    }
  }

  const handleNameSearch = (event) => {
    handleSearchChangeDebounce(event.target.value)
  }
  // search by username, implemented using debounce to prevent excessive api requests
  const handleSearchChangeDebounce = useCallback(debounce((name) => {
    handleNameLookup(name)
  }, 500, { leading: false, trailing: true}), [])

  // if username is given (not null), then fetch transaction by given name
  // is username is empty (null), then fetch all data
  const handleNameLookup = (name) => {
    setPage(0)
    if (name) {
      getAllTransactions(name)
    } else {
      getAllTransactions()
    }
  }

  return (
    <section id="credit-transactions">
      <WalletStats />

      <button
        className='btn-primary btn-primary-white'
        onClick={() => {
          setIsTransactionModalOpen(!isTransactionModalOpen)
        }}
        style={{
          marginLeft: 'auto',
          marginBottom: '1rem',
          display: 'block'
        }}
      >
        New Transaction
      </button>

      <MaterialTable
        title={
          <>
            <h6
              className='MuiTypography-root MuiTypography-h6'
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Wallet Transactions
            </h6>
            <div className='MuiFormControl-root MuiTextField-root MTableToolbar-searchField-63' style={{ marginBlock: '1.5rem'}}>
              <div className='MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd'>
                <input 
                  className='MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd' 
                  aria-invalid="false" 
                  aria-label="Search" 
                  placeholder="Search" 
                  type="text" 
                  onChange={handleNameSearch}
                />
              </div>
            </div>
          </>
        }
        isLoading={isLoading}
        columns={columns}
        data={data}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          exportButton: true,
          emptyRowsWhenPaging: false,
          search: false,
        }}
        detailPanel={(rowData) => {
          const stripePaymentPageLink = 
            rowData.meta && rowData.meta.stripe_payment_id ? (
              <a
                href={`https://dashboard.stripe.com/${process.env.REACT_APP_ENV === 'production' ? '' : 'test/'}payments/${
                  rowData.meta.stripe_payment_id
                }`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Yes
              </a>
            ) : (
              'No'
            )
          return (
            <section className='order-details'>
              <h3>Wallet Transaction</h3>
              <div style={{ display: 'flex' }}>
                <div style={{ width: '50%' }}>
                  <h4>Transaction Details</h4>
                  <ul>
                    <li>
                      <strong>Transaction Type:</strong> {rowData.type}
                    </li>
                    {rowData.meta && rowData.meta.reason && (
                      <li>
                        <strong>Reason:</strong> {rowData.meta.reason}
                      </li>
                    )}
                    <li>
                      <strong>Credit Amount:</strong> {rowData.amount}
                    </li>
                    <li>
                      <strong>Created:</strong> {formatDateTime(rowData.created_at)}
                    </li>
                    <li>
                      <strong>ID:</strong> {rowData.id}
                    </li>
                    <li>
                      <strong>Reference #:</strong> {rowData.hash}
                    </li>
                    <li>
                      <strong>Wallet ID:</strong> {rowData.wallet_id}
                    </li>
                    {rowData.meta && rowData.meta.product && rowData.meta.product.project_id && (
                      <li>
                        <strong>Project ID:</strong>{' '}
                        <a href={`project/${rowData.meta.product.project_id}/process`}>{rowData.meta.product.project_id}</a>
                      </li>
                    )}
                    {rowData.meta && rowData.meta.product && rowData.meta.product.id && (
                      <li>
                        <strong>Product ID:</strong> {rowData.meta.product.id}
                      </li>
                    )}
                    {rowData.meta && rowData.meta.product && rowData.meta.product.type && (
                      <li>
                        <strong>Product Type:</strong> {rowData.meta.product.type}
                      </li>
                    )}
                    <li>
                      <strong>Stripe Payment:</strong> {stripePaymentPageLink}
                    </li>
                  </ul>
                </div>

                <div style={{ width: '50%' }}>
                  <h4>Transaction user</h4>
                  {rowData.user_id && <UserCard userId={rowData.user_id} isAdmin={true} />}
                </div>

              </div>
            </section>
          )
        }}
      />
      {!isLoading && rowCount && rowsPerPage ? (
        <div style={{marginBottom: '3rem'}}>
          <TablePagination
            count={rowCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(event, page) => {
              changePage(page)
            }}
            onChangeRowsPerPage={(event) => {
              setRowsPerPage(event.target.value)
              setPage(0)
            }}
            rowsPerPageOptions={[25,50,100, 200]}
            style={{ float:'right', borderBottom: 'none'}}
          ></TablePagination>
        </div>
      ) : (
        <>{' '}</>
      )}

      {isTransactionModalOpen && (
        <NewTransactionModal
          closeModal={closeNewTransactionModal}
        />
      )}
      
    </section>
  )
}
