import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import * as authReducers from './auth'
import * as requestReducers from './request'
import * as uiReducers from './ui'
import * as projectsReducers from './projects'
import * as flightPlansReducers from './flightPlans'
import * as productsReducers from './products'
import * as mapReducers from './map'
import * as annotationsReducers from './annotations'
import * as productMapLayersReducers from './productMapLayers'
import * as walletReducers from './wallet'
import * as subscriptionsReducers from './subscriptions'

export const createRootReducer = (history) =>
  combineReducers({
    ...authReducers,
    ...requestReducers,
    ...uiReducers,
    ...projectsReducers,
    ...flightPlansReducers,
    ...mapReducers,
    ...productsReducers,
    ...annotationsReducers,
    ...productMapLayersReducers,
    ...walletReducers,
    ...subscriptionsReducers,
    router: connectRouter(history),
  })
