import { GIS, Annotations } from '../api'
import { setRequestInProgress } from './request'
import { setAlertModal } from './ui'
import { history } from '../store'
import { setProfileCoordinate } from './map'
import { debounce } from 'lodash'
import { alertTypes } from '../util/constants'
import { featureGroup } from 'leaflet'
const L = window.L

export function setAnnotationCreationForm(data) {
  return {
    type: 'SET_ANNOTATION_CREATION_FORM',
    data,
  }
}

export function setDrawingAnnotationLayer(data) {
  return {
    type: 'SET_DRAWING_ANNOTATION_LAYER',
    data,
  }
}

export function setDragAnnotationMarker(data) {
  return {
    type: 'SET_DRAG_ANNOTATION_MARKER',
    data,
  }
}

/**
 * Draw annotation
 */
export function drawAnnotation(type, shape = false) {
  return (dispatch, getState) => {
    const { project, product, annotationCreationForm, leafletMapElement } =
      getState()

    let drawingObject
    let dragMarker
    // start drawing with mouse if we aren't given a shape
    if (!shape) {
      let drawControl = new L.Control.Draw()
      if (type === 'map-volume') {
        drawingObject = new L.Draw.Polygon(
          leafletMapElement,
          drawControl.options.polygon
        )
      } else if (type === 'map-area') {
        drawingObject = new L.Draw.Polygon(
          leafletMapElement,
          drawControl.options.polygon
        )
      } else if (type === 'map-distance') {
        drawingObject = new L.Draw.Polyline(leafletMapElement, {})
      } else if (type === 'map-location') {
        drawingObject = new L.Draw.Marker(leafletMapElement, {
          draggable: true,
        })
      }

      drawingObject.enable()

      leafletMapElement.on('draw:created', (e) => {
        // this is a marker, so you can't enable editing of the shape
        if (type !== 'map-location') {
          e.layer.editing.enable()

          let dragMarkerCoordinates = e.layer.getBounds().getCenter()

          dragMarker = L.circleMarker(
            [dragMarkerCoordinates.lat, dragMarkerCoordinates.lng],
            {
              radius: 10,
              color: 'transparent',
              fillColor: 'black',
              fillOpacity: 1,
              draggable: true,
              weight: 0,
              className: 'move-marker',
              fillRule: 'nonzero',
              zIndexOffset: 1000,
            }
          )
          dragMarker.addTo(leafletMapElement)
          dragMarker.on('drag', (dragEvent) => {
            e.layer.editing.disable()
            if (e.layer._transform) {
              e.layer._transform(dragEvent.target.dragging._matrix)
            }
          })
          dragMarker.on('dragend', (dragEvent) => {
            let matrix = dragEvent.target.dragging._matrix
            if (matrix) {
              e.layer.dragging._transformPoints(matrix)
              e.layer._updatePath()
              e.layer._project()
              e.layer._transform(null)
            }
            e.layer.editing.enable()
            let form = getState().annotationCreationForm
            dispatch(
              calculateMeasurement(e.layer, form.type, form.options, product.id)
            )
          })
          dispatch(setDragAnnotationMarker(dragMarker))
        }
        dispatch(setDrawingAnnotationLayer(e.layer))
        let form = getState().annotationCreationForm
        dispatch(
          calculateMeasurement(e.layer, form.type, form.options, product.id)
        )

        history.push(
          `/project/${project.id}/review/${product.id}/add-annotation`
        )

        e.layer.on('edit', () => {
          dispatch(setProfileCoordinate(null))
          let form = getState().annotationCreationForm
          dispatch(
            calculateMeasurement(e.layer, form.type, form.options, product.id)
          )
          let dragMarkerCoordinates = e.layer.getBounds().getCenter()
          dragMarker.setLatLng(dragMarkerCoordinates)
        })
      })
    } else {
      if (type === 'map-volume' || type === 'map-area') {
        drawingObject = new L.polygon(
          L.GeoJSON.coordsToLatLngs(shape.geometry.coordinates[0]),
          {
            transform: true,
            draggable: true,
            color: '#FFFFFF',
          }
        ).addTo(leafletMapElement)
      } else if (type === 'map-distance') {
        drawingObject = new L.polyline(
          L.GeoJSON.coordsToLatLngs(shape.geometry.coordinates),
          {
            transform: true,
            draggable: true,
            color: '#FFFFFF',
          }
        ).addTo(leafletMapElement)
      } else if (type === 'map-location') {
        const markerHtmlStyles = `
                    background-color: ${`#${annotationCreationForm.color}`};
                    width: 1rem;
                    height: 1rem;
                    display: block;
                    left: -.5rem;
                    top: -.5rem;
                    position: relative;
                    border-radius: 1rem 1rem 0;
                    transform: rotate(45deg);`

        const icon = L.divIcon({
          className: 'annotation-marker-icon',
          iconAnchor: [0, 8],
          labelAnchor: [-6, 0],
          popupAnchor: [0, -36],
          html: `<span style="${markerHtmlStyles}" />`,
        })

        drawingObject = L.marker(
          [shape.geometry.coordinates[1], shape.geometry.coordinates[0]],
          { icon: icon }
        )
        drawingObject.addTo(leafletMapElement)
      }

      drawingObject.options.editing || (drawingObject.options.editing = {})
      drawingObject.editing.enable()

      if (type !== 'map-location') {
        let dragMarkerCoordinates = drawingObject.getBounds().getCenter()
        dragMarker = L.circleMarker(
          [dragMarkerCoordinates.lat, dragMarkerCoordinates.lng],
          {
            radius: 10,
            color: 'transparent',
            fillColor: 'black',
            fillOpacity: 1,
            draggable: true,
            weight: 0,
            className: 'move-marker',
            fillRule: 'nonzero',
            zIndexOffset: 1000,
          }
        )

        dragMarker.addTo(leafletMapElement)

        dragMarker.on('drag', (dragEvent) => {
          drawingObject.editing.disable()

          if (drawingObject._transform) {
            drawingObject._transform(dragEvent.target.dragging._matrix)
          }
        })

        dragMarker.on('dragend', (dragEvent) => {
          let matrix = dragEvent.target.dragging._matrix
          if (matrix) {
            drawingObject.dragging._transformPoints(matrix)
            drawingObject._updatePath()
            drawingObject._project()
            drawingObject._transform(null)
          }
          drawingObject.editing.enable()
          let form = getState().annotationCreationForm
          dispatch(
            calculateMeasurement(
              drawingObject,
              form.type,
              form.options,
              product.id
            )
          )
        })
        dispatch(setDragAnnotationMarker(dragMarker))
      }

      dispatch(setDrawingAnnotationLayer(drawingObject))
      let form = getState().annotationCreationForm
      dispatch(
        calculateMeasurement(drawingObject, form.type, form.options, product.id)
      )

      history.push(`/project/${project.id}/review/${product.id}/add-annotation`)

      // Set up the event handlers.
      if (type !== 'map-location') {
        drawingObject.on('edit', () => {
          dispatch(setProfileCoordinate(null))
          let form = getState().annotationCreationForm
          dispatch(
            calculateMeasurement(
              drawingObject,
              form.type,
              form.options,
              product.id
            )
          )
        })
      } else {
        drawingObject.on('dragend', () => {
          dispatch(setProfileCoordinate(null))
          let form = getState().annotationCreationForm
          dispatch(
            calculateMeasurement(
              drawingObject,
              form.type,
              form.options,
              product.id
            )
          )
        })
      }
    }
  }
}

export function setGISData(data) {
  return {
    type: 'SET_GIS_DATA',
    data,
  }
}

export function setGISProfileGraphData(data) {
  return {
    type: 'SET_GIS_PROFILE_GRAPH_DATA',
    data,
  }
}

export function calculateMeasurement(
  layer,
  type,
  options,
  product_id,
  keep_profile = false
) {
  return (dispatch, getState) => {
    const { leafletMapElement, annotationCreationForm } = getState()
    let data = {
      type,
      geojson: layer.toGeoJSON(8).geometry,
      options,
    }

    dispatch(setAnnotationCreationForm(data))

    if (type !== 'map-location') {
      layer.setStyle({
        color: `#${annotationCreationForm.color}`,
      })
    }

    if (type === 'map-location') {
      const markerHtmlStyles = `
        background-color: ${`#${annotationCreationForm.color}`};
        width: 1rem;
        height: 1rem;
        display: block;
        left: -.5rem;
        top: -.5rem;
        position: relative;
        border-radius: 1rem 1rem 0;
        transform: rotate(45deg);
      `

      const icon = L.divIcon({
        className: 'annotation-marker-icon',
        iconAnchor: [0, 8],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`,
      })

      layer.setIcon(icon)
      layer.setOpacity(1)
    }

    layer.options.editing || (layer.options.editing = {})
    layer.options.editing.style || (layer.options.editing.style = {})
    leafletMapElement.addLayer(layer)

    dispatch(fetchMeasurementAndProfile(product_id, data, type, keep_profile))
  }
}

/**
 * Fetch measurement and profile graph data from API
 */
export const fetchMeasurementAndProfileDebounced = debounce(
  (dispatch, product_id, data, type, keep_profile) => {
    // post to GIS service for elevation measurements
    dispatch(setRequestInProgress(true, 'MEASUREMENTS'))

    GIS.measure(product_id, data)
      .then((result) => {
        // console.log('GIS Service result', result)
        if (result.success) {
          // console.log('setting state', result.data)
          dispatch(setGISData(result.data))
          dispatch(setAnnotationCreationForm({ data: result.data }))
          dispatch(setRequestInProgress(false, 'MEASUREMENTS'))
        } else {
          dispatch(
            setAlertModal({ message: result.error, type: alertTypes.error })
          )
        }
      })
      .catch(console.error)

    if (type !== 'map-location' && keep_profile === false) {
      // same endpoint, but for profile graph
      let profileData = { ...data }
      profileData.type = 'map-profile'
      dispatch(setRequestInProgress(true, 'PROFILE_GRAPH'))
      GIS.measure(product_id, profileData)
        .then((result) => {
          if (result.success) {
            dispatch(setGISProfileGraphData(result.data))
            dispatch(setRequestInProgress(false, 'PROFILE_GRAPH'))
          } else {
            dispatch(
              setAlertModal({ message: result.error, type: alertTypes.error })
            )
          }
        })
        .catch(console.error)
    }
  },
  1000,
  {
    leading: true,
    trailing: true,
  }
)

/**
 * Debounced inner function outside of the wrapping function
 * Needed to do this because of async action thunk
 * See:  https://gist.github.com/krstffr/245fe83885b597aabaf06348220c2fe9
 */
export const fetchMeasurementAndProfile =
  (product_id, data, type, keep_profile) => (dispatch) =>
    fetchMeasurementAndProfileDebounced(
      dispatch,
      product_id,
      data,
      type,
      keep_profile
    )

// annotation data
export function annotationsDataSuccess(annotations) {
  console.log('getting annotations from server')
  return {
    type: 'ANNOTATIONS_DATA_SUCCESS',
    annotations,
  }
}

/**
 * Fetch annotations list from API
 */
export function fetchAnnotationsData(id) {
  return (dispatch, getState) => {
    dispatch(setRequestInProgress(true, 'GLOBAL'))

    Annotations.getAll(id).then((response) => {
      console.log(response)

      dispatch(setRequestInProgress(false, 'GLOBAL'))
      dispatch(annotationsDataSuccess(response.data))

      const { leafletMapElement } = getState()

      let group = new featureGroup()
      let dataArray = []
      if (leafletMapElement) {
        leafletMapElement.eachLayer(function (layer) {
          if (
            layer.feature !== undefined ||
            layer.options.className === 'annotation' ||
            layer.options.className === 'annotation-marker'
          ) {
            dataArray.push(layer)
          }
        })

        if (dataArray.length > 0) {
          dataArray.forEach((data) => {
            group.addLayer(data)
          })
          if (group.getBounds()) {
            leafletMapElement.fitBounds(group.getBounds(), {
              padding: [20, 20],
            })
          }
        }
      }
    })
  }
}

/**
 * Save annotation to API
 */
export function saveAnnotationData(data) {
  return (dispatch, getState) => {
    const { project, product } = getState()

    dispatch(setRequestInProgress(true, 'ADD_ANNOTATION'))

    let postData = { ...data }
    // remove '#' from hex color string because Colin is strict
    postData.color =
      postData.color[0] === '#' ? postData.color.substring(1) : postData.color
    postData.product_id = product.id

    console.log('saving annoation', postData)

    Annotations.create(postData).then(() => {
      dispatch(setAnnotationCreationForm(null))
      dispatch(fetchAnnotationsData(product.id))
      dispatch(setRequestInProgress(false, 'ADD_ANNOTATION'))
      dispatch(clearAnnotation())
      history.push(`/project/${project.id}/review/${product.id}`)
    })
  }
}

/**
 * Update annotation to API
 */
export function updateAnnotationData(data) {
  return (dispatch, getState) => {
    const { project, product } = getState()

    dispatch(setRequestInProgress(true, 'ADD_ANNOTATION'))

    let postData = { ...data }
    // remove '#' from hex color string because Colin despises redundancy
    postData.color =
      postData.color[0] === '#' ? postData.color.substring(1) : postData.color
    postData.product_id = product.id

    Annotations.update(data.id, postData)
      .then((response) => {
        if (response.success) {
          dispatch(setAnnotationCreationForm(null))
          dispatch(fetchAnnotationsData(product.id))
          dispatch(setRequestInProgress(false, 'ADD_ANNOTATION'))

          dispatch(clearAnnotation())

          history.push(`/project/${project.id}/review/${product.id}`)
        } else {
          dispatch(
            setAlertModal({ message: response.error, type: alertTypes.error })
          )
        }
      })
      .catch((response) => {
        dispatch(
          setAlertModal({ message: response.error, type: alertTypes.error })
        )
      })
  }
}

// export function setIsEditingAnnotation(bool) {
//   return {
//     type: 'SET_IS_EDITING_ANNOTATION',
//     isEditingAnnotation: bool
//   }
// }

/**
 * Edit annotation
 */
export function editAnnotation(annotation_id) {
  return (dispatch, getState) => {
    const {
      project,
      product,
      annotations,
      leafletMapElement,
      annotationCreationForm,
    } = getState()

    if (annotationCreationForm.id === annotation_id) {
      // Already editing this annotation, no-op.
      return
    }

    // clean up old editing in cases where people jump between shapes on the map
    dispatch(clearAnnotation())

    // find the match annotation data to the layer that was clicked
    let annotation = annotations.filter(
      (annotation) => annotation.id === annotation_id
    )[0]
    dispatch(setAnnotationCreationForm(annotation))

    // Find annotation layer in leaflet map
    let currentLayer
    leafletMapElement.eachLayer((layer) => {
      if (layer.options['data-id'] === annotation_id) {
        // hide tooltips when editing
        if (layer.getTooltip) {
          var toolTip = layer.getTooltip()
          if (toolTip) {
            toolTip.setOpacity(0)
          }
        }

        currentLayer = layer
        // hide layer so we can keep React managing it's state,
        // and at the same time draw new editable shapes.
        // Unfortunately, CSS was the best way to do this without removing the layer.
        if (annotation.type !== 'map-location') {
          currentLayer.setStyle({
            opacity: 0,
            color: 'transparent',
          })
        }

        if (annotation.type === 'map-location') {
          // const markerHtmlStyles = 'background-color: transparent;'
          // const icon = L.divIcon({
          //   className: 'annotation-marker-icon',
          //   iconAnchor: [0, 8],
          //   labelAnchor: [-6, 0],
          //   popupAnchor: [0, -36],
          //   html: `<span style="${markerHtmlStyles}" />`
          // })

          // currentLayer.setIcon(icon)
          currentLayer.setOpacity(0)
        }
        dispatch(drawAnnotation(annotation.type, currentLayer.toGeoJSON(8)))
      } else {
        // if someone tries to click another annotation, unhide them all
        if (layer.options['data-id']) {
          let annotation = annotations.filter(
            (annotation) => annotation.id === layer.options['data-id']
          )[0]
          let color = annotation.color

          if (annotation.type !== 'map-location') {
            layer.setStyle({
              opacity: 1,
              color: `#${color}`,
            })
          }
          if (annotation.type === 'map-location') {
            const markerHtmlStyles = `
            background-color: ${`#${color}`};
            width: 1rem;
            height: 1rem;
            display: block;
            left: -.5rem;
            top: -.5rem;
            position: relative;
            border-radius: 1rem 1rem 0;
            transform: rotate(45deg);`

            const icon = L.divIcon({
              className: 'annotation-marker-icon',
              iconAnchor: [0, 8],
              labelAnchor: [-6, 0],
              popupAnchor: [0, -36],
              html: `<span style="${markerHtmlStyles}" />`,
            })

            layer.setIcon(icon)
            layer.setOpacity(1)
          }
        }
      }
    })

    history.push(
      `/project/${project.id}/review/${product.id}/${annotation.id}/edit`
    )
  }
}

/**
 * Clear old annotation out
 */
export function clearAnnotation() {
  return (dispatch, getState) => {
    const { leafletMapElement, drawingAnnotationLayer, annotations } =
      getState()

    if (Object.keys(drawingAnnotationLayer).length !== 0) {
      drawingAnnotationLayer.off('edit')
      drawingAnnotationLayer.off('dragend')
      drawingAnnotationLayer.options.original ||
        (drawingAnnotationLayer.options.original = {})
      drawingAnnotationLayer.options.original.style ||
        (drawingAnnotationLayer.options.original.style = {})
      drawingAnnotationLayer.remove()
    }

    leafletMapElement.off('draw:created')
    dispatch(setAnnotationCreationForm(null))
    dispatch(setDragAnnotationMarker(null))
    dispatch(setDrawingAnnotationLayer(null))
    dispatch(setGISData({}))

    leafletMapElement.eachLayer((layer) => {
      // hide tooltips when editing
      if (layer.getTooltip) {
        var toolTip = layer.getTooltip()
        if (toolTip) {
          toolTip.setOpacity(1)
        }
      }

      if (layer.options['data-id']) {
        let annotation = annotations.filter(
          (annotation) => annotation.id === layer.options['data-id']
        )[0]
        let color = annotation.color
        console.log('show layer after editing', layer)
        if (annotation.type !== 'map-location') {
          layer.setStyle({
            opacity: 1,
            color: `#${color}`,
          })
        }

        if (annotation.type === 'map-location') {
          const markerHtmlStyles = `
          background-color: ${`#${color}`};
          width: 1rem;
          height: 1rem;
          display: block;
          left: -.5rem;
          top: -.5rem;
          position: relative;
          border-radius: 1rem 1rem 0;
          transform: rotate(45deg);`

          const icon = L.divIcon({
            className: 'annotation-marker-icon',
            iconAnchor: [0, 8],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<span style="${markerHtmlStyles}" />`,
          })

          layer.setIcon(icon)
          layer.setOpacity(1)
        }
      }
    })
  }
}

/**
 * Delete annotation from the server
 */
export function deleteAnnotation(id) {
  return (dispatch, getState) => {
    const { project, product } = getState()
    dispatch(setRequestInProgress(true, 'DELETE_ANNOTATION'))
    Annotations.delete(id).then(() => {
      dispatch(setAnnotationCreationForm(null))
      dispatch(fetchAnnotationsData(product.id))
      dispatch(clearAnnotation())
      dispatch(setRequestInProgress(false, 'DELETE_ANNOTATION'))
      history.push(`/project/${project.id}/review/${product.id}`)
    })
  }
}

export function toggleAnnotationVisibility(id) {
  return {
    type: 'TOGGLE_ANNOTATION_VISIBILITY',
    id,
  }
}

export function setIsPrintingAnnotationReport(bool) {
  return {
    type: 'SET_IS_PRINTING_ANNOTATION_REPORT',
    bool,
  }
}
