import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SidebarHeader from '../SidebarHeader'
import './styles.css'
import { DEFAULT_CRS } from '../../util'
import CoordinateSystem from '../CoordinateSystem'
import { Annotations } from '../../api'

// const L = window.L

const RASTER_FORMATS = [
  // {
  //   value: 'NONE',
  //   name: 'None (Raw)'  // TODO: have conditional naming!
  // },
  {
    value: 'XYZ',
    name: 'Google Maps Tiles'
  },
  {
    value: 'TILES',
    name: 'Tiles'
  },
  {
    value: 'KMZ',
    name: 'Keyhole Markup Language (KMZ)'
  },
  {
    value: 'MB_TILES',
    name: 'Mapbox Tiles'
  },
  {
    value: 'WW',
    name: 'NASA WorldWind'
  }
]

const ANNOTATION_FORMATS = [
  {
    value: 'SHP',
    name: 'ESRI Shapefile',
    extension: 'shp'
  },
  {
    value: 'KML',
    name: 'Keyhole Markup Language',
    extension: 'kml'
  },
  {
    value: 'GEOJSON',
    name: 'GeoJSON',
    extension: 'json'
  }/*,
  {
    value: 'WKT',
    name: 'Well-Known Text',
    extension: 'wkt'
  },
  {
    value: 'GPX',
    name: 'GPS Exchange Format',
    extension: 'xml'
  }*/
]

// function getPointCloudFormatName(options, value) {

// }

function getRasterFormatName(options, value) {
  if (options.data_source === 'ELEVATION') {
    if (value === 'TILES') {
      return 'GeoTIFF'
    }
  }
  let format = RASTER_FORMATS.find(x => value === x.value)
  if (format) {
    return format.name
  } else {
    return value
  }
}

const TYPES = [
  {
    value: 'RASTER',
    name: 'Raster Data',
  },
  {
    value: 'POINT_CLOUD',
    name: 'Point Cloud'
  },
  {
    value: 'MODEL',
    name: 'Model'
  },
  {
    value: 'ANNOTATIONS',
    name: 'Annotations',
  },
  {
    value: 'CONTOURS',
    name: 'Contours',
  }
]

const RASTER_DATA_SOURCES = [
  {
    value: 'ELEVATION',
    name: 'Elevation',
  },
  {
    value: 'ORTHOMOSAIC',
    name: 'Orthomosaic'
  }
]

const POINT_CLOUD_DATA_SOURCES = [
  {
    value: 'TIE_POINTS',
    name: 'Sparse Point Cloud',
  },
  {
    value: 'POINT_CLOUD',
    name: 'Dense Point Cloud'
  }
]

const MODEL_FORMATS = [
  {
    value: 'OBJ',
    name: 'Wavefront OBJ',
    extension: 'obj',
  },
  {
    value: '3DS',
    name: 'Autodesk 3DS',
    extension: '3ds'
  },
  // {
  //   value: 'VRML',
  //   name: 'Virtual Reality Modeling Language',
  //   extension: 'wrz'
  // },
  // {
  //   value: 'PLY',
  //   name: 'Stanford Triangle Format',
  //   extension: 'ply'
  // },
  {
    value: 'COLLADA',
    name: 'COLLADA Digital Asset Exchange',
    extension: 'dae'
  },
  // {
  //   value: 'U3D',
  //   name: 'Unity 3D',
  //   extension: 'u3d'
  // },
  // {
  //   value: 'PDF',
  //   name: 'Portable Document Format',
  //   extension: 'pdf'
  // },
  // {
  //   value: 'DXF',
  //   name: 'AutoCAD Drawing Exchange Format',
  //   extension: 'dxf'
  // },
  {
    value: 'FBX',
    name: 'AutoCAD Filmbox',
    extension: 'fbx'
  },
  // {
  //   value: 'KMZ',
  //   name: 'Keyhole Markup Language',
  //   extension: 'kmz'
  // }
]

const RASTER_TRANSFORMS = [
  {
    value: 'NONE',
    name: 'None (Raw Values)'
  },
  {
    value: 'VALUE',
    name: 'Value'
  },
  {
    value: 'PALETTE',
    name: 'Palette (False Color)'
  }
]

const CONTOURS_FORMATS = [
  {
    value: 'GEOJSON',
    name: 'GeoJSON',
    extension: 'geojson'
  },
  {
    value: 'SHAPEFILE',
    name: 'Shapefile',
    extension: 'shp'
  },
  {
    value: 'KML',
    name: 'Keyhole Markup Language',
    extension: 'kml'
  }
]


const IMAGE_FORMATS = [
  {
    value: 'JPEG',
    name: 'JPEG Interchange Format',
    extension: 'jpg'
  },
  {
    value: 'TIFF',
    name: 'Tagged Image File Format',
    extension: 'tiff'
  },
  {
    value: 'BIGTIFF',
    name: 'Big Tiff',
    extension: 'tiff'
  },
  {
    value: 'PDF',
    name: 'Georeferenced PDF',
    extension: 'pdf'
  },
  {
    value: 'PNG',
    name: 'Portable Network Graphic',
    extension: 'png'
  },
  {
    value: 'TGA',
    name: 'Truevision Targa',
    extension: 'tga'
  },
  {
    value: 'JP2',
    name: 'JPEG 2000',
    extension: 'jp2'
  }
]

const POINT_CLOUD_FORMATS = [
  {
    value: 'DXF',
    name: 'AutoCAD Drawing Exchange Format',
    extension: 'dxf'
  },
  {
    value: 'PLY',
    name: 'Stanford Triangle Format',
    extension: 'ply'
  },
  // Disabling support for now. (See IPA-140)
  // {
  //   value: 'OBJ',
  //   name: 'Wavefront OBJ',
  //   extension: 'obj'
  // },
  {
    value: 'LAS',
    name: 'ASPRS Laser Format',
    extension: 'las'
  },
  {
    value: 'LAZ',
    name: 'ASPRS Laser Format (Compressed)',
    extension: 'laz'
  },
  {
    value: 'PTS',
    name: '3D Points File',
    extension: 'pts'
  },
  {
    value: 'XYZ',
    name: 'Chemical File Format',
    extension: 'xyz'
  }
]

const MAP_PROJECTIONS = [
  { value: 'EPSG:4326', name: 'WGS84 (Web Mercator)' },
  { value: 'EPSG:26901', name: 'NAD83 / UTM Zone 1N' },
  { value: 'EPSG:26902', name: 'NAD83 / UTM Zone 2N' },
  { value: 'EPSG:26903', name: 'NAD83 / UTM Zone 3N' },
  { value: 'EPSG:26904', name: 'NAD83 / UTM Zone 4N' },
  { value: 'EPSG:26905', name: 'NAD83 / UTM Zone 5N' },
  { value: 'EPSG:26906', name: 'NAD83 / UTM Zone 6N' },
  { value: 'EPSG:26907', name: 'NAD83 / UTM Zone 7N' },
  { value: 'EPSG:26908', name: 'NAD83 / UTM Zone 8N' },
  { value: 'EPSG:26909', name: 'NAD83 / UTM Zone 9N' },
  { value: 'EPSG:26910', name: 'NAD83 / UTM Zone 10N' },
  { value: 'EPSG:26911', name: 'NAD83 / UTM Zone 11N' },
  { value: 'EPSG:26912', name: 'NAD83 / UTM Zone 12N' },
  { value: 'EPSG:26913', name: 'NAD83 / UTM Zone 13N' },
  { value: 'EPSG:26914', name: 'NAD83 / UTM Zone 14N' },
  { value: 'EPSG:26915', name: 'NAD83 / UTM Zone 15N' },
  { value: 'EPSG:26916', name: 'NAD83 / UTM Zone 16N' },
  { value: 'EPSG:26917', name: 'NAD83 / UTM Zone 17N' },
  { value: 'EPSG:26918', name: 'NAD83 / UTM Zone 18N' },
  { value: 'EPSG:26919', name: 'NAD83 / UTM Zone 19N' },
  { value: 'EPSG:26920', name: 'NAD83 / UTM Zone 20N' },
  { value: 'EPSG:26921', name: 'NAD83 / UTM Zone 21N' },
  { value: 'EPSG:26922', name: 'NAD83 / UTM Zone 22N' },
  { value: 'EPSG:26923', name: 'NAD83 / UTM Zone 23N' }
]

class ExportData extends Component {

  static propTypes = {
    project: PropTypes.object,
    products: PropTypes.array,
    match: PropTypes.object.isRequired,
    getProjectAndProduct: PropTypes.func.isRequired,
  }


  constructor(props) {
    super(props)
    this.state = {
      form: {
        email: '',
        type: 'RASTER',
        options: this.getDefaultFormOptionsForType('RASTER')
      },
      crs: DEFAULT_CRS,
      loading: false,
    }
  }

  getDefaultFormOptionsForType(type) {
    if (type === 'RASTER') {
      return {
        raster_transform: 'NONE',
        format: 'TILES',
        data_source: 'ORTHOMOSAIC',
        image_format: 'JPEG',
        write_tiles: true,
        write_alpha: false,
        projection: DEFAULT_CRS.id,
      }
    } else if (type === 'ANNOTATIONS') {
      return {
        format: 'SHP',
        projection: 'EPSG:4326'
      }
    } else if (type === 'POINT_CLOUD') {
      return {
        format: 'LAS',
        data_source: 'POINT_CLOUD'
      }
    } else if (type === 'MODEL') {
      return {
        format: 'OBJ',
        data_source: 'MODEL',
        texture_format: 'JPEG'
      }
    } else if (type === 'CONTOURS') {
      return {
        format: 'SHAPEFILE',
        interval: 1
      }
    }
  }


  componentDidMount() {
    if (Object.keys(this.props.project).length === 0 ||
      Object.keys(this.props.products).length === 0) {
      this.props.getProjectAndProduct(this.props.match.params.id, this.props.match.params.pid)
    }
    // TODO: have this based on the actual type!!
    let form = { ...this.state.form }
    form.options = this.getDefaultOptions('map')
    this.setState({ form })
  }

  componentWillUnmount() {
  }

  getDefaultOptions(productType) {
    if (productType === 'map' || productType === 'map+3d') {
      if (this.isExportingAnnotations()) {
        return {
          format: 'KML',
          projection: 'EPSG:4326'
        }
      } else {
        return {
          format: 'TILES',
          data_source: 'ORTHOMOSAIC',
          image_format: 'PNG',
          write_tiles: true,
          write_alpha: true
        }
      }
    }
  }

  getValidatedOptions(type, options) {
    // Evaluate the current state and if any options are actually amiss.
    // This function will return a valid state based on the options passed in.
    let newOptions = { ...options }
    if (type === 'ANNOTATIONS') {
      let annotationFormats = ANNOTATION_FORMATS
      if (annotationFormats.map(x => x.value).includes(newOptions.format) === false) {
        if (annotationFormats.length > 0) {
          newOptions.format = annotationFormats[0].value
        } else {
          newOptions.format = null
        }
      }
    } else if (type === 'POINT_CLOUD') {
      // pass
    } else if (type === 'RASTER') {
      if (['ORTHOMOSAIC', 'ELEVATION'].includes(options.data_source)) {
        // Raster Formats
        let rasterFormats = this.getRasterFormats(newOptions)
        if (rasterFormats.map(x => x.value).includes(newOptions.format) === false) {
          if (rasterFormats.length > 0) {
            newOptions.format = rasterFormats[0].value
          } else {
            newOptions.format = null
          }
        }

        // Image Formats
        let imageFormats = this.getImageFormats(newOptions)
        if (imageFormats.map(x => x.value).includes(newOptions.image_format) === false) {
          if (imageFormats.length > 0) {
            newOptions.image_format = imageFormats[0].value
          } else {
            newOptions.image_format = null
          }
        }

        // Raster Transforms
        let rasterTransforms = this.getRasterTransforms(newOptions)
        if (rasterTransforms.map(x => x.value).includes(newOptions.raster_transform) === false) {
          if (rasterTransforms.length > 0) {
            newOptions.raster_transform = rasterTransforms[0].value
          } else {
            newOptions.raster_transform = null
          }
        }

        newOptions.write_tiles = true

        if (newOptions.data_source === 'ELEVATION' && newOptions.format === 'TILES') {
          newOptions.write_tiles = false
        }
      }
    }
    return newOptions
  }


  getRasterFormats(options) {
    let allowed = []
    if (options.data_source === 'ELEVATION') {
      allowed = ['XYZ', 'TILES']
    } else if (options.data_source === 'ORTHOMOSAIC') {
      allowed = ['XYZ', 'TILES', 'KMZ', 'MB_TILES', 'WW']
    }
    return RASTER_FORMATS.filter(x => allowed.includes(x.value))
  }


  getImageFormats(options) {
    let allowed = []
    if (options.data_source === 'ELEVATION') {
      if (options.format === 'XYZ') {
        allowed = ['PNG', 'JPEG']
      } else if (options.format === 'TILES') {
        allowed = ['TIFF']
      }
    } else if (options.data_source === 'ORTHOMOSAIC') {
      if (['XYZ', 'KMZ', 'MB_TILES', 'WW'].includes(options.format)) {
        allowed = ['PNG', 'JPEG']
      } else {
        return IMAGE_FORMATS
      }
    } else if (options.data_source === 'MODEL') {
      if (['OBJ', '3DS'].includes(options.format)) {
        allowed = ['JPEG', 'PNG', 'TIFF', 'EXR']
      } else {
        return IMAGE_FORMATS
      }
    }
    return IMAGE_FORMATS.filter(x => allowed.includes(x.value))
  }

  getRasterTransforms(options) {
    let allowed = []
    if (options.data_source === 'ELEVATION') {
      if (options.format === 'XYZ') {
        allowed = ['PALETTE']
      } else if (options.format === 'TILES') {
        allowed = ['NONE', 'PALETTE']
      }
    }
    return RASTER_TRANSFORMS.filter(x => allowed.includes(x.value))
  }


  handleEmailChange(event) {
    let form = { ...this.state.form }
    form.email = event.target.value
    this.setState({ form })
  }


  handleTypeChange(event) {
    const target = event.target
    let form = { ...this.state.form }
    form.type = target.value
    form.options = this.getValidatedOptions(form.type, this.getDefaultFormOptionsForType(form.type))
    this.setState({ form })
  }


  handleOptionsChange(event) {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    let form = { ...this.state.form }
    form.options = { ...this.state.form.options, [target.name]: value }
    form.options = this.getValidatedOptions(this.state.form.type, form.options)
    this.setState({ form })
  }


  handleAnnotationsSubmit(event) {
    event.preventDefault()
    const form = { ...this.state.form }
    const productId = this.props.match.params.pid
    const format = form.options.format
    // temp fix if project is undefined.. set a default one.
    // TODO: need to fix the actual form because a projection
    // from the constructor doesn't come through in the form options object
    const projection = form.options.projection ? form.options.projection : 'EPSG:4326'
    let extension = ANNOTATION_FORMATS.find(x => x.value === format).extension

    this.setState({ loading: true })

    // the following allows us to implement a file download using an async call in javascript
    Annotations.exportAll(productId, format, projection)
      .then(response => {
        // change the extension to zip if the response is zipped
        if (response.headers.has('content-type') && response.headers.get('content-type').includes('zip')) {
          extension = 'zip'
        }

        // hack to force the browser to download the file
        const url = window.URL.createObjectURL(new Blob([response.blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${productId}.${extension}`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
      .catch(console.error)
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  handleRasterSubmit(event) {
    event.preventDefault()
    let form = { ...this.state.form }
    console.log(form)
    this.props.exportProduct(this.props.match.params.id, this.props.match.params.pid, form)
  }

  handleSubmit(event) {
    // pass
    event.preventDefault()
    let form = { ...this.state.form }
    console.log(form)
    this.props.exportProduct(this.props.match.params.id, this.props.match.params.pid, form)
  }

  isExportingAnnotations() {
    return this.state.form.type === 'ANNOTATIONS'
  }

  onProjectionPicked = (data) => {
    this.props.setCoordinateSystemPickerModal({ visible: false })
    const { crs, vertCrs } = data

    // null CRS means user clicked cancel or close, so don't update the crs
    if(crs === null) {
      return
    }

    let projectionName = crs.id
    if (vertCrs !== null) {
      // delimit compound projection name with a pipe to store in the DB
      projectionName = `${projectionName}|${vertCrs.id}`
    }

    // otherwise update the state
    let form = { ...this.state.form }
    form.options = { ...this.state.form.options, projection: projectionName }
    this.setState({ form, crs })
  }

  renderPointCloudForm() {
    return <form onSubmit={this.handleSubmit.bind(this)}>
      <CoordinateSystem noVertical={true} onChange={this.onProjectionPicked.bind(this)} />

      <label>Data Source
        <select
          required
          value={this.state.form.options.data_source}
          onChange={this.handleOptionsChange.bind(this)}
          name="data_source"
        >
          <option value="" hidden>Select a data source</option>
          {
            POINT_CLOUD_DATA_SOURCES.map(x => (
              <option key={x.value} value={x.value}>{x.name}</option>
            ))
          }
        </select>
      </label>
      <label>Format
        <select
          required
          value={this.state.form.options.format}
          onChange={this.handleOptionsChange.bind(this)}
          name="format"
        >
          <option value="" hidden>Select an export format</option>
          {
            POINT_CLOUD_FORMATS.map(x => (
              <option key={x.value} value={x.value}>{x.name} (*.{x.extension})</option>
            ))
          }
        </select>
      </label>
      <label htmlFor="email">
        E-mail Address
        <input id="email" type="text" value={this.state.form.email} onChange={this.handleEmailChange.bind(this)} />
      </label>
      <input type="submit" className="button solid" value='Send Data' />
    </form>
  }


  renderModelForm() {
    return <form onSubmit={this.handleSubmit.bind(this)}>
      <label>Format
        <select
          required
          value={this.state.form.options.format}
          onChange={this.handleOptionsChange.bind(this)}
          name="format"
        >
          <option value="" hidden>Select an export format</option>
          {
            MODEL_FORMATS.map(x => (
              <option key={x.value} value={x.value}>{x.name} (*.{x.extension})</option>
            ))
          }
        </select>
      </label>

      <label>Texture Format
        <select required value={this.state.form.options.texture_format} onChange={this.handleOptionsChange.bind(this)} name="texture_format">
          {
            this.getImageFormats(this.state.form.options).map(x => (
              <option key={x.value} value={x.value}>{x.name} (*.{x.extension})</option>
            ))
          }
        </select>
      </label>

      <label htmlFor="email">
        E-mail Address
        <input id="email" type="text" value={this.state.form.email} onChange={this.handleEmailChange.bind(this)} />
      </label>
      <input type="submit" className="button solid" value='Send Data' />
    </form>
  }

  renderContoursForm() {
    return <form onSubmit={this.handleSubmit.bind(this)}>
      <label>Format
        <select
          required
          value={this.state.form.options.format}
          onChange={this.handleOptionsChange.bind(this)}
          name="format"
        >
          <option value="" hidden>Select an export format</option>
          {
            CONTOURS_FORMATS.map(x => (
              <option key={x.value} value={x.value}>{x.name} (*.{x.extension})</option>
            ))
          }
        </select>
      </label>

      <label>Interval in meters
        <input required type="text" id='interval' name="interval"
          value={this.state.form.options.interval} onChange={this.handleOptionsChange.bind(this)} />
      </label>

      <CoordinateSystem noVertical={true} onChange={this.onProjectionPicked.bind(this)} />

      <label htmlFor="email">
        E-mail Address
        <input id="email" type="text" value={this.state.form.email} onChange={this.handleEmailChange.bind(this)} />
      </label>
      <input type="submit" className="button solid" value='Send Data' />
    </form>
  }

  renderRasterForm() {
    return <form onSubmit={this.handleRasterSubmit.bind(this)}>
      <label>Format
        <select
          required
          value={this.state.form.options.format}
          onChange={this.handleOptionsChange.bind(this)}
          name="format"
        >
          <option value="" hidden>Select an export format</option>
          {
            this.getRasterFormats(this.state.form.options).map(x => (
              <option key={x.value} value={x.value}>{getRasterFormatName(this.state.form.options, x.value)}</option>
            ))
          }
        </select>

        <label
          htmlFor="write_tiles"
          hidden={true}
          style={{ paddingBottom: 10 }}
        >
          <input
            disabled={true}
            style={{ marginRight: 10 }}
            id="write_tiles"
            name="write_tiles"
            type="checkbox"
            checked={this.state.form.options.write_tiles === true}
            onChange={this.handleOptionsChange.bind(this)}
          />
          Export Tiled
        </label>
      </label>

      {this.state.form.options.data_source === 'ELEVATION' &&
        <label>Raster Transform
          <select
            required
            value={this.state.form.options.raster_transform}
            onChange={this.handleOptionsChange.bind(this)}
            name="raster_transform"
          >
            {
              this.getRasterTransforms(this.state.form.options).map(x => (
                <option key={x.value} value={x.value}>{x.name}</option>
              ))
            }
          </select>
        </label>
      }

      {(this.state.form.options.data_source === 'ELEVATION' ||
          this.state.form.options.data_source === 'ORTHOMOSAIC') &&
        this.state.form.options.format === 'TILES' &&
        <CoordinateSystem onChange={this.onProjectionPicked.bind(this)} />
      }

      <label>Image Format
        <select required value={this.state.form.options.image_format} onChange={this.handleOptionsChange.bind(this)} name="image_format">
          {
            this.getImageFormats(this.state.form.options).map(x => (
              <option key={x.value} value={x.value}>{x.name} (*.{x.extension})</option>
            ))
          }
        </select>
      </label>

      <label htmlFor="write_alpha" hidden={true} style={{ paddingBottom: 10 }}>
        <input
          disabled={true}
          style={{ marginRight: 10 }}
          id="write_alpha"
          name="write_alpha"
          type="checkbox"
          checked={this.state.form.options.write_alpha}
          onChange={this.handleOptionsChange.bind(this)}
        />
        Write Alpha
      </label>

      <label htmlFor="email">
        E-mail Address
        <input id="email" type="text" value={this.state.form.email} onChange={this.handleEmailChange.bind(this)} />
      </label>

      <input type="submit" className="button solid" value='Send Data' />
    </form >
  }

  renderAnnotationForm() {
    return <form onSubmit={this.handleAnnotationsSubmit.bind(this)}>
      <label htmlFor="format">
        Shape Format
        <select
          id="format"
          name="format"
          checked={this.state.form.options.format}
          onChange={this.handleOptionsChange.bind(this)}
        >
          {
            ANNOTATION_FORMATS.map(x => (
              <option key={x.value} value={x.value}>{x.name} (*.{x.extension})</option>
            ))
          }
        </select>
      </label>

      <label htmlFor="projection" hidden={!this.isExportingAnnotations() || this.state.form.options.format !== 'SHP'}>
        Map Projection
        <select
          id="projection"
          name="projection"
          value={this.state.form.options.projection}
          onChange={this.handleOptionsChange.bind(this)}
        >
          {
            MAP_PROJECTIONS.map(x => (
              <option key={x.value} value={x.value}>{x.name}</option>
            ))
          }
        </select>
      </label>

      <input type="submit" className="button solid" value='Download' disabled={this.state.loading}/>
    </form>
  }

  render() {
    // don't show annotations options if there are no annotations
    let exportTypes = TYPES
    if (this.props.annotations && this.props.annotations.length === 0) {
      exportTypes = TYPES.filter(x => x.value !== 'ANNOTATIONS')
    }
    // don't show model option for map only products
    let product = this.props.products.find(product => product.id === this.props.match.params.pid)
    if (product && product.type === 'map') {
      exportTypes = TYPES.filter(x => x.value !== 'MODEL')
    }
    return <div className="add-annotation-form">
      <SidebarHeader title="Data Export" backLink={`/project/${this.props.match.params.id}/review/${this.props.match.params.pid}`} />
      <label>Type
        <select required value={this.state.form.type} onChange={this.handleTypeChange.bind(this)} name="type">
          {
            exportTypes.map(x => (
              <option key={x.value} value={x.value}>{x.name}</option>
            ))
          }
        </select>
      </label>
      {this.state.form.type === 'RASTER' &&
        <div>
          <label>Data Source
            <select required value={this.state.form.options.data_source} onChange={this.handleOptionsChange.bind(this)} name="data_source">
              {
                RASTER_DATA_SOURCES.map(x => (
                  <option key={x.value} value={x.value}>{x.name}</option>
                ))
              }
            </select>
          </label>

          {['ORTHOMOSAIC', 'ELEVATION'].includes(this.state.form.options.data_source) &&
            this.renderRasterForm()
          }
        </div>
      }
      {
        this.state.form.type === 'POINT_CLOUD' &&
        this.renderPointCloudForm()
      }
      {
        this.state.form.type === 'MODEL' &&
        this.renderModelForm()
      }
      {
        this.state.form.type === 'ANNOTATIONS' &&
        <div>
          {this.renderAnnotationForm()}
        </div>
      }
      {
        this.state.form.type === 'CONTOURS' &&
        this.renderContoursForm()
      }
    </div >
  }
}

export default ExportData