import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { crsCompoundEligible, DEFAULT_CRS, printCrs, fetchCrs } from '../../util'
import { setCoordinateSystemPickerModal } from '../../actions/ui'

class CoordinateSystem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      crs: DEFAULT_CRS,
      vertCrs: null,
    }
    this.setCoordinateSystemPickerModal = props.setCoordinateSystemPickerModal
  }

  componentDidMount = () => {
    // we may want to fetch the input CRS if we got IDs
    let promises = []
    if (this.props.crsId !== null && this.props.crsId.length > 0) {
      promises.push(fetchCrs(this.props.crsId))
      this.setState({ crs: { id: this.props.crsId, name: '', type: 'geographic 2d' } })
    }

    if (this.props.vertCrsId !== null && this.props.vertCrsId.length > 0) {
      promises.push(fetchCrs(this.props.vertCrsId))
      this.setState({ vertCrs: { id: this.props.vertCrsId, name: '', type: 'vertical' } })
    }

    // only do something if we have to...
    if (promises.length > 0) {
      Promise.all(promises)
        .then(this.onCrsLoaded)
        .catch(err => {
          console.error(err)
        }
      )
    }
  }

  onCrsLoaded = (values) => {
    const crs = values[0]
    const vertCrs = values.length === 2 ? values[1] : null

    // in case we bunged up the type...fake it
    // if we have a vert crs it means it is compound eligible...
    if (crs.type.length === 0 && vertCrs) {
      crs.type = 'projected'
    }

    this.setState({ crs, vertCrs })
  }

  onCrsPicked = (crs) => {
    this.setCoordinateSystemPickerModal({ visible: false })

    // means user clicked cancel
    if (crs === null) {
      // no-op
      return
    }
    
    // if the CRS changed to one ineligible for compound, reset the vert CRS
    let vertCrs = this.state.vertCrs
    if(vertCrs && !crsCompoundEligible(crs.type)) {
      vertCrs = null;
    }

    this.setState({ crs, vertCrs })
    this.props.onChange({crs, vertCrs})
  }

  onVertCrsPicked = (vertCrs) => {
    this.setCoordinateSystemPickerModal({ visible: false })
    this.setState({ vertCrs })

    const { crs } = this.state
    this.props.onChange({crs, vertCrs})
  }

  onCrsClick = (event) => {
    this.setCoordinateSystemPickerModal({
      onPick: this.onCrsPicked,
      visible: true,
    })
  }

  onVertCrsClick = (event) => {
    this.setCoordinateSystemPickerModal({
      onPick: this.onVertCrsPicked,
      pickerType: 'vertical',
      visible: true,
    })
  }

  render = () => {
    const crsStyle = {
      background: 'url(/static/media/menu-down.6889e7d7.svg) 95% center no-repeat rgb(255, 255, 255)',
      cursor: 'default',
    }
    return <div className="crsForm">
      <div className="crsFormCrs">
        <label>Coordinate Reference System</label>
        <input
          style={crsStyle}
          type="text"
          name="crs"
          readOnly={true}
          value={CoordinateSystem.prettyPrintCrs(this.state.crs)} 
          onClick={this.onCrsClick.bind(this)} />
      </div>
      {crsCompoundEligible(this.state.crs.type) && !this.props.noVertical &&
      <div className="crsFormVertCrs">
        <label>Vertical Reference System (Optional)</label>
        <input
          style={crsStyle}
          type="text"
          name="vert_crs"
          readOnly={true}
          value={CoordinateSystem.prettyPrintCrs(this.state.vertCrs)} 
          onClick={this.onVertCrsClick.bind(this)} />
      </div>}
    </div>
  }
}

CoordinateSystem.propTypes = {
  onChange: PropTypes.func,
  crsId: PropTypes.string,
  vertCrsId: PropTypes.string,
}

CoordinateSystem.defaultProps = {
  onChange: (data) => { console.warn('No function defined to handle CRS selection...') },
  crsId: null,
  vertCrsId: null,
}

CoordinateSystem.prettyPrintCrs = (crs) => {
  if (!crs) {
    return 'None'
  }
  return printCrs(crs)
}

const mapDispatchToProps = dispatch => {
  return {
    setCoordinateSystemPickerModal: (data) => dispatch(setCoordinateSystemPickerModal(data))
  }
}

export default connect((state) => {return {}}, mapDispatchToProps)(CoordinateSystem)
