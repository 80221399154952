import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import hash from 'object-hash'
import { Map, ImageOverlay, Marker } from 'react-leaflet'
import L from 'leaflet'
import './styles.css'
import markerImage from './marker.png'
import crosshairImage from './crosshair.png'

import { GCPs, Products } from '../../api'
import { setIsGCPOverlayOpen } from '../../actions/projects'
import { setRequestInProgress } from '../../actions/request'
import { alertTypes } from '../../util/constants'


const markerIcon = new L.Icon({
  iconUrl: markerImage,
  iconSize: [41, 79],
  iconAnchor: [21, 57]
})

class GCPMarkerOverlay extends Component {
  static propTypes = {
    // gcps: PropTypes.array,
    product: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.markers = []
    this.state = {
      gcps: [],
      projections: [],
      gcp_id: '',
      currentIndex: 0,
      inTransition: false,
      isFirstRun: true
    }
  }


  componentDidMount() {
    this.setState({
      inTransition: true,
      isFirstRun: true
    })
    GCPs.get(this.props.productJob.product_id)
      .then(response => {
        this.setState({
          gcps: response.data
        })
        GCPs.getProjections(this.props.productJob.product_id)
          .then(response => {
            if (response) {
              // HACK: check if this is a first run (no pinned projections)
              let isFirstRun = response.filter(x => x.pinned).length === 0
              this.setState({
                projections: response,
                gcp_id: this.state.gcps[0].id,
                isFirstRun
              })
            }
          }).catch(error => {
            console.log(error)
          })
      })
      .finally(() => {
        this.setState({
          inTransition: false
        })
      })
  }


  setProjectionPinned = (projection, pinned) => {
    let index = this.state.projections.findIndex(
      x =>
        x.gcp_id === projection.gcp_id &&
        x.file_hashname === projection.file_hashname
    )
    let projections = Object.assign([], this.state.projections)
    projections[index].pinned = pinned
    let key = projection.gcp_id + projection.file_hashname
    let marker = this.markers[key]

    this.setState({
      projections
    }, () => {
      this.setProjectionOffset(projection, { lat: marker.props.position[0], lng: marker.props.position[1] })
    })
  }


  setProjectionOffset = (projection, offset) => {
    let index = this.state.projections.findIndex(
      x =>
        x.gcp_id === projection.gcp_id &&
        x.file_hashname === projection.file_hashname
    )
    let projections = Object.assign([], this.state.projections)
    if (projections[index].offset === null) {
      projections[index].offset = {
        type: 'Point',
        coordinates: [0, 0]
      }
    }
    projections[index].offset.coordinates = [offset.lng, -offset.lat]
    this.setState({ projections })
  }


  onMarkerDragEnd = (projection, e) => {
    this.setProjectionOffset(projection, e.target.getLatLng())
    this.setProjectionPinned(projection, true)
  }


  onGcpIdChanged = event => {
    let index = this.state.gcps.findIndex(
      x =>
        x.id === event.target.value
    )
    this.setState({
      gcp_id: event.target.value,
      currentIndex: index,
      inTransition: true
    }, () => {
      setTimeout(() => {
        this.setState({
          inTransition: false
        })
      }, 300)
    })
  }


  completedRefinement = event => {
    this.props.setAlertModal({
      message: 'Are you sure that you want to finalize the refinement for ALL GCPs on this product?',
      confirmLabel: 'Yes',
      closeLabel: 'No',
      type: alertTypes.warning,
      confirmHandler: () => {
        this.props.setRequestInProgress(true, 'GLOBAL')
        GCPs.updateProjections(this.props.productJob.product_id, { projections: JSON.stringify(this.state.projections) })
          .then(response => {
            if (response.success) {
              this.props.setIsGCPOverlayOpen(false)
              // resume processing
              Products.continueProcessing(this.props.productJob.id)
                .then(resp => {
                  if (resp) {
                    this.props.setAlertModal({
                      message: 'Refined GCP Projections Saved. Resuming processing workflow.',
                      type: alertTypes.info
                    })
                  } else {
                    this.props.setAlertModal({
                      message: 'Unable to continue the processing workflow. Please contact support.',
                      type: alertTypes.error
                    })
                  }
                })
            } else {
              this.props.setAlertModal({
                message: response.error,
                type: alertTypes.error
              })
            }
          }).catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.props.setRequestInProgress(false, 'GLOBAL')
          })
      },
      cancellable: true
    })
  }

  // used to get leaflet map element and zoom
  // after loading based on existence of offset
  mapRef = mapRef => {
    let projection = mapRef.target.options.projection
    if (projection.offset !== null) {
      this.adjustMapZoomLeveltoOffsetMarker(mapRef.target)
    }
  }


  adjustMapZoomLeveltoOffsetMarker = map => {
    // needs to happen after map is initialized, so using layeradd event
    map.on('layeradd', (event) => {
      if (event.layer instanceof L.Marker) {
        let latLngs = [event.layer.getLatLng()]
        let markerBounds = L.latLngBounds(latLngs)
        map.fitBounds(markerBounds)
        // fitBounds zoomed too far, so zooming slightly back a bit
        map.setZoom(1)
      }
    })
  }


  changeCurrentGCP = direction => {
    let index = this.state.gcps.findIndex(
      x => x.id === this.state.gcp_id
    )

    if (direction === 'previous') {
      index = index > 0 ? index - 1 : this.state.gcps.length - 1
    } else if (direction === 'next') {
      index = index < this.state.gcps.length - 1 ? index + 1 : 0
    } else {
      return
    }

    this.setState({
      gcp_id: this.state.gcps[index].id,
      currentIndex: index,
      inTransition: true
    }, () => {
      setTimeout(() => {
        this.setState({
          inTransition: false
        })
      }, 300)
    })
  }


  dragStart = event => {
    // console.log(event)
    event.target._icon.src = crosshairImage
  }

  getRootMeanSquareError(gcp_id) {
    let errors = []
    this.state.projections
      .filter(x => x.gcp_id === gcp_id)
      .forEach(projection => {
        errors.push(projection.error)
      })
    errors.map((error) => {
      return Math.pow(error, 2)
    })
    let meanSquareError = errors.reduce((total, num) => {
      return total + num
    }, 0)
    meanSquareError /= errors.length
    return Math.sqrt(meanSquareError)
  }

  getPinnedProjectionCount(gcpId) {
    return this.state.projections.filter(projection => projection.pinned === true && projection.gcp_id === gcpId).length
  }


  render() {
    let gcps = this.state.gcps
    let refinedCount = this.getPinnedProjectionCount(this.state.gcp_id)

    return (
      <section id="gcp-marker-overlay">
        <header className="instructions">
          <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <h2>Control Point:</h2>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <select style={{ fontSize: '2rem' }} value={this.state.gcp_id} onChange={this.onGcpIdChanged}>
                {gcps.sort((a, b) => {
                  return a.order - b.order
                }).map(gcp => {
                  return <option key={gcp.id} value={gcp.id}>{gcp.label}</option>
                })}
              </select>
              {
                gcps.length > 0 &&
                this.state.projections.length > 0 &&
                this.state.isFirstRun === false &&
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '1rem' }}>
                  <div className="gcp-epsilon-value">
                    Σ {this.getRootMeanSquareError(this.state.gcp_id).toFixed(2)} px
                  </div>
                  {gcps[this.state.currentIndex].error !== null &&
                    <div className="gcp-epsilon-value">
                      ε {gcps[this.state.currentIndex].error.toFixed(2)} m
                    </div>
                  }
                </div>
              }
            </div>
          </div>
          <div style={{ alignSelf: 'center' }}>
            <h3 className={refinedCount >= 2 ? 'complete' : 'needs-action'}>
              {refinedCount} projection{refinedCount > 1 && 's'} pinned
              {refinedCount >= 2 && '. You can move onto the next GCP projections.'}
            </h3>
            <p>Drag at least one marker to the center of a ground control point in an image to refine the accuracy of its position.<br />If the marker is already correctly positioned, pin the projection as an accurate position for processing.</p>
          </div>
        </header >

        <section className="gcp-projections">
          {this.state.inTransition && <div className="lds-ring" style={{ marginTop: '-4rem', transform: 'translateX(-50%)', left: '50%' }}><div></div><div></div><div></div><div></div></div>}
          {!this.state.inTransition && this.state.projections
            .filter(x => x.gcp_id === this.state.gcp_id)
            .map((projection, index) => {
              let markerPosition = [0, 0]
              if (projection.offset !== null) {
                markerPosition = [
                  -projection.offset.coordinates[1],
                  projection.offset.coordinates[0]
                ]
              } else if (projection.target_offset !== null) {
                markerPosition = [
                  -projection.target_offset.coordinates[1],
                  projection.target_offset.coordinates[0]
                ]
              }

              return (
                <div
                  key={hash(projection.file_hashname)}
                  className="gcp-projection"
                >
                  <Map
                    className="gcp-projection__map"
                    attributionControl={false}
                    maxBoundsViscosity={0.5}
                    zoomControl={false}
                    crs={L.CRS.Simple}
                    bounds={[[-500, -500], [500, 500]]} // the bounds are dependent on the size of the image
                    maxBounds={[[-500, -500], [500, 500]]}
                    minZoom={-2}
                    maxZoom={3}
                    center={markerPosition}
                    ref={(mapRef) => { this._mapRef = mapRef }}
                    whenReady={this.mapRef}
                    projection={projection}
                  >
                    <ImageOverlay
                      url={`${this.props.productJob.bucket.base_url}/${projection.product_id}/gcps/${projection.gcp_id}/${
                        projection.file_hashname
                        }?${Math.random()}`}
                      bounds={[[-500, -500], [500, 500]]}
                    />
                    <Marker
                      ref={(ref) => {
                        this.markers[projection.gcp_id + projection.file_hashname] = ref
                      }}
                      position={markerPosition}
                      icon={markerIcon}
                      draggable={true}
                      onDragStart={this.dragStart}
                      onDragEnd={e => {
                        this.onMarkerDragEnd(projection, e)
                        e.target._icon.src = markerImage
                      }}
                    />
                  </Map>
                  {this.state.isFirstRun === false && <div style={{
                    zIndex: 1000,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    padding: '0.25rem',
                    margin: '0.25rem',
                    fontFamily: 'Montserrat',
                    fontSize: '0.75rem',
                    backgroundColor: '#202020'
                  }}>
                    ε {projection.error.toFixed(2)} px
                  </div>}
                  <div
                    style={{
                      pointerEvents: 'none',
                      zIndex: 400,
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                      borderColor: 'white'
                    }}
                  >
                    <button
                      className={projection.pinned ? 'pinned' : 'not-pinned'}
                      onClick={e => {
                        // TODO: if we just click this immediately, the offset isn't set to anything
                        // so we need to be able to rescue the marker reference here somehow
                        this.setProjectionPinned(
                          projection,
                          !projection.pinned
                        )
                      }}
                    >
                      {!projection.pinned && (
                        <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                      )}

                      {projection.pinned && (
                        <i className="fa fa-check"
                          style={{ color: 'rgba(136,197,64)' }}
                          aria-hidden="true"></i>
                      )}

                    </button>
                    {projection.pinned && (
                      <div
                        style={{
                          zIndex: 400,
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          bottom: 0,
                          left: 0,
                          borderWidth: 0,
                          borderStyle: 'solid',
                          borderColor: 'rgb(136, 197, 64)'
                        }}
                      />
                    )}
                    {projection.pinned && (
                      <div className="valid-text">Pinned</div>
                    )}
                  </div>
                </div>
              )
            })}
        </section>

        <svg viewBox="0 0 74 74"
          onClick={() => this.props.setIsGCPOverlayOpen(false)}
          className="close-icon">
          <g data-name="Group 3278">
            <path d="M35.417 25.604v8h-8v2.668h8v8h2.668v-8h8v-2.668h-8v-8z" fill="#FFFFFF"
              className="plus-lines" />
          </g>
        </svg>

        <div className="modal-controls">
          {this.state.gcps.length > 1 && <button
            className="btn white"
            onClick={() => this.changeCurrentGCP('previous')}
          >
            Previous Projections
          </button>}

          {this.state.gcps.length > 1 && <button
            className="btn white"
            onClick={() => this.changeCurrentGCP('next')}
          >
            Next Projections
          </button>}

          <button
            className="btn"
            onClick={this.completedRefinement}>
            Finish Refinement
          </button>
        </div>
      </section >
    )
  }
}


const mapStateToProps = state => {
  return {
    gcps: state.gcps,
    productJob: state.productJob
  }
}


const mapDispatchToProps = dispatch => {
  return {
    setRequestInProgress: (bool, type) => dispatch(setRequestInProgress(bool, type)),
    setIsGCPOverlayOpen: (bool) => dispatch(setIsGCPOverlayOpen(bool))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GCPMarkerOverlay)
