import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import L from 'leaflet'
import { centroid } from '@turf/turf'
import { convertArea } from '@turf/helpers'
import { formatTime } from '../../helpers'
import FlightEstimates from '../FlightEstimates/'
import SidebarHeader from '../SidebarHeader/'
import './styles.css'

class OrbitFlight extends Component {
  static propTypes = {
    leafletMapElement: PropTypes.object.isRequired,
    drawMappingFlightLines: PropTypes.func.isRequired,
    setFlightParameters: PropTypes.func.isRequired,
    setDrawingFlightMapObjects: PropTypes.func.isRequired,
    setOrbitFlightParameters: PropTypes.func.isRequired,
    drawOrbitShape: PropTypes.func.isRequired,
    handleFlightSubmit: PropTypes.func.isRequired,
    backFromFlightType: PropTypes.func.isRequired,
    orbitFlightParameters: PropTypes.object.isRequired,
    flightEstimatesData: PropTypes.object,
    drawingFlightMapObjects: PropTypes.object,
    setOrbitCentroid: PropTypes.func.isRequired,
    flightPlan: PropTypes.object.isRequired,
    isEdittingFlightPlan: PropTypes.bool,
    setFlightPlanName: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      flightPlanName: 'Orbit Flight'
    }
  }

  componentDidMount() {
    if (this.props.leafletMapElement) {
      if (this.props.isEdittingFlightPlan) {
        let point = this.props.flightPlan.geojson
        point = [point.geometry.coordinates[1], point.geometry.coordinates[0]]
        this.props.setOrbitCentroid(point)
      }
      this.props.drawOrbitShape()
    }
    if (this.props.isEdittingFlightPlan) {
      if (this.props.flightPlan.name) {
        this.setState({ flightPlanName: this.props.flightPlan.name })
        this.props.setFlightPlanName(this.props.flightPlan.name)
      }
    }
  }


  handleChange = (event) => {
    if (event.target.name === 'radiix') {
      this.props.setOrbitFlightParameters({
        radii: {
          x: Number(event.target.value),
          y: this.props.orbitFlightParameters.radii.y
        }
      })
    }
    else if (event.target.name === 'radiiy') {
      this.props.setOrbitFlightParameters({
        radii: {
          x: this.props.orbitFlightParameters.radii.x,
          y: Number(event.target.value)
        }
      })
    } else if (event.target.name === 'name') {
      this.setState({ flightPlanName: event.target.value })
      this.props.setFlightPlanName(event.target.value)
    } else {
      this.props.setOrbitFlightParameters({ [event.target.name]: Number(event.target.value) })
    }

    if (this.props.drawingFlightMapObjects.shape) {
      let point = centroid(this.props.drawingFlightMapObjects.shape.toGeoJSON(8))
      point = [point.geometry.coordinates[1], point.geometry.coordinates[0]]
      console.log(point)
      // this.props.setOrbitCentroid(point)
    }



    setTimeout(() => {
      if (this.props.drawingFlightMapObjects.shape) {
        this.props.drawOrbitShape()
      }
    }, 10)
  }


  handleSubmit = (event) => {
    event.preventDefault()
    this.props.drawOrbitShape()
    this.props.handleFlightSubmit()
  }


  handleDelete = (event) => {
    event.preventDefault()
    this.props.deleteFlightPlan(this.props.flightPlan)
  }


  render() {
    let formTitle
    if (this.props.isEdittingFlightPlan) {
      formTitle = 'Edit Orbit Flight'
    } else {
      formTitle = 'Create Orbit Flight'
    }

    let sidebarHeader
    if (this.props.isEdittingProject || this.props.isAddingProject) {
      sidebarHeader = <SidebarHeader title={formTitle} backFunction={this.props.backFromFlightType} />
    } else {
      sidebarHeader = <SidebarHeader title={formTitle} backLink={`/project/${this.props.match.params.id}/plan`} backFunction={this.props.backFromFlightType} />
    }

    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          {sidebarHeader}
          <label>Flight Plan Name
            <input required type="text" value={this.state.flightPlanName} onChange={this.handleChange} placeholder={`${this.props.flightPlan.name}`} name="name" />
          </label>
          {this.props.flightEstimatesData && this.props.flightEstimatesData.meta && (
            <FlightEstimates {...this.props}
              minutes={formatTime(this.props.flightEstimatesData.meta.duration)}
              acres={this.props.flightEstimatesData.meta.acres && (convertArea(this.props.flightEstimatesData.meta.acres, 'metres', 'acres').toFixed(1))}
              photoCount={this.props.flightEstimatesData.meta.photoCount}
              batteries={this.props.flightEstimatesData.meta.batteries} />
          )}

          <label>Altitude: <span className="currentValue">{(Math.round((this.props.orbitFlightParameters.altitude)))} m</span>
            <input type="range" min="2" max="150" step="1" value={this.props.orbitFlightParameters.altitude} name="altitude" onChange={this.handleChange} />
          </label>

          <label>Radius (x): <span className="currentValue">{(Math.round((this.props.orbitFlightParameters.radii.x * 1000)))} m</span>
            <input type="range" min=".005" max="0.4" step="0.0001" value={this.props.orbitFlightParameters.radii.x} name="radiix" onChange={this.handleChange} />
          </label>

          <label>Radius (y): <span className="currentValue">{(Math.round((this.props.orbitFlightParameters.radii.y * 1000)))} m</span>
            <input type="range" min=".005" max="0.4" step="0.0001" value={this.props.orbitFlightParameters.radii.y} name="radiiy" onChange={this.handleChange} />
          </label>

          <label>Angle: <span className="currentValue">{(Math.round((this.props.orbitFlightParameters.angle)))}</span>
            <input type="range" min="-90" max="90" step="1" value={this.props.orbitFlightParameters.angle} name="angle" onChange={this.handleChange} />
          </label>

          <label>Point of Interest Altitude <span className="currentValue">{Math.round((this.props.orbitFlightParameters.pointOfInterestAltitude))} m</span>
            <input type="range" min="0" max="150" step="1" value={this.props.orbitFlightParameters.pointOfInterestAltitude} name="pointOfInterestAltitude" onChange={this.handleChange} />
          </label>

          <label>Photos: <span className="currentValue">{(Math.round((this.props.orbitFlightParameters.photoCount)))}</span>
            <input type="range" min="16" max="48" step="1" value={this.props.orbitFlightParameters.photoCount} name="photoCount" onChange={this.handleChange} />
          </label>


          <input type="submit" className="button solid" value="Save Flight Plan" />

          {this.props.isEdittingFlightPlan && (
            <span style={{
              width: '100%',
              textAlign: 'center',
              textTransform: 'uppercase',
              fontSize: '.8rem',
              marginTop: '1rem',
              float: 'left',
              cursor: 'pointer'
            }} onClick={this.handleDelete}>Delete Flight Plan</span>
          )}

        </form>

      </div>
    )
  }
}

export default OrbitFlight