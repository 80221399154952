import React from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import moment from 'moment'
import 'moment-timezone'
import hash from 'object-hash'
import MapAreaIcon from './MapAreaIcon'
import MapDistanceIcon from './MapDistanceIcon'
import MapVolumeIcon from './MapVolumeIcon'
import MapLocationIcon from './MapLocationIcon'
import PolygonAnnotation from './PolygonAnnotation'
import PolylineAnnotation from './PolylineAnnotation'
import LocationAnnotation from './LocationAnnotation'
import AnnotationMap from './AnnotationMap'

const PrintAnnotationReport = props => {
  const closeHandler = () => {
    props.setIsPrintingAnnotationReport(false)
  }
  const filterAnnotations = (annotationType) => {
    // Deleting "base_plan_elevation" from returned data from the server
    // because we don't currently have an affordance for it in the reports
    // and it's not really a useful value to display. Not sure why we need it.
    // We should consider removing it on the GIS service end in future.
    props.annotations.forEach(annotation => {
      annotation.data && Object.keys(annotation.data).forEach(key => {
        if (key === 'base_plane_elevation') {
          delete annotation.data.base_plane_elevation
        }
      })
    })


    return props.annotations.filter(annotation => {
      return annotation.type === annotationType && annotation.visible
    })
  }
  const areaAnnotations = filterAnnotations('map-area')
  const distanceAnnotations = filterAnnotations('map-distance')
  const locationAnnotations = filterAnnotations('map-location')
  const volumeAnnotations = filterAnnotations('map-volume')
  const annotationsSortedByType = [
    ...areaAnnotations,
    ...distanceAnnotations,
    ...locationAnnotations,
    ...volumeAnnotations
  ]

  const labelColor = (color, index) => {
    return (
      <svg width="25" height="25">
        <circle cx="12.5" cy="12.5" r="10" fill={color} />
        <text x="50%" y="50%" textAnchor="middle" fill="white" fontSize="10px" dy=".3em">{index}</text>
      </svg>
    )
  }

  /**
   * Get labels from object keys returned from server rather than assuming order
   * Since this is for the table header, we're going to assume the first annotation has the same values as the rest
   * Note: we're currently only doing this for volume and area annotations below, and otherwise explicitly coding the values
   * for the others.
   */
  const getLabelsFromKeys = (annotation) => {
    return Object.keys(annotation.data).map(key => {
      // uppercase the first character of the key, replace underscores with spaces, and uppercase 3d to 3D
      return key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ').replace(/3d/g, '3D').replace(/2d/g, '2D')
    })
  }

  const renderTableHeaders = (headers) => {
    return headers.map(header => {
      const centerLabelHeader = header === 'Label' ? 'center-label-header' : ''
      return (
        <div key={header} className={`annotation-table-cell annotation-table-header ${centerLabelHeader}`}>
          <strong>{header}</strong>
        </div>
      )
    })
  }

  const tileUrlRoot = props.product.bucket.base_url
  const tileUrls = [
    { title: 'Digital Elevation Model', url: `${tileUrlRoot}/${props.product.id}/dem/{z}/{x}/{y}.png` },
    { title: 'Orthophoto', url: `${tileUrlRoot}/${props.product.id}/{z}/{x}/{y}.png` }
  ]

  const renderPrintButton = (extraPadding) => {
    return (
      <div className={`${extraPadding ? 'annotation-print-btn annotation-print-btn-2' : 'annotation-print-btn'}`}>
        <button type="button" className="btn white" onClick={() => window.print()}>Print Report</button>
      </div>
    )
  }

  return (
    <section className={props.collapsedMenu ? 'annotations-report' : 'annotations-report menu-collapsed'}>
      <span onClick={closeHandler}>
        <svg viewBox="0 0 74 74" className="close-icon">
          <g data-name="Group 3278">
            <path d="M35.417 25.604v8h-8v2.668h8v8h2.668v-8h8v-2.668h-8v-8z" fill="#BBBBBB"
              className="plus-lines" />
          </g>
        </svg>
      </span>
      {renderPrintButton(false)}
      <div className="annotations-report-header-container">
        <img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="Spexi Geospatial" />
        <h2 className="annotations-report-header">Measurements & Annotations Report</h2>
        <h3 className="annotations-report-name">{props.project.name}</h3>
        <p className="annotations-report-date">Processed on {moment(props.product.updated_at).tz(props.userTimezone).format('MMM. Do YYYY') + ' at ' + moment(props.product.updated_at).tz(props.userTimezone).format('h:mma')}</p>
      </div>
      <section className="measure-annotation-list">
        {areaAnnotations.length > 0 && (
          <article className="annotation-list-section">
            <div className="annotation-header-container">
              <h3 className="annotation-header">Area</h3> <MapAreaIcon width={30} height={30} />
            </div>
            <div className="annotation-table annotation-table-5cols">
              {renderTableHeaders(['Label', 'Title', ...getLabelsFromKeys(areaAnnotations[0])])}
            </div>
            {areaAnnotations.map((areaAnnotation) => {
              let originalIndex = annotationsSortedByType.indexOf(areaAnnotation)
              return (
                <div key={areaAnnotation.id} className="annotation-table annotation-table-5cols">
                  <div className="annotation-table-cell">
                    <div className="label-cell">
                      {labelColor(`#${areaAnnotation.color}`, originalIndex + 1)}
                    </div>
                  </div>
                  <div className="annotation-table-cell">
                    {areaAnnotation.name}
                  </div>
                  {
                    Object.keys(areaAnnotation.data).map((key) => {
                      return (
                        <div key={key} className="annotation-table-cell">
                          <span>{areaAnnotation.data[key].toFixed(2)} m
                            {key === 'area_2d' || key === 'area_3d' ? <sup>2</sup> : null}
                          </span>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })}
          </article>
        )}
        {distanceAnnotations.length > 0 && (
          <article className="annotation-list-section">
            <div className="annotation-header-container">
              <h3 className="annotation-header">Distance</h3> <MapDistanceIcon width={30} height={30} />
            </div>
            <div className="annotation-table annotation-table-4cols">
              {renderTableHeaders(['Label', 'Title', 'Distance', 'Slope'])}
            </div>

            {distanceAnnotations.map((distanceAnnotation) => {
              let originalIndex = annotationsSortedByType.indexOf(distanceAnnotation)
              return (
                <div key={distanceAnnotation.id} className="annotation-table annotation-table-4cols">
                  <div className="annotation-table-cell">
                    <div className="label-cell">
                      {labelColor(`#${distanceAnnotation.color}`, originalIndex + 1)}
                    </div>
                  </div>
                  <div className="annotation-table-cell">
                    {distanceAnnotation.name}
                  </div>
                  <div className="annotation-table-cell">
                    {distanceAnnotation.data && <span>{distanceAnnotation.data.distance.toFixed(2)} m
                    </span>}
                  </div>

                  <div className="annotation-table-cell">
                    {distanceAnnotation.data && <span>{distanceAnnotation.data.slope.toFixed(2)} °
                    </span>}
                  </div>
                </div>
              )
            })}
          </article>
        )}
        {locationAnnotations.length > 0 && (
          <article className="annotation-list-section">
            <div className="annotation-header-container">
              <h3 className="annotation-header">Location</h3> <MapLocationIcon width={30} height={30} />
            </div>
            <div className="annotation-table annotation-table-3cols">
              {renderTableHeaders(['Label', 'Title', 'Elevation'])}
            </div>

            {locationAnnotations.map((locationAnnotation) => {
              let originalIndex = annotationsSortedByType.indexOf(locationAnnotation)
              return (
                <div key={locationAnnotation.id} className="annotation-table annotation-table-3cols">
                  <div className="annotation-table-cell">
                    <div className="label-cell">
                      {labelColor(`#${locationAnnotation.color}`, originalIndex + 1)}
                    </div>
                  </div>
                  <div className="annotation-table-cell">
                    {locationAnnotation.name}
                  </div>
                  <div className="annotation-table-cell">
                    {locationAnnotation.data && <span>{locationAnnotation.data.elevation.toFixed(2)} m
                    </span>}
                  </div>
                </div>
              )
            })}
          </article>
        )}
        {volumeAnnotations.length > 0 && (
          <article className="annotation-list-section">
            <div className="annotation-header-container">
              <h3 className="annotation-header">Volume</h3> <MapVolumeIcon width={30} height={30} />
            </div>
            <div className="annotation-table annotation-table-5cols">
              {renderTableHeaders(['Label', 'Title', ...getLabelsFromKeys(volumeAnnotations[0])])}
            </div>

            {volumeAnnotations.map((volumeAnnotation) => {
              let originalIndex = annotationsSortedByType.indexOf(volumeAnnotation)
              return (
                <div key={volumeAnnotation.id} className="annotation-table annotation-table-5cols">
                  <div className="annotation-table-cell">
                    <div className="label-cell">
                      {labelColor(`#${volumeAnnotation.color}`, originalIndex + 1)}
                    </div>

                  </div>
                  <div className="annotation-table-cell">
                    {volumeAnnotation.name}
                  </div>
                  {
                    Object.keys(volumeAnnotation.data).map((key) => {
                      return (
                        <div key={key} className="annotation-table-cell">
                          <span>{volumeAnnotation.data[key].toFixed(2)} m <sup>3</sup>
                          </span>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })}
          </article>
        )}
      </section>
      {tileUrls.map((tileUrl) => {
        return (
          <section key={tileUrl.url} className="annotation-map">
            <h4 className="annotation-map-header">{tileUrl.title}</h4>
            <div className="print-map">
              <AnnotationMap
                center={props.projectsMapCenter}
                zoom={props.mapZoomLevel}
                tileUrl={tileUrl.url}
              >
                {annotationsSortedByType.map((annotation, index) => {
                  if (annotation.visible) {
                    if (annotation.type === 'map-volume' || annotation.type === 'map-area') {
                      return <PolygonAnnotation key={hash(annotation.id)} annotation={annotation} index={index + 1} />
                    } else if (annotation.type === 'map-distance') {
                      return <PolylineAnnotation key={hash(annotation.id)} annotation={annotation} index={index + 1} />
                    } else if (annotation.type === 'map-location') {
                      return <LocationAnnotation key={hash(annotation.id)} annotation={annotation} index={index + 1} />
                    } else {
                      return <div></div>
                    }
                  } else {
                    return <div></div>
                  }
                })
                }
              </AnnotationMap>
            </div>
          </section>
        )
      })}
      {renderPrintButton(true)}
    </section>
  )
}
PrintAnnotationReport.propTypes = {
  setIsPrintingAnnotationReport: PropTypes.func.isRequired,
  annotations: PropTypes.array.isRequired,
  product: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  userTimezone: PropTypes.string.isRequired,
  projectsMapCenter: PropTypes.array.isRequired,
  mapZoomLevel: PropTypes.number.isRequired,
  collapsedMenu: PropTypes.bool.isRequired,
}
export default PrintAnnotationReport