import React from 'react'

export const NoProjectsIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 252.849 151.863" id="drone-icon">
  <g transform="translate(.001 -.005)" fill="#dadbe0">
    <path id="Path_512" data-name="Path 512" d="M583.806,488.449q3.083,1.98,6.035,4.148c1.5,1.1,2.949-1.407,1.462-2.5q-2.952-2.168-6.035-4.145c-1.572-1.01-3.025,1.493-1.447,2.5Z" transform="translate(-443.462 -417.116)" />
    <path id="Path_513" data-name="Path 513" d="M267.1,420.961c21.775-3.867,44.316-1.5,65.124,5.806a115.035,115.035,0,0,1,14.246,5.913c1.656.842,3.123-1.653,1.462-2.5a130.082,130.082,0,0,0-28.53-10.217,132.6,132.6,0,0,0-33.752-3.719,125.757,125.757,0,0,0-19.32,1.939c-1.832.324-1.056,3.114.77,2.79Z" transform="translate(-217.607 -367.735)" />
    <path id="Path_514" data-name="Path 514" d="M277.88,423.182a27.581,27.581,0,0,1,31.839,18.426c.579,1.766,3.363,1.01,2.79-.77a30.136,30.136,0,0,0-35.4-20.437c-1.821.388-1.051,3.184.767,2.793Z" transform="translate(-225.268 -370.21)" />
    <path id="Path_515" data-name="Path 515" d="M394.634,492.823a57.382,57.382,0,0,0,28.545,7.065c1.861-.035,1.867-2.929,0-2.894a54.359,54.359,0,0,1-27.086-6.672c-1.632-.894-3.094,1.6-1.447,2.5Z" transform="translate(-309.012 -420.254)" />
    <path id="Path_516" data-name="Path 516" d="M492.1,504.112a52.93,52.93,0,0,1,30.753-4.837c1.821.266,2.6-2.521.767-2.79a56.339,56.339,0,0,0-33,5.129c-1.673.819-.208,3.314,1.462,2.5Z" transform="translate(-377.201 -424.338)" />
    <path id="Path_517" data-name="Path 517" d="M403.509,500.027l2.935,5.21a1.446,1.446,0,0,0,2.5-1.447l-2.938-5.21a1.444,1.444,0,0,0-2.5,1.447Z" transform="translate(-315.681 -425.753)" />
    <path id="Path_518" data-name="Path 518" d="M412.545,520.164a69.343,69.343,0,0,0,19.488,5.41c1.841.234,1.826-2.663,0-2.894a64.53,64.53,0,0,1-18.026-5.019c-1.69-.747-3.164,1.737-1.462,2.5Z" transform="translate(-321.707 -439.716)" />
    <path id="Path_519" data-name="Path 519" d="M479.963,523.516l4.536-6.093c1.111-1.5-1.4-2.941-2.5-1.447l-4.533,6.078C476.351,523.551,478.863,525,479.963,523.516Z" transform="translate(-368.176 -438.17)" />
    <path id="Path_520" data-name="Path 520" d="M480.626,511.66c4.663-1.424,9.491-2.619,14.021-4.434,4.261-1.708,7.743-4.527,11.2-7.482,1.418-1.213-.637-3.25-2.046-2.046-3.34,2.857-6.712,5.578-10.889,7.106-4.269,1.563-8.706,2.738-13.057,4.067C478.079,509.411,478.837,512.208,480.626,511.66Z" transform="translate(-369.353 -425.368)" />
    <path id="Path_521" data-name="Path 521" d="M400.8,516.61a3.835,3.835,0,0,0-4.414.8c-1.447,1.447-1.5,3.586-1.5,5.5q.023,7.467.064,14.932l2.842-.385-.22-.848a1.448,1.448,0,0,0-2.79.77l.22.848a1.454,1.454,0,0,0,2.842-.385l-.043-10.42c0-.868-.541-9.213,1.534-8.3,1.69.741,3.166-1.754,1.462-2.5Z" transform="translate(-309.589 -438.802)" />
    <path id="Path_522" data-name="Path 522" d="M422.594,547.439a6.152,6.152,0,0,0-1.5,7.181,6.02,6.02,0,0,0,6.614,3.123,5.91,5.91,0,0,0,4.405-5.595,5.76,5.76,0,0,0-4.877-5.557c-1.823-.252-2.6,2.538-.77,2.793a2.809,2.809,0,0,1,.472,5.569c-3.329.686-4.478-3.514-2.3-5.468C426.033,548.24,423.981,546.2,422.594,547.439Z" transform="translate(-327.907 -460.359)" />
    <path id="Path_523" data-name="Path 523" d="M416.651,454.067l-.081,2.5,2.845-.385-.061-.289a1.449,1.449,0,0,0-2.793.77l.061.289a1.447,1.447,0,0,0,2.842-.385l.081-2.5a1.447,1.447,0,0,0-2.894,0Z" transform="translate(-325.061 -393.599)" />
    <path id="Path_524" data-name="Path 524" d="M481.854,466.1l-.1,2.316a1.447,1.447,0,0,0,1.447,1.447,1.476,1.476,0,0,0,1.447-1.447l.1-2.316a1.447,1.447,0,0,0-1.447-1.447A1.476,1.476,0,0,0,481.854,466.1Z" transform="translate(-371.418 -402.147)" />
    <path id="Path_525" data-name="Path 525" d="M441.5,471.528a4.034,4.034,0,1,0,7.847,1.494,1.453,1.453,0,0,0-1.447-1.447,1.483,1.483,0,0,0-1.447,1.447c-.2,1.609-2.706.99-2.162-.724C444.85,470.518,442.057,469.757,441.5,471.528Z" transform="translate(-342.669 -406.319)" />
    <path id="Path_526" data-name="Path 526" d="M485.283,539.732c2.515-.454.993,6.947.868,8.09l-1.1,9.7c-.208,1.847,2.686,1.832,2.894,0,.518-4.567,1.059-9.129,1.551-13.7.191-1.777.263-3.661-.81-5.21a4.032,4.032,0,0,0-4.171-1.688c-1.829.33-1.054,3.12.77,2.79Z" transform="translate(-372.642 -453.443)" />
    <path id="Path_527" data-name="Path 527" d="M526.694,570.338c-.038,1.661-.139,3.3-.307,4.955a1.455,1.455,0,0,0,1.447,1.447,1.485,1.485,0,0,0,1.447-1.447c.168-1.653.269-3.294.307-4.955C529.629,568.474,526.734,568.474,526.694,570.338Z" transform="translate(-403.132 -476.251)" />
    <path id="Path_528" data-name="Path 528" d="M521.384,531.338a2.746,2.746,0,0,0,.356.026c.1,0,.052,0,.081.038s.029.11,0,0c.023.136.081.272.107.411-.046-.252,0,.208,0,.22v.475c0,.37-.02.741-.023,1.112a1.448,1.448,0,0,0,2.894,0,8.861,8.861,0,0,0-.252-3.114,3.246,3.246,0,0,0-3.184-2.046c-1.861-.058-1.861,2.836,0,2.894Z" transform="translate(-398.574 -447.486)" />
    <path id="Path_529" data-name="Path 529" d="M518.655,591.42l1.989-.981a1.46,1.46,0,0,0-.344-2.651l-.376-.058a1.484,1.484,0,0,0-1.78,1.01,1.463,1.463,0,0,0,1.01,1.78l.376.061-.347-2.646-1.986.981a1.447,1.447,0,0,0-.521,1.98,1.482,1.482,0,0,0,1.98.518Z" transform="translate(-396.094 -489.585)" />
    <path id="Path_530" data-name="Path 530" d="M394.8,586.792l26.681,6.553c1.308.321,2.576-1.447,1.41-2.417l-.634-.533c-1.418-1.193-3.473.845-2.046,2.046l.634.533,1.407-2.42L395.575,584c-1.809-.446-2.582,2.344-.77,2.79Z" transform="translate(-308.905 -486.92)" />
    <path id="Path_531" data-name="Path 531" d="M431.508,601.953c.883-1.54.819-3.7-.886-4.631a1.447,1.447,0,0,0-1.447,2.5c.179.1.09,0,.035,0,.171-.032,0,.168-.02-.069-.017-.168.02.075.026.11.041.217-.014-.347,0,.046,0,.145,0,.136.017,0-.014.084-.035.168-.058.252,0,.035-.13.359-.055.185s-.093.159-.093.159a1.447,1.447,0,1,0,2.5,1.462Z" transform="translate(-333.599 -496.309)" />
    <path id="Path_532" data-name="Path 532" d="M335.722,469.339a50.386,50.386,0,0,0-18.272,24.987c-1.957,5.818-2.524,11.89-2.732,17.986-.087,2.544-.179,5.439,1.291,7.661a5.557,5.557,0,0,0,5.644,2.2,44.4,44.4,0,0,0,9.314-2.894c3.329-1.262,6.62-2.631,9.965-3.852,1.737-.631.987-3.43-.77-2.79-5.007,1.823-9.887,4-14.973,5.609-1.676.53-4.8,1.991-6.4.579-1.737-1.525-1.23-4.955-1.158-6.984a58.655,58.655,0,0,1,2.44-16.223,49.039,49.039,0,0,1,6.174-12.539,44.863,44.863,0,0,1,10.938-11.239c1.505-1.074.061-3.586-1.447-2.5Z" transform="translate(-252.702 -405.288)" />
    <path id="Path_533" data-name="Path 533" d="M347.616,482.163a56.4,56.4,0,0,0-16.932,42.811c.09,1.852,2.984,1.864,2.894,0a53.547,53.547,0,0,1,16.084-40.765C351,482.913,348.956,480.867,347.616,482.163Z" transform="translate(-264.032 -414.297)" />
    <path id="Path_534" data-name="Path 534" d="M332.971,620.917c4.46-1.407,8.944-2.741,13.4-4.151,1.769-.559,1.013-3.355-.77-2.79-4.46,1.41-8.944,2.741-13.4,4.151C330.432,618.686,331.188,621.482,332.971,620.917Z" transform="translate(-264.44 -508.199)" />
    <path id="Path_535" data-name="Path 535" d="M414.366,598.329l1.991-.851a1.452,1.452,0,0,0,.518-1.98l-1.109-1.627a1.482,1.482,0,0,0-1.98-.518,1.459,1.459,0,0,0-.518,1.98l1.109,1.624.518-1.98-1.991.851a1.465,1.465,0,0,0-.518,1.98A1.487,1.487,0,0,0,414.366,598.329Z" transform="translate(-322.002 -493.466)" />
    <path id="Path_536" data-name="Path 536" d="M588.418,638.633c2.961-1.36,6.064-2.315,9.083-3.52,1.711-.683.964-3.473-.767-2.793-3.256,1.3-6.585,2.347-9.774,3.812-1.69.779-.223,3.274,1.447,2.5Z" transform="translate(-445.643 -521.211)" />
    <path id="Path_537" data-name="Path 537" d="M513.248,497.841a53.156,53.156,0,0,0-22.455,22.594,53.836,53.836,0,0,0-5.045,15.306,52.815,52.815,0,0,0-.822,9.717c.026,2.538.072,6.078,2.669,7.485,1.421.767,2.955.064,4.342-.411,1.647-.562,3.291-1.135,4.921-1.737,3.091-1.158,6.142-2.426,9.222-3.618,1.716-.666.97-3.473-.77-2.79-4.88,1.89-9.722,3.826-14.666,5.543-.483.168-1.3.631-1.809.385s-.695-1.314-.81-1.771a18.4,18.4,0,0,1-.208-4.373,47.809,47.809,0,0,1,.868-8.374,49.394,49.394,0,0,1,13-25.679,51.5,51.5,0,0,1,13.025-9.763c1.644-.868.182-3.375-1.462-2.5Z" transform="translate(-373.674 -425.61)" />
    <path id="Path_538" data-name="Path 538" d="M533.7,499.516a51.755,51.755,0,0,0-28.655,47.468c.043,1.858,2.941,1.867,2.894,0a48.89,48.89,0,0,1,27.208-44.959c1.673-.822.208-3.32-1.447-2.5Z" transform="translate(-387.957 -426.814)" />
    <path id="Path_539" data-name="Path 539" d="M558.145,622.415l19.682-7.656c1.716-.669.97-3.473-.77-2.79l-19.682,7.656C555.658,620.293,556.405,623.1,558.145,622.415Z" transform="translate(-424.464 -506.75)" />
    <path id="Path_540" data-name="Path 540" d="M510.235,655.84l5.016-1.951c1.719-.666.972-3.473-.767-2.79l-5.019,1.951c-1.716.669-.97,3.473.77,2.793Z" transform="translate(-390.421 -534.555)" />
    <path id="Path_541" data-name="Path 541" d="M638.858,528.264a32.242,32.242,0,0,1,7.661,13.754c.252.935.547,1.876.773,2.813.084.353.2.695-.049.961-.324.336-1.563.48-2.026.645-2.651.912-5.161,2.18-7.87,2.929a1.448,1.448,0,0,0,.77,2.79c3.323-.917,6.544-2.373,9.8-3.508,2.894-1.007,2.411-3.525,1.795-5.89a37.19,37.19,0,0,0-8.793-16.542c-1.276-1.363-3.32.686-2.046,2.046Z" transform="translate(-481.252 -445.576)" />
    <path id="Path_542" data-name="Path 542" d="M632.545,615.055c5.647-1.98,11.106-4.443,16.753-6.423,1.737-.614,1-3.41-.77-2.79-5.647,1.98-11.106,4.44-16.753,6.423C630.038,612.875,630.779,615.674,632.545,615.055Z" transform="translate(-477.318 -502.409)" />
    <path id="Path_543" data-name="Path 543" d="M658.133,525.7a29.855,29.855,0,0,1,8.327,22.649c-.1,1.609,2.538,1.994,2.842.385l.142-.755c.347-1.818-2.443-2.6-2.79-.767l-.142.753,2.842.385a32.707,32.707,0,0,0-9.175-24.7C658.883,522.318,656.837,524.367,658.133,525.7Z" transform="translate(-496.455 -443.765)" />
    <path id="Path_544" data-name="Path 544" d="M209.249,389.115a20.036,20.036,0,0,0-6.686,1.036c-1.381.48-2.894,1.311-2.628,3.028.327,2,2.7,2.211,4.307,2.454a40.673,40.673,0,0,0,12.95-.035c1.737-.272,4.127-.579,4.611-2.66.975-4.151-6.512-4.127-8.868-3.974-1.85.119-1.864,3.013,0,2.894a17.122,17.122,0,0,1,3.925.142,10.867,10.867,0,0,1,1.583.347c.706.237-.1.136-.414.174a59.882,59.882,0,0,1-7.447.718c-1.175,0-2.353-.052-3.525-.156-.593-.055-1.187-.119-1.774-.2a7.218,7.218,0,0,0-1.876-.318c-.356-.156-.535.023-.541.544.324-.119.648-.229.978-.33.509-.156,1.028-.289,1.549-.405a17.8,17.8,0,0,1,3.858-.362A1.448,1.448,0,0,0,209.249,389.115Z" transform="translate(-171.15 -348.334)" />
    <path id="Path_545" data-name="Path 545" d="M198.735,397.579l.457,9.476c.09,1.855,2.984,1.864,2.894,0l-.457-9.476C201.54,395.726,198.645,395.715,198.735,397.579Z" transform="translate(-170.318 -353.499)" />
    <path id="Path_546" data-name="Path 546" d="M200.993,430.471a21.963,21.963,0,0,0,18.869.177c1.69-.779.223-3.274-1.447-2.5a18.814,18.814,0,0,1-15.948-.177c-1.667-.808-3.138,1.687-1.462,2.5Z" transform="translate(-171.407 -375.971)" />
    <path id="Path_547" data-name="Path 547" d="M265.021,398.511a74.238,74.238,0,0,1-.162,7.485c-.133,1.858,2.761,1.85,2.894,0a74.229,74.229,0,0,0,.162-7.485C267.863,396.652,264.969,396.644,265.021,398.511Z" transform="translate(-217.301 -354.159)" />
    <path id="Path_548" data-name="Path 548" d="M135.376,383.453a61.1,61.1,0,0,0-27.071-.634,29.367,29.367,0,0,0-6.154,1.638,2.31,2.31,0,0,0-.915,3.806c1.271,1.329,4.035,1.447,5.743,1.737a55.212,55.212,0,0,0,6.866.689A54.615,54.615,0,0,0,129.567,389a1.448,1.448,0,0,0-.767-2.79,51.758,51.758,0,0,1-14.683,1.6,50.071,50.071,0,0,1-7.169-.764,22.827,22.827,0,0,0-3.534-.779c-.24.68-.153.915.26.7l.764-.229c.593-.174,1.192-.336,1.792-.489a57.779,57.779,0,0,1,28.365-.02c1.806.454,2.579-2.339.77-2.79Z" transform="translate(-100.56 -343.136)" />
    <path id="Path_549" data-name="Path 549" d="M261.859,374.684c0-.017,6.116-1.812,6.84-1.988,2.316-.564,4.669-1.042,7.033-1.4a49.355,49.355,0,0,1,14.669-.252c.44.069,1.557-.151.918.272a5.614,5.614,0,0,1-1.4.449q-1.812.544-3.647.993-3.41.834-6.886,1.346a73.806,73.806,0,0,1-14.692.619c-1.861-.1-1.855,2.793,0,2.894a77.309,77.309,0,0,0,14.927-.645,74.477,74.477,0,0,0,7.682-1.488c1.919-.478,4.727-.822,6.243-2.2a2.773,2.773,0,0,0-.721-4.53c-1.87-1.042-4.738-.9-6.813-.97a64.139,64.139,0,0,0-15.39,1.5c-2.787.579-5.554,1.305-8.281,2.136-2.11.645-4.321,2.281-2.981,4.715C260.258,377.776,262.759,376.317,261.859,374.684Z" transform="translate(-213.106 -333.302)" />
    <path id="Path_550" data-name="Path 550" d="M620.944,473.035c-2.538-.13-10.686.179-9.792,4.466.394,1.884,2.518,2.463,4.133,2.787a35.9,35.9,0,0,0,15.025-.156c1.714-.385,3.795-1.132,3.916-3.2.1-1.777-1.5-2.753-3.039-3.051a72.642,72.642,0,0,0-7.572-.695c-1.855-.159-1.844,2.735,0,2.894l4.46.4c.784.067,1.595.081,2.371.211.272.046.987.362.958.156-.041-.289-.289.035-.512.133a4.837,4.837,0,0,1-.825.249c-.7.174-1.41.315-2.122.44a32.022,32.022,0,0,1-4.729.434,24.921,24.921,0,0,1-8.556-.929c-.11-.041-.608-.182-.64-.307.069.289.237.069.4.014a21.815,21.815,0,0,1,2.165-.64,16.077,16.077,0,0,1,4.359-.316c1.861.1,1.855-2.8,0-2.894Z" transform="translate(-463.318 -408.089)" />
    <path id="Path_551" data-name="Path 551" d="M610.287,484.261l.31,8.7a1.454,1.454,0,0,0,2.842.385l.107-.368a1.449,1.449,0,0,0-2.793-.767l-.107.365,2.845.385-.31-8.7C613.115,482.4,610.221,482.394,610.287,484.261Z" transform="translate(-462.751 -415.089)" />
    <path id="Path_552" data-name="Path 552" d="M611.113,516.823a27.969,27.969,0,0,0,20.333.744c1.737-.617.993-3.415-.77-2.79a25.008,25.008,0,0,1-18.116-.454C610.867,513.6,609.391,516.094,611.113,516.823Z" transform="translate(-462.792 -437.35)" />
    <path id="Path_553" data-name="Path 553" d="M680.934,483.866c-.125,3.065-.255,6.127-.431,9.187-.107,1.861,2.787,1.855,2.894,0,.177-3.059.307-6.122.431-9.187C683.907,482,681.012,482.008,680.934,483.866Z" transform="translate(-512.642 -414.81)" />
    <path id="Path_554" data-name="Path 554" d="M550.528,466.748a63.94,63.94,0,0,0-26.571-1.207c-1.658.289-3.69.457-4.973,1.679a3.354,3.354,0,0,0-.42,4.385c.961,1.491,2.677,2,4.342,2.252a53.732,53.732,0,0,0,6.608.564,55.311,55.311,0,0,0,15.173-1.809,1.45,1.45,0,0,0-.77-2.793,52.1,52.1,0,0,1-13.586,1.719,43.905,43.905,0,0,1-7.08-.538c-.732-.127-1.916-.258-2.292-1.013-.44-.886.839-1.077,1.447-1.221a58.069,58.069,0,0,1,27.346.776,1.45,1.45,0,0,0,.77-2.793Z" transform="translate(-397.136 -402.169)" />
    <path id="Path_555" data-name="Path 555" d="M685.038,471.727c.553-1.595,3.876-1.786,5.21-2.026a48.478,48.478,0,0,1,7.114-.732,49.115,49.115,0,0,1,14.032,1.563,4.227,4.227,0,0,1,1.21.379c.388.327.2.148-.243.347a14.319,14.319,0,0,1-3.621.608c-2.478.342-4.973.579-7.47.692a91.557,91.557,0,0,1-14.637-.452c-1.85-.2-1.835,2.692,0,2.894a91.724,91.724,0,0,0,14.918.437c2.475-.133,4.944-.373,7.4-.7,1.957-.266,4.631-.252,6.078-1.806a2.8,2.8,0,0,0-.5-4.217c-1.627-1.187-4.125-1.447-6.044-1.783a52.652,52.652,0,0,0-14.078-.677,48.4,48.4,0,0,0-7.751,1.256c-1.968.507-3.719,1.447-4.417,3.456-.608,1.763,2.185,2.521,2.79.77Z" transform="translate(-513.816 -403.145)" />
    <path id="Path_556" data-name="Path 556" d="M796.674,383.313c-2.538-.133-10.686.179-9.792,4.466.394,1.884,2.521,2.463,4.133,2.787a35.842,35.842,0,0,0,15.025-.159c1.714-.382,3.8-1.129,3.916-3.184.1-1.777-1.5-2.753-3.039-3.051a72.634,72.634,0,0,0-7.572-.695c-1.855-.159-1.844,2.735,0,2.894l4.46.385c.787.067,1.595.081,2.371.211.272.046.987.362.958.156-.041-.289-.289.035-.512.133a4.759,4.759,0,0,1-.825.249c-.7.174-1.41.316-2.122.44a32.021,32.021,0,0,1-4.732.449,24.86,24.86,0,0,1-8.556-.932c-.107-.038-.608-.182-.64-.3.069.275.237.069.4.014a21.826,21.826,0,0,1,2.165-.64,16.077,16.077,0,0,1,4.359-.315c1.861.1,1.855-2.8,0-2.894Z" transform="translate(-588.184 -344.336)" />
    <path id="Path_557" data-name="Path 557" d="M786,394.576l.315,8.683a1.454,1.454,0,0,0,2.842.385l.107-.368a1.45,1.45,0,0,0-2.793-.77l-.1.368,2.842.385-.316-8.683C788.828,392.721,785.934,392.712,786,394.576Z" transform="translate(-587.603 -351.364)" />
    <path id="Path_558" data-name="Path 558" d="M786.864,427.1a27.968,27.968,0,0,0,20.345.744c1.737-.616.993-3.415-.77-2.79a25.008,25.008,0,0,1-18.1-.454c-1.693-.721-3.184,1.771-1.447,2.5Z" transform="translate(-587.686 -373.599)" />
    <path id="Path_559" data-name="Path 559" d="M856.667,394.146c-.127,3.062-.255,6.128-.434,9.187-.107,1.861,2.787,1.855,2.894,0,.179-3.059.307-6.125.434-9.187C859.637,392.285,856.742,392.288,856.667,394.146Z" transform="translate(-637.508 -351.059)" />
    <path id="Path_560" data-name="Path 560" d="M860.792,381.992c.55-1.592,3.873-1.786,5.21-2.026a48.509,48.509,0,0,1,7.115-.732,49.009,49.009,0,0,1,14.055,1.557,4.293,4.293,0,0,1,1.21.382c.388.327.2.148-.243.347a14.43,14.43,0,0,1-3.621.608c-2.478.342-4.973.564-7.47.692a91.915,91.915,0,0,1-14.637-.452c-1.85-.2-1.835,2.692,0,2.894a91.736,91.736,0,0,0,14.921.437c2.478-.133,4.944-.373,7.4-.706,1.957-.263,4.631-.249,6.078-1.8a2.8,2.8,0,0,0-.5-4.217c-1.627-1.187-4.125-1.447-6.044-1.786a52.752,52.752,0,0,0-14.093-.672,48.116,48.116,0,0,0-7.754,1.256c-1.968.507-3.719,1.447-4.417,3.456-.608,1.763,2.185,2.521,2.793.767Z" transform="translate(-638.704 -339.382)" />
    <path id="Path_561" data-name="Path 561" d="M712.307,371.983a80.752,80.752,0,0,0-30.5-3.3c-1.954.142-4.052.579-5.112,2.411a3.793,3.793,0,0,0,1.048,4.851c1.7,1.415,4.272,1.641,6.368,2.026,2.484.446,4.993.784,7.505,1.01a83.241,83.241,0,0,0,17.366-.289c1.826-.22,1.847-3.117,0-2.894a83.274,83.274,0,0,1-16,.4,77.729,77.729,0,0,1-8.394-1.068c-.906-.165-7.141-1.065-5.117-2.894.894-.813,2.564-.669,3.685-.727q2-.1,3.991-.136c2.755-.041,5.5.026,8.263.237a70.411,70.411,0,0,1,16.1,3.184c1.777.579,2.538-2.223.77-2.79Z" transform="translate(-509.595 -333.779)" />
    <path id="Path_562" data-name="Path 562" d="M405.893,297.395c.53-4.127,2.848-7.873,5.418-11.051a41.164,41.164,0,0,1,7.977-7.546,43.705,43.705,0,0,1,20.29-7.942c11.025-1.279,23.413,1.58,31.676,9.262,5.682,5.282,9.523,12.269,12.486,19.358.709,1.693,3.511.949,2.79-.77-2.894-6.947-6.5-13.725-11.757-19.187a36.47,36.47,0,0,0-14.568-9.225,47.071,47.071,0,0,0-41.3,5.253c-7.349,4.949-14.735,12.715-15.919,21.844-.234,1.841,2.66,1.823,2.894,0Z" transform="translate(-315.443 -262.235)" />
    <path id="Path_563" data-name="Path 563" d="M475.26,283.4a29.717,29.717,0,0,1,25.28-11.311c1.858.119,1.852-2.776,0-2.894a32.706,32.706,0,0,0-27.786,12.735c-1.135,1.479,1.378,2.92,2.5,1.462Z" transform="translate(-364.822 -263.215)" />
    <path id="Path_564" data-name="Path 564" d="M472.7,316.39l16.872,4.921a1.448,1.448,0,0,0,.77-2.79L473.466,313.6A1.448,1.448,0,0,0,472.7,316.39Z" transform="translate(-364.263 -294.771)" />
    <path id="Path_565" data-name="Path 565" d="M531.2,308.516a28.4,28.4,0,0,1,27.132-9.077c1.815.4,2.6-2.391.77-2.79a31.311,31.311,0,0,0-29.948,9.821C527.932,307.87,529.973,309.925,531.2,308.516Z" transform="translate(-404.833 -282.277)" />
    <path id="Path_566" data-name="Path 566" d="M379.825,354.278a30.635,30.635,0,0,0-10.605,4.591c-1.531,1.042-.084,3.551,1.462,2.5a28.476,28.476,0,0,1,9.913-4.31c1.818-.4,1.048-3.184-.77-2.79Z" transform="translate(-290.996 -323.686)" />
    <path id="Path_567" data-name="Path 567" d="M365.579,380.6c-1.013,1.2-.776,2.8-.243,4.177a5.031,5.031,0,0,0,3.557,3.007c4.874,1.546,9.867,2.784,14.788,4.171a1.45,1.45,0,0,0,.77-2.793L373.97,386.2a41.856,41.856,0,0,1-4.753-1.36,1.777,1.777,0,0,1-1.2-1.158c-.122-.324-.182-1.294-.385-1.054,1.2-1.412-.842-3.473-2.049-2.046Z" transform="translate(-288.374 -342.073)" />
    <path id="Path_568" data-name="Path 568" d="M439.1,401.229a152.193,152.193,0,0,0,27.688,6.159c5.23.674,10.75-.194,15.9-1.114a158.227,158.227,0,0,0,20.3-5.375c4.437-1.421,9.372-2.585,12.446-6.368,1.158-1.447-.868-3.494-2.046-2.046-2.454,3.022-6.151,4-9.725,5.155a181.229,181.229,0,0,1-19.569,5.424c-5.265,1.062-11.062,2.119-16.449,1.528-3.919-.428-7.815-1.247-11.679-2.052a149.061,149.061,0,0,1-16.09-4.107c-1.774-.579-2.535,2.211-.77,2.79Z" transform="translate(-340.406 -350.518)" />
    <path id="Path_569" data-name="Path 569" d="M635.123,324a15,15,0,0,1,4.3,5.181c.839,1.664,3.337.2,2.5-1.447a16.9,16.9,0,0,0-4.75-5.771,1.5,1.5,0,0,0-2.046,0,1.456,1.456,0,0,0,0,2.046Z" transform="translate(-480.102 -300.471)" />
    <path id="Path_570" data-name="Path 570" d="M657.406,353.14h-.145a1.5,1.5,0,0,0-1.025.423,1.447,1.447,0,0,0,0,2.049,1.514,1.514,0,0,0,1.025.423h.145a1.5,1.5,0,0,0,1.025-.423,1.447,1.447,0,0,0,0-2.049A1.514,1.514,0,0,0,657.406,353.14Z" transform="translate(-495.099 -322.913)" />
    <path id="Path_571" data-name="Path 571" d="M358.683,311.783a82.06,82.06,0,0,0-27.051,2.113,5.772,5.772,0,0,0-3.65,2.356,3.614,3.614,0,0,0,.8,4.4c1.357,1.3,3.34,1.473,5.114,1.685a46.978,46.978,0,0,0,6.628.313,49.039,49.039,0,0,0,14.707-2.645c1.751-.6,1-3.4-.77-2.793a45.732,45.732,0,0,1-20.565,2.232c-.993-.122-5.132-.961-2.689-2.316,1.664-.926,4.017-1.074,5.864-1.4q3.618-.648,7.274-.964a89.128,89.128,0,0,1,14.336-.078c1.858.136,1.849-2.758,0-2.894Z" transform="translate(-261.848 -293.327)" />
    <path id="Path_572" data-name="Path 572" d="M654.158,435.913c-1.841.041-4.342.214-5.543-1.511-1.279-1.855,1.2-3.221,2.544-3.991a51.5,51.5,0,0,1,11.306-4.767,53.168,53.168,0,0,1,24.568-1.2c1.821.344,2.6-2.446.77-2.793a56.238,56.238,0,0,0-30.348,2.605,55.6,55.6,0,0,0-6.889,3.155c-1.879,1.025-4.032,2.191-4.883,4.281a4.8,4.8,0,0,0,1.609,5.482c1.922,1.6,4.483,1.67,6.866,1.618,1.858-.041,1.864-2.935,0-2.894Z" transform="translate(-487.674 -370.886)" />
    <path id="Path_573" data-name="Path 573" d="M739.011,400.5a46.6,46.6,0,0,0,13.071,2.521c1.861.093,1.855-2.8,0-2.894a43.676,43.676,0,0,1-12.3-2.417C738.018,397.1,737.26,399.893,739.011,400.5Z" transform="translate(-553.512 -354.522)" />
    <path id="Path_574" data-name="Path 574" d="M678.349,358.912a15.751,15.751,0,0,1,6.4,2.958c1.447,1.135,3.511-.9,2.046-2.046a18.961,18.961,0,0,0-7.676-3.7C677.3,355.716,676.528,358.507,678.349,358.912Z" transform="translate(-510.375 -325.003)" />
    <path id="Path_575" data-name="Path 575" d="M508.466,628.676c14.715,3.763,29.4,7.63,44.128,11.326,1.806.454,2.576-2.336.77-2.79-14.733-3.7-29.416-7.575-44.131-11.337-1.806-.46-2.576,2.33-.77,2.79Z" transform="translate(-389.668 -516.67)" />
    <path id="Path_576" data-name="Path 576" d="M339.061,585.214c14.5,3.858,29.095,7.308,43.587,11.187a1.448,1.448,0,0,0,.767-2.79c-14.489-3.879-29.089-7.329-43.584-11.187A1.448,1.448,0,0,0,339.061,585.214Z" transform="translate(-269.3 -485.793)" />
    <path id="Path_577" data-name="Path 577" d="M506.333,648.863q21.022,5.355,42.036,10.724c1.806.457,2.579-2.333.77-2.793q-21.022-5.34-42.036-10.709c-1.806-.46-2.576,2.33-.77,2.79Z" transform="translate(-388.154 -531.031)" />
    <path id="Path_578" data-name="Path 578" d="M332.023,604.162c14.851,3.826,29.679,7.74,44.554,11.471,1.806.451,2.579-2.339.77-2.793-14.877-3.728-29.705-7.641-44.554-11.468A1.448,1.448,0,0,0,332.023,604.162Z" transform="translate(-264.297 -499.258)" />
    <path id="Path_579" data-name="Path 579" d="M289.3,571.112a6.525,6.525,0,0,0-1.43,7.89c.773,1.69,3.268.223,2.5-1.447a3.581,3.581,0,0,1,.978-4.382c1.407-1.227-.648-3.268-2.046-2.046Z" transform="translate(-233.146 -477.538)" />
    <path id="Path_580" data-name="Path 580" d="M290.165,594.5l5.983,1.774.784-2.8-.457-.133a1.448,1.448,0,0,0-.77,2.79l.457.133a1.448,1.448,0,0,0,.77-2.79L290.95,591.7c-1.789-.53-2.553,2.263-.77,2.793Z" transform="translate(-234.576 -492.374)" />
    <path id="Path_581" data-name="Path 581" d="M294.295,573.323l5.444,1.6.767-2.79-.787-.174c-1.815-.4-2.59,2.391-.77,2.79l.79.174a1.448,1.448,0,0,0,.767-2.79l-5.442-1.6A1.45,1.45,0,0,0,294.295,573.323Z" transform="translate(-237.498 -477.335)" />
    <path id="Path_582" data-name="Path 582" d="M659.8,650.844l-2.894,13.4c-.388,1.818,2.4,2.6,2.79.77l2.871-13.4c.391-1.818-2.4-2.6-2.79-.77Z" transform="translate(-495.853 -533.706)" />
    <path id="Path_583" data-name="Path 583" d="M668.777,653.622l4.362.912c1.818.379,2.6-2.408.77-2.79l-4.365-.912c-1.818-.382-2.6,2.408-.77,2.79Z" transform="translate(-503.567 -534.415)" />
    <path id="Path_584" data-name="Path 584" d="M696.508,617.657a4.7,4.7,0,0,0,5.421-1.769,8.081,8.081,0,0,0,1.551-5.7,18.955,18.955,0,0,0-5.384-11.8l-1.022,2.478c6.078.75,10.084,6.538,10.374,12.356.327,6.6-3.184,12.446-7.751,16.912-1.334,1.3.712,3.349,2.046,2.046,5.465-5.331,9.447-12.591,8.463-20.437-.868-6.993-6-12.892-13.132-13.772-1.317-.162-1.815,1.65-1.025,2.469a16.406,16.406,0,0,1,4.263,7.87,9.381,9.381,0,0,1,.142,4.185c-.359,1.447-1.551,3.042-3.166,2.376-1.737-.706-2.475,2.093-.77,2.793Z" transform="translate(-523.338 -496.875)" />
    <path id="Path_585" data-name="Path 585" d="M657.848,700.858l7.361,1.578-1.01-1.78-1.352,6.2c-.4,1.815,2.394,2.6,2.79.77l1.357-6.2a1.467,1.467,0,0,0-1.01-1.78l-7.361-1.578c-1.818-.391-2.6,2.4-.77,2.79Z" transform="translate(-495.808 -567.978)" />
    <path id="Path_586" data-name="Path 586" d="M701.452,709.4l2.07.492.385-2.842h-.119a1.447,1.447,0,0,0,0,2.894h.119a1.451,1.451,0,0,0,.385-2.845l-2.07-.489a1.447,1.447,0,0,0-.77,2.79Z" transform="translate(-526.778 -574.047)" />
    <path id="Path_587" data-name="Path 587" d="M696.583,706.781l-1.3,6.68c-.356,1.818,2.434,2.6,2.79.767l1.3-6.683c.353-1.818-2.437-2.6-2.793-.767Z" transform="translate(-523.124 -573.449)" />
    <path id="Path_588" data-name="Path 588" d="M656.866,723.429l5.326,1.207c1.815.411,2.6-2.379.77-2.79l-5.326-1.207C655.824,720.228,655.049,723.018,656.866,723.429Z" transform="translate(-495.113 -584.013)" />
    <path id="Path_589" data-name="Path 589" d="M651.594,721.54l-1.366,8.429c-.289,1.821,2.492,2.6,2.79.767l1.366-8.426C654.674,720.486,651.89,719.7,651.594,721.54Z" transform="translate(-491.117 -583.939)" />
    <path id="Path_590" data-name="Path 590" d="M696.1,732.444l3.991.993c1.806.451,2.579-2.339.77-2.79l-3.991-1C695.064,729.2,694.291,731.992,696.1,732.444Z" transform="translate(-522.994 -590.411)" />
    <path id="Path_591" data-name="Path 591" d="M707.775,734.779l-1.812,8.489,2.842.385-.075-.579c-.234-1.821-3.132-1.844-2.894,0l.075.579c.188,1.467,2.469,2.139,2.842.385l1.812-8.492c.388-1.815-2.4-2.6-2.79-.77Z" transform="translate(-530.635 -593.345)" />
    <path id="Path_592" data-name="Path 592" d="M654.427,754.37a19.789,19.789,0,0,0,15.071,3.184c1.829-.342,1.054-3.132-.77-2.79a16.9,16.9,0,0,1-12.84-2.894c-1.528-1.074-2.975,1.447-1.462,2.5Z" transform="translate(-493.66 -606.048)" />
    <path id="Path_593" data-name="Path 593" d="M403.957,369.452l35.6,10.3c1.534.443,2.634-1.942,1.117-2.646l-1.085-.5c-1.679-.779-3.152,1.716-1.447,2.5l1.085.5,1.114-2.643-35.6-10.3a1.448,1.448,0,0,0-.77,2.79Z" transform="translate(-315.429 -332.475)" />
    <path id="Path_594" data-name="Path 594" d="M529.108,379.352a108.867,108.867,0,0,0,43.581-10.484c1.682-.8.214-3.294-1.462-2.5a105.392,105.392,0,0,1-42.12,10.087C527.247,376.513,527.241,379.407,529.108,379.352Z" transform="translate(-404.076 -332.204)" />
    <path id="Path_595" data-name="Path 595" d="M324.237,270.278a9.242,9.242,0,0,0-7.56,4.237c-1.025,1.56,1.479,3.013,2.5,1.462a6.206,6.206,0,0,1,5.062-2.8C326.1,273.121,326.1,270.226,324.237,270.278Z" transform="translate(-253.953 -264.034)" />
    <path id="Path_596" data-name="Path 596" d="M305.335,248.727a20.1,20.1,0,0,0-17.048,10.892c-.834,1.661,1.661,3.129,2.5,1.462a17.167,17.167,0,0,1,14.55-9.459C307.191,251.549,307.2,248.654,305.335,248.727Z" transform="translate(-233.832 -248.72)" />
    <path id="Path_597" data-name="Path 597" d="M835.472,350.03a9.566,9.566,0,0,0-7.525-2.362c-1.821.24-1.844,3.138,0,2.894a6.787,6.787,0,0,1,5.476,1.517c1.4,1.207,3.473-.831,2.046-2.049Z" transform="translate(-616.435 -318.975)" />
    <path id="Path_598" data-name="Path 598" d="M826.452,333.245a21.889,21.889,0,0,0-22.906-6.657c-1.771.556-1.013,3.352.77,2.793a19.025,19.025,0,0,1,20.09,5.916c1.2,1.427,3.239-.631,2.046-2.046Z" transform="translate(-599.357 -303.374)" />
  </g>
</svg>)
