import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { setRecoverPassword } from '../actions/auth'
import { setAlertModal } from '../actions/ui'
import AlertModal from '../components/AlertModal/'

import { Link } from 'react-router-dom'

import LoadingAnimation from '../components/LoadingAnimation/'

class ForgotPasswordContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
    }
  }

  handleInput = () => {
    this.setState({ email: this.refs.email.value })
  }

  /**
   * log in form submitted
   */
  onForgotSubmit = (e) => {
    e.preventDefault()

    if (this.refs.email.value.length) {
      this.props.setRecoverPassword(this.refs.email.value)
    }
  }

  render() {
    if (!this.props.userIsLoggedIn) {
      return (
        <div className="App">
          <AlertModal {...this.props} {...this.props.alertModal} />
          <LoadingAnimation
            visible={this.props.requestsInProgress.FORGOT_PASSWORD}
          />

          <main>
            <section id="login-splash">
              <div className="bg"></div>
              <div className="container">
                <div className="narrow-wrap">
                  <h1>
                    Capture the world <br />
                    from new perspectives
                  </h1>
                </div>
              </div>
            </section>

            <aside id="login-sidebar">
              <a href="/" className="site-name">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    '/assets/img/spexigeo-logo-white.png'
                  }
                  width="340"
                  alt="Spexi Geospatial"
                />
              </a>
              <form
                id="login-form"
                acceptCharset="UTF-8"
                onSubmit={this.onForgotSubmit}
              >
                <p className="intro">Reset password</p>
                <p className="error"></p>

                <input
                  type="text"
                  ref="email"
                  value={this.state.email}
                  className="form-item"
                  placeholder="Email"
                  onChange={this.handleInput}
                />

                <button className="button solid">
                  Request reset email<span className="loading"></span>
                </button>
                <p className="message">
                  Don't have an account yet? <Link to="/signup">Sign up</Link>
                </p>
              </form>
            </aside>
          </main>
        </div>
      )
    } else {
      return <Redirect to="/" />
    }
  }
}

const mapStateToProps = (state) => {
  return {
    requestsInProgress: state.request,
    alertModal: state.alertModal,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setRecoverPassword: (email) => dispatch(setRecoverPassword(email)),
    setAlertModal: (data) => dispatch(setAlertModal(data)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer)
