import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { OrganizationApi } from '../../api'
import { getCustomOrgSubDomain } from '../../helpers'
import GoogleAuth from '../GoogleAuth'
import Modal from '../Modal'
import Pricing from './Pricing'
class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      passwordTextVisibility: false,
      organization: null,
      isOrganizationLoading: false,
      isPricingModalOpen: false,
    }
  }

  componentDidMount() {
    // if this is an org sign up link, modify the signu
    let params = new URLSearchParams(window.location.search)
    const orgSubdomain = getCustomOrgSubDomain()
    const orgId = params.get('org') || orgSubdomain

    if (orgId) {
      this.setState({ isOrganizationLoading: true })
      OrganizationApi.get(orgId)
        .then((json) => {
          const organization = json.data
          this.setState({ organization, isOrganizationLoading: false })
        })
        .catch((json) => {
          console.error(`Failed to fetch information for org ID ${orgId}`)
          this.setState({ isOrganizationLoading: false })
          console.error(json)
        })
    }
  }

  handleInput = () => {
    this.setState({
      email: this.refs.email.value,
      password: this.refs.password.value,
    })
  }

  /**
   * log in form submitted
   */
  onLoginSubmit = (e) => {
    e.preventDefault()
    if (this.refs.email.value.length && this.refs.password.value.length) {
      document.getElementById('login-form').classList.add('logging-in')
      this.props.userLogin(this.refs.email.value, this.refs.password.value)
    }
  }

  render() {
    let visibilityIcon = this.state.passwordTextVisibility
      ? 'visibility'
      : 'visibility_off'

    const logoUrl =
      this.state.organization && this.state.organization.logo_url
        ? this.state.organization.logo_url
        : '/assets/img/spexigeo-logo-white.png'

    return (
      <main>
        <section id="login-splash">
          <div className="bg"></div>
          <div className="container">
            <div className="narrow-wrap" style={{ maxWidth: '700px' }}>
              <h1>
                Capture the world <br />
                from new perspectives
              </h1>
              <p style={{ fontSize: '1.4rem' }}>
                Our Flight Dashboard allows drone pilots to precisely plan
                automated drone flights to capture and create aerial panoramas,
                orbits, maps, and 3D models.
              </p>
              <p>
                <Link
                  to={`/signup${
                    this.state.organization
                      ? '?org=' + this.state.organization.domain
                      : ''
                  }`}
                  className="btn login-button sign-up white"
                >
                  Sign Up
                </Link>

                <button
                  type="button"
                  style={{ marginLeft: '1rem' }}
                  className="btn login-button sign-up white"
                  onClick={() => {
                    this.setState({
                      isPricingModalOpen: true,
                    })
                  }}
                >
                  See pricing
                </button>
              </p>
              <p
                style={{
                  fontSize: '1.1rem',
                  maxWidth: '450px',
                  margin: '4rem auto 0 auto',
                }}
              >
                Download our companion app for iOS or Android to fly and capture
                imagery with your DJI drone.
              </p>
              <p>
                <a
                  href="https://apps.apple.com/ca/app/spexi/id1455273103"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      '/assets/img/download-app-store.svg'
                    }
                    alt="Download on the App Store"
                    width="150"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.spexigeo.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img
                    style={{ margin: '-10px', marginLeft: '10px' }}
                    width="185"
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
              </p>
              <p>
                <Link
                  to={'/faqs'}
                  style={{ marginLeft: '1rem' }}
                  className="btn login-button"
                >
                  Read FAQs
                </Link>
              </p>
            </div>
          </div>
        </section>

        <Modal
          isOpen={this.state.isPricingModalOpen}
          closeHandler={() => {
            this.setState({ isPricingModalOpen: false })
          }}
          customClass="pricing-modal"
        >
          <Pricing />
        </Modal>

        <aside id="login-sidebar">
          {!this.state.isOrganizationLoading && (
            <a href="/" className="site-name">
              <img src={logoUrl} alt="Spexi Geospatial" width="340" />
            </a>
          )}
          <form
            id="login-form"
            acceptCharset="UTF-8"
            onSubmit={this.onLoginSubmit}
          >
            <p className="intro">Log in to your Spexi account</p>
            <p className="error"></p>
            <GoogleAuth
              title="Sign in with Google"
              loginWithGoogle={this.props.loginWithGoogle}
            />
            <p
              className="message"
              style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
            >
              By signing up, you agree to the{' '}
              <a href="/terms/">Terms of Service</a> and{' '}
              <a
                href="https://spexi.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              , including{' '}
              <a
                href="https://spexi.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Use
              </a>
              .
            </p>
            <br />

            {/* <a href={API_ROOT + '/v1/auth/facebook'} className="btn"><i className="fa fa-facebook" aria-hidden="true"></i> Log in with Facebook</a> */}
            <span className="separator">
              <span>OR VIA EMAIL ADDRESS</span>
            </span>
            <input
              type="text"
              ref="email"
              value={this.state.email}
              className="form-item"
              placeholder="Email"
              onChange={this.handleInput}
            />
            <div className="password-container">
              <input
                type={this.state.passwordTextVisibility ? 'text' : 'password'}
                ref="password"
                value={this.state.password}
                className="form-item"
                placeholder="Password"
                onChange={this.handleInput}
              />
              <i
                className="material-icons"
                onClick={() => {
                  let toggle = this.state.passwordTextVisibility ? false : true
                  this.setState({ passwordTextVisibility: toggle })
                }}
              >
                {visibilityIcon}
              </i>
            </div>
            <button type="submit" className="button solid">
              Log in<span className="loading"></span>
            </button>

            <p className="message">
              <Link to="/forgot-password">Forgot your password?</Link>
            </p>
            <p className="message" style={{ marginTop: '2rem' }}>
              Don't have an account yet?{' '}
              <Link
                to={`/signup${
                  this.state.organization
                    ? '?org=' + this.state.organization.domain
                    : ''
                }`}
              >
                Sign up
              </Link>
            </p>
          </form>
        </aside>
      </main>
    )
  }
}

export default Login
