import React, { Component } from 'react'
import './styles.css'
import {
  ContentState,
  Editor,
  EditorState,
  convertFromHTML,
  RichUtils,
  CompositeDecorator,
} from 'draft-js'
import '../../../node_modules/draft-js/dist/Draft.css'
import { convertToHTML } from 'draft-convert'
import { Project } from '../../api'
import { alertTypes } from '../../util/constants'

class SendToCustomerOverlay extends Component {
  constructor(props) {
    super(props)

    const findLinkEntities = (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity()
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        )
      }, callback)
    }

    const Link = (props) => {
      const { url } = props.contentState.getEntity(props.entityKey).getData()
      return <a href={url}>{props.children}</a>
    }

    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ])

    const sampleMarkup = `
        <p>Hi there,</p>
        <p>Thanks for using Spexi to showcase your project.</p>
        <p>Here is a shareable link to our media player showcasing your project:<br />
        <a href="https://projects.spexigeo.com/${
  this.props.project.slug
    ? this.props.project.slug
    : this.props.project.id
}">https://projects.spexigeo.com/${
  this.props.project.slug ? this.props.project.slug : this.props.project.id
}</a></p>

        <p>Additionally, you can embed our player directly into your webpage using the HTML code there. Find it by pressing the "Share" button at the bottom right of our player.</p>

        <p>Best regards,<br/>Spexi Team</p>
      `

    const blocksFromHTML = convertFromHTML(sampleMarkup)
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )

    this.state = {
      customerEmail: '',
      customerName: '',
      subject: `Aerial footage for "${this.props.project.name}" is ready to share`,
      message: EditorState.createWithContent(state, decorator),
      trigger_payment: true,
      sending: false,
    }

    this.handleKeyCommand = (command) => this._handleKeyCommand(command)
  }

  _handleKeyCommand(command) {
    const { message } = this.state
    const newState = RichUtils.handleKeyCommand(message, command)
    if (newState) {
      this.handleEditorChange(newState)
      return true
    }
    return false
  }

  handleChange = (event) => {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  handleEditorChange = (editorState) => {
    console.log(editorState)
    this.setState({ message: editorState })
  }

  sendProducts = () => {
    this.setState({ sending: true })
    this.props.setRequestInProgress(true, 'GLOBAL')

    const html = convertToHTML({
      styleToHTML: (style) => {
        if (style === 'BOLD') {
          return <span style={{ color: 'blue' }} />
        }
      },
      blockToHTML: (block) => {
        if (block.type === 'PARAGRAPH') {
          return <p />
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return (
            <a href={entity.data.url} style={{ color: '#6AAEC4' }}>
              {originalText}
            </a>
          )
        }
        return originalText
      },
    })(this.state.message.getCurrentContent())

    console.log(html)
    Project.sendToCustomer({ ...this.state, message: html })
      .then((response) => {
        if (response.success) {
          this.props.setIsSendingToCustomer(false)
          this.props.setAlertModal({
            message: response.data,
            type: alertTypes.success,
          })
        } else {
          this.setState({ sending: false })
        }
      })
      .catch(() => {
        this.setState({ sending: false })
      })
      .finally(() => {
        this.props.setRequestInProgress(false, 'GLOBAL')
      })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    if (this.state.trigger_payment) {
      this.props.setAlertModal({
        message:
          'Are you sure the products are ready to send to the recipient?',
        type: alertTypes.warning,
        cancellable: true,
        confirmHandler: () => {
          this.sendProducts()
        },
      })
    } else {
      this.sendProducts()
    }
  }

  render() {
    return (
      <form id="send-to-customer" onSubmit={this.handleSubmit}>
        <svg
          viewBox="0 0 74 74"
          onClick={() => this.props.setIsSendingToCustomer(false)}
          className="close-icon"
        >
          <g data-name="Group 3278">
            <path
              d="M35.417 25.604v8h-8v2.668h8v8h2.668v-8h8v-2.668h-8v-8z"
              fill="#555"
              className="plus-lines"
            />
          </g>
        </svg>

        <div className="input-form">
          <label>
            Recipient Email Address
            <input
              type="email"
              required
              name="customerEmail"
              placeholder="Recipient Email"
              value={this.state.customerEmail}
              onChange={this.handleChange}
            />
          </label>

          <label>
            Recipient Name
            <input
              type="text"
              required
              name="customerName"
              placeholder="Recipient Name"
              value={this.state.customerName}
              onChange={this.handleChange}
            />
          </label>

          <label>
            Email Subject
            <input
              type="text"
              required
              name="subject"
              placeholder="Email Subject"
              value={this.state.subject}
              onChange={this.handleChange}
            />
          </label>

          <Editor
            handleKeyCommand={this.handleKeyCommand}
            editorState={this.state.message}
            onChange={this.handleEditorChange}
          />

          <button type="submit" className="btn" disabled={this.state.sending}>
            Send email
          </button>
        </div>
      </form>
    )
  }
}

export default SendToCustomerOverlay
