import React, { Component } from 'react'
import PropTypes from 'prop-types'
import 'leaflet-draw'
import 'leaflet-draw-drag'
import centroid from '@turf/centroid'
import toGeoJSON from '@mapbox/togeojson'
import toKml from 'tokml'
import { alertTypes } from '../../util/constants'
import { latLngToH3GeoJson } from '../../util'
// import DOMParser from 'xmldom'
import { convertArea } from '@turf/helpers'


import { formatTime, getCamera } from '../../helpers'
import { MapFlightPlanParameters, terrainify, getElevationData, getTakeOffElevationData } from 'spexi-pathify'

import FlightEstimates from '../FlightEstimates/'
import SidebarHeader from '../SidebarHeader'
import Tooltip from '../Tooltip'
import './styles.css'
// const DOMParser = require('xmldom').DOMParser

class MappingFlight extends Component {
  static propTypes = {
    flightPlan: PropTypes.object.isRequired,
    flightParameters: PropTypes.object.isRequired,
    leafletMapElement: PropTypes.object.isRequired,
    drawMappingShapes: PropTypes.func.isRequired,
    drawMappingFlightLines: PropTypes.func.isRequired,
    setFlightParameters: PropTypes.func.isRequired,
    setDrawingFlightMapObjects: PropTypes.func.isRequired,
    handleFlightSubmit: PropTypes.func.isRequired,
    setProjectsMapCenter: PropTypes.func.isRequired,
    isEdittingFlightPlan: PropTypes.bool,
    flightEstimatesData: PropTypes.object,
    drawingFlightMapObjects: PropTypes.object,
    droneList: PropTypes.array.isRequired,
    setDroneCamera: PropTypes.func.isRequired,
    setFlightPlanName: PropTypes.func.isRequired,
    viewportCentre: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isImportExportOpen: false,
      flightPlanName: 'Mapping Flight',
      selectedDrone: null,
    }
  }

  toggleImportExport = () => {
    let toggle
    toggle = this.state.isImportExportOpen ? false : true
    this.setState({ isImportExportOpen: toggle })
  }

  componentDidMount() {
    // if the drone model name exists in the flight plan, match it up to the selected drone
    let drone = null
    if (this.props.flightPlan.drone) {
      drone = this.props.droneList.find(elem => elem.model === this.props.flightPlan.drone)
      if (drone) {
        this.setState({
          selectedDrone: drone
        });
      }
    }
    // when editing a flight plan, set camera to the one on the flight plan in the db
    if (this.props.flightPlan.camera) {
      let camera = getCamera(this.props.droneList, this.props.flightPlan.camera)
      this.props.setDroneCamera(camera)
    } else if (this.props.flightPlan.camera === null || this.props.flightPlan.camera === undefined) {
      // otherwise, use user's primary equipment for flight planning
      if (this.props.user.primaryEquipment) {
        drone = this.props.droneList.find(drone => drone.id === this.props.user.primaryEquipment.drone.id)
        let camera = { ...drone.camera, focalLength: drone.camera.focal_length, drone: drone.model }
        this.props.setDroneCamera(camera)
      } else {
        // if all else fails, default to phantom 4 pro (first in list)
        drone = this.props.droneList[0]
        let camera = { ...drone.camera, focalLength: drone.camera.focal_length, drone: drone.model }
        this.props.setDroneCamera(camera)
      }
      this.setState({
        selectedDrone: drone
      });
    }


    if (this.props.leafletMapElement && !this.props.isEdittingFlightPlan) {
      this.props.drawMappingShapes()
    }

    if (this.props.leafletMapElement && this.props.isEdittingFlightPlan) {
      this.props.drawMappingShapes(this.props.flightPlan.geojson)
    }
    if (this.props.isEdittingFlightPlan) {
      if (this.props.flightPlan.name) {
        this.setState({ flightPlanName: this.props.flightPlan.name })
        this.props.setFlightPlanName(this.props.flightPlan.name)
      }

    }
  }

  componentWillUnmount() {
    this.props.setDroneCamera(null)
    this.props.setFlightPlanDataSuccess({})
  }

  handleKMLimport = event => {
    let file = event.target.files[0]

    let reader = new FileReader()

    reader.addEventListener('load', event => {
      let file = event.target.result

      if (file) {
        let geojson = toGeoJSON.kml(new DOMParser().parseFromString(file, 'text/xml'))
        if (geojson.type === 'FeatureCollection') {
          let boundaryCentroid
          try {
            boundaryCentroid = centroid(geojson)

          } catch (error) {
            this.props.setAlertModal({
              message: 'Unable to process KML file structure. Please make sure your KML file is valid.',
              type: alertTypes.error
            })
          }
          if (boundaryCentroid) {
            this.props.drawMappingShapes(geojson.features[0])
            const position = [boundaryCentroid.geometry.coordinates[1], boundaryCentroid.geometry.coordinates[0]]
            this.props.setProjectsMapCenter(position)
          }
        } else {
          this.props.setAlertModal({
            message: 'Unable to process KML file structure. Please make sure your KML file is valid.',
            type: alertTypes.error
          })
        }
      }
    }, false)
    reader.readAsText(file)
  }


  handleChange = (event) => {
    if (event.target.name === 'bearing') {
      this.props.setFlightParameters({
        bearing: {
          mode: 'relative',
          value: parseInt(event.target.value, 10)
        }
      })
    } else if (event.target.name === 'camera') {
      const drone = this.props.droneList.find(elem => elem.id === parseInt(event.target.value))
      const camera = { ...drone.camera, focalLength: drone.camera.focal_length, drone: drone.model }
      this.props.setDroneCamera(camera)
      this.props.setFlightParameters({ camera_id: drone.camera.id })
      this.setState({
        selectedDrone: drone
      });
    } else if (event.target.name === 'name') {
      this.setState({ flightPlanName: event.target.value })
      this.props.setFlightPlanName(event.target.value)
    } else {
      if (event.target.type === 'checkbox') {
        if (event.target.checked) {
          // only allow either isAdvancedMapParams or isTerrainAwareMission
          const { isAdvancedMapParams, isTerrainAwareMission } = this.props.flightParameters
          if (event.target.name === 'isAdvancedMapParams') {
            if (isTerrainAwareMission) {
              this.alertParamsWarning(
                'Since Terrain Awareness is already enabled Advanced Take Off Settings cannot be enabled. Terrain awareness already accounts for the take off location above sea level. Therefore Advanced Take Off Settings are not needed. Please disable Terrain Awareness if you wish to use this feature.'
              )
              return
            }
            // feature successfully enabled but warn user about potential negative altitude causing a crash
            this.alertParamsWarning(
              'This feature allows the drone to fly to an altitude lower than the take off altitude. To avoid a potential crash, first manually fly the drone over the location of the first waypoint. Then initiate the mission.'
            )
          } else if (event.target.name === 'isTerrainAwareMission') {
            if (isAdvancedMapParams) {
              this.alertParamsWarning(
                'Terrain Awareness cannot be enabled along with Advanced Take Off Settings. Please disable Advanced Take Off Settings before enabling this feature. Terrain Awareness requires the altitude of a specific GPS location in order to work. Further adjusting the take off altitude with Advanced Take Off Settings would have undesired effects.'
              )
              return
            }
            // set takeOffCoordinate as viewportCentre by default
            const takeOffCoordinate = event.target.checked
              ? { latitude: this.props.viewportCentre[0], longitude: this.props.viewportCentre[1] }
              : null
            this.props.setFlightParameters({ takeOffCoordinate })
          }
        }

        if(event.target.checked) {
          // convert to h3 hexagon by using the default drawn polygon centroid to find h3Index
          let currentPolygon = this.props.drawingFlightMapObjects.shape.getBounds().getCenter()
          let h3Polygon = latLngToH3GeoJson([currentPolygon.lat, currentPolygon.lng])
          if (event.target.name === 'isH3Polygon') {
            this.props.drawMappingShapes(h3Polygon)
          }
        } else {
          if (event.target.name === 'isH3Polygon') {
            this.props.drawMappingShapes()
          }
        }
        this.props.setFlightParameters({ [event.target.name]: event.target.checked })
      } else {
        this.props.setFlightParameters({ [event.target.name]: isNaN(event.target.value) ? event.target.value : Number(event.target.value) })
      }
    }

    setTimeout(() => {
      this.props.resetFlightMapObjects()
    }, 10)
  }

  alertParamsWarning(message) {
    this.props.setAlertModal({
      message,
      type: alertTypes.warning,
      confirmLabel: 'Okay',
      confirmHandler: () => {
      },
      cancellable: false
    })
  }


  handleSubmit = (event) => {
    event.preventDefault()
    this.props.setDrawingFlightMapObjects({ line: null, startMarker: null, endMarker: null, centerMarker: null, takeOffLocationMarker: null })
    this.props.drawMappingFlightLines(this.props.drawingFlightMapObjects.shape.toGeoJSON(8), this.props.flightParameters)
    this.props.handleFlightSubmit()
  }


  handleDelete = (event) => {
    event.preventDefault()
    this.props.deleteFlightPlan(this.props.flightPlan)
  }

  showTerrainProfile = () => this.props.setIsShowingTerrainProfile(true)

  /**
   * Export 3D flight lines of terrain aware flight plan in KML format
   * Can be used to view flight lines in Google Earth
   */
  handle3DFlightLineExport = async () => {

    try {
      if (!this.props.flightPlan?.parameters?.isTerrainAwareMission ||
        !this.props.flightPlan?.parameters?.takeOffCoordinate
      ) {
        alert('Error Exporting KML - Flight is not terrain aware!')
        return
      }
      const jwt = localStorage.getItem('jwt-token')
      const takeOffElvData = await getTakeOffElevationData(jwt, this.props.flightPlan)
      const elevations = await getElevationData(jwt, this.props.flightEstimatesData)
      const terrainAwareMission = await terrainify(
        this.props.flightEstimatesData, // pathify flightPath
        this.props.flightPlan,
        {
          takeOffElevation: takeOffElvData.results[0].elevation,
          elevations
        }
      )
      const geoJson = terrainAwareMission?.path
      if (!geoJson) {
        alert('Error Exporting KML - No GeoJson found!')
        return
      }
      // convert to kml
      let kml = toKml(geoJson, {
        documentName: `${this.props.project.name}: Map Flight Lines`
      })
      // parse kml as xml
      let xmlDoc = new DOMParser().parseFromString(kml, 'text/xml')
      // Google Earth ignores altitude values without <altitudeMode />
      let newEle = xmlDoc.createElement('altitudeMode')
      // interpret altitude as values above sea level
      let newText = xmlDoc.createTextNode('absolute')
      newEle.appendChild(newText)
      // // <altitudeMode /> must be a child of the LineString node
      xmlDoc.getElementsByTagName('LineString')[0].appendChild(newEle)

      let flightLinesKMLData = new XMLSerializer().serializeToString(xmlDoc.documentElement)
      flightLinesKMLData = "text/xml;charset=utf-8," + encodeURIComponent(flightLinesKMLData)
      // trick to download file in browser
      var a = document.createElement('a')
      a.href = 'data:' + flightLinesKMLData
      console.log(a.href)
      a.download = '3d-mapping-flight-lines.kml'
      document.body.appendChild(a)
      a.click()
      a.remove()
    } catch (e) {
      alert(e)
    }
  }

  /**
   * Export flight line of flight plan in KML format
   */
  handleFlightLineExport = event => {
    let flightLinesKML = toKml(this.props.drawingFlightMapObjects.line.toGeoJSON(8), {
      documentName: `${this.props.project.name}: Map Flight Lines`
    })

    let flightLinesKMLData = "text/xml;charset=utf-8," + encodeURIComponent(flightLinesKML);


    // trick to download file in browser
    var a = document.createElement('a')
    a.href = 'data:' + flightLinesKMLData
    console.log(a.href)
    a.download = 'mapping-flight-lines.kml'
    document.body.appendChild(a)
    a.click()
    a.remove()
  }


  /**
   * Export polygon of flight plan in KML format (flight lines not included)
   */
  handleFlightPolygonExport = event => {
    let flightPolygonKML = toKml(this.props.flightPlan.geojson, {
      documentName: `${this.props.project.name}: Map Flight Polygon`
    })

    let flightPolygonKMLData = "text/xml;charset=utf-8," + encodeURIComponent(flightPolygonKML)


    // trick to download file in browser
    var a = document.createElement('a')
    a.href = 'data:' + flightPolygonKMLData
    console.log(a.href)
    a.download = 'mapping-flight-polygon.kml'
    document.body.appendChild(a)
    a.click()
    a.remove()
  }


  render() {
    let formTitle
    if (this.props.isEdittingFlightPlan) {
      formTitle = 'Edit Mapping Flight'
    } else {
      formTitle = 'Create Mapping Flight'
    }

    let sidebarHeader
    if (this.props.isEdittingProject || this.props.isAddingProject) {
      sidebarHeader = <SidebarHeader title={formTitle} backFunction={this.props.backFromFlightType} />
    } else {
      sidebarHeader = <SidebarHeader title={formTitle} backLink={`/project/${this.props.match.params.id}/plan`} backFunction={this.props.backFromFlightType} />
    }

    let altitude = this.props.flightParameters.altitude || 50.0
    let gsd = MapFlightPlanParameters.altitudeToGsd(altitude, this.props.droneCamera)
    const selectedDrone = this.state.selectedDrone || this.props.droneList[0]
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          {sidebarHeader}

          <label>Flight Plan Name
            <input required type="text" value={this.state.flightPlanName} onChange={this.handleChange} placeholder={`${this.props.flightPlan.name}`} name="name" />
          </label>
          {this.props.flightEstimatesData && this.props.flightEstimatesData.meta && (
            <FlightEstimates {...this.props}
              minutes={formatTime(this.props.flightEstimatesData.meta.duration)}
              acres={this.props.flightEstimatesData.meta.acres && (convertArea(this.props.flightEstimatesData.meta.acres, 'metres', 'acres').toFixed(1))}
              photoCount={this.props.flightEstimatesData.meta.photoCount}
              batteries={this.props.flightEstimatesData.meta.batteries} />
          )}

          <label htmlFor="flightEquipment">Flight Equipment
            <select required value={selectedDrone.id} onChange={this.handleChange} name="camera">
              {this.props.droneList && (this.props.droneList.map(drone => {
                return <option key={drone.id} value={drone.id}>{drone.manufacturer} {drone.model}</option>
              }))}
            </select>
          </label>

          {!this.props.flightParameters.isAdvancedMapParams && (
            <label>Altitude{' '}
              <Tooltip
                tip="This option represents the flying height of the drone relative to the take off location. It is also used to calculate the flight lines and photo intervals captured by the drone."
                type="light"
              />
              <span className="currentValue">{altitude} m ({(gsd * 100).toFixed(1)} cm/px)</span>
              <input type="range" min="10" max="500" step="2" value={altitude} name="altitude" onChange={this.handleChange} />
            </label>
          )}

          <label>Front Overlap<span className="currentValue">{Math.round(this.props.flightParameters.flap * 100)}%</span>
            <input type="range" min="0" max=".95" step="0.01" value={this.props.flightParameters.flap} name="flap" onChange={this.handleChange} />
          </label>

          <label>Side Overlap<span className="currentValue">{Math.round(this.props.flightParameters.slap * 100)}%</span>
            <input type="range" min="0" max=".95" step="0.01" value={this.props.flightParameters.slap} name="slap" onChange={this.handleChange} />
          </label>

          <label>Flight Direction<span className="currentValue">{this.props.flightParameters.bearing.value}°</span>
            <input type="range" min="-180" max="180" value={this.props.flightParameters.bearing.value} name="bearing" onChange={this.handleChange} />
          </label>

          <label>Speed<span className="currentValue">{this.props.flightParameters.speed} m/s</span>
            <input type="range" min="2.0" max="15.0" value={this.props.flightParameters.speed} name="speed" onChange={this.handleChange} />
          </label>

          <label>Gimbal Pitch<span className="currentValue">{this.props.flightParameters.gimbalPitch}°</span>
            <input type="range" min="-90" max="-45" value={this.props.flightParameters.gimbalPitch} name="gimbalPitch" onChange={this.handleChange} />
          </label>

          <div className="checkbox-settings-container">
            <label>
              <input type="checkbox" checked={this.props.flightParameters.crosshatch} name="crosshatch" onChange={this.handleChange} />
              Cross-hatch
            </label>

            <label>
              <input type="checkbox" checked={this.props.flightParameters.isAdvancedMapParams} name="isAdvancedMapParams" onChange={this.handleChange} />
              Advanced Take Off Settings
            </label>
          </div>

          {this.props.flightParameters.isAdvancedMapParams && (
            <div>
              <label>Height Above Project{' '}
                <Tooltip
                  tip="In advanced take off, this option represents the height of the drone above the flight plan. It is used to calculate the flight lines and photo intervals captured by the drone. Use Altitude to control the flying height of the drone"
                  type="light"
                />
                <span className="currentValue">{altitude} m ({(gsd * 100).toFixed(1)} cm/px)</span>
                <input type="range" min="10" max="400" step="2" value={altitude} name="altitude" onChange={this.handleChange} />
              </label>
              <label>Altitude{' '}
                <Tooltip
                  tip="In advanced take off, this option represents the flying height of the drone relative to the take off location. It is NOT used to calculate flight lines or photo intervals. That is controlled by the Height Above Project parameter in Advanced take off."
                  type="light"
                />
                <span className="currentValue">{this.props.flightParameters.flyingHeight} m</span>
                <input type="range" min="-200" max="400" step="2" value={this.props.flightParameters.flyingHeight} name="flyingHeight" onChange={this.handleChange} />
              </label>
            </div>
          )}

          <div className="checkbox-settings-container">
            <label>
              <input type="checkbox" checked={this.props.flightParameters.isTerrainAwareMission} name="isTerrainAwareMission" onChange={this.handleChange} />
              Terrain Awareness{' '}
              <Tooltip
                tip="In this mode the drone continually alters flying height to maintain a constant ground sampling distance above the mission."
                type="light"
              />
            </label>

            {this.props.flightParameters.isTerrainAwareMission && (
              <div>
                <label style={{ marginTop: '5px', marginBottom: '5px' }}>
                  View Terrain Profile
                  <button type="button"
                    style={{ marginTop: '5px', fontSize: '12px', display: 'flex', padding: '4px 10px', borderRadius: '4px', cursor: 'pointer' }}
                    onClick={this.showTerrainProfile}>
                    Terrain Profile
                  </button>
                </label>

                <label style={{ marginTop: '5px', marginBottom: '5px' }}>
                  Export 3D Flight Lines
                  <button type="button"
                    style={{ marginTop: '5px', fontSize: '12px', display: 'flex', padding: '4px 10px', borderRadius: '4px', cursor: 'pointer' }}
                    onClick={this.handle3DFlightLineExport}>
                    Export as KML
                  </button>
                </label>
              </div>
            )}


            <label>
              <input type="checkbox" checked={this.props.flightParameters.isH3Polgyon} name="isH3Polygon" onChange={this.handleChange} />
              Convert Polygon to H3 Hexagon{' '}
              <Tooltip
                tip="This will convert your current mapping flight shape to the equivalent H3 hexagon (9 resolution) based on your shape's centroid"
                type="light"
              />
            </label>
          </div>

          <div className={'import-export-settings' + (this.state.isImportExportOpen ? ' open' : '')}>
            <h4 className="toggle-settings" onClick={this.toggleImportExport}>Import/Export Options</h4>
            <div className="import-export-settings__options">
              <label htmlFor="KMLimport">Import Shape from KML file
                <input type="file" name="KMLimport" accept="application/vnd.google-earth.kml+xml"
                  onChange={(event) => { this.handleKMLimport(event) }}
                  onClick={(event) => { event.target.value = null }} />
              </label>

              <label>
                Export Flight Line
                <button type="button"
                  style={{ marginTop: '5px', fontSize: '12px', display: 'flex', padding: '4px 10px', borderRadius: '4px', cursor: 'pointer' }}
                  onClick={this.handleFlightLineExport}>Export as KML</button>
              </label>


              <label style={{ marginTop: '10px' }}>
                Export Flight Polygon
                <button type="button"
                  style={{ marginTop: '5px', fontSize: '12px', display: 'flex', padding: '4px 10px', borderRadius: '4px', cursor: 'pointer' }}
                  onClick={this.handleFlightPolygonExport}>Export as KML</button>
              </label>


            </div>
          </div>

          <input type="submit" className="button solid" value="Save Flight Plan" />

          {this.props.isEdittingFlightPlan && (
            <span style={{
              width: '100%',
              textAlign: 'center',
              textTransform: 'uppercase',
              fontSize: '.8rem',
              marginTop: '1rem',
              float: 'left',
              cursor: 'pointer'
            }} onClick={this.handleDelete}>Delete Flight Plan</span>
          )}

        </form>

      </div>
    )
  }
}

export default MappingFlight