import React, { useEffect, useState } from 'react'
import { Chip, TablePagination } from '@material-ui/core'
import MaterialTable from 'material-table'
import './styles.css'
import {
  getStripePlanDescription,
  formatDate,
  formatDateTime,
} from '../../helpers'
import UserCard from '../UserCard'
import AdminSubscriptionsDialog from '../AdminSubscriptionsDialog'
import { connect } from 'react-redux'
import { Subscriptions } from '../../api'
import { alertTypes } from '../../util/constants'

const AdminSubscriptions = ({ setAlertModal, subscriptionPlans }) => {
  const [openSubscriptionsDialog, setOpenSubscriptionsDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [subscriptionUsers, setSubscriptionUsers] = useState([])
  const [page, setPage] = useState(0)
  const [rowCount, setRowCount] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [currentUser, setCurrentUser] = useState(null)
  const [selectedRow, setSelectedRow] = useState(null)

  const columns = [
    {
      title: 'User',
      field: 'name',
      render: (rowData) => `${rowData.name} (${rowData.email})`,
    },
    {
      title: 'Plan type',
      field: 'plan_type',
      render: (rowData) => `${getStripePlanType(rowData.stripe_price)}`,
      customSort: (a, b) =>
        getStripePlanType(a.stripe_price).localeCompare(
          getStripePlanType(b.stripe_price)
        ),
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: 'Plan description',
      field: 'plan_description',
      render: (rowData) =>
        `${getStripePlanDescription(rowData.stripe_price, subscriptionPlans)}`,
      customSort: (a, b) =>
        getStripePlanDescription(
          a.stripe_price,
          subscriptionPlans
        ).localeCompare(
          getStripePlanDescription(b.stripe_price, subscriptionPlans)
        ),
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: 'Status',
      field: 'status',
      render: (rowData) => `${rowData.stripe_status}`,
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: 'Images Uplaoded',
      field: 'profile',
      render: (rowData) =>
        `${
          rowData.subscriptionPeriodUploadLimitStat
            ? rowData.subscriptionPeriodUploadLimitStat.currentTotal
            : '-'
        }`,
    },
    {
      title: 'Current period start',
      field: 'current_period_start',
      render: (rowData) =>
        `${
          rowData.subscriptionPeriodUploadLimitStat
            ? formatDateTime(
              rowData.subscriptionPeriodUploadLimitStat.period
                .current_period_start
            )
            : '-'
        }`,
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      title: 'Current period end',
      field: 'current_period_end',
      render: (rowData) =>
        `${
          rowData.subscriptionPeriodUploadLimitStat
            ? formatDateTime(
              rowData.subscriptionPeriodUploadLimitStat.period
                .current_period_end
            )
            : '-'
        }`,
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ]

  useEffect(() => {
    getSubscriptionUsers()
  }, [rowsPerPage]) // eslint-disable-line react-hooks/exhaustive-deps

  const getSubscriptionUsers = async () => {
    setIsLoading(true)
    const response = await Subscriptions.getAllSubscriptionUsers(
      `paginate=${rowsPerPage}`
    )
    if (response && response.success) {
      setRowCount(response.paginate.total)
      setSubscriptionUsers(response.data)
      setIsLoading(false)
    } else {
      setIsLoading(false)
      setAlertModal({
        message: response?.error || 'an error has occured',
        type: alertTypes.error,
      })
    }
  }

  const fetchDetailedSubscriptionData = async (rowData) => {
    let res = await Subscriptions.getUserSubscriptions(rowData.id)
    if (res && res.success) {
      let modified = subscriptionUsers.map((x) => {
        if (x.id === rowData.id) {
          return { ...x, subscriptions: res.data }
        } else {
          return x
        }
      })
      setSubscriptionUsers(modified)
      return true
    }
  }

  const changePage = async (page) => {
    setIsLoading(true)
    const query = `page=${page+1}&paginate=${rowsPerPage}`
    const response = await Subscriptions.getAllSubscriptionUsers(query)
    if (response && response.success) {
      setSubscriptionUsers(response.data)
    }
    setPage(page)
    setIsLoading(false)
  }

  const getStripePlanType = (stripePlan) =>
    subscriptionPlans[stripePlan]?.metadata.plan_type || 'legacy'

  const renderSubscription = (subscriptions) => {
    const ret = subscriptions.map((subscription) => {
      return (
        <div
          className="admin-subscriptions__subscription-detail"
          key={`subscription-${subscription.id}`}
        >
          <div className="admin-subscriptions__subscription-detail-subtitle">
            {`${getStripePlanDescription(
              subscription.stripe_price,
              subscriptionPlans
            )} plan`}
            {subscription.stripe_status === 'active' &&
              subscription.ends_at && (
              <Chip
                className="admin-subscriptions__chip-warning"
                label={`ends on : ${formatDate(subscription.ends_at)}`}
                variant="outlined"
                size="small"
              />
            )}
          </div>
          <ul className="admin-subscriptions__subscription-detail-list">
            <li>
              <strong>Plan type: </strong>
              {getStripePlanType(subscription.stripe_price)}
            </li>
            <li>
              <strong>Plan description: </strong>
              {getStripePlanDescription(
                subscription.stripe_price,
                subscriptionPlans
              )}
            </li>
            <li>
              <strong>Plan id: </strong>
              {subscription.stripe_price}
            </li>
            <li>
              <strong>Status: </strong>
              {subscription.stripe_status}
            </li>
            <li>
              <strong>Created on: </strong>
              {formatDateTime(subscription.created_at)}
            </li>
            <li>
              <strong>Plan ends on: </strong>
              {formatDateTime(subscription.ends_at)}
            </li>
            <li>
              <strong>Last updated: </strong>
              {formatDateTime(subscription.updated_at)}
            </li>
            <li>
              <strong>Images Uploaded: </strong>
              {subscription.images_uploaded}
            </li>
          </ul>
        </div>
      )
    })

    return ret
  }

  return (
    <section className="admin-subscriptions">
      <AdminSubscriptionsDialog
        setAlertModal={setAlertModal}
        currentUser={currentUser}
        open={openSubscriptionsDialog}
        getSubscriptionUsers={getSubscriptionUsers}
        onClose={() => {
          setOpenSubscriptionsDialog(false)
          setCurrentUser(null)
        }}
      />
      <MaterialTable
        title="Subscriptions"
        columns={columns}
        data={subscriptionUsers}
        isLoading={isLoading}
        options={{
          actionsColumnIndex: -1,
          paging: false,
          exportButton: false,
          emptyRowsWhenPaging: false,
          rowStyle: (rowData) => {
            let selected =
              selectedRow && selectedRow === rowData.tableData.id + 1
            return {
              opacity: selected ? '0.4' : '1',
            }
          },
        }}
        actions={[
          {
            onClick: (rowData) => {
              setCurrentUser(rowData)
              setOpenSubscriptionsDialog(true)
            },
          },
        ]}
        localization={{
          header: {
            actions: '',
          },
        }}
        components={{
          Action: (props) => (
            <button
              type="button"
              id="modify"
              className="btn-primary-solid admin-subscriptions__btn-small"
              style={{ marginRight: '1rem' }}
              onClick={(event) => {
                props.action.onClick(props.data)
                event.stopPropagation()
              }}
            >
              Modify
            </button>
          ),
          Toolbar: (props) => (
            <div className="admin-subscriptions__table-toolbar">
              <h1 className="sidebar-header__title">{props.title}</h1>
              <button
                type="button"
                className="btn-primary-white admin-subscriptions__btn"
                onClick={() => {
                  setCurrentUser(null)
                  setOpenSubscriptionsDialog(true)
                }}
              >
                Add new subscription
              </button>
            </div>
          ),
        }}
        onRowClick={(event, rowData, togglePanel) => {
          setSelectedRow(rowData.tableData.id + 1)
          const user = subscriptionUsers.find((x) => x.id === rowData.id)
          if (!user.subscriptions) {
            const ret = fetchDetailedSubscriptionData(rowData)
            ret.then(() => {
              setSelectedRow(null)
              togglePanel()
            })
          } else {
            setSelectedRow(null)
            togglePanel()
          }
        }}
        detailPanel={[
          {
            disabled: true,
            render: (rowData) => {
              const activeSubscriptions = rowData.subscriptions
                .map((subscription) => {
                  if (subscription.stripe_status === 'active') {
                    return subscription
                  } else {
                    return null
                  }
                })
                .filter((item) => item)

              const pastSubscriptions = rowData.subscriptions
                .map((subscription) => {
                  if (subscription.stripe_status === 'canceled') {
                    return subscription
                  } else {
                    return null
                  }
                })
                .filter((item) => item)

              return (
                <section className="admin-subscriptions__details">
                  <div className="admin-subscriptions__subscription-details">
                    <h3>Subscription History</h3>

                    {activeSubscriptions.length > 0 ? (
                      <>
                        <h2 className="admin-subscriptions__subscription-detail-title">
                          Active Subscription
                        </h2>
                        {renderSubscription(activeSubscriptions)}
                      </>
                    ) : (
                      <></>
                    )}

                    {pastSubscriptions.length ? (
                      <>
                        <h2 className="admin-subscriptions__subscription-detail-title">
                          Past Subscriptions
                        </h2>
                        {renderSubscription(pastSubscriptions)}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <UserCard userId={rowData.id} isAdmin={true} />
                  </div>
                </section>
              )
            },
          },
        ]}
      />
      {!isLoading && rowCount && rowsPerPage && (
        <TablePagination
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={(event, page) => {
            changePage(page)
          }}
          onChangeRowsPerPage={(event) => {
            setRowsPerPage(event.target.value)
            setPage(0)
          }}
          rowsPerPageOptions={[5, 10, 20]}
          style={{ float: 'right', borderBottom: 'none' }}
        ></TablePagination>
      )}
    </section>
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionPlans: state.subscriptionPlans,
  }
}

export default connect(mapStateToProps)(React.memo(AdminSubscriptions))
