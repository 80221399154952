import React from 'react'
import './styles.css'
export default function Pricing() {
  return (
    <div id="pricing">
      <section className="panel basic-page" style={{ paddingBottom: 0 }}>
        <div className="container">
          <div className="narrow-wrap-700">
            <p className="intro">
              Choose a plan that suits your needs. We offer a monthly
              subscription plan or pay-as-you-go credits per 100 images.
            </p>
          </div>
        </div>
      </section>

      <section className="panel basic-page pricing" style={{ paddingTop: 0 }}>
        <div className="container">
          <div className="inner-wrap" style={{ maxWidth: '900px' }}>
            <div className="col-half">
              <div className="package-box">
                <h2>Subscriptions</h2>
                <p>Lower cost for higher volume processing</p>
                <ul>
                  <li className="price-row">
                    <strong>$300</strong>* per month
                  </li>
                  <li>Up to 3,000 images per month</li>
                </ul>
                <p style={{ fontSize: '0.9rem', color: '#666' }}>
                  * Billed annually. $375 month to month.
                </p>
                <p>
                  <a className="btn" href="/signup">
                    Register to start
                  </a>
                </p>
              </div>
            </div>

            <div className="col-half">
              <div className="package-box">
                <h2>Credits</h2>
                <p>Process products on demand</p>
                <ul>
                  <li className="price-row">
                    <strong>$15</strong> per credit
                  </li>
                  <li>5 free credits to start</li>
                </ul>
                <p style={{ fontSize: '0.9rem', color: '#666' }}>
                  100 uploaded images per 1 credit.
                </p>
                <p>
                  <a className="btn" href="/signup">
                    Register to start
                  </a>
                </p>
              </div>
            </div>
          </div>
          <p style={{ textAlign: 'center', marginBottom: '2rem' }}>
            <a
              href="https://spexi.com/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us
            </a>{' '}
            for higher volume enterprise-level subscription options.
          </p>
        </div>
      </section>
    </div>
  )
}
