import React from 'react'
import PropTypes from 'prop-types'

const MapLocationIcon = (props) => {
  const { width = 10.357, height = 14.796 } = props
  return (
    <svg viewBox="0 0 10.357 14.796" className="location-icon" width={width} height={height}>
      <g data-name="Group 2339">
        <path
          data-name="Path 313"
          d="M5.27 2.79a2.726 2.726 0 1 1-2.726 2.726A2.726 2.726 0 0 1 5.27 2.79z"
          fill="#fff"
        />
        <path
          d="M5.179 0A5.175 5.175 0 0 0 0 5.179c0 3.884 5.179 9.617 5.179 9.617s5.179-5.733 5.179-9.617A5.175 5.175 0 0 0 5.179 0zm0 7.028a1.85 1.85 0 1 1 1.85-1.85 1.85 1.85 0 0 1-1.85 1.85z"
          fill="#426288"
          data-name="Group 760"
        />
      </g>
    </svg>
  )
}

MapLocationIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

export default MapLocationIcon