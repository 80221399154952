import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import LoadingAnimation from '../LoadingAnimation/'
import { OrganizationApi } from '../../api'

class OrganizationJoin extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      loading: true,
      completed: false,
    }
  }

  componentDidMount() {
    OrganizationApi.join(this.props.orgId)
      .then(json => {
        this.setState({
          loading: false,
          completed: true,
        })
        this.props.getLoggedInUser().then(() => {
          this.props.setOrgJoinSuccessMessage(true)
        })
      })
      .catch(json => {
        const error = json.error ? json.error : 'An error occurred when attempting to join this organization. Please contect Spexi support.'
        this.setState({ error, loading: false })
      })
  }

  render() {
    if (this.state.completed) {
      return <Redirect to="/" />
    } else if (this.state.loading) {
      return <LoadingAnimation visible={this.state.loading} />
    } else if (this.state.error) {
      return <section id="organizations" className="organizations">
        <div className="join-org">
          <LoadingAnimation visible={this.state.loading} />
          {this.state.error && (
            <>
              <header className="organizations__header">
                <h1 className="title">Joining {this.props.orgId} Organization</h1>
              </header>
              <div className="error-msg">
                <p>{this.state.error}</p>
              </div>
            </>
          )}
        </div>
      </section>
    } else {
      return <Redirect to="/" />
    }
  }
}

export default OrganizationJoin