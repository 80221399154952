import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Papa from 'papaparse'
import L from 'leaflet'

import CloseIcon from '@material-ui/icons/Close'
import './styles.css'
import GCPTpl from './gcps-template.csv'
import { GCPs } from '../../api'
import markerIcon from './marker.png'
import CoordinateSystem from '../CoordinateSystem'
import { DEFAULT_CRS, printCrs, checkLatLng, fetchCrs, convertGcpsToMarkers, isTerminalState } from '../../util'
import { cleanGcpHeaders, getGcps } from './gcphelper'
import { alertTypes } from '../../util/constants'

const markerImage = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [28, 28],
  iconAnchor: [14, 14]
})


class GCPUpload extends Component {
  static propTypes = {
    project: PropTypes.object.isRequired,
    gcps: PropTypes.array
  }


  constructor(props) {
    super(props)

    this.state = {
      disableEdit: false,
      crs: DEFAULT_CRS,
      vertCrs: null,
    }
  }


  componentDidMount() {
    let product = this.props.products.find(product => product.id === this.props.productForm.id)

    // only disable the GCP edit if the product is in progress
    if (product && product.job !== null && !isTerminalState(product.job) && product.job.current_task !== null && !isTerminalState(product.job.current_task)) {
      console.log('disabled gcp edit')
      this.setState({
        disableEdit: true
      })
    }

    // check if we have GCPs - if so, we may want to init the projections
    if (this.props.productForm.gcps.length > 0) {
      const projectionName = this.props.productForm.gcps[0].projection

      // we only need to fire off an async load if we have a non default projection
      if (projectionName && projectionName !== DEFAULT_CRS.id) {
        // its possible we have a custom compound projection
        const parts =  projectionName.split('|')
        let promises = []
        parts.forEach(part => promises.push(fetchCrs(part)))

        // update the state while we wait for the projections to load
        this.setState({ crs: { id: parts[0], name: '', type: 'geographic 2d' } })
        if (promises.length === 2) {
          this.setState({ vertCrs: { id: parts[1], name: '', type: 'vertical' } })
        }

        // do an async fetch
        Promise.all(promises)
          .then(this.onCrsLoaded)
          .catch(err => {
            console.error(err)
          }
        )
      }
    }
  }

  onCrsLoaded = (values) => {
    const crs = values[0]
    const vertCrs = values.length === 2 ? values[1] : null

    // in case we bunged up the type...fake it
    // if we have a vert crs it means it is compound eligible...
    if (crs.type.length === 0 && vertCrs) {
      crs.type = 'projected'
    }

    this.setState({ crs, vertCrs })
  }

  componentWillUnmount() {
    this.props.setIsUploadingGCPs(false)
  }

  processCsvFile = (csvData) => {
    // fetch GCPs from csv data
    const gcps = getGcps(csvData)
        
    // if the CRS is not the default, we should update the projection name for each GCP 
    // so we save that to the DB
    const { crs, vertCrs } = this.state
    if (crs.id !== DEFAULT_CRS.id) {
      let projectionName = crs.id
      if (vertCrs !== null) {
        // delimit compound projection name with a pipe to store in the DB
        projectionName = `${projectionName}|${vertCrs.id}`
      }

      // update each GCP with the selected projection name
      gcps.forEach(gcp => gcp.projection = projectionName)
    }
    this.props.setProductForm({ gcps })

    // convert gcps to markers and display on map
    convertGcpsToMarkers(gcps, markerImage, 'gcp-marker')
    .then(markers => {
      // check the coords to try to see if we are way off
      let validMapCoords = true
      markers.forEach(marker => {
        validMapCoords = validMapCoords && checkLatLng(marker.coordinates[0], marker.coordinates[1])
      })

      // alert user that something messed up
      if (!validMapCoords) {
        throw new Error('Forward projection resulted in invalid Lat/Lng coordinates!')
      }

      // otherwise update the preview markers
      this.props.setPreviewMarkers(markers)
    }).catch(err => {
      this.setState({ crs: DEFAULT_CRS, vertCrs: null })
      this.props.setAlertModal({
        message: 'Failed to convert GCP coordinates using selected Coordinate System! Please try again with another coordinate system.',
        type: alertTypes.error
      })
    })
  }

  onChangeFileHandler = e => {
    let file = e.target.files[0]
    let reader = new FileReader()
    this.setState({ file })

    reader.addEventListener('load', event => {
      let file = event.target.result
      if (file) {
        Papa.parse(file, {
          beforeFirstChunk: cleanGcpHeaders,
          header: false,
          skipEmptyLines: true,
          complete: (csvData) => {
            try {
              this.processCsvFile(csvData)
            } catch (err) {
              this.props.setAlertModal({
                message: err.message,
                type: alertTypes.error
              })
              event.target.value = null
              return
            }
          }
        })
      }
    }, false)
    reader.readAsText(file)
  }


  handeCheckpointToggle = (gcpId) => {
    let gcps = [...this.props.productForm.gcps]
    gcps = gcps.map(gcp => {
      if (gcp.id === gcpId) {
        gcp.is_check_point = gcp.is_check_point ? false : true
        GCPs.update(gcp.id, {
          is_check_point: gcp.is_check_point
        }).then(response => {
          console.log(response)
        })
          .catch(error => console.log(error))

      }
      return gcp
    })

    this.props.setProductForm({ gcps })
  }


  handleDelete = (id) => {
    this.props.setAlertModal({
      message: 'Are you sure that you want to delete this ground control point?',
      type: alertTypes.warning,
      confirmLabel: 'Yes',
      closeLabel: 'No',
      confirmHandler: () => {
        // update the product form
        const gcps = this.props.productForm.gcps.filter(gcp => gcp.id !== id)
        this.props.setProductForm({ gcps })

        // update the markers
        let markers = this.props.previewMarkers.filter(marker => marker.gcp_id !== id)
        this.props.setPreviewMarkers(markers)

        if (this.props.productForm.editing) {
          this.props.setRequestInProgress(true, 'GLOBAL')
          GCPs.delete(id)
            .then(response => {
              // if we get a null response, it's probably because the GCP isn't in the DB yet
              // this can happen if the product was created with GCPs and then the GCPs were later deleted
              if (response && response.success) {
                // if we successfully deleted the GCP from the DB, refresh from the DB...
                // NOTE: this also updates markers
                this.props.getProductGCPs(this.props.productForm.id)
              }
            }).finally(() => {
              this.props.setRequestInProgress(false, 'GLOBAL')
            })
        }
      },
      cancellable: true
    })
  }


  handleDeleteAll = (event) => {
    this.props.setAlertModal({
      message: 'Are you sure that you want to DELETE ALL ground control points on this project?',
      type: alertTypes.warning,
      confirmLabel: 'Yes',
      closeLabel: 'No',
      confirmHandler: () => {
        // collect all the ids
        let ids = this.props.productForm.gcps.map(x => x.id)

        // update our form and markers
        this.props.setProductForm({ gcps: [] })
        this.props.setPreviewMarkers(null)

        if (this.props.productForm.editing) {
          this.props.setRequestInProgress(true, 'GLOBAL')
          GCPs.deleteMultiple(ids)
            .then(response => {
              if (response.success) {
                // refresh from DB - they should all be gone...but in case there was an issue deleting 1 or more, update it
                this.props.getProductGCPs(this.props.productForm.id)
              }
            }).catch(err => {
              // this can happen if the product was created with GCPs and then the GCPs were later deleted
              console.debug(`Failed to delete all GCPs from DB - probably because they aren't there yet, message: ${err.message}`)
            }).finally(() => {
              this.props.setRequestInProgress(false, 'GLOBAL')
            })
        }
      },
      cancellable: true
    })
  }

  onCrsChange(data) {
    const { crs, vertCrs } = data
    // null crs means user hit cancel
    if (crs === null) {
      return
    }
    this.setState({ crs, vertCrs })
  }

  handleClose = () => {
    this.props.setIsUploadingGCPs(false)
  }

  renderCrsText() {
    const { crs, vertCrs } = this.state
    if (vertCrs !== null) {
      return `${printCrs(crs)} + ${printCrs(vertCrs)}`
    }
    return printCrs(crs)
  }

  renderGcpText(gcp) {
    return `(${gcp.point.coordinates[0].toFixed(5)}, ${gcp.point.coordinates[1].toFixed(5)}) @ ${gcp.elevation.toFixed(2)}m`
  }

  render() {
    return (
      <section id="gcp-upload-view">
        <article>
          <h3 className="section-title">Ground Control Points</h3>

          <CloseIcon onClick={this.handleClose} className="gcpupload__close-icon" fontSize="large"/>

          {this.props.productForm.gcps.length === 0 && (
            <form id="upload-gcp-file">
              <p>Ground Control Points (GCPs) are physical points marked with known geographic locations. They enhance the positioning and 
                accuracy of the mapping data.</p>
              <p>If you have GCP data to add to this project, please select the relevant coordinate reference system and ensure your CSV 
                file contains 4 columns:
              </p>
              <div>
                <ol>
                  <li>Label</li>
                  <li>Latitude or Northing</li>
                  <li>Longitude or Easting</li>
                  <li>Elevation</li>
                </ol>
              </div>
              <p>You can use this <a href={GCPTpl}>CSV file</a> as an example.</p>
              <CoordinateSystem onChange={this.onCrsChange.bind(this)}/>
              <label htmlFor="GCPFileUpload">
                <input type="file" name="GCPFileUpload" accept="text/csv"
                  onChange={(event) => { this.onChangeFileHandler(event) }}
                  onClick={(event) => { event.target.value = null }} />
              </label>
            </form>
          )}

          {this.props.productForm.gcps.length > 0 && (
            <div className="gcpList">
              <span className="crs-title">Coordinate System:<br/>{this.renderCrsText()}</span>
              <div className="list">
                {this.state.disableEdit && (
                  <p style={{ fontSize: '0.9rem' }}>Please note that once you have started processing with GCPs uploaded, you cannot delete them without restarting processing.</p>
                )}
                {this.props.productForm.gcps.map((gcp, index) => {
                  return (
                    <div className="row" key={index}>
                      <div>
                        <input id={`checkpoint-${index}`} type="checkbox" className="checkpoint-button"
                          checked={gcp.is_check_point}
                          onChange={() => this.handeCheckpointToggle(gcp.id)} />
                        <label title="Check point" htmlFor={`checkpoint-${index}`} />
                        <strong> {gcp.label}</strong>
                      </div>
                      <div className="details">
                  <div className="details__item">{this.renderGcpText(gcp)}</div>
                      </div>
                      {!this.state.disableEdit &&
                        <button className="delete-button" type="button" onClick={() => this.handleDelete(gcp.id)}>
                          <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </button>
                      }
                    </div>
                  )
                })}
                {!this.state.disableEdit && <button type="button" className="button solid delete-all" onClick={this.handleDeleteAll}>Remove all</button>}
              </div>
            </div>
          )}
        </article>
      </section>
    )
  }
}

export default GCPUpload
