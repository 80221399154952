import React, { Component } from 'react'
import PageHeading from '../components/PageHeading/PageHeading'
import FAQ from '../components/FAQ'
class FAQContainer extends Component {
  render() {
    return (
      <div className="App">
        <div className="page-wrap">
          <PageHeading
            title="Frequently Asked Questions"
            bgImage="/assets/img/contact.jpg"
            backgroundPosition="50% 0"
            smallHeader={true}
          />

          <section className="panel basic-page">
            <div className="container">
              <div className="narrow-wrap-700">
                <FAQ question="What is Spexi?">
                  <p>
                    Spexi is a cloud-based platform that makes it easy to
                    capture, process, analyze, and share aerial imagery captured
                    by drones.
                  </p>

                  <h3>Pilots</h3>
                  <p>
                    To get started, register and plan your first flight using
                    our web-based{' '}
                    <a href="https://fly.spexigeo.com">Flight Dashboard</a>.
                    Then, download our{' '}
                    <a href="https://apps.apple.com/ca/app/spexi/id1455273103">
                      iOS
                    </a>{' '}
                    or{' '}
                    <a href="https://play.google.com/store/apps/details?id=com.spexigeo.app&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                      Android
                    </a>{' '}
                    capture app to connect your DJI drone and capture your
                    aerial footage in the field.&nbsp;
                  </p>

                  <p>
                    Once captured, upload your imagery using our Flight
                    Dashboard to produce interactive panoramas, point of
                    interest orbits, 3D models, and precise maps that can be
                    used for analysis and measurement.
                  </p>

                  <h3>Service Customers</h3>
                  <p>
                    Spexi provides customers with an end-to-end service solution
                    for image capture and delivery. With a focus on real estate,
                    construction, industrial volumetric survey, and general
                    mapping services, Spexi can quickly, reliably and safely
                    capture your area of interest. Contact our sales team to
                    start your project today.
                  </p>
                </FAQ>

                <FAQ question="What drones do you recommend and support?">
                  <h3>Recommended</h3>
                  <ul>
                    <li>Mavic Pro</li>
                    <li>Mavic 2 Pro / Zoom / Enterprise / Dual (thermal)</li>
                    <li>Phantom 4, Pro V1 / V2</li>
                  </ul>

                  <h3>Also Supported</h3>
                  <ul>
                    <li>Mavic Mini (iOS only)</li>
                    <li>Mavic Air</li>
                    <li>Inspire 2</li>
                    <li>Matrice 200 / 210 / 210 RTK V1 / V2</li>
                    <li>Matrice 600</li>
                    <li>Matrice 100</li>
                  </ul>

                  <h3>Not Compatible with the Following Hardware</h3>
                  <ul>
                    <li>DJI Spark</li>
                    <li>Phantom 3 Series</li>
                    <li>Inspire 1 Series</li>
                    <li>
                      CrystalSky Monitor and CrystalSky Integrated Display
                    </li>
                  </ul>
                </FAQ>

                <FAQ question="What mobile and tablet devices are supported?">
                  <h3>iOS</h3>

                  <h4>Recommended Devices</h4>

                  <ul>
                    <li>iPad Pro (2015+)</li>
                    <li>iPad (2017+)</li>
                    <li>iPad Mini 4, 5</li>
                    <li>iPhone 11, 11 Pro, 11 Pro Max</li>
                    <li>iPhone X, Xs, Xs Max</li>
                    <li>iPhone 8</li>
                    <li>iPhone 7, 7 Plus</li>
                  </ul>

                  <h4>Versions</h4>
                  <ul>
                    <li>Recommended iOS 13+ and 1GB of free disk space</li>
                    <li>Minimum iOS 9.0 and 1GB of free disk space</li>
                  </ul>

                  <h3>Android</h3>
                  <p>
                    Unfortunately, due to the large variety of different Android
                    devices, we are not able to test and support them all.&nbsp;
                  </p>

                  <h4>Recommended Devices</h4>
                  <ul>
                    <li>Samsung Galaxy S9, S9+</li>
                    <li>Samsung Galaxy S8, S8+</li>
                    <li>Samsung Galaxy S7, S7 edge</li>
                    <li>Samsung Galaxy Note 5</li>
                    <li>Samsung Galaxy Tab S4</li>
                    <li>Samsung Galaxy Tab S3</li>
                    <li>Google Pixel 3, Pixel 3 XL</li>
                    <li>Google Pixel 2, Pixel 2 XL</li>
                    <li>Google Pixel, Pixel XL</li>
                    <li>Google Nexus 5X</li>
                  </ul>

                  <h4>Versions</h4>
                  <ul>
                    <li>Recommended Android 9+ and 1Gb of free disk space</li>
                    <li>Minimum Android 7.1 and 1GB of free disk space</li>
                  </ul>
                </FAQ>

                <FAQ question="Do I need a pilot&rsquo;s licence to fly?">
                  <p>
                    Licences are dependent upon the rules and regulations for
                    the country you are flying in. Currently in Canada, drones
                    over 250g and up to 25kg require either a basic or advanced
                    license to fly. The responsibility is in the individual
                    pilots to follow their local and national requirements to
                    operate drones.
                  </p>
                </FAQ>

                <FAQ question="What safety or regulation considerations should I be aware of?">
                  <p>
                    Safety and regulatory considerations are at the
                    responsibility of the pilot in command. It is up to the
                    pilot to ensure they are operating safely and following the
                    regulations and restrictions that apply to their geographic
                    area.
                  </p>
                </FAQ>

                <FAQ question="Does Spexi work in areas without cell phone or WIFI signals?">
                  <p>
                    Yes, you are able to pre-plan flights, either on your mobile
                    device or desktop computer, prior to cellular or wifi
                    coverage loss. For projects that require &ldquo;offline
                    mode&rdquo;, pilots are required to sync those projects
                    within the Spexi mobile application by selecting the Gear
                    icon in the upper right corner within a project. This will
                    allow for background map tiles to be loaded for those
                    projects.
                  </p>
                </FAQ>

                <FAQ question="Does Spexi save the drone flight records for uploading to third party sites such as AirData?">
                  <p>
                    Yes, for both iOS and Android applications, the Flight
                    Records are recorded and stored for manual upload. For iOS,
                    the Flight Recordings can be downloaded by connecting the
                    device to a computer, opening iTunes and navigating on the
                    device to File Sharing. For Android, the files can be found
                    by connecting the device to a computer and navigating to the
                    appropriate internal shared storage location for Spexi.
                  </p>
                </FAQ>

                <FAQ question="Does Spexi work outside of North America?">
                  <p>
                    Yes, Spexi works anywhere in the world, however projects
                    need to be set up in areas where cellular or wifi
                    connectivity is available.
                  </p>
                </FAQ>

                <FAQ question="How do I set up Spexi for the first time?">
                  <h3>Desktop</h3>
                  <p>
                    Click the Sign Up button on the Spexi Geospatial website, or
                    navigate to{' '}
                    <a href="https://fly.spexigeo.com">fly.spexigeo.com</a>.
                    This will take you to the platform login screen where you
                    can create a new login very simply. Following email
                    verification, your account will be active and you will be
                    able to log into the platform.
                  </p>

                  <h3>Mobile</h3>
                  <p>
                    Download and install the iOS or Android applications for
                    your tablet or phone and upon opening for the first time you
                    will be prompted to create a user account. Following email
                    verification, you will be able to log into the platform.
                  </p>
                </FAQ>

                <FAQ question="Can I fly a multi-battery flight?">
                  <p>
                    Yes, if required to land the drone or stop a mission
                    mid-flight, you can easily resume the flight starting at the
                    last waypoint collected. When you are ready to continue a
                    flight, go into the individual flight that you were just
                    collecting (i.e. mapping), and scroll down to the flight
                    history. The last flight you were on will be at the top of
                    the Flight History list and you will see a Continue icon on
                    the right side. Select Continue and the drone will be
                    sequenced to continue at the last waypoint collected.&nbsp;
                  </p>
                </FAQ>

                <FAQ question="Do I need to close the DJI Go app before starting Spexi?">
                  <p>
                    For Android applications, yes. The issue is due to the way
                    that Android handles the USB connections, where the USB
                    connection gets locked to whichever application is open
                    first. If the DJI Go app is opened first, you will need to
                    force quit it prior to opening the Spexi app.
                  </p>
                </FAQ>

                <FAQ question="How do I stop an automated flight and take manual control?">
                  <p>
                    Very easily. You can either stop the flight be selecting
                    Exit Mission within the app or switch modes on the DJI
                    controller (i.e., move the switch from P-mode to S-mode).
                    Both options will stop the drone at its current location and
                    give back full manual control.
                  </p>
                </FAQ>

                <FAQ question="Do I have manual control during an automated flight?">
                  <p>
                    Manual controls are limited during an automated flight. You
                    can change the speed of the drone by pushing forwards or
                    backwards on the assigned joystick, but otherwise the drone
                    will be unresponsive to joystick inputs. The user must Exit
                    Mission or change controller modes to stop the flight and to
                    immediately regain full manual control. It&rsquo;s wise to
                    practice this procedure on your first flight.
                  </p>
                </FAQ>

                <FAQ question="Does Spexi control my drones camera angle?">
                  <p>
                    Yes, the camera angle is controlled by the Spexi application
                    and set based on the flight plan inputs.&nbsp;
                  </p>
                </FAQ>

                <FAQ question="Can I share my products created on the platform with other users?">
                  <p>
                    Yes, all products generated will be viewable in an unique
                    shareable URL address. In addition, users can assign
                    projects to other users on the platform provided you know
                    their email address. This enables other team mates or
                    stakeholders (that you have provided access to)&nbsp; to
                    have the ability to measure, analyze, and create reports on
                    assigned projects.
                  </p>
                </FAQ>

                <FAQ question="Do I need to have a paid subscription or purchase credits to upload and process photos?">
                  <p>
                    Yes, to be able to upload and process datasets, you will be
                    required to either have a paid subscription or have
                    purchased credits available. Upon initial sign up, users
                    will be granted limited credits to test the system.
                  </p>
                </FAQ>

                <FAQ question="Are the use of Ground Control Points (GCP&rsquo;s) supported?">
                  <p>
                    Yes, users are able to upload a CSV file with GCP
                    coordinates when uploading the image files. If GCPs are
                    uploaded, the user will be prompted to refine their
                    positions during the processing workflow. Accuracy residuals
                    are displayed during the refinement process for quality
                    accuracy assurance.
                  </p>
                </FAQ>

                <FAQ question="How accurate are volume measurements?">
                  <p>
                    The majority of users achieve 1-2% accuracy when compared to
                    ground-based measurements. Accuracies achieved are also
                    dependent upon the drone type used, how the mission was
                    planned and captured, and by following best practices.
                  </p>
                </FAQ>

                <FAQ question="Can I upload and process imagery that was not collected via the Spexi mobile applications?">
                  <p>
                    Yes, provided the imagery is in jpeg format (with exif
                    information included in the image files). DJI drones
                    collecting in jpeg format by default will work. It is
                    important to note that the maps and 3D models are processed
                    to the extent of the mapping flight plan boundary. If no
                    mapping flight plan is generated, the processed maps and
                    models will include the full extent of the photo coverage.
                  </p>
                </FAQ>

                <FAQ question="How does Spexi's cloud solution generate geospatial data products?">
                  <p>
                    Spexi's proprietary cloud-based software pipeline
                    facilitates the transformation of raw aerial imagery into a
                    variety of geospatial data products including panoramas,
                    orbits, deep zoom image galleries, orthomosaic maps, 3D
                    point clouds, etc. In addition to our proprietary methods,
                    we use available open source tools where applicable as part
                    of our workflow in addition to licenced software such as{' '}
                    <a
                      href="https://www.agisoft.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Agisoft Metashape
                    </a>{' '}
                    to augmented our service offerings.
                  </p>
                </FAQ>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }
}

export default FAQContainer
