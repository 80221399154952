import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactJoyride from 'react-joyride'
import Modal from '../Modal'
import './styles.css'


class Tour extends Component {
  constructor(props) {
    super(props)

    // Example on how to use react-joyride
    // https://codesandbox.io/s/2zpjporp4p?file=/Basic/index.js:3799-3802
    this.state = {
      mounted: false,
      steps: [
        {
          title: 'Watch Quick Start video',
          content: (
            <>
              {/* <h3>Watch Quick Start video</h3> */}
              <iframe 
                src="https://www.youtube-nocookie.com/embed/cXCJNpNNs6A?modestbranding=1&rel=0" 
                title="YouTube video player" frameborder="0" rel="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe>
            </>
          ),
          target: '.App',
          placement: 'center'
        },
        {
          title: 'Create your first project',
          content: (<p>Press this button to start planning your first project which you can then fly using our mobile app.</p>),
          target: '#collapsible-menu a.add-button',
          placement: 'right'
        },
        {
          title: 'Create teams to collaborate',
          content: (<p>When you’re ready to share your project with your team or stakeholders, click here to begin creating teams.</p>),
          target: '#collapsible-menu a[href="/teams"]',
          placement: 'right'
        },
        {
          title: 'Buy credits or subscription',
          content: (<p>Once you’ve used up all of your trial processing credits, you can purchase credits or a monthly subscription in the account section.</p>),
          target: '#collapsible-menu a[href="/account"]',
          placement: 'right'
        }
      ],
      styles: {
        options: {
          zIndex: 10000,
          primaryColor: '#6aaec4',
        }
      }
    }
  }
  componentDidUpdate() {
    if(this.props.run && !this.state.mounted) this.setState({mounted: true})
  }

  shouldComponentUpdate(nextProps) {
    if ((nextProps.run !== this.props.run) || !this.state.mounted) return true
    return false
  }

  static propTypes = {
    run: PropTypes.bool,
    callback: PropTypes.func,
  }

  render() {
    const { steps, styles } = this.state
    const { run, callback } = this.props

    const Tooltip = ({
      continuous,
      index,
      step,
      backProps,
      closeProps,
      primaryProps,
      tooltipProps,
      isLastStep,
      skipProps,
      size
    }) => (
      <div {...tooltipProps}>
        <Modal 
          customClass='tour-modal'
          isOpen={true}
          closeHandler={skipProps.onClick}
          title={step.title}>
          <div>{step.content}</div>
          <div className='buttons-bar'>
            {!isLastStep && (
              <button className='btn tour-button' type='button' {...skipProps}>
                skip
              </button>
            )}
            <div> 
              {index > 0 && (
                <button className='btn tour-button' type='button' {...backProps}>
                    back
                </button>
              )}
              {continuous && (
                <button className='btn tour-button margin-between' type='button' {...primaryProps}>
                  {isLastStep? 'Finish' : `next ${index + 1}/${size}` }
                </button>
              )}
              {!continuous && (
                <button className='btn tour-button' type='button' {...closeProps}>
                    close
                </button>
              )}

            </div>
          </div>
        </Modal>
        
      </div>
    )
      
    return(
      <ReactJoyride 
        continuous
        showProgress
        showSkipButton
        callback={callback}
        tooltipComponent={Tooltip}
        steps={steps} run={run} styles={styles}/>
    )
  }
}

export default Tour