import React, { useState, useEffect, useCallback } from 'react'
import './style.css'
import { Auth } from '../../api'
import userDefaultAvatar from '../CollapsibleMenu/user-avatar.svg'
import store from '../../store'
import {
  formatDate,
  formatTimestampToDate,
  getStripePlanDescription,
} from '../../helpers'
import moment from 'moment'
import { connect } from 'react-redux'
import prettyBytes from 'pretty-bytes'

const UserCard = (props) => {
  const {
    userId,
    user,
    isLargeFormat,
    isAdmin,
    isNetworkProfile,
    subscriptionPlans,
  } = props

  const [state, setState] = useState({
    user: user ? user : null,
    userId: userId ? userId : user.id,
    isAdmin: isAdmin ? isAdmin : false,
  })

  // Fetch user data for the card from the server
  const fetchUser = useCallback(async () => {
    const result = await Auth.getUser(state.userId)
    if (result) {
      setState((prevState) => {
        return { ...prevState, user: result.data }
      })
    }
  }, [state.userId])

  useEffect(() => {
    // only do this fetch if user hasn't already been populated from higher level component
    if (!state.user) {
      fetchUser()
    }
  }, [fetchUser, state.user])

  useEffect(() => {
    if (user && JSON.stringify(state.user) !== JSON.stringify(user)) {
      setState({ ...state, user })
    }
  }, [user, state])

  const renderResultAvatar = () => {
    let userAvatar =
      state.user.profile && state.user.profile.hashedFileName
        ? state.user.profile.avatar_url
        : userDefaultAvatar
    let stripeCustomerStatus =
      state.user && state.user.stripe_id ? (
        <a
          href={`https://dashboard.stripe.com/${
            process.env.REACT_APP_ENV === 'production' ? '' : 'test/'
          }customers/${state.user.stripe_id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Yes
        </a>
      ) : (
        'No'
      )
    return (
      <div
        className="user-card__content"
        style={
          state.user.hasActiveSubscription
            ? { maxWidth: '500px' }
            : { maxWidth: '400px' }
        }
        onClick={() => {
          // TODO: clean this up later during redesign
          if (isNetworkProfile) {
            props.setProjectsMapCenter([
              state.user.profile.geolocation.coordinates[1],
              state.user.profile.geolocation.coordinates[0],
            ])
          }
        }}
      >
        <div className="user-card__profile" key={state.user.id}>
          <div className="user-card__user-avatar">
            <img src={userAvatar} alt={state.user.name} />
            <div className="user-card__user-meta">
              <div className="user-card__user-meta-content">
                <span style={{ fontSize: '0.9rem' }}>{state.user.name}</span>
                <br />
                {state.user.email}
                <br />
                Member since{' '}
                {moment(state.user.created_at)
                  .tz(store.getState().userTimezone)
                  .format('MMM D, YYYY')}
                {state.user.organizationMembership &&
                  state.user.organizationMembership.organization && (
                  <>
                    <p>
                        Organization: <br />
                      {state.user.organizationMembership.organization.name}
                    </p>
                  </>
                )}
                <br />
                {isNetworkProfile && state.user.networkProfile && (
                  <div className="user-card__network-profile">
                    {state.user.profile && state.user.profile.phone_number && (
                      <div>
                        <strong>Phone Number:</strong>{' '}
                        {state.user.profile.phone_number}
                      </div>
                    )}
                    <div>
                      <strong>Basic Certificate:</strong>{' '}
                      {state.user.networkProfile.basic_certificate}
                    </div>
                    <div>
                      <strong>Advanced Certificate:</strong>{' '}
                      {state.user.networkProfile.advanced_certificate}
                    </div>
                    {state.user.networkProfile.travel_distance && (
                      <div>
                        <strong>Willing to Travel:</strong>{' '}
                        {state.user.networkProfile.travel_distance} km
                      </div>
                    )}
                    {state.user.networkProfile.closest_airport && (
                      <div>
                        <strong>Closest Airport:</strong>{' '}
                        {state.user.networkProfile.closest_airport}
                      </div>
                    )}
                    {state.user.networkProfile.pilot_certificate_number && (
                      <div>
                        <strong>Pilot Certificate No.:</strong>{' '}
                        {state.user.networkProfile.pilot_certificate_number}
                      </div>
                    )}
                    {state.user.networkProfile.aircraft.length > 0 && (
                      <div>
                        <strong>Aircraft:</strong>
                        <br />{' '}
                        {state.user.networkProfile.aircraft.map((craft) => (
                          <span key={craft}>
                            {craft}
                            <br />
                          </span>
                        ))}
                      </div>
                    )}
                    {state.user.networkProfile.additional_sensors.length >
                      0 && (
                      <div>
                        <strong>Sensors:</strong>
                        <br />{' '}
                        {state.user.networkProfile.additional_sensors.map(
                          (sensor) => (
                            <span key={sensor}>
                              {sensor}
                              <br />
                            </span>
                          )
                        )}
                      </div>
                    )}
                    {state.user.networkProfile.services_provided.length > 0 && (
                      <div>
                        <strong>Services:</strong>
                        <br />{' '}
                        {state.user.networkProfile.services_provided.map(
                          (service) => (
                            <span key={service}>
                              {service}
                              <br />
                            </span>
                          )
                        )}
                      </div>
                    )}
                    {state.user.networkProfile.training.length > 0 && (
                      <div>
                        <strong>Training:</strong>
                        <br />{' '}
                        {state.user.networkProfile.training.map((training) => (
                          <span key={training}>
                            {training}
                            <br />
                          </span>
                        ))}
                      </div>
                    )}
                    {state.user.networkProfile.company_type && (
                      <div>
                        <strong>Company type:</strong>{' '}
                        {state.user.networkProfile.company_type}
                      </div>
                    )}
                    {state.user.networkProfile.insurance && (
                      <div>
                        <strong>Insurance:</strong>{' '}
                        {state.user.networkProfile.insurance}
                      </div>
                    )}
                  </div>
                )}
                {!state.user.hasActiveSubscription &&
                  !state.user.organizationMembership &&
                  state.user.wallet &&
                  !isNetworkProfile && (
                  <>
                    <strong>{state.user.wallet.balance}</strong> credits
                      remaining
                    <div className="storage">
                      <strong>
                        {prettyBytes(state.user.rawDataUploadBytes)}
                      </strong>{' '}
                        total files uploaded
                    </div>
                    <br />
                  </>
                )}
                {!state.user.hasActiveSubscription &&
                  state.user.organizationMembership && (
                  <>
                    <div className="storage">
                      <strong>
                        {prettyBytes(state.user.rawDataUploadBytes)}
                      </strong>{' '}
                        total files uploaded
                    </div>
                    <br />
                  </>
                )}
                {state.user.hasActiveSubscription && state.user.profile && (
                  <>
                    <strong>
                      {
                        state.user.profile.subscriptionPeriodUploadLimitStat
                          .currentTotal
                      }{' '}
                      of{' '}
                      {
                        state.user.profile.subscriptionPeriodUploadLimitStat
                          .limit
                      }
                    </strong>{' '}
                    images uploaded
                    <div className="storage">
                      <strong>
                        {prettyBytes(state.user.rawDataUploadBytes)}
                      </strong>{' '}
                      total files uploaded
                    </div>
                    <br />
                    <br />
                    {state.user.activeSubscription && (
                      <div>
                        <strong className="user-card__profile-section-title">
                          Active subscription plan:
                        </strong>
                        <strong>
                          {state.user.activeSubscription.stripe_status ===
                            'past_due' && (
                            <div className="user-card__profile-section-error">
                              <i className="fa fa-warning" />
                              You have a pending payment for this subscription.
                              Please check your email for any unpaid invoices
                              and make sure your default card is up to date.{' '}
                              <br />
                              You won't be able to upload and process images
                              until payment has been made.
                            </div>
                          )}
                        </strong>
                        <ul className="user-card__profile-section-list">
                          <li>
                            <strong>
                              {getStripePlanDescription(
                                state.user.activeSubscription.stripe_price,
                                subscriptionPlans
                              )}
                            </strong>
                          </li>
                          {state.user.profile.subscriptionPeriodUploadLimitStat
                            .period.current_period_end && (
                            <li>
                              Current period ends on{' '}
                              {formatDate(
                                state.user.profile
                                  .subscriptionPeriodUploadLimitStat.period
                                  .current_period_end
                              )}
                            </li>
                          )}
                          {state.user.activeSubscription?.ends_at && (
                            <li>{`Ends on ${formatDate(
                              state.user.activeSubscription.ends_at
                            )} `}</li>
                          )}
                        </ul>
                      </div>
                    )}
                  </>
                )}
                {state.user.upcomingSubscriptionSchedule?.length > 0 && (
                  <>
                    <strong className="user-card__profile-section-title">
                      Upcoming subscription plan:
                    </strong>
                    {state.user.upcomingSubscriptionSchedule[0].phases?.map(
                      (schedule) => {
                        const scheduleItem = schedule.items
                          ? schedule.items[0]
                          : schedule.plans[0]
                        return (
                          <ul
                            key={scheduleItem?.plan}
                            className="user-card__profile-section-list"
                          >
                            <li>
                              <strong>
                                {getStripePlanDescription(
                                  scheduleItem.plan,
                                  subscriptionPlans
                                )}
                              </strong>
                            </li>
                            <li>
                              Starts on{' '}
                              {formatTimestampToDate(schedule.start_date)}
                            </li>
                          </ul>
                        )
                      }
                    )}
                  </>
                )}
                {state.isAdmin && (
                  <>
                    <strong>Stripe Customer:</strong> {stripeCustomerStatus}
                    <br />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <section className={isLargeFormat ? 'user-card large-format' : 'user-card'}>
      {state.user && renderResultAvatar()}
      {!state.user && (
        <div className="lds-ring" style={{ marginBottom: '2rem' }}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
    </section>
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionPlans: state.subscriptionPlans,
  }
}

export default connect(mapStateToProps)(UserCard)
