import React, { useState } from "react";
import AdminSubscriptions from "../AdminSubscriptions";
import WalletTransactionsList from "../WalletTransactionsList";
import AdminOrganizations from "../AdminOrganizations";
import AdminProcessingReports from "../AdminProcessingReports";
import "./styles.css";

const Admin = ({ setAlertModal }) => {
  const [tabValue, setTabValue] = useState("processing-reports");

  return (
    <div className="admin">
      <div className="admin__tab-btn-container">
        <button
          type="button"
          className={`btn-link admin__btn-link ${
            tabValue === "processing-reports" ? "active" : ""
          }`}
          onClick={() => setTabValue("processing-reports")}
        >
          Processing Reports
        </button>

        <button
          type="button"
          className={`btn-link admin__btn-link ${
            tabValue === "subscriptions" ? "active" : ""
          }`}
          onClick={() => setTabValue("subscriptions")}
        >
          Subscriptions
        </button>
        <button
          type="button"
          className={`btn-link admin__btn-link ${
            tabValue === "wallet" ? "active" : ""
          }`}
          onClick={() => setTabValue("wallet")}
        >
          Wallet
        </button>
        <button
          type="button"
          className={`btn-link admin__btn-link ${
            tabValue === "organizations" ? "active" : ""
          }`}
          onClick={() => setTabValue("organizations")}
        >
          Organizations
        </button>
      </div>
      <div className="admin__tab-content">
        {tabValue === "wallet" && <WalletTransactionsList />}
        {tabValue === "processing-reports" && (
          <AdminProcessingReports setAlertModal={setAlertModal} />
        )}
        {tabValue === "subscriptions" && (
          <AdminSubscriptions setAlertModal={setAlertModal} />
        )}
        {tabValue === "organizations" && (
          <AdminOrganizations setAlertModal={setAlertModal} />
        )}
      </div>
    </div>
  );
};

export default React.memo(Admin);
