import React from 'react'
import PropTypes from 'prop-types'

const MapDistanceIcon = (props) => {
  const { width = 43.7, height = 35.4 } = props
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      width={width}
      height={height}
      viewBox="0 0 43.7 35.4"
      xmlSpace="preserve"
    >
      <g id="prefix__Symbol_2_1" transform="translate(-26 -353)">
        <g id="prefix__Group_2308" transform="translate(-1 44)">
          <ellipse
            id="prefix__Ellipse_98"
            transform="rotate(-3.371 28.721 310.723) scale(1.00003)"
            style={{ fill: '#6aaec4' }}
            cx={28.7}
            cy={310.7}
            rx={1.7}
            ry={1.7}
          />
          <ellipse
            id="prefix__Ellipse_99"
            transform="rotate(-3.371 69.01 342.655) scale(1.00003)"
            style={{ fill: '#6aaec4' }}
            cx={69}
            cy={342.6}
            rx={1.7}
            ry={1.7}
          />
          <g id="prefix__Group_2307">
            <g id="prefix__Group_2294" transform="translate(-238 -283.535)">
              <path
                id="prefix__Path_300_2_"
                d="M306.1 625.5l-39.2-30.7"
                fill="none"
                stroke="#6aaec4"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

MapDistanceIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

export default MapDistanceIcon