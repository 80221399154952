import React, { useState, useEffect, useCallback } from 'react'
// import { makeStyles } from '@material-ui/core/styles'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Select from '@material-ui/core/Select'
import { CircularProgress, FormHelperText } from '@material-ui/core'
// import ShareModal from '../ShareModal'
import UserCard from '../UserCard'
import Modal from '../Modal'
import { Auth, Subscriptions, User } from '../../api'
import {
  alertTypes,
  CELLULAR_CONNECTIVITY,
  CERTIFICATE_OPTIONS,
  COMPANY_TYPES,
  DRONES,
  INSURANCE_TYPES,
  SENSORS,
  SERVICE_TYPES,
  SUBSCRIPTION_PLAN_TYPES,
  TRAINING_TYPES,
} from '../../util/constants'
import './styles.css'
import SubscriptionsDialog from '../SubscriptionsDialog'
import { connect } from 'react-redux'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from '../../config'
import { capitalize } from 'lodash'

const stripePromise = loadStripe(STRIPE_KEY)

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Profile = (props) => {
  const { user, setAlertModal } = props
  const [profile, setProfile] = useState(user.profile)
  const [networkProfile, setNetworkProfile] = useState(user.networkProfile)
  const [open, setOpen] = useState(false)
  const [openSubscriptionDialog, setOpenSubscriptionDialog] = useState(false)
  const [emailSentOpen, setEmailSentOpen] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [shouldShowProfileDetailsModal, setShouldShowProfileDetailsModal] =
    useState(false)
  const [isError, setIsError] = useState(false)
  const [userSubscriptionPaymentMethods, setUserSubscriptionPaymentMethods] =
    useState([])

  useEffect(() => {
    setProfile(user.profile)
    setNetworkProfile(user.networkProfile)
  }, [user, user.networkProfile])

  const getSubscriptionPaymentMethods = useCallback(async () => {
    if (!user.hasActiveSubscription) return
    const response = await Subscriptions.getSubscriptionPaymentMethods(user.id)

    if (!response.success) {
      setAlertModal({
        message: response?.error || 'An error occured',
        type: alertTypes.error,
      })
      return
    }

    setUserSubscriptionPaymentMethods(response.data)
  }, [user.id, user.hasActiveSubscription, setAlertModal])

  const setSubscriptionDefaultPaymentMethod = async (paymentMethodId) => {
    if (!user.hasActiveSubscription || !user.activeSubscription.stripe_id)
      return
    const hasUnpaidSubscription =
      user.activeSubscription.stripe_status === 'past_due'

    const response = await Subscriptions.updateSubscriptionDefaultPaymentMethod(
      user.activeSubscription.stripe_id,
      paymentMethodId
    )

    setUserSubscriptionPaymentMethods([])

    if (!response.success) {
      setAlertModal({
        message: response?.error || 'An error occured',
        type: alertTypes.error,
      })
      getSubscriptionPaymentMethods()
      return
    }

    setAlertModal({
      message: `Successfully set new default payment method. ${
        hasUnpaidSubscription
          ? 'Your payment has been processed. Please refresh to view your changes'
          : ''
      }`,
      type: alertTypes.success,
    })
    getSubscriptionPaymentMethods()
  }

  const deletePaymentMethod = async (paymentMethodId) => {
    const response = await Subscriptions.deletePaymentMethod(paymentMethodId)

    setUserSubscriptionPaymentMethods([])

    if (!response.success) {
      setAlertModal({
        message: response?.error || 'An error occured',
        type: alertTypes.error,
      })
      getSubscriptionPaymentMethods()
      return
    }

    setAlertModal({
      message: 'Successfully deleted payment method',
      type: alertTypes.success,
    })
    getSubscriptionPaymentMethods()
  }

  useEffect(() => {
    getSubscriptionPaymentMethods()
  }, [getSubscriptionPaymentMethods])

  const userHasContractSubscription = () => {
    if (!user.hasActiveSubscription) return false

    const plan = props.subscriptionPlans[user.activeSubscription?.stripe_price]
    return plan && plan.metadata?.plan_type === SUBSCRIPTION_PLAN_TYPES.contract
  }

  const handleOpenSubscriptionDialog = () => {
    if (userHasContractSubscription()) {
      setAlertModal({
        message:
          'Please contact your administrator to modify your contract subscription',
        type: alertTypes.info,
      })
    } else {
      if (user.wallet?.balance > 0 && !user.hasActiveSubscription) {
        setAlertModal({
          message: `You have ${user.wallet?.balance} credits left in your wallet. Are you sure you want to purchase a subscription?`,
          type: alertTypes.warning,
          confirmHandler: () => {
            setOpenSubscriptionDialog(true)
          },
          cancellable: true,
        })
        return
      }

      setOpenSubscriptionDialog(true)
    }
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
    setEmailSentOpen(false)
    setIsError(false)
  }

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.id]: e.target.value })
  }

  const handleChangeNetwork = (e) => {
    let fieldName
    if (e.target.id) {
      fieldName = e.target.id
    } else {
      fieldName = e.target.name
    }
    setNetworkProfile({ ...networkProfile, [fieldName]: e.target.value })
  }

  const handleChangeNetworkMultiple = (event) => {
    const { options } = event.target
    const value = []
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value)
      }
    }

    setNetworkProfile({ ...networkProfile, [event.target.id]: value })
  }

  const handleSubmit = () => {
    setIsUpdating(true)

    User.updateProfile(profile)
      .then(() => {
        User.updateNetworkProfile(user.id, networkProfile)
          .then((result) => {
            setOpen(true)
            setNetworkProfile(result.data)
            setIsUpdating(false)
            setShouldShowProfileDetailsModal(false)
          })
          .catch((error) => {
            console.log(error)
            setIsError(true)
          })
      })
      .finally(() => {})
      .catch(() => {
        setIsError(true)
      })
  }
  const handleChangeDefaultPaymentMethod = async () => {
    if (!user.hasActiveSubscription || !user.activeSubscription.stripe_id)
      return

    const response = await Subscriptions.createSubscriptionCheckoutSession(
      user.activeSubscription.stripe_id,
      user.id
    )

    if (!response.success) {
      setAlertModal({
        message: response?.error || 'An error occured',
        type: alertTypes.error,
      })
      return
    }

    const sessionId = response.data['sessionId']

    // When the customer clicks on the button, redirect them to Checkout.
    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    })

    if (error?.message) {
      setAlertModal({
        message: error.message,
        type: alertTypes.error,
      })
    }
  }

  const handleSetNewDefaultPaymentMethod = async (paymentMethodId) => {
    setAlertModal({
      message:
        'Are you sure that you want to set this payment method as default? Your next subscription invoice will be charged on this card.',
      cancellable: true,
      type: alertTypes.warning,
      confirmHandler: () => {
        setSubscriptionDefaultPaymentMethod(paymentMethodId)
      },
    })
  }

  const handleDeletePaymentMethod = async (paymentMethodId) => {
    setAlertModal({
      message:
        'Are you sure that you want to delete this payment method? You will no longer be able to use this payment method',
      cancellable: true,
      type: alertTypes.warning,
      confirmHandler: () => {
        deletePaymentMethod(paymentMethodId)
      },
    })
  }

  const handleResetPassword = () => {
    setAlertModal({
      message: `An e-mail will be sent to ${user.email} with a link to reset your password. Continue?`,
      type: alertTypes.info,
      cancellable: true,
      confirmHandler: () => {
        Auth.recover(user.email).then((response) => {
          // console.log(response)
          setEmailSentOpen(true)
        })
      },
    })
  }

  const renderCardInfo = (pm) => (
    <div>
      <strong>{capitalize(pm.card.brand)}</strong> ({pm.card.type}) ****{' '}
      {pm.card.last4}
      {pm.is_default && (
        <strong className="account__section-subtitle">(Default)</strong>
      )}
      <br />
      <span style={{ fontSize: '0.8rem' }}>
        Expires {pm.card.exp_month} / {pm.card.exp_year}
      </span>
      {pm.card.is_expired && (
        <span className="account__section-expired-tag">
          <i className="fa fa-warning" /> expired
        </span>
      )}
    </div>
  )

  return (
    <section id="account-page">
      <header className="page__header">
        <h1 className="sidebar-header__title">Account Information</h1>
      </header>

      <Snackbar
        open={open}
        autoHideDuration={2000}
        message="Profile updated"
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Profile updated!
        </Alert>
      </Snackbar>

      <Snackbar
        open={emailSentOpen}
        autoHideDuration={2000}
        message="Profile updated"
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          Password reset e-mail has been sent
        </Alert>
      </Snackbar>

      <Snackbar
        open={isError}
        autoHideDuration={2000}
        message="Error occured"
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          An error occured saving your profile. Please try again or contact
          support.
        </Alert>
      </Snackbar>

      {user && !user.is_verified && (
        <div>
          Please verify your account to begin.
          <br /> Check your inbox for a verification email.
        </div>
      )}

      {user && !!user.is_verified && (
        <>
          <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
            <UserCard user={user} isLargeFormat={true} isAdmin={user.isAdmin} />
            {user.hasActiveSubscription && !userHasContractSubscription() && (
              <section className="account__payment-section">
                <h2 className="account__section-title">
                  Subscription Payment methods
                  {!userSubscriptionPaymentMethods?.find(
                    (pm) => pm.is_default
                  ) && (
                    <button
                      type="button"
                      className="btn-link account__section-btn-link"
                      onClick={handleChangeDefaultPaymentMethod}
                    >
                      Add
                    </button>
                  )}
                </h2>
                {userSubscriptionPaymentMethods.length > 0 ? (
                  <>
                    {userSubscriptionPaymentMethods
                      .filter((pm) => pm.is_default)
                      .map((pm) => (
                        <div className="account__section-desc" key={pm.id}>
                          {renderCardInfo(pm)}
                          <div>
                            <button
                              type="button"
                              className="btn-link account__section-btn-link"
                              onClick={handleChangeDefaultPaymentMethod}
                            >
                              Change
                            </button>
                          </div>
                        </div>
                      ))}

                    {userSubscriptionPaymentMethods
                      .filter((pm) => !pm.is_default)
                      .map((pm) => (
                        <div className="account__section-desc" key={pm.id}>
                          {renderCardInfo(pm)}
                          <div>
                            <button
                              type="button"
                              className="btn-link account__section-btn-link"
                              onClick={() => {
                                handleSetNewDefaultPaymentMethod(pm.id)
                              }}
                            >
                              Set as default
                            </button>
                            &nbsp;|&nbsp;
                            <button
                              type="button"
                              className="btn-link account__section-btn-link"
                              onClick={() => {
                                handleDeletePaymentMethod(pm.id)
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      ))}
                  </>
                ) : (
                  <>
                    <CircularProgress color="primary" size={30} />
                  </>
                )}
              </section>
            )}
          </div>

          <section className="account__options-menu">
            {!user.organizationMembership && (
              <button
                type="button"
                className="btn white"
                onClick={handleOpenSubscriptionDialog}
              >
                {user.hasActiveSubscription
                  ? 'Modify Subscription'
                  : 'Purchase Subscription'}
              </button>
            )}

            {!user.organizationMembership &&
              !user.hasActiveSubscription &&
              user.wallet && (
              <button
                type="button"
                className="btn white"
                onClick={() => props.setIsPurchasingCredits(true)}
              >
                  Purchase credits
              </button>
            )}

            <button
              type="button"
              className="btn white"
              disabled={isUpdating}
              onClick={() => {
                setShouldShowProfileDetailsModal(true)
              }}
            >
              Edit Profile
            </button>

            <button
              type="button"
              className="btn white"
              onClick={handleResetPassword}
            >
              Reset Password
            </button>

            <SubscriptionsDialog
              user={user}
              setAlertModal={setAlertModal}
              open={openSubscriptionDialog}
              onClose={() => setOpenSubscriptionDialog(false)}
            />
          </section>

          <Modal
            title="Edit Profile"
            customClass="profile-modal"
            isOpen={shouldShowProfileDetailsModal}
            closeHandler={() => {
              setShouldShowProfileDetailsModal(false)
              setProfile(user.profile)
              setNetworkProfile(user.networkProfile)
            }}
          >
            <article className="account-details">
              {!networkProfile && (
                <div className="network-profile-ask">
                  <p>
                    Would you like to be contacted about paid projects in your
                    area? Join our pilot network.{' '}
                    <button
                      type="button"
                      className="btn white"
                      disabled={isUpdating}
                      onClick={() => {
                        User.createNetworkProfile({
                          user_id: user.id,
                        }).then((response) => {
                          setNetworkProfile(response.data)
                        })
                      }}
                    >
                      Register
                    </button>
                  </p>
                </div>
              )}
              <form noValidate autoComplete="off">
                <div className="field-set">
                  <TextField
                    id="name"
                    label="Full Name"
                    value={user.name}
                    disabled
                  />
                  <TextField
                    id="email"
                    label="Email"
                    value={user.email}
                    disabled
                  />
                  <TextField
                    id="phone_number"
                    label="Phone Number"
                    value={profile.phone_number}
                    onChange={handleChange}
                  />
                </div>
                <div className="field-set">
                  <TextField
                    id="address"
                    label="Address"
                    value={profile.address || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    id="city"
                    label="City"
                    value={profile.city || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    id="province"
                    label="Province/State"
                    value={profile.province || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    id="postal_code"
                    label="Postal/Zip Code"
                    value={profile.postal_code || ''}
                    onChange={handleChange}
                  />
                  <TextField
                    id="country"
                    label="Country"
                    value={profile.country || ''}
                    onChange={handleChange}
                  />
                </div>

                {networkProfile && (
                  <div className="network-profile-form">
                    <h1 className="generic-modal__title">Network Profile</h1>
                    <p>
                      Your network profile details allow us to match you to
                      projects in your area. Please keep it up to date.
                    </p>
                    <div className="field-set">
                      <FormHelperText>
                        How far are you willing to travel for a project?
                      </FormHelperText>
                      <TextField
                        id="travel_distance"
                        className="multi-line-label"
                        type="number"
                        label="Distance (Kilometres)"
                        value={networkProfile.travel_distance || ''}
                        onChange={handleChangeNetwork}
                      />
                    </div>

                    <div className="field-set">
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Do you have a current Advanced Pilot Certificate?
                        </FormLabel>
                        <RadioGroup
                          aria-label="advanced_certificate"
                          id="advanced_certificate"
                          name="advanced_certificate"
                          value={networkProfile.advanced_certificate}
                          onChange={handleChangeNetwork}
                        >
                          {CERTIFICATE_OPTIONS.map((option) => (
                            <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio color="default" />}
                              label={option.label}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>

                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Do you have a current Basic Pilot Certificate?
                        </FormLabel>
                        <RadioGroup
                          aria-label="basic_certificate"
                          id="basic_certificate"
                          name="basic_certificate"
                          value={networkProfile.basic_certificate}
                          onChange={handleChangeNetwork}
                        >
                          {CERTIFICATE_OPTIONS.map((option) => (
                            <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio color="default" />}
                              label={option.label}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>

                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Do you have a current Part 107 Pilot Certificate? (US
                          only)
                        </FormLabel>
                        <RadioGroup
                          aria-label="part_107_certificate"
                          id="part_107_certificate"
                          name="part_107_certificate"
                          value={networkProfile.part_107_certificate}
                          onChange={handleChangeNetwork}
                        >
                          {CERTIFICATE_OPTIONS.map((option) => (
                            <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio color="default" />}
                              label={option.label}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>

                      <FormHelperText>
                        What is your pilot certificate number?
                      </FormHelperText>
                      <TextField
                        id="pilot_certificate_number"
                        label="Certificate Number"
                        value={networkProfile.pilot_certificate_number || ''}
                        onChange={handleChangeNetwork}
                      />
                    </div>

                    <div className="field-set">
                      <FormControl component="fieldset">
                        <FormHelperText>
                          What Aircraft do you currently operate?{' '}
                          <span
                            style={{ fontSize: '0.8rem', display: 'block' }}
                          >
                            (Hold Ctrl/CMD to select multiple)
                          </span>
                        </FormHelperText>
                        <Select
                          id="aircract"
                          multiple
                          native
                          value={networkProfile.aircraft}
                          onChange={handleChangeNetworkMultiple}
                          inputProps={{
                            id: 'aircraft',
                          }}
                        >
                          {DRONES.map((name) => (
                            <option key={name} value={name}>
                              {name}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl component="fieldset">
                        <FormHelperText>
                          Do you use an iOS or Android device for the flight
                          controller? Please specific which make and model.
                        </FormHelperText>
                        <TextField
                          id="fight_controller"
                          label="Flight Controller"
                          value={networkProfile.fight_controller || ''}
                          onChange={handleChangeNetwork}
                        />
                      </FormControl>

                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Is your device capable of connecting to the cellular
                          network?
                        </FormLabel>
                        <RadioGroup
                          aria-label="cellular_connectivity"
                          id="cellular_connectivity"
                          name="cellular_connectivity"
                          value={networkProfile.cellular_connectivity || ''}
                          onChange={handleChangeNetwork}
                        >
                          {CELLULAR_CONNECTIVITY.map((connectivity) => (
                            <FormControlLabel
                              key={connectivity}
                              value={connectivity}
                              control={<Radio color="default" />}
                              label={connectivity}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </div>

                    <div className="field-set">
                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Do you have aviation and general liability insurance?
                        </FormLabel>
                        <RadioGroup
                          aria-label="insurance"
                          id="insurance"
                          name="insurance"
                          value={networkProfile.insurance || ''}
                          onChange={handleChangeNetwork}
                        >
                          {INSURANCE_TYPES.map((insuranceType) => (
                            <FormControlLabel
                              key={insuranceType}
                              value={insuranceType}
                              control={<Radio color="default" />}
                              label={insuranceType}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>

                      <FormControl component="fieldset">
                        <FormLabel component="legend">
                          Do you have your own company?
                        </FormLabel>
                        <RadioGroup
                          aria-label="company_type"
                          id="company_type"
                          name="company_type"
                          value={networkProfile.company_type || ''}
                          onChange={handleChangeNetwork}
                        >
                          {COMPANY_TYPES.map((companyType) => (
                            <FormControlLabel
                              key={companyType}
                              value={companyType}
                              control={<Radio color="default" />}
                              label={companyType}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>

                      <FormControl component="fieldset">
                        <FormHelperText>
                          What sensors do you have?{' '}
                          <span
                            style={{ fontSize: '0.8rem', display: 'block' }}
                          >
                            (Hold Ctrl/CMD to select multiple)
                          </span>
                        </FormHelperText>
                        <Select
                          id="additional_sensors"
                          multiple
                          native
                          value={networkProfile.additional_sensors}
                          onChange={handleChangeNetworkMultiple}
                          inputProps={{
                            id: 'additional_sensors',
                          }}
                        >
                          {SENSORS.map((sensor) => (
                            <option key={sensor} value={sensor}>
                              {sensor}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl component="fieldset">
                        <FormHelperText>
                          What is the closest major airport to your company/home
                          locations? (Example: YYZ, YVR)
                        </FormHelperText>
                        <TextField
                          id="closest_airport"
                          label="Airport"
                          value={networkProfile.closest_airport || ''}
                          onChange={handleChangeNetwork}
                        />
                      </FormControl>

                      <FormControl component="fieldset">
                        <FormHelperText>
                          Do you have any training below?{' '}
                          <span
                            style={{ fontSize: '0.8rem', display: 'block' }}
                          >
                            (Hold Ctrl/CMD to select multiple)
                          </span>
                        </FormHelperText>
                        <Select
                          id="training"
                          multiple
                          native
                          value={networkProfile.training}
                          onChange={handleChangeNetworkMultiple}
                          inputProps={{
                            id: 'training',
                          }}
                        >
                          {TRAINING_TYPES.map((trainingType) => (
                            <option key={trainingType} value={trainingType}>
                              {trainingType}
                            </option>
                          ))}
                        </Select>
                      </FormControl>

                      <FormControl component="fieldset">
                        <FormHelperText>
                          What type of service do you provide?{' '}
                          <span
                            style={{ fontSize: '0.8rem', display: 'block' }}
                          >
                            (Hold Ctrl/CMD to select multiple)
                          </span>
                        </FormHelperText>
                        <Select
                          id="services_provided"
                          multiple
                          native
                          value={networkProfile.services_provided}
                          onChange={handleChangeNetworkMultiple}
                          inputProps={{
                            id: 'services_provided',
                          }}
                        >
                          {SERVICE_TYPES.map((serviceType) => (
                            <option key={serviceType} value={serviceType}>
                              {serviceType}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}

                <div className="account-options">
                  <button
                    type="button"
                    className="btn white"
                    disabled={isUpdating}
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </article>
          </Modal>
        </>
      )}
    </section>
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionPlans: state.subscriptionPlans,
  }
}

export default connect(mapStateToProps)(Profile)
