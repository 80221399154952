import React from 'react'
import Tooltip from '../Tooltip'
import { alertTypes } from '../../util/constants'

const CreditSelectionForm = props => {
  const handleNext = () => {
    if (props.credits === 0) {
      props.setAlertModal({
        message: 'Please make a credit selection before continuing.',
        type: alertTypes.warning
      })
    } else if (!props.wallet_id) {
      props.setAlertModal({
        message: 'Please select a wallet to deposit into.',
        type: alertTypes.warning
      })
    } else {
      props.transitionStep(1)
    }
  }

  return (
    <div className="input-form">
      <h2 className="sidebar-header__title">Select Credit Amount</h2>
      {/* <div className="free-credits">
        <h3>Unlock Free Credits</h3>
        <ul>
          <li>Fill out your profile <strong className="credit-up">+3 credits</strong></li>
          <li>Review our Getting Started Tips <strong className="credit-up">+3 credits</strong></li>
          <li>Refer a friend using your Spexi referral code <strong className="credit-up">+2 credits for each of you</strong></li>
        </ul>
      </div> */}

      <section className="credit-options">
        <div className="credit-option">
          <input type="radio" id="credits-3" name="credits" value="3" onChange={props.handleChange} defaultChecked={props.credits === '3'} />
          <label htmlFor="credits-3">
            <div className="credit-amount"><strong>3</strong> credits</div>
            <div className="credit-price">${props.creditsPrice * 3}<span>CAD</span></div>
          </label>
        </div>

        <div className="credit-option">
          <input type="radio" id="credits-6" name="credits" value="6" onChange={props.handleChange} defaultChecked={props.credits === '6'} />
          <label htmlFor="credits-6">
            <div className="credit-amount"><strong>6</strong> credits</div>
            <div className="credit-price">${props.creditsPrice * 6}<span>CAD</span></div>
          </label>
        </div>

        <div className="credit-option">
          <input type="radio" id="credits-12" name="credits" value="12" onChange={props.handleChange} defaultChecked={props.credits === '12'} />
          <label htmlFor="credits-12">
            <div className="credit-amount"><strong>12</strong> credits</div>
            <div className="credit-price">${props.creditsPrice * 12}<span>CAD</span></div>
          </label>
        </div>

        <div className="credit-option">
          <input type="radio" id="credits-24" name="credits" value="24" onChange={props.handleChange} defaultChecked={props.credits === '24'} />
          <label htmlFor="credits-24">
            <div className="credit-amount"><strong>24</strong> credits</div>
            <div className="credit-price">${props.creditsPrice * 24}<span>CAD</span></div>
          </label>
        </div>

        <div className="credit-option">
          <input type="radio" id="credits-100" name="credits" value="100" onChange={props.handleChange} defaultChecked={props.credits === '100'} />
          <label htmlFor="credits-100">
            <div className="credit-amount"><strong>100</strong> credits</div>
            <div className="credit-price">${props.creditsPrice * 100}<span>CAD</span></div>
          </label>
        </div>

        <div className="credit-option">
          <input type="radio" id="credits-200" name="credits" value="200" onChange={props.handleChange} defaultChecked={props.credits === '200'} />
          <label htmlFor="credits-200">
            <div className="credit-amount"><strong>200</strong> credits</div>
            <div className="credit-price">${props.creditsPrice * 200}<span>CAD</span></div>
          </label>
        </div>
      </section>

      <label><strong>Deposit Wallet</strong>
        <select name="wallet_id" value={props.wallet_id} onChange={props.handleChange}>
          <option value="" hidden>Select wallet to deposit to...</option>
          {props.user && props.user.wallet.id && <option value={props.user.wallet.id}>{props.user.name}'s Wallet</option>}
          {/* {props.teams && props.teams.map(team => {
            let members = team.members.map((member, index) => {
              return member.user.name + (team.members.length - 1 !== index ? ', ' : '')
            })
            if(team.wallet_id) {
              return <option key={team.wallet_id} value={team.wallet_id}>Team Wallet: {team.name} -- ({members})</option>
            } else {
              return ''
            }
          })} */}
        </select>
      </label>

      <div style={{fontSize: '0.8rem', marginBottom: '1rem'}}>Credits do not expire. See our payment and refund terms&nbsp;
        <Tooltip tip="Credit terms"><Terms /></Tooltip>.</div>

      <div className="form-options">
        <button type="button" className="btn white" onClick={() => props.setIsPurchasingCredits(false)}>Cancel</button>
        <button type="button" className="btn" onClick={handleNext}>Buy{props.credits > 0 ? ' ' + props.credits + ' credits' : ''}</button>
      </div>
    </div>
  )
}

export default CreditSelectionForm

const Terms = props => (
  <div>
    <strong>Credit terms</strong>
    <ul style={{margin: 0, padding:'0 16px 10px 16px'}}>
      <li>Credits do not expire</li>
      <li>Customers can convert credits to a subscription at any time</li>
    </ul>

    <strong>Refunds</strong>
    <ul style={{margin: 0, padding:'0 16px 10px 16px'}}>
      <li>Full refunds, for whatever reason, are available within 24 hours of purchase</li>
      <li>Refunds are available for 30 days from original purchase date less a 5% administrative fee</li>
    </ul>
  </div>
)
