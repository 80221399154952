import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.css'
import layerTemplate from './layer-template.csv'
import toGeoJSON from '@mapbox/togeojson'
import { ProductMapLayers } from '../../api'
import { alertTypes } from '../../util/constants'

class LayerImportDialog extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.ref = React.createRef()

    this.state = {
      layerName: '',
      fileType: null,
    }
  }

  componentDidMount() {
    console.log('Import from file')
  }

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {}

  uploadLayerFile = () => {
    if (this.state.layerName === '') {
      this.props.setAlertModal({
        message: 'Layer name is required.',
        type: alertTypes.warning,
      })
      return
    } else if (!this.state.file) {
      this.props.setAlertModal({
        message: 'File is required.',
        type: alertTypes.warning,
      })
      return
    }
    this.props.toggleImportDialog()
    let data = new FormData()
    data.append('name', this.state.layerName)
    data.append('product_id', this.props.product.id)
    data.append('file', this.state.file)
    data.append('type', this.state.fileType)
    this.props.setRequestInProgress(true, 'GLOBAL')

    ProductMapLayers.upload(data)
      .then((response) => {
        this.props.setRequestInProgress(false, 'GLOBAL')
        if (response && response.success) {
          this.props.fetchProductMapLayersData(this.props.match.params.pid)
        } else if (response && !response.success && response.error) {
          this.props.setAlertModal({
            message: response.error,
            type: alertTypes.error,
          })
        } else {
          this.props.setAlertModal({
            message:
              'Error uploading your layer file. Please check that it\'s match the format of our provided template.',
            type: alertTypes.error,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  onChangeFileHandler = (event) => {
    let fileObj = event.target.files[0]
    this.props.clearReferenceBoundaryLayer()

    let reader = new FileReader()

    reader.addEventListener(
      'load',
      (event) => {
        let file = event.target.result

        if (file) {
          let data = null
          if (this.state.fileType === 'csv') {
            data = fileObj
            this.setState({ file: data })
          } else if (this.state.fileType === 'kml') {
            data = toGeoJSON.kml(
              new DOMParser().parseFromString(file, 'text/xml')
            )
            this.setState({ file: JSON.stringify(data) })
          } else if (this.state.fileType === 'kmz') {
            this.getKmlDom(fileObj).then((kmlDom) => {
              let geoJsonObject = toGeoJSON.kml(kmlDom)
              this.setState({ file: JSON.stringify(geoJsonObject) })
            })
          } else if (this.state.fileType === 'geojson') {
            data = file
            this.setState({ file: data })
          } else {
            console.log('Error loading file.')
          }
        }
      },
      false
    )
    reader.readAsText(fileObj)
  }

  checkValidJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  getDom = (xml) => new DOMParser().parseFromString(xml, 'text/xml')
  getExtension = (fileName) => fileName.split('.').pop()

  getKmlDom = (kmzFile) => {
    var JSZip = require('jszip')
    var zip = new JSZip()
    return zip.loadAsync(kmzFile).then((zip) => {
      let kmlDom = null
      zip.forEach((relPath, file) => {
        if (this.getExtension(relPath) === 'kml' && kmlDom === null) {
          kmlDom = file.async('string').then(this.getDom)
        }
      })
      return kmlDom || Promise.reject('No kml file found')
    })
  }

  handleChange = (event) => {
    if (this.ref.current) {
      this.ref.current.value = ''
    }

    let fileType = event.target.value
    this.setState({ fileType: fileType })
  }

  renderFileType = () => {
    let fileTypes = ['csv', 'kml', 'kmz', 'geojson']
    return (
      <>
        <select
          name="file_type"
          onChange={this.handleChange}
          style={{ textTransform: 'capitalizae' }}
          value={this.state.fileType ? this.state.fileType : ''}
          required
        >
          <option value="" hidden disabled>
            Select File Type
          </option>
          {fileTypes.map((type, i) => {
            return (
              <option key={i} value={type}>
                {type}
              </option>
            )
          })}
        </select>
      </>
    )
  }

  renderInformation = () => {
    return (
      <>
        <h1 className="dialog__title">Import Custom Layer File</h1>
        {this.renderFileType()}
        {this.state.fileType && this.state.fileType === 'csv' && (
          <>
            <p>
              To import from CSV file, your file is required to contained{' '}
              <strong>Northing/Latitude</strong>,{' '}
              <strong>Easting/Longitude</strong>, and{' '}
              <strong>Projection</strong> headers as the first row of your file.
            </p>
            <p>
              Use <a href={layerTemplate}>this template</a> file as a guide to
              create your own file for upload.
            </p>
            <p>
              <strong>Note:</strong> Any additional columns in your CSV file
              will be imported and used for display purposes on marker tooltips.
            </p>
          </>
        )}

        {this.state.fileType && this.state.fileType === 'kml' && (
          <>
            <p>
              To import from KML file, please confirm the file follows proper
              kml file format.
            </p>
            <p>
              Use{' '}
              <a
                href={
                  'https://developers.google.com/kml/documentation/kml_tut#:~:text=KML%20is%20a%20file%20format,listed%20in%20the%20KML%20Reference.'
                }
              >
                this guide
              </a>{' '}
              as a guide to create your own file for upload.
            </p>
          </>
        )}

        {this.state.fileType && this.state.fileType === 'kmz' && (
          <>
            <p>
              To import from KML file, please confirm the file follows proper
              kmz file format.
            </p>
          </>
        )}

        {this.state.fileType && this.state.fileType === 'geojson' && (
          <>
            <p>
              To import from KML file, please confirm the file follows proper
              geojson file format.
            </p>
            <p>
              Use{' '}
              <a
                href={
                  'https://doc.arcgis.com/en/arcgis-online/reference/geojson.htm'
                }
              >
                this guide
              </a>{' '}
              as a guide to create you own file for upload.
            </p>
          </>
        )}
      </>
    )
  }

  render() {
    return (
      <>
        <section className="dialog">
          <div className="dialog__content">
            {this.renderInformation()}

            <label htmlFor="file-upload">
              {/* {this.state.fileType !== null && ( */}
              <input
                type="file"
                name="file-upload"
                accept={
                  this.state.fileType
                    ? '.' + this.state.fileType.toString()
                    : ''
                }
                onChange={this.onChangeFileHandler}
                onClick={(event) => {
                  event.target.value = null
                }}
                ref={this.ref}
                disabled={this.state.fileType ? false : true}
              />
              {/* )} */}
            </label>

            <label>
              {' '}
              Layer Name:
              <input
                type="text"
                name="layerName"
                onChange={(event) => {
                  this.setState({ layerName: event.target.value })
                }}
              />
            </label>

            <div className="dialog__controls">
              <button
                type="button"
                className="btn solid"
                onClick={this.uploadLayerFile}
              >
                Upload File
              </button>
              <button
                type="button"
                className="btn white"
                onClick={this.props.toggleImportDialog}
              >
                Cancel
              </button>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default LayerImportDialog
