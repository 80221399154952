/**
 * Random helper/utility functions
 */
import { Camera } from 'spexi-pathify'
import distance from '@turf/distance'
import store from './store'
import moment from 'moment'

export const createPolygonFromBounds = (latLngBounds) => {
  let latlngs = []

  latlngs.push(latLngBounds.getSouthWest()) //bottom left
  latlngs.push(latLngBounds.getSouthEast()) //bottom right
  latlngs.push(latLngBounds.getNorthEast()) //top right
  latlngs.push(latLngBounds.getNorthWest()) //top left

  return latlngs
}

export const convertStr = (string) => {
  return string.replace(/-/g, ' ').replace(/^./, function (x) {
    return x.toUpperCase()
  })
}

export const arrayLngLatTolatLng = (coordinates) => {
  let newCoordinates = coordinates.map((coordinate) => {
    return [coordinate[1], coordinate[0]]
  })
  return newCoordinates
}

export const swapLngLatTolatLng = (coordinates) => {
  return [coordinates[1], coordinates[0]]
}

export const formatTime = (time) => {
  // Hours, minutes and seconds
  time = Math.round(time)
  let hrs = ~~(time / 3600)
  let mins = ~~((time % 3600) / 60)
  let secs = time % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs

  return ret
}

export const formatDate = (date) => {
  return date
    ? moment(date).tz(store.getState().userTimezone).format('MMM D, YYYY')
    : '-'
}

export const formatDateTime = (date) => {
  return date
    ? moment(date).tz(store.getState().userTimezone).format('MMM D, YYYY HH:MM')
    : '-'
}

export const formatTimestampToDate = (date) => {
  return date
    ? moment.unix(date).tz(store.getState().userTimezone).format('MMM D, YYYY')
    : '-'
}

export const formatTimestampToDatetime = (date) => {
  return date
    ? moment
      .unix(date)
      .tz(store.getState().userTimezone)
      .format('MMM D, YYYY HH:MM')
    : '-'
}

export const getCamera = (droneList, cameraModel) => {
  if (cameraModel === null) {
    return new Camera()
  }
  let drone = droneList.find((elem) => elem.camera.id === cameraModel)
  if (drone) {
    let camera = { ...drone.camera, focalLength: drone.camera.focal_length }
    return camera
  }
  return new Camera()
}

// get the distance between my position and project location
export function getDistanceToProject(myPosition, project) {
  if (myPosition && project) {
    let myLocationCoord = [myPosition[1], myPosition[0]]
    let projectCoord = project.centroid.coordinates
    return distance(myLocationCoord, projectCoord).toFixed(1)
  }
  return null
}

export function isProjectFavorite(projectId) {
  let state = store.getState()
  let user = state.user
  if (user === null || user.favoriteProjects === null) {
    return false
  }
  return user.favoriteProjects.findIndex((x) => x === projectId) !== -1
}

export function getProcessingStatus(job) {
  if (job && job.current_task) {
    switch (job.current_task.type.id) {
      case 'add-photos':
      case 'add-photos-pano':
        return 'Adding photos'
      case 'match-photos':
        return 'Matching photos'
      case 'align-cameras':
        // return 'Aligning cameras'
        // Temporarily renaming this to below
        return 'Matching photos'
      case 'add-gcps':
        return 'Adding ground control points'
      case 'refine-markers':
        // return 'Refining markers'
        // Temporarily renaming this to below
        return 'Refining GCPs'
      case 'optimize-cameras':
        return 'Optimizing cameras'
      case 'build-depth-maps':
        return 'Generating depth maps'
      case 'build-dense-cloud':
        // return 'Building dense cloud'
        // Temporarily renaming this to below
        return 'Generating Point Cloud'
      case 'build-dem':
        return 'Generating elevation map'
      case 'build-orthomosaic':
        return 'Generating orthomosaic'
      case 'shrinkwrap-region':
        return 'Shrink-wrapping region'
      case 'export':
        return 'Exporting'
      case 'build-model':
        return 'Generating model'
      case 'build-uv':
        return 'Generating texture atlas'
      case 'build-texture':
        return 'Generating texture'
      case 'build-atlas':
        return 'Generating atlas'
      case 'build-levels':
      case 'build-panorama-levels':
      case 'build-orbit-levels':
        return 'Generating levels'
      case 'build-dzi':
        return 'Generating DeepZoom'
      case 'find-control-points':
        return 'Finding control points'
      case 'optimize-panorama':
        return 'Optimizing panorama'
      case 'build-sphere-map':
      case 'export-pano':
        return 'Generating sphere map'
      case 'build-cube-map':
        return 'Generating cube map'
      case 'build-tiled-model':
        return 'Generating tiled model'
      case 'convert-point-cloud':
        return 'Converting point cloud data'
      default:
        return 'Loading'
    }
  }
  return 'Finalizing...'
}

export function getJobStatus(job) {
  if (!job) {
    return ''
  }
  if (job.status === 'new') {
    return 'Waiting to be queued'
  } else if (job.status === 'queued') {
    return 'Waiting for next available processing slot'
  } else if (job.status === 'running') {
    return `${getProcessingStatus(job)} ${
      job.current_task && job.current_task.progress
        ? ` (${job.current_task.progress}%)`
        : ''
    }`
  } else if (job.status === 'failed') {
    return `An error occurred while ${getProcessingStatus(job)}`
  } else if (job.status === 'paused') {
    if (job.current_task && job.current_task.executor === 'human') {
      return `Action required: (${getProcessingStatus(job)})`
    } else {
      return 'Processing has been paused'
    }
  } else if (job.status === 'done') {
    return `Completed ${moment(job.updated_at)
      .tz(store.getState().userTimezone)
      .calendar()}`
  } else {
    return ''
  }
}

export function getJobProgress(job) {
  if (job && job.status !== 'done' && job.tasks) {
    let doneSteps = job.tasks.filter(
      (task) => task.status === 'done' || task.status === 'skipped'
    )
    let progress = (doneSteps.length / job.tasks.length) * 100
    // processing pipeline doesn't really know when something is 100% finished, and returns step 1 of 1
    // when there is still progress remaining. the following is a visual fudge factor to not confuse the user
    progress = Math.max(0, Math.min(progress, 95))
    return progress
  }
  return 0.0
}

export function getDefaultProductName(productType) {
  if (productType === 'map' || productType === 'map+3d') {
    return 'Mapping'
  } else if (productType === 'orbit') {
    return 'Orbit'
  } else if (productType === 'gallery') {
    return 'Image Gallery'
  } else if (productType === 'panorama') {
    return 'Panorama'
  } else if (productType === '3d') {
    return '3D from LAS/LAZ'
  } else if (productType === 'video') {
    return 'Intro Video'
  }
  return 'Product'
}

export function getProductName(product) {
  return product.name ? product.name : getDefaultProductName(product.type)
}

export const PRODUCT_TYPES = [
  {
    name: 'map',
    label: 'Map',
  },
  {
    name: 'map+3d',
    label: 'Map & 3D Model',
  },
  {
    name: 'panorama',
    label: 'Panorama',
  },
  {
    name: 'orbit',
    label: 'Orbit',
  },
  {
    name: 'gallery',
    label: 'Image Gallery',
  },
  {
    name: 'video',
    label: 'Intro Video',
  },
]

export const THREED_PRODUCT_TYPE = {
  name: '3d',
  label: '3D from LAS/LAZ',
}

export function getBaseUrl() {
  let getUrl = window.location
  return `${getUrl.protocol}//${getUrl.host}`
}

/**
 * Transform stripe plan id to human friendly string
 * @param {*} stripePlanId
 * return {string}
 */
export const getStripePlanDescription = (stripePlanId, subscriptionPlans) => {
  if (subscriptionPlans[stripePlanId]?.nickname)
    return subscriptionPlans[stripePlanId]?.nickname

  if (stripePlanId === '300monthlyannual') {
    return '$300/month (annual commitment)'
  } else if (stripePlanId === '500monthlyannual') {
    return '$500/month (annual commitment)'
  } else if (stripePlanId === '1000monthlyannual') {
    return '$1000/month (annual commitment)'
  } else if (stripePlanId === '2000monthlyannual') {
    return '$2000/month (annual commitment)'
  } else if (stripePlanId === '10000monthlyorg') {
    return '10,000 photos'
  } else {
    return stripePlanId
  }
}

/**
 * Check if the subdomain is a custom (non-Spexi) org domain
 * and return the subdomain string if it is
 */
export const getCustomOrgSubDomain = () => {
  let subdomain = window.location.host.split('.')[1]
    ? window.location.host.split('.')[0]
    : false
  if (subdomain !== 'fly' && subdomain !== 'staging-fly') {
    return subdomain
  } else {
    return null
  }
}
