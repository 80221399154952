import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'

import { createRootReducer } from './reducers/'

import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history)
]

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)


const store = createStore(
  createRootReducer(history),
  initialState,
  composedEnhancers
)


if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./reducers/', () => {
      store.replaceReducer(createRootReducer)
    })
  }
}

export default store
