import React from 'react'
import { Tooltip } from 'react-leaflet'

const AnnotationTooltip = props => {
  const { annotation } = props

  return (
    <Tooltip direction='top' offset={[0, -20]} opacity={1}>
      <span>
        <strong>{annotation.name}</strong>
        {annotation.type === 'map-volume' && (
          <div className="tooltip-measurement">
            <span className="type">Volume:</span> {annotation.data && annotation.data.total && <span className="tooltip-measurement__number">{annotation.data.total.toFixed(3)} m<sup>3</sup></span>}
          </div>
        )}

        {annotation.type === 'map-area' && (
          <div className="tooltip-measurement">
            <span className="type">Area:</span> {annotation.data && annotation.data.area_2d && <span className="tooltip-measurement__number">{annotation.data.area_2d.toFixed(3)} m<sup>2</sup></span>}
          </div>
        )}

        {annotation.type === 'map-distance' && (
          <div className="tooltip-measurement">
            <span className="type">Distance:</span> {annotation.data && annotation.data.distance && <span className="tooltip-measurement__number">{annotation.data.distance.toFixed(3)} m</span>}
          </div>
        )}

        {annotation.type === 'map-location' && (
          <div className="tooltip-measurement">
            <span className="type">Elevation:</span> {annotation.data && annotation.data.elevation && <span className="tooltip-measurement__number">{annotation.data.elevation.toFixed(3)} m</span>}
          </div>
        )}
      </span>
    </Tooltip>
  )
}

export default AnnotationTooltip