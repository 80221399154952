import React from 'react'
import './styles.css'

const FlightEstimates = props => (
  <section className="estimates">
    <div className="estimates__figure">
      <span className="estimates__number">{props.minutes}</span>
      <span className="estimates__label">Time</span>
    </div>

    {props.acres && (
      <div className="estimates__figure">
        <span className="estimates__number">{props.acres}</span>
        <span className="estimates__label">Acres</span>
      </div>

    )}


    <div className="estimates__figure">
      <span className="estimates__number">{props.photoCount}</span>
      <span className="estimates__label">Images</span>
    </div>

    <div className="estimates__figure">
      <span className="estimates__number">{props.batteries}</span>
      <span className="estimates__label">Batteries</span>
    </div>

  </section>

)

export default FlightEstimates