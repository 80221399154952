import React from 'react'
import { Map, Marker, Popup, LayersControl } from 'react-leaflet'
import { GoogleLayer } from 'react-leaflet-google'
import L from 'leaflet'
import markerIcon from '../MainMap/marker.png'
import previewUploadedMarkerIcon from '../MainMap/preview-uploaded-marker.png'
import AddProductAdvancedOptions from '../AddProductAdvancedOptions'
import './styles.css'
import { getDefaultProductName, PRODUCT_TYPES, THREED_PRODUCT_TYPE } from '../../helpers'
import { Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const { BaseLayer } = LayersControl
const key = 'AIzaSyBE8iWMY6ucUjg54gBf5V--mOhy9O15AIM'
const terrain = 'TERRAIN'
const road = 'ROADMAP'
const satellite = 'SATELLITE'
const hydrid = 'HYBRID'


const markerImage = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [37, 37],
  iconAnchor: [19, 19]
})

const previewUploadedMarkerImage = new L.Icon({
  iconUrl: previewUploadedMarkerIcon,
  iconSize: [11, 11],
  iconAnchor: [6, 6],
  zindex: 5,
})


const AddProductSidebar = (props) => {
  
  let viewTitle = props.productForm.editing ? 'Edit Product' : 'Create Product'

  /**
   * Preview markers used when uploading images and generating
   * a visual of where each image's geocoordinates exist on the map
   */
   const renderPreviewMarkers = (previewMarkers, isUploaded) => {
    if (previewMarkers.length > 0) {
      return previewMarkers
        .filter(marker => marker.coordinates[0] !== 0)
        .map((marker, index) => {
          let icon
          if (marker.icon) {
            icon = marker.icon
          } else {
            icon = isUploaded ? previewUploadedMarkerImage : markerImage
          }
          return (
            <Marker key={index} position={marker.coordinates} icon={icon} className={marker.className ? marker.className : 'preview-marker'}>
              <Popup className={marker.className ? marker.className : 'preview-marker'}>
                <span>
                  <h3>{marker.name}</h3>
                  {marker.preview && (
                    <img src={marker.preview} alt={marker.name} width="100%" />
                  )}
                </span>
              </Popup>
            </Marker>
          )
        })
    }
  }

  const smClassName = props.mobileScreen ? "sm" : "";

  const productTypesParameters = ['map', 'map+3d', 'panorama', 'orbit']
  if (props.user.isAdmin) {
    productTypesParameters.push('gallery')
  }

  return (
    <aside className={`upload-sidebar ${smClassName}`}>
      {props.mobileScreen && (
        <CloseIcon onClick={props.closeDialog} className="upload-sidebar__close-icon"/>
      )}

      <h3 className="upload-sidebar__title">{viewTitle}</h3>

      {props.mobileScreen && (
        <div className="sidebar__view-images-option" onClick={props.toggleImagesView}>
          <i className="fa fa-image" /><Typography variant="subtitle2">View Images</Typography>
        </div>
      )}

      {props.project.status === 'draft' && (
        <p>This project is not currently published. Please publish it before uploading.</p>
      )}


      <label htmlFor="productType">
        Product Type
        <select required value={props.productForm.type} disabled={props.productForm.editing} onChange={props.handleChange} name="type">
          <option value='' disabled> -- Select a product type -- </option>
          {PRODUCT_TYPES.map(type => {
            return <option key={type.name} value={type.name}>{type.label}</option>
          })}
          {props.user.hasActiveSubscription ? 
            <option key={THREED_PRODUCT_TYPE.name} value={THREED_PRODUCT_TYPE.name}>{THREED_PRODUCT_TYPE.label}</option> : ''}
        </select>
      </label>

      <label>Product Name
        <input required type="text" value={props.productForm.name} onChange={props.handleChange} placeholder={getDefaultProductName(props.productForm.type)} name="name" />
      </label>

      <label>Capture Date
        <input required type="date" value={props.productForm.capture_date} onChange={props.handleChange} placeholder={`2019-03-14`} name="capture_date" />
      </label>

      {props.teams && props.teams.length > 0 && props.teams.filter(team => team.wallet_id && team.wallet.balance > 0).length > 0 && (
        <label>Withdraw Wallet
          <select name="wallet_id" value={props.productForm.wallet_id || props.user.wallet.id} onChange={props.handleChange}>
            <option value="" hidden>Select wallet to withdraw from...</option>
            {props.user && props.user.wallet.id && <option value={props.user.wallet.id}>{props.user.name}'s Wallet - {props.user.wallet.balance} credits</option>}
            {props.teams && props.teams.map(team => {
              let members = team.members.map((member, index) => {
                return member.user.name + (team.members.length - 1 !== index ? ', ' : '')
              })
              if (team.wallet_id && team.wallet.balance > 0) {
                return <option key={team.wallet_id} value={team.wallet_id}>Team Wallet: {team.name} - {team.wallet.balance} credits -- ({members})</option>
              } else {
                return ''
              }
            })}
          </select>
        </label>

      )}
      
      {props.productForm &&
        props.productForm.type &&
        productTypesParameters.includes(props.productForm.type) &&
        <AddProductAdvancedOptions {...props} />
      }

      {props.productForm &&
        props.productForm.type &&
        ['map', 'map+3d', 'orbit', 'panorama'].includes(props.productForm.type) &&
          <article style={{ height: 296 }}>
            {props.productForm.showMapPreview === false ? (
              <h4>Preview markers and thumbnails not generated to improve performance of large dataset queued for upload.</h4>
            ) : (
              <>
                <h4 className="section-title">Map Preview</h4>
                <Map
                  center={props.productMapPreview.center}
                  maxZoom={21}
                  maxNativeZoom={21}
                  zoom={props.productMapPreview.zoom}
                  zoomControl={false}
                  animate={true}
                  onEdited={props.handleEdit}
                  ref={props.handleMapRef}
                  whenReady={props.mapRef}
                  onViewportChanged={props.onViewportChanged}
                  editable={true}
                  boxZoom={false}
                  className={props.expandedMap ? 'expanded' : ''}
                >
                  <i className="material-icons expand" onClick={props.toggleMapSize}>{!props.expandedMap ? 'fullscreen' : 'fullscreen_exit'}</i>
                  <LayersControl position='bottomright'>
                    <BaseLayer name='Roads'>
                      <GoogleLayer maxZoom={21} googlekey={key} maptype={road} />
                    </BaseLayer>
                    <BaseLayer name='Terrain'>
                      <GoogleLayer maxZoom={21} googlekey={key} maptype={terrain} />
                    </BaseLayer>
                    <BaseLayer name='Satellite'>
                      <GoogleLayer maxZoom={21} googlekey={key} maptype={satellite} />
                    </BaseLayer>
                    <BaseLayer checked name='Hydrid'>
                      <GoogleLayer maxZoom={21} googlekey={key} maptype={hydrid} libraries={['geometry', 'places', 'drawing']} />
                    </BaseLayer>
                  </LayersControl>
                  {renderPreviewMarkers(props.previewMarkers, false)}
                  {renderPreviewMarkers(props.previewUploadedMarkers, true)}
                </Map>
              </>
            )}
          </article>
        }
    </aside> 
  )
}

export default AddProductSidebar;