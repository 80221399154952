import React from 'react'
import { Link } from 'react-router-dom'

import './styles.css'

const NotFound = ({ location }) => (
  <main>
    <section id="not-found-section">
      <div className="bg"></div>
      <div className="container">
        <div className="half first">
          <a href="/" className="site-name"><img src={process.env.PUBLIC_URL + '/assets/img/logo.png'} alt="Spexi Geospatial" width="160" height="41" /></a>

          <div className="not-found-copy">
            <h3>Sorry. Nothing to see here!</h3>
            <p>No match for <code>{location.pathname}</code></p>
            <p><Link className="btn" to="/">Visit dashboard</Link></p>
          </div>
        </div>

        <div className="half second">
        </div>

      </div>
    </section>
  </main>

)


export default NotFound