import React, { useState, useEffect, useRef } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import stripeBadge from './powered_by_stripe.svg'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

// Custom styling can be passed to options when creating an Element.
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Montserrat", sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChange} />
  </div>
)

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <TextField
    id={id}
    label={label}
    value={value}
    className="FormRowInput"
    type={type}
    required={required}
    onChange={onChange}
    placeholder={placeholder}
    autoComplete={autoComplete}
  />
)

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`btn SubmitButton ${error ? 'btn SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? 'Processing...' : children}
  </button>
)

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    <svg width="16" height="16" viewBox="0 0 17 17">
      <path
        fill="#FFF"
        d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
      />
      <path
        fill="#6772e5"
        d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
      />
    </svg>
    {children}
  </div>
)

const CheckoutForm = (props) => {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState(null)
  const [cardComplete, setCardComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [paymentIntent, setPaymentIntent] = useState(null)
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    email: '',
    phone: '',
    address: {
      line1: '',
      city: '',
      postal_code: '',
      state: '',
      country: '',
    },
  })
  const ProvinceSelectRef = useRef(null)

  const { paymentSummary, clientSecret, checkPaymentIntent } = props

  useEffect(() => {
    checkPaymentIntent()
  }, [checkPaymentIntent])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    if (error) {
      elements.getElement('card').focus()
      return
    }

    if (cardComplete) {
      setProcessing(true)
    }

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      },
    })

    setProcessing(false)

    if (payload.error) {
      setError(payload.error)
    } else {
      setPaymentIntent(payload.paymentIntent)

      // get user balance after enough time for stripe's system to hit our API's webhook endpoint
      setTimeout(() => {
        props.getLoggedInUser()
        props.getUserTeams()
      }, 3000)
    }
  }

  return paymentIntent ? (
    <div className="input-form">
      {paymentIntent.status === 'succeeded' && (
        <>
          <h2
            className="sidebar-header__title"
            style={{ marginBottom: '1rem' }}
          >
            Payment Successful
          </h2>
          <p>
            Your card has been successfully processed. A receipt was sent to
            your email address.
          </p>
        </>
      )}
    </div>
  ) : (
    <form className="Form" onSubmit={handleSubmit} autoComplete="off">
      <div className="input-form">
        <fieldset className="FormGroup">
          <h2 className="sidebar-header__title">Billing Information</h2>
          <Field
            label="Full Name (or Company Name)"
            id="name"
            type="text"
            placeholder="Jane Doe"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value })
            }}
          />
          <Field
            label="Email"
            id="email"
            type="email"
            placeholder="janedoe@gmail.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, email: e.target.value })
            }}
          />
          <Field
            label="Phone"
            id="phone"
            type="tel"
            placeholder="(941) 555-0123"
            required
            autoComplete="tel"
            value={billingDetails.phone}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, phone: e.target.value })
            }}
          />
          <Field
            label="Address"
            id="address.line1"
            type="text"
            placeholder="123 Cherry Lane"
            required
            autoComplete="address.line1"
            value={billingDetails.address.line1}
            onChange={(e) => {
              setBillingDetails({
                ...billingDetails,
                address: {
                  ...billingDetails.address,
                  line1: e.target.value,
                },
              })
            }}
          />
          <Field
            label="City"
            id="address.city"
            type="text"
            placeholder="Vancouver"
            required
            autoComplete="address.city"
            value={billingDetails.address.city}
            onChange={(e) => {
              setBillingDetails({
                ...billingDetails,
                address: {
                  ...billingDetails.address,
                  city: e.target.value,
                },
              })
            }}
          />

          <FormControl className="FormRowInput">
            <InputLabel htmlFor="address.state" required>
              Province/State
            </InputLabel>
            <Select
              native
              required
              value={billingDetails.address.state}
              ref={ProvinceSelectRef}
              onChange={(e) => {
                props.setRequestInProgress(true, 'GLOBAL')
                // Note: really long way to get country grouping form DOM list.
                // This is will be useless once I add more countries
                let parentCountry = ''
                let selectOptions = ProvinceSelectRef.current.children

                for (let index = 0; index < selectOptions.length; index++) {
                  const options = selectOptions[index].options
                  if (options) {
                    let optionsArray = Array.from(options)
                    for (let i = 0; i < optionsArray.length; i++) {
                      const child = optionsArray[i]
                      if (child.value === e.target.value) {
                        parentCountry =
                          child.parentNode.getAttribute('data-countrycode')
                      }
                    }
                  }
                }

                let newAddress = {
                  ...billingDetails,
                  address: {
                    ...billingDetails.address,
                    state: e.target.value,
                    country: parentCountry,
                  },
                }
                setBillingDetails(newAddress)

                // update payment intent on backend to get new payment summary including tax
                checkPaymentIntent(newAddress)
              }}
              inputProps={{
                name: 'address.state',
                id: 'address.state',
              }}
            >
              <option value="" />
              <optgroup label="Canadian Provinces" data-countrycode="CA">
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NT">Northwest Territories</option>
                <option value="NS">Nova Scotia</option>
                <option value="NU">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon Territory</option>
              </optgroup>
              <optgroup label="U.S. States/Territories" data-countrycode="US">
                <option value="AK">Alaska</option>
                <option value="AL">Alabama</option>
                <option value="AR">Arkansas</option>
                <option value="AZ">Arizona</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DC">District of Columbia</option>
                <option value="DE">Delaware</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="IA">Iowa</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="MA">Massachusetts</option>
                <option value="MD">Maryland</option>
                <option value="ME">Maine</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MO">Missouri</option>
                <option value="MS">Mississippi</option>
                <option value="MT">Montana</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="NE">Nebraska</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NV">Nevada</option>
                <option value="NY">New York</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="PR">Puerto Rico</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VA">Virginia</option>
                <option value="VT">Vermont</option>
                <option value="WA">Washington</option>
                <option value="WI">Wisconsin</option>
                <option value="WV">West Virginia</option>
                <option value="WY">Wyoming</option>
              </optgroup>
            </Select>
          </FormControl>

          <Field
            label="Postal Code/Zip Code"
            id="address.postal_code"
            type="text"
            placeholder="V2E 2R8"
            required
            autoComplete="address.postal_code"
            value={billingDetails.address.postal_code}
            onChange={(e) => {
              setBillingDetails({
                ...billingDetails,
                address: {
                  ...billingDetails.address,
                  postal_code: e.target.value,
                },
              })
            }}
          />
          <FormControl className="FormRowInput">
            <InputLabel htmlFor="address.country" required>
              Country
            </InputLabel>
            <Select
              native
              required
              value={billingDetails.address.country}
              onChange={(e) => {
                setBillingDetails({
                  ...billingDetails,
                  address: {
                    ...billingDetails.address,
                    country: e.target.value,
                  },
                })
              }}
              inputProps={{
                name: 'address.country',
                id: 'address.country',
              }}
            >
              <option value="" />
              <option value="CA">Canada</option>
              <option value="US">United States</option>
              {/* <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="BE">Belgium</option>
              <option value="BR">Brazil</option>
              <option value="CA">Canada</option>
              <option value="CN">China</option>
              <option value="DK">Denmark</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="DE">Germany</option>
              <option value="HK">Hong Kong</option>
              <option value="IE">Ireland</option>
              <option value="IT">Italy</option>
              <option value="JP">Japan</option>
              <option value="LU">Luxembourg</option>
              <option value="MY">Malaysia</option>
              <option value="MX">Mexico</option>
              <option value="NL">Netherlands</option>
              <option value="NZ">New Zealand</option>
              <option value="NO">Norway</option>
              <option value="PT">Portugal</option>
              <option value="SG">Singapore</option>
              <option value="ES">Spain</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="GB">United Kingdom</option> */}
            </Select>
          </FormControl>
        </fieldset>

        <fieldset className="FormGroup">
          <h2 className="sidebar-header__title">Payment Information</h2>
          {!!paymentSummary && paymentSummary.taxes.length > 0 && (
            <section className="payment-summary">
              <div className="payment-summary__row">
                <span>Subtotal:</span>{' '}
                <span>
                  {formatter.format((paymentSummary.subTotal / 100).toFixed(2))}
                </span>
              </div>
              {paymentSummary.taxes.map((tax) => (
                <div className="payment-summary__row" key={tax.taxAmout}>
                  <span>Tax ({tax.taxLabel}):</span>{' '}
                  <span>
                    {formatter.format((tax.taxAmount / 100).toFixed(2))}
                  </span>
                </div>
              ))}
              <div className="payment-summary__row">
                <strong>Total:</strong>{' '}
                <span>
                  {formatter.format((paymentSummary.total / 100).toFixed(2))}
                </span>
              </div>
            </section>
          )}
          <CardField
            onChange={(e) => {
              setError(e.error)
              setCardComplete(e.complete)
            }}
          />
          <div
            style={{
              textAlign: 'left',
              marginTop: '-0.5rem',
              display: 'flex',
              alignItems: 'center',
              lineHeight: 0,
            }}
          >
            <a
              href="https://stripe.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img width="100" src={stripeBadge} alt="Powered by Stripe" />
            </a>
            <span
              style={{
                fontSize: '0.7rem',
                fontWeight: '500',
                color: '#a0a3b9',
                marginLeft: '0.5rem',
              }}
            >
              Secure AES-256 Encrypted Checkout
            </span>
          </div>
        </fieldset>
        {error && <ErrorMessage>{error.message}</ErrorMessage>}

        <div className="form-options">
          <button
            type="button"
            className="btn white"
            onClick={() => props.transitionStep(0)}
          >
            Back
          </button>
          {!!paymentSummary && paymentSummary.total > 0 && (
            <SubmitButton
              processing={processing}
              error={error}
              disabled={!stripe}
            >
              Pay {formatter.format((paymentSummary.total / 100).toFixed(2))}{' '}
              CAD
            </SubmitButton>
          )}
        </div>
      </div>
    </form>
  )
}

export default CheckoutForm
