import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.css'

import { Line } from 'rc-progress'

import { getJobStatus, getJobProgress, getProductName } from '../../helpers'
import ProductIcon from '../ProductIcon'

class ProductUploadList extends Component {
  static propTypes = {
    products: PropTypes.array.isRequired,
    isAddingProject: PropTypes.bool.isRequired,
    isEdittingProject: PropTypes.bool.isRequired,
  }

  listClickHandler = id => {
    if(this.props.products.filter(product => product.id === id)[0].type === 'spexigon-multipano') {
      this.props.setAlertModal({ message: "Can't open file upload modal on Spexigon datasets.", type: 'error' })
      return
    }
    this.props.setIsUploadingImages(true)
    this.props.setProductForm({
      ...this.props.products.filter(product => product.id === id)[0],
      active: true,
      editing: true
    })
  }

  render() {
    return (
      <section className="product_uploads">
        <h4 className="product_uploads__title">Products</h4>
        {this.props.requestsInProgress.PRODUCTS && (
          <div className="lds-ring" style={{ marginTop: '1.5rem', marginBottom: '1.5rem', transform: 'translateX(-50%)', left: '50%' }}><div></div><div></div><div></div><div></div></div>
        )}
        {!this.props.requestsInProgress.PRODUCTS && (
          <div className="product_uploads__list">
            {this.props.products &&
              this.props.products
                .map(product => {
                  let job = product.job
                  let progress = getJobProgress(job)
                  return (
                    <div className="product_uploads__item" key={product.id} onClick={() => { this.listClickHandler(product.id) }} data-productid={product.id} title={`Status: ${getJobStatus(job)} (Product ID: ${product.id})`}>
                      <ProductIcon product={product} />
                      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <h4>{getProductName(product)}</h4>
                        {job &&
                          <div>
                            {job.current_task !== null && job.status === 'paused' &&
                              <i className="fa fa-exclamation-triangle" style={{ color: '#A0A0A0', marginRight: '0.25rem' }} />
                            }
                            <span className="product__status">{getJobStatus(job)}</span>
                            {job.current_task !== null &&
                              job.status !== 'paused' &&
                              job.status !== 'failed' &&
                              job.status !== 'done' &&
                              <Line percent={progress} strokeWidth="3" strokeColor="rgba(3,169,244,1)" trailWidth="2" />
                            }
                          </div>
                        }
                      </div>
                    </div>
                  )
                })}
          </div>
        )}
      </section>
    )
  }
}

export default ProductUploadList
