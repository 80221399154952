import { ProductMapLayers } from '../api'
import { setRequestInProgress } from './request'
import { setAlertModal } from './ui'
import { alertTypes } from '../util/constants'

import L, { featureGroup } from 'leaflet'

export function setProductMapLayersData(productMapLayers) {
  return {
    type: 'SET_PRODUCT_MAP_LAYERS',
    productMapLayers,
  }
}

/**
 * Fetch map layers list from API
 */
export function fetchProductMapLayersData(product_id) {
  return (dispatch, getState) => {
    dispatch(setRequestInProgress(true, 'MAP_LAYERS'))
    console.log('getting map layers from server')

    return ProductMapLayers.getAll(product_id).then((response) => {
      dispatch(setRequestInProgress(false, 'MAP_LAYERS'))
      dispatch(setProductMapLayersData(response.data))
      
      const { leafletMapElement } = getState()
      
      let group = new featureGroup()
      let dataArray = []
      
      // adding geojson layers to bounding area
      response.data.forEach(data => {
        const layer = L.geoJSON(data.geojson)
        dataArray.push(layer)
      })

      if (leafletMapElement) {
        // adding anotations to bounding area
        leafletMapElement.eachLayer(function (layer) {
          if (
            layer.feature !== undefined ||
            layer.options.className === 'annotation' ||
            layer.options.className === 'annotation-marker'
          ) {
            dataArray.push(layer)
          }
        })
        if (dataArray.length > 0) {
          dataArray.forEach((data) => {
            group.addLayer(data)
          })
          if (group.getBounds()) {
            leafletMapElement.fitBounds(group.getBounds(), {
              padding: [20, 20],
            })
          }
        }
      }
    })
  }
}

export function toggleProductMapLayerVisibility(id) {
  return {
    type: 'TOGGLE_PRODUCT_MAP_LAYER_VISIBILITY',
    id,
  }
}

export function toggleProductMapLayerColorPicker(id) {
  return {
    type: 'TOGGLE_PRODUCT_MAP_LAYER_COLOR_PICKER',
    id,
  }
}

/**
 * Delete product map layer
 */
export function deleteProductMapLayer(layer_id) {
  return (dispatch, getState) => {
    const { product } = getState()

    dispatch(
      setAlertModal({
        message: 'Are you sure that you want to delete this map layer?',
        type: alertTypes.warning,
        cancellable: true,
        confirmHandler: () => {
          dispatch(setRequestInProgress(true, 'MAP_LAYERS'))
          return ProductMapLayers.delete(layer_id).then((response) => {
            console.log(response)
            dispatch(fetchProductMapLayersData(product.id))
          })
        },
      })
    )
  }
}

/**
 * Delete product map layer
 */
export function changeLayerColor(color, layer_id) {
  return (dispatch, getState) => {
    const { product } = getState()

    return ProductMapLayers.update(layer_id, { color }).then((response) => {
      dispatch(fetchProductMapLayersData(product.id))
    })
  }
}
