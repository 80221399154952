import React from 'react'
import './styles.css'

const OrganizationHelpModal = (props) => {
  const {
    organization_roles,
    organization_permissions,
    role_has_permissions,
    updateParentState,
  } = props

  const renderRoles = () => {
    return organization_roles.map((role, i) => {
      return (
        <th key={i} scope="col">
          {role.name}
        </th>
      )
    })
  }

  const renderPermissions = () => {
    return organization_permissions.map((permission, i) => {
      return (
        <tr
          key={i}
          style={{ backgroundColor: i % 2 === 0 ? '#FDFEFE' : '#F4F6F6' }}
        >
          <td>
            <span
              title={permission.description}
              // style={{ textTransform: 'capitalize' }}
            >
              {permission.description}
            </span>
          </td>
          {getPermissionCheckmark(1, permission.id)}
          {getPermissionCheckmark(2, permission.id)}
          {getPermissionCheckmark(3, permission.id)}
          {getPermissionCheckmark(4, permission.id)}
          {getPermissionCheckmark(5, permission.id)}
        </tr>
      )
    })
  }

  const getPermissionCheckmark = (role_id, permission_id) => {
    if (
      role_has_permissions.some(
        (p) => p.role_id === role_id && p.permission_id === permission_id
      )
    ) {
      return (
        <td className="check__container">
          <i className="material-icons check__icon">check</i>
        </td>
      )
    } else {
      return (
        <td className="check__container">
          <i className="material-icons x_icon">close</i>
        </td>
      )
    }
  }

  return (
    <section className="help-dialog">
      <div className="help-dialog__content">
        <h1 className="help-dialog__title">Organizations</h1>
        <table className="help-table__outer">
          <thead>
            <tr className="help-table__col">
              <th scope="col"></th>
              {renderRoles()}
            </tr>
          </thead>
          <tbody>{renderPermissions()}</tbody>
        </table>

        <div className="help-dialog__controls">
          <button
            type="button"
            className="btn solid"
            onClick={() => updateParentState('isHelpClicked', false)}
          >
            Close
          </button>
        </div>
      </div>
    </section>
  )
}

export default OrganizationHelpModal
