import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import './styles.css'
import { OrganizationApi } from '../../api'
import { getCustomOrgSubDomain } from '../../helpers'
import GoogleAuth from '../GoogleAuth'

class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      password: '',
      referralCode: this.props.match.params.code
        ? this.props.match.params.code
        : '',
      passwordTextVisibility: false,
      userIsLoggedIn: false,
      isOrganizationLoading: false,
      organization: null,
      orgId: null,
      is_tos_agreed: false,
    }
  }

  componentDidMount() {
    // check if the user is already logged in or not
    const token = localStorage.getItem('jwt-token')
    let userIsLoggedIn = false
    if (token) {
      userIsLoggedIn = true
    }

    // if this is an org sign up link, modify the signu
    let params = new URLSearchParams(window.location.search)
    const orgSubdomain = getCustomOrgSubDomain()
    const orgId = params.get('org') || orgSubdomain
    if (orgId) {
      this.setState({ isOrganizationLoading: true })
      OrganizationApi.get(orgId)
        .then((json) => {
          if (json.data) {
            const organization = json.data
            this.setState({
              organization,
              isOrganizationLoading: false,
              orgId: organization.id,
            })
          }
        })
        .catch((json) => {
          console.error(`Failed to fetch information for org ID ${orgId}`)
          console.error(json)
        })
    }

    // update our state...
    this.setState({
      userIsLoggedIn,
    })
  }

  handleInput = () => {
    this.setState({
      name: this.refs.name.value,
      email: this.refs.email.value,
      password: this.refs.password.value,
    })
  }

  /**
   * log in form submitted
   */
  onSignupSubmit = (e) => {
    e.preventDefault()

    if (this.refs.email.value.length && this.refs.password.value.length) {
      this.props.userRegister(
        this.refs.name.value,
        this.refs.email.value,
        this.refs.password.value,
        this.refs.referral_code.value,
        this.state.orgId
      )
    }
  }

  render() {
    let visibilityIcon = this.state.passwordTextVisibility
      ? 'visibility'
      : 'visibility_off'
    const logoUrl =
      this.state.organization && this.state.organization.logo_url
        ? this.state.organization.logo_url
        : '/assets/img/spexigeo-logo-white.png'

    // if the user is already logged in, see if this is a singup and join org link, if so redirect there
    if (this.state.userIsLoggedIn) {
      // if this was an org join link, send them to the appropriate org join page
      let params = new URLSearchParams(window.location.search)
      const organization = params.get('org')
      if (organization) {
        return <Redirect to={`/organizations/join/${organization}`} />
      }
      return <Redirect to="/" />
    } else {
      return (
        <main>
          <section id="signup-page">
            <div className="bg"></div>
            <div className="container">
              <div className="half first" style={{ textAlign: 'center' }}>
                {!this.state.isOrganizationLoading && (
                  <a href="/" className="site-name">
                    <img src={logoUrl} alt="Spexi Geospatial" width="340" />
                  </a>
                )}

                <form
                  id="login-form"
                  acceptCharset="UTF-8"
                  onSubmit={this.onSignupSubmit}
                >
                  <h3 style={{ textAlign: 'center' }}>Join Our Platform</h3>

                  <p className="error"></p>

                  <p>
                    <input
                      type="checkbox"
                      style={{ width: 'auto' }}
                      onChange={(event) => {
                        this.setState({ is_tos_agreed: event.target.checked })
                      }}
                    />{' '}
                    I agree to the <a href="/terms/">Terms of Service</a> and{' '}
                    <a
                      href="https://spexi.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                    , including{' '}
                    <a
                      href="https://spexi.com/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Cookie Use
                    </a>
                    .
                  </p>

                  <div
                    className={
                      this.state.is_tos_agreed
                        ? 'google-signup'
                        : 'google-signup disabled'
                    }
                  >
                    <GoogleAuth
                      title="Sign up with Google"
                      loginWithGoogle={this.props.loginWithGoogle}
                    />
                  </div>
                  <span className="separator">
                    <span>OR VIA EMAIL ADDRESS</span>
                  </span>
                  <input
                    type="text"
                    ref="name"
                    value={this.state.name}
                    className="form-item"
                    placeholder="Full Name"
                    onChange={this.handleInput}
                  />
                  <input
                    type="email"
                    ref="email"
                    value={this.state.email}
                    className="form-item"
                    placeholder="Email"
                    onChange={this.handleInput}
                  />
                  <input
                    type="hidden"
                    ref="referral_code"
                    value={this.state.referralCode}
                  />
                  <div className="password-container">
                    <input
                      type={
                        this.state.passwordTextVisibility ? 'text' : 'password'
                      }
                      ref="password"
                      value={this.state.password}
                      className="form-item"
                      placeholder="Password"
                      onChange={this.handleInput}
                    />
                    <i
                      className="material-icons"
                      onClick={() => {
                        let toggle = this.state.passwordTextVisibility
                          ? false
                          : true
                        this.setState({ passwordTextVisibility: toggle })
                      }}
                    >
                      {visibilityIcon}
                    </i>
                  </div>
                  <button
                    className="button solid"
                    disabled={this.state.is_tos_agreed === false}
                  >
                    Sign up <span className="loading"></span>
                  </button>

                  <p className="message">
                    Already have an account? <Link to="/">Sign in</Link>
                  </p>
                </form>
              </div>

              <div className="half second"></div>
            </div>
          </section>
        </main>
      )
    }
  }
}

export default Signup
