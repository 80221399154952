import React, { useState, useEffect } from 'react'
import OrganizationMembers from '../OrganizationMembers'
import OrganizationReport from '../OrganizationReport'
import OrganizationAddModal from '../OrganizationAddModal'
import userDefaultAvatar from '../../CollapsibleMenu/user-avatar.svg'
import { OrganizationApi } from '../../../api'
import moment from 'moment'
import './circle.css'
import './styles.css'
import OrganizationDeleteModal from '../OrganizationDeleteModal'
import { alertTypes } from '../../../util/constants'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { Collapse } from '@material-ui/core'

const OrganizationData = (props) => {
  const {
    isAdmin,
    current_member,
    organization,
    organization_roles,
    timezone,
    setAlertModal,
    autoExpandMemberList,
  } = props

  const [members, setMembers] = useState(null)
  const [projects, setProjects] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [subscriptionDate, setSubscriptionDate] = useState(null)
  const [showMembers, setShowMembers] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)
  const [memberUpdate, setMemberUpdate] = useState(false)
  const [signupType, setSignupType] = useState(null)
  const [isSignupUpdating, setIsSignupUpdating] = useState(false)
  const [currentPage, setCurrentPage] = useState(null)

  useEffect(() => {
    const fetchOrganizationData = () => {
      fetchOrganizationProjects()
      fetchOrganizationSubscription()
      fetchOrganizationMembers()
    }

    // if there is only one org, auto expand the member list
    if (autoExpandMemberList) {
      setShowMembers(true)
    }

    const fetchOrganizationProjects = async () => {
      const organization_projects = await OrganizationApi.getAllProjects(
        organization.id
      )
      if (organization_projects) {
        setProjects(organization_projects)
      }
    }

    const fetchOrganizationSubscription = async () => {
      const organization_subscription = await OrganizationApi.getSubscription(
        organization.id
      )
      if (organization_subscription) {
        setSubscription(organization_subscription)
      }
      if (organization_subscription && organization_subscription.success) {
        const remainingSubscriptionDays = getRemainingSubscriptionDays(
          organization_subscription.data.end
        )
        const totalSubscriptionDays = getTotalSubscriptionDays(
          organization_subscription.data.start,
          organization_subscription.data.end
        )
        setSubscriptionDate({
          remaining: remainingSubscriptionDays,
          total: totalSubscriptionDays,
        })
      }
    }

    const fetchOrganizationMembers = async () => {
      const organization_members = await OrganizationApi.members(
        organization.id
      )
      if (organization_members) {
        setMembers(organization_members)
        setCurrentPage(1)
      }
    }

    setSignupType(organization.signup_type)
    fetchOrganizationData()
  }, [organization, autoExpandMemberList])

  useEffect(() => {
    const fetchOrganizatoinMembersPage = async () => {
      const page_url = `?page=${currentPage}`
      const organization_members_page = await OrganizationApi.membersPage(
        organization.id,
        page_url
      )
      if (organization_members_page) {
        setMembers(organization_members_page)
      }
    }

    if (currentPage) {
      fetchOrganizatoinMembersPage()
    }

    if (memberUpdate) {
      setMemberUpdate(false)
    }
  }, [memberUpdate, currentPage, organization.id])

  const getRemainingSubscriptionDays = (endDate) => {
    let end = new Date(endDate.replace(/-/g, '/'))
    let today = new Date()
    let oneDay = 1000 * 60 * 60 * 24
    let diffInTime = end.getTime() - today.getTime()
    let remainingDays = Math.round(diffInTime / oneDay)
    return remainingDays
  }

  const getTotalSubscriptionDays = (startDate, endDate) => {
    let start = new Date(startDate.replace(/-/g, '/'))
    let end = new Date(endDate.replace(/-/g, '/'))
    let oneDay = 1000 * 60 * 60 * 24
    let diffInTime = end.getTime() - start.getTime()
    let totalDays = Math.round(diffInTime / oneDay)
    return totalDays
  }

  const renderOrganizationData = () => {
    let showSignup = false
    return (
      <div className="box__padding">
        <div className="image__container">
          {renderLogo(organization.logo_url)}
        </div>
        <div className="text__container">
          <div className="title">Organization</div>
          <div className="subtitle">{organization.name}</div>
        </div>
        <div className="text__container">
          <div className="title">Date created</div>
          <div className="subtitle">
            {moment(organization.created_at)
              .tz(timezone)
              .format('MMMM Do YYYY')}
          </div>
        </div>
        {checkPermissions('signup_view') && showSignup && renderSignup()}
      </div>
    )
  }

  const renderLogo = (url) => {
    let logo = url ? url : userDefaultAvatar
    return <img src={logo} alt={logo} className="organization__logo" />
  }

  const renderProjectData = () => {
    return (
      <>
        <div className="col-2">
          <div>Members</div>
          {members.success ? (
            <div className="highlight__text1">{members.data.total}</div>
          ) : (
            <div>Member Error</div>
          )}
        </div>
        <div className="col-2">
          <div>Total projects</div>
          {projects.success ? (
            <div className="highlight__text1">{projects.data.length}</div>
          ) : (
            <div>Project Error</div>
          )}
        </div>
      </>
    )
  }

  const renderSubscriptionData = () => {
    return (
      <>
        {subscription.success && subscriptionDate && (
          <>
            <h3 style={{ fontSize: '1rem', color: '#9f9f9f' }}>
              Organization Subscription
            </h3>

            <h4
              style={{
                fontWeight: '500',
                color: '#4a698d',
                textTransform: 'uppercase',
                fontSize: '.9rem',
              }}
            >
              This month
            </h4>
            <div className="col-2">
              <strong>You have used</strong>
              <div>
                <span className="highlight__text2">
                  {subscription.data.usage}
                </span>
                {subscription.data.limit && (
                  <>/ {subscription.data.limit} photo(s)</>
                )}
              </div>
              <strong>current subscription period</strong>
            </div>
            <div className="col-3">
              {renderPercentageWheel(
                Math.round(
                  (subscription.data.usage / subscription.data.limit) * 100
                )
              )}
            </div>
            <div className="col-2">
              <strong>You have</strong>
              <div>
                <span className="highlight__text2">
                  {subscriptionDate.remaining}
                </span>
                / {subscriptionDate.total} day(s)
              </div>
              <strong>remaining</strong>
            </div>
            <div className="col-3">
              {renderPercentageWheel(
                Math.round(
                  (1 - subscriptionDate.remaining / subscriptionDate.total) *
                    100
                )
              )}
            </div>
          </>
        )}
        {!subscription.success && (
          <strong className="subscription__error">{subscription.error}</strong>
        )}
      </>
    )
  }

  const renderPercentageWheel = (percentage) => {
    return (
      <div className={`c100 p${percentage}`}>
        {isNaN(percentage) ? <span>Ultd.</span> : <span>{percentage}%</span>}
        <div className="slice">
          <div className="bar"></div>
          <div className="fill"></div>
        </div>
      </div>
    )
  }

  const renderSignup = () => {
    return (
      <>
        <div className="text__container">
          <div className="title">Active</div>
          <div className="subtitle">{organization.active ? 'Yes' : 'No'}</div>
        </div>
        <div className="text__container">
          <div className="title">Signup Type</div>
          <div
            className="signup__row"
            style={{
              marginTop: '0.5rem',
            }}
          >
            <select
              name="signupType"
              style={{ width: '80%' }}
              onChange={(e) => handleSignupChange(e)}
              value={signupType ? signupType : ''}
              disabled={!checkPermissions('signup_update')}
              required
            >
              <option>domain</option>
              <option>invite</option>
              <option>unrestricted</option>
            </select>
            {isSignupUpdating && renderComponentLoader('1rem')}
          </div>
        </div>
        {signupType === 'unrestricted' && (
          <div className="text__container">
            <div className="title">Invite link</div>
            <div className="subtitle">{`${window.location.protocol}//${window.location.host}/signup?org=${organization.domain}`}</div>
          </div>
        )}
      </>
    )
  }

  const renderComponentLoader = (size) => {
    return (
      <div style={{ flex: '0 0 auto', width: '20%' }}>
        <div
          className="lds-ring"
          style={{
            height: size,
            width: size,
          }}
        >
          <div style={{ width: size, height: size }}></div>
          <div style={{ width: size, height: size }}></div>
          <div style={{ width: size, height: size }}></div>
          <div style={{ width: size, height: size }}></div>
        </div>
      </div>
    )
  }

  const renderShowMemberButton = () => {
    return (
      <button
        className="member__btn"
        onClick={() => {
          setShowMembers(!showMembers)
        }}
        style={{
          fontWeight: showMembers && '800',
        }}
      >
        {showMembers ? 'Hide Members' : 'Show Members'}
      </button>
    )
  }

  const renderShowReportButton = () => {
    return (
      <button
        className="member__btn"
        onClick={() => {
          setShowReport(!showReport)
        }}
        style={{
          fontWeight: showReport && '800',
        }}
      >
        Organization Report
      </button>
    )
  }

  const renderAddMemberButton = () => {
    return (
      <button
        className="btn white"
        onClick={() => {
          setIsAddModalOpen(!isAddModalOpen)
        }}
      >
        Add Member
      </button>
    )
  }

  const handleSignupChange = async (event) => {
    let target = event.target
    let value = target.value

    let updatedOrganization = {
      ...organization,
      signup_type: value,
    }

    setIsSignupUpdating(true)

    const result = await OrganizationApi.updateSignupType(updatedOrganization)

    if (result.success) {
      setSignupType(value)
      setIsSignupUpdating(false)
    } else {
      setIsSignupUpdating(false)
      setAlertModal({
        message: result.error,
        type: alertTypes.warning,
      })
    }
  }

  const checkPermissions = (permission_string) => {
    if (isAdmin || current_member.permissions.includes(permission_string)) {
      return true
    } else {
      return false
    }
  }

  return (
    <div className="organization__data">
      <div className="container">
        <div className="row">
          <div className="row__btn">
            {renderAddMemberButton()}
            {!autoExpandMemberList && renderShowMemberButton()}
          </div>
        </div>
        <div className="row outer__border">
          <div className="col-2 inner__border">{renderOrganizationData()}</div>
          <div className="col">
            <div className="row row-padding">
              {projects && members ? (
                <>{renderProjectData()}</>
              ) : (
                <>{renderComponentLoader('3rem')}</>
              )}
            </div>
            <div className="row row-padding" style={{ paddingTop: 0 }}>
              {subscription ? (
                <>{renderSubscriptionData()}</>
              ) : (
                <>{renderComponentLoader('3rem')}</>
              )}
            </div>
            {(isAdmin || checkPermissions('report_view')) && (
              // check for users permission as organization member, only show report if they have report_view permission
              <>
                {subscription && subscription.success && (
                  <>
                    <div
                      className="row row-padding"
                      style={{ paddingBottom: '2rem'}}
                    >
                      <div className="col-2 report__button">
                        {showReport ? (
                          <KeyboardArrowUp
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setShowReport(!showReport)
                            }}
                          />
                        ) : (
                          <KeyboardArrowDown
                            style={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setShowReport(!showReport)
                            }}
                          />
                        )}
                        {renderShowReportButton()}
                      </div>
                    </div>
                    <Collapse in={showReport}>
                      <div className="row row-padding" style={{paddingTop: '0'}}>
                        <OrganizationReport
                          organization={organization}
                          setAlertModal={setAlertModal}
                        />
                      </div>
                    </Collapse>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {showMembers && members && (
          <div className="row">
            <OrganizationMembers
              isAdmin={isAdmin}
              current_member={current_member}
              members={members.data}
              roles={organization_roles}
              timezone={timezone}
              setIsDeleteModalOpen={setIsDeleteModalOpen}
              setSelectedMember={setSelectedMember}
              setMemberUpdate={setMemberUpdate}
              checkPermissions={checkPermissions}
              setAlertModal={setAlertModal}
              setCurrentPageNumber={setCurrentPage}
            />
          </div>
        )}

        {isAddModalOpen && (
          <OrganizationAddModal
            organization={organization}
            roles={organization_roles}
            setIsAddModalOpen={setIsAddModalOpen}
            setMemberUpdate={setMemberUpdate}
            checkPermissions={checkPermissions}
          />
        )}

        {isDeleteModalOpen && selectedMember && (
          <OrganizationDeleteModal
            member={selectedMember}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setMemberUpdate={setMemberUpdate}
            setAlertModal={setAlertModal}
          />
        )}
      </div>
    </div>
  )
}

export default OrganizationData

OrganizationData.signupText = new Map()
OrganizationData.signupText.set(
  'unrestricted',
  'Unrestricted - any registered Spexi user can join'
)
OrganizationData.signupText.set(
  'domain',
  'Domain - only registered Spexi user with a confirmed email matching the organization domain may joinb'
)
OrganizationData.signupText.set(
  'invite',
  'Invite - only registered Spexi user who have received an invite from an organization owner or administrator may join'
)
