import React from 'react'
import './styles.css'


const NotificationMessage = props => {
  let visible = false
  let message = false

  if(props.requestsInProgress.LOCATION){
  	visible = true
  	message = <span><i className="fa fa-location-arrow" aria-hidden="true"></i> Getting your geolocation...</span>
  }


  if(props.requestsInProgress.BOUNDED_PROJECTS){
  	visible = true
  	message = <span><i className="fa fa-location-arrow" aria-hidden="true"></i> Fetching projects in this area...</span>
  }

  if(props.requestsInProgress.ADDRESS){
    visible = true
  	message = <span><i className="fa fa-location-arrow" aria-hidden="true"></i> Looking up your address...</span>
  }

  if(props.requestsInProgress.PREVIEWING_MARKERS){
    visible = true
    message = <span><i className="fa fa-map-marker" aria-hidden="true"></i> Creating preview markers...</span>
  }
  

  if (visible) {
    return <div className="notification-message visible">{message}</div>
  } else {
    return <div className="notification-message"></div>
  }
}

export default NotificationMessage