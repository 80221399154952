import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ActionCreators } from "../actions";
import { Route } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ProfileContainer from "../containers/ProfileContainer";
import LoadingAnimation from "../components/LoadingAnimation/";
import AlertModal from "../components/AlertModal/";
import Modal from "../components/Modal/";
import TerrainProfile from "../components/TerrainProfile";
import MainMap from "../components/MainMap/";
import NavigationContainer from "../containers/NavigationContainer";
import { CSSTransition } from "react-transition-group";
import GCPMarkerOverlay from "../components/GCPMarkerOverlay/";
import AddProductDialog from "../components/AddProductDialog/";
import TeamsList from "../components/TeamsList/";
import SendToCustomerOverlay from "../components/SendToCustomerOverlay/";
import PurchaseCreditsOverlay from "../components/PurchaseCreditsOverlay/";
import CoordinateSystemPickerModal from "../components/CoordinateSystemPickerModal";
import OrganizationContainer from "../containers/OrganizationContainer";
import Admin from "../components/Admin";
import Tour from "../components/Tour";
import HelpButton from "../components/HelpButton";
import { EVENTS } from "react-joyride";
import { User } from "../api";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class DashboardContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      run: false,
    };
  }

  static propTypes = {
    requestsInProgress: PropTypes.object.isRequired,
    collapsedMenu: PropTypes.bool.isRequired,
  };

  
  componentDidUpdate(prevProps) {
    if (
      this.props.requestsInProgress.UPLOADING_IMAGES ||
      this.props.requestsInProgress.VERIFYING_UPLOADS
      ) {
        // try to stop the user from leaving the site if they are uploading images - shows a generic "you will lose saved changes" message
        window.onbeforeunload = () => true;
      } else {
        window.onbeforeunload = undefined;
      }
      
    if (this.props.user && this.props.user !== prevProps.user) {
      if (!this.props.user.is_web_onboarding_seen && this.props.user.is_verified) {
        this.handleActivateTour();
      }
    }

  }

  closeTerainProfile = () => this.props.setIsShowingTerrainProfile(false);

  handleActivateTour = () => {
    this.setState({ run: true });
  };

  handleJoyrideCallback = (data) => {
    const { type } = data;

    if (type === EVENTS.TOUR_END && this.state.run) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false });
      User.updateIsWebOnboardingSeen();
    }
  };

  render() {
    let menuState = this.props.collapsedMenu
      ? "App collapsible-menu-is-open"
      : "App collapsible-menu-is-closed";
    let { run } = this.state;

    return (
      <main className={menuState}>
        <HelpButton onActivateTour={this.handleActivateTour} />
        <Tour run={run} callback={this.handleJoyrideCallback} />
        <LoadingAnimation visible={this.props.requestsInProgress.GLOBAL} />
        <AlertModal {...this.props} {...this.props.alertModal} />
        <CoordinateSystemPickerModal
          {...this.props.coordinateSystemPickerModal}
        />
        <NavigationContainer {...this.props} />
        {this.props.match.path !== "/admin" &&
          this.props.match.path !== "/teams" &&
          this.props.match.path !== "/account" &&
          !this.props.match.path.startsWith("/organizations") && (
            <MainMap {...this.props} />
          )}

        <CSSTransition
          in={this.props.productForm.active}
          timeout={300}
          classNames="viewOverlay"
          unmountOnExit
        >
          <AddProductDialog {...this.props} />
        </CSSTransition>

        {this.props.user?.isAdmin && (
          <Route
            path="/admin"
            render={() => <Admin setAlertModal={this.props.setAlertModal} />}
          />
        )}

        <Route
          path="/teams"
          render={() => <TeamsList {...this.props} user={this.props.user} />}
        />

        <Route
          path="/account"
          render={() => (
            <ProfileContainer {...this.props} user={this.props.user} />
          )}
        />

        <Route
          path="/organizations"
          render={() => (
            <OrganizationContainer {...this.props} user={this.props.user} />
          )}
        />

        <CSSTransition
          in={
            Object.keys(this.props.project).length !== 0 &&
            this.props.isGCPOverlayOpen
          }
          timeout={300}
          classNames="viewGCPOverlay"
          unmountOnExit
        >
          <GCPMarkerOverlay
            product={this.props.product}
            setAlertModal={this.props.setAlertModal}
          />
        </CSSTransition>

        <CSSTransition
          in={
            Object.keys(this.props.project).length !== 0 &&
            this.props.isSendingToCustomer
          }
          timeout={300}
          classNames="viewGCPOverlay"
          unmountOnExit
        >
          <SendToCustomerOverlay {...this.props} />
        </CSSTransition>

        <CSSTransition
          in={this.props.isPurchasingCredits}
          timeout={300}
          classNames="viewPurchaseCreditsOverlay"
          unmountOnExit
        >
          <PurchaseCreditsOverlay {...this.props} />
        </CSSTransition>

        <CSSTransition
          in={this.props.isShowingTerrainProfile}
          timeout={300}
          classNames="viewTerrainProfileOverlay"
          unmountOnExit
        >
          <Modal
            title="Terrain Profile"
            customClass="terrain-profile-modal"
            isOpen={this.props.isShowingTerrainProfile}
            closeHandler={this.closeTerainProfile}
          >
            <TerrainProfile
              flightParameters={this.props.flightParameters}
              newFlightPlan={this.props.newFlightPlan}
              droneCamera={this.props.droneCamera}
            />
          </Modal>
        </CSSTransition>

        {this.props.user && this.props.user.organizationMembership && (
          <Snackbar
            open={this.props.orgJoinSuccessMessage}
            autoHideDuration={20000}
            message="Organization Join Successful"
            onClose={this.props.closeOrgJoinSuccessMessage}
          >
            <Alert
              onClose={this.props.closeOrgJoinSuccessMessage}
              severity="success"
            >
              Successfully joined{" "}
              {this.props.user.organizationMembership.organization.name}
            </Alert>
          </Snackbar>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userIsVerified: state.userIsVerified,
    userIsLoggedIn: state.userIsLoggedIn,
    collapsedMenu: state.collapsedMenu,
    requestsInProgress: state.request,
    projectFilter: state.projectFilter,
    projects: state.projects,
    project: state.project,
    flightPlans: state.flightPlans,
    flightPlan: state.flightPlan,
    projectsHasErrored: state.projectsHasErrored,
    openAddProjectMenu: state.openAddProjectMenu,
    isAddingProject: state.isAddingProject,
    projectForm: state.projectForm,
    drawingFlightMapObjects: state.drawingFlightMapObjects,
    addProjectFiles: state.addProjectFiles,
    projectsMapCenter: state.projectsMapCenter,
    userGeoLocation: state.userGeoLocation,
    isEdittingProject: state.isEdittingProject,
    openSingleProject: state.openSingleProject,
    leafletMapElement: state.leafletMapElement,
    mapZoomLevel: state.mapZoomLevel,
    activeFeatureGroup: state.activeFeatureGroup,
    activeMapLayer: state.activeMapLayer,
    viewportCentre: state.viewportCentre,
    reviseSearchBounds: state.reviseSearchBounds,
    previewMarkers: state.previewMarkers,
    previewUploadedMarkers: state.previewUploadedMarkers,
    mapBounds: state.mapBounds,
    flightParameters: state.flightParameters,
    panoFlightParameters: state.panoFlightParameters,
    orbitFlightParameters: state.orbitFlightParameters,
    flightPlanType: state.flightPlanType,
    newFlightPlan: state.newFlightPlan,
    flightEstimatesData: state.flightEstimatesData,
    startingMarker: state.startingMarker,
    orbitPhotoMarkers: state.orbitPhotoMarkers,
    isUploadingImages: state.isUploadingImages,
    isAddingFlightPlan: state.isAddingFlightPlan,
    isEdittingFlightPlan: state.isEdittingFlightPlan,
    droneList: state.droneList,
    droneCamera: state.droneCamera,
    metadatas: state.metadatas,
    user: state.user,
    products: state.products,
    userTimezone: state.userTimezone,
    product: state.product,
    annotationCreationForm: state.annotationCreationForm,
    GISData: state.GISData,
    GISProfileGraphData: state.GISProfileGraphData,
    drawingAnnotationLayer: state.drawingAnnotationLayer,
    dragAnnotationMarker: state.dragAnnotationMarker,
    mapLayers: state.mapLayers,
    annotations: state.annotations,
    productMapLayers: state.productMapLayers,
    contoursMapLayer: state.contoursMapLayer,
    profileCoordinate: state.profileCoordinate,
    isPrintingAnnotationReport: state.isPrintingAnnotationReport,
    searchMarker: state.searchMarker,
    isUploadingGCPs: state.isUploadingGCPs,
    gcps: state.gcps,
    isGCPOverlayOpen: state.isGCPOverlayOpen,
    productJob: state.productJob,
    productJobs: state.productJobs,
    productForm: state.productForm,
    productMapPreview: state.productMapPreview,
    alertModal: state.alertModal,
    isSendingToCustomer: state.isSendingToCustomer,
    projectSearchOptions: state.projectSearchOptions,
    isEditingProductParameters: state.isEditingProductParameters,
    projectSearchState: state.projectSearchState,
    usersSearchState: state.usersSearchState,
    mapMaxNativeZoomLevel: state.mapMaxNativeZoomLevel,
    isPurchasingCredits: state.isPurchasingCredits,
    teams: state.teams,
    users: state.users,
    coordinateSystemPickerModal: state.coordinateSystemPickerModal,
    userSearchOptions: state.userSearchOptions,
    orgJoinSuccessMessage: state.orgJoinSuccessMessage,
    subscriptionPlans: state.subscriptionPlans,
    isShowingTerrainProfile: state.isShowingTerrainProfile,
    spexigonData: state.spexigonData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);
