import React, { useCallback, useEffect, useState } from 'react'
import {
  Grid,
  DialogContent,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  DialogActions,
} from '@material-ui/core'
import { Auth, OrganizationApi } from '../../api'
import { debounce } from 'lodash'
import { alertTypes } from '../../util/constants'

const OrganizationTab = (props) => {
  const {
    organization,
    setOrganization,
    organizationUpdated,
    setAlertModal,
    handleDialogClose,
  } = props

  const [owner, setOwner] = useState(null)

  useEffect(() => {
    if (organization.id) {
      setOwner({
        id: organization.owner_id,
        email: organization.owner_email,
      })
    }
  }, [organization.id, organization.owner_id, organization.owner_email])

  const inputFields = [
    {
      inputType: 'TextField',
      key: 'name',
      type: 'text',
      label: 'Organization Name',
      value: organization.name,
      size: 12,
      style: { width: '60%' },
      required: true,
      disabled: false,
    },
    {
      inputType: 'TextField',
      key: 'url',
      type: 'url',
      label: 'Organization URL',
      value: organization.url,
      size: 12,
      style: { width: '60%' },
      required: true,
      disabled: false,
    },
    {
      inputType: 'TextField',
      key: 'domain',
      type: 'text',
      label: 'Organization Domain',
      value: organization.domain,
      size: 12,
      style: { width: '60%' },
      required: true,
      disabled: false,
    },
    {
      inputType: 'TextField',
      key: 'owner_email',
      type: 'email',
      label: 'Owner Email',
      value: organization.owner_email,
      size: 12,
      style: { width: '60%' },
      required: true,
      disabled: false,
      handleEmailChange: true,
      helperText: owner !== null && !owner && 'email does not exists in Spexi.',
    },
    {
      inputType: 'SelectField',
      key: 'signup_type',
      type: '',
      label: 'Signup Type',
      value: organization.signup_type ? organization.signup_type : '',
      size: 6,
      style: { width: '60%' },
      required: true,
      disabled: false,
      items: [
        {
          key: 'unrestricted',
          value: 'unrestricted',
          label: 'Unrestricted',
        },
        {
          key: 'invite',
          value: 'invite',
          label: 'Invite',
        },
        {
          key: 'domain',
          value: 'domain',
          label: 'Domain',
        },
      ],
    },
  ]

  const handleEmailChange = (event) => {
    handleEmailChangeDebounce(event.target.value)
  }

  const handleEmailChangeDebounce = useCallback(
    debounce(
      (email) => {
        handleEmailLookup(email)
      },
      500,
      { leading: false, trailing: true }
    )
  )

  const handleEmailLookup = (email) => {
    if (email) {
      // if request email exists for organization owner in spexi database, user as the owner
      Auth.doesExist(email).then((resp) => {
        if (resp && resp.success) {
          setOwner(resp.data)
        } else {
          setOwner(false)
        }
      })
    } else {
      setOwner(null)
    }
  }

  const renderInputFields = () => {
    return inputFields.map((input) => {
      if (input.inputType === 'TextField') {
        return (
          <Grid key={input.key} item xs={input.size}>
            <TextField
              type={input.type}
              label={input.label}
              value={input.value}
              style={input.style}
              required={input.required}
              disabled={input.disabled}
              onInput={(e) => {
                setOrganization({
                  ...organization,
                  [input.key]: e.target.value,
                })
                if (input.handleEmailChange) {
                  handleEmailChange(e)
                }
              }}
              error={
                input.handleEmailChange &&
                owner !== null &&
                (owner ? false : true)
              }
              helperText={input.helperText}
            />
          </Grid>
        )
      }
      if (input.inputType === 'SelectField') {
        return (
          <Grid key={input.key} item xs={input.size}>
            <FormControl fullWidth>
              <InputLabel id={input.key}>{input.label}</InputLabel>
              <Select
                id={input.key}
                label={input.label}
                value={input.value}
                style={input.style}
                disabled={input.disabled}
                inputProps={{
                  required: input.required,
                }}
                onChange={(e) => {
                  setOrganization({
                    ...organization,
                    [input.key]: e.target.value,
                  })
                }}
              >
                {input.items.map((item) => {
                  return (
                    <MenuItem key={item.key} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        )
      }
      return true
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (organization.id) {
      // check to see the organization owner exists and is valid
      if (owner) {
        let body = { ...organization, owner_id: owner.id }
        OrganizationApi.updateOrganization(body.id, body).then((resp) => {
          if (resp && resp.success) {
            organizationUpdated(resp.data.id)
            setAlertModal({
              message: 'Organization Successfully updated.',
              type: alertTypes.success,
            })
          } else {
            console.log(resp)
            setAlertModal({
              message: '[Error] Organization Update',
              type: alertTypes.error,
            })
          }
        })
      } else {
        setAlertModal({
          message: 'No changes has been made.',
          type: alertTypes.warning,
        })
        console.log('No changes has been made or Owner does Not Exists')
      }
    } else {
      if (owner) {
        let body = { ...organization, owner_id: owner.id }
        OrganizationApi.create(body).then((resp) => {
          if (resp && resp.success) {
            organizationUpdated(resp.data.id)
            setAlertModal({
              message: 'Organization Successfully saved.',
              type: alertTypes.success,
            })
          } else {
            console.log(resp)
            setAlertModal({
              message: '[Error] Organization Create',
              type: alertTypes.error,
            })
          }
        })
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Grid container spacing={3}>
          {renderInputFields()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <button
          type="button"
          className="btn-primary-white admin-organizations-dialog__btn"
          onClick={handleDialogClose}
        >
          Close
        </button>
        <button
          type="submit"
          className="btn-primary-solid admin-organizations-dialog__btn right"
        >
          Save & Continue
        </button>
      </DialogActions>
    </form>
  )
}

export default OrganizationTab
