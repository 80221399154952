import { Auth, Team } from '../api'
import { history } from '../store'
import { setRequestInProgress } from './request'
import { PLAYER_URL } from '../config'
import { setAlertModal } from './ui'
import { alertTypes } from '../util/constants'

export function setUserIsLoggedIn(bool) {
  return {
    type: 'SET_USER_IS_LOGGED_IN',
    userIsLoggedIn: bool
  }
}

export function setUserIsVerified(bool) {
  return {
    type: 'SET_USER_IS_VERIFIED',
    userIsVerified: bool
  }
}

export function setUser(user) {
  return {
    type: 'SET_USER',
    user: user
  }
}

export function setUserTimezone(timezone) {
  return {
    type: 'SET_USER_TIMEZONE',
    timezone
  }
}

export function userLogin(email, password) {
  return (dispatch) => {

    Auth.login(email, password)
      .then(response => {
        if(response.success) {
          localStorage.setItem('jwt-token', response.data.token)
          dispatch(setUserIsLoggedIn(true))
          dispatch(loginToPlayer(response.data.token))


          // check if the user is verified
          Auth.getUser()
            .then(response => {
              if(response.success && response.data.is_verified){
                dispatch(setUser(response.data))
                dispatch(setUserIsVerified(true))
              }
            })


        } else {
          if(JSON.parse(response.error).email) {
            userLoginError(JSON.parse(response.error).email)
          }
        }
      })
      .catch((error) => {
        userLoginError(error.error)
        dispatch(setAlertModal({
          message: error.error,
          type: alertTypes.error,
        }))
      })

  }
}

export function resendVerificationLink(email){
  return (dispatch) => {
    dispatch(setRequestInProgress(true, 'GLOBAL'))
    Auth.resendVerification(email)
      .then(response => {
        dispatch(setRequestInProgress(false, 'GLOBAL'))
        if (response && response.success) {
          dispatch(setAlertModal({
            message: 'Verification email successfully resent. Check your inbox.',
            type: alertTypes.success,
          }))
        } else {
          if (response && response.error) {
            dispatch(setAlertModal({
              message: response.error,
              type: alertTypes.error,
            }))
          } else {
            throw new Error(response)
          }
        }
      })
      .catch((error) => {
        dispatch(setRequestInProgress(false, 'GLOBAL'))
        dispatch(setAlertModal({
          message: error.error,
          type: alertTypes.error,
        }))
      })
  }
}


export function userRegister(name, email, password, referralCode, orgId) {
  return (dispatch) => {

    dispatch(setRequestInProgress(true, 'REGISTRATION'))

    Auth.register(name, email, password, referralCode, orgId)
      .then(response => {
        dispatch(setRequestInProgress(false, 'REGISTRATION'))

        if (response.success) {
          localStorage.setItem('jwt-token', response.data.token)
          dispatch(setUserIsLoggedIn(true))
          dispatch(loginToPlayer(response.data.token))
        } else {
          let error = JSON.parse(response.error)
          if(error.name) {
            userRegisterError('text', error.name)
          }
          if(error.email) {
            userRegisterError('email', error.email)
          }
          if(error.password) {
            userRegisterError('password', error.password)
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })

  };
}


export function userRegisterError(type, message){
  const registerWrapper = document.querySelector('#signup-page .container')
  registerWrapper.classList.add('form-error', 'form-error-shake')

  console.log(type)

  registerWrapper.querySelectorAll(`input[type=${type}`).forEach((elem) => {
    elem.classList.add('form-error')
  })

  setTimeout(() => {
    registerWrapper.classList.remove('form-error-shake')
  }, 400)

  registerWrapper.querySelector('.error').innerHTML = message
}



export function userLoginError(message){
  const sidebar = document.getElementById('login-sidebar')
  sidebar.classList.add('form-error', 'form-error-shake');

  sidebar.querySelectorAll('input').forEach((elem) => {
    elem.classList.add('form-error')
  })

  setTimeout(() => {
    sidebar.classList.remove('form-error-shake');
    document.getElementById('login-form').classList.remove('logging-in');

  }, 400)
}



export function userVerify(token) {
  return (dispatch) => {

    Auth.verify(token)
      .then(response => {
        if(response.success) {
          // localStorage.setItem('jwt-token', response.data.token);
          if(window && window.innerWidth <= 600){
            alert('Thanks for verifying your account. Please return to our mobile app to continue.')
          }
          dispatch(setUserIsVerified(true))
          dispatch(setUserIsLoggedIn(true))

        } else {
          alert(response.error)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function setUserLogout() {
  return (dispatch) => {
    dispatch(setUser(null))
    // Logout of the player too
    const iframe = document.getElementById('iframeLogin').contentWindow
    if (iframe) {
      console.log('logging out of player')
      iframe.postMessage(null, PLAYER_URL)
    }
    localStorage.removeItem('jwt-token')
    // Needed a slight delay of postMessage would never get a chance to fire
    setTimeout(()=>{
      console.log('logging out')
      dispatch(setUserIsLoggedIn(false))
      window.location.href = '/'
    }, 100)
  }
}


// export function loginAuthProvider(provider, urlString) {
//   return (dispatch) => {
//     dispatch(setRequestInProgress(true, 'GLOBAL'))

//     Auth.authProviderCallback(provider, urlString)
//       .then(response => {
//         dispatch(setRequestInProgress(false, 'GLOBAL'))
//         if (response.success) {
//           localStorage.setItem('jwt-token', response.data.token)
//         }
//         history.push('/')

//       })
//       .catch((error) => {
//         alert('An error occurred with the authentication provider. Please try again with our email-based login.')
//         dispatch(setRequestInProgress(false, 'GLOBAL'))
//         console.error(error)
//         history.push('/')
//       })
//   }
// }



export function setRecoverPassword(email) {
  return (dispatch) => {

    dispatch(setRequestInProgress(true, 'FORGOT_PASSWORD'))

    Auth.recover(email)
      .then(response => {
        dispatch(setRequestInProgress(false, 'FORGOT_PASSWORD'))
        if (response.success) {
          dispatch(setAlertModal({
            message: response.data.msg,
            type: alertTypes.success,
            confirmHandler: () => {
              history.push('/')
            },
            cancellable: false
          }))
        } else {
          dispatch(setAlertModal({ message: response, type: alertTypes.error }))
        }
      })
      .catch((error) => {
        dispatch(setRequestInProgress(false, 'FORGOT_PASSWORD'))
        dispatch(setAlertModal({
          message: 'An error occurred. Please try to reset again.',
          type: alertTypes.error,
        }))
      })
  }
}


export function setResetPassword(email, token, password, password_confirmation) {
  return (dispatch) => {

    dispatch(setRequestInProgress(true, 'FORGOT_PASSWORD'))

    Auth.reset(email, token, password, password_confirmation)
      .then(response => {
        dispatch(setRequestInProgress(false, 'FORGOT_PASSWORD'))
        if (response.success) {
          dispatch(setAlertModal({
            message: 'Password reset successful. Please login with your new credentials.',
            type: alertTypes.success,
            confirmHandler: () => {
              history.push('/')
            },
            cancellable: false
          }))
        } else {
          dispatch(setAlertModal({
            message: response.error,
            type: alertTypes.error,
          }))
        }
      })
      .catch((error) => {
        dispatch(setAlertModal({
          message: 'An error occurred. Please try to reset again.',
          type: alertTypes.error,
        }))
      })
  }
}


export function loginWithGoogle(token) {
  return (dispatch) => {
    dispatch(setRequestInProgress(true, 'GLOBAL'))

    Auth.loginWithExternal(token, 'google')
      .then(response => {
        console.log(response)
        if (response.success) {
          localStorage.setItem('jwt-token', response.data.token)
          dispatch(loginToPlayer(response.data.token))

          dispatch(setUserIsLoggedIn(true))

          // get user object and check if the user is verified
          Auth.getUser()
            .then(response => {
              dispatch(setRequestInProgress(false, 'GLOBAL'))
              if (response.success) {
                dispatch(setUser(response.data))
                if (response.data.is_verified) {
                  dispatch(setUserIsVerified(true))
                } else {
                  dispatch(setUserIsVerified(false))
                }
                if(response.data.token) {
                  this.props.loginToPlayer(token)
                }
              }
            })
        }
        history.push('/')

      })
      .catch((error) => {
        dispatch(setAlertModal({
          message: 'An error occurred with the authentication provider. Please try again with our email-based login.',
          type: alertTypes.error,
          confirmHandler: () => {
            console.error(error)
            history.push('/')
          },
          cancellable: false
        }))
        dispatch(setRequestInProgress(false, 'GLOBAL'))
      })
  }
}


export function loginToPlayer(token) {
  return (dispatch) => {
    console.log('Logging into player')
    const iframe = document.getElementById('iframeLogin')
    if (iframe) {
      const iframeWindow = iframe.contentWindow
      if (token && iframeWindow) {
        iframeWindow.postMessage(token, PLAYER_URL)
      }
    }
  }
}


export function getLoggedInUser() {
  return (dispatch) => {
    return Auth.getUser()
      .then(response => {
        if (response.success) {
          dispatch(setUser(response.data))
        }

      })
      .catch(console.error)
  }
}


export function getUserTeams() {
  return (dispatch) => {
    return Team.getAll('?memberOnly=true')
      .then(response => {
        if (response.success) {
          dispatch(setTeams(response.data))
        }

      })
      .catch(console.error)
  }
}

export function setTeams(teams) {
  return {
    type: 'SET_TEAMS',
    teams
  }
}


export function setOrgJoinSuccessMessage(bool) {
  return {
    type: 'SET_ORG_JOIN_SUCCESS_MESSAGE',
    orgJoinSuccessMessage: bool
  }
}


export function closeOrgJoinSuccessMessage() {
  return (dispatch) => {
    dispatch(setOrgJoinSuccessMessage(false))
  }
}
