import React, { Component } from 'react'
import CreditSelectionForm from './CreditSelectionForm'
import CheckoutForm from './CheckoutForm'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Wallet, Team } from '../../api'
import './styles.css'
import { STRIPE_KEY } from '../../config'

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(STRIPE_KEY)

class PurchaseCreditsOverlay extends Component {
  constructor(props) {
    super(props)

    Team.getAll().then((result) => {
      console.log(result)

      if (result.success) {
        this.setState({ teams: result.data })
      }
    })

    this.state = {
      credits: 0,
      step: 0,
      wallet_id: this.props.user && this.props.user.wallet_id ? this.props.user.wallet_id : null,
      creditPrice: 15,
      paymentSummary: 0,
      clientSecret: null,
      paymentIntentId: null,
      teams: null,
      isLoading: false,
    }
  }

  transitionStep = (step) => {
    this.setState({ step })
  }

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CreditSelectionForm
            setIsPurchasingCredits={this.props.setIsPurchasingCredits}
            handleChange={this.handleChange}
            credits={this.state.credits}
            paymentSummary={this.state.paymentSummary}
            clientSecret={this.state.clientSecret}
            creditsPrice={this.state.creditPrice}
            wallet_id={this.state.wallet_id}
            transitionStep={this.transitionStep}
            checkPaymentIntent={this.checkPaymentIntent}
            getLoggedInUser={this.props.getLoggedInUser}
            user={this.props.user}
            teams={this.state.teams}
            isLoading={this.state.isLoading}
            setAlertModal={this.props.setAlertModal}
          />
        )
      case 1:
        return (
          <Elements stripe={stripePromise}>
            <CheckoutForm
              credits={this.state.credits}
              paymentSummary={this.state.paymentSummary}
              clientSecret={this.state.clientSecret}
              creditsPrice={this.state.creditPrice}
              wallet_id={this.state.wallet_id}
              setUser={this.props.setUser}
              transitionStep={this.transitionStep}
              setIsPurchasingCredits={this.props.setIsPurchasingCredits}
              getLoggedInUser={this.props.getLoggedInUser}
              getUserTeams={this.props.getUserTeams}
              checkPaymentIntent={this.checkPaymentIntent}
              isLoading={this.state.isLoading}
              setRequestInProgress={this.props.setRequestInProgress}
              setAlertModal={this.props.setAlertModal}
            />
          </Elements>
        )
      default:
        throw new Error('Unknown step')
    }
  }

  handleChange = (event) => {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    this.setState({ [name]: value })
  }

  // create payment intent in Stripe which will then get completed once the workflow
  // successfully is completed
  createPaymentIntent = async () => {
    this.setState({ isLoading: true })
    let result = await Wallet.checkoutIntent({
      creditAmount: this.state.credits,
      wallet_id: this.state.wallet_id,
    })
    // console.log(result)
    if (result.success) {
      this.setState({
        paymentSummary: result.data.amount,
        clientSecret: result.data.client_secret,
        paymentIntentId: result.data.id,
        isLoading: false,
      })
      // console.log('state', this.state)
    }
  }

  updatePaymentIntent = async (data) => {
    let result = await Wallet.checkoutIntentUpdate(data)
    this.setState({ isLoading: true })
    if (result.success) {
      this.setState({
        paymentSummary: result.data.cost,
        wallet_id: this.state.wallet_id,
        isLoading: false,
      })
      this.props.setRequestInProgress(false, 'GLOBAL')
    }
  }

  checkPaymentIntent = (newData) => {
    if (!this.state.clientSecret && !this.state.paymentSummary) {
      this.createPaymentIntent()
    } else if (this.state.clientSecret) {
      this.updatePaymentIntent({
        paymentIntentId: this.state.paymentIntentId,
        creditAmount: this.state.credits,
        wallet_id: this.state.wallet_id,
        address: newData ? newData.address : null,
      })
    }
  }

  render() {
    return (
      <section id="purchase-credits-overlay">
        <svg
          viewBox="0 0 74 74"
          onClick={() => {
            this.props.setIsPurchasingCredits(false)
            // abandon this payment intent. keeps track of failed conversation rates in Stripe if we update it.
            if (this.state.paymentIntentId) {
              Wallet.checkoutIntentAbandon({
                paymentIntentId: this.state.paymentIntentId,
              })
            }
          }}
          className="close-icon"
        >
          <g data-name="Group 3278">
            <path d="M35.417 25.604v8h-8v2.668h8v8h2.668v-8h8v-2.668h-8v-8z" fill="#555" className="plus-lines" />
          </g>
        </svg>

        {this.getStepContent(this.state.step)}
      </section>
    )
  }
}

export default PurchaseCreditsOverlay
