import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Route } from 'react-router-dom'
import 'moment-timezone'
import SidebarHeader from '../components/SidebarHeader'
import ProjectPlan from '../components/ProjectPlan'
import ProjectUpload from '../components/ProjectUpload'
import ProjectReview from '../components/ProjectReview'
import { isProjectFavorite } from '../helpers'
import { Project } from '../api'

class ProjectContainer extends Component {
  static propTypes = {
    setIsAddingProject: PropTypes.func.isRequired,
    setOpenSingleProject: PropTypes.func.isRequired,
    fetchProject: PropTypes.func.isRequired,
    backToProjectList: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    flightPlans: PropTypes.array,
    match: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.props.setIsAddingProject(false)

    this.state = {
      projectFavourited: false,
    }
  }

  componentDidMount() {
    // Check if the user has access to this project still from the server
    Project.getProject(this.props.match.params.id).then((response) => {
      if (response && !response.id) {
        // if the user doesn't have access anymore, refresh project list and
        // redirect them back
        this.props.setProjects([])
        this.props.setProjectSearchState({ page: 0, hasMore: true })
        this.props.fetchNextProjectPage()
        this.props.history.push('/')
      }
    })

    document.querySelector('.sidebar-content').scrollTop = 0
    this.props.setMapZoomLevel(18)

    // Fetch these immediately for responsiveness perceived in the UI.
    // We later call a debounced version of them within their specific component to get updated polling data
    this.props.getProductJobs(this.props.match.params.id)
    this.props.getProducts(this.props.match.params.id)
  }

  componentWillUnmount() {
    this.props.setPreviewMarkers(null)
  }

  renderFavouriteStatusIcon = (starred) => {
    return (
      <svg
        viewBox="0 0 20.091 19"
        className={starred ? 'svg-star starred' : 'svg-star'}
        onClick={this.clickStarHandler}
      >
        <path
          fill="#dadbe0"
          d="M20.091 7.239l-7.223-.62-2.823-6.62-2.822 6.63L0 7.239l5.485 4.73-1.648 7.03 6.208-3.73 6.208 3.73-1.637-7.03zm-10.046 6.16l-3.777 2.27 1-4.28-3.33-2.88 4.4-.38 1.707-4.03 1.718 4.04 4.4.38-3.335 2.88 1 4.28z"
        />
      </svg>
    )
  }

  clickStarHandler = (event) => {
    event.stopPropagation()
    // let newFavouriteStatus = this.props.project.favourite !== undefined || this.props.project.favourite === false ? true : false;
    let isFavorite = isProjectFavorite(this.props.project.id)
    console.warn(isFavorite)
    this.props.setProjectFavourite(this.props.project.id, !isFavorite)
    event.target.classList.toggle('starred')
  }

  renderProjectProcessingTab = () => {
    // Org specific permission required to allow processing
    if (this.props.project && this.props.project.isOrgProject) {
      // Don't display if they don't have this permission
      if (
        this.props.project.currentUserOrgPermissions &&
        !this.props.project.currentUserOrgPermissions.includes(
          'product_process'
        )
      ) {
        return <></>
      }
    }

    // render Processing tab in all other cases
    return (
      <NavLink
        className="tab"
        activeClassName="active"
        to={`/project/${this.props.match.params.id}/process`}
      >
        PROCESS
      </NavLink>
    )
  }

  renderProjectTabs = () => {
    if (
      this.props.project.teamMemberRole &&
      this.props.project.teamMemberRole.name === 'reviewer'
    ) {
      return (
        <>
          <NavLink
            className="tab"
            activeClassName="active"
            to={`/project/${this.props.match.params.id}/review`}
          >
            REVIEW
          </NavLink>
        </>
      )
    } else {
      return (
        <>
          <NavLink
            className="tab"
            activeClassName="active"
            to={`/project/${this.props.match.params.id}/plan`}
          >
            PLAN
          </NavLink>
          {this.renderProjectProcessingTab()}
          <NavLink
            className="tab"
            activeClassName="active"
            to={`/project/${this.props.match.params.id}/review`}
          >
            REVIEW
          </NavLink>
        </>
      )
    }
  }

  render() {
    if (this.props.project.length === 0 && this.props.flightPlans > 0) {
      return <div />
    }

    return (
      <div>
        <SidebarHeader
          title="Project Details"
          backLink="/"
          backFunction={this.props.backToProjectList}
        />
        <div className="tabs">
          {!this.props.requestsInProgress.PROJECT && this.renderProjectTabs()}
        </div>

        {this.props.requestsInProgress.PROJECT && (
          <div
            className="lds-ring"
            style={{
              marginTop: '3rem',
              transform: 'translateX(-50%)',
              left: '50%',
            }}
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}

        {!this.props.requestsInProgress.PROJECT && (
          <div>
            <Route
              exact
              path="/project/:id/plan"
              render={() => (
                <ProjectPlan
                  {...this.props}
                  renderFavouriteStatusIcon={this.renderFavouriteStatusIcon}
                />
              )}
            />
            <Route
              exact
              path="/project/:id/process"
              render={() => (
                <ProjectUpload
                  {...this.props}
                  renderFavouriteStatusIcon={this.renderFavouriteStatusIcon}
                />
              )}
            />
            <Route
              exact
              path="/project/:id/review"
              render={() => (
                <ProjectReview
                  {...this.props}
                  renderFavouriteStatusIcon={this.renderFavouriteStatusIcon}
                />
              )}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ProjectContainer
