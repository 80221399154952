import { API_ROOT } from './config'

const setHeaders = (token, contentType = 'application/json', customHeaders) => {
  let headers = {}
  if (token) {
    headers = {
      Accept: 'application/json',
      'Content-Type': contentType,
      Authorization: `Bearer ${token}`,
    }

    if (contentType === 'empty') {
      headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': '', // Google Storage CORS issue when this doesn't exist.. it needs to match signedUrl header
      }
    }

    if (!contentType) {
      headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }
  } else {
    headers = {
      Accept: 'application/json',
      'Content-Type': contentType,
    }
  }

  if (customHeaders) {
    headers = { ...headers, ...customHeaders }
    console.log(headers)
  }

  return headers
}

const requests = {
  get: async (url) => {
    return fetch(url, {
      method: 'get',
      headers: setHeaders(localStorage.getItem('jwt-token')),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('jwt-token')
          window.location.href = '/'
        }
        return response
      })
      .then((response) =>
        response.json().then((json) => {
          if (!response.ok) {
            return Promise.reject(json)
          }
          return json
        })
      )
      .catch(console.error)
  },

  post: (url, body, contentType, customHeaders) => {
    let data = contentType === 'application/json' ? JSON.stringify(body) : body
    return fetch(url, {
      method: 'post',
      headers: setHeaders(
        localStorage.getItem('jwt-token'),
        contentType,
        customHeaders
      ),
      body: data,
    })
      .then((response) => {
        if (response.status === 401) {
          // do this for all except the login POST
          if (!body.email.length && !body.password.length) {
            localStorage.removeItem('jwt-token')
            window.location.href = '/'
          }
        }
        return response
      })
      .then((response) =>
        response.json().then((json) => {
          if (!response.ok) {
            return Promise.reject(json)
          }

          return json
        })
      )
  },

  put: (url, body, contentType, customHeaders) => {
    let data = contentType === 'application/json' ? JSON.stringify(body) : body

    return fetch(url, {
      method: 'put',
      headers: setHeaders(
        localStorage.getItem('jwt-token'),
        contentType,
        customHeaders
      ),
      body: data,
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('jwt-token')
          window.location.href = '/'
        }
        return response
      })
      .then((response) => {
        // TODO: not good
        if (
          contentType === 'image/jpeg' ||
          contentType === 'video/mp4' ||
          contentType === 'application/octet-stream'
        ) {
          return response
        }
        return response.json().then((json) => {
          if (response.status === 403) {
            if (json.error) {
              // alert(json.error)
            }
          }

          if (!response.ok) {
            return Promise.reject(json)
          }
          return json
        })
      })
  },

  del: (url) => {
    return fetch(url, {
      method: 'delete',
      headers: setHeaders(localStorage.getItem('jwt-token')),
    })
      .then((response) => {
        if (response.ok && response.status === 204) {
          return { success: true }
        } else if (response.status === 403) {
          return {
            success: false,
            error: 'You are not authorized to perform this action.',
          }
        } else {
          return response.json()
        }
      })
      .catch(console.error)
  },

  // use this if you wish to download binary data instead of json
  blob: async (url) => {
    return fetch(url, {
      method: 'get',
      headers: setHeaders(localStorage.getItem('jwt-token')),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem('jwt-token')
          window.location.href = '/'
        }
        return response
      })
      .then((response) =>
        response.blob().then((blob) => {
          if (!response.ok) {
            return Promise.reject(blob)
          }
          // return the headers as well as the blob
          return { headers: response.headers, blob }
        })
      )
      .catch(console.error)
  },
}

export const Webhook = {
  getWebhooks: () => requests.get(`${API_ROOT}/v1/webhooks`),
  getWebhook: (orgId) =>
    requests.get(`${API_ROOT}/v1/organization/${orgId}/webhook`),
  createWebhook: (orgId, body) =>
    requests.post(
      `${API_ROOT}/v1/organization/${orgId}/webhook`,
      body,
      'application/json'
    ),
  updateWebhook: (orgId, body) =>
    requests.post(
      `${API_ROOT}/v1/organization/${orgId}/webhook`,
      body,
      'application/json'
    ),
  deleteWebhook: (orgId) =>
    requests.del(`${API_ROOT}/v1/organization/${orgId}/webhook`),
}

export const Auth = {
  register: (name, email, password, referralCode, orgId) =>
    requests.post(
      `${API_ROOT}/v1/register`,
      {
        name: name,
        email: email,
        password: password,
        referral_code: referralCode,
        is_tos_agreed: true,
        orgId,
      },
      'application/json'
    ),
  login: (email, password) =>
    requests.post(
      `${API_ROOT}/v1/login`,
      { email: email, password: password },
      'application/json'
    ),
  verify: (token) =>
    requests.get(`${API_ROOT}/v1/verify/${token}`, 'application/json'),
  resendVerification: (email) =>
    requests.post(
      `${API_ROOT}/v1/resend-verification`,
      { email: email },
      'application/json'
    ),
  authProviderCallback: (provider, urlString) =>
    requests.get(
      `${API_ROOT}/v1/auth/${provider}/callback${urlString}`,
      'application/json'
    ),
  loginWithExternal: (token, platform) =>
    requests.post(
      `${API_ROOT}/v1/auth/loginWithExternal`,
      { token, platform },
      'application/json'
    ),
  recover: (email) =>
    requests.post(
      `${API_ROOT}/v1/recover`,
      { email: email },
      'application/json'
    ),
  reset: (email, token, password, password_confirmation) =>
    requests.post(
      `${API_ROOT}/v1/reset`,
      {
        email: email,
        token: token,
        password: password,
        password_confirmation: password_confirmation,
      },
      'application/json'
    ),
  getUser: (id = '') => requests.get(`${API_ROOT}/v1/user/${id}`),
  getAllUsersData: () => requests.get(`${API_ROOT}/v1/user/showAll`),
  getAllUsers: () => requests.get(`${API_ROOT}/v1/users`),
  doesExist: (email) => requests.get(`${API_ROOT}/v1/user/doesExist/${email}`),
}

export const Project = {
  getProjects: (
    search = '',
    page = 0,
    order = '',
    bounds = '',
    authored_only = false
  ) => {
    return requests.get(
      `${API_ROOT}/v1/project?page=${page}&search=${search}&order=${order}&bounds=${bounds}&authored_only=${authored_only}`
    )
  },
  getMyProjects: () => requests.get(`${API_ROOT}/v1/project/me`),
  getMyFavourites: () => requests.get(`${API_ROOT}/v1/project/favourites`),
  getProject: (id) => requests.get(`${API_ROOT}/v1/project/${id}`),
  createProject: (body) =>
    requests.post(`${API_ROOT}/v1/project`, body, 'application/json'),
  updateProject: (id, body) =>
    requests.put(`${API_ROOT}/v1/project/${id}`, body, 'application/json'),
  deleteProject: (id) => requests.del(`${API_ROOT}/v1/project/${id}`),
  markFavourite: (id) =>
    requests.post(`${API_ROOT}/v1/user/favorite/project/${id}`),
  removeFavourite: (id) =>
    requests.del(`${API_ROOT}/v1/user/favorite/project/${id}`),
  startProcessing: (id) =>
    requests.post(`${API_ROOT}/v1/project/${id}/process`),
  sendToCustomer: (body) =>
    requests.post(`${API_ROOT}/v1/project/send`, body, 'application/json'),
  createTemporarySharingToken: (id, body) =>
    requests.post(
      `${API_ROOT}/v1/project/${id}/createTemporarySharingToken`,
      body,
      'application/json'
    ),
  getProjectUsageData: (id) =>
    requests.get(`${API_ROOT}/v1/project/${id}/getDataUsage`),
}

export const FlightPlan = {
  getFlightPlans: (query) => requests.get(`${API_ROOT}/v1/flight-plan${query}`),
  getFlightPlan: (id) => requests.get(`${API_ROOT}/v1/flight-plan/${id}`),
  createFlightPlan: (body) =>
    requests.post(`${API_ROOT}/v1/flight-plan`, body, 'application/json'),
  updateFlightPlan: (id, body) =>
    requests.put(`${API_ROOT}/v1/flight-plan/${id}`, body, 'application/json'),
  batchUpdateFlightPlans: (body) =>
    requests.post(
      `${API_ROOT}/v1/flight-plan/batchUpdate`,
      body,
      'application/json'
    ),
  deleteFlightPlan: (id) => requests.del(`${API_ROOT}/v1/flight-plan/${id}`),
}

export const ProjectPdf = {
  getProjectPdfs: (projectId) =>
    requests.get(`${API_ROOT}/v1/pdf?project_id=${projectId}`),
  deleteProjectPdf: (id) => requests.del(`${API_ROOT}/v1/pdf/${id}`),
  postProjectPdf: (projectId, name) =>
    requests.post(`${API_ROOT}/v1/pdf?project_id=${projectId}&name=${name}`),
  updateProjectPdf: (id, query) =>
    requests.get(`${API_ROOT}/v1/pdf/${id}${query}`),
  getSignedPostUrls: (body) =>
    requests.post(
      `${API_ROOT}/v1/pdf/get-signed-urls`,
      body,
      'application/json'
    ),
}

export const Booking = {
  createBooking: (body) =>
    requests.post(`${API_ROOT}/v1/booking`, body, 'application/json'),
  forfeitBooking: (body) =>
    requests.post(`${API_ROOT}/v1/booking/forfeit`, body, 'application/json'),
}

export const Images = {
  getImages: (id, query = '') =>
    requests.get(`${API_ROOT}/v1/file/${id}?${query}`),
  getMarkers: (id) => requests.get(`${API_ROOT}/v1/file/${id}/markers`),
  getImageThumb: (filename) =>
    requests.get(`${API_ROOT}/v1/uploads/thumbnails/${filename}`),
  createImage: (body) => requests.post(`${API_ROOT}/v1/file`, body, false),
  createImageS3: (body, metadata, url, contentType) =>
    requests.put(url, body, contentType, metadata),
  deleteImage: (id) => requests.del(`${API_ROOT}/v1/file/${id}`),
  deleteImages: (body) =>
    requests.post(`${API_ROOT}/v1/file/batch-delete`, body, 'application/json'),
  getSignedPostUrls: (body) =>
    requests.post(
      `${API_ROOT}/v1/file/get-signed-urls`,
      body,
      'application/json'
    ),
  createFilesEntries: (body) =>
    requests.post(`${API_ROOT}/v1/file/storeS3Files`, body, 'application/json'),
  createMarzi: (body) =>
    requests.post(`${API_ROOT}/v1/product/marzipano`, body, false),
  exportAllRaw: (body) =>
    requests.post(
      `${API_ROOT}/v1/file/exportRawFiles`,
      body,
      'application/json'
    ),
}

export const Share = {
  shareByEmail: (email) => requests.post(`${API_ROOT}/v1/share/email/${email}`),
}

export const Drones = {
  getDrones: () => requests.get(`${API_ROOT}/v1/drone`),
}

export const Products = {
  create: (body) =>
    requests.post(`${API_ROOT}/v1/product`, body, 'application/json'),
  update: (productId, body) =>
    requests.put(
      `${API_ROOT}/v1/product/${productId}`,
      body,
      'application/json'
    ),
  getProductsJobsByProjectId: (projectId) =>
    requests.get(`${API_ROOT}/v1/product/job?project_id=${projectId}`),
  getProductsByProjectId: (projectId) =>
    requests.get(`${API_ROOT}/v1/product?project_id=${projectId}`),
  continueProcessing: (productJobId) =>
    requests.post(
      `${API_ROOT}/v1/product/job/${productJobId}/continue`,
      'application/json'
    ),
  completeProcessing: (productJobId, taskId) =>
    requests.post(
      `${API_ROOT}/v1/product/job/${productJobId}/task/${taskId}/complete`,
      'application/json'
    ),
  createJob: (body) =>
    requests.post(`${API_ROOT}/v1/product/job`, body, 'application/json'),
  updateJob: (productJobId, body) =>
    requests.put(`${API_ROOT}/v1/product/job/${productJobId}`, body),
  updateJobTask: (productJobId, taskId, body) =>
    requests.put(
      `${API_ROOT}/v1/product/job/${productJobId}/task/${taskId}`,
      body
    ),
  export: (productId, parameters) =>
    requests.post(
      `${API_ROOT}/v1/product/${productId}/export`,
      parameters,
      'application/json'
    ),
  delete: (productId) => requests.del(`${API_ROOT}/v1/product/${productId}`),
  cancelJob: (productJobId) =>
    requests.post(`${API_ROOT}/v1/product/job/${productJobId}/cancel`),
  estimateCost: (body) =>
    requests.post(`${API_ROOT}/v1/product/estimate`, body, 'application/json'),
  getProductUsageData: (id) =>
    requests.get(`${API_ROOT}/v1/product/${id}/getDataUsage`),
  getProductJobsCosts: (startDate = '', endDate = '', sortFilter = '') =>
    requests.get(
      `${API_ROOT}/v1/product/job/costs?startDate=${startDate}&endDate=${endDate}&sortFilter=${sortFilter}`
    ),
}

export const GIS = {
  measure: (productId, body) =>
    requests.post(
      `${API_ROOT}/v1/product/${productId}/gis`,
      body,
      'application/json'
    ),
}

export const Annotations = {
  getAll: (productId) =>
    requests.get(`${API_ROOT}/v1/product/annotation/?product_id=${productId}`),
  get: (annotation_id) =>
    requests.get(`${API_ROOT}/v1/product/annotation/${annotation_id}`),
  create: (body) =>
    requests.post(
      `${API_ROOT}/v1/product/annotation`,
      body,
      'application/json'
    ),
  update: (annotation_id, body) =>
    requests.put(
      `${API_ROOT}/v1/product/annotation/${annotation_id}`,
      body,
      'application/json'
    ),
  delete: (annotation_id) =>
    requests.del(`${API_ROOT}/v1/product/annotation/${annotation_id}`),
  exportAll: (productId, format, projection) =>
    requests.blob(
      `${API_ROOT}/v1/product/${productId}/annotation/export/?format=${format}&projection=${projection}`
    ),
}

export const ProductMapLayers = {
  getAll: (productId) =>
    requests.get(`${API_ROOT}/v1/map-layer/?product_id=${productId}`),
  upload: (body) => requests.post(`${API_ROOT}/v1/map-layer`, body, false),
  update: (layer_id, data) =>
    requests.put(
      `${API_ROOT}/v1/map-layer/${layer_id}`,
      data,
      'application/json'
    ),
  delete: (id) => requests.del(`${API_ROOT}/v1/map-layer/${id}`),
}

export const GCPs = {
  get: (productId) =>
    requests.get(`${API_ROOT}/v1/product/${productId}/uploadedGcps`),
  getProjections: (productId) =>
    requests.get(`${API_ROOT}/v1/product/${productId}/gcp`),
  updateProjections: (productId, body) =>
    requests.post(
      `${API_ROOT}/v1/product/${productId}/gcp`,
      body,
      'application/json'
    ),
  upload: (projectId, body) =>
    requests.post(
      `${API_ROOT}/v1/project/${projectId}/gcp/upload`,
      body,
      false
    ),
  delete: (
    gcpId // deletes specific gcp with id
  ) => requests.del(`${API_ROOT}/v1/gcp/${gcpId}`),
  deleteMultiple: (
    ids // deletes list of gcps supplied in body { ids: [] }
  ) =>
    requests.post(
      `${API_ROOT}/v1/gcp/delete-gcps`,
      { ids },
      'application/json'
    ),
  update: (gcpId, data) =>
    requests.put(`${API_ROOT}/v1/gcp/${gcpId}`, data, 'application/json'),
}

export const Order = {
  getAll: (query = '') => requests.get(`${API_ROOT}/v1/order${query}`),
  create: (body) =>
    requests.post(`${API_ROOT}/v1/order`, body, 'application/json'),
  update: (id, body) =>
    requests.put(`${API_ROOT}/v1/order/${id}`, body, 'application/json'),
  delete: (id) => requests.del(`${API_ROOT}/v1/order/${id}`),
}

export const Team = {
  getAll: (query = '') => requests.get(`${API_ROOT}/v1/team${query}`),
  create: (body) =>
    requests.post(`${API_ROOT}/v1/team`, body, 'application/json'),
  update: (id, body) =>
    requests.put(`${API_ROOT}/v1/team/${id}`, body, 'application/json'),
  delete: (id) => requests.del(`${API_ROOT}/v1/team/${id}`),
  addMember: (id, body) =>
    requests.post(`${API_ROOT}/v1/team/${id}/member`, body, 'application/json'),
  removeMember: (team_id, user_id) =>
    requests.del(`${API_ROOT}/v1/team/${team_id}/member/${user_id}`),
  getRoles: () => requests.get(`${API_ROOT}/v1/team/roles`),
}

export const Wallet = {
  getAll: (query = '') => requests.get(`${API_ROOT}/v1/team${query}`),
  checkoutIntent: (body) =>
    requests.post(`${API_ROOT}/v1/wallet/checkout`, body, 'application/json'),
  checkoutIntentUpdate: (body) =>
    requests.put(
      `${API_ROOT}/v1/wallet/checkout/update`,
      body,
      'application/json'
    ),
  checkoutIntentAbandon: (body) =>
    requests.put(
      `${API_ROOT}/v1/wallet/checkout/abandon`,
      body,
      'application/json'
    ),
  getAllTransactions: (query = '') =>
    requests.get(`${API_ROOT}/v1/wallet/transactions?${query}`),
  getStats: (startDate = '', endDate = '') =>
    requests.get(
      `${API_ROOT}/v1/wallet/stats?startDate=${startDate}&endDate=${endDate}`
    ),
  updateWallet: (walletId, body) =>
    requests.put(`${API_ROOT}/v1/wallet/${walletId}`, body, 'application/json'),
}

export const User = {
  getUser: (id = '') => requests.get(`${API_ROOT}/v1/user/${id}`),
  getAll: (query = '', page = 1) =>
    requests.get(`${API_ROOT}/v1/user/showAll?page=${page}&${query}`),
  updateProfile: (body) =>
    requests.put(`${API_ROOT}/v1/profile`, body, 'application/json'),
  createNetworkProfile: (body) =>
    requests.post(`${API_ROOT}/v1/network-profile`, body, 'application/json'),
  updateNetworkProfile: (id, body) =>
    requests.put(
      `${API_ROOT}/v1/network-profile/${id}`,
      body,
      'application/json'
    ),
  updateIsWebOnboardingSeen: () =>
    requests.put(`${API_ROOT}/v1/user/updateIsWebOnboardingSeen`),
  deleteUser: (userId, token) =>
    requests.get(
      `${API_ROOT}/v1/user/delete-user?userId=${userId}&token=${token}`
    ),
}

export const Subscriptions = {
  getAllPlans: (query = '') =>
    requests.get(`${API_ROOT}/v1/subscriptions/plans?${query}`),
  getAllSubscriptionUsers: (query = '') =>
    requests.get(`${API_ROOT}/v1/subscriptions/users?${query}`),
  getUserSubscriptions: (userId) =>
    requests.get(`${API_ROOT}/v1/subscriptions/user/${userId}/subscriptions`),
  createUserSubscription: (userId, planId, additionalQuery = '') =>
    requests.post(
      `${API_ROOT}/v1/subscriptions/user/${userId}/add?plan_id=${planId}${additionalQuery}`
    ),
  updateUserSubscription: (userId, planId, additionalQuery = '') =>
    requests.post(
      `${API_ROOT}/v1/subscriptions/user/${userId}/update?plan_id=${planId}${additionalQuery}`
    ),
  deleteUserSubscription: (userId, planId, additionalQuery = '') =>
    requests.del(
      `${API_ROOT}/v1/subscriptions/user/${userId}?plan_id=${planId}${additionalQuery}`
    ),
  deleteUserUpcomingSubscription: (userId) =>
    requests.del(`${API_ROOT}/v1/subscription/upcoming/user/${userId}`),
  cancelSubscriptionInvoice: (invoiceId) =>
    requests.put(`${API_ROOT}/v1/subscriptions/invoice/${invoiceId}/cancel`),
  createSubscriptionCheckoutSession: (subscriptionId, userId) =>
    requests.post(
      `${API_ROOT}/v1/subscriptions/${subscriptionId}/user/${userId}/checkoutSession/create`
    ),
  getSubscriptionPaymentMethods: (userId) =>
    requests.get(`${API_ROOT}/v1/subscriptions/user/${userId}/paymentMethods`),
  updateSubscriptionDefaultPaymentMethod: (subscriptionId, paymentMethodId) =>
    requests.put(
      `${API_ROOT}/v1/subscriptions/${subscriptionId}/paymentMethod/update?payment_method_id=${paymentMethodId}`
    ),
  deletePaymentMethod: (paymentMethodId) =>
    requests.del(
      `${API_ROOT}/v1/subscriptions/paymentMethod/${paymentMethodId}`
    ),
}

export const EpsgApiProxy = {
  get: (path, query = '') =>
    requests.get(`${API_ROOT}/v1/epsg/${path}${query}`),
  // allows more flexibility with how we query the EPSG API proxy...so we can get text responses
  getRequest: (path, query = '', basePath = 'epsg') => {
    return new Request(`${API_ROOT}/v1/${basePath}/${path}${query}`, {
      method: 'GET',
      headers: setHeaders(localStorage.getItem('jwt-token')),
    })
  },
}

export const OrganizationApi = {
  get: (id) => requests.get(`${API_ROOT}/v1/organization/${id}`),
  getAll: () => requests.get(`${API_ROOT}/v1/organization`),
  // getProjects: (id) => requests.get(`${API_ROOT}/v1/${id}/projects`),
  updateOrganization: (id, body) =>
    requests.put(`${API_ROOT}/v1/organization/${id}`, body, 'application/json'),
  create: (body) =>
    requests.post(`${API_ROOT}/v1/organization`, body, 'application/json'),
  // members: (id, query = '') => requests.get(`${API_ROOT}/v1/organization/${id}/members/page/${query}`),
  members: (id) => requests.get(`${API_ROOT}/v1/organization/${id}/members`),
  allMembers: (id) =>
    requests.get(`${API_ROOT}/v1/organization/${id}/allMembers`),
  membersPage: (id, page_url) =>
    requests.get(`${API_ROOT}/v1/organization/${id}/members${page_url}`),
  myOrgs: () => requests.get(`${API_ROOT}/v1/organization/my/orgs`),
  join: (id) =>
    requests.post(
      `${API_ROOT}/v1/organization/${id}/join`,
      null,
      'application/json'
    ),
  linkProject: (id, project_id) =>
    requests.post(
      `${API_ROOT}/v1/organization/${id}/link/project`,
      { project_id },
      'application/json'
    ),
  unlinkProject: (project_id) =>
    requests.del(`${API_ROOT}/v1/organization/unlink/project/${project_id}`),
  hasSubscription: (id) =>
    requests.get(`${API_ROOT}/v1/organization/${id}/has/subscription`),
  getSubscription: (id) =>
    requests.get(`${API_ROOT}/v1/organization/${id}/get/subscription`),
  getRoles: () => requests.get(`${API_ROOT}/v1/organization-roles`),
  addMember: (id, body) =>
    requests.post(
      `${API_ROOT}/v1/organization/${id}/add`,
      body,
      'application/json'
    ),
  removeMember: (organization_id, user_id) =>
    requests.del(
      `${API_ROOT}/v1/organization/${organization_id}/member/${user_id}`
    ),
  batchDeleteMembers: (body) =>
    requests.post(
      `${API_ROOT}/v1/organization/batchDeleteMembers`,
      body,
      'application/json'
    ),
  updateMember: (id, body) =>
    requests.put(
      `${API_ROOT}/v1/organization/organization-update/${id}`,
      body,
      'application/json'
    ),
  updateMemberExternalId: (id, body) =>
    requests.put(
      `${API_ROOT}/v1/organization/member/external-id/${id}`,
      body,
      'application/json'
    ),
  updateSignupType: (body) =>
    requests.put(
      `${API_ROOT}/v1/organization/update-signup`,
      body,
      'application/json'
    ),

  getAllProjects: (id) =>
    requests.get(`${API_ROOT}/v1/organization/${id}/projects`),

  getUserOrgRole: (id) =>
    requests.get(`${API_ROOT}/v1/organization/${id}/role`),
  getPermissions: () => requests.get(`${API_ROOT}/v1/organization-permissions`),
  getPermissionRelation: () =>
    requests.get(`${API_ROOT}/v1/organization-role-has-permissions`),
  getStats: (id, startDate = '', endDate = '') =>
    requests.get(
      `${API_ROOT}/v1/organization/${id}/stats?startDate=${startDate}&endDate=${endDate}`
    ),
  getSubscriptionPeriods: (id) =>
    requests.get(`${API_ROOT}/v1/organization/${id}/subscription-periods`),
  generateReport: (id, startDate = '', endDate = '') =>
    requests.get(
      `${API_ROOT}/v1/organization/${id}/report?startDate=${startDate}&endDate=${endDate}`
    ),
}

export const OrganizationSubscriptions = {
  get: (id) => requests.get(`${API_ROOT}/v1/organization-subscription/${id}`),
  create: (body) =>
    requests.post(
      `${API_ROOT}/v1/organization-subscription`,
      body,
      'application/json'
    ),
  update: (body, id) =>
    requests.put(
      `${API_ROOT}/v1/organization-subscription/${id}`,
      body,
      'application/json'
    ),
  getSubscriptionByOrgId: (id) =>
    requests.get(`${API_ROOT}/v1/organization-subscription/organization/${id}`),
}

export const AIModel = {
  get: () => requests.get(`${API_ROOT}/v1/aimodels`),
}
export const Spexigon = {
  get: (id) => requests.get(`${API_ROOT}/v1/spexigon/${id}`),
  getAll: () => requests.get(`${API_ROOT}/v1/spexigon`),
}
