export function annotations(state = [], action) {
  switch (action.type) {
    case 'ANNOTATIONS_DATA_SUCCESS':
      return action.annotations.map(annotation => {
        return { ...annotation, visible: true }
      })
    case 'TOGGLE_ANNOTATION_VISIBILITY':
      return state.map((annotation) => {
        if ((action.id === annotation.id)) {
          return { ...annotation, visible: !annotation.visible }
        }
        return annotation

      })
    default:
      return state
  }
}


const initialFormState = {
  product_id: '',
  user_id: '',
  id: '',
  type: '',
  name: '',
  color: 'F30808',
  description: '',
  comments: [],
  options: {
    method: 'BEST_FIT'
  },
  public: false
}
export function annotationCreationForm(state = initialFormState, action) {
  switch (action.type) {
    case 'SET_ANNOTATION_CREATION_FORM':
      if (action.data === null) {
        return { ...initialFormState }
      } else {
        if (action.data.options === null) {
          action.data.options = initialFormState.options
        }
        return { ...state, ...action.data }
      }
    default:
      return state
  }
}


export function drawingAnnotationLayer(state = {}, action) {
  switch (action.type) {
    case 'SET_DRAWING_ANNOTATION_LAYER':
      if (action.data === null) {
        // console.log(state)
        // state.remove && state.remove()
        return {}
      } else {
        return action.data
      }
    default:
      return state
  }
}


export function dragAnnotationMarker(state = {}, action) {
  switch (action.type) {
    case 'SET_DRAG_ANNOTATION_MARKER':
      if (action.data === null) {
        state.remove && state.remove()
        return {}
      } else {
        return action.data
      }
    default:
      return state
  }
}


export function GISData(state = {}, action) {
  switch (action.type) {
    case 'SET_GIS_DATA':
      return action.data
    default:
      return state
  }
}


export function GISProfileGraphData(state = {}, action) {
  switch (action.type) {
    case 'SET_GIS_PROFILE_GRAPH_DATA':
      return action.data
    default:
      return state
  }
}


// export function isEditingAnnotation(state = false, action) {
//   switch (action.type) {
//     case 'SET_IS_EDITING_ANNOTATION':
//       return action.isEditingAnnotation
//     default:
//       return state
//   }
// }



export function isPrintingAnnotationReport(state = false, action) {
  switch (action.type) {
    case 'SET_IS_PRINTING_ANNOTATION_REPORT':
      return action.bool
    default:
      return state
  }
}

