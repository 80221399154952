import React, { useRef } from 'react'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

const BillingForm = ({
  billingDetails,
  setBillingDetails,
  createSubscriptionIntent,
  gotoPrevious,
}) => {
  const ProvinceSelectRef = useRef(null)

  const handleSubmitForm = (e) => {
    e.preventDefault()
    createSubscriptionIntent()
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <h1 className="subscriptions-dialog__title">Billing information</h1>
      <h2 className="subscriptions-dialog__section-title">
        Enter your billing details
      </h2>
      <fieldset className="subscriptions-dialog__fieldset">
        <TextField
          className="subscriptions-dialog__billing-field left"
          label="Full Name (or Company Name)"
          id="name"
          type="text"
          placeholder="Jane Doe"
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value })
          }}
        />
        <TextField
          className="subscriptions-dialog__billing-field"
          label="Email"
          id="email"
          type="email"
          placeholder="janedoe@gmail.com"
          required
          autoComplete="email"
          value={billingDetails.email}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, email: e.target.value })
          }}
        />
        <TextField
          className="subscriptions-dialog__billing-field left"
          label="Phone"
          id="phone"
          type="tel"
          placeholder="(941) 555-0123"
          required
          autoComplete="tel"
          value={billingDetails.phone}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, phone: e.target.value })
          }}
        />
        <TextField
          className="subscriptions-dialog__billing-field"
          label="Address"
          id="address.line1"
          type="text"
          placeholder="123 Cherry Lane"
          required
          autoComplete="address.line1"
          value={billingDetails.address.line1}
          onChange={(e) => {
            setBillingDetails({
              ...billingDetails,
              address: {
                ...billingDetails.address,
                line1: e.target.value,
              },
            })
          }}
        />
        <TextField
          className="subscriptions-dialog__billing-field left"
          label="City"
          id="address.city"
          type="text"
          placeholder="Vancouver"
          required
          autoComplete="address.city"
          value={billingDetails.address.city}
          onChange={(e) => {
            setBillingDetails({
              ...billingDetails,
              address: {
                ...billingDetails.address,
                city: e.target.value,
              },
            })
          }}
        />

        <FormControl className="subscriptions-dialog__billing-field">
          <InputLabel htmlFor="address.state" required>
            Province/State
          </InputLabel>
          <Select
            native
            required
            value={billingDetails.address.state}
            ref={ProvinceSelectRef}
            onChange={(e) => {
              // Note: really long way to get country grouping form DOM list.
              // This is will be useless once I add more countries
              let parentCountry = ''
              let selectOptions = ProvinceSelectRef.current.children

              for (let index = 0; index < selectOptions.length; index++) {
                const options = selectOptions[index].options
                if (options) {
                  let optionsArray = Array.from(options)
                  for (let i = 0; i < optionsArray.length; i++) {
                    const child = optionsArray[i]
                    if (child.value === e.target.value) {
                      parentCountry =
                        child.parentNode.getAttribute('data-countrycode')
                    }
                  }
                }
              }

              let newAddress = {
                ...billingDetails,
                address: {
                  ...billingDetails.address,
                  state: e.target.value,
                  country: parentCountry,
                },
              }
              setBillingDetails(newAddress)
            }}
            inputProps={{
              name: 'address.state',
              id: 'address.state',
            }}
          >
            <option value="" />
            <optgroup label="Canadian Provinces" data-countrycode="CA">
              <option value="AB">Alberta</option>
              <option value="BC">British Columbia</option>
              <option value="MB">Manitoba</option>
              <option value="NB">New Brunswick</option>
              <option value="NL">Newfoundland and Labrador</option>
              <option value="NT">Northwest Territories</option>
              <option value="NS">Nova Scotia</option>
              <option value="NU">Nunavut</option>
              <option value="ON">Ontario</option>
              <option value="PE">Prince Edward Island</option>
              <option value="QC">Quebec</option>
              <option value="SK">Saskatchewan</option>
              <option value="YT">Yukon Territory</option>
            </optgroup>
            <optgroup label="U.S. States/Territories" data-countrycode="US">
              <option value="AK">Alaska</option>
              <option value="AL">Alabama</option>
              <option value="AR">Arkansas</option>
              <option value="AZ">Arizona</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DC">District of Columbia</option>
              <option value="DE">Delaware</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="IA">Iowa</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="MA">Massachusetts</option>
              <option value="MD">Maryland</option>
              <option value="ME">Maine</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MO">Missouri</option>
              <option value="MS">Mississippi</option>
              <option value="MT">Montana</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="NE">Nebraska</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NV">Nevada</option>
              <option value="NY">New York</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="PR">Puerto Rico</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VA">Virginia</option>
              <option value="VT">Vermont</option>
              <option value="WA">Washington</option>
              <option value="WI">Wisconsin</option>
              <option value="WV">West Virginia</option>
              <option value="WY">Wyoming</option>
            </optgroup>
          </Select>
        </FormControl>

        <TextField
          className="subscriptions-dialog__billing-field left"
          label="Postal Code/Zip Code"
          id="address.postal_code"
          type="text"
          placeholder="V2E 2R8"
          required
          autoComplete="address.postal_code"
          value={billingDetails.address.postal_code}
          onChange={(e) => {
            setBillingDetails({
              ...billingDetails,
              address: {
                ...billingDetails.address,
                postal_code: e.target.value,
              },
            })
          }}
        />
        <FormControl className="subscriptions-dialog__billing-field">
          <InputLabel htmlFor="address.country" required>
            Country
          </InputLabel>
          <Select
            native
            required
            value={billingDetails.address.country}
            onChange={(e) => {
              setBillingDetails({
                ...billingDetails,
                address: {
                  ...billingDetails.address,
                  country: e.target.value,
                },
              })
            }}
            inputProps={{
              name: 'address.country',
              id: 'address.country',
            }}
          >
            <option value="" />
            <option value="CA">Canada</option>
            <option value="US">United States</option>
          </Select>
        </FormControl>
      </fieldset>
      <div className="subscriptions-dialog__btn-container">
        <div>
          <button
            type="button"
            className="btn-primary-white subscriptions-dialog__btn"
            onClick={gotoPrevious}
          >
            Back
          </button>
        </div>
        <button
          type="submit"
          className="btn-primary-solid subscriptions-dialog__btn"
        >
          Next
        </button>
      </div>
    </form>
  )
}

export default BillingForm
