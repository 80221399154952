// import { GIS, Annotations } from '../api'
// import { setRequestInProgress } from './request'
// import { history } from '../store'

export function setIsPurchasingCredits(bool) {
  return {
    type: 'SET_IS_PURCHASING_CREDITS',
    isPurchasingCredits: bool
  }
}
