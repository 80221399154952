import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom'
import './styles.css'
import userDefaultAvatar from './user-avatar.svg'

class CollapsibleMenu extends Component {
  static propTypes = {
    collapsedMenu: PropTypes.bool,
    userIsVerified: PropTypes.bool,
    toggleCollapsibleMenu: PropTypes.func.isRequired,
    setIsAddingProject: PropTypes.func.isRequired,
    setUserLogout: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    if (localStorage.getItem('collapsedMenu') !== null) {
      let menuState = JSON.parse(localStorage.getItem('collapsedMenu'))
      this.props.toggleCollapsibleMenu(menuState)
      // wait for animation to end before invalidating map container
      setTimeout(() => {
        this.props.invalidateMapContainerSize()
      }, 250)
    }
  }

  toggleMenu = () => {
    let toggle = this.props.collapsedMenu ? false : true

    // needed to reset CSS animations with this reflow trick
    let elem1 = document.querySelector('#collapsible-menu')
    let elem2 = document.querySelector('.logo')
    let elem3 = document.querySelector('.user-meta')

    /* eslint-disable */
    if (elem1) {
      elem1.style.animation = 'none'
      elem1.offsetHeight /* trigger reflow */
      elem1.style.animation = null
    }

    if (elem2) {
      elem2.style.animation = 'none'
      elem2.offsetHeight /* trigger reflow */
      elem2.style.animation = null
    }

    if (elem3) {
      elem3.style.animation = 'none'
      elem3.offsetHeight /* trigger reflow */
      elem3.style.animation = null
    }
    /* eslint-enable */

    this.props.toggleCollapsibleMenu(toggle)
    // wait for animation to end before invalidating map container
    setTimeout(() => {
      this.props.invalidateMapContainerSize()
    }, 250)
    localStorage.setItem('collapsedMenu', toggle)
  }

  projectActive = (match, location) => {
    if (match.isExact) {
      return true
    }

    if (location.pathname.includes('/project/')) {
      return true
    }
  }

  render() {
    let menuState = this.props.collapsedMenu
      ? 'collapsible-menu is-open'
      : 'collapsible-menu is-closed'
    let userAvatar =
      this.props.user && this.props.user.profile.hashedFileName
        ? this.props.user.profile.avatar_url
        : userDefaultAvatar
    let logo = this.props.user && (
      <div className="logo-wrap">
        <img
          src={process.env.PUBLIC_URL + '/assets/img/spexigeo-logo-white.png'}
          alt="Spexi Geospatial"
          width="197"
          height="32"
          className="logo"
        />
      </div>
    )
    if (
      this.props.user &&
      this.props.user.organizationMembership &&
      this.props.user.organizationMembership.organization &&
      this.props.user.organizationMembership.organization.logo_url
    ) {
      logo = (
        <div className="logo-wrap white-label">
          <img
            src={this.props.user.organizationMembership.organization.logo_url}
            alt="Spexi Geospatial"
            width="120"
            className="logo"
          />
        </div>
      )
    }

    return (
      <div className={menuState}>
        {logo}

        {this.props.user && (
          <div className="user-meta">
            <Link to="/account">
              <img src={userAvatar} alt={this.props.user.name} />
            </Link>
            <p>{this.props.user.name}</p>
          </div>
        )}

        <nav id="collapsible-menu">
          {this.props.userIsVerified && (
            <Link to="/add-project" className="add-button">
              <button
                className="btn"
                onClick={() => this.props.setIsAddingProject(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 22 22"
                  className="add-icon"
                >
                  <path
                    d="M10.5,5v5.5H5v1.833h5.5v5.5h1.833v-5.5h5.5V10.5h-5.5V5Z"
                    transform="translate(-0.417 -0.417)"
                  />
                </svg>
                <span>New Project</span>
              </button>
            </Link>
          )}

          <ul className="collapsible-menu__items">
            <li>
              <NavLink
                activeClassName="active"
                to="/"
                isActive={this.projectActive}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path
                    d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM11,19.93A7.988,7.988,0,0,1,4,12a8.118,8.118,0,0,1,.21-1.79L9,15v1a2.006,2.006,0,0,0,2,2Zm6.9-2.54A1.984,1.984,0,0,0,16,16H15V13a1,1,0,0,0-1-1H8V10h2a1,1,0,0,0,1-1V7h2a2.006,2.006,0,0,0,2-2V4.59a7.982,7.982,0,0,1,2.9,12.8Z"
                    transform="translate(-2 -2)"
                  />
                </svg>
                <span>Projects</span>
              </NavLink>
            </li>

            {this.props.user && this.props.user.isAdmin && (
              <li>
                <NavLink activeClassName="active" to="/admin">
                  <svg width={20} height={20} style={{ marginLeft: '-2px' }}>
                    <path fill="none" d="M0 0h24v24H0V0z" />
                    <path d="M19 3H5c-1.1 0-2 .9-2 2v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 6h-4c0 1.62-1.38 3-3 3s-3-1.38-3-3H5V5h14v4zm-4 7h6v3c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2v-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3z" />
                  </svg>
                  <span>Admin</span>
                </NavLink>
              </li>
            )}

            {this.props.user &&
              (this.props.user.isAdmin ||
                this.props.user.isOrgOwnerOrAdmin) && (
              <li>
                <NavLink activeClassName="active" to="/organizations">
                  <i className="material-icons">domain</i>{' '}
                  <span>Organizations</span>
                </NavLink>
              </li>
            )}

            {this.props.user && this.props.userIsVerified && (
              <>
                <li>
                  <NavLink activeClassName="active" to="/teams">
                    <i className="material-icons">supervisor_account</i>{' '}
                    <span>Teams</span>
                  </NavLink>
                </li>

                <li>
                  <NavLink activeClassName="active" to="/account">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      className="profile-icon"
                    >
                      <path
                        d="M12,5.9A2.1,2.1,0,1,1,9.9,8,2.1,2.1,0,0,1,12,5.9m0,9c2.97,0,6.1,1.46,6.1,2.1v1.1H5.9V17c0-.64,3.13-2.1,6.1-2.1M12,4a4,4,0,1,0,4,4A4,4,0,0,0,12,4Zm0,9c-2.67,0-8,1.34-8,4v3H20V17C20,14.34,14.67,13,12,13Z"
                        transform="translate(-4 -4)"
                      />
                    </svg>
                    <span>Account</span>
                  </NavLink>
                </li>
              </>
            )}
            <li>
              <Link onClick={this.props.setUserLogout} to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 18 18"
                  className="logout-icon"
                >
                  <path
                    d="M10.09,15.59,11.5,17l5-5-5-5L10.09,8.41,12.67,11H3v2h9.67ZM19,3H5A2,2,0,0,0,3,5V9H5V5H19V19H5V15H3v4a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3Z"
                    transform="translate(-3 -3)"
                  />
                </svg>
                <span>Logout</span>
              </Link>
            </li>
          </ul>
        </nav>

        <button className="toggle-collapsible" onClick={this.toggleMenu}>
          <i className="fa fa-chevron-left" aria-hidden="true"></i>
        </button>
      </div>
    )
  }
}

export default CollapsibleMenu
