import React, { useState, useEffect, useCallback } from 'react'
import './style.css'
import { Team, Auth } from '../../api'
import { debounce } from 'lodash'
import { alertTypes } from '../../util/constants'
import userDefaultAvatar from '../CollapsibleMenu/user-avatar.svg'

export default function AddMemberDialog(props) {
  const { fetchData, team, setParentState } = props

  const [state, setState] = useState({
    newTeamMemberForm: {
      role_id: null,
      team_id: team.id,
      email: null
    },
    roles: [],
    user: null,
  })

  const [userExists, setUserExists] = useState(null)

  const fetchRoles = async () => {
    const result = await Team.getRoles()
    if (result.data) {
      setState(prevState => {
        return { ...prevState, roles: result.data }
      })
    }
  }

  const handleChange = (event) => {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    setState(prevState => {
      return {
        ...prevState, newTeamMemberForm: {
          ...prevState.newTeamMemberForm, [name]: value
        }
      }
    })
  }

  const handleEmailChange = event => {
    handleChange(event)
    handleEmailChangeDebounce(event.target.value)
  }

  const handleEmailLookup = (email) => {
    if (email) {
      Auth.doesExist(email).then(response => {
        if (response && response.success) {
          setState(prevState => {
            let user = response.data
            return {
              ...prevState, newTeamMemberForm: {
                ...prevState.newTeamMemberForm
              },
              user
            }
          })
          setUserExists(true)
        } else {
          setState(prevState => {
            return { ...prevState, user: null }
          })
          setUserExists(false)
        }
      })
    } else {
      setState(prevState => {
        return { ...prevState, user: null }
      })
      setUserExists(null)
    }
  }

  const handleEmailChangeDebounce = useCallback(debounce((email) => {
    handleEmailLookup(email)
  }, 500, { leading: false, trailing: true }), [])



  const handleSubmit = () => {
    if (!state.newTeamMemberForm.email) {
      props.setAlertModal({
        message: 'Please type the email address of an existing user that you want to add.',
        type: alertTypes.warning
      })

      return
    }

    if (!state.newTeamMemberForm.role_id) {
      props.setAlertModal({
        message: 'Please select a role for this user.',
        type: alertTypes.warning
      })
      return
    }

    Team.addMember(team.id, state.newTeamMemberForm).then(response => {
      if (response.success) {
        fetchData()
        setParentState({activeTeam: null})
      }
    })
  }


  useEffect(() => {
    fetchRoles()
  }, [])


  const userExistsDisplay = () => {
    if(userExists !== null && !userExists) {
      return {
        message: 'User does not exist on Spexi.',
        className: 'error'
      }
    } else if (userExists !== null && userExists) {
      return {
        message: '',
        className: 'valid'
      }
    }
  }

  const renderResultAvatar = () => {
    let userAvatar = state.user.profile && state.user.profile.hashedFileName ? state.user.profile.avatar_url : userDefaultAvatar
    return (
      <div className="team-row__member preview" key={state.user.id}>
        <div className="team-row__user-avatar">
          <img src={userAvatar} alt={state.user.name} />
          <div className="team-row__user-meta">
            {state.user.name}<br />
            {state.user.email}<br />
          </div>
        </div>
        <span className="preview-label">New Member Preview</span>
      </div>
    )
  }

  return (
    <section className="dialog">
      <div className="dialog__content">
        <h1 className="dialog__title">Add Team Member</h1>
        <p>Enter a full email address to add a user to your team. Users must already be registered with Spexi.</p>
        <label className={userExists !== null ? userExistsDisplay().className : ''}>
          Email: {userExists !== null && userExistsDisplay().message}
          <input type="text"
            value={state.newTeamMemberForm.email ? state.newTeamMemberForm.email : ''}
            onChange={handleEmailChange}
            placeholder="e.g.: milton@initech.com" name="email"
            required
          />
        </label>

        {state.user && renderResultAvatar()}

        <div>
          <label>Role: </label>
          <select
            value={state.newTeamMemberForm.role_id ? state.newTeamMemberForm.role_id : ''}
            name="role_id"
            style={{ textTransform: 'capitalize' }}
            onChange={handleChange}
            required
          >
            <option value="" hidden>Select team role</option>
            {state.roles.map(role => {
              return <option key={role.id} value={role.id}>{role.name}</option>
            })}
          </select>
        </div>

        <div className="dialog__controls">
          <button type="button" className="btn solid" onClick={handleSubmit}>Add Member</button>
          <button type="button" className="btn white" onClick={() => setParentState({ activeTeam: null })}>Cancel</button>
        </div>
      </div>
    </section>
  )
}