import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Subscriptions } from '../../api'
import { alertTypes } from '../../util/constants'
import { connect } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'
import { STRIPE_KEY } from '../../config'
import { getLoggedInUser, getUserTeams } from '../../actions/auth'
import { loadStripe } from '@stripe/stripe-js'
import BillingForm from './BillingForm'
import PaymentForm from './PaymentForm'
import './styles.css'
import LoadingAnimation from '../LoadingAnimation'
import { formatDate } from '../../helpers'
import SelectSubscriptionForm from './SelectSubscriptionForm'

// Setup Stripe.js and the Elements provider
const stripePromise = loadStripe(STRIPE_KEY)

const SubscriptionsDialog = ({ user, getLoggedInUser, getUserTeams, setAlertModal, open, onClose }) => {
  const initialBillingState = {
    name: '',
    email: '',
    phone: '',
    address: {
      line1: '',
      city: '',
      postal_code: '',
      state: '',
      country: '',
    },
  }

  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const [step, setStep] = useState(1)
  const [billingDetails, setBillingDetails] = useState(initialBillingState)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentIntent, setPaymentIntent] = useState(null)

  useEffect(() => {
    if (user.hasActiveSubscription) {
      setSelectedPlanId(user.activeSubscription.stripe_price)
    }
  }, [user])

  const handleCancelSubscription = () => {
    setAlertModal({
      message: `Are you sure you want to cancel your subscription? Your subscription will end on ${formatDate(
        user.profile.subscriptionPeriodUploadLimitStat.period.current_period_end
      )}`,
      type: alertTypes.warning,
      cancellable: true,
      confirmLabel: 'Cancel subscription',
      closeLabel: 'Back',
      confirmHandler: () => {
        cancelSubscription()
      },
    })
  }

  const handleCancelUpcomingSubscription = () => {
    setAlertModal({
      message: 'Are you sure you want to cancel your upcoming subscription?',
      type: alertTypes.warning,
      cancellable: true,
      confirmLabel: 'Cancel subscription',
      closeLabel: 'Back',
      confirmHandler: () => {
        cancelUpcomingSubscription()
      },
    })
  }

  const handleCloseDialog = () => {
    onClose()
    resetState()
    cancelSubscriptionInvoice()
  }

  const resetState = () => {
    setSelectedPlanId(user.activeSubscription?.stripe_price)
    setStep(1)
    setIsLoading(false)
    setBillingDetails(initialBillingState)
    setPaymentIntent(null)
  }

  const transitionStep = (stepAmt) => {
    setStep(step + stepAmt)
  }

  const createSubscriptionIntent = async () => {
    const country = billingDetails.address.country
    const state = billingDetails.address.state
    setIsLoading(true)
    const response = await Subscriptions.createUserSubscription(user.id, selectedPlanId, `&country=${country}&state=${state}`)

    if (response?.success && response.data) {
      setIsLoading(false)
      setPaymentIntent(response.data)
      transitionStep(1)
    } else {
      setAlertModal({
        message: response?.error || 'an error has occured',
        type: alertTypes.error,
      })
    }
  }

  const cancelSubscriptionInvoice = async () => {
    if (!paymentIntent) return
    console.log('cancelling checkout')

    setIsLoading(true)
    const response = await Subscriptions.cancelSubscriptionInvoice(paymentIntent.invoice_id)

    if (!response?.success) {
      console.error(response?.message)
    }

    setPaymentIntent(null)
    setIsLoading(false)
  }

  const updateSubscription = async () => {
    setIsLoading(true)
    const response = await Subscriptions.updateUserSubscription(user.id, selectedPlanId)
    setIsLoading(false)

    if (response?.success) {
      setAlertModal({
        message: 'Successfully processed your subscription plan change',
        type: alertTypes.success,
        confirmHandler: () => {
          updateUserState()
        },
      })
    } else {
      setAlertModal({
        message: response?.error || 'error occured',
        type: alertTypes.error,
      })
    }
  }

  const cancelSubscription = async () => {
    setIsLoading(true)
    const response = await Subscriptions.deleteUserSubscription(user.id, user.activeSubscription.stripe_price)
    setIsLoading(false)

    if (response?.success) {
      setAlertModal({
        message: `Successfully cancelled your subscription, please wait a few moments for the change to be visible on your profile. You have until ${formatDate(
          user.profile.subscriptionPeriodUploadLimitStat.period.current_period_end
        )} to upload and process images`,
        type: alertTypes.success,
        confirmHandler: () => {
          updateUserState()
        },
      })
    } else {
      setAlertModal({
        message: response?.error || 'error occured',
        type: alertTypes.error,
      })
    }
  }

  const cancelUpcomingSubscription = async () => {
    setIsLoading(true)
    const response = await Subscriptions.deleteUserUpcomingSubscription(user.id)
    setIsLoading(false)

    if (response?.success) {
      setAlertModal({
        message:
          'Successfully cancelled your upcoming subscription. Please wait a few moments for the change to be visible on your profile',
        type: alertTypes.success,
        confirmHandler: () => {
          updateUserState()
        },
      })
    } else {
      console.log(response)
      setAlertModal({
        message: response?.error || 'error occured',
        type: alertTypes.error,
      })
    }
  }

  const updateUserState = () => {
    setIsLoading(true)
    setTimeout(() => {
      getLoggedInUser()
      getUserTeams()
      resetState()
      onClose()
      setIsLoading(false)
    }, 3000)
  }

  const renderForm = () => {
    switch (step) {
      case 1:
        return (
          <SelectSubscriptionForm
            user={user}
            selectedPlanId={selectedPlanId}
            setSelectedPlanId={setSelectedPlanId}
            transitionStep={transitionStep}
            setAlertModal={setAlertModal}
            updateSubscription={updateSubscription}
            onCancelUpcomingSubscription={handleCancelUpcomingSubscription}
            onCancelSubscription={handleCancelSubscription}
            onClose={handleCloseDialog}
          />
        )
      case 2:
        return (
          <BillingForm
            billingDetails={billingDetails}
            setBillingDetails={setBillingDetails}
            createSubscriptionIntent={createSubscriptionIntent}
            gotoPrevious={() => transitionStep(-1)}
          />
        )
      case 3:
      case 4:
        return (
          <Elements stripe={stripePromise}>
            <PaymentForm
              paymentIntent={paymentIntent}
              billingDetails={billingDetails}
              updateUserState={updateUserState}
              setIsLoading={setIsLoading}
              setAlertModal={setAlertModal}
              gotoPrevious={() => {
                cancelSubscriptionInvoice()
                transitionStep(-1)
              }}
            />
          </Elements>
        )
      default:
        return <></>
    }
  }

  return (
    <>
      <Dialog
        classes={{ root: 'subscriptions-dialog-container', paper: `subscriptions-dialog ${step === 3 ? 'small' : ''}` }}
        open={open}
        onClose={handleCloseDialog}
        maxWidth={false}
        transitionDuration={0}
      >
        <CloseIcon onClick={handleCloseDialog} className="btn-close-menu" />

        <DialogContent>{renderForm()}</DialogContent>
        <LoadingAnimation visible={isLoading} />
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLoggedInUser: () => dispatch(getLoggedInUser()),
    getUserTeams: () => dispatch(getUserTeams()),
  }
}

export default connect(() => ({}), mapDispatchToProps)(React.memo(SubscriptionsDialog))
