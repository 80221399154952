export function userGeoLocation(state = [], action) {
  switch (action.type) {
    case "SET_USER_GEO_LOCATION":
      return action.userGeoLocation;
    default:
      return state;
  }
}

export function projectsMapCenter(
  state = [49.27889261335716, -122.91654109954834],
  action
) {
  switch (action.type) {
    case "SET_PROJECTS_MAP_CENTER":
      return action.projectsMapCenter;
    default:
      return state;
  }
}

export function leafletMapElement(state = {}, action) {
  switch (action.type) {
    case "SET_LEAFLET_MAP_ELEMENT":
      return action.data;
    default:
      return state;
  }
}

export function activeMapLayerMapLayer(state = false, action) {
  switch (action.type) {
    case "SET_ACTIVE_MAP_LAYER":
      return action.data;
    default:
      return state;
  }
}

export function previewMarkers(state = [], action) {
  switch (action.type) {
    case "SET_PREVIEW_MARKERS":
      if (action.data === null) {
        return [];
      } else if (Array.isArray(action.data)) {
        return action.data;
      } else {
        return [...state, action.data];
      }
    case "REMOVE_PREVIEW_MARKERS":
      let newState = [...state];
      for (let uuidFilename of action.uuidFilenames) {
        let previewIndex = newState.findIndex(
          (f) => f.uuidFilename === uuidFilename
        );
        if (previewIndex !== -1) {
          newState.splice(previewIndex, 1);
        }
      }
      return newState;
    default:
      return state;
  }
}

export function previewUploadedMarkers(state = [], action) {
  switch (action.type) {
    case "SET_PREVIEW_UPLOADED_MARKERS":
      if (action.data === null) {
        return [];
      } else if (Array.isArray(action.data)) {
        return action.data;
      } else {
        return [...state, action.data];
      }
    default:
      return state;
  }
}

export function viewportCentre(state = [], action) {
  switch (action.type) {
    case "SET_VIEWPORT_CENTRE":
      return action.data;
    default:
      return state;
  }
}

export function mapZoomLevel(state = 10, action) {
  switch (action.type) {
    case "SET_MAP_ZOOM_LEVEL":
      return action.zoom;
    default:
      return state;
  }
}

export function mapMaxNativeZoomLevel(state = 22, action) {
  switch (action.type) {
    case "SET_MAP_MAX_NATIVE_ZOOM_LEVEL":
      return action.zoom;
    default:
      return state;
  }
}

export function mapBounds(state = false, action) {
  switch (action.type) {
    case "SET_MAP_BOUNDS":
      return action.bounds;
    default:
      return state;
  }
}

export function reviseSearchBounds(state = false, action) {
  switch (action.type) {
    case "SET_REVISE_SEARCH_BOUNDS":
      return action.bool;
    default:
      return state;
  }
}

export function startingMarker(state = {}, action) {
  switch (action.type) {
    case "SET_STARTING_MARKER":
      return action.marker;
    default:
      return state;
  }
}

export function searchMarker(state = {}, action) {
  switch (action.type) {
    case "SET_SEARCH_MARKER":
      return action.coordinates;
    default:
      return state;
  }
}

export function mappingPhotoMarkers(state = [], action) {
  switch (action.type) {
    case "SET_MAPPING_PHOTO_MARKERS":
      return action.markers;
    default:
      return state;
  }
}

export function panoCentroid(state = [], action) {
  switch (action.type) {
    case "SET_PANO_CENTROID":
      return action.centroid;
    default:
      return state;
  }
}

export function orbitPhotoMarkers(state = [], action) {
  switch (action.type) {
    case "SET_ORBIT_PHOTO_MARKERS":
      return action.markers;
    default:
      return state;
  }
}

export function orbitCentroid(state = [], action) {
  switch (action.type) {
    case "SET_ORBIT_CENTROID":
      return action.centroid;
    default:
      return state;
  }
}

const defaultMapLayers = {
  basemap: true,
  orthomosaic: false,
  dsm: false,
  contours: false,
  vegetation: false,
  pdfs: [],
};
export function mapLayers(state = defaultMapLayers, action) {
  switch (action.type) {
    case "SET_MAP_LAYERS":
      return { ...state, ...action.mapLayers };
    case "SET_PDF_LAYERS":
      return { ...state, pdfs: action.pdfs };
    default:
      return state;
  }
}

export function profileCoordinate(state = null, action) {
  switch (action.type) {
    case "SET_PROFILE_COORDINATE":
      return action.coordinate;
    default:
      return state;
  }
}

export function contoursMapLayer(state = {}, action) {
  switch (action.type) {
    case "SET_CONTOURS_MAP_LAYER":
      return { ...state, ...action.contoursMapLayer };
    default:
      return state;
  }
}

// users/pilots displayed on map for admins
const initialUsersSearchState = {
  isLoading: false,
};

export function usersSearchState(state = initialUsersSearchState, action) {
  switch (action.type) {
    case "SET_USERS_SEARCH_STATE":
      return { ...state, ...action.state };
    default:
      return state;
  }
}
export function users(state = [], action) {
  switch (action.type) {
    case "SET_USERS":
      return action.users;
    default:
      return state;
  }
}

const initialUserSearchOptions = {
  name: "",
};

export function userSearchOptions(state = initialUserSearchOptions, action) {
  switch (action.type) {
    case "SET_USER_SEARCH_OPTIONS":
      if (action.options === null) {
        return { ...initialUserSearchOptions };
      } else {
        return { ...state, ...action.options };
      }
    default:
      return state;
  }
}

export function spexigonData(state = [], action) {
  switch (action.type) {
    case "SET_SPEXIGON_DATA":
      return action.data;
    default:
      return state;
  }
}
