import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { userRegister, loginWithGoogle } from '../actions/auth'
import { setRequestInProgress } from '../actions/request'

import { getCustomOrgSubDomain } from '../helpers'

import LoadingAnimation from '../components/LoadingAnimation/'
import Signup from '../components/Signup/'

class SignupContainer extends Component {
  render() {
    let params = new URLSearchParams(window.location.search)
    const orgSubdomain = getCustomOrgSubDomain()

    const organization = params.get('org') || orgSubdomain

    if(!this.props.userIsLoggedIn){
      let signUpPage = <Signup {... this.props} />

      return (
        <div className="App">
          <LoadingAnimation visible={(this.props.requestsInProgress.REGISTRATION || this.props.requestsInProgress.GLOBAL)} />
          {signUpPage}
        </div>
      )
    }

    else {
      // if this was an org join link, send them to the appropriate org join page
      if (organization) {
        return <Redirect to={`/organizations/join/${organization}`} />
      }
      return <Redirect to="/" />
    }

  }
}


const mapStateToProps = state => {
  return {
    userIsVerified: state.userIsVerified,
    userIsLoggedIn: state.userIsLoggedIn,
    requestsInProgress: state.request,
  }
}


const mapDispatchToProps = dispatch => {
  return {
    userRegister: (name, email, password, referral_code, orgId) => dispatch(userRegister(name, email, password, referral_code, orgId)),
    setRequestInProgress: (bool, type) => dispatch(setRequestInProgress(bool, type)),
    loginWithGoogle: (token) => dispatch(loginWithGoogle(token))
  }
}




export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer)
