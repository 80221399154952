import React, { Component } from 'react'
import PropTypes from 'prop-types'
import hash from 'object-hash'
import { convertArea } from '@turf/helpers'
import { formatTime, getCamera } from '../../helpers'
import FlightEstimates from '../FlightEstimates/'
import pathify from 'spexi-pathify'
import { sortable } from 'react-sortable'
import { FlightPlan } from '../../api'
import './styles.css'
import { CSSTransition } from 'react-transition-group'
import AddFlight from '../AddFlight/'

class FlightPlanItem extends React.Component {
  render() {
    return <div {...this.props}>{this.props.children}</div>
  }
}

let SortableFlightItem = sortable(FlightPlanItem)

class FlightPlanList extends Component {
  static propTypes = {
    flightPlans: PropTypes.array.isRequired,
    editMode: PropTypes.bool,
    isAddingProject: PropTypes.bool.isRequired,
    isEdittingProject: PropTypes.bool.isRequired,
    editFlightPlan: PropTypes.func.isRequired,
    deleteFlightPlan: PropTypes.func.isRequired,
    openSingleFlightPlanView: PropTypes.func.isRequired,
    setIsAddingFlightPlan: PropTypes.func.isRequired,
    setProjectForm: PropTypes.func.isRequired,
    projectForm: PropTypes.object.isRequired,
  }

  state = {
    flightPlans: this.props.flightPlans ? this.props.flightPlans : [],
  }

  onSortItems = (flightPlans) => {
    // add order to flightPlan object
    flightPlans = flightPlans.map((flightPlan, index) => {
      flightPlan.order = index
      return flightPlan
    })

    console.log(flightPlans)

    this.props.setFlightPlansFetchDataSuccess(flightPlans)

    // If this component is being used on the Project Creation form component, use redux
    if (this.props.isEdittingProject || this.props.isAddingProject) {
      // set ordered flight plan list in the form data
      this.props.setProjectForm({ flightPlans })
    } else {
      // otherwise, it will be used within the Project Flight Plans List view.. so update order on the server directly
      // to match the UI state
      FlightPlan.batchUpdateFlightPlans({ flightPlans }).then((response) => {
        console.log(response)
      })
    }
  }

  flightPlanViewEdit = (flightId) => {
    if (!this.props.isEdittingProject && !this.props.isAddingProject) {
      this.props.openSingleFlightPlanView(flightId)
    }
  }

  handleDelete = (flightPlan) => {
    if (this.props.isEdittingProject) {
      if (flightPlan.id) {
        this.props.deleteFlightPlan(flightPlan)
      } else {
        let flightPlans = this.props.projectForm.flightPlans
        flightPlans = flightPlans.filter((fp) => fp.uuid !== flightPlan.uuid)
        this.props.setProjectForm({ flightPlans })
      }
    } else {
      let flightPlans = this.props.projectForm.flightPlans
      flightPlans = flightPlans.filter((fp) => fp.uuid !== flightPlan.uuid)
      this.props.setProjectForm({ flightPlans })
    }
  }

  renderFlightPlanList = () => {
    if (this.props.flightPlans && this.props.flightPlans.length > 0) {
      return (
        <div>
          <h4 className="flights__title">Flight Plans</h4>
          <div className="flights__list">
            {this.props.flightPlans &&
              this.props.flightPlans.map((flightPlan, index) => {
                let typeLabel
                if (flightPlan.type === 'map') {
                  typeLabel = 'Mapping Flight'
                } else if (flightPlan.type === 'orbit') {
                  typeLabel = 'Orbit Flight'
                } else if (flightPlan.type === 'panorama') {
                  typeLabel = 'Panoramic Flight'
                }

                let flightKey = flightPlan.id ? flightPlan.id : index
                let camera = getCamera(this.props.droneList, flightPlan.camera)
                // WARNING: calling pathify in render is going to be extremely *SLOW* because it has to recalculate it every
                // re-render (which can be triggered by a million things no doubt)

                let flightPath = pathify(flightPlan, camera)
                return (
                  <SortableFlightItem
                    key={index}
                    onSortItems={this.onSortItems}
                    items={this.props.flightPlans}
                    sortId={index}
                  >
                    <article
                      className="flights__item"
                      key={hash(flightKey)}
                      onClick={() => this.flightPlanViewEdit(flightKey)}
                    >
                      <span className="material-icons drag-handle">
                        drag_indicator
                      </span>
                      <h4>{flightPlan.name || typeLabel}</h4>
                      {this.props.editMode && (
                        <div tabIndex="0" className="list-controls">
                          <ul className="list-controls-content">
                            <li>
                              <button
                                type="button"
                                onClick={() =>
                                  this.props.editFlightPlan(flightPlan)
                                }
                              >
                                {' '}
                                Edit
                              </button>
                            </li>
                            <li>
                              <button
                                type="button"
                                onClick={() => this.handleDelete(flightPlan)}
                              >
                                {' '}
                                Delete
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}

                      <FlightEstimates
                        {...this.props}
                        minutes={formatTime(flightPath.meta.duration)}
                        acres={
                          flightPath.meta.acres &&
                          convertArea(
                            flightPath.meta.acres,
                            'meters',
                            'acres'
                          ).toFixed(1)
                        }
                        photoCount={flightPath.meta.photoCount}
                        batteries={flightPath.meta.batteries}
                      />
                    </article>
                  </SortableFlightItem>
                )
              })}
          </div>
        </div>
      )
    } else {
      return <span></span>
    }
  }

  render() {
    let addButtonLabel =
      this.props.flightPlans < 1 ? 'Add Flight Plan' : 'Add Another'
    return (
      <section
        className={
          'flights' +
          (this.props.isEdittingProject || this.props.isAddingProject
            ? ' editmode'
            : '')
        }
      >
        {this.renderFlightPlanList()}

        {(this.props.editMode || (this.props.project.currentUserOrgPermissions && this.props.project.currentUserOrgPermissions.includes('flightplan_create'))) && (
          <button
            type="button"
            className="btn"
            onClick={() => this.props.setIsAddingFlightPlan(true)}
          >
            {addButtonLabel}
          </button>
        )}
        <CSSTransition
          in={
            this.props.isAddingFlightPlan &&
            !this.props.isEdittingFlightPlan
          }
          timeout={300}
          classNames="addFlight"
          unmountOnExit
        >
          <AddFlight {...this.props} />
        </CSSTransition>
      </section>
    )
  }
}

export default FlightPlanList
