import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './styles.css'

const Modal = props => {
  const [open, setOpen] = useState(false)

  const closeHandler = (e) => {
    if (props.closeHandler) {
      props.closeHandler(e)
    }

    setOpen(false)
  }

  return (
    <div hidden={!props.isOpen && !open}>
      <section className={props.customClass ? `generic-modal${' ' + props.customClass}` : 'generic-modal'}>
        <svg viewBox="0 0 74 74"
          onClick={(e) => {
            setOpen(false)
            closeHandler(e)
          }}
          className="close-icon">
          <g data-name="Group 3278">
            <path d="M35.417 25.604v8h-8v2.668h8v8h2.668v-8h8v-2.668h-8v-8z" fill="#555"
              className="plus-lines" />
          </g>
        </svg>


        <h1 className="generic-modal__title">{props.title}</h1>

        <div className="generic-modal__content">
          {props.children}
        </div>
      </section>
    </div>
  )
}

Modal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  closeHandler: PropTypes.func,

}

export default Modal