import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import centroid from '@turf/centroid'
import { kml } from '@tmcw/togeojson'
import xmldom from 'xmldom'
import AddFlight from '../AddFlight/'
import FlightPlanList from '../FlightPlanList/'
import ProjectPdfList from '../ProjectPdfList'
import SidebarHeader from '../SidebarHeader'
import { Auth, Team, OrganizationApi } from '../../api'
import Tooltip from '../Tooltip'
import './styles.css'
import { alertTypes } from '../../util/constants'
// import FormGroup from '@material-ui/core/FormGroup'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 339,
    },
  },
}

class AddProject extends Component {
  static propTypes = {
    setIsAddingProject: PropTypes.func.isRequired,
    setProjectForm: PropTypes.func.isRequired,
    drawStartingMarker: PropTypes.func.isRequired,
    editProject: PropTypes.func.isRequired,
    updateProjectData: PropTypes.func.isRequired,
    storeProjectData: PropTypes.func.isRequired,
    setMapZoomLevel: PropTypes.func.isRequired,
    backToProjectList: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    projectForm: PropTypes.object.isRequired,
    isEdittingProject: PropTypes.bool.isRequired,
    isAddingFlightPlan: PropTypes.bool.isRequired,
    isEdittingFlightPlan: PropTypes.bool.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      selectedUser: this.props.project.operator
        ? this.props.project.operator.id
        : null,
      teams: [],
      orgs: [],
      isReferencesOpen: false,
    }
  }

  componentDidMount() {
    this.props.setIsAddingProject(true)

    if (this.props.match.path !== '/project/:id/edit') {
      if (
        this.props.viewportCentre.length > 1 &&
        Object.keys(this.props.leafletMapElement).length !== 0 &&
        this.props.leafletMapElement?._mapPane
      ) {
        this.props.drawStartingMarker()
        this.props.setMapZoomLevel(17)
      }
    }

    if (this.props.match.path === '/project/:id/edit') {
      this.props.editProject(this.props.match.params.id)
    }

    // fetch users teams for optional team assignment of projects
    Team.getAll().then((response) => {
      if (response.success) {
        this.setState({
          teams: response.data,
        })
      }
    })

    // fetch organizations
    if (this.props.user) {
      this.fetchOrganizations()
    }
  }

  fetchOrganizations() {
    if (this.props.user.isAdmin) {
      OrganizationApi.getAll()
        .then((resp) => {
          if (resp && resp.success) {
            let all_organizations = Object.values(resp.data)
            let organizations_array = []
            all_organizations.forEach((organization) => {
              organizations_array.push({
                organization: organization,
              })
            })
            console.log(organizations_array)
            if (organizations_array.length) {
              this.setState({
                orgs: organizations_array,
              })
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      OrganizationApi.myOrgs()
        .then((resp) => {
          if (resp && resp.success) {
            const ownedOrgs = resp.data.filter(
              (org) => org.organization.active === 1
            )
            if (ownedOrgs) {
              this.setState({
                orgs: ownedOrgs
              })
            }
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.user === null &&
      this.props.user !== prevProps.user &&
      (this.props.user.isAdmin || this.props.user.isOrgOwnerOrAdmin)
    ) {
      Auth.getAllUsersData().then((response) => {
        console.log('fetching users')
        this.setState({
          users: response.data,
        })
      })
    }

    if (this.props.user !== prevProps.user && this.props.user) {
      this.fetchOrganizations()
    }

    // we need to make sure the map exists before we can call it
    if (
      Object.keys(this.props.leafletMapElement).length !== 0 &&
      (Object.keys(prevProps.leafletMapElement).length !==
        Object.keys(this.props.leafletMapElement).length) !==
        0 &&
      this.props.leafletMapElement._mapPane &&
      this.props.leafletMapElement._mapPane !==
        prevProps.leafletMapElement._mapPane
    ) {
      if (this.props.match.path !== '/project/:id/edit') {
        if (this.props.viewportCentre.length > 1) {
          this.props.drawStartingMarker()
        }
        this.props.setMapZoomLevel(17)
      }
    }
    // if user is only accessing the project by organization permission, they prevent them from accessing edit project page
    if (prevProps.project !== this.props.project) {
      // TODO: expand this to specify defined permission rather than assume all org projects are like this
      if (
        this.props.match.path === '/project/:id/edit' &&
        this.props.project.isOrgProject &&
        this.props.project.currentUserOrgPermissions &&
        !this.props.project.currentUserOrgPermissions.includes('project_edit')
      ) {
        this.props.history.push(`/project/${this.props.match.params.id}/plan`)
      }
    }
  }

  componentWillUnmount() {
    this.props.clearReferenceBoundaryLayer()
  }

  handleChange = (event) => {
    const target = event.target
    let value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name
    let parts = name.split('.')
    if (name === 'status') {
      value = target.checked ? 'open' : 'draft'
      this.props.setProjectForm({ [name]: value })
    } else if (name === 'is_passcode') {
      value = target.checked ? '' : null
      this.props.setProjectForm({ passcode: value })
    } else if (name === 'public') {
      value = target.checked ? true : false
      this.props.setProjectForm({ [name]: value })
    } else if (name === 'updated_organization_id') {
      this.props.setProjectForm({ [name]: value })
      // }
      // else if (name === 'project_teams') {
      //   // TODO: Make this work and then save properly.
      //   this.props.setProjectForm({
      //     teams: [...this.props.projectForm.teams, value],
      //   })
    } else if (parts.length === 2) {
      // TODO: do some sort of iterative/recursive thing for N splits
      let root = parts[0]
      let key = parts[1]
      let original = this.props.projectForm[root]
      let newValue = { ...original, ...{ [key]: value } }
      this.props.setProjectForm({ [root]: newValue })
    } else {
      this.props.setProjectForm({ [name]: value })
    }
  }

  handleKMLfile = (event) => {
    let file = event.target.files[0]
    this.props.clearReferenceBoundaryLayer()

    let reader = new FileReader()

    reader.addEventListener(
      'load',
      (event) => {
        let file = event.target.result

        if (file) {
          let geojson = kml(
            new xmldom.DOMParser().parseFromString(file, 'text/xml')
          )
          if (geojson.type === 'FeatureCollection') {
            let boundaryCentroid
            // if you can't get a centroid from the data, it likely doesn't have any geographic info in it
            try {
              boundaryCentroid = centroid(geojson)
            } catch (error) {
              this.props.setAlertModal({
                message:
                  'Unable to process KML file structure. Please make sure your KML file is valid.',
                type: alertTypes.error,
              })
            }

            if (boundaryCentroid) {
              const position = [
                boundaryCentroid.geometry.coordinates[1],
                boundaryCentroid.geometry.coordinates[0],
              ]
              this.props.drawReferenceBoundaryLayer(geojson)
              if (
                this.props.startingMarker &&
                this.props.startingMarker.hasOwnProperty('editing')
              ) {
                this.props.startingMarker.remove()
                this.props.setStartingMarker(null)
              }
              this.props.drawStartingMarker(position)
              this.props.fitMaptoReferenceBoundaryLayer()

              this.props.setProjectForm({ reference_boundary: geojson })
              this.props.setProjectsMapCenter(position)
            }
          } else {
            this.props.setAlertModal({
              message:
                'Unable to process KML file structure. Please make sure your KML file is valid.',
              type: alertTypes.error,
            })
          }
        }
      },
      false
    )
    reader.readAsText(file)
  }

  handleClickedTeams = (event, id) => {
    this.state.teams.forEach((team) => {
      if (id === team.id) {
        if (event.target.checked) {
          this.props.setProjectForm({
            teams: [
              ...this.props.projectForm.teams,
              { team: team, team_id: team.id },
            ],
          })
        } else {
          const items = this.props.projectForm.teams.filter(
            (delTeam) => delTeam.team_id !== team.id
          )
          this.props.setProjectForm({
            teams: items,
          })
        }
      }
    })
  }

  toggleReferences = () => {
    let toggle
    toggle = this.state.isReferencesOpen ? false : true
    this.setState({ isReferencesOpen: toggle })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    console.log('project form submit', this.props.projectForm)

    if (this.props.isEdittingProject) {
      this.props.updateProjectData(this.props.projectForm)
    } else {
      this.props.storeProjectData(this.props.projectForm)
    }
  }

  getCheckedTeams = (id) => {
    // console.log(this.props.projectForm.teams)
    let flag = false
    this.props.projectForm.teams.forEach((team) => {
      if (id === team.team_id) {
        flag = true
      }
    })
    return flag
  }

  render() {
    if (!this.props.projectForm.name && this.props.isEdittingProject) {
      return <div></div>
    }

    let formTitle
    if (this.props.isEdittingProject) {
      formTitle = (
        <SidebarHeader
          title="Edit project"
          backLink="/"
          backFunction={this.props.backToProjectList}
        />
      )
    } else {
      formTitle = (
        <SidebarHeader
          title="New project"
          backLink="/"
          backFunction={this.props.backToProjectList}
        />
      )
    }

    let realtor = this.props.projectForm.realtor
    const isAdminOrOrgAdmin =
      this.props.user &&
      (this.props.user.isAdmin || this.props.user.isOrgOwnerOrAdmin)

    return (
      <div className="add-project-form">
        <form onSubmit={this.handleSubmit}>
          {formTitle}

          <label>
            Project Name
            <input
              required
              type="text"
              value={this.props.projectForm.name}
              onChange={this.handleChange}
              placeholder="Enter a name"
              name="name"
            />
          </label>

          <div>
            <label>Project Category </label>
            <select
              value={
                this.props.projectForm.category
                  ? this.props.projectForm.category
                  : ''
              }
              onChange={this.handleChange}
              name="category"
            >
              <option value="" hidden>
                Select category
              </option>
              <option value="general">General</option>
              <option value="real-estate">Real estate</option>
              <option value="tower-inspection">Tower Inspection</option>
            </select>
          </div>

          <div className={'import-export-settings' + (this.state.isReferencesOpen ? ' open' : '')}>
            <h4 className="toggle-settings" onClick={this.toggleReferences}
              style={{marginBottom: '0'}}>
                Reference Layers (Optional)
            </h4>
            <div className="import-export-settings__options">
              <div className="field-set">
                <label htmlFor="KMLimport" className="reference-layer-title">
                  Boundary KML: {' '}
                  <Tooltip 
                    tip="Upload a KML file of a boundary or area of interest to use as a reference when creating your flight plans." />
                  <input
                    type="file"
                    name="KMLimport"
                    accept="application/vnd.google-earth.kml+xml"
                    onChange={this.handleKMLfile}
                    onClick={(event) => {
                      event.target.value = null
                    }}
                  />
                </label>
              </div>
              { this.props.isEdittingProject && (
                <ProjectPdfList 
                  {...this.props}
                  projectPdfs={this.props.projectForm.projectPdfs}
                />
              )}
              { !this.props.isEdittingProject && (
                <>
                  <h4 className="reference-layer-title">Georeferenced PDFs</h4>
                  <label>Note: Georeferenced PDFs are only available after you save your project.</label>
                </>
              )}
            </div>
          </div>


          {this.props.projectForm.flightPlans && (
            <FlightPlanList
              {...this.props}
              editMode={true}
              flightPlans={this.props.projectForm.flightPlans}
            />
          )}

          <div className="field-set">
            <label>
              Notes
              <textarea
                maxLength="21845"
                value={this.props.projectForm.description || ''}
                onChange={this.handleChange}
                placeholder="Enter any notes or comments about the project."
                name="description"
              />
            </label>
          </div>

          <label htmlFor="status">
            <input
              type="checkbox"
              id="status"
              name="status"
              checked={this.props.projectForm.status === 'draft' ? false : true}
              onChange={this.handleChange}
            />
            Publish{' '}
            <Tooltip tip="Uncheck this box to save your project as a draft." />
          </label>

          {isAdminOrOrgAdmin &&
            this.props.users &&
            this.props.isEdittingProject && (
            <div>
              <label>Author: </label>
              <select
                value={
                  this.props.projectForm.author_id
                    ? this.props.projectForm.author_id
                    : ''
                }
                onChange={this.handleChange}
                name="author_id"
              >
                <option value="" hidden>
                    Select author
                </option>
                {this.props.users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {' '}
                      {user.name} -- {user.email}
                    </option>
                  )
                })}
              </select>
            </div>
          )}

          {isAdminOrOrgAdmin && this.props.users && (
            <div>
              <label>Operator: </label>
              <select
                value={
                  this.props.projectForm.operator_id
                    ? this.props.projectForm.operator_id
                    : ''
                }
                onChange={this.handleChange}
                name="operator_id"
              >
                <option value="" hidden>
                  Select operator
                </option>
                {this.props.projectForm.operator_id && (
                  <option value="">[None]</option>
                )}
                {this.props.users.map((user) => {
                  return (
                    <option key={user.id} value={user.id}>
                      {' '}
                      {user.name} -- {user.email}
                    </option>
                  )
                })}
              </select>
            </div>
          )}

          {this.state.orgs.length > 0 && (
            <div className="project-team">
              <label>
                Project Organization:{' '}
                <Tooltip tip="Link this project to an organization (optional)" />
              </label>
              <select
                value={
                  this.props.projectForm.updated_organization_id
                    ? this.props.projectForm.updated_organization_id
                    : this.props.projectForm.organization_id
                      ? this.props.projectForm.organization_id
                      : ''
                }
                onChange={this.handleChange}
                name="updated_organization_id"
              >
                {/* once a project is assigned to an org, you can't unassign from the org, only change the org, at least for now */}
                <option value="none" hidden>
                  Select organization
                </option>
                {(this.props.projectForm.organization_id !== null ||
                  (this.props.projectForm.updated_organization_id &&
                    this.props.projectForm.updated_organization_id !==
                      'none')) && <option value="none">[None]</option>}
                {this.state.orgs.map((org) => {
                  const organization = org.organization
                  return (
                    <option key={organization.id} value={organization.id}>
                      {organization.name}
                    </option>
                  )
                })}
              </select>
            </div>
          )}

          {this.state.teams.length > 0 && (
            <div className="project-team">
              <label>
                Project Team:{' '}
                <Tooltip tip="Link this project to a team or teams for them to access it." />
              </label>

              <FormControl
                style={{
                  width: '100%',
                  margin: '0.5rem 0 1.5rem 0',
                  borderRadius: 0,
                }}
              >
                <Select
                  labelId="project_teams-label"
                  id="project_teams"
                  name="project_teams"
                  multiple
                  displayEmpty
                  MenuProps={MenuProps}
                  value={
                    this.props.projectForm.teams.length > 0
                      ? [this.props.projectForm.teams.map((team) => team.name)]
                      : []
                  }
                  // onChange={this.handleChange}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return 'Select team(s)'
                    } else {
                      return selected[0].length + ' teams selected'
                    }
                  }}
                  input={
                    <OutlinedInput
                      shrink="false"
                      notched={false}
                      style={{ borderRadius: 0 }}
                    />
                  }
                >
                  <MenuItem disabled value="">
                    <em>Select team(s)</em>
                  </MenuItem>

                  {this.state.teams.map((team) => {
                    let members = team.members.map((member, index) => {
                      return (
                        member.user.name +
                        (team.members.length - 1 !== index ? ', ' : '')
                      )
                    })

                    return (
                      <MenuItem key={team.id} value={team.id}>
                        <Checkbox
                          checked={this.getCheckedTeams(team.id)}
                          onChange={(e) => {
                            this.handleClickedTeams(e, team.id)
                          }}
                        />
                        <ListItemText
                          primary={team.name}
                          secondary={members}
                          primaryTypographyProps={{
                            style: { fontSize: '14px' },
                          }}
                          secondaryTypographyProps={{
                            style: { fontSize: '12px' },
                          }}
                        />
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </div>
          )}

          {this.props.user &&
            (this.props.user.isAdmin ||
              this.props.user.roles.filter(
                (role) => role.name === 'spexigon-user'
              )) &&
            this.props.isEdittingProject && (
            <label htmlFor="is_branded" style={{ marginBottom: '2rem' }}>
              <input
                type="checkbox"
                id="is_branded"
                name="is_branded"
                checked={this.props.projectForm.is_branded}
                onChange={this.handleChange}
              />
                Spexi Branded{' '}
              <Tooltip tip="Unchecked this box to remove Spexi branding in player." />
            </label>
          )}

          <label htmlFor="public" style={{ marginBottom: '1rem' }}>
            <input
              type="checkbox"
              id="public"
              name="public"
              checked={this.props.projectForm.public}
              onChange={this.handleChange}
            />
            Enable sharing{' '}
            <Tooltip tip="Uncheck this box to disable public sharing of this project in our player." />
          </label>

          {this.props.isEdittingProject && (
            <label>
              Friendly URL{' '}
              <Tooltip tip="Player URL such as <br />https://project.spexigeo.com/project-name. Type only 'project-name'." />
              <input
                type="text"
                value={this.props.projectForm.slug || ''}
                onChange={this.handleChange}
                placeholder="e.g.: project-name"
                name="slug"
              />
            </label>
          )}

          {this.props.isEdittingProject &&
            this.props.project.category === 'real-estate' && (
            <section className="realtor-profile-fields">
              <h4 className="section-title">Realtor Profile</h4>
              <label>
                  Realtor Name{' '}
                <Tooltip tip="This is the first line under the project title in the player." />
                <input
                  type="text"
                  value={realtor ? realtor.name : ''}
                  onChange={this.handleChange}
                  placeholder="e.g.: Kim McBean - Royal Lepage"
                  name="realtor.name"
                />
              </label>

              <label>
                  Realtor Phone{' '}
                <Tooltip tip="This is the first line under the project title in the player." />
                <input
                  type="text"
                  value={realtor ? realtor.phone : ''}
                  onChange={this.handleChange}
                  placeholder="e.g.: 604-555-5555"
                  name="realtor.phone"
                />
              </label>

              <label>
                  Realtor Link URL{' '}
                <Tooltip tip="Website address or email. Format for website link is 'http://www.example.com' and email link is 'mailto:sean@example.com'." />
                <input
                  type="text"
                  value={realtor ? realtor.link_url : ''}
                  onChange={this.handleChange}
                  placeholder="e.g.: https://spexigeo.com/"
                  name="realtor.link_url"
                />
              </label>

              <label>
                  Realtor Link Text{' '}
                <Tooltip tip="This is the link text for the URL in the previous field." />
                <input
                  type="text"
                  value={realtor ? realtor.link_text : ''}
                  onChange={this.handleChange}
                  placeholder="e.g.: Spexi Website"
                  name="realtor.link_text"
                />
              </label>
            </section>
          )}

          <input type="submit" className="button solid" value="Save Project" />

          {this.props.isEdittingProject && (
            <button
              className="button solid"
              onClick={(e) => {
                this.props.deleteProject(this.props.project.id)
                e.preventDefault()
              }}
            >
              {' '}
              Delete project
            </button>
          )}
        </form>

        <CSSTransition
          in={this.props.isAddingFlightPlan && !this.props.isEdittingFlightPlan}
          timeout={300}
          classNames="addFlight"
          unmountOnExit
        >
          <AddFlight {...this.props} />
        </CSSTransition>
      </div>
    )
  }
}

export default AddProject
