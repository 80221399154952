import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import hash from 'object-hash'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import { PLAYER_URL } from '../../config'
import './styles.css'
import { getProductName } from '../../helpers'
import { Project } from '../../api'
import ProductIcon from '../ProductIcon'
import moment from 'moment'
import 'moment-duration-format'
dayjs.extend(calendar)

class ProductReviewList extends Component {
  static propTypes = {
    userTimezone: PropTypes.string,
    flightPlans: PropTypes.array.isRequired,
    isAddingProject: PropTypes.bool.isRequired,
    isEdittingProject: PropTypes.bool.isRequired,
    project: PropTypes.object.isRequired,
    products: PropTypes.array.isRequired,
    getProducts: PropTypes.func.isRequired,
    setProductsDataSuccess: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      temporaryAccessToken: '',
    }
  }

  componentDidMount() {
    if (
      this.props.project &&
      this.props.project.id &&
      !this.props.project.public
    ) {
      Project.createTemporarySharingToken(this.props.project.id).then(
        (response) => {
          if (response && response.success && response.data) {
            this.setState({ temporaryAccessToken: response.data })
          }
        }
      )
    }
  }

  openSingleProduct(id) {
    if (this.props.products.length > 0) {
      let currentProduct = this.props.products.filter(
        (product) => product.id === id
      )[0]
      this.props.setProductDataSuccess(currentProduct)
    }
  }

  render() {
    if (
      this.props.products.length > 0 &&
      this.props.products.filter((product) => product.status === 'done')
        .length > 0
    ) {
      return (
        <section className="product-list">
          <h4 className="products__title">Products</h4>
          <div className="products">
            {this.props.products
              .filter((product) => product.status === 'done')
              .map((product, index) => {
                if (product.type === 'map' || product.type === 'map+3d') {
                  return (
                    <NavLink
                      onClick={() => this.openSingleProduct(product.id)}
                      to={`/project/${this.props.project.id}/review/${product.id}`}
                      key={hash(index)}
                      className="products__item"
                    >
                      <div className="products__icon">
                        <ProductIcon product={product} />
                      </div>
                      <div className="products__details">
                        <h4>{getProductName(product)}</h4>
                        {product.capture_date && (
                          <p>
                            Captured{' '}
                            {dayjs(product.capture_date).calendar(null, {
                              sameDay: '[Today]',
                              nextDay: '[Tomorrow]',
                              nextWeek: 'dddd',
                              lastDay: '[Yesterday]',
                              lastWeek: '[Last] dddd',
                              sameElse: 'DD/MM/YYYY',
                            })}
                          </p>
                        )}
                        {product.usage &&
                        product.usage.total_time &&
                        this.props.user.isAdmin ? (
                            <p>
                            Processing time:{' '}
                              {moment
                                .duration(product.usage.total_time, 'hours')
                                .format('D:*HH:mm')}{' '}
                            Hours
                            </p>
                          ) : (
                            ''
                          )}
                        {product.usage &&
                        product.usage.total_size &&
                        this.props.user.isAdmin ? (
                            <p>Total size: {product.usage.total_size} GB</p>
                          ) : (
                            ''
                          )}
                      </div>
                    </NavLink>
                  )
                } else if (product.type === 'video') {
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${PLAYER_URL}/${this.props.project.id}/${
                        this.state.temporaryAccessToken !== ''
                          ? '?access=' + this.state.temporaryAccessToken
                          : ''
                      }`}
                      key={hash(index)}
                      className="products__item  external"
                    >
                      <div className="products__icon">
                        <ProductIcon product={product} />
                      </div>
                      <div className="products__details">
                        <h4>{getProductName(product)}</h4>
                        {product.capture_date && (
                          <p>
                            Captured{' '}
                            {dayjs(product.capture_date).calendar(null, {
                              sameDay: '[Today]',
                              nextDay: '[Tomorrow]',
                              nextWeek: 'dddd',
                              lastDay: '[Yesterday]',
                              lastWeek: '[Last] dddd',
                              sameElse: 'DD/MM/YYYY',
                            })}
                          </p>
                        )}
                        {product.usage &&
                        product.usage.total_time &&
                        this.props.user.isAdmin ? (
                            <p>
                            Processing time:{' '}
                              {moment
                                .duration(product.usage.total_time, 'hours')
                                .format('D:*HH:mm')}{' '}
                            Hours
                            </p>
                          ) : (
                            ''
                          )}
                        {product.usage &&
                        product.usage.total_size &&
                        this.props.user.isAdmin ? (
                            <p>Total size: {product.usage.total_size} GB</p>
                          ) : (
                            ''
                          )}
                      </div>
                    </a>
                  )
                } else {
                  return (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${PLAYER_URL}/${this.props.project.id}/${
                        product.type
                      }/${product.id}${
                        this.state.temporaryAccessToken !== ''
                          ? '?access=' + this.state.temporaryAccessToken
                          : ''
                      }`}
                      key={hash(index)}
                      className="products__item  external"
                    >
                      <div className="products__icon">
                        <ProductIcon product={product} />
                      </div>
                      <div className="products__details">
                        <h4>{getProductName(product)}</h4>
                        {product.capture_date && (
                          <p>
                            Captured{' '}
                            {dayjs(product.capture_date).calendar(null, {
                              sameDay: '[Today]',
                              nextDay: '[Tomorrow]',
                              nextWeek: 'dddd',
                              lastDay: '[Yesterday]',
                              lastWeek: '[Last] dddd',
                              sameElse: 'DD/MM/YYYY',
                            })}
                          </p>
                        )}
                        {product.usage &&
                        product.usage.total_time &&
                        this.props.user.isAdmin ? (
                            <p>
                            Processing time:{' '}
                              {moment
                                .duration(product.usage.total_time, 'hours')
                                .format('D:*HH:mm')}{' '}
                            Hours
                            </p>
                          ) : (
                            ''
                          )}
                        {product.usage &&
                        product.usage.total_size &&
                        this.props.user.isAdmin ? (
                            <p>Total size: {product.usage.total_size} GB</p>
                          ) : (
                            ''
                          )}
                      </div>
                    </a>
                  )
                }
              })}
          </div>
          {this.state.temporaryAccessToken !== '' && (
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${PLAYER_URL}/${this.props.project.id}/${
                  this.state.temporaryAccessToken !== ''
                    ? '?access=' + this.state.temporaryAccessToken
                    : ''
                }`}
                className="btn white"
              >
                Open in Viewer
              </a>
            </div>
          )}
        </section>
      )
    } else if (this.props.requestsInProgress.PRODUCTS) {
      return (
        <div
          className="lds-ring"
          style={{
            marginTop: '2rem',
            transform: 'translateX(-50%)',
            left: '50%',
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )
    } else {
      return (
        <section className="product-list">
          <div className="products">
            <h4 className="products__title">No Products Found</h4>
            <p style={{ fontSize: '0.75rem' }}>
              No processed products found on this project. You must upload
              images before we can process any products.
            </p>
          </div>
        </section>
      )
    }
  }
}

export default ProductReviewList
