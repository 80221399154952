import React, { useState, useEffect } from 'react'
import { Dialog, Box, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import './styles.css'
import { OrganizationApi, OrganizationSubscriptions } from '../../api'
import _ from 'lodash'
import OrganizationTab from './organizationTab'
import SubscriptionTab from './subscriptionTab'
import MembersTab from './membersTab'

const AdminOrganizationDialog = (props) => {
  const {
    organization,
    isModify,
    open,
    setOpenOrganizationsDialog,
    updatePage,
    setAlertModal,
  } = props

  const [tabValue, setTabValue] = useState('1')
  const [organizationData, setOrganizationData] = useState({})
  const [subscriptionData, setSubscriptionData] = useState({})

  useEffect(() => {
    if (organization) {
      let organizationClone = _.cloneDeep(organization)
      setOrganizationData(organizationClone)
      fetchOrganizationSubscription(organizationClone.id)
    }
  }, [organization])

  const fetchOrganizationSubscription = async (id) => {
    OrganizationSubscriptions.getSubscriptionByOrgId(id).then((resp) => {
      if (resp && resp.success) {
        setSubscriptionData({
          ...resp.data,
          ends_at: resp.data.ends_at ? resp.data.ends_at.split(' ')[0] : null,
        })
      } else {
        console.log(resp)
      }
    })
  }

  // clear current organization/subscription Data and tabValue upon dialog close
  const handleDialogClose = () => {
    setOrganizationData({})
    setSubscriptionData({})
    setTabValue('1')
    setOpenOrganizationsDialog(false)
    updatePage()
  }

  // upon organization update, fetch/refresh organization data
  const organizationUpdated = (id) => {
    OrganizationApi.get(id).then((resp) => {
      if (resp && resp.success) {
        let updated = { ...organizationData, ...resp.data }
        setOrganizationData(updated)
        setTabValue('2')
      }
    })
  }

  // upon members update, fetch/refresh organization members data
  const membersUpdated = (id) => {
    OrganizationApi.allMembers(id).then((resp) => {
      if (resp && resp.success) {
        let updated = { ...organizationData, members: resp.data }
        setOrganizationData(updated)
      }
    })
  }

  // upon organization subscription update, fetch/refresh organization subscription data
  const subscriptionUpdated = (id) => {
    OrganizationSubscriptions.get(id).then((resp) => {
      if (resp && resp.success) {
        setSubscriptionData({
          ...resp.data,
          ends_at: resp.data.ends_at ? resp.data.ends_at.split(' ')[0] : null,
        })
        setTabValue('3')
      }
    })
  }

  return (
    <>
      <Dialog
        classes={{
          root: 'admin-organization-dialog-container',
          paper: 'admin-organizations-dialog',
        }}
        open={open}
        onClose={() => {
          handleDialogClose()
        }}
        maxWidth={false}
        transitionDuration={0}
      >
        <CloseIcon
          className="btn-close-menu"
          onClick={() => {
            handleDialogClose()
          }}
        />
        <h1 className="admin-organizations-dialog__title">
          {isModify ? 'Edit Organization' : 'Create New Organization'}
        </h1>
        <Box sx={{ width: '100%' }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                aria-label="organization-tabs"
                onChange={(e, value) => {
                  setTabValue(value)
                }}
              >
                <Tab label="organizations" value="1" />
                <Tab
                  label="subscriptions"
                  value="2"
                  disabled={!organizationData.id}
                />
                <Tab
                  label="members"
                  value="3"
                  disabled={!(organizationData.id && subscriptionData.id)}
                />
              </TabList>

              <TabPanel value="1">
                <OrganizationTab
                  organization={organizationData}
                  setOrganization={setOrganizationData}
                  organizationUpdated={organizationUpdated}
                  setAlertModal={setAlertModal}
                  handleDialogClose={handleDialogClose}
                />
              </TabPanel>
              <TabPanel value="2">
                <SubscriptionTab
                  organization={organizationData}
                  subscription={subscriptionData}
                  setSubscription={setSubscriptionData}
                  subscriptionUpdated={subscriptionUpdated}
                  setAlertModal={setAlertModal}
                  handleDialogClose={handleDialogClose}
                />
              </TabPanel>
              <TabPanel value="3">
                <MembersTab
                  organization={organizationData}
                  members={organizationData.members}
                  setAlertModal={setAlertModal}
                  membersUpdated={membersUpdated}
                  handleDialogClose={handleDialogClose}
                />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Dialog>
    </>
  )
}

export default AdminOrganizationDialog
