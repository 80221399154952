import L from "leaflet";
import "leaflet-draw";
import "leaflet-draw-drag";
import { area, centroid, lineStringToPolygon } from "@turf/turf";
import pathify, { Camera, ellipse } from "spexi-pathify";
import { createPolygonFromBounds } from "../helpers";
import { setProjectForm, projectsBoundsFetchData } from "../actions/projects";
import { setRequestInProgress } from "../actions/request";
import {
  setDrawingFlightMapObjects,
  setOrbitFlightParameters,
  setFlightEstimatesData,
  setNewFlightPlan,
  setFlightParameters,
} from "../actions/flightPlans";

import { User } from "../api";

import blankIcon from "../components/MainMap/blank-marker.png";
import markerIcon from "../components/AddFlight/camera-marker.png";
import panoIcon from "../components/AddFlight/pano-marker.png";
import startIcon from "../components/MainMap/start-marker.png";
import endIcon from "../components/MainMap/end-marker.png";
import centerIcon from "../components/MainMap/marker.png";
import takeOffIcon from "../components/MainMap/take-off-location-marker.png";

const GOOGLE_API_KEY = "AIzaSyBE8iWMY6ucUjg54gBf5V--mOhy9O15AIM";

const blankMarker = new L.Icon({
  iconUrl: blankIcon,
  iconSize: [40, 60],
  iconAnchor: [20, 60],
  zIndexOffset: 10000,
});

const markerImage = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [10, 9],
  iconAnchor: [5, 4],
});

const panoImage = new L.Icon({
  iconUrl: panoIcon,
  iconSize: [37, 37],
  iconAnchor: [19, 19],
});

const startMarker = new L.Icon({
  iconUrl: startIcon,
  iconSize: [16, 16],
  iconAnchor: [8, 8],
});

const endMarker = new L.Icon({
  iconUrl: endIcon,
  iconSize: [16, 16],
  iconAnchor: [8, 8],
});

const centerMarker = new L.Icon({
  iconUrl: centerIcon,
  iconSize: [37, 37],
  iconAnchor: [19, 19],
});

const takeOffMarkerIcon = new L.Icon({
  iconUrl: takeOffIcon,
  iconSize: [25, 25],
  zindex: 0,
});

/**
 * Ask for geolocation from the browser and center map
 */
export function getGeoLocation(panTo) {
  return (dispatch, getState) => {
    let coordinates = [];

    dispatch(setRequestInProgress(true, "LOCATION"));

    // Prioritize geolocation obtained from their profile data address
    const { userGeoLocation, openSingleProject, isEdittingProject } =
      getState();
    if (userGeoLocation.length > 0) {
      dispatch(setRequestInProgress(false, "LOCATION"));
      coordinates = userGeoLocation;
      const { leafletMapElement } = getState();
      if (leafletMapElement && panTo) {
        leafletMapElement.panTo(coordinates);
        dispatch(setRequestInProgress(false, "LOCATION"));
      }
    } else {
      // use Google Geolocation API to locate user
      const GEO_URL = `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`;
      fetch(GEO_URL, { method: "post" }).then((response) =>
        response
          .json()
          .then((json) => {
            if (!response.ok) {
              dispatch(setRequestInProgress(false, "LOCATION"));
              return Promise.reject(json);
            }

            coordinates = [json.location.lat, json.location.lng];
            if (coordinates && coordinates.length > 1) {
              dispatch(setUserGeoLocation(coordinates));
              if (coordinates.length > 0) {
                if (!openSingleProject && !isEdittingProject) {
                  dispatch(setProjectsMapCenter(coordinates));
                } else {
                  dispatch(setRequestInProgress(false, "LOCATION"));
                }

                const { leafletMapElement } = getState();
                if (leafletMapElement && panTo) {
                  leafletMapElement.panTo(coordinates);
                  dispatch(setRequestInProgress(false, "LOCATION"));
                }
              }
            }
          })
          .catch(console.error)
      );
    }
  };
}

export function setProjectsMapCenter(projectsMapCenter) {
  return {
    type: "SET_PROJECTS_MAP_CENTER",
    projectsMapCenter,
  };
}

export function setUserGeoLocation(userGeoLocation) {
  return {
    type: "SET_USER_GEO_LOCATION",
    userGeoLocation,
  };
}

export function setLeafletMapElement(data) {
  return {
    type: "SET_LEAFLET_MAP_ELEMENT",
    data,
  };
}

export function setActiveMapLayer(data) {
  return {
    type: "SET_ACTIVE_MAP_LAYER",
    data,
  };
}

export function setPreviewMarkers(data) {
  return {
    type: "SET_PREVIEW_MARKERS",
    data,
  };
}

export function removePreviewMarkers(uuidFilenames) {
  return {
    type: "REMOVE_PREVIEW_MARKERS",
    uuidFilenames,
  };
}

export function setPreviewUploadedMarkers(data) {
  return {
    type: "SET_PREVIEW_UPLOADED_MARKERS",
    data,
  };
}

export function setViewportCentre(data) {
  return {
    type: "SET_VIEWPORT_CENTRE",
    data,
  };
}

export function setMapZoomLevel(zoom) {
  return {
    type: "SET_MAP_ZOOM_LEVEL",
    zoom,
  };
}

export function setMapMaxNativeZoomLevel(zoom) {
  return {
    type: "SET_MAP_MAX_NATIVE_ZOOM_LEVEL",
    zoom,
  };
}

export function setMapBounds(bounds) {
  return {
    type: "SET_MAP_BOUNDS",
    bounds,
  };
}

export function setReviseSearchBounds(bool) {
  return {
    type: "SET_REVISE_SEARCH_BOUNDS",
    bool,
  };
}

/**
 * MAPPING FLIGHT SHAPE - Create resizable polygon shape and flight lines
 */
export function drawStartingMarker(coordinates = []) {
  return (dispatch, getState) => {
    const { leafletMapElement, viewportCentre } = getState();
    let map = leafletMapElement;
    let marker;

    if (map) {
      if (coordinates.length > 0) {
        marker = new L.marker(coordinates, {
          draggable: true,
          icon: blankMarker,
        }).addTo(map);
      } else {
        marker = new L.marker(viewportCentre, {
          draggable: true,
          icon: blankMarker,
        }).addTo(map);
      }

      if (marker) {
        L.DomUtil.addClass(marker._icon, "starting-marker");
        marker
          .bindTooltip("Drag me to your project center!", {
            permanent: true,
            direction: "top",
            offset: L.point(0, -60),
          })
          .openTooltip();
        dispatch(setStartingMarker(marker));
      }

      if (marker && marker._icon) {
        marker.on("drag", () => {
          L.DomUtil.removeClass(marker._icon, "starting-marker");

          dispatch(setStartingMarker(marker));
          dispatch(
            setProjectForm({
              centroid: [marker._latlng.lat, marker._latlng.lng],
            })
          );
        });
      }
    }
  };
}

/**
 * The jumping marker that gets added to map when you start adding a new project
 */
export function setStartingMarker(marker) {
  return {
    type: "SET_STARTING_MARKER",
    marker,
  };
}

/**
 * Search marker used when searching from search bar
 */
export function setSearchMarker(coordinates) {
  return {
    type: "SET_SEARCH_MARKER",
    coordinates,
  };
}

/**
 * MAPPING FLIGHT SHAPE - Create resizable polygon shape and flight lines
 */
export function drawMappingShapes(polygon = false) {
  return (dispatch, getState) => {
    const { startingMarker, leafletMapElement, viewportCentre } = getState();

    if (startingMarker && startingMarker.hasOwnProperty("editing")) {
      startingMarker.remove();
      dispatch(setStartingMarker(null));
    }

    // needed to control CSS in leaflet vertices, so adding a body class here to hook into
    document.body.classList.add("mapping-flight-draw");

    dispatch(
      setDrawingFlightMapObjects({
        shape: null,
        line: null,
        startMarker: null,
        endMarker: null,
        centerMarker: null,
        takeOffLocationMarker: null,
      })
    );

    let map = leafletMapElement;
    let newPolygon;

    if (map) {
      if (polygon) {
        if (polygon.geometry && polygon.geometry.type === "LineString") {
          polygon = lineStringToPolygon(polygon);
        }
        newPolygon = new L.polygon(
          L.GeoJSON.coordsToLatLngs(polygon.geometry.coordinates[0]),
          {
            transform: true,
            draggable: true,
            color: "#00C5D5",
          }
        ).addTo(map);

        leafletMapElement.fitBounds(newPolygon.getBounds());
        dispatch(invalidateMapContainerSize());
      } else {
        // had to draw circle on map to get radius based polygon shape
        let circle = L.circle(viewportCentre, { radius: 50 }).addTo(map);
        let bounds = circle.getBounds();
        circle.remove();

        let polyPoints = createPolygonFromBounds(bounds);
        newPolygon = new L.polygon(polyPoints, {
          transform: true,
          draggable: true,
          color: "#00C5D5",
        }).addTo(map);
      }

      newPolygon.options.editing || (newPolygon.options.editing = {});
      newPolygon.editing.enable();
      dispatch(setDrawingFlightMapObjects({ shape: newPolygon }));

      // draw the lines within the polygon
      dispatch(drawMappingFlightLines(newPolygon.toGeoJSON(8)));

      // attach event listener for resizing
      newPolygon.on("edit", (event) => {
        let shape = event.target;
        dispatch(
          setDrawingFlightMapObjects({
            line: null,
            shape,
            startMarker: null,
            endMarker: null,
            centerMarker: null,
            takeOffLocationMarker: null,
          })
        );
        dispatch(drawMappingFlightLines(shape.toGeoJSON(8)));
      });
    }
  };
}

/**
 * MAPPING FLIGHT LINE - Draw flight lines with pathify given a poly shape
 */

export function drawMappingFlightLines(polygonGEOJSON) {
  return (dispatch, getState) => {
    const {
      leafletMapElement,
      flightParameters,
      droneCamera,
      flightPlanName,
      drawingFlightMapObjects,
    } = getState();
    // Terrain Aware Mission takeOffMarker
    let takeOffMarker = null;
    if (flightParameters.isTerrainAwareMission) {
      document.body.classList.add("terrain-aware-map");
      const { takeOffCoordinate } = flightParameters;
      let coordinates = [
        takeOffCoordinate.latitude,
        takeOffCoordinate.longitude,
      ];
      if (leafletMapElement) {
        takeOffMarker = new L.marker(coordinates, {
          icon: takeOffMarkerIcon,
          draggable: true,
        }).addTo(leafletMapElement);

        if (takeOffMarker) {
          takeOffMarker.bindTooltip("Drag me to your take off location!", {
            permanent: false,
            direction: "top",
            offset: L.point(0, -15),
          });

          takeOffMarker.on("dragend", (e) => {
            dispatch(
              setFlightParameters({
                takeOffCoordinate: {
                  latitude: e.target._latlng.lat,
                  longitude: e.target._latlng.lng,
                },
              })
            );
            dispatch(
              setDrawingFlightMapObjects({
                line: null,
                startMarker: null,
                endMarker: null,
                centerMarker: null,
                takeOffLocationMarker: null,
              })
            );
            // NOTE: setTimeout gives setFlightParameters enough time to set takeOffCoordinate
            // and allows drawingFlightMapObjects to be reset to null before drawMappingFlightLines call
            setTimeout(() => {
              dispatch(
                drawMappingFlightLines(
                  drawingFlightMapObjects.shape.toGeoJSON(8),
                  flightParameters
                )
              );
            }, 100);
          });
        }
      }
    } else {
      document.body.classList.remove("terrain-aware-map");
    }

    let pathifyFlightPlan = {
      type: "map",
      geojson: polygonGEOJSON,
      parameters: flightParameters,
    };
    let flightOutput = pathify(pathifyFlightPlan, droneCamera);
    flightOutput.meta.acres = area(polygonGEOJSON);
    // console.log('flightOutput', flightOutput)
    dispatch(setFlightEstimatesData(flightOutput));

    // console.log('droneCamera', droneCamera)

    let newFlightPlan = {
      ...pathifyFlightPlan,
      status: "ready",
      visible: false,
      drone: droneCamera.drone,
      camera: droneCamera.id,
      name: flightPlanName !== "" ? flightPlanName : null,
    };
    dispatch(setNewFlightPlan(newFlightPlan));

    let editFlightLine = new L.GeoJSON(flightOutput.path, {
      color: "#fff",
      weight: 2,
    }).addTo(leafletMapElement);
    let start = [
      flightOutput.path.geometry.coordinates[0][1],
      flightOutput.path.geometry.coordinates[0][0],
    ];
    start = L.marker(start, {
      icon: startMarker,
      className: "start-end-marker",
      interactive: false,
    }).addTo(leafletMapElement);
    let end = [
      flightOutput.path.geometry.coordinates[
        flightOutput.path.geometry.coordinates.length - 1
      ][1],
      flightOutput.path.geometry.coordinates[
        flightOutput.path.geometry.coordinates.length - 1
      ][0],
    ];
    end = L.marker(end, {
      icon: endMarker,
      className: "start-end-marker",
      interactive: false,
    }).addTo(leafletMapElement);
    if (editFlightLine) {
      dispatch(
        setDrawingFlightMapObjects({
          line: editFlightLine,
          startMarker: start,
          endMarker: end,
          takeOffLocationMarker: takeOffMarker,
        })
      );
    }
  };
}

export function drawMappingCameraMarkers() {
  return (dispatch, getState) => {
    const { mappingPhotoMarkers, flightEstimatesData, leafletMapElement } =
      getState();
    if (mappingPhotoMarkers.length > 0) {
      mappingPhotoMarkers.forEach((marker) => {
        marker.remove();
      });
    }

    if (flightEstimatesData.waypoints.length) {
      let markers = [];
      for (
        let index = 0;
        index < flightEstimatesData.waypoints.length;
        index++
      ) {
        const waypoint = flightEstimatesData.waypoints[index].coordinate;

        let marker = L.marker([waypoint.latitude, waypoint.longitude], {
          icon: markerImage,
        }).addTo(leafletMapElement);
        markers.push(marker);

        dispatch(setMappingPhotoMarkers(markers));
      }
    }
  };
}

export function setMappingPhotoMarkers(markers) {
  return {
    type: "SET_MAPPING_PHOTO_MARKERS",
    markers,
  };
}

export function setPanoCentroid(centroid) {
  return {
    type: "SET_PANO_CENTROID",
    centroid,
  };
}

/**
 * PANO FLIGHT SHAPE: Create pano marker on map
 */
export function drawPanoMarker() {
  return (dispatch, getState) => {
    const {
      startingMarker,
      leafletMapElement,
      viewportCentre,
      panoCentroid,
      panoFlightParameters,
      flightPlanName,
    } = getState();
    const map = leafletMapElement;

    if (startingMarker && startingMarker.hasOwnProperty("editing")) {
      startingMarker.remove();
      dispatch(setStartingMarker(null));
    }

    dispatch(setDrawingFlightMapObjects({ shape: null }));

    if (map) {
      let start = panoCentroid.length !== 0 ? panoCentroid : viewportCentre;
      let marker = new L.marker(start, {
        title: "Your panorama here",
        draggable: true,
        zIndexOffset: 1000,
        icon: panoImage,
      }).addTo(leafletMapElement);

      let camera = new Camera();

      let pathifyFlightPlan = {
        type: "panorama",
        geojson: marker.toGeoJSON(8),
        parameters: panoFlightParameters,
      };

      dispatch(setDrawingFlightMapObjects({ shape: marker }));
      let flightOutput = pathify(pathifyFlightPlan, camera);
      // flightOutput.meta.acres = 0
      dispatch(setFlightEstimatesData(flightOutput));

      let newFlightPlan = {
        ...pathifyFlightPlan,
        name: flightPlanName !== "" ? flightPlanName : null,
        status: "ready",
        visible: false,
      };
      dispatch(setNewFlightPlan(newFlightPlan));

      marker.on("dragend", (event) => {
        let point = event.target;
        dispatch(setPanoCentroid(point._latlng));
      });
    }
  };
}

export function setOrbitCentroid(centroid) {
  return {
    type: "SET_ORBIT_CENTROID",
    centroid,
  };
}

/**
 * ORBIT FLIGHT SHAPE: Create pano marker on map
 */
export function drawOrbitShape() {
  return (dispatch, getState) => {
    const {
      startingMarker,
      leafletMapElement,
      viewportCentre,
      orbitCentroid,
      orbitFlightParameters,
      flightPlanName,
    } = getState();
    const map = leafletMapElement;
    if (startingMarker && startingMarker.hasOwnProperty("editing")) {
      startingMarker.remove();
      dispatch(setStartingMarker(null));
    }

    dispatch(
      setDrawingFlightMapObjects({
        shape: null,
        startMarker: null,
        centerMarker: null,
      })
    );

    if (map) {
      let circleCenter =
        orbitCentroid.length !== 0 ? orbitCentroid : viewportCentre;
      let geojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Point",
          coordinates: [circleCenter[1], circleCenter[0]],
        },
      };
      let options = {
        steps: orbitFlightParameters.photoCount || 8,
        angle: orbitFlightParameters.angle || 0,
      };
      let ellipseGEOJSON = ellipse(
        geojson,
        orbitFlightParameters.radii.x,
        orbitFlightParameters.radii.y,
        options
      );
      console.log("ellipsoid", ellipseGEOJSON);
      let shape = new L.polygon(
        L.GeoJSON.coordsToLatLngs(ellipseGEOJSON.geometry.coordinates[0]),
        {
          transform: true,
          draggable: true,
          color: "#00C5D5",
        }
      ).addTo(map);

      console.log("shape", shape);
      console.log("circleCenter", circleCenter);

      let camera = new Camera();

      let pathifyFlightPlan = {
        type: "orbit",
        name: flightPlanName !== "" ? flightPlanName : null,
        geojson,
        parameters: orbitFlightParameters,
      };
      let flightOutput = pathify(pathifyFlightPlan, camera);
      flightOutput.meta.acres = area(flightOutput.coverage);
      let start = [
        flightOutput.path.geometry.coordinates[0][1],
        flightOutput.path.geometry.coordinates[0][0],
      ];
      start = L.marker(start, { icon: startMarker }).addTo(leafletMapElement);
      let center = L.marker(circleCenter, { icon: centerMarker }).addTo(
        leafletMapElement
      );

      dispatch(
        setDrawingFlightMapObjects({
          shape: shape,
          startMarker: start,
          centerMarker: center,
        })
      );
      dispatch(setOrbitFlightParameters(pathifyFlightPlan.parameters));
      dispatch(setFlightEstimatesData(flightOutput));
      dispatch(drawOrbitCameraMarkers());

      let newFlightPlan = {
        ...pathifyFlightPlan,
        status: "ready",
        visible: false,
      };
      dispatch(setNewFlightPlan(newFlightPlan));

      shape.on("dragend", (event) => {
        let point = centroid(event.target.toGeoJSON(8));
        point = [point.geometry.coordinates[1], point.geometry.coordinates[0]];
        setTimeout(() => {
          dispatch(setOrbitCentroid(point));
          dispatch(drawOrbitShape());

          dispatch(drawOrbitCameraMarkers());
        }, 10);
      });
    }
  };
}

export function drawOrbitCameraMarkers() {
  return (dispatch, getState) => {
    const { orbitPhotoMarkers, flightEstimatesData, leafletMapElement } =
      getState();
    if (orbitPhotoMarkers.length > 0) {
      orbitPhotoMarkers.forEach((marker) => {
        marker.remove();
      });
      dispatch(setOrbitPhotoMarkers([]));
    }
    if (flightEstimatesData.waypoints.length > 0) {
      let markers = [];
      for (
        let index = 0;
        index < flightEstimatesData.waypoints.length;
        index++
      ) {
        const waypoint = flightEstimatesData.waypoints[index].coordinate;

        let marker = L.marker([waypoint.latitude, waypoint.longitude], {
          icon: markerImage,
        }).addTo(leafletMapElement);
        markers.push(marker);
      }
      dispatch(setOrbitPhotoMarkers(markers));
    }
  };
}

export function getMapAreaBoundedProjects() {
  return (dispatch, getState) => {
    const { leafletMapElement } = getState();

    let bbox = createPolygonFromBounds(leafletMapElement.getBounds());
    let boundsPolygon = L.polygon(bbox).toGeoJSON(8);

    if (boundsPolygon) {
      let bounds = JSON.stringify(boundsPolygon);
      dispatch(setMapBounds(bounds));
      dispatch(projectsBoundsFetchData(bounds));
    }
  };
}

export function setOrbitPhotoMarkers(markers) {
  return {
    type: "SET_ORBIT_PHOTO_MARKERS",
    markers,
  };
}

export function setMapLayers(mapLayers) {
  return {
    type: "SET_MAP_LAYERS",
    mapLayers,
  };
}

export function addPdfLayer(pdfLayer) {
  return (dispatch, getState) => {
    const { mapLayers } = getState();
    const { pdfs } = mapLayers;
    pdfs.push(pdfLayer);
    dispatch(setPdfLayer(pdfs));
  };
}

export function removePdfLayer(pdfName) {
  return (dispatch, getState) => {
    const { mapLayers } = getState();
    const { pdfs } = mapLayers;
    const index = pdfs.findIndex((p) => p.name === pdfName);
    pdfs.splice(index, 1);
    dispatch(setPdfLayer(pdfs));
  };
}

export function removeEveryPdfLayer() {
  return (dispatch, getState) => {
    dispatch(setPdfLayer([]));
  };
}

export function setPdfLayer(pdfs) {
  return {
    type: "SET_PDF_LAYERS",
    pdfs,
  };
}

export function setProfileCoordinate(coordinate) {
  return {
    type: "SET_PROFILE_COORDINATE",
    coordinate,
  };
}

// export function setReferenceBoundaryLayer(data) {
//   return {
//     type: 'SET_REFERENCE_BOUNDARY_LAYER',
//     data
//   }
// }

export function drawReferenceBoundaryLayer(geojson) {
  return (dispatch, getState) => {
    const { leafletMapElement } = getState();
    let reference = new L.GeoJSON(geojson, {
      color: "red",
      weight: 1,
      dashArray: "10 10",
    }).addTo(leafletMapElement);

    reference
      .bindTooltip(
        function (layer) {
          return layer.feature.properties.name || "";
        },
        {
          sticky: true,
          direction: "top",
          offset: [0, -20],
          opacity: 1,
        }
      )
      .addTo(leafletMapElement);

    // reference.bindTooltip("my tooltip", {
    //   // sticky: true // If true, the tooltip will follow the mouse instead of being fixed at the feature center.
    // }).addTo(leafletMapElement)

    dispatch(setDrawingFlightMapObjects({ reference }));
  };
}

export function clearReferenceBoundaryLayer() {
  return (dispatch, getState) => {
    dispatch(setDrawingFlightMapObjects({ reference: null }));
  };
}

export function fitMaptoReferenceBoundaryLayer() {
  return (dispatch, getState) => {
    const { drawingFlightMapObjects, leafletMapElement } = getState();
    if (drawingFlightMapObjects.reference) {
      setTimeout(() => {
        let bounds = drawingFlightMapObjects.reference.getBounds();
        leafletMapElement.fitBounds(bounds);
      }, 100);
    }
  };
}

/**
 * Invalidate the container size so map is not cut off.
 */
export function invalidateMapContainerSize() {
  return (dispatch, getState) => {
    const { leafletMapElement } = getState();
    if (Object.keys(leafletMapElement).length !== 0) {
      leafletMapElement.invalidateSize();
    }
  };
}

export function setContoursMapLayerData(contoursMapLayer) {
  return {
    type: "SET_CONTOURS_MAP_LAYER",
    contoursMapLayer,
  };
}

/**
 * Fetch map layers list from API
 */
export function fetchContoursMapLayerInfo(product_id) {
  return (dispatch, getState) => {
    const { contoursMapLayer } = getState();

    // already fetched data previously
    if (contoursMapLayer.productId === product_id && contoursMapLayer.data) {
      dispatch(setContoursMapLayerData({ loading: false }));
      return;
    }

    dispatch(
      setContoursMapLayerData({
        loading: true,
        error: false,
        data: null,
        message: "loading ...",
        productId: product_id,
      })
    );

    const { products } = getState();
    let product = products.filter((product) => product.id === product_id)[0];
    if (!product) return;

    let url = `${product.bucket.base_url}/${product.id}/contour.geojson`;
    fetch(url, {
      method: "HEAD",
    })
      .then((response) => {
        if (response?.ok) {
          const geojsonSize = response.headers.get("content-length");

          // dont't display if geojsonSize larger than 1GB
          if (geojsonSize >= 1073741824) {
            dispatch(
              setContoursMapLayerData({
                loading: false,
                error: true,
                message:
                  "We currently do not support contour files greater than 1GB",
              })
            );
            return;
          }

          dispatch(
            setContoursMapLayerData({
              loading: false,
            })
          );
          return;
        }
      })
      .catch((_) => {
        dispatch(
          setContoursMapLayerData({
            loading: false,
            error: true,
            message: "Error fetching geojson file info",
          })
        );
      });
  };
}

/**
 * Users/pilots displayed on map for admins
 */

export function setUsersSearchState(state) {
  return {
    type: "SET_USERS_SEARCH_STATE",
    state,
  };
}

export function setUsers(users) {
  return {
    type: "SET_USERS",
    users,
  };
}

/**
 * Fetch users list from API
 */
export function getUsers(query = "", page = 1) {
  return (dispatch, getState) => {
    const { user, users } = getState();
    dispatch(setUsersSearchState({ isLoading: true }));
    if (user && (user.isAdmin || user.isOrgOwnerOrAdmin)) {
      User.getAll(query, page).then((response) => {
        if (response?.data) {
          let newUsers = [...users];

          if (page === 1) {
            newUsers = response.data;
          } else {
            newUsers = newUsers.concat(response.data);
          }

          dispatch(setUsers(newUsers));
          dispatch(setUsersSearchState({ isLoading: false }));

          let hasMore = response.next_page_url !== null;
          let nextpage = response.current_page + 1;

          if (hasMore) {
            dispatch(getUsers(query, nextpage));
          }
        }
      });
    }
  };
}

// fetch specific user and update that user in users list
export function getUser(userId) {
  return (dispatch, getState) => {
    const { user, users } = getState();
    dispatch(setUsersSearchState({ isLoading: true }));
    if (user && (user.isAdmin || user.isOrgOwnerOrAdmin)) {
      User.getUser(userId).then((response) => {
        if (response?.data) {
          let newUsers = [...users];
          const userIndex = newUsers.findIndex((u) => u.id === userId);
          newUsers[userIndex] = response?.data;

          dispatch(setUsers(newUsers));
          dispatch(setUsersSearchState({ isLoading: false }));
        }
      });
    }
  };
}

export function setUserSearchOptions(options) {
  return {
    type: "SET_USER_SEARCH_OPTIONS",
    options,
  };
}

export function setSpexigonData(data) {
  return {
    type: "SET_SPEXIGON_DATA",
    data,
  };
}
