import React from 'react'
import { Marker, Polyline } from 'react-leaflet'
import hash from 'object-hash'
import L from 'leaflet'
import PropTypes from 'prop-types'

export default class PolylineAnnotation extends React.Component {
  static propTypes = {
    annotation: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
  }
  constructor(props) {
    super(props)
    this.polylineRef = React.createRef()
    this.state = {
      center: [0,0]
    }
  }
  componentDidMount() {
    this.setState({center: this.polylineRef.current.leafletElement.getBounds().getCenter()})
  }
  render() {
    const { annotation, index } = this.props
    const icon = L.divIcon({
      className: 'annotation-marker-icon',
      iconAnchor: [5, 5],
      labelAnchor: [0, 0],
      html: `
        <svg width="25" height="25">
          <circle cx="12.5" cy="12.5" r="10" fill="#${annotation.color}" />
          <text x="50%" y="50%" text-anchor="middle" fill="white" font-size="10px" dy=".3em">${index}</text>
        </svg>
      `
    })
    return (
      <Polyline
        ref={this.polylineRef}
        className="annotation"
        positions={L.GeoJSON.coordsToLatLngs(annotation.geojson.coordinates)}
        color={`#${annotation.color}`}
        key={hash(annotation.id)}
        data-id={annotation.id}
      >
        <Marker className="annotation-marker"
          position={this.state.center}
          icon={icon}
          key={hash(annotation.id)}
          data-id={annotation.id}>
        </Marker>
      </Polyline>
    )
  }
}

