import React, { useEffect, useState } from 'react'
import InfoIcon from '@material-ui/icons/Info'
import { alertTypes, SUBSCRIPTION_PLAN_TYPES } from '../../util/constants'
import { sortBy } from 'lodash'
import { connect } from 'react-redux'
import { formatDate } from '../../helpers'

const SelectSubscriptionForm = ({
  user,
  selectedPlanId,
  setSelectedPlanId,
  transitionStep,
  updateSubscription,
  subscriptionPlans,
  setAlertModal,
  onCancelUpcomingSubscription,
  onCancelSubscription,
  onClose,
}) => {
  const [paidPlans, setPaidPlans] = useState([])

  useEffect(() => {
    if (Object.values(subscriptionPlans)) {
      const paid = Object.values(subscriptionPlans).filter(
        (plan) => plan.active && plan.metadata.plan_type === SUBSCRIPTION_PLAN_TYPES.paid
      )
      setPaidPlans(paid)
    }
  }, [subscriptionPlans])

  const handleSubmitForm = (e) => {
    e.preventDefault()

    // user wants to change current subscription
    if (user.hasActiveSubscription) {
      const currentPlan = subscriptionPlans[user.activeSubscription.stripe_price]
      const selectedPlan = subscriptionPlans[selectedPlanId]
      setAlertModal({
        message: `Are you sure you want to modify your subscription? ${
          currentPlan.unit_amount < selectedPlan.unit_amount
            ? 'Your upgraded plan will begin immediately and you will be charged only for the remaining of the current period.'
            : 'Your downgraded plan will begin at the end of the current period.'
        }`,
        type: alertTypes.warning,
        cancellable: true,
        confirmLabel: 'Update subscription',
        closeLabel: 'Back',
        confirmHandler: () => {
          updateSubscription()
        },
      })
      return
    }

    // for new subscriptions go to billing form
    transitionStep(1)
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <h1 className="subscriptions-dialog__title">Subscription Package</h1>
      {user.activeSubscription?.ends_at && (
        <div className="subscriptions-dialog__content-info">
          <InfoIcon classes={{ root: 'subscriptions-dialog__content-info-icon' }} />
          {`Your current subscription will end on ${formatDate(user.activeSubscription.ends_at)}`}
        </div>
      )}
      <div>
        {paidPlans.length &&
          sortBy(paidPlans, (plan) => parseInt(plan.metadata.num_images)).map((subscription, index) => {
            return (
              <div className="subscriptions-dialog__plan-container" key={subscription.id}>
                <input
                  className="subscriptions-dialog__plan-checkbox"
                  type="radio"
                  id={'subscription-' + index + 1}
                  name="subscription"
                  value={subscription.stripe_price}
                  onClick={() => setSelectedPlanId(subscription.id)}
                  defaultChecked={selectedPlanId === subscription.id}
                  required
                />
                <label htmlFor={'subscription-' + index + 1}>
                  <h3 className="subscriptions-dialog__plan-title">{subscription.nickname} Image Package</h3>
                  <div className="subscriptions-dialog__plan-desc">
                    Upload, process and share {subscription.metadata.num_images} images per month.
                  </div>
                </label>
                <div className="subscriptions-dialog__plan-price">
                  <div className="subscriptions-dialog__plan-price-amt">{`$${subscription.unit_amount / 100}`}</div>
                  <div className="subscriptions-dialog__plan-price-currency">{subscription.currency.toUpperCase()} monthly</div>
                </div>
              </div>
            )
          })}
      </div>
      <div className="subscriptions-dialog__content-footer">
        Upgrade to higher tier subscription package at any time. Downgrading to a lower tier will start on next billing cycle.
      </div>
      <div className="subscriptions-dialog__btn-container">
        <div>
          <button type="button" className="btn-primary-white subscriptions-dialog__btn left" onClick={onClose}>
            Close
          </button>
          {user.hasActiveSubscription && !user.activeSubscription?.ends_at && (
            <button type="button" className="btn-secondary-white subscriptions-dialog__btn left" onClick={onCancelSubscription}>
              Cancel Subscription
            </button>
          )}
          {user.upcomingSubscriptionSchedule?.length > 0 && (
            <button
              type="button"
              className="btn-secondary-white subscriptions-dialog__btn left"
              onClick={onCancelUpcomingSubscription}
            >
              Cancel Upcoming Subscription
            </button>
          )}
        </div>
        <div>
          <button
            type="submit"
            className="btn-primary-solid subscriptions-dialog__btn"
            disabled={
              !selectedPlanId || (user?.hasActiveSubscription && user.activeSubscription?.stripe_price === selectedPlanId)
            }
          >
            {user?.hasActiveSubscription ? 'Modify' : 'Next'}
          </button>
        </div>
      </div>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    subscriptionPlans: state.subscriptionPlans,
  }
}

export default connect(mapStateToProps, () => ({}))(React.memo(SelectSubscriptionForm))
