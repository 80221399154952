import React, { Component } from 'react'
import { connect } from 'react-redux'

import { Auth } from './api'
import { setUserIsLoggedIn, setUserIsVerified, setUser, setUserTimezone, loginToPlayer } from './actions/auth'
import { setUserGeoLocation, setProjectsMapCenter } from './actions/map'

import DashboardContainer from './containers/DashboardContainer'
import LoginContainer from './containers/LoginContainer'
import './App.css'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import 'moment-timezone'
moment.tz.setDefault('UTC')

class App extends Component {
  constructor(props) {
    super(props)

    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({ dsn: 'https://1e034ce97cf54d568d95da0a18d90fa7@sentry.io/1288570' })
    }

    const token = localStorage.getItem('jwt-token')
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (userTimezone) this.props.setUserTimezone(userTimezone)

    if (token) {
      this.props.setUserIsLoggedIn(true)

      Auth.getUser()
        .then(response => {
          if(response.success) {
            // check if the user is verified
            if(response.data.is_verified){
              this.props.setUserIsVerified(true)
              if (response.data && response.data.profile && response.data.profile.geolocation) {
                let coordinates = [response.data.profile.geolocation.coordinates[1], response.data.profile.geolocation.coordinates[0]]
                this.props.setUserGeoLocation(coordinates)

                if (!this.props.openSingleProject && !this.props.isEdittingProject) {
                  this.props.setProjectsMapCenter(coordinates)
                }
              }
            } else {
              this.props.setUserIsVerified(false)
            }
            this.props.setUser(response.data)
          }

        })
        .catch(console.error)
    }
  }


  componentDidMount() {
    const token = localStorage.getItem('jwt-token')
    if(token) {
      this.props.loginToPlayer(token)
    }

  }


  render() {
    if(this.props.userIsLoggedIn) {
      return <>
        <DashboardContainer {...this.props}/>
      </>
    }

    return <LoginContainer />
  }
}


const mapStateToProps = (state) => {
  return {
    userIsLoggedIn: state.userIsLoggedIn,
    isEdittingProject: state.isEdittingProject,
    openSingleProject: state.openSingleProject,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUserIsLoggedIn: bool => dispatch(setUserIsLoggedIn(bool)),
    setUserIsVerified: bool => dispatch(setUserIsVerified(bool)),
    setUser: user => dispatch(setUser(user)),
    setUserTimezone: timezone => dispatch(setUserTimezone(timezone)),
    loginToPlayer: token => dispatch(loginToPlayer(token)),
    setUserGeoLocation: coordinates => dispatch(setUserGeoLocation(coordinates)),
    setProjectsMapCenter: coordinates => dispatch(setProjectsMapCenter(coordinates))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
