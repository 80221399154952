import React from 'react'
import './pageheading.css'
import { Link } from 'react-router-dom'
const PageHeading = ({
  title,
  description,
  bgImage,
  backgroundPosition,
  callToActionLink,
  callToActionText,
  smallHeader,
  newWindow,
}) => {
  const styles = {
    backgroundImage: `url(${bgImage})`,
  }

  if (backgroundPosition) {
    styles.backgroundPosition = backgroundPosition
  }

  return (
    <header
      className={'panel page-heading' + (smallHeader ? ' small-header' : '')}
      style={styles}
    >
      <div
        className="container"
        style={{ position: 'absolute', top: '100px', textAlign: 'left' }}
      >
        <a href="/" className="site-name">
          <img
            src={process.env.PUBLIC_URL + '/assets/img/spexigeo-logo-white.png'}
            alt="Spexi Geospatial"
            width="200"
            height="auto"
          />
          <p
            style={{
              marginTop: '40px',
              fontSize: '1rem',
              borderBottom: '1px solid #ccc',
              display: 'flex',
              width: 'fit-content',
            }}
          >
            <span>Back to Home</span>
          </p>
        </a>
      </div>
      <div className="container">
        <div className="narrow-wrap">
          <h2>{title}</h2>
          {description && <p>{description}</p>}
          {callToActionLink && callToActionText && !newWindow && (
            <p>
              <Link className="btn white" to={callToActionLink}>
                {callToActionText}
              </Link>
            </p>
          )}

          {callToActionLink && callToActionText && newWindow && (
            <p>
              <a
                className="btn white"
                href={callToActionLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {callToActionText}
              </a>
            </p>
          )}
        </div>
      </div>
    </header>
  )
}

export default PageHeading
