export function isUploadingImages(state = false, action) {
  switch (action.type) {
    case 'SET_IS_UPLOADING_IMAGES':
      return action.boolean
    default:
      return state
  }
}

const initialFormState = {
  active: false,
  editing: false,
  id: '',
  project_id: '',
  type: '',
  name: '',
  capture_date: new Date().toJSON().slice(0, 10),
  gcps: []

}
export function productForm(state = initialFormState, action) {
  switch (action.type) {
    case 'SET_PRODUCT_FORM':
      if (action.data === null) {
        return { ...initialFormState }
      } else {
        return { 
          ...state, 
          ...action.data,
          // Let the db know that you are creating a 3d product just with the point cloud file
          ...(action.data.type === '3d' && {parameters: {...action.data.parameters, model: false}})
        }
      }
    default:
      return state
  }
}

export function products(state = [], action) {
  switch (action.type) {
    case 'SET_PRODUCTS_DATA_SUCCESS':
      // Excluding 'export' type because this is a bundle download product derived from other products
      return action.products.filter(product => product.type !== 'export')
    case 'SET_PRODUCT_JOBS':
      // Check if any of our existing products match the product Id list for the incoming payload.
      // If there are no matches, just return existing state, otherwise we need to copy the
      // entire block, edit shit, then return that.
      if (!state || !action.productJobs) {
        return state
      }
      var productIds = new Set(state.map(x => x.id))
      var incomingProductIds = new Set(Object.keys(action.productJobs))
      var intersection = new Set(Array.from(productIds).filter(x => incomingProductIds.has(x)))
      if (intersection.size === 0) {
        return state
      }
      var products = state.map((product) => {
        if (Object.keys(action.productJobs).findIndex(x => x === product.id) !== -1) {
          product.job = action.productJobs[product.id]
        }
        return product
      })
      return products
    default:
      return state
  }
}

export function product(state = {}, action) {
  switch (action.type) {
    case 'SET_PRODUCT_DATA_SUCCESS':
      return action.product
    case 'SET_PRODUCT_JOBS':
      // Check if our product_id is in the keys of the incoming object.
      // If it is, update the product's job.
      if (!state || !action.productJobs) {
        return state
      }
      if (state.id in Object.keys(action.productJobs)) {
        // Update product job.
        let newState = { ...state }
        newState.job = action.productJobs[state.id]
        return newState
      }
      return state
    default:
      return state
  }
}

export function productJob(state = null, action) {
  switch (action.type) {
    case 'SET_PRODUCT_JOB':
      return action.productJob
    default:
      return state
  }
}



export function gcps(state = [], action) {
  switch (action.type) {
    case 'SET_PRODUCT_GCPS_DATA_SUCCESS':
      return action.gcps
    default:
      return state
  }
}

export function isEditingProductParameters(state = false, action) {
  switch (action.type) {
    case 'SET_IS_EDITING_PRODUCT_PARAMETERS':
      return action.isEditingProductParameters
    default:
      return state
  }
}


export function productMapPreview(state = { center: [49.281592374278, -123.0204496822], zoom: 18 }, action) {
  switch (action.type) {
    case 'SET_PRODUCT_MAP_PREVIEW':
      return action.productMapPreview
    default:
      return state
  }
}