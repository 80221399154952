import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import AddAnnotation from '../components/AddAnnotation'
import ProjectList from '../components/ProjectList/'
import ProjectContainer from './ProjectContainer'
import AddProject from '../components/AddProject'
import CollapsibleMenu from '../components/CollapsibleMenu'
import AddFlight from '../components/AddFlight'
import ExportData from '../components/ExportData/'
import Product from '../components/Product/'
import PrintAnnotationReport from '../components/PrintAnnotationReport/'

class NavigationContainer extends Component {
  static propTypes = {
    userIsVerified: PropTypes.bool.isRequired,
    setUserIsVerified: PropTypes.func.isRequired,
    isEdittingProject: PropTypes.bool,
    flightPlan: PropTypes.object,
    isAddingFlightPlan: PropTypes.bool,
    isViewingFlightPlan: PropTypes.bool,
    isEdittingFlightPlan: PropTypes.bool,
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.setOpenSingleProject(true)
      this.props.fetchProject(this.props.match.params.id)

      // Redirect if someone happens to hit the URL without '/plan' at the end
      // which was our old URL structure for projects before we introduced tabs
      if (this.props.match.path === '/project/:id') {
        // redirect people to first tab
        this.props.history.push(`/project/${this.props.match.params.id}/plan`)
      }

      // Fetch a users teams globally and early in initialization
      // so its easily available in other parts of the app
      this.props.getUserTeams()
    }

    // Fetch subscription plan types
    this.props.getSubscriptionPlans()
  }

  componentDidUpdate(prevProps) {
    // Default project filter is NEARBY
    // we need to make sure the map exists before we can call it
    if (
      Object.keys(prevProps.leafletMapElement).length === 0 &&
      Object.keys(this.props.leafletMapElement).length !== 0
    ) {
      console.log('LOAD DEFAULT MAP BOUNDED PROJECT FILTER')
      this.props.fetchNextProjectPage()
    }

    // Clear product and product job data when transition routes out of the project container
    // Unfortunately, this didn't work in componentWillUnmount in ProjectContainer because of
    // the way React Router re-renders routes that are passed through several components
    if (
      prevProps.match.params.id &&
      Object.keys(prevProps.match.params.id).length !== 0 &&
      !this.props.match.params.id
    ) {
      this.props.setProductsDataSuccess([])
      this.props.setProductJobs([])
    }

    // get Users data for admins to display users on map as markers
    if (this.props.user && !prevProps.user) {
      this.props.getUsers()
    }
  }

  render() {
    return (
      <aside id="dashboard-navigation" className="dashboard-navigation">
        <CollapsibleMenu {...this.props} />

        {this.props.match.path !== '/admin' && (
          <section id="sidebar-content" className="sidebar-content">
            <Route
              exact
              path="/"
              render={() => <ProjectList {...this.props} />}
            />
            <Route
              exact
              path="/add-project"
              render={() => <AddProject {...this.props} />}
            />
            <Route
              exact
              path="/project/:id"
              render={() => <ProjectContainer {...this.props} />}
            />
            <Route
              exact
              path="/project/:id/plan"
              render={() => <ProjectContainer {...this.props} />}
            />
            <Route
              exact
              path="/project/:id/process"
              render={() => <ProjectContainer {...this.props} />}
            />
            <Route
              exact
              path="/project/:id/review"
              render={() => <ProjectContainer {...this.props} />}
            />
            <Route
              exact
              path="/project/:id/review/:pid"
              render={() => <Product {...this.props} />}
            />
            <Route
              exact
              path="/project/:id/review/:pid/export"
              render={() => <ExportData {...this.props} />}
            />
            <Route
              exact
              path="/project/:id/edit"
              render={() => <AddProject {...this.props} />}
            />
            <Route
              exact
              path="/project/:id/review/:pid/add-annotation"
              render={() => <AddAnnotation {...this.props} />}
            />
            <Route
              exact
              path="/project/:id/review/:pid/:aid/edit"
              render={() => <AddAnnotation {...this.props} />}
            />

            <CSSTransition
              in={
                Object.keys(this.props.flightPlan).length !== 0 &&
                this.props.isEdittingFlightPlan &&
                !this.props.isAddingFlightPlan
              }
              timeout={300}
              classNames="addFlight"
              unmountOnExit
            >
              <AddFlight {...this.props} />
            </CSSTransition>

            <CSSTransition
              in={
                Object.keys(this.props.annotations).length !== 0 &&
                this.props.isPrintingAnnotationReport
              }
              timeout={300}
              classNames="addFlight"
              unmountOnExit
            >
              <PrintAnnotationReport {...this.props} />
            </CSSTransition>
          </section>
        )}
      </aside>
    )
  }
}

export default NavigationContainer
