import L from 'leaflet'
import geojsonvt from 'geojson-vt'

class Contours extends L.GridLayer {
  constructor(data, vectorTileOpts, gridLayerOpts) {
    super(gridLayerOpts)
    this.tileIndex = geojsonvt(data, vectorTileOpts)
  }

  createTile = (coords) => {
    // canvas setup
    const canvas = L.DomUtil.create('canvas')

    const size = this.getTileSize()
    canvas.width = size.x
    canvas.height = size.y

    // get the data for this particular tile and zoom level
    const tile = this.tileIndex.getTile(coords.z, coords.x, coords.y)
    if (!tile) {
      // no data so return empty canvas
      return canvas
    }

    // following code adapted from https://github.com/mapbox/geojson-vt
    // const padding = 8 / 512 // NOTE: this padding value caused the lines to be slightly off as they crossed tile boundaries
    const padding = 0
    const totalExtent = 4096 * (1 + padding * 2)
    const ratio = canvas.height / totalExtent
    const pad = 4096 * padding * ratio

    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const features = tile.features

    ctx.strokeStyle = 'white'
    ctx.fillStyle = 'rgba(255,255,255,0.05)'

    for (let i = 0; i < features.length; i++) {
        const feature = features[i]
        const type = feature.type

        ctx.beginPath()

        for (let j = 0; j < feature.geometry.length; j++) {
            const geom = feature.geometry[j]

            if (type === 1) {
                ctx.arc(geom[0] * ratio + pad, geom[1] * ratio + pad, 2, 0, 2 * Math.PI, false)
                continue
            }

            for (let k = 0; k < geom.length; k++) {
                const p = geom[k]
                if (k) ctx.lineTo(p[0] * ratio + pad, p[1] * ratio + pad)
                else ctx.moveTo(p[0] * ratio + pad, p[1] * ratio + pad)
            }
        }

        if (type === 3 || type === 1) ctx.fill('evenodd')
        ctx.stroke()
    }

    return canvas
  }
}

export default Contours