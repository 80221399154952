import React, { Component } from 'react'
import { Menu, MenuItem, MenuButton, MenuDivider } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import './styles.css'

class HelpButton extends Component {
  render() {
    const { onActivateTour } = this.props
    return (
      <Menu
        direction="top"
        menuClassName="help-menu"
        menuButton={<MenuButton className="help-button btn">Help</MenuButton>}
        transition
      >
        <MenuItem onClick={onActivateTour}>Overview Guide</MenuItem>
        <MenuItem href="https://fly.spexigeo.com/faqs" target="_blank">
          FAQs
        </MenuItem>
        <MenuItem href="mailto:support@spexigeo.com" target="_blank">
          Contact support
        </MenuItem>
        <MenuDivider />
        <MenuItem disabled className="disabled-title">
          Mobile Capture App
        </MenuItem>
        <MenuItem
          href="https://play.google.com/store/apps/details?id=com.spexigeo.app&hl=en_CA&gl=US"
          target="_blank"
        >
          <img
            src="/assets/img/GooglePlay_Store_icon.png"
            alt=""
            role="presentation"
            width="160px"
          />
        </MenuItem>
        <MenuItem
          href="https://apps.apple.com/ca/app/spexi/id1455273103"
          target="_blank"
        >
          <img
            src="/assets/img/app-store-icon.png"
            alt=""
            role="presentation"
            width="160px"
          />
        </MenuItem>
        <MenuItem href="https://github.com/szhsin/react-menu/">
          <img src="octocat.png" alt="" role="presentation" />
        </MenuItem>
      </Menu>
    )
  }
}

export default HelpButton
