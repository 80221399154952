import React, { useCallback, useEffect, useState } from 'react'
import { CircularProgress, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { Auth, Subscriptions } from '../../api'
import { Autocomplete } from '@material-ui/lab'
import { alertTypes, SUBSCRIPTION_PLAN_TYPES } from '../../util/constants'
import { getUser } from '../../actions/map'
import { connect } from 'react-redux'
import { sortBy } from 'lodash'
import ConfirmationDialog from './ConfirmationDialog'
import './styles.css'

const AdminSubscriptionsDialog = ({
  currentUser,
  subscriptionPlans,
  setAlertModal,
  updateUser,
  getSubscriptionUsers,
  open,
  onClose,
}) => {
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const [openCancelDialog, setOpenCancelDialog] = useState(false)
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false)
  const [users, setUsers] = useState([])

  const [contractPlans, setContractPlans] = useState([])

  const currentUserActiveSubscription = currentUser?.stripe_status === 'active' ? currentUser : null

  const currentUserActiveSubscriptionPlanType =
    (currentUserActiveSubscription && subscriptionPlans[currentUserActiveSubscription.stripe_price]?.metadata.plan_type) ||
    'legacy'

  const getAllUsers = useCallback(async () => {
    const response = await Auth.getAllUsers()

    if (response?.success && response.data) {
      setUsers(response.data)
    } else {
      setAlertModal({
        message: response?.error || 'an error has occured',
        type: alertTypes.error,
      })
    }
  }, [setAlertModal])

  useEffect(() => {
    if (Object.values(subscriptionPlans)) {
      const contract = Object.values(subscriptionPlans).filter(
        (plan) => plan.active && plan.metadata.plan_type === SUBSCRIPTION_PLAN_TYPES.contract
      )
      setContractPlans(contract)
    }
  }, [subscriptionPlans])

  useEffect(() => {
    if (currentUser) {
      if (currentUserActiveSubscription) setSelectedPlanId(currentUserActiveSubscription.stripe_price)
      setSelectedUser(currentUser)
    } else {
      setSelectedUser(null)
      setSelectedPlanId(null)

      if (users.length === 0) {
        getAllUsers()
      }
    }
  }, [currentUser, currentUserActiveSubscription, users.length, getAllUsers])

  const handleSubmitForm = async (e) => {
    e.preventDefault()
    if (!selectedUser) {
      return
    }

    if (currentUserActiveSubscription) {
      setOpenUpdateDialog(true)
    } else {
      createSubscription()
    }
  }

  const handleCloseCancelDialog = () => setOpenCancelDialog(false)
  const handleCloseUpdateDialog = () => setOpenUpdateDialog(false)

  const handleCancelPaidSubscription = () => {
    // for paid plans, only allow admin to cancel the plan for the end of current period

    // susbcription already cancelled
    if (currentUserActiveSubscription.ends_at) {
      setAlertModal({
        message: 'The user\'s paid subscription is already cancelled for the end of the current period',
        type: alertTypes.error,
        confirmLabel: 'Close',
      })
      return
    }

    setAlertModal({
      message:
        'The user has a paid plan subscription. Are you sure you want to cancel? Cancelling it would end the user\'s subscription at the end of the current period.',
      type: alertTypes.warning,
      confirmHandler: () => {
        deleteSubscription(false)
      },
      confirmLabel: 'Cancel subscription',
      cancellable: true,
      closeLabel: 'Back',
    })
  }

  const createSubscription = async () => {
    const response = await Subscriptions.createUserSubscription(selectedUser.id, selectedPlanId)

    if (response?.success && response.data) {
      setAlertModal({
        message: 'Successfully created new subscription',
        type: alertTypes.success,
        confirmHandler: () => {
          updateUser(selectedUser.id)
          getSubscriptionUsers()
          onClose()
        },
      })
    } else {
      setAlertModal({
        message: response?.error || 'an error has occured',
        type: alertTypes.error,
      })
    }
  }

  const updateSubscription = async (cancelNow) => {
    const response = await Subscriptions.updateUserSubscription(
      selectedUser.id,
      selectedPlanId,
      `&date=${cancelNow ? 'now' : ''}`
    )

    if (response?.success && response.data) {
      setAlertModal({
        message: 'Successfully updated the subscription',
        type: alertTypes.success,
        confirmHandler: () => {
          updateUser(selectedUser.id)
          getSubscriptionUsers()
          onClose()
          handleCloseUpdateDialog()
        },
      })
    } else {
      setAlertModal({
        message: response?.error || 'an error has occured',
        type: alertTypes.error,
      })
    }
  }

  const deleteSubscription = async (cancelNow) => {
    const response = await Subscriptions.deleteUserSubscription(
      selectedUser.id,
      selectedPlanId,
      `&date=${cancelNow ? 'now' : ''}`
    )

    if (response?.success) {
      setAlertModal({
        message: 'Successfully cancelled the subscription',
        type: alertTypes.success,
        confirmHandler: () => {
          updateUser(selectedUser.id)
          getSubscriptionUsers()
          onClose()
          handleCloseCancelDialog()
        },
      })
    } else {
      setAlertModal({
        message: response?.error || 'an error has occured',
        type: alertTypes.error,
      })
    }
  }

  return (
    <>
      <Dialog
        classes={{ root: 'admin-subscriptions-dialog-container', paper: 'admin-subscriptions-dialog' }}
        open={open}
        onClose={onClose}
        maxWidth={false}
        transitionDuration={0}
      >
        <CloseIcon onClick={onClose} className="btn-close-menu" />
        <h1 className="admin-subscriptions-dialog__title">
          {currentUser ? `Modify Contract for ${currentUser.name}` : 'Select Contract Subscription Package'}
        </h1>
        <form onSubmit={handleSubmitForm}>
          <DialogContent>
            <>
              {open && !currentUser && (
                <>
                  <h1 className="admin-subscriptions-dialog__content-title">Select User</h1>
                  {users.length === 0 && (
                    <div className="admin-subscriptions__loading-container">
                      <CircularProgress className="admin-subscriptions__circular-progress" color="primary" size={30} />
                      Loading users ...
                    </div>
                  )}
                  <Autocomplete
                    className="admin-subscriptions-dialog__content-input"
                    selectOnFocus
                    value={selectedUser}
                    onChange={(_, newValue) => {
                      setSelectedUser(newValue)
                    }}
                    options={users}
                    getOptionLabel={(option) => `${option?.name} (${option?.email})` || ''}
                    renderInput={(params) => <TextField {...params} label="Select user" variant="outlined" required />}
                  />
                </>
              )}

              {currentUserActiveSubscriptionPlanType === SUBSCRIPTION_PLAN_TYPES.paid ? (
                <div className="admin-subscriptions-dialog__content-warning">
                  <i className="fa fa-warning" />
                  User has an active paid subscription plan. Currently we don't support modifying a user's paid plan
                </div>
              ) : (
                <>
                  <h1 className="admin-subscriptions-dialog__content-title">Select Contract subscription package</h1>

                  <div>
                    {contractPlans.length &&
                      sortBy(contractPlans, (plan) => parseInt(plan.metadata.num_images)).map((subscription, index) => {
                        return (
                          <div className="admin-subscriptions-dialog__plan-container" key={subscription.id}>
                            <input
                              className="admin-subscriptions-dialog__plan-checkbox"
                              type="radio"
                              id={'subscription-' + index + 1}
                              name="subscription"
                              value={subscription.stripe_price}
                              onClick={() => setSelectedPlanId(subscription.id)}
                              defaultChecked={selectedPlanId === subscription.id}
                              required
                            />
                            <label htmlFor={'subscription-' + index + 1}>
                              <h3 className="admin-subscriptions-dialog__plan-title">{subscription.nickname} Image Package</h3>
                              <div className="admin-subscriptions-dialog__plan-desc">
                                Upload, process and share {subscription.metadata.num_images} images per month.
                              </div>
                            </label>
                          </div>
                        )
                      })}
                  </div>
                </>
              )}
            </>
          </DialogContent>
          <DialogActions>
            <button type="button" className="btn-primary-white admin-subscriptions-dialog__btn" onClick={onClose}>
              Close
            </button>
            <div>
              {currentUserActiveSubscriptionPlanType === SUBSCRIPTION_PLAN_TYPES.paid ? (
                <>
                  <button
                    type="button"
                    className="btn-secondary-white admin-subscriptions-dialog__btn"
                    onClick={handleCancelPaidSubscription}
                  >
                    Cancel Subscription
                  </button>
                </>
              ) : (
                <>
                  {currentUserActiveSubscription && (
                    <button
                      type="button"
                      className="btn-secondary-white admin-subscriptions-dialog__btn"
                      onClick={() => setOpenCancelDialog(true)}
                    >
                      Cancel Subscription
                    </button>
                  )}
                  <button
                    type="submit"
                    className="btn-primary-solid admin-subscriptions-dialog__btn right"
                    disabled={!selectedUser || !selectedPlanId || currentUserActiveSubscription?.stripe_price === selectedPlanId}
                  >
                    Confirm
                  </button>
                </>
              )}
            </div>
          </DialogActions>
        </form>
      </Dialog>

      <ConfirmationDialog
        type="cancel"
        open={openCancelDialog}
        onClose={handleCloseCancelDialog}
        onConfirm={deleteSubscription}
      />

      <ConfirmationDialog
        type="update"
        open={openUpdateDialog}
        onClose={handleCloseUpdateDialog}
        onConfirm={updateSubscription}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (userId) => dispatch(getUser(userId)),
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptionPlans: state.subscriptionPlans,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AdminSubscriptionsDialog))
