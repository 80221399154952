import uuidv1 from 'uuid/v1'
import { fetchProject, setProjectForm, fetchEditProjectData } from './projects'
import { FlightPlan, Drones } from '../api'
import { setRequestInProgress } from './request'
import { drawMappingFlightLines, setStartingMarker, setOrbitCentroid, setPreviewMarkers } from './map'
import { setAlertModal } from './ui';
import { alertTypes } from '../util/constants'

// fetch all flights associated with a project
export function setFlightPlansFetchDataSuccess(flightPlans) {
  return {
    type: 'SET_FLIGHT_PLANS_FETCH_DATA_SUCCESS',
    flightPlans
  }
}

// view single flight
export function setFlightPlanDataSuccess(flightPlan) {
  return {
    type: 'SET_FLIGHT_PLAN_DATA_SUCCESS',
    flightPlan
  }
}

export function openSingleFlightPlanView(idOrindex) {
  return (dispatch, getState) => {
    // console.log('openSingleFlightView')
    const { flightPlans } = getState()
    // dispatch(setIsViewingFlightPlan(true))
    // console.log(flights)
    // console.log(idOrindex)

    let flightPlan = flightPlans.filter((flightPlan, index) => (flightPlan.id !== undefined && flightPlan.id === idOrindex) || (flightPlan.id === undefined && index !== idOrindex))
    flightPlan = JSON.parse(JSON.stringify(flightPlan))
    dispatch(setFlightPlanDataSuccess(flightPlan[0]))
    dispatch(editFlightPlan(flightPlan[0]))
  }
}


export function setDrawingFlightMapObjects(data) {
  return {
    type: 'SET_DRAW_FLIGHT_MAP_OBJECTS',
    data
  }
}

export function setEditFlightLine(data) {
  return {
    type: 'SET_EDIT_FLIGHTLINE',
    data
  }
}

export function setFlightParameters(data) {
  return {
    type: 'SET_FLIGHT_PARAMETERS',
    data
  }
}


export function setPanoFlightParameters(data) {
  return {
    type: 'SET_PANO_FLIGHT_PARAMETERS',
    data
  }
}

export function setOrbitFlightParameters(data) {
  return {
    type: 'SET_ORBIT_FLIGHT_PARAMETERS',
    data
  }
}


export function toggleFlightPlanVisiblity(idOrindex) {
  return {
    type: 'TOGGLE_FLIGHT_PLAN_VISIBILITY',
    idOrindex
  }
}


export function setFlightPlanType(flightPlanType) {
  return {
    type: 'SET_FLIGHT_PLAN_TYPE',
    flightPlanType
  }
}

export function setFlightPlanName(flightPlanName) {
  return {
    type: 'SET_FLIGHT_PLAN_NAME',
    flightPlanName
  }
}

export function setNewFlightPlan(newFlightPlan) {
  return {
    type: 'SET_NEW_FLIGHT_PLAN',
    newFlightPlan
  }
}

export function resetFlightMapObjects() {
  return (dispatch, getState) => {
    const { drawingFlightMapObjects, flightParameters } = getState()
    if (drawingFlightMapObjects.shape && drawingFlightMapObjects.line && drawingFlightMapObjects.startMarker && drawingFlightMapObjects.endMarker) {
      drawingFlightMapObjects.line.remove()
      drawingFlightMapObjects.startMarker.remove()
      drawingFlightMapObjects.endMarker.remove()
      dispatch(setDrawingFlightMapObjects({ line: null, startMarker: null, endMarker: null, centerMarker: null, takeOffLocationMarker: null }))
      dispatch(drawMappingFlightLines(drawingFlightMapObjects.shape.toGeoJSON(8), flightParameters))
    }
  }
}

/**
 * Update flight plan
 */
export function handleFlightSubmit() {
  return (dispatch, getState) => {
    const { project, isAddingProject, isEdittingProject, flightPlan, droneCamera, isAddingFlightPlan, isEdittingFlightPlan, projectForm, newFlightPlan } = getState()

    let newFormData
    if (flightPlan) {
      if (isAddingFlightPlan) {
        newFormData = JSON.parse(JSON.stringify(projectForm))
        let newFlightPlanData = JSON.parse(JSON.stringify(newFlightPlan))
        newFlightPlanData.uuid = uuidv1()
        newFormData.flightPlans.push(newFlightPlanData)
        dispatch(clearNewDrawing())
      }

      if (isEdittingFlightPlan) {
        newFormData = JSON.parse(JSON.stringify(projectForm))
        newFormData.flightPlans = newFormData.flightPlans.map((formFlightPlan) => {
          if ((flightPlan.id !== undefined && formFlightPlan.id === flightPlan.id) || (flightPlan.uuid !== undefined && formFlightPlan.uuid === flightPlan.uuid)) {
            newFlightPlan.camera = droneCamera.id
            newFlightPlan.drone = droneCamera.drone
            return { ...flightPlan, ...newFlightPlan }
          } else {
            return formFlightPlan
          }
        })
      }
      // console.log('new form data', {flightPlans: newFormData.flightPlans})
      dispatch(setProjectForm(newFormData))

      if (isEdittingFlightPlan && (!isEdittingProject && !isAddingProject && !isAddingFlightPlan)) {
        let newFlightPlanData = { ...flightPlan, ...newFlightPlan }
        dispatch(updateFlightPlan(newFlightPlanData, newFlightPlanData))
      }
      if (!isEdittingFlightPlan && !isEdittingProject && !isAddingProject && isAddingFlightPlan) {
        newFlightPlan.project_id = project.id
        dispatch(addFlightPlan(newFlightPlan))
        dispatch(setProjectForm(null))
      }

      dispatch(setIsAddingFlightPlan(false))
      dispatch(setIsEdittingFlightPlan(false))
      dispatch(clearNewDrawing())
      dispatch(setFlightParameters(null))
      dispatch(setPanoFlightParameters(null))
      dispatch(setOrbitFlightParameters(null))
      dispatch(setOrbitCentroid([]))
      // reset current flight plan
      dispatch(setFlightPlanDataSuccess({}))
      dispatch(setFlightPlanName(''))
    }
  }
}

/**
 *  Add flight
 */
export function addFlightPlan(flightPlan) {
  return (dispatch) => {
    dispatch(setRequestInProgress(true, 'SET_AWAITING_UPLOAD'))
    FlightPlan.createFlightPlan(flightPlan)
      .then(() => {
        dispatch(fetchProject(flightPlan.project_id))

        FlightPlan.getFlightPlans(`?project_id=${flightPlan.project_id}`)
          .then(response => {
            dispatch(setFlightPlansFetchDataSuccess(response.data))
          })
        dispatch(setRequestInProgress(false, 'SET_AWAITING_UPLOAD'))
      })
      .catch((error) => {
        dispatch(setAlertModal({
          message: error.error,
          type: alertTypes.warning,
          confirmHandler: () => {
            dispatch(setRequestInProgress(false, 'SET_AWAITING_UPLOAD'))
          }
        }))
      })
  }
}


/**
 * Update flight
 */
export function updateFlightPlan(flightPlan, data, isOpenFlightPlanView = true) {
  return (dispatch) => {
    dispatch(setRequestInProgress(true, 'SET_AWAITING_UPLOAD'))
    FlightPlan.updateFlightPlan(flightPlan.id, data)
      .then(() => {
        dispatch(fetchProject(flightPlan.project_id))

        FlightPlan.getFlightPlans(`?project_id=${flightPlan.project_id}`)
          .then(response => {
            dispatch(setFlightPlansFetchDataSuccess(response.data))
          })
        dispatch(setRequestInProgress(false, 'SET_AWAITING_UPLOAD'))
      })
      .catch((error) => {
        dispatch(setAlertModal({
          message: error.error,
          type: alertTypes.warning,
          confirmHandler: () => {
            dispatch(setRequestInProgress(false, 'SET_AWAITING_UPLOAD'))
          }
        }))
      })
  }
}



/**
 * Clear Map of Shape Drawing and Markers
 */
export function clearNewDrawing() {
  return (dispatch, getState) => {
    const { drawingFlightMapObjects, orbitPhotoMarkers } = getState()
    dispatch(setPreviewMarkers(null))
    dispatch(setFlightPlanType(''))
    dispatch(setFlightParameters(null))
    dispatch(setPanoFlightParameters(null))
    dispatch(setOrbitFlightParameters(null))
    dispatch(setOrbitCentroid([]))

    if (drawingFlightMapObjects) {
      dispatch(setDrawingFlightMapObjects({
        shape: null,
        line: null,
        startMarker: null,
        endMarker: null,
        centerMarker: null,
        takeOffLocationMarker: null
      }))
    }

    if (orbitPhotoMarkers) {
      orbitPhotoMarkers.forEach(marker => {
        marker.remove()
      })
    }

  }
}


export function setIsAddingFlightPlan(boolean) {
  return {
    type: 'SET_IS_ADDING_FLIGHT_PLAN',
    boolean
  }
}


export function setIsEdittingFlightPlan(boolean) {
  return {
    type: 'SET_IS_EDITTING_FLIGHT_PLAN',
    boolean
  }
}

export function setFlightEstimatesData(flightEstimatesData) {
  return {
    type: 'SET_FLIGHT_ESTIMATES',
    flightEstimatesData
  }
}


/**
 * Edit flight plan
 */
export function editFlightPlan(flightPlan) {
  return (dispatch) => {
    // console.log('TRYING TO EDIT FLIGHT', flightPlan)
    dispatch(setIsEdittingFlightPlan(true))
    dispatch(setFlightPlanDataSuccess(flightPlan))

    if (flightPlan.type === 'map') {
      dispatch(setFlightParameters(flightPlan.parameters))
    }

    if (flightPlan.type === 'panorama') {
      dispatch(setPanoFlightParameters(flightPlan.parameters))
    }

    if (flightPlan.type === 'orbit') {
      dispatch(setOrbitFlightParameters(flightPlan.parameters))
    }

    dispatch(setFlightPlanType(flightPlan.type))
  }
}


/**
 * Delete flight from flight list and server
 */
export function deleteFlightPlan(flightPlan) {
  return (dispatch, getState) => {
    dispatch(setAlertModal({
      message: 'Are you sure that you want to delete this flight?',
      cancellable: true,
      type: alertTypes.warning,
      confirmHandler: () => {
        const { isAddingProject, isEdittingProject, isAddingFlightPlan } = getState()

        dispatch(setRequestInProgress(true, 'GLOBAL'))
        if (flightPlan.id) {
          FlightPlan.deleteFlightPlan(flightPlan.id)
            .then((resp) => {
              if (resp.success) {
                if (isEdittingProject || isAddingProject) {
                  dispatch(fetchEditProjectData(flightPlan.project_id))
                } else {
                  dispatch(fetchProject(flightPlan.project_id))
                }
                
                FlightPlan.getFlightPlans(`?project_id=${flightPlan.project_id}`)
                  .then(response => {
                    dispatch(setFlightPlansFetchDataSuccess(response.data))
                  })
  
                dispatch(setRequestInProgress(false, 'GLOBAL'))
                
                if (!isAddingFlightPlan) {
                  dispatch(setIsAddingFlightPlan(false))
                  dispatch(setIsEdittingFlightPlan(false))
                }
                dispatch(clearNewDrawing())
  
                document.body.classList.remove('mapping-flight-draw')
              }
              if (!resp.success) {
                dispatch(setAlertModal({
                  message: resp.error,
                  type: alertTypes.warning,
                  confirmHandler: () => {
                    if (!isAddingFlightPlan) {
                      dispatch(setIsAddingFlightPlan(false))
                      dispatch(setIsEdittingFlightPlan(false))
                    }
                    dispatch(clearNewDrawing())
                    document.body.classList.remove('mapping-flight-draw')
                    dispatch(setRequestInProgress(false, 'GLOBAL'))
                  }
                }))
              }
            })
        }
      }
    }))
  }
}


export function backFromFlightType() {
  return (dispatch, getState) => {
    const { isAddingFlightPlan } = getState()
    if (!isAddingFlightPlan) {
      dispatch(setIsAddingFlightPlan(false))
      dispatch(setIsEdittingFlightPlan(false))
    }
    dispatch(clearNewDrawing())

    // needed to control CSS in leaflet vertices, so adding a body class here to hook into
    // and this line removes it
    document.body.classList.remove('mapping-flight-draw')

  }
}


export function clearFlightPlanCreation() {
  return (dispatch, getState) => {
    dispatch(setFlightPlansFetchDataSuccess(null))
    dispatch(setDrawingFlightMapObjects({ shape: null, line: null }))
    dispatch(setEditFlightLine(null))
    dispatch(setPreviewMarkers(null))

    dispatch(setFlightPlanDataSuccess({}))
    dispatch(setFlightPlanName(''))
    const { startingMarker } = getState()
    if (startingMarker && startingMarker.hasOwnProperty('editing')) {
      startingMarker.remove()
      dispatch(setStartingMarker(null))
    }

    // needed to control CSS in leaflet vertices, so adding a body class here to hook into
    // and this line removes it
    document.body.classList.remove('mapping-flight-draw')
  }
}


export function getDroneList() {
  return (dispatch) => {
    Drones.getDrones()
      .then(response => {
        dispatch(setDroneList(response))
      })
      .catch(console.error)

  }
}


export function setDroneList(droneList) {
  return {
    type: 'SET_DRONE_LIST',
    droneList
  }
}


export function setDroneCamera(camera) {
  return {
    type: 'SET_DRONE_CAMERA',
    camera
  }
}

export function setIsShowingTerrainProfile(isShowingTerrainProfile) {
  return {
    type: 'SET_IS_SHOWING_TERRAIN_PROFILE',
    isShowingTerrainProfile
  }
}
