import React from 'react'
import { OrganizationApi } from '../../../api'
import { alertTypes } from '../../../util/constants'
import './styles.css'

const OrganizationDeleteModal = (props) => {
  const { member, setIsDeleteModalOpen, setMemberUpdate, setAlertModal } = props

  const handleSubmit = async () => {
    const result = await OrganizationApi.removeMember(
      member.organization_id,
      member.user_id
    )

    if (result.success) {
      setIsDeleteModalOpen(false)
      setMemberUpdate(true)
    } else {
      setIsDeleteModalOpen(false)
      setAlertModal({
        message: result.error,
        type: alertTypes.warning,
      })
    }
  }

  const renderButtons = () => {
    return (
      <div className="dialog__controls">
        <button type="button" className="btn solid" onClick={handleSubmit}>
          Delete
        </button>
        <button
          type="button"
          className="btn white"
          onClick={() => {
            setIsDeleteModalOpen(false)
          }}
        >
          Cancel
        </button>
      </div>
    )
  }

  return (
    <>
      <div className="dialog" id="org-delete">
        <div className="dialog__content">
          <h1 className="dialog__title">Delete Member</h1>
          <p className="center-text">
            Are you sure you want to remove
            <br />
            <strong>{member.name}</strong> ?
          </p>
          {renderButtons()}
        </div>
      </div>
    </>
  )
}

export default OrganizationDeleteModal
