import React from 'react'
import { Map, TileLayer, FeatureGroup } from 'react-leaflet'
import L from 'leaflet'
import PropTypes from 'prop-types'

export default class AnnotationMap extends React.Component {
  static propTypes = {
    center: PropTypes.array.isRequired,
    zoom: PropTypes.number.isRequired,
    tileUrl: PropTypes.string.isRequired,
  }
  constructor(props) {
    super(props)
    this._mapRef = null
  }

  setMapRef = element => {
    if (!element) return
    this._mapRef = element.target || this._mapRef
  }

  setMapBounds = (e) => {
    let bounds = L.latLngBounds([])
    e.target.eachLayer(layer => {
      if (layer instanceof L.Marker) return
      const layerBounds = layer.getBounds()
      bounds.extend(layerBounds)
    })
    if(this._mapRef) {
      this._mapRef.fitBounds(bounds)
    }
  }

  render() {
    const { zoom, center } = this.props
    return (
      <Map
        ref={this.setMapRef}
        center={center}
        maxZoom={24}
        zoom={zoom}
        zoomControl={false}
        animate={true}
        whenReady={this.setMapRef}
        zoomSnap={0.25}
      >
        <TileLayer
          url={this.props.tileUrl}
          zIndex={100}
          maxZoom={24}
        />
        <FeatureGroup
          onAdd={this.setMapBounds}
        >
          {this.props.children}
        </FeatureGroup>
      </Map>
    )
  }
}