import React, { Component } from 'react'
import { connect } from 'react-redux'
import { userLogin, loginWithGoogle } from '../actions/auth'
import LoadingAnimation from '../components/LoadingAnimation/'
import AlertModal from '../components/AlertModal/'
import { setAlertModal } from '../actions/ui'

import Login from '../components/Login/'

class LoginContainer extends Component {
  render() {
    return (
      <div className="App">
        <AlertModal {... this.props} {... this.props.alertModal}/>
        <LoadingAnimation visible={this.props.requestsInProgress.GLOBAL} />
        <Login {... this.props} />
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    requestsInProgress: state.request,
    alertModal: state.alertModal,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (email, password) => dispatch(userLogin(email, password)),
    loginWithGoogle: (token) => dispatch(loginWithGoogle(token)),
    setAlertModal: (data) => dispatch(setAlertModal(data))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer)
