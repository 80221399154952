export function toggleCollapsibleMenu(bool) {
  return {
    type: 'TOGGLE_COLLAPSIBLE_MENU',
    menuState: bool
  }
}


export function setAlertModal(data) {
  return {
    type: 'SET_ALERT_MODAL',
    alertModal: data
  }
}

export function setCoordinateSystemPickerModal(data) {
  return {
    type: 'SET_CRS_MODAL',
    coordinateSystemPickerModal: data
  }
}
