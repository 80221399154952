import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sortable } from 'react-sortable'
import hash from 'object-hash'
import { ProjectPdf } from '../../api'
import { alertTypes } from '../../util/constants'
import IconCheckbox from '../IconCheckbox'
import { CircularProgress } from '@material-ui/core'

import './styles.css'

class ProjectPdfItem extends React.Component {
  render() {
    return <div {...this.props}>{this.props.children}</div>
  }
}

let SortablePdfItem = sortable(ProjectPdfItem)

class ProjectPdfList extends Component {
  static propTypes = {
    projectPdfs: PropTypes.array.isRequired,
    storeProjectPdf: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    setProjectPdfFetchDataSuccess: PropTypes.func.isRequired,
    setProjectForm: PropTypes.func.isRequired,
    projectForm: PropTypes.object.isRequired,
    deleteProjectPdfData: PropTypes.func.isRequired,
    fetchProjectPdfData: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    setPreviewUploadedMarkers: PropTypes.func.isRequired,
    setAlertModal: PropTypes.func.isRequired,
    addPdfLayer: PropTypes.func.isRequired,
    getProjectPdfDebounced: PropTypes.func.isRequired,
  }

  state = {
    projectPdfs: this.props.projectPdfs ? this.props.projectPdfs : [],
    showUpload: false,
    editMode: PropTypes.bool,
    selectedFile: null,
    updating: false
  }

  componentDidUpdate = (prevProps, prepState) => {
    for (let projectPdf of this.props.projectPdfs) {
      // Find matching projectPdf from previous list
      let prevPdf = prevProps.projectPdfs.find(p => p.id === projectPdf.id)
      // compare it to find if status changed to 'done'
      // or If there's a new PDF in props add it to map by default
      if ((prevPdf && projectPdf.status !== prevPdf.status && 
        projectPdf.status === 'done') || !prevPdf) {
        this.props.addPdfLayer(projectPdf)
      }

      if (projectPdf.status === 'pending') {
        this.activateUpdate()
      }
    }
  }

  componentWillUnmount = () => {
    this.props.removeEveryPdfLayer()
  }

  activateUpdate() {
    if (!this.state.updating) {
      this.refreshProjectPdf()
      this.setState({updating: true})
    }
  }

  handleChange = (event) => {
    const target = event.target
    let value = target.value
    this.props.setProjectForm({ pdfName: value })
  }

  handleDelete = (projectPdf) => {
    this.props.setAlertModal({
      message: 'Do you want to delete this PDF layer?',
      type: alertTypes.warning,
      cancellable: true,
      confirmHandler: () => {
        this.props.removePdfLayer(projectPdf.name)
        this.props.deleteProjectPdfData(projectPdf)
        this.props.fetchProjectPdfData(this.props.project.id)
      }
    })
  }

  toggleShowUpload = () => {
    this.setState({showUpload: !this.state.showUpload})
  }

  handleFileInput = (e) => {
    this.setState({selectedFile: e.target.files[0]})
  }
  
  handleUpload = (projectId, name = 'test3') => {
    if (!this.props.projectForm.pdfName) {
      this.props.setAlertModal({
        message: 'Please define PDF name first.',
        type: alertTypes.error
      })
      return
    }
    if (!this.state.selectedFile) {
      this.props.setAlertModal({
        message: 'Please select PDF file first.',
        type: alertTypes.error
      })
      return
    }
    this.toggleShowUpload()
    ProjectPdf.getSignedPostUrls({ projectId: this.props.project.id, fileName: `${name}.pdf` })
      .then((response) => {
        if (response && response.success) {
          this.uploadFile(this.state.selectedFile, response.data).then(response => {
            this.props.storeProjectPdf({projectId, name})
            this.activateUpdate()
          })
        }
      })
  }

  handleClick = (projectPdf) => {
    let status = this.props.mapLayers.pdfs.find(p => p.id === projectPdf.id)
    if (status) {
      this.props.removePdfLayer(projectPdf.name)
    } else {
      this.props.addPdfLayer(projectPdf)
    }
  }

  uploadFile = (file, url) => {
    return new Promise((resolve, reject) => {

      fetch(url, { method: 'put', body: file })
        .then((response) => {
          if (
            (response && response.success) ||
            (response.type === 'cors' && response.status === 200)
          ) {
            this.props.setAlertModal({
              message: 'PDF was uploaded succesfully. PDF might take up to 5 minutes to become available.',
              type: alertTypes.success
            })
            // resolve to our promise to let it know the task is completed
            resolve(response)
          } else if (response && !response.success && response.status !== 200) {
            this.props.setAlertModal({
              message: response.error,
              type: alertTypes.error
            })
            // resolve to our promise to let it know the task is completed
            resolve(response)
          }
        })
        .catch((error) => {
          console.log(error)

          // resolve to our promise to let it know the task is completed
          resolve(error)
        })
    })
  }

  refreshProjectPdf = (milliseconds = 20000) => {
    let project_id = this.props.project.id ? this.props.project.id : this.props.match.params.id
    this.props.getProjectPdfDebounced(project_id)
    this.timer = setTimeout(() => {
      this.refreshProjectPdf()
    }, milliseconds)
  }

  render() {
    let addButtonLabel = this.props.projectPdfs < 1 ? 'Add Reference PDF' : 'Add Another PDF'
    return( 
      <div>
        <h3 className="reference-layer-title">Georeferenced PDFs</h3>
        {
          this.props.projectPdfs.map((projectPdf, index) => {
            let pdfKey = projectPdf.id ? projectPdf.id : index
            if (projectPdf.status === 'pending') {
              this.activateUpdate()
            }
            return(
              <SortablePdfItem
                key={index}
                onSortItems={this.onSortItems}
                items={this.props.projectPdfs}
                sortId={index}
              >
                <article
                  className="flights__item"
                  key={hash(pdfKey)}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span className="material-icons drag-handle">
                    drag_indicator
                  </span>
                  <span className="layer__label label">{projectPdf.name}</span>

                  <div style={{display: 'flex'}}>
                    { projectPdf.status === 'done' && (
                      <IconCheckbox checked={this.props.mapLayers.pdfs.find(p => p.id === projectPdf.id) !== undefined} onChange={() => this.handleClick(projectPdf)}/>
                    )}

                    { projectPdf.status === 'pending' && (
                      <>
                        <div className="map-layer__spinner">
                          <span className="layer__label label">Processing layer... </span>
                          <CircularProgress size={'1rem'} style={{margin: '0 0.5rem'}}/>
                        </div>
                      </>
                    )}

                    { projectPdf.status === 'failed' && (
                      <span>Error</span>
                    )}

                    { projectPdf.status !== 'pending' && (
                      <i
                        className="fa fa-trash-o"
                        style={{marginLeft: '10px'}}
                        aria-hidden="true"
                        onClick={() => this.handleDelete(projectPdf)}
                      ></i>)
                    }
                  </div>

                </article>
              </SortablePdfItem>
            )
          })
        }
        { this.state.showUpload && (
          <label htmlFor="pdfImport">
            <input 
              type="text"
              placeholder="Enter a name for the file"
              onChange={this.handleChange}
            ></input>
            <input
              type="file"
              name="pdfImport"
              accept="application/pdf"
              onChange={this.handleFileInput}
              onClick={(event) => {
                event.target.value = null
              }}
            />
            <button 
              type="button"
              className="btn pdf__btn white"
              onClick={() => this.handleUpload(this.props.project.id, this.props.projectForm.pdfName)}>
              Upload
            </button>
          </label>
        )}
        { !this.state.showUpload && (<button
          type="button"
          className="btn pdf__btn white"
          onClick={this.toggleShowUpload}
        >
          {addButtonLabel}
        </button>)}
      </div>
    )
  }
}

export default ProjectPdfList
