import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Line } from 'rc-progress'
import queue from 'async/queue'
import shortid from 'shortid'
import moment from 'moment'
import 'moment-timezone'
import { Images, Products, OrganizationApi } from '../../api'
import { alertTypes } from '../../util/constants'
import _ from 'lodash'
// import EXIF from 'exif-js'
import path from 'path'
import noImages from './no-images.svg'
import {
  getJobStatus,
  getJobProgress,
  getStripePlanDescription,
} from '../../helpers'
import { Grid, CircularProgress, Typography, Tooltip } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import './styles.css'
import { connect } from 'react-redux'
import SubscriptionsDialog from '../SubscriptionsDialog'
import exifr from 'exifr'
import { latLngToCell } from "h3-js"
class AddFiles extends Component {
  constructor(props) {
    super(props)
    this.state = {
      accept: '',
      files: [],
      dropzoneActive: true,
      progress: -1,
      processed: 0,
      concurrency: 10,
      signedPostUrl: [],
      initializingUpload: false,
      verifyAttempts: 0,
      autoProcess: true,
      selectedFiles: new Set(),
      creditCost: 0,
      imagesPerCredit: 100,
      walletBalance:
        props.activeWallet && props.activeWallet.balance
          ? props.activeWallet.balance
          : 0,
      hasOrgSubscription: false,
      orgSubscription: null,
      openSubscriptionDialog: false,
      filesetSize: 0,
      filesetLimit: 30 * 1073741824, // (1 gb === 1073741824 bytes)
    }
  }

  componentDidMount() {
    // fetch the imagesPerCredit in case it is not the default...
    this.estimateCreditCost([])

    if (this.props.project && this.props.project.organization_id) {
      OrganizationApi.hasSubscription(this.props.project.organization_id)
        .then((resp) => {
          const hasOrgSubscription = resp.success && resp.data.has_subscription
          this.setState({
            hasOrgSubscription,
            orgSubscription: resp.data.subscription,
          })
        })
        .catch(console.error)
    }
  }

  componentWillUnmount() {
    this.props.setPreviewMarkers(null)
  }

  autoPopulateCaptureDate = (file) => {
    if (file.dateTime === undefined) {
      return
    }
    let capture_date = file.dateTime.format('YYYY-MM-DD')
    this.props.setProductForm({ capture_date })
  }

  onDragEnter = () => {
    this.setState({
      dropzoneActive: true,
    })
  }

  onDragLeave = () => {
    this.setState({
      dropzoneActive: false,
    })
  }

  onDrop = (files) => {
    if (this.props.productForm.type === 'video' && files.length > 1) {
      this.props.setAlertModal({
        message: 'Only one video can be uploaded for an intro video.',
        type: alertTypes.error,
      })
      return false
    }

    if (this.props.productForm.type === '3d' && files.length > 1) {
      this.props.setAlertModal({
        message: 'Only one point cloud can be uploaded.',
        type: alertTypes.error,
      })
      return false
    }

    files = files.filter((file) => {
      // REMOVE THIS IN FUTURE WHEN BILL STOP USING MARZIPANO TOOLS
      if (file.name === 'Marzi.zip') {
        return true
      }

      // Check for support file types
      let extension = path.extname(file.name).toLowerCase()

      if (this.props.productForm.type === 'video') {
        if (extension !== '.mp4') {
          this.props.setAlertModal({
            message: `Unsupported file type ${extension}. Only MP4 files are supported!`,
            type: alertTypes.error,
          })
          return false
        }
      } else if (this.props.productForm.type === '3d') {
        if (extension !== '.las' && extension !== '.laz') {
          this.props.setAlertModal({
            message: `Unsupported file type: ${extension}. Only LAS/LAZ files are supported!`,
            type: alertTypes.error,
          })
          return false
        }
      } else {
        if (extension !== '.jpeg' && extension !== '.jpg') {
          this.props.setAlertModal({
            message: `Unsupported file type: ${extension}. Only JPEG files are supported!`,
            type: alertTypes.error,
          })
          return false
        }
      }

      //  duplicates in existing uploaded files
      if (
        this.props.images.filter(
          (image) =>
            image.filename === file.name &&
            image.size === file.size &&
            image.lastModified === file.lastModified
        ).length > 0
      ) {
        return false
      }

      //  duplicates in queued uploaded files
      if (
        this.state.files.filter(
          (f) =>
            f.name === file.name &&
            f.size === file.size &&
            f.lastModified === file.lastModified
        ).length > 0
      ) {
        return false
      }

      return true
    })

    files = files.map((file) => {
      file.uuidFilename =
        shortid.generate() + path.extname(file.name).toLowerCase()
      file.status = 'waiting'
      file.statusMessage = ''
      file.marker = null
      file.previewMarker = null
      file.extension = path.extname(file.name).toLowerCase()
      return file
    })

    // Add the files to the state (no EXIF or thumbnails yet!)
    let existingFiles = _.cloneDeep(this.state.files)
    let newFiles = _.cloneDeep(files)
    files = files.concat(existingFiles)
    this.setState({
      files,
      dropzoneActive: false,
      filesetSize: _.sum(files.map((i) => i.size)),
    })

    // check if fileset size exceed the limit, if exceeds do not process thumbnail and filemetada and return instead
    if (this.state.filesetSize > this.state.filesetLimit) {
      this.props.setProductForm({
        ...this.props.productForm,
        showMapPreview: false,
      })
      if (this.props.productForm.type !== 'video') {
        // estimate cost for credit based users
        this.estimateCreditCost(this.state.files)
      } else {
        // if we just transitioned to a video or gallery type reset the credit cost back to 0
        this.estimateCreditCost([])
      }
      return true
    } else {
      this.props.setProductForm({
        ...this.props.productForm,
        showMapPreview: true,
      })
    }
    // Fetch file EXIF & thumbnail asynchronously.
    let filePromises = newFiles
      .filter((file) => file.extension === '.jpg' || file.extension === '.jpeg')
      .map(
        (file) =>
          new Promise((resolve, reject) => {
            let asyncTasks = [
              this.parseFileThumbnail(file),
              this.parseFileDate(file),
              this.parseFileMetadata(file),
            ]
            Promise.all(asyncTasks).then(() => {
              resolve(file)
            })
          })
      )

    // Wait for all promises to be resolved or rejected, then add the new files to the existing list.
    Promise.all(filePromises).then(() => {
      let mergedFiles = null
      if (existingFiles.length > 0) {
        mergedFiles = [...existingFiles, ...newFiles]
      } else {
        mergedFiles = newFiles
      }

      let markers = mergedFiles
        .map((x) => x.marker)
        .filter((ele) => {
          return ele !== null
        })
      this.props.setPreviewMarkers(markers)

      let sortedFiles = mergedFiles.sort((a, b) => {
        if (a.dateTime && b.dateTime) {
          return a.dateTime - b.dateTime
        }
        return 0
      })
      // populate date from first image in the set
      if (sortedFiles.length > 0) {
        this.autoPopulateCaptureDate(sortedFiles[0])
      }

      if (this.props.productForm.type !== 'video') {
        // estimate cost for credit based users
        this.estimateCreditCost(sortedFiles)
      } else {
        // if we just transitioned to a video or gallery type reset the credit cost back to 0
        this.estimateCreditCost([])
      }

      if (
        this.props.productForm.type === "panorama" &&
        this.props.user.isAdmin
      ) {
        this.props.setProductForm({
          name: latLngToCell(
            files[0].coordinates.lat,
            files[0].coordinates.lon,
            9
          ).toUpperCase(),
        })
      }

      this.setState({
        files: sortedFiles,
        dropzoneActive: false,
      })
    })
  }

  parseFileMetadata = (file) => {
    return new Promise((resolve, reject) => {
      exifr.gps(file).then((result) => {
        if (result === undefined) {
          this.props.setAlertModal({
            message:
              'A file is missing GPS coordinate data and cannot be used for processing.',
            type: alertTypes.error,
          })
          file.status = 'error'
          file.statusText =
            'File missing GPS coordinate data and cannot be used for processing. Please remove it.'
          resolve(file)
        } else {
          file.coordinates = { lat: result.latitude, lon: result.longitude }

          let marker = {
            coordinates: [file.coordinates.lat, file.coordinates.lon],
            name: file.name,
            uuidFilename: file.uuidFilename,
            preview: file.preview,
            uploaded: false,
          }
          file.marker = marker
          resolve(file)
        }
      })
    })
  }

  parseFileDate = (file) => {
    return new Promise((resolve, reject) => {
      let options = {
        pick: ['DateTimeOriginal'],
      }
      exifr.parse(file, options).then((result) => {
        if (result !== undefined) {
          // Some EXIF data seems to use a slightly different datetime format, so checking to see if default is valid first
          file.dateTime = moment(result.DateTimeOriginal, 'YYYY:MM:DD HH:mm:ss')
            ._isValid
            ? moment(result.DateTimeOriginal, 'YYYY:MM:DD HH:mm:ss')
            : moment(result.DateTimeOriginal, 'YYYY-MM-DD HH:mm:ss')
        }
        resolve(file)
      })
    })
  }

  parseFileThumbnail = (file) => {
    return new Promise((resolve, reject) => {
      exifr.thumbnailUrl(file).then((result) => {
        if (result !== undefined) {
          file.thumbnailUrl = result
        }
      })
      resolve(file)
    })
  }

  estimateCreditCost = (files) => {
    Products.estimateCost({
      files_metadata: this.getFilesMetadata(files),
    }).then((resp) => {
      if (resp && resp.success) {
        this.setState({
          creditCost: resp.data.credit_cost,
          imagesPerCredit: resp.data.images_per_credit,
        })
      }
    })
  }

  hasGpsMetadata = (file) => {
    let exif = file.exifdata
    return (
      Object.keys(exif).length > 0 &&
      exif.GPSLatitude !== undefined &&
      exif.GPSLongitude !== undefined &&
      exif.GPSLatitudeRef !== undefined &&
      exif.GPSLongitudeRef !== undefined
    )
  }

  /**
   * Convert GPS decimals format to standard lat, lng coordinates
   */
  convertDecimalstoCoordinates = (exif) => {
    let lat = exif.GPSLatitude
    let lon = exif.GPSLongitude
    let latRef = exif.GPSLatitudeRef || 'N'
    let lonRef = exif.GPSLongitudeRef || 'W'
    lat = (lat[0] + lat[1] / 60 + lat[2] / 3600) * (latRef === 'N' ? 1 : -1)
    lon = (lon[0] + lon[1] / 60 + lon[2] / 3600) * (lonRef === 'W' ? -1 : 1)

    return {
      lat,
      lon,
    }
  }

  /**
   * Click file in list to focus/center map on it's marker
   */
  focusOnMarker = (event, optionalImage) => {
    console.log(optionalImage)
    let coordinate
    if (optionalImage) {
      coordinate = optionalImage.point ? optionalImage.point.coordinates : null
    } else {
      let index = event.currentTarget.getAttribute('data-index')
      if (this.state.files[index].marker) {
        coordinate = this.state.files[index].marker.coordinates
      }
    }
    if (coordinate) {
      this.props.setProductMapPreview({ center: coordinate, zoom: 20 })
    }
  }

  /**
   * Check if any files are actually queued before proceeding with upload
   */
  checkIsEmptyFileSet = () => {
    if (this.state.files.length === 0) {
      this.props.setAlertModal({
        message:
          'There are no files queued for upload. Please drag & drop files in to be uploaded or hit back to return to your project.',
        type: alertTypes.warning,
      })
      this.setState({
        initializingUpload: false,
      })
      return true
    } else {
      return false
    }
  }

  getFilesMetadata = (files) => {
    return files.map((file) => {
      return {
        uuidFilename: file.uuidFilename,
        fileName: file.name,
        filetype:
          file.uuidFilename.endsWith('.laz') ||
          file.uuidFilename.endsWith('.las')
            ? 'application/octet-stream'
            : file.type,
        size: file.size,
        coordinates: file.coordinates,
      }
    })
  }

  /**
   * Start image set upload sequence
   */
  uploadImageSet = (product_id) => {
    let uploadMetadata = {
      projectId: this.props.project.id,
      productId: product_id,
    }

    this.props.setRequestInProgress(true, 'UPLOADING_IMAGES')
    this.setState({
      progress: 0,
      processed: 0,
    })

    // create queue for files to get added to and
    // processed in a max concurrent amount
    let q = queue((task, callback) => {
      // change row icon to visually indicate queued status
      let files = _.cloneDeep(this.state.files)
      files = files.map((existingFile) => {
        if (
          task.fileChunk.find(
            (e) => e.uuidFilename === existingFile.uuidFilename
          )
        ) {
          existingFile.status = 'queued'
          existingFile.statusText = 'Waiting to be uploaded.'
        }
        return existingFile
      })

      this.setState({
        files,
      })

      let filesMetadata = this.getFilesMetadata(task.fileChunk)
      Images.getSignedPostUrls({ ...uploadMetadata, filesMetadata })
        .then((response) => {
          if (response && response.success) {
            let filePromises = task.fileChunk.map((file, index) =>
              this.uploadFile(file, index, response.data[index])
            )
            Promise.all(filePromises).then(() => {
              console.log('File Chunk #', task.index, 'Completed')
              // once all files in the chunks have been processed
              // callback() to begin processing next file chunks
              callback()
            })
          } else {
            this.props.setAlertModal({
              message: response.error,
              type: alertTypes.error,
              cancellable: true,
            })
            this.setState({
              initializingUpload: false,
            })
          }
        })
        .catch((response) => {
          if (
            response.error &&
            response.error.includes(
              'Sorry, you\'ve reached your subscription\'s upload quota'
            )
          ) {
            this.props.setAlertModal({
              message: `${response.error} Please contact our sales team to upgrade your subscription.`,
              type: alertTypes.error,
              cancellable: true,
            })
          } else {
            this.props.setAlertModal({
              message:
                'Error communicating with upload server. Please contact support.',
              type: alertTypes.error,
              cancellable: true,
            })
          }

          this.setState({
            initializingUpload: false,
          })
        })
    })

    // split files into chunks with size of concurrency
    const chunkSize = this.state.concurrency
    for (let i = 0; i < this.state.files.length; i += chunkSize) {
      const fileChunk = this.state.files.slice(i, i + chunkSize)
      const index = i / chunkSize
      q.push({
        fileChunk,
        index,
      })
    }

    q.drain = () => {
      console.log('ALL DONE!')
      let files = _.cloneDeep(this.state.files)
      let uploadedFiles = files.filter((e) => e.status === 'uploaded')
      this.verifyRecordsExist()
      Images.createFilesEntries({
        ...uploadMetadata,
        filesMetadata: this.getFilesMetadata(uploadedFiles),
      }).then((response) => {
        console.log('SUCCESS MASS LIST POST', response)
      })
    }
  }

  /**
   * Upload individual file to a given signed Url
   */
  uploadFile = (file, index, url) => {
    return new Promise((resolve, reject) => {
      console.log('processing item #', index)
      let metadata = {
        'x-amz-meta-projectId': this.props.project.id,
        'x-amz-meta-productId': this.props.product.id,
        'x-amz-meta-filename': file.name,
        'x-amz-meta-userId': this.props.user.id,
      }

      if (!url.includes(file.uuidFilename)) {
        console.error('Signed url file name doesnt match')
        reject()
      }

      fetch(url, { method: 'put', headers: metadata, body: file })
        .then((response) => {
          if (
            (response && response.success) ||
            (response.type === 'cors' && response.status === 200)
          ) {
            // update file status to uploaded for nice icon display of in rows
            let files = _.cloneDeep(this.state.files)
            files = files.map((existingFile) => {
              if (existingFile.uuidFilename === file.uuidFilename) {
                existingFile.status = 'uploaded'
                existingFile.statusText = 'File successfully uploaded.'
              }
              return existingFile
            })

            // for progress bar percentage
            let processed = JSON.parse(JSON.stringify(this.state.processed))
            processed = processed + 1

            this.setState({
              files,
              progress: processed / this.state.files.length,
              processed,
            })

            // uploaded markers on map
            if (file.marker) {
              let coordinates = file.marker.coordinates
              let name = file.filename
              let preview = file.preview
              this.props.setPreviewUploadedMarkers({
                coordinates,
                name,
                preview,
                uploaded: true,
              })
            }

            // resolve to our promise to let it know the task is completed
            resolve()
          } else if (response && !response.success && response.status !== 200) {
            this.props.setAlertModal({
              message: response.error,
              type: alertTypes.error,
              cancellable: true,
            })

            let files = _.cloneDeep(this.state.files)
            files = files.map((existingFile) => {
              if (existingFile.uuidFilename === file.uuidFilename) {
                existingFile.status = 'error'
                existingFile.statusText = response.error
                  ? response.error
                  : response.statusText
              }
              return existingFile
            })

            // for progress bar percentage
            let processed = JSON.parse(JSON.stringify(this.state.processed))
            processed = processed + 1

            this.setState({
              files,
              progress: processed / this.state.files.length,
              processed,
            })

            // resolve to our promise to let it know the task is completed
            resolve()
          }
        })
        .catch((error) => {
          console.log(error)
          let files = _.cloneDeep(this.state.files)
          files = files.map((existingFile) => {
            if (existingFile.uuidFilename === file.uuidFilename) {
              existingFile.status = 'error'
              existingFile.statusText =
                'Error uploading this file. Try again or remove it.'
            }
            return existingFile
          })

          // for progress bar percentage
          let processed = JSON.parse(JSON.stringify(this.state.processed))
          processed = processed + 1

          this.setState({
            files,
            progress: processed / this.state.files.length,
            processed,
          })

          // resolve to our promise to let it know the task is completed
          resolve()
        })
    })
  }

  /**
   * Form submission handler
   */
  handleSubmit = (event, autoProcess = true) => {
    event.preventDefault()
    this.setState({ autoProcess })

    // REMOVE THIS IN FUTURE WHEN BILL STOP USING MARZIPANO TOOLS
    if (this.state.files[0] && this.state.files[0].name === 'Marzi.zip') {
      let data = new FormData()
      data.append('file', this.state.files[0])
      data.append('project_id', this.props.project.id)

      this.props.setRequestInProgress(true, 'GLOBAL')
      Images.createMarzi(data).then((response) => {
        if (response && response.success) {
          this.props.setRequestInProgress(false, 'GLOBAL')
          this.props.getProducts(this.props.project.id)
          this.props.setIsUploadingImages(false)
          this.props.setAlertModal({
            message:
              'Your new panorama should be in the product processing list now. Please note, it will not appear in the Review tab until the file has been fully transferred to our product storage.',
            type: alertTypes.success,
          })
        } else {
          this.props.setAlertModal({
            message: response.data,
          })
          this.props.setRequestInProgress(false, 'GLOBAL')
        }
      })
      return
    }

    if (this.props.productForm.type === '') {
      this.props.setAlertModal({
        message: 'Please select a product type.',
        type: alertTypes.warning,
      })
      return
    }

    if (!this.props.productForm.editing) {
      // subscription users don't need to be prompted for confirmation about credit usage
      if (
        this.props.user &&
        (this.props.user.hasActiveSubscription || this.state.hasOrgSubscription)
      ) {
        this.createInitialProduct()
      } else {
        this.props.setAlertModal({
          message: `Are you sure that you want to use ${this.state.creditCost} credit(s) to create this product?`,
          cancellable: true,
          type: alertTypes.warning,
          confirmHandler: () => {
            this.createInitialProduct()
          },
        })
      }
    } else {
      // if user is credit user and the change requires credits to complete, ask for confirmation
      if (
        this.props.user &&
        !this.props.user.hasActiveSubscription &&
        !this.state.hasOrgSubscription &&
        this.state.creditCost > 0
      ) {
        // the user has added photos to their product, confirm that changes are ok
        this.props.setAlertModal({
          message: `Are you sure that you want to use ${this.state.creditCost} credit(s) to update this product?`,
          type: alertTypes.warning,
          cancellable: true,
          confirmHandler: () => {
            this.updateExistingProduct()
          },
        })
      } else {
        // they are a subscription user or the change doesn't cost anything
        this.updateExistingProduct()
      }
    }
  }

  createInitialProduct = () => {
    if (!this.checkIsEmptyFileSet()) {
      this.setState({
        initializingUpload: true,
      })

      // Create the product on the server first,
      // and only then let them upload the images.
      Products.create({
        ...this.props.productForm,
        files_metadata: this.getFilesMetadata(this.state.files),
      })
        .then((response) => {
          if (response && response.success) {
            this.props.setProductForm({ editing: true, id: response.data.id })
            let newProduct = response.data
            this.props.setProductDataSuccess(newProduct)
            this.uploadImageSet(newProduct.id)

            // update wallets
            this.props.getLoggedInUser()
            this.props.getUserTeams()
            this.updateWalletBalance()
          }
        })
        .catch((response) => {
          if (
            response.error &&
            response.error === 'Credits or subscription required.'
          ) {
            this.props.setAlertModal({
              message:
                'You need to purchase credits or an enterprise subscription in order to upload and process products. Would you like to buy credits?',
              type: alertTypes.info,
              cancellable: true,
              confirmHandler: () => {
                this.props.setIsPurchasingCredits(true)
              },
            })
          } else if (
            response.error &&
            response.error.includes(
              'Sorry, you\'ve reached your subscription\'s upload quota'
            )
          ) {
            this.props.setAlertModal({
              message: `${response.error} Please contact our sales team to upgrade your subscription.`,
              type: alertTypes.error,
              cancellable: true,
            })
          } else if (response.error) {
            this.props.setAlertModal({
              message: `${response.error}`,
              type: alertTypes.error,
              cancellable: true,
            })
          } else {
            this.props.setAlertModal({
              message:
                'Error communicating with upload server. Please contact support.',
              type: alertTypes.error,
              cancellable: true,
            })
          }
          this.setState({
            initializingUpload: false,
          })
        })
    }
  }

  updateWalletBalance = () => {
    // only update balance if we have an active wallet
    if (this.props.activeWallet) {
      let walletBalance = this.state.walletBalance
      if (this.state.creditCost > 0) {
        walletBalance -= this.state.creditCost
        if (walletBalance < 0) {
          walletBalance = 0
        }
        this.setState({ walletBalance })
      }
    }
  }

  updateExistingProduct = () => {
    this.props.setRequestInProgress(true, 'GLOBAL')
    Products.update(this.props.productForm.id, {
      ...this.props.productForm,
      files_metadata: this.getFilesMetadata(this.state.files),
    })
      .then((response) => {
        if (response && response.success) {
          this.props.setProductForm({ editing: true, id: response.data.id })
          let product = response.data
          this.props.setProductDataSuccess(product)

          // update wallets to properly reflect any charges that may have happened
          this.props.getLoggedInUser()
          this.props.getUserTeams()
          this.updateWalletBalance()

          if (this.state.files.length > 0) {
            this.setState({
              initializingUpload: true,
            })
            this.uploadImageSet(product.id)
          } else {
            if (this.state.autoProcess) {
              this.startProcessing(product.id)
            }

            this.props.setAlertModal({
              message: `Product successfully saved. ${
                this.state.autoProcess ? 'Processing will now begin.' : ''
              }`,
              type: alertTypes.success,
              confirmHandler: () => {
                if (this.state.autoProcess) {
                  // hide the product form.
                  // this.props.setProductForm(null)
                }
              },
            })
          }
          this.props.getProducts(this.props.project.id)
        }
      })
      .catch((response) => {
        if (
          response.error &&
          response.error === 'Credits or subscription required.'
        ) {
          this.props.setAlertModal({
            message:
              'You need to purchase credits or an enterprise subscription in order to upload and process products. Would you like to buy credits?',
            type: alertTypes.error,
            cancellable: true,
            confirmHandler: () => {
              this.props.setIsPurchasingCredits(true)
            },
          })
        } else if (response.error) {
          this.props.setAlertModal({
            message: `${response.error}`,
            type: alertTypes.error,
            cancellable: true,
          })
        } else {
          this.props.setAlertModal({
            message:
              'Error communicating with upload server. Please contact support.',
            type: alertTypes.error,
          })
        }
        this.setState({
          initializingUpload: false,
        })
      })
      .finally(() => {
        this.props.setRequestInProgress(false, 'GLOBAL')
      })
  }

  handleDeleteProduct = () => {
    this.props.setAlertModal({
      message:
        'Are you sure that you want to PERMANENTLY DELETE this product and its files? Make sure to have a backup copy on your computer!',
      type: alertTypes.warning,
      confirmLabel: 'Delete',
      confirmHandler: () => {
        this.props.deleteProduct(this.props.productForm.id)
      },
      cancellable: true,
    })
  }

  handleCancelJob = () => {
    this.props.setAlertModal({
      message:
        'Are you sure that you want to cancel processing for this product?',
      type: alertTypes.warning,
      confirmLabel: 'Cancel Processing',
      confirmHandler: () => {
        let product = this.getProduct()
        this.props.cancelProductJob(product.job.id)
      },
      cancellable: true,
    })
  }

  /**
   * Remove file from upload queue
   */
  cancelFile(index, file) {
    let files = _.cloneDeep(this.state.files)
    files.splice(index, 1)
    let uploadedIndex = this.props.previewUploadedMarkers.findIndex((f) => {
      return (
        f.name === file.name &&
        f.size === file.size &&
        f.lastModified === file.lastModified
      )
    })
    if (uploadedIndex) {
      let previewUploadedMarkerData = JSON.parse(
        JSON.stringify(this.props.previewUploadedMarkers)
      )
      previewUploadedMarkerData.splice(uploadedIndex, 1)
      this.props.setPreviewUploadedMarkers(previewUploadedMarkerData)
    }
    this.props.removePreviewMarkers([file.uuidFilename])
    this.setState({
      files,
      forceUpdate: Math.random(),
      dropzoneActive: files.length === 0,
    })
  }

  /**
   * After all files are uploaded, this checks if what the server has matches what the browser has.
   */
  verifyRecordsExist = () => {
    this.props.setRequestInProgress(true, 'VERIFYING_UPLOADS')
    Images.getImages(this.props.product.id)
      .then((response) => {
        let dbRecords = response.data.files.total
        let browserFiles = this.state.files.filter(
          (e) => e.status === 'uploaded'
        )
        console.log('DB', dbRecords)
        console.log('BROWSER', browserFiles.length)
        console.log('PREVIOUS DB', this.props.totalImageCount)

        if (
          dbRecords ===
          browserFiles.length + this.props.totalImageCount
        ) {
          // reset form for future uploads
          this.setState(
            {
              files: this.state.files.filter((e) => e.status !== 'uploaded'),
              progress: -1,
              processed: 0,
              dropzoneActive: true,
              signedPostUrl: [],
              initializingUpload: false,
            },
            () => {
              this.props.setRequestInProgress(false, 'UPLOADING_IMAGES')
              this.props.setRequestInProgress(false, 'VERIFYING_UPLOADS')

              // reset credit estimate/cost
              this.estimateCreditCost([])

              if (!this.state.files.length) {
                if (this.state.autoProcess) {
                  this.startProcessing(this.props.product.id)
                }
                this.props.setAlertModal({
                  message: `Product successfully saved and uploaded. ${
                    this.state.autoProcess ? 'Processing will now begin.' : ''
                  }`,
                  type: alertTypes.success,
                  confirmHandler: () => {
                    if (this.state.autoProcess) {
                      this.props.getProducts(this.props.project.id)
                      this.props.closeDialog()
                    }
                  },
                })
              } else {
                this.props.setAlertModal({
                  message:
                    'An error occurred uploading some of your files. Select re-try to re-attempt the failed files.',
                  type: alertTypes.warning,
                  confirmLabel: 'Re-try',
                  // cancellable: true,
                  closeLabel: this.state.autoProcess ? 'Process Anyways' : 'OK',
                  confirmHandler: () => {
                    this.uploadImageSet(this.props.product.id)
                  },
                  closeHandler: () => {
                    if (this.state.autoProcess) {
                      this.setState({
                        files: [],
                      })
                      this.startProcessing(this.props.product.id)
                      this.props.getProducts(this.props.project.id)
                      this.props.closeDialog()
                    }
                  },
                })
              }

              // TODO: Change the way this calls a parent component function passed via props
              // should be a redux action creator instead
              this.props.fetchImages()

              this.props.getProducts(this.props.project.id)
            }
          )
        } else {
          this.setState({
            verifyAttempts: this.state.verifyAttempts + 1,
          })
          throw Error('Files do not match.')
        }
      })
      .catch((error) => {
        console.log(error)
        // keep trying for a while to get records set from the cloud function that trickle in...
        if (this.state.verifyAttempts < 10) {
          setTimeout(this.verifyRecordsExist, 1000)
        } else {
          // reset form
          this.setState(
            {
              files: [],
              progress: -1,
              processed: 0,
              dropzoneActive: true,
              signedPostUrl: [],
              initializingUpload: false,
              verifyAttempts: 0,
            },
            () => {
              this.props.setRequestInProgress(false, 'UPLOADING_IMAGES')
              this.props.setRequestInProgress(false, 'VERIFYING_UPLOADS')
              this.props.fetchImages()
              this.props.setAlertModal({
                message:
                  'Unable to verify all uploads completed properly. Please check for any missing files and try again.',
                type: alertTypes.error,
              })
            }
          )

          this.props.setRequestInProgress(false, 'UPLOADING_IMAGES')
          this.props.setRequestInProgress(false, 'VERIFYING_UPLOADS')
        }
      })
  }

  startProcessing = (product_id) => {
    Products.createJob({ product_id })
      .then((response) => {
        console.log(response)
        if (response.success) {
          this.props.getProductJobs(this.props.project.id)
        }
      })
      .catch((response) => {
        if (response.error) {
          this.props.setAlertModal({
            message: `${response.error}`,
            type: alertTypes.error,
            cancellable: true,
          })
        } else {
          this.props.setAlertModal({
            message:
              'Error communicating with upload server. Please contact support.',
            type: alertTypes.error,
            cancellable: true,
          })
        }

        this.setState({
          initializingUpload: false,
        })
      })
  }

  getSelectedImages() {
    return this.props.images.filter((x) =>
      this.state.selectedFiles.has(x.hashname)
    )
  }

  /**
   * Delete all queued files
   */
  deleteSelectedFiles = (event) => {
    event.preventDefault()
    this.props.setAlertModal({
      message: `Are you sure you want to delete ${this.state.selectedFiles.size} selected file(s)?`,
      cancellable: true,
      type: alertTypes.warning,
      confirmHandler: () => {
        // remove preview markers
        let uuidFilenames = Array.from(this.state.selectedFiles)
        this.props.removePreviewMarkers(uuidFilenames)

        // set new state accordingly
        let files = this.state.files.filter(
          (x) => !this.state.selectedFiles.has(x.uuidFilename)
        )
        this.setState({
          files,
          selectedFiles: new Set(),
          dropzoneActive: files.length === 0,
        })

        // update the credit cost
        if (this.props.productForm.type !== 'video') {
          // estimate cost for credit based users
          this.estimateCreditCost(files)
        } else {
          // video are free
          this.estimateCreditCost([])
        }

        let selectedImages = this.getSelectedImages()
        if (selectedImages.length > 0) {
          let hashnames = selectedImages.map((x) => x.hashname)
          let body = { hashnames }
          this.props.setRequestInProgress(true, 'GLOBAL')
          Images.deleteImages(body)
            .then((response) => {
              if (response.success === true) {
                this.props.fetchImages()
                // TODO: update this.props.images to exclude deleted files.
                // TOOD: some sort of error occurred
              }
            })
            .finally(() => {
              this.props.setRequestInProgress(false, 'GLOBAL')
            })
        }
      },
    })
  }

  onFileCheckedChange = (key) => {
    if (
      this.state.files.findIndex((x) => x.uuidFilename === key) === -1 &&
      this.props.images.findIndex((x) => x.hashname === key) === -1
    ) {
      // File does not exist in the files or images list.
      return
    }
    let selectedFiles = new Set(this.state.selectedFiles.keys())
    if (selectedFiles.has(key)) {
      selectedFiles.delete(key)
    } else {
      selectedFiles.add(key)
    }
    this.setState({
      selectedFiles,
    })
  }

  toggleSelectAllFiles = () => {
    if (this.areAllFilesSelected()) {
      this.deselectAllFiles()
    } else {
      this.selectAllFiles()
    }
  }

  selectAllFiles = () => {
    let a = this.state.files
      .map((x) => x.uuidFilename)
      .concat(this.props.images.map((x) => x.hashname))
    this.setState({
      selectedFiles: new Set(a),
    })
  }

  areAllFilesSelected = () => {
    return (
      this.state.selectedFiles.size > 0 &&
      this.state.files.length + this.props.images.length ===
        this.state.selectedFiles.size
    )
  }

  deselectAllFiles = () => {
    this.setState({
      selectedFiles: new Set(),
    })
  }

  isFileSelected = (uuid) => {
    return this.state.selectedFiles.has(uuid)
  }

  canDeleteFiles = () => {
    if (this.state.selectedFiles.size === 0) {
      return false
    }
    let product = this.getProduct()
    if (
      product &&
      product.job &&
      product.job.status !== 'done' &&
      product.job.status !== 'failed'
    ) {
      return false
    }
    return true
  }

  getProduct = () => {
    return this.props.products.find((x) => x.id === this.props.productForm.id)
  }

  renderTimeline = () => {
    const { files } = this.state
    let timestampedFiles = files.filter((x) => x.dateTime)
    if (timestampedFiles.length > 0) {
      return (
        <div className="file-timestamp-timeline">
          <div className="file-timestamp-timeline__title">
            <Typography variant="overline">Timestamp Timeline</Typography>
          </div>
          <div className="file-timestamp-timeline__items">
            {timestampedFiles.map((file) => {
              let itemTimeUnix = file.dateTime.format('x')
              let startTimeUnix = timestampedFiles[0].dateTime.format('x')
              let endTimeUnix =
                timestampedFiles[timestampedFiles.length - 1].dateTime.format(
                  'x'
                )
              let sequenceTotalTime = endTimeUnix - startTimeUnix
              let percentage =
                ((itemTimeUnix - startTimeUnix) / sequenceTotalTime) * 100
              return (
                <button
                  type="button"
                  style={{ left: `${percentage}% ` }}
                  className="file-timestamp-timeline__item"
                  key={`${file.name}-${file.size}`}
                >
                  <span>
                    {file.name}
                    <br />
                    {file.dateTime.format('MMM Do YYYY')}
                    <i
                      className="fa fa-trash-o"
                      aria-hidden="true"
                      onClick={() =>
                        this.cancelFile(
                          this.state.files.findIndex(
                            (f) => f.uuidFilename === file.uuidFilename
                          ),
                          file
                        )
                      }
                    ></i>
                  </span>
                </button>
              )
            })}
          </div>
        </div>
      )
    }
    return null
  }

  toggleGCPOverlay = (productJob) => {
    let toggle = this.props.isGCPOverlayOpen ? false : true
    if (toggle) {
      this.props.setProductJob(productJob)
    } else {
      this.props.setProductJob(null)
    }
    this.props.setIsGCPOverlayOpen(toggle)
  }

  completeProcessing = (event, productJob) => {
    event.preventDefault()
    this.props.setAlertModal({
      message:
        'Are you sure you want to continue? This will complete processing the final product.',
      cancellable: true,
      type: alertTypes.warning,
      confirmHandler: () => {
        this.props.setRequestInProgress(true, 'GLOBAL')
        Products.completeProcessing(productJob.id, productJob.current_task.id)
          .then((response) => {
            console.log(response)
          })
          .finally(() => {
            this.props.setRequestInProgress(false, 'GLOBAL')
          })
      },
    })
  }

  exportFiles = (event) => {
    if (this.props.productForm.id) {
      this.props.setAlertModal({
        message:
          'Are you sure that you want to export a copy of all raw input files from this product? We will send you a compressed zip file via email link.',
        type: alertTypes.warning,
        cancellable: true,
        confirmHandler: () => {
          this.props.setRequestInProgress(true, 'GLOBAL')

          const postBody = {
            productId: this.props.productForm.id,
          }
          Images.exportAllRaw(postBody)
            .then((response) => {
              this.props.setRequestInProgress(false, 'GLOBAL')

              if (response.success) {
                this.props.setAlertModal({
                  message:
                    'Export initiated. You should receive an email with a link to download the files within the next few minutes.',
                  type: alertTypes.info,
                })
              } else {
                this.props.setAlertModal({
                  message: response.error,
                  type: alertTypes.error,
                })
              }
            })
            .catch((error) => console.log(error))
        },
      })
    } else {
      this.props.setAlertModal({
        message:
          'Unable to export raw files. Product ID not found. Please make sure you\'ve uploaded something before exporting.',
        type: alertTypes.error,
      })
    }
  }

  handleClose = () => {
    if (this.props.mobileScreen) {
      this.props.toggleImagesView()
    } else {
      this.props.closeDialog()
    }
  }

  renderSubscriptionDescription = (stripe_price, organization = false) => {
    return (
      <>
        {organization ? (
          <>
            <div className="addfiles__process-title">
              Subscription Summary (organization)
            </div>
            <Typography variant="body2">
              <Typography variant="body2" color="primary" component="span">
                {this.state.orgSubscription &&
                  (this.state.orgSubscription.maxPhotoLimit
                    ? this.state.orgSubscription.maxPhotoLimit
                    : 'unlimited')}{' '}
                images
              </Typography>
              &nbsp;monthly plan
            </Typography>
          </>
        ) : (
          <>
            <div className="addfiles__process-title">Subscription Summary</div>

            <Typography variant="body2">
              <Typography variant="body2" color="primary" component="span">
                {getStripePlanDescription(
                  stripe_price,
                  this.props.subscriptionPlans
                )}
              </Typography>
              &nbsp;monthly plan
            </Typography>
          </>
        )}
      </>
    )
  }

  handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
    if (bottom) {
      this.props.fetchMoreImages()
    }
  }

  render() {
    const { accept, files } = this.state
    let dropzoneRef
    // TODO: move this out of here to save time
    // TODO: we have to grab it from the products list, in future this should be a self-contained fetch
    // we don't have to rely on global state being a certain way to get this to work.
    let product = this.getProduct()
    let job = product ? product.job : null
    let mobileClassName = this.props.mobileScreen ? 'mobile' : ''
    let smClassName = this.props.smScreen ? 'sm' : ''
    let hideAddFilesFormClassName = this.props.showImagesView ? '' : 'hide'

    return (
      <>
        <SubscriptionsDialog
          user={this.props.user}
          setAlertModal={this.props.setAlertModal}
          open={this.state.openSubscriptionDialog}
          onClose={() => this.setState({ openSubscriptionDialog: false })}
        />
        <form
          onSubmit={this.handleSubmit}
          className={`addfiles-form ${smClassName} ${mobileClassName} ${hideAddFilesFormClassName}`}
        >
          <CloseIcon
            onClick={this.handleClose}
            className={`addfiles-close-icon ${mobileClassName}`}
            fontSize="large"
          />
          <section>
            <header className={`file-management__header ${smClassName}`}>
              <Typography variant="overline"> Images </Typography>

              <div>
                <button
                  type="button"
                  className={`btn-primary btn-secondary-white file-management__btn ${smClassName}`}
                  onClick={() => {
                    dropzoneRef.open()
                  }}
                >
                  Browse Files
                </button>

                {this.props.user &&
                  !this.props.user.organizationMembership &&
                  this.props.images.length > 0 && (
                  <button
                    type="button"
                    className={`btn-primary btn-secondary-white file-management__btn ${smClassName}`}
                    onClick={this.exportFiles}
                  >
                      Export Files
                  </button>
                )}

                {/* TODO: files and images are going to need to be merged. either that or a whole bunch of dumb duplicate logic is going to need to be written */}
                <button
                  type="button"
                  className={`btn-primary btn-secondary-white file-management__btn ${smClassName}`}
                  onClick={this.toggleSelectAllFiles}
                  disabled={
                    this.state.files.length === 0 &&
                    this.props.images.length === 0
                  }
                >
                  {this.areAllFilesSelected() ? 'Deselect All' : 'Select All'}
                </button>

                <button
                  type="button"
                  className={`btn-primary btn-primary-distructive file-management__btn ${smClassName}`}
                  disabled={!this.canDeleteFiles()}
                  onClick={this.deleteSelectedFiles}
                >
                  Delete
                </button>
              </div>
            </header>
          </section>

          <section className="dropzone" onScroll={this.handleScroll}>
            <Dropzone
              disableClick
              accept={accept}
              onDrop={this.onDrop}
              onDragEnter={this.onDragEnter}
              onDragLeave={this.onDragLeave}
              ref={(node) => {
                dropzoneRef = node
              }}
              className="dropzone-area"
            >
              {this.state.files.length === 0 &&
                this.props.images.length === 0 &&
                !this.props.requestsInProgress.FETCHING_IMAGES &&
                !this.props.requestsInProgress.VERIFYING_UPLOADS && (
                <div className="dropzone-noimages-container">
                  <div
                    className={`dropzone-noimages-messagebox ${mobileClassName}`}
                  >
                    <Typography variant="subtitle1" gutterBottom>
                        No images uploaded.
                      <br />
                        Drag & drop files here.
                    </Typography>

                    <button
                      type="button"
                      className="btn-primary btn-secondary-white dropzone-noimages-btn"
                      onClick={() => {
                        dropzoneRef.open()
                      }}
                    >
                        Browse Files
                    </button>
                  </div>
                  {!this.props.mobileScreen && (
                    <img
                      src={noImages}
                      className="dropzone-noimages-background"
                      alt="No images here"
                    />
                  )}
                </div>
              )}

              {this.props.requestsInProgress.FETCHING_IMAGES && (
                <div className="addfiles__loading">
                  <CircularProgress
                    className="addfiles__loading-circular-progress"
                    color="primary"
                  />
                  <div className="addfiles__loading-text">
                    Checking server for uploaded files...{' '}
                  </div>
                </div>
              )}

              {this.props.requestsInProgress.VERIFYING_UPLOADS && (
                <div className="addfiles__loading">
                  <CircularProgress
                    className="addfiles__loading-circular-progress"
                    color="primary"
                  />
                  <div className="addfiles__loading-text">
                    Verifying file uploads...{' '}
                  </div>
                </div>
              )}

              {this.state.files.length > 0 && (
                <Grid item xs={12}>
                  <div className="dropzone-title">
                    <Typography variant="body">
                      Awaiting Upload ({files.length})
                      {this.state.progress > -1 &&
                        ` : ${
                          this.state.files.length - this.state.processed
                        } Remaining`}
                      <br />
                    </Typography>
                  </div>
                </Grid>
              )}

              {!this.props.requestsInProgress.VERIFYING_UPLOADS && (
                <Grid container spacing={1}>
                  {files.map((file, index) => {
                    return (
                      <Grid
                        item
                        key={index}
                        xs={12}
                        md={6}
                        lg={3}
                        data-index={index}
                        onClick={(e) => this.focusOnMarker(e)}
                        data-file-status={file.status}
                      >
                        <div
                          className="file-list__item"
                          onClick={() =>
                            this.onFileCheckedChange(file.uuidFilename)
                          }
                        >
                          <input
                            type="checkbox"
                            checked={this.isFileSelected(file.uuidFilename)}
                            name={file.uuidFilename}
                          />
                          {file.thumbnailUrl === undefined ? (
                            <div className="file-list__item-nothumbnail">
                              <i className="fa fa-image" aria-hidden="true" />
                            </div>
                          ) : (
                            <img
                              className="file-list__item-thumbnail"
                              src={file.thumbnailUrl}
                              alt="file thumbnail"
                            />
                          )}
                          <div className="file-list__item-text">
                            <Typography variant="subtitle2">
                              {file.name}
                            </Typography>
                            <Typography variant="caption">
                              {file.dateTime &&
                                file.dateTime.format('MMM Do YYYY')}
                            </Typography>
                          </div>
                          <div className="file-list__item-icon">
                            {file.status === 'error' && (
                              <Tooltip
                                title={file.statusText}
                                placement="bottom"
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                arrow
                              >
                                <i
                                  className="fa fa-exclamation"
                                  aria-hidden="true"
                                />
                              </Tooltip>
                            )}
                            {file.status === 'uploaded' && (
                              <i className="fa fa-cloud" aria-hidden="true" />
                            )}
                            {file.status === 'queued' && (
                              <i
                                className="fa fa-cloud-upload"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </div>
                      </Grid>
                    )
                  })}

                  {this.props.totalImageCount > 0 && (
                    <Grid item xs={12}>
                      <div className="dropzone-title">
                        <Typography variant="body">{`Uploaded (${this.props.totalImageCount})`}</Typography>
                      </div>
                    </Grid>
                  )}

                  {this.props.images.map((image, index) => (
                    <Grid
                      item
                      key={index}
                      xs={12}
                      md={6}
                      lg={3}
                      onClick={(e) => this.focusOnMarker(e, image)}
                    >
                      <div
                        className="file-list__item"
                        onClick={() => this.onFileCheckedChange(image.hashname)}
                      >
                        <input
                          type="checkbox"
                          checked={this.isFileSelected(image.hashname)}
                          name={image.hashname}
                        />
                        {image.thumbnailUrl === undefined ? (
                          <div className="file-list__item-nothumbnail">
                            <i className="fa fa-image" aria-hidden="true" />
                          </div>
                        ) : (
                          <img
                            className="file-list__item-thumbnail"
                            src={image.thumbnailUrl}
                            alt=""
                          />
                        )}
                        <div className="file-list__item-text">
                          <Typography variant="subtitle2">
                            {image.filename}
                          </Typography>
                        </div>
                        <div className="file-list__item-icon">
                          <i className="fa fa-cloud" aria-hidden="true" />
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Dropzone>
          </section>

          {this.state.filesetSize < this.state.filesetLimit && (
            <section>{this.renderTimeline()}</section>
          )}

          <section className="addfiles__process-container">
            {!this.state.initializingUpload &&
              !(
                this.props.user.hasActiveSubscription ||
                this.state.hasOrgSubscription
              ) &&
              this.props.productForm.type && (
              <>
                <div className="addfiles__process-section">
                  <div className="addfiles__process-title">
                      Credit Summary
                  </div>
                  {this.props.activeWallet && this.state.walletBalance > 0 && (
                    <>
                      <Typography variant="body2">
                          Wallet has {this.state.walletBalance} credit(s)
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ color: '#426288' }}
                      >
                          Cost is 1 credit per {this.state.imagesPerCredit}{' '}
                          images
                      </Typography>
                    </>
                  )}
                  {this.props.activeWallet && this.state.walletBalance === 0 && (
                    <>
                      <Typography variant="body2">
                        {' '}
                          Wallet is empty{' '}
                      </Typography>
                      <Typography
                        variant="body2"
                        className="button-link"
                        onClick={() =>
                          this.props.setIsPurchasingCredits(true)
                        }
                        gutterBottom
                      >
                        <button type="button" className="button-link">
                            Purchase credits now
                        </button>
                      </Typography>
                    </>
                  )}
                </div>
              </>
            )}

            {!this.state.initializingUpload && (
              <>
                <div className="addfiles__process-section">
                  {this.state.hasOrgSubscription &&
                  this.state.orgSubscription ? (
                      <>
                        {this.renderSubscriptionDescription(
                          this.state.orgSubscription.stripe_price,
                          true
                        )}
                      </>
                    ) : (
                      <>
                        {this.props.user.hasActiveSubscription ? (
                          <>
                            {this.renderSubscriptionDescription(
                              this.props.user.activeSubscription.stripe_price
                            )}
                          </>
                        ) : (
                          <>
                            {/* {this.state.walletBalance === 0 && (
                            <>
                              <div className="addfiles__process-title"> Subscription Summary </div>
                              <Typography variant="body2"> No subscription </Typography>
                              <Typography variant="body2" className="button-link" gutterBottom>
                                <button
                                  type="button"
                                  className="button-link"
                                  onClick={() => this.setState({ openSubscriptionDialog: true })}
                                >
                                  Purchase subscription now
                                </button>
                              </Typography>
                            </>
                          )} */}
                          </>
                        )}
                      </>
                    )}
                </div>
              </>
            )}

            {this.state.initializingUpload && (
              <div className="addfiles__process-section">
                <div className="addfiles__process-title">Upload Status</div>
                <Typography variant="body2">Queuing and Uploading</Typography>
              </div>
            )}
            {!this.state.initializingUpload && job && (
              <div className="addfiles__process-section">
                <div className="addfiles__process-title">Processing Status</div>

                <Typography variant="body2">{getJobStatus(job)}</Typography>
              </div>
            )}

            <div className={`addfiles__process-btn-container ${smClassName}`}>
              {job && (job.status === 'new' || job.status === 'queued') && (
                <button
                  type="button"
                  className="btn-primary btn-primary-white addfiles__process-btn-large"
                  onClick={this.handleCancelJob}
                >
                  Cancel Processing
                </button>
              )}

              {job &&
                job.current_task !== null &&
                job.status === 'paused' &&
                job.current_task.type.id === 'refine-markers' && (
                <>
                  <button
                    type="button"
                    className="btn-primary btn-primary-solid addfiles__process-btn"
                    onClick={() => this.toggleGCPOverlay(job)}
                  >
                      Refine GCPs
                  </button>
                  <button
                    type="button"
                    className="btn-primary btn-primary-solid addfiles__process-btn-large"
                    onClick={(e) => this.completeProcessing(e, job)}
                  >
                      Resume Processing
                  </button>
                </>
              )}

              {/* only show delete button if the product already exists (editing mode) */}
              {!this.state.initializingUpload &&
                this.props.productForm.editing &&
                (!job ||
                  job.status === 'done' ||
                  job.status === 'failed' ||
                  job.status === 'paused') && (
                <button
                  type="button"
                  className="btn-primary btn-secondary-white addfiles__process-delete-btn"
                  onClick={this.handleDeleteProduct}
                >
                    Delete Product
                </button>
              )}

              {/* you should be able to save only if there is no job *or* the current job is done */}
              {!this.state.initializingUpload &&
                (!job || job.status === 'done' || job.status === 'failed') && (
                <button
                  type="button"
                  className="btn-primary btn-primary-solid addfiles__process-btn"
                  onClick={(event) => this.handleSubmit(event, false)}
                >
                    Save Product
                </button>
              )}
              {!this.state.initializingUpload &&
                (!job || job.status === 'done' || job.status === 'failed') &&
                (this.state.files.length !== 0 ||
                  this.props.images.length !== 0) && (
                <button
                  type="submit"
                  className="btn-primary btn-primary-solid addfiles__process-btn"
                >
                    Save & Process
                </button>
              )}
            </div>

            {/* product job progress bar */}
            {job &&
              job.current_task !== null &&
              job.status !== 'paused' &&
              job.status !== 'failed' &&
              job.status !== 'done' && (
              <Line
                className={`addfiles__progress-bar ${smClassName} ${mobileClassName}`}
                percent={getJobProgress(job)}
                strokeWidth="1.3"
                strokeColor="rgba(3,169,244,1)"
                trailWidth="1"
              />
            )}

            {/* file uploads progress bar... here for css reasons */}
            {!this.props.requestsInProgress.VERIFYING_UPLOADS &&
              this.state.progress >= 0 && (
              <Line
                className={`addfiles__progress-bar ${smClassName} ${mobileClassName}`}
                percent={this.state.progress * 100}
                strokeWidth="1.3"
                strokeColor="rgba(3,169,244,1)"
                trailWidth="1"
              />
            )}
          </section>
        </form>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    subscriptionPlans: state.subscriptionPlans,
  }
}

export default connect(mapStateToProps)(AddFiles)